import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import PlanResource from '@/lib/api/resource/case/plan/PlanResource';
import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
import { getHipComponentsCatalog } from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';

export default class PlanReportService {
    constructor(
        private readonly _project: CaseRepresentation,
        private readonly _plan: PlanRepresentation,
        private readonly _$apiResource: ApiRepresentation,
        private _$apiOptions: CacheOptions) {
    }

    public async getReportData(): Promise<PlanRepresentation | null> {
        const _componentsCatalog = await getHipComponentsCatalog(
            this._$apiResource, this._$apiOptions);
        await PlanResource.getPlanFiles(this._plan, { ...this._$apiOptions, forceLoad: true });
        await PlanResource.getPlanForExternalIntegration(
            this._project, this._plan, _componentsCatalog, this._$apiResource, this._$apiOptions);

        return this._plan;
    }
}
