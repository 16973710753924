import { approxEquals, ThreeUtil, worldPosition, worldTransform } from '@/lib/base/ThreeUtil';
import { Matrix4, Vector3 } from 'three';

import anylogger from 'anylogger';
import { positionalPart } from '@/lib/base/RigidTransform';
import { formatArrayNumber } from '@/lib/filters/format/formatArrayNumber';
import { formatArrayNumberDiff } from '@/lib/filters/format/formatArrayNumberDiff';
import StringUtil from '@/lib/base/StringUtils';
import { Matrix4Util } from '@/lib/base/three-js/Matrix4Util';
import { AcidObject3dBase } from '@/lib/planning/objects-3D/AcidObject3d';
import { groupBy } from 'ramda';

const log = anylogger('TroubleshootUtil');

/**
 * Log the differences, if any, between two matrices.
 */
export function logMatrixComparison(name: string, expected: Matrix4, actual: Matrix4): void {
    const expectedComponentPosition = positionalPart(expected);
    const actualComponentPosition = positionalPart(actual);

    const { x: expectedX, y: expectedY, z: expectedZ } = ThreeUtil.getBasis(expected);
    const { x: actualX, y: actualY, z: actualZ } = ThreeUtil.getBasis(actual);

    logVectorComparisons(name, [
        ['position', expectedComponentPosition, actualComponentPosition],
        ['x', expectedX, actualX],
        ['y', expectedY, actualY],
        ['z', expectedZ, actualZ],
    ]);
}

/**
 * Log the differences, if any, between the position of two matrices.
 */
export function logMatrixPositionComparison(name: string, expected: Matrix4, actual: Matrix4): void {
    const { level, message } = formatVectorComparison(name, positionalPart(expected), positionalPart(actual));
    log(level, message);
}

/**
 * Only compares and logs the rotational part of the 4x4 matrix. This is the upper 3x3 matrix.
 *
 * @see {@link logMatricesComparison}
 */
export function logMatricesRotationComparison2(
    component: AcidObject3dBase, componentToPlanning: Matrix4, sceneTransformation: Matrix4): void {
    const componentToScene = Matrix4Util.premultiply(componentToPlanning, sceneTransformation);
    const { x: expectedX, y: expectedY, z: expectedZ } = ThreeUtil.getBasis(componentToScene);

    const actualComponentRotation = worldTransform(component.theObject);
    const { x: actualX, y: actualY, z: actualZ } = ThreeUtil.getBasis(actualComponentRotation);

    logVectorComparisons(component.alias, [
        ['x', expectedX, actualX],
        ['y', expectedY, actualY],
        ['z', expectedZ, actualZ],
    ]);
}

/**
 * Only compares and logs the positional part of the 4x4 matrix
 *
 * @see {@link logMatricesComparison}
 */
export function logMatricesPositionComparison(component: AcidObject3dBase, sceneTransformation: Matrix4): void {
    const componentToScene = Matrix4Util.premultiply(component.objectMatrix, sceneTransformation);
    const expectedComponentPosition = positionalPart(componentToScene);

    const actualComponentPosition = worldPosition(component.theObject);
    logVectorComparisons(component.alias, [
        ['position', expectedComponentPosition, actualComponentPosition],
    ]);
}

/**
 * Only compares and logs the rotational part of the 4x4 matrix. This is the upper 3x3 matrix.
 *
 * @see {@link logMatricesComparison}
 */
export function logMatricesRotationComparison(component: AcidObject3dBase, sceneTransformation: Matrix4): void {
    const componentToScene = Matrix4Util.premultiply(component.objectMatrix, sceneTransformation);
    const { x: expectedX, y: expectedY, z: expectedZ } = ThreeUtil.getBasis(componentToScene);

    const actualComponentRotation = worldTransform(component.theObject);
    const { x: actualX, y: actualY, z: actualZ } = ThreeUtil.getBasis(actualComponentRotation);

    logVectorComparisons(component.alias, [
        ['x', expectedX, actualX],
        ['y', expectedY, actualY],
        ['z', expectedZ, actualZ],
    ]);
}

function logVectorComparisons(name: string, vectors: [string, Vector3, Vector3][]) {
    const { info, warn } = groupBy(
        v => v.level,
        vectors.map(
            ([vectorName, expected, actual]) =>
                formatVectorComparison(vectorName, expected, actual),
        ),
    );
    if (info) {
        log.info(
            'Comparing %s:\n  %s',
            StringUtil.capitalize(name),
            info.map(i => i.message).join('\n  '),
        );
    }
    if (warn) {
        for (const w of warn) {
            log.debug('%s %s', name, w.message);
        }
    }
}

function formatVectorComparison(
    name: string, expected: Vector3, actual: Vector3, separator = '\n  '): { level: 'info' | 'warn', message: string } {
    if (approxEquals(expected, actual, 0.01)) {
        return {
            level: 'info',
            message: `${StringUtil.capitalize(name)} is as expected: ${formatArrayNumber(expected.toArray())}`,
        };
    } else {
        return {
            level: 'warn',
            message: [
                `${StringUtil.capitalize(name)} is different than expected. `,
                `  Expected:${formatArrayNumber(expected.toArray())}. `,
                `  Actual:${formatArrayNumber(actual.toArray())}. `,
                `  Diff:${formatArrayNumberDiff(expected.toArray(), actual.toArray())}` +
                `(${expected.distanceTo(actual)}} mm)`,
            ].join(separator),
        };
    }
}
