
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
    import { formatAngle } from '@/lib/filters/format/formatAngle';
    import { SpinopelvicStore, useSpinopelvicStore } from '@/stores/spinopelvic/store';
    import { mapStores } from 'pinia';
    import AlertIcon from '@/components/ui/icons/AlertIcon.vue';
    import AppSimpleTable from '@/components/basic/AppSimpleTable.vue';
    import {
        SpinopelvicCalculations, SpinopelvicWarnings,
        TableRow,
    } from './types';
    import SpinopelvicWarningsTable from '@/components/spinopelvic/SpinopelvicWarningsTable.vue';

    @Component({
        components: {
            AlertIcon,
            AppSimpleTable,
            SpinopelvicWarningsTable,
        },
        computed: {
            ...mapStores(useSpinopelvicStore),
        },
    })
    export default class SpinopelvicParameterWarnings extends Vue {
        @Prop({required: true})
        spinopelvicCalculations!: SpinopelvicCalculations;

        @Prop({required: true})
        spinopelvicWarnings!: SpinopelvicWarnings;

        declare protected spinopelvicStore: SpinopelvicStore;
        protected parametersContent: Array<TableRow> = [];

        protected mounted(): void {
            this.updateParametersContent();
        }

        @Watch('spinopelvicCalculations')
        protected onCalculationsChange() {
            this.updateParametersContent();
        }

        protected updateParametersContent(): void {
            this.parametersContent = [
                {
                    label: 'Parameter',
                    value: 'Value',
                },
                {
                    label: 'Spinopelvic Tilt',
                    value: this.formattedStandingPelvicTilt,
                },
                {
                    label: 'Pelvic Femoral Angle',
                    value: this.formattedPelvicFemoralAngle,
                },
                {
                    label: 'Lumbar Lordosis',
                    value: this.formattedLumbarLordosis,
                },
                {
                    label: 'Sacral Slope',
                    value: this.formattedSacralSlope,
                },
                {
                    label: 'Pelvic Tilt',
                    value: this.formattedPelvicTilt,
                },
            ];
        }

        protected get formattedStandingPelvicTilt(): string {
            return formatAngle(Number(this.spinopelvicStore.measurements.standingPelvicTilt) ?? null);
        }

        protected get formattedPelvicFemoralAngle(): string {
            return formatAngle(Number(this.spinopelvicStore.measurements.pelvicFemoralAngle) ?? null);
        }

        protected get formattedLumbarLordosis(): string {
            return formatAngle(Number(this.spinopelvicStore.measurements.lumbarLordosis) ?? null);
        }

        protected get formattedSacralSlope(): string {
            return formatAngle(Number(this.spinopelvicStore.measurements.sacralSlope) ?? null);
        }
        protected get formattedPelvicTilt(): string {
            return formatAngle(Number(this.spinopelvicStore.measurements.pelvicTilt) ?? null);
        }

    }
