import {
    AVAILABLE_PRODUCTS,
    Product,
    ProductCollectionRepresentation,
    ProductRepresentation,
} from '@/lib/api/representation/ProductRepresentation';
import { BodySide, BodySideType } from '@/lib/api/representation/interfaces';
import { flatten } from 'ramda';
import { compact } from 'lodash';

export interface ProductOption {
    name: string;
    productType: ProductRepresentation;
    procedureSide?: BodySideType;
}

/**
 * Names used on the API to determinate a product and its possible side
 */
export enum CaseProductNames {
    RightHip = 'Right Hip',
    LeftHip = 'Left Hip',
}

export class ProductUtil {
    /**
     * Utility method to know if a product has a side or not
     */
    public static isProductWithSide(product: Product): boolean {
        return product === Product.Hip;
    }

    public static hasProduct(productCollection: ProductCollectionRepresentation, product: Product): boolean {
        return ProductUtil.getProduct(productCollection, product) !== undefined;
    }

    public static getProduct(
        productCollection: ProductCollectionRepresentation,
        product: Product): ProductRepresentation | undefined {
        return (productCollection.items || []).find(i => i.name === product);
    }

    public static getProductOptions(productCollection: ProductCollectionRepresentation): ProductOption[] {
        const productOptionsByProduct = AVAILABLE_PRODUCTS.map(
            (productType: Product): ProductOption | ProductOption[] | null => {
                const product = ProductUtil.getProduct(productCollection, productType);
                if (product) {
                    switch (productType) {
                        case Product.Hip:
                            return ProductUtil.getHipProductOptions(product);
                        default:
                            return null;
                    }
                }
                return null;
            });

        return flatten(compact(productOptionsByProduct));
    }

    public static getHipProductOptions(product: ProductRepresentation): ProductOption[] {
        return [
            {
                name: CaseProductNames.LeftHip,
                productType: product,
                procedureSide: BodySide.Left,
            },
            {
                name: CaseProductNames.RightHip,
                productType: product,
                procedureSide: BodySide.Right,
            }];
    }
}
