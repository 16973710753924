
    import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
    import Ago from '@/components/lib/Ago.vue';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import { navigatePathPlanner } from '@/router/navigate';
    import ProjectStore from '@/hipPlanner/components/state/project/ProjectStore';

    @Component({ components: { Ago } })
    export default class PlanDownloadReportUserWarning extends Vue {
        @InjectReactive() store!: ProjectStore;

        @Prop({ required: true, validator: notNilValidator('isActive') })
        public isActive!: boolean;

        public get plannerPath(): string {
            return this.store.project ? navigatePathPlanner(this.store.project) : '';
        }
    }
