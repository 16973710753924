import { Vue } from 'vue/types/vue';
import { PlannerEvent } from '@/lib/planning/events/PlannerEvent';
import anylogger from 'anylogger';
import AcidPlannerModeController from '@/hipPlanner/assembly/controllers/AcidPlannerModeController';
import { ViewingMode } from '@/lib/viewer/ViewingMode';
import { addHandlers } from '@/lib/vue/addHandlers';
import { FilterByAlias, FilterByViewingMode } from '@/hipPlanner/components/viewer-filter-bar/ViewerFilterItem';
import { VueObserver } from '@/hipPlanner/assembly/controllers/VueObserver';

const log = anylogger('ViewerFilterObserver');

/**
 * Support for storing a refresh token in local storage.
 */
export class ViewerFilterObserver extends VueObserver {
    constructor(
        /**
         * event bus used for filtering
         * TODO Add typing for event bus
         */
        private readonly eventBus: Vue,
        /**
         * controller that does visibility, transparency, x-ray, and a lot of other stuff. Adding here to use it
         * until I stop using it
         */
        private readonly plannerModeController: AcidPlannerModeController) {
        super();
        this.addWatches(
            addHandlers(this.eventBus, [
                [PlannerEvent.ToggleModelVisibilityByAlias, this.onToggleModelVisibilityByAlias.bind(this)],
                [PlannerEvent.ToggleViewXRayMode, this.onToggleViewXRayMode.bind(this)],
            ]),
        );
    }

    private onToggleModelVisibilityByAlias(payload: FilterByAlias): void {
        log.debug(
            'onToggleModelVisibilityByAlias handler called with filter: %s, isActive: %s',
            payload.filter.toString(),
            payload.isActive);
        this.plannerModeController.toggleVisibilityByAlias(payload.isActive, payload.filter);

        this.eventBus.$emit(PlannerEvent.ToggleModelVisibilityByAliasDone, payload);
    }

    private onToggleViewXRayMode(payload: FilterByViewingMode): void {
        log.debug('onToggleViewXRayMode handler called filter: %s, isActive: %s', payload.filter, payload.isActive);

        const viewingMode = payload.isActive ? ViewingMode.Xray : ViewingMode.Normal;
        this.plannerModeController.changeXRayMode(viewingMode);
    }
}
