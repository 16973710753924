import { HipCupAnteversionMode, SelectCupAnteversionModeOption } from '@/lib/api/representation/interfaces';

const HipCupAnteversionModes: SelectCupAnteversionModeOption[] = [
    {
        value: HipCupAnteversionMode.Auto,
        text: 'Combined',
    },
    {
        value: HipCupAnteversionMode.Manual,
        text: 'Manual',
    },
];

export default HipCupAnteversionModes;
