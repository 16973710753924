import Name from '@/router/Name';
import { Route } from 'vue-router';

/**
 * Utility to over Vue Route
 */
export class RouteUtils {
    public static isOnDashboard(route: Route): boolean {
        return route.matched.some(({ name }) => name === Name.Home);
    }

    public static isOnPlanningWorkflow(route: Route): boolean {
        return RouteUtils.isOnCaseSettings(route) ||
            RouteUtils.isOnDicom(route) ||
            RouteUtils.isOn3DPlanning(route) ||
            RouteUtils.isOnReport(route);
    }

    public static isOn3DPlanning(route: Route): boolean {
        return RouteUtils.isOnHip3DPlanning(route);
    }

    public static isOnCaseSettings(route: Route): boolean {
        return route.matched.some(({ name }) => name === Name.CaseSettings);
    }

    public static isOnDicom(route: Route): boolean {
        return route.matched.some(({ name }) => name === Name.CaseCatStackViewer);
    }

    public static isOnReport(route: Route): boolean {
        return route.matched.some(({ name }) => name === Name.CasePlan);
    }

    public static isOnHip3DPlanning(route: Route): boolean {
        return route.matched.some(({ name }) => name === Name.CaseHipPlanner);
    }

    public static isOnAboutPage(route: Route): boolean {
        return route.matched.some(({ name }) => name === Name.About);
    }

    public static isOnPrivacyPage(route: Route): boolean {
        return route.matched.some(({ name }) => name === Name.Privacy);
    }

    public static isOnTermsPage(route: Route): boolean {
        return route.matched.some(({ name }) => name === Name.Terms);
    }

    public static isOnPreferencesPage(route: Route): boolean {
        return route.matched.some(({ name }) => name === Name.ProfileHipSettings);
    }
}
