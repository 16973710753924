import { parse, Token } from 'auth-header';

/**
 * Support for parsing www-authenticate header(s)
 *
 *  @see
 *    - {@link http://www.iana.org/assignments/http-authschemes/http-authschemes.xhtml}
 */
export default class WwwAuthenticateUtil {
    /**
     * Parse a response 'www-authenticate-header'. If the server supports multiple header values then
     * the input may be an array of headers. Otherwise it is assumed that the multiple header values
     * have been concatenated into a single header with the values separated by commas (',').
     */
    public static parse(header: string | string[]): Token[] {
        if (Array.isArray(header)) {
            return header.map(h => WwwAuthenticateUtil.parseSingle(h)).flat(1);
        }
        return this.parseSingle(header);
    }

    /**
     * A single header *may* have multiple auth schemes. The library used
     * doesn't support multiple values, so pre-split the headers.
     *
     * There are multiple grammar ambiguity issues with the www-authenticate header
     * that have been known for some time. The main one seems to be that the Basic
     * auth challenge which is a base64 encoded string looks like (is ambiguous with)
     * an auth scheme (it looks like a token), and with the trailing equals sign ('=')
     * it also looks like a parameter (but it is neither).
     *
     * Splitting and unambiguously parsing multiple auth scheme is more work than
     * the current time allows. Because UWSGI doesn't support multiple response headers
     * with the same name, as a simple hack/workaround the comma (',') separator between
     * successive auth schemes is augmented with a horizontal tab ('\t') character. This
     * is likely to be unique enough as a character to split adjacent auth scheme, while
     * being compliant with RFCs.
     */
    public static parseSingle(header: string): Token[] {
        if (header) {
            return header.split(',\t').map((h) => parse(h));
        }
        return [];
    }

    /**
     * Given a set of response headers, get all 'www-authenticate' headers
     * as {@link Token} objects.
     *
     * If multiple 'WWW-Authenticate' headers are provided by the server then
     * multiple tokens will be returned. The base library may not be able to
     * parse multiple schemes within the same header.
     *
     * On Amazon Web Services the header is mapped in serverless environments.
     */
    public static wwwAuthenticateHeaders(headers: any): Token[] {
        const header: string | string[] = headers['www-authenticate'];
        if (header) {
            return WwwAuthenticateUtil.parse(header);
        }
        const mappedHeader: string | string[] = headers['x-amzn-remapped-www-authenticate'];
        if (mappedHeader) {
            return WwwAuthenticateUtil.parse(mappedHeader);
        }
        return [];
    }
}
