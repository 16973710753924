import ResourceService from '@/lib/api/ResourceService';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { CacheOptions, create } from '@/lib/semanticNetworkMigrationUtils';
import { LinkUtil } from 'semantic-link';
import ResourceUtil from '@/lib/api/ResourceUtil';
import LinkRelation from '@/lib/api/LinkRelation';
import {
    makeAutomatedSurgicalTemplateCreateDataRepresentation,
    makeManualSurgicalTemplateCreateDataRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateCreateDataRepresentation';
import {
    SurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import anylogger from 'anylogger';
import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
import ContentType from '@/lib/http/mimetype';
import { pooledSingletonMakeStrategy } from '@/lib/semantic-network';
import LinkRelationTitle from '@/lib/api/LinkRelationTitle';

const log = anylogger('CaseSurgicalTemplateResource');
export default class CaseSurgicalTemplateResource implements ResourceService {
    /**
     * Creates a new ACID surgical template base on the existing/current acid surgical template and reloads the project
     */
    public static async createDuplicateAcidSurgicalTemplate<T extends SurgicalTemplateRepresentation>(
        caseResource: CaseRepresentation, options?: CacheOptions): Promise<T | null> {
        const templates = ResourceUtil.makePooledCollection(
            caseResource, { ...options, rel: LinkRelation.surgicalTemplates });
        return await create<T>(
            makeAutomatedSurgicalTemplateCreateDataRepresentation(
                getRequiredUri(caseResource, LinkRelation.acidSurgicalTemplate)),
            {
                ...options,
                createContext: caseResource,
                rel: LinkRelation.surgicalTemplates,
                singletonName: 'acidSurgicalTemplate',
                singletonRel: LinkRelation.surgicalTemplate,
                singletonTitle: LinkRelationTitle.automatedSurgicalTemplate,
                contentType: ContentType.Json,
                makeSparseStrategy: (o) => {
                    return pooledSingletonMakeStrategy(templates, o);
                },
            }) ?? null;
    }

    /**
     * 1) Get the current surgical template (user surgical template) for a case (project) if it exists
     * This surgical template resource may change over time, and may not be present.
     *
     * The case (project) should have a link with a relationship of 'surgicalTemplate' and a name of 'current'.
     * The strategy here is to load it from a virtual list of surgical templates in the 'surgical-templates' collection.
     *
     * 2) When no surgical template is present, it will try to create one
     */
    public static async getOrCreateUserSurgicalTemplate<TC extends CaseRepresentation,
        T extends SurgicalTemplateRepresentation>(
        caseResource: TC,
        options?: CacheOptions): Promise<T | null> | never {
        const userSurgicalTemplate = await this.getValidUserSurgicalTemplate<TC, T>(caseResource, options);

        if (userSurgicalTemplate) {
            return userSurgicalTemplate;
        } else {
            // There is no valid user surgical template, we need to create one.
            // Either:
            // a) This is likely the first time the user enters the 3D scene
            // b) There is a user surgical template but does not belong to the current acid surgical template.
            // This is likely the
            //   1. An admin clicks the duplicate acid surgical template on the manage page.
            //   2. A user uploaded a study a second time (or third, etc).
            return await this.createUserSurgicalTemplate(caseResource, options);
        }
    }

    /**
     * Validate a user surgical template was created using the case acid surgical template as its parent.
     *
     * Note: A user surgical template's parent link refers to a version/history item.
     * To validate a template was created using the current case acid surgical template, we need to compare
     * the non-canonical version (a.k.a main record), given the case resource does not know
     * about the specific version, and only knows about the main resource
     *
     * The following diagram illustrate current model and some existent link relations.
     * The specific validation done on this method, is that, with a resource identified as case (A), and a user
     * surgical template identified as (B), the link identified as (1) and (2) are the same.
     *
     *         (A)
     * +--------+         +-----------+    (2) non-canonical      +--------------+
     * |        | (1)acid | Acid      | <-------------------------| Acid         |--+
     * | Case   |-------->| Surgical  |                           | Surgical     |  |
     * |        |-+       | Template  |                           | Template     |  |   parent
     * |        | |       |           |                        +->| History      |  |  <------------+
     * +--------+ |       +-----------+                        |  +--------------+  |               |
     *            |                                            |    +---------------+               |
     *            |                                            |                                    |
     *     current|                    (B)                     |                                    |
     *            |        +-----------+          parent       |   +--------------+                 |
     *            |        |           | ----------------------+   | User         |--+              |
     *            +------> | User      |       canonical           | Surgical     |  |              |
     *                     | surgical  | ------------------------->| Template     |  | -------------+
     *                     | template  |                           | History      |  |
     *                     +-----------+                           +--------------+  |
     *                                                               +---------------+
     *
     * @return the surgical template if it meets the condition, otherwise null
     */
    private static async validateUserSurgicalTemplate<TC extends CaseRepresentation,
        T extends SurgicalTemplateRepresentation>(
        userSurgicalTemplate: T,
        caseResource: TC,
        options?: CacheOptions): Promise<T | null> | never {
        const userSurgicalTemplateParent = await SurgicalTemplateResource.getParentSurgicalTemplate(
            userSurgicalTemplate, options);
        const userSurgicalTemplateParentUri = LinkUtil.getUri(userSurgicalTemplateParent, LinkRelation.nonCanonical);
        const acidSurgicalTemplateUri = getRequiredUri(caseResource, LinkRelation.acidSurgicalTemplate);

        if (acidSurgicalTemplateUri === userSurgicalTemplateParentUri) {
            return userSurgicalTemplate;
        } else {
            log.warn('current surgical template was created using %s and current acid surgical template is %s',
                userSurgicalTemplateParentUri,
                acidSurgicalTemplateUri);
            return null;
        }
    }

    /**
     * Get the user surgical template if the case has a link with rel='surgical template' and title='current'
     *
     * @return the surgical template resource if valid, or null if there is no link or invalid.
     * @throw a exception if could not get the surgical template using the link
     */
    private static async getValidUserSurgicalTemplate<TC extends CaseRepresentation,
        T extends SurgicalTemplateRepresentation>(
        caseResource: TC,
        options?: CacheOptions): Promise<T | null> | never {
        if (LinkUtil.matches(caseResource, LinkRelation.currentSurgicalTemplate)) {
            const userSurgicalTemplate = await this.getCurrentSurgicalTemplate<T, TC>(caseResource, options);
            if (userSurgicalTemplate) {
                const validUserSurgicalTemplate = await this.validateUserSurgicalTemplate(
                    userSurgicalTemplate, caseResource, options);
                if (validUserSurgicalTemplate) {
                    return validUserSurgicalTemplate;
                }
                return null;
            } else {
                throw new Error('Failed to get surgical template');
            }
        }
        return null;
    }

    /**
     * Creates a surgical template (user (a.k.a 'manual') surgical template, not acid surgical template),
     * in the context of a project
     */
    private static async createUserSurgicalTemplate<TC extends CaseRepresentation,
        T extends SurgicalTemplateRepresentation>(
        caseResource: TC,
        options?: CacheOptions): Promise<T | null> {
        const acidSurgicalTemplate = await SurgicalTemplateResource.getCaseAutomatedSurgicalTemplate(
            caseResource, { ...options, forceLoad: true });

        if (acidSurgicalTemplate) {
            CaseSurgicalTemplateResource.validateAcidSurgicalTemplateCanBeUsedAsParent(acidSurgicalTemplate);

            const templates = ResourceUtil.makePooledCollection(
                caseResource, { ...options, rel: LinkRelation.surgicalTemplates });
            const template = await create<T>(
                makeManualSurgicalTemplateCreateDataRepresentation(
                    getRequiredUri(caseResource, LinkRelation.acidSurgicalTemplate)),
                {
                    ...options,
                    createContext: caseResource,
                    rel: LinkRelation.surgicalTemplates,
                    singletonName: 'surgicalTemplate',
                    singletonRel: LinkRelation.surgicalTemplate,
                    singletonTitle: LinkRelationTitle.manualSurgicalTemplate,
                    contentType: ContentType.Json,
                    makeSparseStrategy: (o) => pooledSingletonMakeStrategy(templates, o),
                }) ?? null;

            if (template) {
                return template;
            }
            throw new Error('Failed to create surgical template');
        }
        throw new Error('Could not create surgical template on case without required data');
    }

    /**
     * Gets the current surgical template on the case, from the surgical templates collection
     */
    private static async getCurrentSurgicalTemplate<T extends SurgicalTemplateRepresentation,
        TC extends CaseRepresentation>(
        caseResource: TC,
        options?: CacheOptions): Promise<T | null> {
        return await SurgicalTemplateResource.getCaseUserSurgicalTemplate<T>(caseResource, options) ?? null;
    }

    /**
     * Validates an acid surgical template is in the right state before using it as a parent of a user surgical template
     *
     * Validates:
     * 1. The acid surgical template is in the {@link SurgicalTemplateState.Completed}
     * 2. TODO it may need to check/consider for clinical warnings
     */
    private static validateAcidSurgicalTemplateCanBeUsedAsParent<T extends SurgicalTemplateRepresentation>(
        acidSurgicalTemplate: T): T | never {
        if (SurgicalTemplateUtil.isCompleted(acidSurgicalTemplate)) {
            return acidSurgicalTemplate;
        }
        throw new Error('The acid surgical template must be completed');
    }
}
