
    import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
    import CaseStateStepper from '@/components/case/state-stepper/CaseStateStepper.vue';
    import Username from '@/components/profile/Username.vue';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import { PatientRepresentation } from '@/lib/api/representation/PatientRepresentation';
    import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
    import PatientResource from '@/lib/api/resource/case/PatientResource';
    import UserResource from '@/lib/api/resource/user/UserResource';
    import { UriDeconstructionUtil } from '@/components/case-plan/UrlDeconstructionUtil';
    import LinkRelation from '@/lib/api/LinkRelation';

    import anylogger from 'anylogger';
    import DatetimeLocaleUtil, { DateDayEnum, DateMonthEnum, DateYearEnum } from '@/lib/datetimeLocaleUtil';
    import Bugsnag from '@bugsnag/js';
    import assert from 'assert';
    import HomeTextHighlight from '@/views/home/HomeTextHighlight.vue';
    import { isErrorCausedBy } from '@/hipPlanner/views/hipPlannerServices';
    import { getRequiredSelfUri } from '@/lib/api/SemanticNetworkUtils';

    const log = anylogger('CaseListItem');

    const DataCancellationReason = 'loading user case list item cancelled';

    @Component({ components: { CaseStateStepper, Username, HomeTextHighlight } })
    /**
     * This component is coupled to the knowledge that is inside a <v-virtual-scroll>
     * The component is mounted when visible in the <v-virtual-scroll> and unmounted when
     * not visible anymore (this could be because the user is scrolling, or because the project
     * list iterable changed based on the search text)
     *
     * Residual: The <CaseStateStepper /> as a child component is being mounted/unmounted in the same
     * lifecycle. This is not ideal, but I am reluctant to touch its behaviour until we put in place
     * a new way of navigating the workflow. Overall, considering that the request are being cancelled,
     * this extra request loaded on mount of <CaseStateStepper /> have no impact in performance.
     */
    export default class CaseListItem extends Vue {
        /**
         * This is the sparsely populated case/project that will populated in the
         * created method (below). The property must be provided by the parent.
         */
        @Prop({ required: true })
        public value!: CaseRepresentation;

        @InjectReactive()
        protected searchText!: string;

        protected isLoading = true;

        /**
         * The case state is refreshed every this many ms
         * This should be less often than when on a single case's page since there
         * will be many cases refreshing when the case list is shown
         */
        private stateInterval = 20000;

        /**
         * The state refresh time is offset randomly by up to this ms
         * This should be non-zero to prevent all cases visible to refresh state
         * at the same time
         */
        private stateIntervalOffset = 2000;

        owner: UserRepresentation | null = null;
        surgeon: UserRepresentation | null = null;
        patient: PatientRepresentation | null = null;

        /** A controller to cancel request in progress */
        private cancellation?: AbortController;

        /**
         * Manually for the created timestamp as a date (without time of day)
         */
        protected get createdDate(): string {
            if (this.value.created) {
                return DatetimeLocaleUtil.formatToLocaleDate(
                    this.value.created,
                    DatetimeLocaleUtil.getUserLocale(),
                    {
                        year: DateYearEnum.Numeric,
                        month: DateMonthEnum.Short,
                        day: DateDayEnum.Numeric,
                    });
            }
            return '';
        }

        protected get referenceIdentifier(): string | null {
            const caseId = UriDeconstructionUtil.pathNameOfLink(this.value, LinkRelation.self);
            if (caseId) {
                return caseId;
            }
            return null;
        }

        protected async mounted(): Promise<void> {
            this.cancellation = new AbortController();

            if (this.value) {
                try {
                    // Ensure that the case data is fresh, but don't reassign the value.
                    this.patient = await PatientResource.getCasePatient(
                        this.value, { signal: this.cancellation.signal, ...this.$apiOptions });
                    this.cancellation.signal.throwIfAborted();

                    this.surgeon = await UserResource.getCaseSurgeon(
                        this.$api, this.value, { signal: this.cancellation.signal, ...this.$apiOptions });
                    this.cancellation.signal.throwIfAborted();

                    this.owner = await UserResource.getCaseOwner(
                        this.$api, this.value, { signal: this.cancellation.signal, ...this.$apiOptions });
                    this.cancellation.signal.throwIfAborted();
                } catch (e) {
                    if (isErrorCausedBy(e, DataCancellationReason)) {
                        log.info('Loading case list item - cancelled (case: %s )', getRequiredSelfUri(this.value));
                    } else {
                        assert.ok(e instanceof Error);
                        log.info('Failed to read case list data for case: %s ): Error %s', getRequiredSelfUri(this.value), e.message);
                        Bugsnag.notify(e);
                    }
                } finally {
                    this.isLoading = false;
                }
            } else {
                log.debug('Case representation not loaded. Cannot make CaseListItem');
            }
        }

        protected beforeDestroy(): void {
            this.cancellation?.abort({ name: DataCancellationReason });
        }
    }
