import { Vector3 } from 'three';
import { CrossSectionPlaneModel } from '@/lib/planning/cross-section/CrossSectionPlaneModel';
import { CrossSectionPlaneName } from '@/lib/planning/cross-section/types';
import { CrossSectionPlane } from '@/lib/planning/cross-section/CrossSectionPlane';
import HipViewerObjectUtil from '@/hipPlanner/assembly/objects/HipViewerObjectUtil';
import { AcidMeshObject3d } from '@/hipPlanner/assembly/objects/AcidMeshObject3d';
import MaterialUtils from '@/lib/viewer/MaterialUtils';
import { OutlineParams } from '@/lib/planning/cross-section/OutlineParams';

function makeCrossSectionPlane(name: string, position: Vector3, direction: Vector3, outlineParams: OutlineParams, visibleWhenEnabled: boolean) {
    const plane = new CrossSectionPlaneModel(
        name, position, direction, outlineParams, visibleWhenEnabled);

    HipViewerObjectUtil.onEachMeshObject3D(outlineParams.models, (acidMesh: AcidMeshObject3d) => {
        MaterialUtils.addCrossSectionToAcidMeshMaterial(acidMesh, plane);
    });

    return plane;
}

/**
 * Cross-section plane utils
 */
export class CrossSectionPlaneUtils {
    /**
     * Make a plane that is visible when is enabled
     */
    public static makeVisibleWhenEnabledPlane(
        name: CrossSectionPlaneName,
        position: Vector3,
        direction: Vector3,
        outlineParams: OutlineParams): CrossSectionPlane {
        return makeCrossSectionPlane(name, position, direction, outlineParams, true);
    }

    /**
     * Make a plane that can be optionally visible when is enabled
     */
    public static makePlaneWithOptionalVisibility(
        name: CrossSectionPlaneName,
        position: Vector3,
        direction: Vector3,
        outlineParams: OutlineParams): CrossSectionPlane {
        return makeCrossSectionPlane(name, position, direction, outlineParams, false);
    }
}
