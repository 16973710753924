import anylogger, { BaseLevels, BaseLogger } from 'anylogger';

export type LogFunction = (message?: unknown, ...args: unknown[]) => void;

/**
 * Bind a logging-function that always logs at a particular level
 */
export function defaultLevelLogger(
    level: keyof BaseLevels,
    nameOrLogger: string | BaseLogger): LogFunction {
    const log: BaseLogger = (nameOrLogger === undefined || typeof nameOrLogger === 'string') ?
        anylogger(nameOrLogger) :
        nameOrLogger;
    switch (level) {
        case 'error':
            return log.error.bind(log);
        case 'warn':
            return log.warn.bind(log);
        case 'info':
            return log.info.bind(log);
        case 'log':
            return log.log.bind(log);
        case 'debug':
            return log.debug.bind(log);
        case 'trace':
            return log.trace.bind(log);
        default:
            throw Error(`Invalid log level: ${level}`);
    }
}

