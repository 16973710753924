import { MathUtils } from 'three';
import {
    AnatomicDegreesCupRotation,
    CupRotation,
    RadiographicDegreesCupRotation,
} from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateModel';
import AcetabularAngleUtil from '@/hipPlanner/components/state/AcetabularAngleUtil';

/**
 * Utility to deal with different acetabular angle calculations
 */
export default class CupRotationUtil {
    /**
     * Converts the cup rotation to the anatomic angle mode.
     */
    public static toAnatomic(cupRotation: RadiographicDegreesCupRotation): AnatomicDegreesCupRotation {
        const inRadians = CupRotationUtil.toRadians(cupRotation);
        return CupRotationUtil.toDegrees(AcetabularAngleUtil.toAnatomic(inRadians));
    }

    /**
     * Converts the cup rotation to the anatomic angle mode.
     */
    public static toRadiographic(cupRotation: AnatomicDegreesCupRotation): RadiographicDegreesCupRotation {
        const inRadians = CupRotationUtil.toRadians(cupRotation);
        return CupRotationUtil.toDegrees(AcetabularAngleUtil.toRadiographic(inRadians));
    }

    public static make(anteversion: number, inclination: number): CupRotation {
        return { anteversion, inclination };
    }

    public static toDegrees(angles: CupRotation): CupRotation {
        return CupRotationUtil.make(MathUtils.radToDeg(angles.anteversion), MathUtils.radToDeg(angles.inclination));
    }

    public static toRadians(angles: CupRotation): CupRotation {
        return CupRotationUtil.make(MathUtils.degToRad(angles.anteversion), MathUtils.degToRad(angles.inclination));
    }
}
