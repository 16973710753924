import axios, { AxiosResponse } from 'axios';
import LinkRelation from '@/lib/api/LinkRelation';
import { CaseSettingsStore } from '@/stores/caseSettings/store';
import {
    patientRequestBodyFromState,
    unassignSurgeonRequestBody,
    projectRequestBodyFromState,
    surgicalSpecificationRequestBodyFromState,
} from '@/stores/caseSettings/mapToRequest';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { SurgeonPreferences } from '@/stores/caseSettings/types';
import CaseResource from '@/lib/api/resource/case/CaseResource';
import PatientResource from '@/lib/api/resource/case/PatientResource';
import UserResource from '@/lib/api/resource/user/UserResource';
import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
import SurgicalSpecificationResource
    from '@/lib/api/resource/case/surgical-specification/SurgicalSpecificationResource';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import { ApiOptions } from '@/lib/semantic-network';
import { findUrl } from '@/stores/caseSettings/utils';

export async function loadProject(
    api: ApiRepresentation,
    apiOptions: ApiOptions,
    apiUri: string,
): Promise<CaseRepresentation> {
    const project = await CaseResource.getCaseByUri(api, apiUri, {
        ...apiOptions,
        forceLoad: true,
    });
    if (!project) {
        throw new Error('Failed to load project data');
    }

    // Note:there are at least five independent resources to fetch from the API. So
    // that they load in a timely manner, the code needs to initiate all of them in
    // parallel then we wait for them to complete.
    await Promise.all([
        // load the patient for the case (known fixed resource)
        PatientResource.getCasePatient(project, {
            ...apiOptions,
            forceLoad: true,
        }),

        // The surgeon is an optional resource that may change. it is loaded from the global collection.
        UserResource.getCaseSurgeon(api, project, {
            ...apiOptions,
            forceLoad: true,
        }),

        // the surgeon is an mandatory resource. It is loaded from the global user collection.
        UserResource.getCaseOwner(api, project, {
            ...apiOptions,
            forceLoad: true,
        }),

        // The active study is an optional resource, that is loaded from the collection of
        // studies on the case.
        CaseStudyResource.getActiveStudy(project, {
            ...apiOptions,
            forceLoad: true,
        }),

        SurgicalSpecificationResource.getSurgicalSpecification(project, {
            ...apiOptions,
            forceLoad: true,
        }),
    ]);
    return project;
}

export function unassignSurgeonSubmitRequest(store: CaseSettingsStore): Promise<AxiosResponse> {
    // Update case info & selected surgeon
    const projectSubmitUrl: string = findUrl(store.project, LinkRelation.self);
    return axios.put(projectSubmitUrl, unassignSurgeonRequestBody(store));
}

export function projectSubmitRequest(store: CaseSettingsStore): Promise<AxiosResponse> {
    // Update case info & selected surgeon
    const projectSubmitUrl: string = findUrl(store.project, LinkRelation.self);
    return axios.put(projectSubmitUrl, projectRequestBodyFromState(store));
}

export function patientSubmitRequest(store: CaseSettingsStore): Promise<AxiosResponse> {
    // Update patient info
    const patientSubmitUrl: string = findUrl(store.project, LinkRelation.patient);
    return axios.put(patientSubmitUrl, patientRequestBodyFromState(store));
}

export function surgicalSpecificationSubmitRequest(store: CaseSettingsStore): Promise<AxiosResponse> {
    // Update surgical specification
    const surgicalSpecificationUrl: string = findUrl(store.project, LinkRelation.surgicalSpecification);
    return axios.put(surgicalSpecificationUrl, surgicalSpecificationRequestBodyFromState(store));
}

export async function getSurgeonPreferences(surgeonUri: string): Promise<SurgeonPreferences> {
    const surgeonPreferencesUrl = `${surgeonUri}/preferences/product/4`;

    return await axios
        .get<SurgeonPreferences>(surgeonPreferencesUrl)
        .then((response: AxiosResponse): SurgeonPreferences => {
            return {
                stem: response.data.stem,
                cup: response.data.cup,
            };
        });
}
