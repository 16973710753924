
    /**
     * see
     *  - https://vuetifyjs.com/en/components/dialogs
     */
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';
    import DraggableResource from '@/components/case-manage/DraggableResource.vue';

    @Component({ components: { DraggableResource, HyperlinkButton } })
    export default class ProjectStatus extends Vue {
        @Prop({ required: true })
        project!: CaseRepresentation | null;

        protected isLoading = false;
    }
