import Vue from 'vue';
import Vuetify from 'vuetify';
import AlertWarningIconSVG from '@/components/ui/icons/svg/AlertWarningIconSVG.vue';
import FemurModeInactiveIcon from '@/hipPlanner/components/view-panel/hip/FemurModeInactiveIcon.vue';
import FemurModeActiveIcon from '@/hipPlanner/components/view-panel/hip/FemurModeActiveIcon.vue';
import PelvisModeActiveIcon from '@/hipPlanner/components/view-panel/hip/PelvisModeActiveIcon.vue';
import PelvisModeInactiveIcon from '@/hipPlanner/components/view-panel/hip/PelvisModeInactiveIcon.vue';
import ImplantModeActiveIcon from '@/hipPlanner/components/view-panel/hip/ImplantModeActiveIcon.vue';
import ImplantModeInactiveIcon from '@/hipPlanner/components/view-panel/hip/ImplantModeInactiveIcon.vue';
import XRayModeActiveIcon from '@/hipPlanner/components/view-panel/hip/XRayModeActiveIcon.vue';
import XRayModeInactiveIcon from '@/hipPlanner/components/view-panel/hip/XRayModeInactiveIcon.vue';
import NeckCrossSectionModeActiveIcon
    from '@/hipPlanner/components/view-panel/hip/NeckCrossSectionModeActiveIcon.vue';
import NeckCrossSectionModeInactiveIcon
    from '@/hipPlanner/components/view-panel/hip/NeckCrossSectionModeInactiveIcon.vue';
import StemCrossSectionModeActiveIcon
    from '@/hipPlanner/components/view-panel/hip/StemCrossSectionModeActiveIcon.vue';
import StemCrossSectionModeInactiveIcon
    from '@/hipPlanner/components/view-panel/hip/StemCrossSectionModeInactiveIcon.vue';
import CupCrossSectionModeActiveIcon from '@/hipPlanner/components/view-panel/hip/CupCrossSectionModeActiveIcon.vue';
import CupCrossSectionModeInactiveIcon
    from '@/hipPlanner/components/view-panel/hip/CupCrossSectionModeInactiveIcon.vue';
import CustomColors from '@/styles/_colors';
import CustomButton from '@/components/ui/CustomButton.vue';

Vue.component('CustomButton', CustomButton);

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            alertWarning: {
                component: AlertWarningIconSVG,
            },
            caseStepDetails: 'mdi-cog',
            caseStepDicomViewer: 'mdi-camera',
            caseStepPlanner: 'mdi-rotate-3d',
            caseStepPdf: 'mdi-file-pdf',
            crossSection: 'mdi-slash-forward-box',
            planningHipFemurModeActive: {
                component: FemurModeActiveIcon,
            },
            planningHipFemurModeInactive: {
                component: FemurModeInactiveIcon,
            },
            planningHipPelvisModeActive: {
                component: PelvisModeActiveIcon,
            },
            planningHipPelvisModeInactive: {
                component: PelvisModeInactiveIcon,
            },
            planningHipImplantModeActive: {
                component: ImplantModeActiveIcon,
            },
            planningHipImplantModeInactive: {
                component: ImplantModeInactiveIcon,
            },
            planningHipXRayModeActive: {
                component: XRayModeActiveIcon,
            },
            planningHipXRayModeInactive: {
                component: XRayModeInactiveIcon,
            },
            planningHipNeckCrossSectionModeActive: {
                component: NeckCrossSectionModeActiveIcon,
            },
            planningHipNeckCrossSectionModeInactive: {
                component: NeckCrossSectionModeInactiveIcon,
            },
            planningHipStemCrossSectionModeActive: {
                component: StemCrossSectionModeActiveIcon,
            },
            planningHipStemCrossSectionModeInactive: {
                component: StemCrossSectionModeInactiveIcon,
            },
            planningHipCupCrossSectionModeActive: {
                component: CupCrossSectionModeActiveIcon,
            },
            planningHipCupCrossSectionModeInactive: {
                component: CupCrossSectionModeInactiveIcon,
            },
        },
    },
    theme: {
        dark: false,
        themes: {
            // Note that the logo blue is #007fb5
            light: {
                primary: '#007fb5', // '#487fb5',
                secondary: '#002b58',
                accent: '#b53600',
                error: '#7e0000',
                warning: '#ffc107',
                success: '#7fb500',
                ...CustomColors,
            },
        },
        // Enabling customProperties will also generate a css variable for each theme color,
        // which you can then use in your components’ <style> blocks.
        // https://vuetifyjs.com/en/features/theme/#caching
        options: { customProperties: true },
    },
});
