import {
    AngleMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/AngleMeasurementValueRepresentation';
import {
    DistanceMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/DistanceMeasurementValueRepresentation';
import { NumberUtil } from '@/lib/base/NumberUtil';
import HipSurgicalTemplateMeasurementsUtil
    from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateMeasurementsUtil';

/**
 * An abstract class to define the interface of the methods the new/legacy measurements have to implement
 */
export abstract class HipSurgicalTemplateMeasurements {
    public abstract getStemAnteversion(): AngleMeasurementValueRepresentation | null;

    public abstract getStemResectionLesserTrochanter(): DistanceMeasurementValueRepresentation | null;
    public abstract getStemResectionGreaterTrochanter(): DistanceMeasurementValueRepresentation | null;
    public abstract getStemSaddleDistance(): DistanceMeasurementValueRepresentation | null;

    public abstract getStemLegLengthDifference(): AngleMeasurementValueRepresentation | null;

    public abstract getStemLegOffsetDifference(): AngleMeasurementValueRepresentation | null;

    public abstract getCupAnteversion(): AngleMeasurementValueRepresentation | null;

    public abstract getCupLegLengthDifference(): AngleMeasurementValueRepresentation | null;

    public abstract getCupLegOffsetDifference(): AngleMeasurementValueRepresentation | null;

    public getCombinedVersion(): number | null {
        const stemVersion = this.getStemAnteversion();
        const cupVersion = this.getCupAnteversion();

        if (stemVersion && cupVersion) {
            return HipSurgicalTemplateMeasurementsUtil.getCombinedVersion(stemVersion, cupVersion);
        }

        return null;
    }

    public getCombinedLegLengthDifference(): number | null {
        const femoralLegLengthDifference = this.getStemLegLengthDifference();
        const acetabularLegLengthDifference = this.getCupLegLengthDifference();

        if (femoralLegLengthDifference && acetabularLegLengthDifference) {
            return NumberUtil.sumIfFinite(femoralLegLengthDifference.value, acetabularLegLengthDifference.value);
        }

        return null;
    }

    public getCombinedLegOffsetDifference(): number | null {
        const femoralLegOffsetDifference = this.getStemLegOffsetDifference();
        const acetabularLegOffsetDifference = this.getCupLegOffsetDifference();

        if (femoralLegOffsetDifference && acetabularLegOffsetDifference) {
            return NumberUtil.sumIfFinite(femoralLegOffsetDifference.value, acetabularLegOffsetDifference.value);
        }

        return null;
    }
}
