import {
    CrossSectionNameEnum,
    CrossSectionPlaneController,
} from '@/lib/planning/cross-section/CrossSectionPlaneController';
import plannerEventBus from '@/lib/planning/events/PlannerEventBus';
import { PlannerEvent } from '@/lib/planning/events/PlannerEvent';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import anylogger from 'anylogger';
import { CrossSectionPlane } from '@/lib/planning/cross-section/CrossSectionPlane';
import { HipCrossSectionOutlineNames } from '@/lib/planning/cross-section/types';
import { SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import {
    makeStemCoronalCrossSection,
    updateStemCoronalCrossSection,
} from '@/hipPlanner/assembly/controllers/cross-sections/StemCoronal';
import { makeCupCoronalCrossSection } from '@/hipPlanner/assembly/controllers/cross-sections/CupCoronal';
import {
    makeNeckCutCrossSection,
    updateNeckCutCrossSectionPlane,
} from '@/hipPlanner/assembly/controllers/cross-sections/StemNeckCut';
import { watch } from 'vue';
import HipViewerObjectUtil from '@/hipPlanner/assembly/objects/HipViewerObjectUtil';
import Implant3d from '@/hipPlanner/assembly/objects/Implant3d';
import { HipImplantAlias } from '@/hipPlanner/assembly/objects/HipImplantAlias';
import MaterialUtils from '@/lib/viewer/MaterialUtils';
import { addHandlers } from '@/lib/vue/addHandlers';
import { HipPlannerViewStore } from '@/hipPlanner/stores/plannerView/hipPlannerView';
import {
    FilterByAlias,
    FilterByCrossSectionName,
} from '@/hipPlanner/components/viewer-filter-bar/ViewerFilterItem';
import { VueObserver } from '@/hipPlanner/assembly/controllers/VueObserver';

const log = anylogger('HipCrossSectionPlanes');

export default class HipCrossSectionPlanes extends VueObserver {
    /**
     * Flag to update the stem cross-sections when the assembly is in native configuration
     *
     * Background: The stem cross-sections calculation relies on the assembly being in its native configuration.
     * In the hip planner, there are some user interactions that could leave the cross-sections
     * in a wrong position & orientation, so we need to proceed to update them. Most of the time,
     * these user interactions are done while the assembly is in the native configuration, but there are some
     * that are not:
     * E.g.:
     *   1. Update stem neck variation (combined panel)
     *   2. Update head offset (combined panel)
     *
     * This flag allows to update the cross-section planes when the stem panel is opened again, which means the
     * stem is in the native configuration
     */
    private outdatedStemCrossSections = false;

    constructor(
        private sceneAssembly: SceneAssembly,
        private assembly: HipPlannerAssembly,
        private controller: CrossSectionPlaneController,
        protected templateStore: HipTemplateStore,
        private plannerViewStore: HipPlannerViewStore) {
        super();
        this.addCrossSection(makeCupCoronalCrossSection(assembly, {
            name: HipCrossSectionOutlineNames.CupCoronal,
            models: this.sceneAssembly.hipObjects,
        }));

        this.addWatches(
            watch(
                () => this.templateStore.stemTransform,
                () => {
                    if (this.templateStore.enableStemTransform) {
                        this.onStemUpdated(this.assembly);
                    } else {
                        log.debug('MSP is disabled. Skipping onStemUpdated');
                    }
                },
                { immediate: true, deep: true },
            ),
            watch(
                () => this.plannerViewStore.mode,
                this.onPlannerModeChange.bind(this),
            ),
            addHandlers(plannerEventBus, [
                [PlannerEvent.HipPlannerCupAssemblyCupSet, this.onCupSet.bind(this)],
                [PlannerEvent.HipPlannerCupAssemblyCupSet, this.onCupAssemblyChange.bind(this)],
                [PlannerEvent.HipPlannerCupAssemblyMoved, this.onCupMoved.bind(this)],
                [PlannerEvent.ToggleCrossSection, this.onToggleCrossSection.bind(this)],
                [PlannerEvent.ToggleModelVisibilityByAlias, this.onToggleModelVisibilityByAliasDone.bind(this)],
            ]),
        );
    }

    private onCupSet(): void {
        this.updateAllPlanesOutlines();
    }

    private onCupMoved(): void {
        this.updateAllPlanesOutlines();
    }

    /**
     * Handler to creates cross-sections planes at the right time
     * - Stem neck cut cross-sections are created when on stem mode for the first time
     * - Cup cross-section is created when on cup mode for the first time
     */
    private onPlannerModeChange(): void {
        // Is this needed ??????????????????
        this.updateAllPlanesOutlines();

        // The stem coronal neck is created when the stem group is in native position
        if (this.assembly.isInNativePosition) {
            if (!this.controller.hasPlane(CrossSectionNameEnum.HipStemCoronal)) {
                this.addCrossSection(
                    makeStemCoronalCrossSection(
                        this.assembly,
                        {
                            name: HipCrossSectionOutlineNames.StemCoronal,
                            models: this.sceneAssembly.hipObjects,
                        }));
            } else {
                log.debug('stem-coronal-cross-section plane already created');
            }

            if (!this.controller.hasPlane(CrossSectionNameEnum.OperativeFemurNeckCut)) {
                this.addCrossSection(makeNeckCutCrossSection(this.assembly));
            } else {
                log.debug('stem-neck-cut-cross-section plane already created');
            }

            if (this.outdatedStemCrossSections) {
                log.debug('stem-cross-sections are outdated - proceed to update them.');
                this.onStemUpdated(this.assembly);
            }
        }
    }

    /** Update the neck cut and stem coronal cross-sections */
    public onStemUpdated(_assembly: HipPlannerAssembly): void {
        if (_assembly.isInNativePosition) {
            // when on native position, we are able to update the cross-section planes
            this.updateNeckCutCrossSectionPlane();
            this.updateStemCoronalCrossSectionPlane();
            this.outdatedStemCrossSections = false;
        } else {
            // when not on native position, we flag the cross-section planes to be updated in the future
            // This is probably the result of the following scenarios:
            // 1. Update stem neck variation (combined panel)
            // 2. Update head offset (combined panel)
            log.debug('stem-cross-sections flagged as outdated');
            this.outdatedStemCrossSections = true;
        }
    }

    private updateAllPlanesOutlines(): void {
        this.controller.updateAllPlanesOutlines();
    }

    /**
     * Add the cross-section outline and plane mesh representation to the main scene
     */
    private addCrossSection(plane: CrossSectionPlane): void {
        this.controller.addPlane(plane);

        const scene = this.sceneAssembly.scene;
        scene.add(plane.mesh);
        scene.add(plane.outlineCollection.theObject);
    }

    /** Update the direction and position of the stem coronal cross-section plane */
    private updateStemCoronalCrossSectionPlane() {
        this.controller.onPlane(CrossSectionNameEnum.HipStemCoronal, (plane: CrossSectionPlane) => {
            updateStemCoronalCrossSection(this.assembly, plane);
        });
    }

    /** Update the direction and position of the stem neck cross-section plane */
    private updateNeckCutCrossSectionPlane() {
        this.controller.onPlane(CrossSectionNameEnum.OperativeFemurNeckCut, (plane: CrossSectionPlane) => {
            updateNeckCutCrossSectionPlane(this.assembly, plane);
        });
    }

    /**
     * Handler for toggling cross-section on viewer button cluster
     */
    private onToggleCrossSection(payload: FilterByCrossSectionName): void {
        log.debug(
            'onToggleCrossSection handler called with filter: %s, isActive: %s',
            payload.filter,
            payload.isActive);

        this.updateAllPlanesOutlines();
        this.controller.onPlane(payload.filter, (plane: CrossSectionPlane) => {
            plane.enabled = payload.isActive;
        });
    }

    /**
     * Handler to react to visibility changes applied to model / implants
     *
     * - If model/implants visibility is toggled, outlines should be updated, given the outline should be
     * visible only over visible model/implants
     */
    private onToggleModelVisibilityByAliasDone(payload: FilterByAlias): void {
        log.debug(
            'onToggleModelVisibilityByAliasDone handler called with filter: %s, isActive: %s',
            payload.filter.toString(),
            payload.isActive);
        // Updates all planes outlines
        // TODO Could be specific by cross section and the bone/implant being toggled and be more efficient
        this.updateAllPlanesOutlines();
    }

    /**
     * On cup assembly change, set the material mode of the implants
     *
     * Note: There is no distinction at this point to which of the implants of the assembly the material is applied.
     */
    public onCupAssemblyChange(cupAssembly: HipPlannerAssembly): void {
        log.debug('onCupAssemblyChange handler called');
        HipViewerObjectUtil.onEachImplant3D(cupAssembly, this.addCrossSectionMaterial.bind(this));
    }

    /**
     * Adds the cross-section material to the object based on if cross-sections are available/exist or not
     *
     * Note: Only adds hip coronal, and hip cup coronal cross-sections.
     * Stem neck cut it is not added, given it only applies to the femur, and the femur is never replaced.
     */
    private addCrossSectionMaterial(implant3d: Implant3d) {
        if (this.controller.hasPlane(CrossSectionNameEnum.HipStemCoronal)) {
            this.controller.onPlane(CrossSectionNameEnum.HipStemCoronal, (plane: CrossSectionPlane) => {
                // TODO if implant is a stem, update the cross-section coronal direction
                if (implant3d.alias === HipImplantAlias.Stem) {
                    // TODO: update direction
                } // else, nothing to do
                MaterialUtils.addCrossSectionToAcidMeshMaterial(implant3d, plane);
            });
        }

        if (this.controller.hasPlane(CrossSectionNameEnum.HipCupCoronal)) {
            this.controller.onPlane(CrossSectionNameEnum.HipCupCoronal, (plane: CrossSectionPlane) => {
                MaterialUtils.addCrossSectionToAcidMeshMaterial(implant3d, plane);
            });
        }
    }
}
