import { LinkedRepresentation, LinkUtil, RelationshipType, Uri } from 'semantic-link';

const lastElement = /.*\/([^/]*)$/;

/**
 * This is a reasonably formal but constrained deconstruction of a URI from the server. The
 * general principles of REST mean that the client should not deconstruct URIs and URLs.
 *
 * However this little utility pulls just the last path element off the URL to give a reasonably
 * human readable but techy summary of the URL.
 *
 * @see {@link https://stackoverflow.com/a/6168370}
 */
export class UriDeconstructionUtil {
    /**
     *  Given a URI get the last path element.
     */
    private static pathName(uri?: Uri): string {
        if (uri) {
            const url = document.createElement('a');
            url.href = uri;
            const path = url.pathname;
            return path.replace(lastElement, '$1');
        }
        return '';
    }

    /**
     *  Given a URI get the last number of the path element.
     */
    private static pathLastNumber(uri?: Uri): string {
        if (uri) {
            const url = document.createElement('a');
            url.href = uri;
            const path = url.pathname;
            const lastNumberOfString = path.replace(/.*?(\d+)[^\d]*$/, '$1');
            return lastNumberOfString || '';
        }
        return '';
    }

    /**
     * Given a {@link LinkedRepresentation} get the last path element of the link referred to
     * by the `rel` parameter.
     */
    public static pathNameOfLink(representation: LinkedRepresentation | null | undefined, rel: RelationshipType): string {
        if (representation) {
            const uri = LinkUtil.getUri(representation, rel);
            if (uri) {
                return UriDeconstructionUtil.pathName(uri);
            }
        }
        return '';
    }

    /**
     * Given a {@link LinkedRepresentation} get the last path element of the link referred to
     * by the `rel` parameter.
     */
    public static pathLastNumberOfLink(representation: LinkedRepresentation | null | undefined, rel: RelationshipType): string {
        if (representation) {
            const uri = LinkUtil.getUri(representation, rel);
            if (uri) {
                return UriDeconstructionUtil.pathLastNumber(uri);
            }
        }
        return '';
    }

    public static pathNameOfLinkAsNumber(
        representation: LinkedRepresentation | null | undefined, rel: RelationshipType): number | undefined {
        const name = UriDeconstructionUtil.pathNameOfLink(representation, rel);
        if (name) {
            const value = Number.parseInt(name, 10);
            if (isFinite(value)) {
                return value;
            }
        }
        return undefined;
    }
}
