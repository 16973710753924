
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import QRCode from '@/components/case-plan/actions/QRCode.vue';
    import PrintableQRCode from '@/components/case-plan/actions/PrintableQRCode.vue';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';

    /** This the component in charge of loading the data to display the qr code */
    @Component({ components: { QRCode, PrintableQRCode } })
    export default class QRCodeLoader extends Vue {
        public declare $refs: Vue['$refs'] & { print: Vue };

        /** whether the main v-card/panel is loading data */
        @InjectReactive() private planStore!: PlanReportStore;

        protected async mounted(): Promise<void> {
            // nothing to do
        }

        protected async onPrint(): Promise<void> {
            const localOptions = {
                styles: ['/css/qr-plan.css'],
            };
            await this.$htmlToPaper(this.$refs.print.$el, localOptions);
        }

        /**
         * The QR codes requires the report-measurements to be calculated.
         * It will be present before the plan is completed, but it takes around 1 minute to reach this state.
         */
        protected get isReady(): boolean {
            return this.planStore.initialised && (this.planStore.measurements !== null || !this.planStore.isCreating);
        }
    }
