
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import AppSvg from '@/components/basic/AppSvg.vue';

    @Component({components: {AppNotificationBanner, AppSvg}})
    export default class AppNotificationBanner extends Vue {
        @Prop(String) readonly label: string | undefined;
        @Prop(String) readonly flavour: 'good' | 'bad' | 'warn' | 'info' | undefined;
        @Prop(String) readonly icon: string | undefined;
    }
