import { PerspectiveCamera, WebGLRenderer } from 'three';

export default class BasicViewerUtil {
    /**
     * Resize viewer canvas and adjust the aspect ratio of the camera
     */
    public static resize(containerDom: HTMLElement, renderer: WebGLRenderer, camera: PerspectiveCamera): void {
        const computedStyle = getComputedStyle(containerDom);

        const windowSize = { x: window.innerWidth, y: window.innerHeight };
        if (computedStyle) {
            windowSize.x -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
            windowSize.y -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
        } // else, no computed style and nothing more to do

        // Update the aspect ratio of the camera to match canvas size
        camera.aspect = windowSize.x / windowSize.y;

        // We MUST update the projection matrix, otherwise the aspect ratio will not render correctly
        camera.updateProjectionMatrix();

        // Update canvas size
        renderer.setSize(windowSize.x, windowSize.y);
    }
}
