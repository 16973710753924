import ResourceService from '@/lib/api/ResourceService';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import anylogger from 'anylogger';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import { ApiUtil } from '@/lib/semantic-network';

const log = anylogger('log');
export default class MeasurementsResource implements ResourceService {
    /**
     * Get the measurements from a study
     */
    public static async getStudyMeasurements(
        study: StudyRepresentation, options?: CacheOptions): Promise<MeasurementsRepresentation | null> {
        if (LinkUtil.matches(study, LinkRelation.measurements)) {
            return await ApiUtil.get<MeasurementsRepresentation>(
                study, { rel: LinkRelation.measurements, ...options }) ?? null;
        }

        log.warn('Study does not have measurements');
        return null;
    }
}
