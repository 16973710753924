import { CollectionRepresentation, LinkedRepresentation } from 'semantic-link';

/**
 * Status for notifications (messages) pulling status
 */
export enum NotificationPullStatus {
    Running = 'running',
    Paused = 'paused',
    Cancelled = 'cancelled',
}

export type NotificationPullStatusType =
    NotificationPullStatus.Running
    | NotificationPullStatus.Paused
    | NotificationPullStatus.Cancelled;

/**
 * Severity level of the message
 */
export enum MessageLevel {
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error',
}

export type MessageLevelType = MessageLevel.Info | MessageLevel.Warning | MessageLevel.Error;

export interface MessageRepresentation extends LinkedRepresentation {
    message?: string;
    /**
     * This timestamp is an ISO-8601 timestamp of the form:
     *     "2019-12-09T01:50:36.756542+00:00"
     */
    timestamp?: string;
    level?: MessageLevelType;
}

export type MessageCollectionRepresentation = CollectionRepresentation<MessageRepresentation>;
