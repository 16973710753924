
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
    import StudyModelsViewer from '@/components/case-manage/study/StudyModelsViewer.vue';
    import SceneView from '@/lib/scene/SceneView.vue';

    /** * Container for the study 3d viewer */
    @Component({ components: { StudyModelsViewer, SceneView } })
    export default class StudyViewer extends Vue {
        public declare $refs: {
            container: HTMLElement
        };

        @Prop({ required: true })
        value!: StudyRepresentation;

        private viewerContainer: HTMLElement | null = null;

        protected async created(): Promise<void> {
            /**
             * Need to wait until next render loop to guarantee that the $refs exist
             * https://www.raymondcamden.com/2019/02/22/what-is-nexttick-in-vue-and-when-you-need-it
             */
            this.$nextTick(() => {
                this.viewerContainer = this.$refs.container;
            });
        }
    }
