import { camelCase } from 'lodash';
import ComponentSelectorRepresentationUtil from '@/lib/api/ComponentSelectorRepresentationUtil';

/** mapping of stem type to display strings */
const STEM_TYPE_NAMES: { [id: string]: string } = {
    fullProfile: 'Full',
    reducedDistal: 'Reduced',
    microplasty: 'Microplasty',
    collarless: 'Collarless',
    collared: 'Collared',
};

const STEM_SYSTEM_NAMES: { [id: string]: string } = {
    taperlocComplete: 'Taperloc',
    avenirComplete: 'Avenir',
};

export default class CatalogStemFormattingUtils {
    /**
     * The 'shape' is the first part of the type
     * E.g: Reduced Distal[High Offset] -> shape: Reduced Distal
     */
    public static formattedShape(componentType: string): string {
        const shape = ComponentSelectorRepresentationUtil.getStemShapeFromStemType(componentType);
        return STEM_TYPE_NAMES[camelCase(shape)];
    }

    public static formattedSystem(componentSystem: string): string {
        return STEM_SYSTEM_NAMES[camelCase(componentSystem)];
    }
}
