import { PatientRepresentation } from '@/lib/api/representation/PatientRepresentation';

export class PatientUtil {
    /**
     * @return true if the basic data of the patient is completed (sex, date of birth, and family, given name)
     */
    public static arePatientBasicDetailsCompleted(patient: PatientRepresentation): boolean {
        if (patient) {
            if (patient.sex &&
                patient.birth_date &&
                patient.name?.family &&
                patient.name?.given) {
                return true;
            }
        }

        return false;
    }
}
