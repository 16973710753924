import { defineStore } from 'pinia';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import LandmarksResource from '@/lib/api/resource/case/LandmarksResource';
import assert from 'assert';
import { Landmark, LandmarkGroup, StudyLandmarksState } from '@/stores/studyLandmarks/types';
import { LandmarkRepresentation } from '@/lib/api/representation/base/landmark/LandmarkRepresentation';
import { StudyLandmarkUtil } from '@/lib/api/representation/case/study/landmarks/StudyLandmarkUtil';

export const useStudyLandmarksStore = defineStore('studyLandmarks', {
    state: (): StudyLandmarksState => {
        return {
            landmarks: null,
        };
    },
    getters: {
    },
    actions: {
        async load(study: StudyRepresentation): Promise<void> {
            const studyLandmarks = await LandmarksResource.getStudyLandmarks(study, this.$apiOptions);

            assert.ok(!!studyLandmarks, 'cannot load study landmarks');

            this.landmarks = studyLandmarks;
        },
        getLandmark(groupName: LandmarkGroup, landmarkName: Landmark): LandmarkRepresentation {
            assert.ok(!!this.landmarks, 'no landmarks');

            const landmark = StudyLandmarkUtil.getLandmark(
                this.landmarks, groupName.toString(), landmarkName.toString()
            );

            assert.ok(!!landmark, 'landmark not found');

            return landmark;
        },
    },
});

export type StudyLandmarksStore = ReturnType<typeof useStudyLandmarksStore>;
