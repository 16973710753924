
    import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

    @Component
    export default class AppInputDate extends Vue {
        @VModel({type: String}) input!: string;
        @Prop(String) readonly placeholder?: string;
        @Prop(String) readonly error?: string;
        @Prop(Boolean) readonly disabled?: boolean;
    }
