import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import CaseResource from '@/lib/api/resource/case/CaseResource';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import PatientResource from '@/lib/api/resource/case/PatientResource';
import { ApiUtil } from '@/lib/semantic-network';
import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
import PlanResource from '@/lib/api/resource/case/plan/PlanResource';
import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';

/**
 * A top level page with a set of v-cards with plans for the case (project).
 *
 * Each project will have zero or more plans. After the surgical details and the
 * DICOM have been configured, segmentation and other processing is done that will
 * create an initial 'ACID' plan with the automated plan. The surgeon can then modify
 * the parameters of the case to approve additional plans.
 *
 * Note: This component is responsible for loading data. Other than the skeleton loader it has no
 * visible elements. The task of refreshing and keeping the list of plans up to date is the responsibility
 * of the child {@link Plans} component.
 */
export default class ProjectStoreService {
    constructor(
        private readonly _$apiResource: ApiRepresentation,
        private _$apiOptions?: CacheOptions) {
    }

    public async getProject<T extends CaseRepresentation>(caseUri: string): Promise<T | null> {
        return CaseResource.getCaseByUri(this._$apiResource, caseUri, { ...this._$apiOptions, forceLoad: true });
    }

    /**
     * Resources from the project:
     * project -> surgeon
     *         -> patient
     *         -> active study
     *         -> automated surgical template -> plan
     *         -> manual surgical template -> plan
     */
    public async getInitialProjectData(project: CaseRepresentation): Promise<CaseRepresentation> {
        const apiOptions = { ...this._$apiOptions, forceLoad: true };

        const _activeStudy = await CaseStudyResource.getActiveStudy(project, apiOptions);
        const _patient = await PatientResource.getCasePatient(project, apiOptions);
        const _surgeon = await ApiUtil.get<UserRepresentation>(
            project, { rel: LinkRelation.surgeon, ...apiOptions });

        const automatedSurgicalTemplate = await SurgicalTemplateResource.getCaseAutomatedSurgicalTemplate(
            project, apiOptions);
        if (automatedSurgicalTemplate) {
            const _acidPlan = await PlanResource.getSurgicalTemplatePlan(automatedSurgicalTemplate, project, apiOptions);
        } // else the project has no 'automated' surgical template so there won't be a plan.

        const manualSurgicalTemplate = await SurgicalTemplateResource.getCaseUserSurgicalTemplate(project, apiOptions);
        if (manualSurgicalTemplate) {
            const _userPlan = await PlanResource.getSurgicalTemplatePlan(
                manualSurgicalTemplate, project, apiOptions);
        } // else the project has no 'manual' surgical template so there won't be a plan.

        return project;
    }
}
