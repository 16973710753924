import CameraMan, { HipCameraPresetEnum } from '@/lib/planning/camera/CameraMan';
import { Vector3 } from 'three';
import AcidObject3d from '@/lib/planning/objects-3D/AcidObject3d';
import { cupWorldPosition } from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import assert from 'assert';
import { HipStemRepresentation } from '@/lib/api/representation/case/hip/HipStemRepresentation';
import { HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import { asVector3 } from '@/lib/base/ThreeUtil';
import LPS from '@/lib/base/LPS';

export const DEFAULT_CAMERA_DISTANCE = 450;

/** Default camera offset direction from look-at-target is to the anterior,
 * so the camera will look in an AP direction **/
export const DEFAULT_CAMERA_DIRECTION = LPS.Anterior;

export default class CameraManUtil {
    public static moveToObject(
        cameraMan: CameraMan,
        object3D: AcidObject3d,
        distance: number = DEFAULT_CAMERA_DISTANCE,
        direction: Vector3 = DEFAULT_CAMERA_DIRECTION): void {
        const presetName = `${object3D.name}-default`;

        // Center position of the object
        // When getting the bounding sphere center we will always
        // get the object's center of mass position
        const lookAt = object3D.getWorldBoundingSphereCenter() || new Vector3();

        cameraMan.addPreset(presetName, lookAt, {
            direction: direction.clone(),
            distance,
        });

        cameraMan.moveTo(presetName, distance);
    }

    /** Set up the Initial, Stem, Cup and Combined camera presents */
    public static setupInitialView(
        cameraMan: CameraMan,
        sceneOrigin: Vector3): void {
        cameraMan.addPreset(HipCameraPresetEnum.InitialAP, sceneOrigin, { direction: LPS.Anterior });
        cameraMan.moveTo(HipCameraPresetEnum.InitialAP);
    }

    /** Set up the Initial, Stem, Cup and Combined camera presents */
    public static setupHipCameraPresets(
        cameraMan: CameraMan,
        assembly: HipPlannerAssembly): void {
        CameraManUtil._setupStemAnteriorPosterior(cameraMan, assembly);
        CameraManUtil._setupCupAnteriorPosterior(cameraMan, assembly);
        CameraManUtil._setupCombinedModeCamera(cameraMan, assembly);
        CameraManUtil._setupAxialView(cameraMan, assembly);
        CameraManUtil._setupCoronalView(cameraMan, assembly);
        CameraManUtil._setupLateralView(cameraMan, assembly);

    }

    private static _setupStemAnteriorPosterior(
        cameraMan: CameraMan, assembly: HipPlannerAssembly): void {
        const stemBucket: HipStemRepresentation = assembly.stem.getCaseComponent();
        assert.ok(stemBucket, 'stem bucket is not defined');
        assert.ok(stemBucket.pa_axis, 'stem bucket pa_axis is not defined');

        const stemAnterior = asVector3(stemBucket.pa_axis).negate();
        cameraMan.addPreset(
            HipCameraPresetEnum.StemAP,
            assembly.stemGroup.worldPosition, {
                direction: stemAnterior,
            },
        );
    }

    private static _setupCupAnteriorPosterior(
        cameraMan: CameraMan, assembly: HipPlannerAssembly): void {
        const cupBucket: HipCupRepresentation = assembly.cup.getCaseComponent();
        assert.ok(cupBucket, 'cup bucket is not defined');
        assert.ok(cupBucket.ap_vector, 'cup bucket ap_vector is not defined');

        const cupAnterior = asVector3(cupBucket.ap_vector).negate();

        cameraMan.addPreset(
            HipCameraPresetEnum.CupAP,
            cupWorldPosition(assembly), {
                direction: cupAnterior,
            },
        );
    }

    private static _setupCombinedModeCamera(cameraMan: CameraMan, assembly: HipPlannerAssembly): void {
        // TODO: ByeByeSceneTransform
        const DEFAULT_COMBINED_DIRECTION = LPS.Anterior;
        const DEFAULT_COMBINED_VIEW_DISTANCE = 300;

        cameraMan.addPreset(
            HipCameraPresetEnum.Combined,
            cupWorldPosition(assembly), {
                direction: DEFAULT_COMBINED_DIRECTION,
                distance: DEFAULT_COMBINED_VIEW_DISTANCE,
            },
        );
    }

    private static _setupAxialView(cameraMan: CameraMan, assembly: HipPlannerAssembly): void {

        const DEFAULT_STEM_AXIAL_DIRECTION = LPS.Superior;
        const DEFAULT_STEM_AXIAL_VIEW_DISTANCE = 300;

        // Normal is the CT coordinates Superior direction
        // Up is defined as posterior so when you rotate
        // from a front facing view (default) the camera
        // does not need to flip
        cameraMan.addPreset(
            HipCameraPresetEnum.Axial,
            assembly.stemGroup.worldPosition, {
                direction: DEFAULT_STEM_AXIAL_DIRECTION,
                distance: DEFAULT_STEM_AXIAL_VIEW_DISTANCE,
                up: LPS.Posterior,
            },
        );
    }

    private static _setupCoronalView(cameraMan: CameraMan, assembly: HipPlannerAssembly): void {

        const DEFAULT_CORONAL_DIRECTION = LPS.Anterior;
        const DEFAULT_CORONAL_VIEW_DISTANCE = 400;

        // Normal is the CT coordinates Anterior direction
        // Up is defined CT coordinates Superior direction

        cameraMan.addPreset(
            HipCameraPresetEnum.Coronal,
            assembly.stemGroup.worldPosition, {
                direction: DEFAULT_CORONAL_DIRECTION,
                distance: DEFAULT_CORONAL_VIEW_DISTANCE,
                up: LPS.Superior,
            },
        );
    }

    private static _setupLateralView(cameraMan: CameraMan, assembly: HipPlannerAssembly): void {

        const DEFAULT_LATERAL_DIRECTION = LPS.lateral(assembly.side);
        const DEFAULT_LATERAL_VIEW_DISTANCE = 400;

        // Normal is the lateral direction
        // Up is defined CT coordinates Superior direction

        cameraMan.addPreset(
            HipCameraPresetEnum.Lateral,
            assembly.stemGroup.worldPosition, {
                direction: DEFAULT_LATERAL_DIRECTION,
                distance: DEFAULT_LATERAL_VIEW_DISTANCE,
                up: LPS.Superior,
            },
        );
    }
}
