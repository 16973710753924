import { makeAbsolute } from './UriMapping';
import { Route } from 'vue-router';

/**
 * Resolves the short-form in-browser-location Api Uri to CurrentUri on the component.
 *
 * Note: each component explicitly must add a 'props' to the component.vue. We then get the
 *       property on `this.apiUri`.
 *
 *  @example:
 *
 *  export default {
 *     props: {
 *         apiUri: {type: String}
 *     },
 *     methods: {
 *         goHome(){
 *            this.$router.push(toSitePath(this.apiUri, '/home/a/'));
 *         }
 *    }
 *  }
 *
 *  Then it can be used in a component:
 *
 *  @example
 *
 *      this.$router.push(toSitePath(this.currentUri, '/about/a/'));
 *    --> #/home/a/tenant/4
 *
 *  Note: you can use the router internal mechanism resolve the view but it will URL encode
 *  the URI. The recommended solution is to use above. See https://github.com/vuejs/vue-router/issues/787
 *
 *  @example
 *
 *      this.$router.push({ name: 'About', params: { apiUri: makeRelative(this.currentUri) }});
 *    --> #/home/a/tenant%2F4
 *
 *  TODO: we could clean this up and centralise the mapping between a view name and site prefix
 *        eg Home --> /home/a/
 *
 * @param {Route} route vue router
 * @return {{apiUri: string}} absolute uri of the current api
 */
export function resolve(route: Route): { apiUri: string } | undefined {
    if (route.params.apiUri) {
        return { apiUri: makeAbsolute(route.params.apiUri) };
    }
}

/**
 * Basic construction of the prefix for client-side of the uri that pivots on the '#' and the api uri
 *
 * @example https://example.com/#/todo/a/tenant/1
 *
 * We need to construct the `/todo/a/` part being the client prefix. The input is simply the unique
 * client-side routing, in this case
 * 'todo'. In other cases of nested resources it will be more complex (eg `tenant/todo`)
 */
export function makePrefix(clientPath: string): string {
    return `${clientPath}/a/`;
}

/**
 * Basic construction of routing path that concatenates the client-side view state with a wildcard that allows
 * us to get access to the api uri.
 *
 * @example https://example.com/#/todo/a/tenant/1
 *
 * We need to construct a `/todo/a/:apiUri(.*)` so that routing matches the client-side component and then passes in the
 * api uri (in this case `tenant/1` which is in turn mapped back the absolute api uri {@link resolve})
 */
export function makePath(clientPath: string): string {
    return `${makePrefix(clientPath)}:apiUri(.*)`;
}
