import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { areEqualTransforms, RigidTransformRepresentation } from '@/lib/api/representation/geometry/transforms';
import { all, equals } from 'ramda';
import { LinkUtil, RelationshipType } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import { formatArrayNumber } from '@/lib/filters/format/formatArrayNumber';

/** Options for comparing surgical template-properties */
export type TemplatePropertyOptions = {
    /** Include stem-related properties, including stem-transform. Defaults to true. */
    includeStemProperties?: boolean

    /** Include the stem-transform property. Defaults to true */
    includeStemTransform?: boolean
};

const defaultOptions: TemplatePropertyOptions = {
    includeStemProperties: true,
    includeStemTransform: true,
};

/**
 * Check whether values of properties in the given templates are equal
 *
 * @return true if there are any properties that are not equal.
 * */
export function templatesAreEqual(
    template1: HipSurgicalTemplateRepresentation,
    template2: HipSurgicalTemplateRepresentation,
    options?: TemplatePropertyOptions,
): boolean {
    return templatePropertiesAreEqual(
        template1, template2, makeTemplateProperties({ ...defaultOptions, ...options })
    );
}

/**
 * Format each difference between properties of the given templates into a string.
 *
 * @return a string for each difference in properties.
 * */
export function formatTemplateDifferences(
    template1: HipSurgicalTemplateRepresentation,
    template2: HipSurgicalTemplateRepresentation,
    options: TemplatePropertyOptions,
): string[] {
    return formatTemplatePropertyDifferences(
        template1, template2, makeTemplateProperties({ ...defaultOptions, ...options })
    );
}

// -----------------------------------------------------------------------------------------------------

/** Tolerance for difference between floating-point components */
const TOLERANCE = 0.01;

type PropertiesAreEqual = (
    template1: HipSurgicalTemplateRepresentation,
    template2: HipSurgicalTemplateRepresentation,
) => boolean;

type TemplateProperty = {
    name: string,
    areEqual: PropertiesAreEqual,
    formatValue: (template1: HipSurgicalTemplateRepresentation) => string
}

type GetTemplateValue<T> = (template: HipSurgicalTemplateRepresentation) => T | null | undefined;

function isNull<T>(value: T | null | undefined): value is null | undefined {
    return value === null || value === undefined;
}

function isNotNull<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
}

function areEqualOrBothNull<T>(
    value1: T | null | undefined,
    value2: T | null | undefined,
    areEqual: (value1: T, value2: T) => boolean,
): boolean {
    if (isNull(value1)) {
        return isNull(value2);
    } else if (isNull(value2)) {
        return false;
    } else {
        return areEqual(value1, value2);
    }
}

function formatNullable<T>(
    value: T | null | undefined,
    format: (value: T) => string = value => String(value),
): string {
    if (value === null) {
        return 'null';
    } else if (value === undefined) {
        return 'undefined';
    } else {
        return format(value);
    }
}

function transformProperty(
    name: string,
    getValue: GetTemplateValue<RigidTransformRepresentation>,
): TemplateProperty {
    return {
        name,
        areEqual: (template1, template2) => areEqualOrBothNull(
            getValue(template1), getValue(template2), areEqualTransforms,
        ),
        formatValue: (template) => {
            const value = getValue(template);
            return formatNullable(value?.matrix, formatArrayNumber);
        },
    };
}

function numberProperty(
    name: string,
    getValue: GetTemplateValue<number>,
): TemplateProperty {
    return {
        name,
        areEqual: (template1, template2) => areEqualOrBothNull(getValue(template1), getValue(template2), equals),
        formatValue: (template) => {
            const value = getValue(template);
            return formatNullable(value);
        },
    };
}

function floatProperty(
    name: string,
    getValue: GetTemplateValue<number>,
    tolerance: number = TOLERANCE): TemplateProperty {
    return {
        name,
        areEqual: (template1, template2) => areEqualOrBothNull(
            getValue(template1),
            getValue(template2),
            (v1, v2) => Math.abs(v1 - v2) < tolerance,
        ),
        formatValue: (template) => {
            const value = getValue(template);
            return formatNullable(value);
        },
    };
}

function vector3Property(
    name: string,
    getValue: GetTemplateValue<[number, number, number]>,
    tolerance: number = TOLERANCE): TemplateProperty {
    return {
        name,
        areEqual: (template1, template2) => areEqualOrBothNull(
            getValue(template1),
            getValue(template2),
            (v1, v2) => all(
                i => Math.abs(v1[i] - v2[i]) < tolerance,
                [0, 1, 2],
            ),
        ),
        formatValue: (template) => {
            const value = getValue(template);
            return formatNullable(value);
        },
    };
}

function uriProperty(name: string, relation: RelationshipType): TemplateProperty {
    return {
        name,
        areEqual: (template1, template2) => areEqualOrBothNull(
            LinkUtil.getUri(template1, relation), LinkUtil.getUri(template2, relation), equals,
        ),
        formatValue: (template) => {
            const value = LinkUtil.getUri(template, relation);
            return formatNullable(value);
        },
    };
}

const targetLegLength = numberProperty('target-leg-length-change', t => t.target_leg_length_change);
const targetOffset = numberProperty('target-offset-change', t => t.target_offset_change);
const stem = uriProperty('stem', LinkRelation.hipStemComponent);
const stemTransform = transformProperty('stem-transform', t => t.stem_transform);
const headOffset = numberProperty('head-offset', t => t.head?.offset);
const cup = uriProperty('cup', LinkRelation.hipCupComponent);
const liner = uriProperty('liner', LinkRelation.hipCupLinerComponent);
const cupAnteversion = floatProperty('cup-anteversion', t => t.cup_rotation.anteversion);
const cupInclination = floatProperty('cup-inclination', t => t.cup_rotation.inclination);
const cupOffset = vector3Property('cup-offset', t => {
    const { si, ml, ap } = t.cup_offset;
    if (si && ml && ap) {
        return [ml, ap, si];
    } else {
        return null;
    }
});

const targetProperties = [targetLegLength, targetOffset];
const cupProperties = [
    cup,
    liner,
    cupAnteversion,
    cupInclination,
    cupOffset,
];

/* Make a set of template-properties given some options */
function makeTemplateProperties(options: TemplatePropertyOptions): TemplateProperty[] {
    const properties = [
        ...targetProperties,
        ...cupProperties,
    ];
    if (options.includeStemProperties) {
        properties.push(stem, headOffset);
        if (options.includeStemTransform) {
            properties.push(stem, stemTransform);
        }
    }
    return properties;
}

function templatePropertiesAreEqual(
    template1: HipSurgicalTemplateRepresentation,
    template2: HipSurgicalTemplateRepresentation,
    properties: TemplateProperty[]): boolean {
    return all(p => p.areEqual(template1, template2), properties);
}

function formatTemplatePropertyDifferences(
    template1: HipSurgicalTemplateRepresentation,
    template2: HipSurgicalTemplateRepresentation,
    properties: TemplateProperty[]): string[] {
    return properties.map(property => {
        if (property.areEqual(template1, template2)) {
            return null;
        } else {
            return `${property.name}: ` +
                `${property.formatValue(template1)} does not equal ${property.formatValue(template2)}`;
        }
    }).filter(isNotNull);
}
