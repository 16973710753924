import { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import RequestQueue from '@/lib/http/RequestQueue';
import MessageBus from '@/lib/http/MessageBus';
import { format } from 'auth-header';
import _ from 'lodash';
import WwwAuthenticateUtil from '@/lib/http/WwwAuthenticateUtil';
import AuthenticationUtils, { ResourceAuthenticationScheme } from '@/lib/api/resource/auth/AuthenticationUtils';
import { RefreshTokenRepresentation } from '@/lib/api/representation/RefreshTokenRepresentation';

import anylogger from 'anylogger';

const log = anylogger('AuthenticatorService');

/**
 * Name of the Authorization header.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization
 * @type {string}
 */
export const AUTHORIZATION_HEADER = 'Authorization';

/**
 * We are using JSON Web Token (JWT and not Java Web Tokens) authentication.
 *
 * @see {@link https://tools.ietf.org/html/rfc7235}
 * @example www-authenticate: JSONWebToken realm="api", uri=http://example.com/authenticate/jwt
 */
export const AuthenticationScheme = 'Bearer';

export enum AuthRevokeReason {
    Inactivity = 'inactivity',
    LogOut = 'log-out',
}

export type AuthRevokeReasonType = {
    reason: AuthRevokeReason.Inactivity | AuthRevokeReason.LogOut
}

export class AuthenticatorEvent {
    /**
     * HTTP response 401 not authorised. This event should be triggered when an http call returns a 401 response.
     * This should be handled with on-demand authentication and then the original request retried. Any requests
     * in between are to be queued in the meantime.
     *
     * @type {string}
     */
    public static readonly authRequired = 'event:auth-required';

    /**
     * HTTP response 401 not authorised. This event should be triggered once the user has
     * made successfully authenticated.
     *
     * Note: This is an event that the {@link AuthenticatorService} receives from the UI. The various
     * components in the system should listen for the {@link AuthenticatorEvent.authenticated} event.
     */
    public static readonly authConfirmed = 'event:auth-confirmed';

    /**
     * An event that indicates the user is likely to be authenticated and attempts to get resources
     * will be done using the users identity.
     *
     * What this means for the UI is that per-user information should now be obtained or refreshed.
     *
     * Note: This event will be generated when a user changes from a non-authenticated state to an
     * authenticated state. This may be because they have provided their credentials through the login
     * dialog, or it may be because a refresh token has been used to create the initial refresh access.
     */
    public static readonly authenticated = 'event:authenticated';

    /**
     * Revoke authentication credentials (tokens). This is equivalent to the
     * user concept of 'logging out'.
     */
    public static readonly authRevoke = 'event:auth-revoke';
}

export enum State {
    /**
     * Initial state where it is unclear if the user is authenticated or not. Until the 'access' token
     * is used it is unclear if the user is authenticated.
     */
    Unknown = 0,
    /**
     * The http requests are running without an issue. This is somewhat of an
     * optimistic state that is deemed to exist until a not-authenticated
     * event is received.
     */
    Authenticated = 10,

    /**
     * The user is clearly not authenticated. i.e. no refresh token, no access token.  This will come about
     * if the user revokes the tokens with the server, or the client just discards the tokens.
     */
    Unauthenticated = 20,
}

/** The expiration time can be null when the user is not authenticated. */
export type ExpirationTime = number | null;

const REFRESH_TOKEN_KEY = 'refreshToken';
const EXPIRATION_TIME_KEY = '_expirationTime';
/**
 * Support for storing a refresh token in local storage.
 */
export class AuthenticationStorage {
    public static getRefreshToken(): string {
        return localStorage.getItem(REFRESH_TOKEN_KEY) || '';
    }

    public static setRefreshToken(refreshToken: string): void {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    }

    public static clearRefreshToken(): void {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    public static getExpirationTime(): ExpirationTime {
        const value = localStorage.getItem(EXPIRATION_TIME_KEY);
        return value ? parseInt(value, 10) : null;
    }

    public static setExpirationTime(value: number): void {
        localStorage.setItem(EXPIRATION_TIME_KEY, value.toString());
    }

    public static clearExpirationTime(): void {
        localStorage.removeItem(EXPIRATION_TIME_KEY);
    }
}

export default class AuthenticatorService {
    private axios: AxiosInstance;

    /** Hold queue of requests while authentication is being performed */
    private queue: RequestQueue;

    private eventBus: MessageBus;
    private state: State = State.Unknown;

    /**
     * A JWT authentication token to attach to all requests
     *
     * TODO: Limit the token to a single host to avoid accidental exposure.
     * TODO: Limit the token to a single host to avoid accidental exposure.
     * TODO: Limit the token to a single host to avoid accidental exposure.
     * TODO: Limit the token to a single host to avoid accidental exposure.
     * TODO: Limit the token to a single host to avoid accidental exposure.
     */
    private authenticationToken = '';

    constructor(axios: AxiosInstance, eventBus: MessageBus, que?: RequestQueue) {
        this.axios = axios;
        this.eventBus = eventBus;
        this.queue = que || new RequestQueue(axios);

        const _responseIndex = this.axios.interceptors.response.use(
            (response) => response,
            (error) => this.onResponseRejected(error));

        const _requestIndex = this.axios.interceptors.request.use(
            (config) => this.onRequest(config),
            (error) => this.onRequestRejected(error));

        // the event handling the login MUST then trigger this event to be caught
        // eg EventBus.$emit(authConfirmed)
        this.eventBus.$on(
            AuthenticatorEvent.authConfirmed,
            (message, _args) => this.onAuthComplete(message as unknown as RefreshTokenRepresentation));

        this.eventBus.$on(AuthenticatorEvent.authRevoke,
            (message, _args) => this.onAuthRevoke(message as unknown as AuthRevokeReasonType));
    }

    public getAuthToken(): string {
        return this.authenticationToken;
    }

    /**
     * An event from the application (login dialog) that allows the authenticator to resume
     * issuing web requests (and re-issue outstanding requests).
     *
     * The application must provide a new authentication token.
     */
    private onAuthComplete(message: RefreshTokenRepresentation): void {
        if (message) {
            if (message.refresh) {
                AuthenticationStorage.setRefreshToken(message.refresh);
            }
            if (message.access) {
                this.authenticationToken = message.access;
            } else {
                log.error('No access token');
            }
        } else {
            log.warn('%s event received without a token', AuthenticatorEvent.authConfirmed);
        }
        switch (this.state) {
            case State.Unknown:
            case State.Unauthenticated:
                log.debug('[Authentication] login confirmed (http-interceptor)');
                this.state = State.Authenticated;
                this.eventBus.$emit(AuthenticatorEvent.authenticated);

                this.queue.retryAll()
                    .then(() => {
                        log.info('Post-auth queue resubmitted');
                    })
                    .catch((err) => {
                        return log.warn('Failed to re-submit requests post authentication: %s', err);
                    });
                break;
            case State.Authenticated:
                log.warn('Auth complete event unexpected: Authenticator in state %s', this.state);
                break;
            default:
                log.error('Unexpected authentication state %s', this.state);
        }
    }

    private onAuthRevoke(payload: AuthRevokeReasonType): void {
        log.info('Authentication tokens revoked/discarded.');

        this.authenticationToken = '';
        AuthenticationStorage.clearRefreshToken();
    }

    /**
     * Interceptor method for outgoing requests. This will add the 'Bearer' JWT token
     * to the request (iff it is valid/available).
     */
    private onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
        if (this.authenticationToken) {
            config.withCredentials = true;
            if (config.headers) {
                config.headers[AUTHORIZATION_HEADER] = format({
                    scheme: AuthenticationScheme,
                    token: this.authenticationToken,
                });
            } else {
                log.error('No headers');
            }
        }
        return config;
    }

    /**
     * Interceptor method for outgoing request errors. Nothing to do.
     */
    private onRequestRejected(error: unknown): Promise<unknown> {
        return Promise.reject(error);
    }

    /**
     * Interceptor method for responses to outgoing requests. If the request results
     * in a 401 error then reauthenticate the client.
     */
    private onResponseRejected(error: AxiosError): Promise<never> | AxiosPromise {
        if (error.response && error.response.status === 401) {
            const tokens = WwwAuthenticateUtil.wwwAuthenticateHeaders(error.response.headers);
            log.debug('Request %s %s not authenticated. Supported methods %o', error.config.method, error.config.url, tokens);
            if (!_(tokens).isEmpty()) {
                // Queue this request for later processing.
                const p = this.queue.pushAsPromise(error.config);
                const resourceAuthentication = AuthenticationUtils.getResourceScheme(tokens);
                if (resourceAuthentication) {
                    this.handleNotAuthenticatedEvent(resourceAuthentication, error);
                } else {
                    log.warn('Failed to decode resource authentication scheme');
                }
                return p;
            } else {
                log.warn('Not authenticated, but no authentication schemes found');
                return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
    }

    private handleNotAuthenticatedEvent(resourceAuthentication: ResourceAuthenticationScheme, error: AxiosError): void {
        switch (this.state) {
            case State.Unknown:
            case State.Authenticated: {
                this.state = State.Unauthenticated;

                // clear the access token, as it has stopped working.
                this.authenticationToken = '';

                const refreshToken = AuthenticationStorage.getRefreshToken();
                if (refreshToken) {
                    AuthenticationUtils.createAccessToken(this.axios, resourceAuthentication.uri, refreshToken)
                        .then((token: RefreshTokenRepresentation | undefined) => {
                            if (token) {
                                this.onAuthComplete(token);
                            } else {
                                log.info('Failed to get access token, getting a new refresh token');
                                this.emitOpenLoginDialog(resourceAuthentication, error);
                            }
                        })
                        .catch((err) => {
                            log.info('Failed to get access token, getting a new refresh token: %s', err);
                            this.emitOpenLoginDialog(resourceAuthentication, error);
                        });
                } else {
                    // this event starts the process of logging in and MUST be handled
                    log.info('No refresh token, getting a new one');
                    this.emitOpenLoginDialog(resourceAuthentication, error);
                }
                break;
            }
            case State.Unauthenticated:
                log.debug('Unauthenticated request queued');
                break;
            default:
                log.error('Unsupported authentication state');
        }
    }

    private emitOpenLoginDialog(resourceAuthentication: ResourceAuthenticationScheme, error: AxiosError): void {
        this.eventBus.$emit(AuthenticatorEvent.authRequired, resourceAuthentication, error);
    }
}
