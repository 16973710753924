import { FeedRepresentation } from 'semantic-link';
import ContentType from '@/lib/http/mimetype';

import anylogger from 'anylogger';

const log = anylogger('dragdrop');

type JsonReplacer = (this: unknown, key: string, value: unknown) => unknown;

type OnString = (json: string) => void;

/**
 * Name of a file dragged off the browser if we can't find a title.
 * @type {string}
 */

export type DataTransferItemMapper<T> = (item: DataTransferItem) => T;

/**
 * A {@link FileEntry} and it's materialised {@File}. This is a simple holder
 * pattern to keep the async file result available in a simple synchronous data
 * structure.
 */
export interface FileAndFileEntry {
    /**
     * The file associated with the file entry.
     *
     * @see {@link FileEntry.file}
     */
    file: File;

    /**
     * A file entry.
     */
    entry: FileEntry;
}

/**
 * see
 *   - {@link https://github.com/leonadler/drag-and-drop-across-browsers}
 */
export default class DragDropUtil {
    /**
     * Returns the model out of the dataTransfer in order of:
     *  * application/json
     *  * File (application/json) - first file only
     *
     *  This is an asynchronous version
     *
     * @param {DataTransfer} transfer - drag event
     * @param {string} mediaType
     * @returns {LinkedRepresentation|Object|undefined}
     */
    public static getDragData(transfer: DataTransfer, mediaType: string): Promise<unknown> {
        return new Promise((resolve, reject) => {
            /**
             *
             * Warning on dataTransfer.types
             *
             * @see https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets
             *
             * Note that the latest spec now dictates that DataTransfer.types should return a frozen array
             * of DOMStrings rather than a DOMStringList (this is supported in Firefox 52 and above.
             * As a result, the contains method no longer works on the property; the includes method
             * should be used instead to check if a specific type of data is provided, using code
             * like the following:
             *
             * @example
             *         if ([...event.dataTransfer.types].includes('text/html')) {
             *              // Do something
             *          }
             */

            log.debug('Media type: [%s], types available [%s]', mediaType, [transfer.types].join(','));
            [...transfer.items].forEach((item) => log.debug(`[Drag] item: kind '${item.kind}' type '${item.type}'`));

            if (mediaType === 'application/json' && [...transfer.types].includes(ContentType.Json)) {
                log.debug('[Drop] found: application/json');
                const data = transfer.getData(ContentType.Json);
                return resolve(JSON.parse(data));
            } else if (mediaType === 'text/uri-list') {
                log.debug('[Drop] found: text/uri-list');
                return resolve(transfer.getData('text/uri-list'));
            } else if ([...transfer.items].findIndex((item) =>
                item.kind === 'file' &&
                item.type === mediaType &&
                item.type === ContentType.Json) >= 0) {
                // currently we are only going to take one file
                // TODO: multiple dropped files
                const [file] = [...transfer.files];

                log.debug(`File: "${file.name}" of type ${file.type}`);

                const reader = new FileReader();
                reader.onload = (_event: ProgressEvent) => {
                    log.debug('[Drop] data loaded');
                    if (typeof reader.result === 'string') {
                        return resolve(JSON.parse(reader.result));
                    } else {
                        return reject(new Error('JSON file not a string'));
                    }
                };
                reader.readAsText(file);
            } else if (mediaType === 'text/plain' && [...transfer.types].includes('text/plain')) {
                //
                //  After all the content types and file have been processed, have a few guesses
                //  at what the content is. If it has come from an editor then JSON could be
                //  represented as text. Try parsing it as JSON and proceed if that works.
                //
                log.debug('[Drop] trying text as JSON');
                try {
                    return resolve(JSON.parse(transfer.getData('Text')));
                } catch (e) {
                    log.error('[Drop] error parsing text as JSON');
                    if (e instanceof SyntaxError) {
                        log.error('The content is not valid JSON');
                        return resolve(undefined);
                    } else {
                        log.error('The content type is not JSON and unsupported');
                        return resolve(undefined);
                    }
                }
            } else {
                log.debug(`[Drop] content is an unsupported file/content type: '${mediaType}' in [${[transfer.types].join(',')}]`);
                resolve(undefined);
            }
        });
    }

    /**
     * Used in exploratory work to determine what has been drageed and dropped and what is possible.
     *
     * Each application will present a different set of types, this allows a matching type to
     * be supported.
     */
    public static dumpDropEvent(transfer: DataTransfer | null): DataTransfer | null {
        function logString(index: number, s: string) {
            log.info(
                'item %d: %s',
                index,
                s ? (s.length > 100 ? (s.substring(0, 100) + '...') : s) : '');
        }

        function formatItems(items: DataTransferItemList): string[] {
            function entryType(entry: FileSystemEntry): string {
                if (entry.isFile) {
                    return 'f';
                } else if (entry.isDirectory) {
                    return 'd';
                } else {
                    return '-';
                }
            }

            const result: string[] = [];
            for (let index = 0; index < items.length; ++index) {
                const item = items[index];
                const entry: FileSystemEntry | null = item.webkitGetAsEntry();
                if (item.kind === 'string') {
                    item.getAsString((s) => logString(index, s));
                }
                if (entry) {
                    result.push(`[k:${item.kind}, t:${item.type}, entry: t=${entryType(entry)}, ${entry.name}]`);
                } else {
                    result.push(`[k:${item.kind}, t:${item.type}, no entry]`);
                }
            }
            return result;
        }

        if (transfer) {
            const items = transfer.items;
            const files = transfer.files;
            log.info(
                'Drop %s (%s), %d types [%s], %d items [%s], %d files',
                transfer.dropEffect,
                transfer.effectAllowed,
                transfer.types.length,
                transfer.types.join(', '),
                items.length,
                formatItems(items),
                files.length);
        } else {
            log.warn('No data transfer');
        }
        return transfer;
    }

    // /**
    //  * Sets up a data transfer. Currently works to transfer model as JSON only. Currently offers
    //  * up types:
    //  *
    //  * File (DownloadURL) will name the file on title, description, or default Unnamed (in that order)
    //  *  - text/plain
    //  *  - application/json
    //  *
    //  * TODO: text/csv (for dragging to Excel)
    //  *
    //  * @param {LinkedRepresentation|Object} model
    //  * @param {DragEvent} event
    //  * @param {function(key:string, value:string):string|undefined=} replacerStrategy JSON.stringify replacer function
    //  *         see https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
    //  * @param {?string|string[]} mediaType media types to be included in the {@link DataTransfer} object on drag
    //  */
    // public static setDragData(
    //     model: unknown,
    //     event: DragEvent,
    //     replacerStrategy?: JsonReplacer,
    //     mediaType?: string[]): void {
    //     if (event.dataTransfer) {
    //         const dataTransfer = event.dataTransfer;
    //
    //         const mediaTypes: string[] = mediaType || [ContentType.Json, ContentType.UriList];
    //
    //         /**
    //          * In practice, we are going to want to be able to drag data out to the desktop/folders
    //          * and other applications. To do this, we can't use application/json (although we can
    //          * use text/uri-list). At this stage, default to add plain text forms of the
    //          * representation (which are JSON in plain text).
    //          *
    //          * If this becomes a problem (like with very large files) then this default can be changed.
    //          *
    //          * @see https://stackoverflow.com/questions/19362416/cross-browser-html5-drag-and-drop-json-datatransfer-fails
    //          */
    //         mediaTypes.push('text/plain', 'DownloadUrl');
    //         log.debug(`[Drag] using media types: [${mediaTypes.join(',')}]`);
    //
    //         if (mediaTypes.includes(ContentType.Json)) {
    //             dataTransfer.clearData(ContentType.Json);
    //             log.debug('[Drop] set data application/json');
    //             const data = this.makeJson(model);
    //             dataTransfer.setData(ContentType.Json, data);
    //         }
    //
    //         if (mediaTypes.includes('DownloadUrl')) {
    //             log.debug('[Drop] set data DownloadURL');
    //
    //             /*
    //              * the model should be {@link LinkedRepresentation} so there are search strategy. Title should actually
    //              * be retired.
    //              * TODO: we could use link relation 'self' to get titles, for example
    //              * @type {string}
    //              */
    //             // @ts-ignore
    //             const filename = (model.title || model.name || model.description || _DEFAULT_FILE_NAME) + '.json';
    //
    //             // encode the prettyJson so that carriage returns are not lost
    //             // http://stackoverflow.com/questions/332872/encode-url-in-javascript
    //             // 'DownloadURL' is chrome specific (I think)
    //             // see https://www.html5rocks.com/en/tutorials/casestudies/box_dnd_download/
    //             const jsonUri = encodeURI(this.makePrettyJson(model, replacerStrategy));
    //             dataTransfer.setData(
    //                 'DownloadURL',
    //                 `application/json:${filename}:data:application/json,${jsonUri}`
    //             );
    //         }
    //
    //         if (mediaTypes.includes('text/plain')) {
    //             dataTransfer.setData('text/plain', this.makePrettyJson(model, replacerStrategy));
    //             log.debug('[Drop] set data text/plain');
    //         }
    //
    //         if (mediaTypes.includes('text/uri-list')) {
    //             /**
    //              * Making a uri-list is a subset of the rfc.
    //              *
    //              * DO NOT include any comments. It breaks the {@link DataTransfer} object
    //              */
    //             dataTransfer.setData('text/uri-list', this.makeUriList(model));
    //             log.debug('[Drop] set data text/uri-list');
    //         }
    //     } else {
    //         log.warn('No data transfer object');
    //     }
    // }

    public static setSingleFileDragData(event: DragEvent): void {
        if (event.dataTransfer) {
            const data = new Blob(['Hello'], { type: 'text/plain;charset=utf-8;' });
            const _objectUrl = URL.createObjectURL(data);
            // const item = event.dataTransfer.setData('text/plain', "Hello");
            // const item2 = event.dataTransfer.setData('text', "I was here.");
            const item3 = event.dataTransfer.items.add(new File(
                [new Blob(['this is a sample file'])],
                'bob.pdf',
                { type: ContentType.Pdf }));
            log.info('item: %o', item3);
            log.debug(`[Drag] data drag set for [${[event.dataTransfer.types].join(',')}]`);
        } else {
            log.warn('No data transfer');
        }
    }

    /**
     *  On drag start, load the in-memory model ready to dropped (onto file system) or another element
     * @param {DragEvent} event
     * @param {LinkedRepresentation|Object} model
     * @param {?string|string[]} mediaType types to be attached to the event on drag
     * @return {boolean}
     */
    public static dragstart(event: DragEvent, model: unknown, mediaType?: string[]): void {
        log.debug(`[Drag] start ${mediaType}`);

        /**
         * Drop effect from drag can be set in dragenter and/or dragover
         *
         * You can modify the dropEffect property during the dragenter or dragover events, if
         * for example, a particular drop target only supports certain operations. You can modify
         * the dropEffect property to override the user effect, and enforce a specific drop operation
         * to occur. Note that this effect must be one listed within the effectAllowed property. Otherwise,
         * it will be set to an alternate value that is allowed.
         * @see https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#drageffects
         */
        if (event.dataTransfer) {
            event.dataTransfer.effectAllowed = 'copy';
            event.dataTransfer.dropEffect = 'copy';

            if (event.srcElement instanceof Element) {
                event.srcElement.classList.add('drag');
            } else {
                log.warn('Drag over event source not a DOM element');
            }
            // this.setDragData(model, event, this.defaultReplacer(event), mediaType);
            //
            // if (event.dataTransfer.types.length === 0) {
            //     log.warn('[Drag] drag data was not set');
            // } else {
            //     log.debug(`[Drag] data drag set for [${[event.dataTransfer.types].join(',')}]`);
            // }
        } else {
            log.warn('Drag over event has no data transfer');
        }
    }

    /**
     * On drag end, change the icon effect to remove the 'drag' icon
     */
    public static dragend(event: DragEvent): void {
        if (event.dataTransfer) {
            if (event.dataTransfer.dropEffect === 'none') {
                log.debug('[Drag] canceled');
            } else {
                log.debug(`[Drag] complete ${event.dataTransfer.dropEffect}`);
            }

            // Clear the data from the data transfer as it has been consumed
            event.dataTransfer.clearData();
        } else {
            log.warn('Drag over event has no data transfer');
        }

        if (event.target instanceof Element) {
            event.target.classList.remove('drag');
            event.target.classList.remove('over');
        } else {
            log.warn('Drag over event target not a DOM element');
        }
    }

    /**
     * On drag over, change the icon effect to 'copy' and the target icon to 'over'
     */
    public static dragover(event: DragEvent): boolean {
        log.debug('[Drag] in - over');

        /**
         * Drop effect from drag can be set in dragenter and/or dragover
         *
         * You can modify the dropEffect property during the dragenter or dragover events, if
         * for example, a particular drop target only supports certain operations. You can modify
         * the dropEffect property to override the user effect, and enforce a specific drop operation
         * to occur. Note that this effect must be one listed within the effectAllowed property. Otherwise,
         * it will be set to an alternate value that is allowed.
         * @see https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#drageffects
         */
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'copy';
        } else {
            log.warn('Drag over event has no data transfer');
        }

        if (event.target instanceof Element) {
            event.target.classList.add('over');
        } else {
            log.warn('Drag over event target not a DOM element');
        }

        /**
         * Allows us to drop and we could either return false or event.preventDefault()
         *
         * If you want to allow a drop, you must prevent the default handling by cancelling
         * the event. You can do this either by returning false from an attribute-defined
         * event listener, or by calling the event's preventDefault() method. The latter
         * may be more feasible in a function defined in a separate script.
         *
         * @see https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets
         */
        event.preventDefault();
        // stop event propagation - if a parent context has drag-drop support, then we don't want that context
        // disabling the drop target; or changing the behaviours we accept.
        event.stopPropagation();
        return false;
    }

    /**
     * On drag enter, change the icon effect to 'over' on the target
     */
    public static dragenter(event: DragEvent): boolean {
        log.debug('[Drag] in - enter');

        /**
         * Drop effect from drag can be set in dragenter and/or dragover
         *
         * You can modify the dropEffect property during the dragenter or dragover events, if
         * for example, a particular drop target only supports certain operations. You can modify
         * the dropEffect property to override the user effect, and enforce a specific drop operation
         * to occur. Note that this effect must be one listed within the effectAllowed property. Otherwise,
         * it will be set to an alternate value that is allowed.
         * @see https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#drageffects
         */
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'copy';
        } else {
            log.warn('Drag over event has no data transfer');
        }

        if (event.target instanceof Element) {
            event.target.classList.add('over');
        } else {
            log.warn('Drag over event target not a DOM element');
        }

        /**
         * Allows us to drop and we could either return false or event.preventDefault()
         *
         * If you want to allow a drop, you must prevent the default handling by cancelling
         * the event. You can do this either by returning false from an attribute-defined
         * event listener, or by calling the event's preventDefault() method. The latter
         * may be more feasible in a function defined in a separate script.
         *
         * @see https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets
         */
        event.preventDefault();
        return false;
    }

    /**
     * On drag leave, remove the 'over' icon from the target. No cancel events are required
     */
    public static dragleave(event: DragEvent): void {
        log.debug('[Drag] in - leave');

        if (event.target instanceof Element) {
            event.target.classList.remove('over');
        } else {
            log.warn('Drag over event target not a DOM element');
        }
    }

    /**
     * On dropping, remove the 'over' icon from the target (to make the procees look finished) and then
     * dump the model to the relevant location based on the HTML 5 {@link DataTransfer}
     *
     * This has the side effect of a callback so that the calling ocmponent can get access to the model
     * in the cases that a model has been dropped from the desktop onto the browser.
     *
     * @param {DragEvent} event
     * @param {function(LinkedRepresentation)}cb callback function process the model
     * @param {string?} mediaType='application/json' media type to be returned
     */
    public static drop(event: DragEvent, cb: (result: unknown) => void, mediaType: string): boolean | undefined {
        if (event) {
            mediaType = mediaType || ContentType.Json;

            log.debug(`[Drag] in - drop ${mediaType}`);

            if (event.dataTransfer && event.dataTransfer.items.length !== 0) {
                // Stops some browsers from redirecting.
                event.stopPropagation();

                if (event.target instanceof Element) {
                    event.target.classList.remove('over');
                } else {
                    log.warn('Event target is not a DOM element');
                }

                /**
                 * Accept the drop event (regardless of pass or fail)
                 *
                 * Call the preventDefault() method of the
                 * event if you have accepted the drop so that the default browser
                 * handling does not handle the dropped data as well.
                 *
                 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#drop
                 */
                event.preventDefault();

                this.getDragData(event.dataTransfer, mediaType)
                    .then((result) => {
                        if (result) {
                            cb(result);
                        } else {
                            log.debug(`[Drop] result undefined for ${mediaType}`);
                        }
                    });
                return false;
            } else {
                log.debug(`[Drop] event has no drop data for ${mediaType}`);
            }
        } else {
            log.warn('Undefined event on drop');
        }
    }

    public static async getAllFileEntriesAsFiles(dataTransfer: DataTransfer): Promise<FileAndFileEntry[]> {
        return await Promise.all(
            (await DragDropUtil.getAllFileEntries(dataTransfer))
                .map<Promise<FileAndFileEntry>>(async (fileEntry: FileEntry) => {
                    return {
                        file: await DragDropUtil.getFile(fileEntry),
                        entry: fileEntry,
                    };
                }));
    }

    public static getFile(entry: FileEntry): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            try {
                return entry.file(resolve, reject);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Recursively (and repetitively) enumerate all entries in the data transfer
     * list. Each {@link FileEntry} is returned as-is. {@link DirectoryEntry}s are
     * enumerated to a list of {@link FileEntry}.
     *
     * @see {@link https://protonet.com/blog/html5-experiment-drag-drop-of-folders/}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryReader}
     * @see {@link https://wiki.whatwg.org/wiki/DragAndDropEntries}
     * @see {@link
     *   https://stackoverflow.com/questions/3590058/does-html5-allow-drag-drop-upload-of-folders-or-a-folder-tree}
     */
    public static async getAllFileEntries(dataTransfer: DataTransfer): Promise<FileEntry[]> {
        // Convert the array like items to an actual array.
        const result: FileEntry[][] = await Promise.all(
            Array
                .from<DataTransferItem>(dataTransfer.items)

                // For each entry in the data transfer, recursively and repetitively enumerate
                // each entry.
                .map<Promise<FileEntry[]>>(
                    async (entry: DataTransferItem) => await DragDropUtil.mapFileSystemEntry(entry.webkitGetAsEntry())));

        return result
            // Flatten the array of arrays to a simple array
            .flat(1);
    }

    /**
     * Enumerate an filesystem entry and return an array of FileEntry (with zero or more items)
     */
    private static async mapEntry(anEntry: Entry): Promise<FileEntry[]> {
        if (anEntry.isFile) {
            const fileEntry: FileEntry = anEntry as FileEntry;
            return [fileEntry];
        } else if (anEntry.isDirectory) {
            const directoryEntry: DirectoryEntry = anEntry as DirectoryEntry;
            return await DragDropUtil.readAllDirectoryEntries(directoryEntry.createReader());
        } else {
            log.warn('Unsupported entry');
            return [];
        }
    }

    /**
     * Enumerate an filesystem entry and return an array of FileEntry (with zero or more items)
     */
    private static async mapFileSystemEntry(anEntry: FileSystemEntry | null): Promise<FileEntry[]> {
        if (anEntry?.isFile) {
            const fileEntry: FileEntry = anEntry as FileEntry;
            return [fileEntry];
        } else if (anEntry?.isDirectory) {
            const directoryEntry: DirectoryEntry = anEntry as DirectoryEntry;
            return await DragDropUtil.readAllDirectoryEntries(directoryEntry.createReader());
        } else {
            log.warn('Unsupported entry');
            return [];
        }
    }

    /**
     * Get all the entries (files or sub-directories) in a directory
     * by calling readEntries until it returns empty array
     *
     * e.g. Chrome returns at most 100 entries at a time
     */
    public static async readAllDirectoryEntries(directoryReader: DirectoryReader): Promise<FileEntry[]> {
        const entries: FileEntry[] = [];
        do {
            const readEntries: Entry[] = await DragDropUtil.readEntries(directoryReader);
            if (readEntries.length > 0) {
                const x: FileEntry[][] = await Promise.all(readEntries
                    .map<Promise<FileEntry[]>>(async (anEntry: Entry) => await DragDropUtil.mapEntry(anEntry)));
                entries.push(...(x.flat(1)));
            } else {
                return entries;
            }
            // eslint-disable-next-line no-constant-condition
        } while (true);
    }

    /**
     * Convert readEntries() from a callback to a promise based async method.
     */
    public static async readEntries(directoryReader: DirectoryReader): Promise<Entry[]> {
        return await new Promise((resolve, reject) => {
            try {
                directoryReader.readEntries(resolve, reject);
            } catch (err) {
                reject(err);
            }
        });
    }

    /// ////////////////////////////////////////////////////////////////
    //
    //  Support for various media types
    //
    //  e.g. JSON, uri list, atom like feed representation
    //

    /**
     * Make a uri-list formatted string without comments of the the collection items
     * @param {CollectionRepresentation|string[]} representation
     * @returns {string} uri-list formatted (without comments)
     */
    public static makeUriList(representation: any | string[] | FeedRepresentation) /* TODO: decide on return type */ {
        if (representation instanceof Array) {
            return representation.join('\n');
        } else if ('items' in representation) {
            const _items = representation.items;
            return representation
                .items
                .map((feedItemOrObjectItem: any) => {
                    if ('id' in feedItemOrObjectItem) {
                        return feedItemOrObjectItem.id;
                    } else if ('links' in feedItemOrObjectItem) {
                        return ''; // TODO: return self or canonical link.
                    } else {
                        return '';
                    }
                })
                .join('\n');
        } else if ('links' in representation) {
            return []; // TODO: return self or canonical link.
        } else {
            return [];
        }
    }

    /**
     *
     * @param {string} uriList
     * @returns {string[]}
     */
    public static fromUriList(uriList: string): string[] {
        return uriList
            .split('\n')
            .filter((uri) => !uri.startsWith('#'));
    }

    /**
     * The replacer strategy is a cleanser of object models to across-the-wire representations, such that when
     * we get models out they look close to what they look like when the arrive across-the-wire in the first place.
     * The default removes the edit and create forms links.
     *
     * The default replacer also has hidden functionality that if you hold down th control key, you can get back the
     * in-memory version without replacements. This is useful for debugging.
     *
     * @param event
     * @return {function(key:string, value:string):string|undefined}
     */
    public static defaultReplacer(event: DragEvent): JsonReplacer | undefined {
        if (event.ctrlKey) {
            return undefined;
        }

        return (key, value) => {
            switch (key) {
                case 'createForm':
                case 'editForm':
                    return undefined;
                default:
                    return value;
            }
        };
    }

    public static makeJson(model: any): string {
        // Alert: JSON.stringify can crash the browser on a large model with a complex replacer strategy
        //        If no strategy is handed in then we will use the canonical form
        try {
            return JSON.stringify(model, null, 0);
        } catch (e) {
            log.error(e);
            return JSON.stringify({}, null, 0);
        }
    }

    public static makePrettyJson(model: any, replacer?: JsonReplacer): string {
        try {
            return JSON.stringify(model, replacer, 2);
        } catch (e) {
            log.error(e);
            return JSON.stringify({}, null, 1);
        }
    }

    /**
     * A crass implementation of support for dropping a single JSON file. This is not
     * production ready and not for user facing support.
     */
    public static onSimpleSingleJsonFileDrop(event: DragEvent): Promise<string> {
        log.debug('Drop');

        function fileEntryFromEvent(event: DragEvent): FileEntry {
            if (!event.dataTransfer) {
                throw Error('Expected a data transfer');
            }
            const items = event.dataTransfer.items;
            if (items.length !== 1) {
                throw Error(`Expected one file but got ${items.length} items`);
            }
            const item: DataTransferItem = items[0];
            if (item.kind !== 'file' || item.type !== ContentType.Json) {
                throw Error(`Expected a json file but got item of type '${item.type}' and kind '${item.kind}'`);
            }
            const entry: FileSystemEntry | null = item.webkitGetAsEntry();
            if (!entry?.isFile) {
                throw Error('Could not get item as filesystem entry');
            }
            return entry as FileEntry;
        }

        event.preventDefault();

        return new Promise<string>((resolve, reject) => {
            const fileEntry = fileEntryFromEvent(event);
            log.info('JSON configuration file %s', fileEntry.fullPath);

            fileEntry.file(
                file => {
                    file.text().then(text => {
                        const config = JSON.parse(text);
                        log.info('ok %s', DragDropUtil.makePrettyJson(config));
                        resolve(config);
                    }, reject);
                },
                reject
            );
        });
    }

    /**
     * A trivial implementation of support for drag-n-drop of a spreadsheet in CSV format (tab seperated)
     */
    public static onSimpleSingleCsvDrop(event: DragEvent, onStringContext: OnString): boolean {
        const dataTransfer = event.dataTransfer;
        if (dataTransfer) {
            if (dataTransfer.files.length === 0 && dataTransfer.items.length > 0) {
                if (DragDropUtil.everyItem(dataTransfer.items, (i) => i.kind !== 'file')) {
                    if (DragDropUtil.everyItem(dataTransfer.items, (i) => i.kind === 'string')) {
                        const item = DragDropUtil.firstItem(
                            dataTransfer.items, (i) => i.kind === 'string' && i.type === 'text/plain');
                        if (item) {
                            item.getAsString(s => {
                                if (s) {
                                    return onStringContext(s);
                                }
                                log.warn('String content is empty');
                            });
                        } else {
                            log.warn('Not string content');
                        }
                    } else {
                        log.warn('All content expected to be strings');
                    }
                } else {
                    log.warn('Files not supported');
                }
            } else {
                log.warn('A single content based drop is supported (no files)');
            }
        } else {
            log.error('Expected a data transfer');
        }
        event.preventDefault();
        return false;
    }

    /**
     * The data transfer items collection is not an array (it is array like). This provides a simple thunk
     * to perform an operation on all items.
     */
    public static mapItem<T>(items: DataTransferItemList, mapper: DataTransferItemMapper<T>): T[] {
        const result = [];
        if (items) {
            for (let index = 0; index < items.length; ++index) {
                const item = items[index];
                result.push(mapper(item));
            }
        }
        return result;
    }

    /** Check every item meats the criteria checker condition */
    public static everyItem(items: DataTransferItemList, checker: DataTransferItemMapper<boolean>): boolean {
        if (items) {
            for (let index = 0; index < items.length; ++index) {
                const item = items[index];
                if (!checker(item)) {
                    return false;
                }
            }
        }
        return true;
    }

    /** get the first matching item */
    public static firstItem(
        items: DataTransferItemList,
        matcher: DataTransferItemMapper<boolean>): DataTransferItem | undefined {
        if (items) {
            for (let index = 0; index < items.length; ++index) {
                const item = items[index];
                if (matcher(item)) {
                    return item;
                }
            }
        }
        return undefined;
    }
}
/*
export class FileDataProvider {
    public QueryInterface(iid:nsID) {
        if (iid.equals(Components.interfaces.nsIFlavorDataProvider)
            || iid.equals(Components.interfaces.nsISupports))
            return this;
        throw Components.results.NS_NOINTERFACE;
    }
    getFlavorData(aTransferable, aFlavor, aData, aDataLen) {
        if (aFlavor === 'application/x-moz-file-promise') {

            let urlPrimitive = {};
            let dataSize = {};

            aTransferable.getTransferData('application/x-moz-file-promise-url', urlPrimitive, dataSize);
            var url = urlPrimitive.value.QueryInterface(Components.interfaces.nsISupportsString).data;
            console.log("URL file orignal is = " + url);

            var namePrimitive = {};
            aTransferable.getTransferData('application/x-moz-file-promise-dest-filename', namePrimitive, dataSize);
            var name = namePrimitive.value.QueryInterface(Components.interfaces.nsISupportsString).data;

            console.log("target filename is = " + name);

            var dirPrimitive = {};
            aTransferable.getTransferData('application/x-moz-file-promise-dir', dirPrimitive, dataSize);
            var dir = dirPrimitive.value.QueryInterface(Components.interfaces.nsILocalFile);

            console.log("target folder is = " + dir.path);

            var file = Cc['@mozilla.org/file/local;1'].createInstance(Components.interfaces.nsILocalFile);
            file.initWithPath(dir.path);
            file.appendRelativePath(name);

            console.log("output final path is =" + file.path);

            // now you can write or copy the file yourself…
        }
    }
}
*/
/*

// currentEvent is an existing drag operation event

currentEvent.dataTransfer.setData("text/x-moz-url", URL);
currentEvent.dataTransfer.setData("application/x-moz-file-promise-url", URL);
currentEvent.dataTransfer.setData("application/x-moz-file-promise-dest-filename", leafName);
currentEvent.dataTransfer.mozSetDataAt('application/x-moz-file-promise',
                  new dataProvider(success,error),
                  0, Components.interfaces.nsISupports);

function dataProvider(){}

dataProvider.prototype = {
  QueryInterface : function(iid) {
    if (iid.equals(Components.interfaces.nsIFlavorDataProvider)
                  || iid.equals(Components.interfaces.nsISupports))
      return this;
    throw Components.results.NS_NOINTERFACE;
  },
  getFlavorData : function(aTransferable, aFlavor, aData, aDataLen) {
    if (aFlavor == 'application/x-moz-file-promise') {

       var urlPrimitive = {};
       var dataSize = {};

       aTransferable.getTransferData('application/x-moz-file-promise-url', urlPrimitive, dataSize);
       var url = urlPrimitive.value.QueryInterface(Components.interfaces.nsISupportsString).data;
       console.log("URL file orignal is = " + url);

       var namePrimitive = {};
       aTransferable.getTransferData('application/x-moz-file-promise-dest-filename', namePrimitive, dataSize);
       var name = namePrimitive.value.QueryInterface(Components.interfaces.nsISupportsString).data;

       console.log("target filename is = " + name);

       var dirPrimitive = {};
       aTransferable.getTransferData('application/x-moz-file-promise-dir', dirPrimitive, dataSize);
       var dir = dirPrimitive.value.QueryInterface(Components.interfaces.nsILocalFile);

       console.log("target folder is = " + dir.path);

       var file = Cc['@mozilla.org/file/local;1'].createInstance(Components.interfaces.nsILocalFile);
       file.initWithPath(dir.path);
       file.appendRelativePath(name);

       console.log("output final path is =" + file.path);

       // now you can write or copy the file yourself…
    }
  }
}

*/
