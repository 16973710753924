import numeral from 'numeral';
import { NumberUtil } from '@/lib/base/NumberUtil';

/**
 * Format an a number as an angle
 *
 * E.g:
 * 38.33333 => formatAngle(38.33333, 1) = 38.3°
 * 38.33333 => formatAngle(38.33333, 2) = 38.33°
 */
export const formatAngle = function(value: number | null, precision = 0): string {
    if (NumberUtil.isFiniteNumber(value)) {
        return numeral(value).format('0.' + '0'.repeat(precision)) + ' \xB0';
    } else {
        return '--';
    }
};
