
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import {
        MeasurementsRepresentation,
    } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
    import Measurements from '@/components/case-manage/measurement/Measurements.vue';
    import { Product } from '@/lib/api/representation/ProductRepresentation';
    import MeasurementsResource from '@/lib/api/resource/case/MeasurementsResource';
    import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';

    /**
     * A loader for measurements in the context of a surgical template
     */
    @Component({ components: { Measurements } })
    export default class StudyMeasurementsLoader extends Vue {
        @Prop({ required: true })
        public readonly value!: StudyRepresentation;

        /** the name of the product - this is used for language string loading */
        @Prop({ required: true })
        public product!: Product;

        protected measurements: MeasurementsRepresentation | null = null;

        /** whether the main list of plans (and the project) is being loaded */
        private loading = true;

        @IsLoading('loading')
        protected async onIntersect(
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
            isIntersecting: boolean): Promise<void> {
                if (isIntersecting) {
                    if (this.value) {
                        // if the surgical template has a plan, then load it (this is a conditional get)
                        this.measurements = await MeasurementsResource.getStudyMeasurements(
                            this.value, { ...this.$apiOptions, forceLoad: true });
                    }
                }
            }
    }
