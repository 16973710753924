import { DicomInfo } from '@/lib/dicom/DicomInfo';

export interface WorkerEvents {
    [key: string]: (e: WorkerMessageEvent) => void | Promise<void>,
}

export interface WorkerMessageEvent extends MessageEvent {
    data: WorkerData
}

export interface UploadDicomWorkerMessageEvent extends WorkerMessageEvent {
    data: UploadDicomData
}

export interface WorkerData {
    event: WorkerEvent
    error?: string
}

export interface UploadDicomData extends WorkerData {
    fileInfos?: DicomInfo[]
    ctFileCollectionUri?: string
    authToken?: string
    refreshToken?: string
}

export enum WorkerEvent {
    Authenticate = 'authenticate',
    AuthRevoke = 'auth-revoke',
    Start = 'start',
    UploadComplete = 'upload-complete',
    Error = 'error',
    Cancelled = 'cancelled',
    IncrementStart = 'increment-start',
    IncrementComplete = 'increment-complete',
}
