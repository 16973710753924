
    import { Component, Vue } from 'vue-property-decorator';
    import Home from '@/views/home/Home.vue';
    import TitleUtil, { TitleComponent } from '@/components/TitleUtil';

    /**
     * This is the wrapper over the Home page, that re-renders the <Home /> component when full path changes
     */
    @Component({ components: { Home } })
    export default class HomeContainer extends Vue {
        /**
         * Bubble up title and notification data for title bar
         */
        protected onUpdateTitle(titleComponent: TitleComponent): void {
            TitleUtil.$emit(this, titleComponent);
        }
    }
