import { LinkedRepresentation } from 'semantic-link';
import { TrackedRepresentationFactory } from '@/lib/semantic-network';
import { ResourceQueryOptions } from '@/lib/semantic-network/interfaces/resourceQueryOptions';
import { ResourceLinkOptions } from '@/lib/semantic-network/interfaces/resourceLinkOptions';
import { HttpRequestOptions } from '@/lib/semantic-network/interfaces/httpRequestOptions';
import { ResourceFactoryOptions } from '@/lib/semantic-network/interfaces/resourceFactoryOptions';
import { ResourceFetchOptions } from '@/lib/semantic-network/interfaces/resourceFetchOptions';
import { RepresentationUtil } from '@/lib/semantic-network/utils/representationUtil';
import anylogger from 'anylogger';
import { instanceOfCollection } from '@/lib/semantic-network/utils/instanceOf/instanceOfCollection';
import { Tracked } from '../types/types';
import { ResourceDeleteOptions } from '@/lib/semantic-network/interfaces/resourceDeleteOptions';

const log = anylogger('delete');

/**
 * A subset of the {@link ApiOptions} that are appropriate for a HTTP DELETE.
 *
 * @see ApiOptions
 */
export type ApiDeleteOptions = ResourceDeleteOptions &
    ResourceFactoryOptions &
    ResourceQueryOptions &
    ResourceLinkOptions &
    HttpRequestOptions &
    ResourceFetchOptions;

/**
 *
 * TODO: accept but don't require TrackedRepresentation interface
 * @param resource
 * @param options
 * @returns removed representation or default
 */
export async function del<T extends LinkedRepresentation>(
    resource: T | Tracked<T>,
    options?: ApiDeleteOptions): Promise<T | undefined> {
    const { where = undefined, removeOnDeleteItem = true } = { ...options };

    // find specific item in collection to delete
    if (where) {
        if (instanceOfCollection(resource)) {
            // refresh collection first
            const collection = await TrackedRepresentationFactory.load(resource, options);
            if (instanceOfCollection(collection)) {
                // then check for existence
                // TODO: needs to process collection<T & LocalState> rather than collection<T>
                const item = RepresentationUtil.findInCollection(collection, options);
                if (item) {
                    const deletedResource = await TrackedRepresentationFactory.del(item, options);
                    if (deletedResource && removeOnDeleteItem) {
                        TrackedRepresentationFactory.removeCollectionItem(collection, deletedResource);
                    }
                    return deletedResource as T;
                } else {
                    log.debug('Item not found in collection');
                    return;
                }
            }
        } else {
            log.warn('Where options cannot be used outside of a collection, skipping where');
            // fall through to return context resource
        }
    }

    if (instanceOfCollection(resource)) {
        log.debug('Attempting to delete collection resource');
    }

    return await TrackedRepresentationFactory.del(resource, options);
}
