
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import AppSvg from '@/components/basic/AppSvg.vue';

    @Component({components: {AppButton, AppSvg}})
    export default class AppButton extends Vue {
        @Prop(String) readonly label: string | undefined;
        @Prop(String) readonly icon: string | undefined;
        @Prop(Boolean) readonly disabled: boolean | undefined;
        @Prop(String) readonly flavour: 'good' | 'bad' | 'warn' | 'act' | 'naked' | undefined;
        @Prop(String) readonly size: 'lrg' | undefined;
    }
