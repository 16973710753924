
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import {
        SurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
    import {
        SurgicalTemplateCollectionRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateCollectionRepresentation';
    import SurgicalTemplateRankingLoader
        from '@/components/case-manage/surgical-template/ranking/SurgicalTemplateRankingLoader.vue';

    /**
     * This is a case component **DEBUG** component that shows the admin the state of surgical template history
     *
     * The table use the v-intersect pattern, so data is loaded on demand as rows are intersected on the browser.
     */
    @Component({
        components: {
            SurgicalTemplateRankingLoader,
        },
    })
    export default class SurgicalTemplateRankingList extends Vue {
        @Prop({ required: true })
        value!: SurgicalTemplateRepresentation;

        protected isLoading = false;

        /** The history of surgical template. */
        protected history: SurgicalTemplateCollectionRepresentation | null = null;

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            this.history = await SurgicalTemplateResource.getHistory(
                this.value, { includeItems: true, ...this.$apiOptions });
        }

        protected get historyItems(): SurgicalTemplateRepresentation[] {
            return this.history?.items ? this.history.items : [];
        }
    }
