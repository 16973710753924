export function parseHeadMetaTag(tag: string, backup: string | null = null): string {
    const element = document.querySelector(`head meta[name="${tag}"]`);

    if (element === undefined || element === null) {
        if(backup !== null) {
            return backup;
        }
        throw new Error(`${tag} meta tag missing`);
    }

    const content = element.getAttribute('content');

    if (content === undefined || content === null || content.length === 0) {
        if(backup !== null) {
            return backup;
        }
        throw new Error(`${tag} meta tag content empty`);
    }

    return content;
}
