import Implant3d from '@/hipPlanner/assembly/objects/Implant3d';
import { LinkUtil } from 'semantic-link';
import { AxiosInstance } from 'axios';
import { CaseComponentRepresentation } from '@/lib/api/representation/case/CaseComponentRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import anylogger from 'anylogger';
import { HipImplantAlias, implantRenderOrder } from '@/hipPlanner/assembly/objects/HipImplantAlias';
import HipSurgicalTemplateComponentResource
    from '@/lib/api/resource/case/surgical-template/components/HipSurgicalTemplateComponentResource';
import { assignMesh } from '@/hipPlanner/assembly/objects/Object3DFactory';
import { CaseHead, CaseStem } from '@/hipPlanner/components/state/types';
import { asVector3 } from '@/lib/base/ThreeUtil';
import { getPosition } from '@/lib/base/matrix';
import { Matrix4 } from 'three';

const log = anylogger('ViewerObjectsController');

/**
 * Create a three-js object representing an implant. The 'object matrix' of the
 * representation will be applied to the object so that it is transformed into its
 * templated position.
 */
export async function loadImplant(
    axios: AxiosInstance,
    representation: CaseComponentRepresentation,
    alias: HipImplantAlias,
    transform?: Matrix4): Promise<Implant3d> {
    const implant = new Implant3d(representation, {
        // The component self uri is assigned as a name
        name: LinkUtil.getUri(representation, LinkRelation.self) as string,
        alias,
    });
    // Check if the model is loaded for this item
    if (!representation.model) {
        // Load the buffered geometry into the "model" key of the item
        await HipSurgicalTemplateComponentResource.getComponentModelPLY<CaseComponentRepresentation>(
            axios, representation);
    }

    if (representation.model) {
        assignMesh(implant, representation.model);
        implant.setRenderOrder(implantRenderOrder(alias));
    } else {
        log.warn('Could not load model for implant');
    }

    // Unless it is explicitly given as the objectMatrix is the same as the 'tmatrix' on the api.
    if (transform) {
        implant.objectMatrix.copy(transform);
    }
    implant.applyMatrix(implant.objectMatrix);

    return implant;
}

/**
 * Calculate the world-transform that puts the given head on the given stem
 */
export function calculateHeadTransform(stem: CaseStem, head: CaseHead): Matrix4 {
    const stemNeckAxis = asVector3(stem.neck_axis);
    const headPosition = getPosition(stem.headTransform);
    const offsetPosition = headPosition.add(stemNeckAxis.multiplyScalar(head.offset));
    return stem.headTransform.clone().setPosition(offsetPosition);
}
