import ResourceService from '@/lib/api/ResourceService';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { ApiUtil } from '@/lib/semantic-network';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';

export default class ApiResource implements ResourceService {
    /**
     * Load the root of the API
     */
    public static async getApi(api: ApiRepresentation, options?: CacheOptions):
        Promise<ApiRepresentation | null> {
        return await ApiUtil.get<ApiRepresentation>(api, options) ?? null;
    }
}
