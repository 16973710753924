import {
    AngleMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/AngleMeasurementValueRepresentation';
import {
    PlaneMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/PlaneMeasurementValueRepresentation';
import {
    MeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/MeasurementValueRepresentation';
import { MeasurementValueTypes } from '@/lib/api/representation/case/measurements/MeasurementValueType';
import anylogger from 'anylogger';
import { compact } from 'lodash';
import {
    AxisMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/AxisMeasurementValueRepresentation';
import AxisLine3D from '@/debugView/assembly/geometry-shapes/AxisLine3D';
import GeometryShapesFactory, {
    AxisConstructionParameters,
} from '@/debugView/assembly/geometry-shapes/GeometryShapesFactory';
import assert from 'assert';
import Angle3D from '@/debugView/assembly/geometry-shapes/Angle3D';
import { formatAngle } from '@/lib/filters/format/formatAngle';
import MaterialUtils from '@/lib/viewer/MaterialUtils';
import Plane3D from '@/debugView/assembly/geometry-shapes/Plane3D';
import {
    RadialMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/RadialMeasurementValueRepresentation';
import {
    DistanceMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/DistanceMeasurementValueRepresentation';
import {
    CoordinateSystemMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/CoordinateSystemMeasurementValueRepresentation';
import { AxesHelper } from 'three';
import { MeshBasicMaterialParameters } from 'three/src/materials/MeshBasicMaterial';
import { asVector3 } from '@/lib/base/ThreeUtil';
import { formatArrayNumber } from '@/lib/filters/format/formatArrayNumber';

export type Measurement3D = Angle3D | Plane3D | AxisLine3D;

/** A type guard to check the type of case representation. */
export function isMeasurementValue(item: unknown): item is MeasurementValueRepresentation {
    const asMeasurementValue = item as MeasurementValueRepresentation;
    return item !== null &&
        item !== undefined &&
        asMeasurementValue.name !== undefined &&
        asMeasurementValue.name !== null &&
        asMeasurementValue.type !== undefined &&
        asMeasurementValue.type !== null &&
        Object.values(MeasurementValueTypes).includes(asMeasurementValue.type);
}

/** A type guard to check a measurement value is a {@link AngleMeasurementValueRepresentation} */
export function isAngleMeasurement(item: unknown): item is AngleMeasurementValueRepresentation {
    const asAngle = item as AngleMeasurementValueRepresentation;
    return isMeasurementValue(item) && asAngle.type === MeasurementValueTypes.Angle;
}

/** A type guard to check a measurement value is a {@link PlaneMeasurementValueRepresentation } */
export function isPlaneMeasurement(item: unknown): item is PlaneMeasurementValueRepresentation {
    const asPlane = item as PlaneMeasurementValueRepresentation;
    return isMeasurementValue(item) && asPlane.type === MeasurementValueTypes.Plane;
}

/** A type guard to check a measurement value is a {@link CoordinateSystemMeasurementValueRepresentation } */
export function isCoordinateSystemMeasurement(item: unknown): item is CoordinateSystemMeasurementValueRepresentation {
    const asPlane = item as CoordinateSystemMeasurementValueRepresentation;
    return isMeasurementValue(item) && asPlane.type === MeasurementValueTypes.CoordinateSystem;
}

/** A type guard to check a measurement value is a {@link AxisMeasurementValueRepresentation } */
export function isAxisMeasurement(item: unknown): item is AxisMeasurementValueRepresentation {
    const asAxis = item as AxisMeasurementValueRepresentation;
    return isMeasurementValue(item) && asAxis.type === MeasurementValueTypes.Axis;
}

/** A type guard to check a measurement value is a {@link RadialMeasurementValueRepresentation } */
export function isRadialMeasurement(item: unknown): item is RadialMeasurementValueRepresentation {
    const asRadial = item as RadialMeasurementValueRepresentation;
    return isMeasurementValue(item) && asRadial.type === MeasurementValueTypes.Radial;
}

/** A type guard to check a measurement value is a {@link DistanceMeasurementValueRepresentation } */
export function isDistanceMeasurement(item: unknown): item is DistanceMeasurementValueRepresentation {
    const asDistance = item as DistanceMeasurementValueRepresentation;
    return isMeasurementValue(item) && asDistance.type === MeasurementValueTypes.Distance;
}

const log = anylogger('MeasurementUtil');

/**
 * Helper class for mapping various landmark sets extracted from a hip or shoulder {@link StudyLandmarksRepresentation}
 */
export class MeasurementUtil {
    /** Makes an angle in 3D given a measurement */
    public static makeAxis3D(measurement: AxisMeasurementValueRepresentation): AxisLine3D | null {
        return GeometryShapesFactory.makeAxis3D(measurement.name, measurement.position, measurement.value);
    }

    /** Makes an angle in 3D given a measurement */
    public static makeAngle3D(measurement: AngleMeasurementValueRepresentation): Angle3D | null {
        assert.ok(measurement.axes, `axes measurement are not defined ${measurement.name}`);
        const [referenceAxis, targetAxis] = measurement.axes;
        assert.ok(referenceAxis, `reference axis is not defined in ${measurement.name}`);
        assert.ok(referenceAxis.value, `reference value is not defined in ${measurement.name}`);
        assert.ok(targetAxis, `target axis are not defined in ${measurement.name}`);
        assert.ok(targetAxis.value, `target value is not defined in ${measurement.name}`);
        assert.ok(measurement.position, `measurement position is not set in ${measurement.name}`);

        const axisColor = MaterialUtils.randomColor(); // same random color for both axes
        const axisLength = 150; // same random color for both axes
        /**
         * When constructing an angle from two axis we want the two axis to not extend from the origin point,
         * so they look like an angle
         */
        const axisOffset = 0;
        /**
         * Make the reference axis parameters according to the value of the angle between the two axis.
         *
         * If the angle is acute, the two axis are drawn 'as they are'.
         * The reference axis offset is set to 0, so the axes do not cross the 'origin point'
         * and do not end up being a 'cross', instead of an 'angle'.
         *
         * Acute angles:
         * ------------
         *
         * ```
         * If the offsets were not set to 0, instead of an 'angle', we will end up with a 'cross':
         *
         *                                                    | (offset)
         *                                                    |
         *      /                                             |    /
         *     / A = (angle is less that 90°)                 V  / A =
         *    /_______                                       __ /______ The offset is not zero,
         *                                    (offset) ------> /        so it does not look like an angle.
         *
         * Obtuse angles:
         * ---------------
         * The reference axis is shifted (by offsetting it to the negated value of the length).
         * e.g: with angle B being 135°, the angle 3d ends up being an obtuse angle.
         *
         *              /                                     /
         *         B  /                                      /  180° - B
         *  ________/ The offset = -length                  /_______    If not, we will end up with
         *            so it in an obtuse angle.                         its complement angle
         *```
         */
        const makeReferenceAxisParams = (): AxisConstructionParameters => {
            if (measurement.value <= 90) {
                return { length: axisLength, offset: axisOffset, color: axisColor };
            } else {
                return { length: axisLength, offset: -axisLength, color: axisColor };
            }
        };

        const reference = GeometryShapesFactory.makeAxis3D(
            measurement.name,
            measurement.position,
            referenceAxis.value,
            makeReferenceAxisParams());

        const target = GeometryShapesFactory.makeAxis3D(
            measurement.name, measurement.position, targetAxis.value,
            { length: axisLength, offset: axisOffset, color: axisColor });

        //
        // TODO: Hack to the get the alia of the object display the angle of the measurement ;)
        //
        const alias = `${measurement.name}: ${formatAngle(measurement.value)}`;
        return GeometryShapesFactory.makeAngle3D(alias, measurement.position, reference, target);
    }

    /** Makes a plane in 3D given a measurement */
    public static makePlane3D(
        measurement: PlaneMeasurementValueRepresentation | CoordinateSystemMeasurementValueRepresentation,
        materialParameters: MeshBasicMaterialParameters = {}): Plane3D | null {
        assert.ok(measurement.origin, `measurement origin is not defined in ${measurement.name}`);
        assert.ok(measurement.value, `reference value is not defined in ${measurement.name}`);

        const plane3D = GeometryShapesFactory.makePlane3D(
            measurement.name, measurement.origin, measurement.value, materialParameters, measurement.y);

        // Add axes to visualize the x and y directions and the normal of the plane
        const axes = new AxesHelper(100);
        axes.name = 'Axes';
        plane3D.theObject.add(axes);
        return plane3D;
    }

    /**
     * Render a Coordinate system representation
     *
     * Note: As quick an easy way to have the right object as the parent of the 'axes', the plane is being used
     * and make it invisible
     */
    public static makeCoordinateSystem3D(measurement: CoordinateSystemMeasurementValueRepresentation): Plane3D | null {
        // On the API we are representing anatomical coordinate systems as coronal planes with the convention:
        // - normal points anteriorly
        // - plane X points to the right
        // - plane Y points superiorly
        const superior = asVector3(measurement.y);
        const anterior = asVector3(measurement.value);

        // The coordinate system is going to be represented by a set of 3 red/green/blue axes, with the conventional
        // correspondence with the (x, y, z) components of a vector. We want to choose the mapping from anatomical
        // coordinates into (red/x, green/y, blue/z) so it makes sense. The obvious choice is to see that the underlying
        // 'global' coordinate system (CT coordinates) has the 'LPS' mapping:
        // red/x <-> left
        // green/y <-> posterior
        // blue/z <-> superior
        // So this is what we choose as a representation.

        // Plane3D arguments:
        // 'direction' will map to the blue/z axis, so 'direction' should be *superior*
        // 'up' will map to the green/y axis, so 'up' should be *posterior*.
        const plane3D = GeometryShapesFactory.makePlane3D(
            measurement.name,
            measurement.origin,
            superior,
            { opacity: 0 },
            anterior.negate());
        assert.ok(plane3D, 'plane expected to be created');

        const axes = new AxesHelper(100);
        axes.name = 'Axes';
        plane3D.theObject.add(axes);

        return plane3D;
    }

    /**
     * Make the measurements 3d.
     *
     * Discard measurements that are 'null' (given they could not be made or are not supported yet)
     * @param measurements
     */
    public static makeMeasurements(measurements: MeasurementValueRepresentation[]): Measurement3D[] {
        return compact(measurements.map(MeasurementUtil.makeMeasurement));
    }

    private static makeMeasurement<T extends MeasurementValueRepresentation>(measurement: T): Measurement3D | null {
        try {
            if (isAxisMeasurement(measurement)) {
                return MeasurementUtil.makeAxis3D(measurement);
            }

            if (isAngleMeasurement(measurement)) {
                return MeasurementUtil.makeAngle3D(measurement);
            }

            if (isPlaneMeasurement(measurement)) {
                return MeasurementUtil.makePlane3D(measurement);
            }

            if (isCoordinateSystemMeasurement(measurement)) {
                return MeasurementUtil.makeCoordinateSystem3D(measurement);
            }
        } catch (e) {
            log.warn('Could not make measurement:', e);
            return null;
        }

        log.warn(`Type of measurement '${measurement.type} not available yet`);
        return null;
    }

    public static toString<T extends MeasurementValueRepresentation>(measurement: T, precision = 2): string {
        try {
            if (isAxisMeasurement(measurement)) {
                return `Axis '${measurement.name}'  direction:${formatArrayNumber(measurement.value, precision)}`;
            }

            if (isAngleMeasurement(measurement)) {
                if (measurement.axes?.[0].value && measurement.axes?.[1].value) {
                    return `Angle '${measurement.name}'\n  angle: ${measurement.value}\n` +
                        ` from-axis: ${formatArrayNumber(measurement.axes[0].value, precision)}\n` +
                        `   to-axis: ${formatArrayNumber(measurement.axes[1].value, precision)}`;
                } else {
                    return `Angle '${measurement.name}'  angle: ${measurement.value}`;
                }
            }

            if (isPlaneMeasurement(measurement)) {
                return `Plane '${measurement.name}'\n  normal: ${formatArrayNumber(measurement.value, precision)}`;
            }

            if (isCoordinateSystemMeasurement(measurement)) {
                const right = asVector3(measurement.x);
                const superior = asVector3(measurement.y);
                const anterior = asVector3(measurement.value);
                return `Coordinates '${measurement.name}'\n` +
                    `      x/left: ${formatArrayNumber(right.negate().toArray(), precision)}\n` +
                    ` y/posterior: ${formatArrayNumber(anterior.negate().toArray(), precision)}\n` +
                    `  z/superior: ${formatArrayNumber(superior.toArray(), precision)}`;
            }

            log.warn(`Type of measurement '${measurement.type} not available yet`);
            return 'Unknown Measurement';
        } catch (e) {
            log.warn('Error converting measurement to string:', e);
            return '';
        }
    }
}
