import { Uri } from 'semantic-link';
import { Representation } from '@/lib/semanticNetworkMigrationUtils';
import { productVersion } from '@/lib/version';

export enum PlanCreatorType {
    Acid = 'ACID',
    User = 'USER',
}

/**
 * Representation for creating a plan. The plan belongs to a specific surgical template history
 */

export interface PlanCreatedDataRepresentation extends Representation {
    /** arbitrary name */
    readonly name: string;
    /** the uri of the specific version being approved */
    readonly surgical_template_history: Uri;
}

export function makePlanCreateDataRepresentation(surgicalTemplateHistoryUri: Uri): PlanCreatedDataRepresentation {
    return {
        name: 'User-approved Plan',
        web_component_version: productVersion(),
        surgical_template_history: surgicalTemplateHistoryUri,
    };
}
