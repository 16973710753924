import { CaseCollectionRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { SearchUtil } from '@/views/home/SearchUtil';
import { HomeSearchAnalyticsData } from '@/views/home/HomeSearchAnalyticsData';

/**
 * Matches any characters that is not a letter or digit or the white space
 *
 * \d - numeric class
 * \s - whitespace
 * a-zA-Z - matches all the letters
 * ^ - negates them all - so you get - non numeric chars, non spaces
 */
const ESPECIAL_CHARACTERS_REGEXP = /[^a-zA-Z\d\s]/;

const NUMBER_REGEXP = /\d+/g;

/***
 * Module for constants and static methods used in the Home search
 */
export class HomeUtil {
    /**
     * @param searchText: the text for search
     * @param results: the results returned after the search
     */
    public static makeSearchAnalyticsParams(
        searchText: string, results: CaseCollectionRepresentation | null): HomeSearchAnalyticsData {
        const tokens = SearchUtil.getSearchTokens(searchText);

        const digitsMatch = searchText.match(NUMBER_REGEXP);
        return {
            numberOfTokens: tokens?.length ?? 0,
            textLength: searchText?.length ?? 0,
            amountOfNumbers: digitsMatch ? digitsMatch[0].length : 0,
            amountOfResults: results?.items?.length ?? 0,
            hasSpecialCharacters: !!searchText?.match(ESPECIAL_CHARACTERS_REGEXP)?.length,
        };
    }
}
