
    import { mapStores } from 'pinia';
    import { Component, Vue } from 'vue-property-decorator';

    import AppButton from '@/components/basic/AppButton.vue';
    import AppCard from '@/components/basic/AppCard.vue';
    import AppCheckboxGroup from '@/components/inputs/AppCheckboxGroup.vue';
    import AppFormActions from '@/components/basic/AppFormActions.vue';
    import AppFormControl from '@/components/basic/AppFormControl.vue';
    import AppFormSectionTitle from '@/components/basic/AppFormSectionTitle.vue';
    import AppFormStatusNotification from '@/components/basic/AppFormStatusNotification.vue';
    import AppInlineNotification from '@/components/basic/AppInlineNotification.vue';
    import AppInputText from '@/components/inputs/AppInputText.vue';
    import AppPageHolder from '@/components/layout/AppPageHolder.vue';
    import AppPageTitle from '@/components/basic/AppPageTitle.vue';
    import AppRadioGroup from '@/components/inputs/AppRadioGroup.vue';
    import AppText from '@/components/basic/AppText.vue';

    import BlankTitle from '@/components/title/BlankTitle.vue';
    import TitleUtil from '@/components/TitleUtil';
    import { usePreferences } from '@/stores/preferences';

    @Component({
        computed: { ...mapStores(usePreferences) },
        components: {
            AppButton,
            AppCard,
            AppCheckboxGroup,
            AppFormActions,
            AppFormControl,
            AppFormSectionTitle,
            AppFormStatusNotification,
            AppInlineNotification,
            AppInputText,
            AppPageHolder,
            AppPageTitle,
            AppRadioGroup,
            AppText,
            BlankTitle,
        },
    })
    export default class Preferences extends Vue {
        preferencesStore!: ReturnType<typeof usePreferences>;

        async created() {
            TitleUtil.$emit(this, { titleComponentType: BlankTitle, notificationComponentType: '' });
        }

        async mounted() {
            this.preferencesStore.load();
        }
    }
