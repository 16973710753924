
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { PatientRepresentation } from '@/lib/api/representation/PatientRepresentation';
    import { NameRepresentationUtil } from '@/components/case-settings/utils/NameRepresentationUtil';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import { PatientComparisonUtil } from '@/components/case-settings/scan/workflow/PatientComparisonUtil';
    import { DicomInfo } from '@/lib/dicom/DicomInfo';
    import { flatten, sortBy } from 'ramda';

    type RowData = {
        patient: PatientRepresentation;
        group: DicomInfo[]
    }

    /**
     * A Dialog to display the inconsistencies found the patient details across different dicom
     * files of the same upload.
     * {@see ScanUploadState.PatientInconsistentIdentityError}
     */
    @Component({ components: {} })
    export default class ScanUploadPatientInconsistentIdentityError extends Vue {
        protected patientFormattedUtil = NameRepresentationUtil;

        @Prop({ required: true, validator: notNilValidator('isActive') })
        public isActive!: boolean;

        @Prop({ required: true })
        public value!: Record<string, DicomInfo[]>;

        /** Only the first 10 different files are displayed */
        private filesToDisplay = 10;

        protected get differentPatientGroups(): RowData[] {
            return Object.values(this.value).map((group: DicomInfo[]) => {
                if (group.length) {
                    return {
                        patient: PatientComparisonUtil.fromDicom(group[0]),
                        group,
                    };
                }

                return { patient: {} as PatientRepresentation, group: [] };
            });
        }

        /**
         * @returns the dicoms files in the group of less length. It is assumed that the first
         * group with more dicom files is the correct one, and the other ones are wrong.
         */
        protected get allDicomsInConflict(): DicomInfo[] {
            const groups = this.differentPatientGroups;
            const sortedByLenghDesc = sortBy((row: RowData) => -row.group.length, groups);
            const _topGroup = sortedByLenghDesc.shift(); // mutate the array, removes the group with more files.
            return flatten(sortedByLenghDesc.map((row: RowData) => row.group));
        }

        /**
         * An array with the first 10 files names.
         */
        protected get discomNamesToDisplay(): string[] {
            return this.allDicomsInConflict.slice(0, this.filesToDisplay).map((dicom: DicomInfo) => dicom.file.name);
        }

        /**
         * The amount of files that are not specifically listed.
         * This will be > 0 only when the amount of files in conflict is bigger than {@property this.filesToDisplay}.
         * Note: This is done to not clog the screen with a really large list of file names.
         */
        protected get amountOfFilesNotListed(): number {
            return this.allDicomsInConflict.length - this.discomNamesToDisplay.length;
        }

        /**
         * The formatted list of file names
         * e.g: if the limit of files to display is 10
         *  - if 11 files => name and 1 other file.
         *  - if 12 files => name and 2 other files.
         */
        protected get fileNameListed(): string {
            // @ts-ignore https://github.com/microsoft/TypeScript/issues/46907
            const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });

            if (this.amountOfFilesNotListed) {
                return formatter.format([
                    ...this.discomNamesToDisplay,
                    `${this.amountOfFilesNotListed} other ${this.amountOfFilesNotListed === 1 ? 'file' : 'files'}`,
                ]);
            } else {
                return formatter.format(this.discomNamesToDisplay);
            }
        }
    }
