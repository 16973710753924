import { includes } from 'ramda';
import anylogger from 'anylogger';

const log = anylogger('includedInValidator');

/**
 * A validator to check that the value is included into the available values
 *
 * E.g: if we want to validate a product is a valid product
 * @Prop({ required: true, validator: includedInValidator('product', ['hip, 'shoulder', 'spine']) })
 * public product!: string;
 *
 * @param propName: used for logging purposes only
 * @param values: set of valid values
 * @return the validator to be executed, which expects the value
 */
export const includedInValidator = function <T>(propName: string, values: T[]) {
    return function(propValue: T): boolean {
        if (includes<T>(propValue, values)) {
            return true;
        }

        log.error('Prop %s, with value: %o is not included in values: %o', propName, propValue, values);
        return false;
    };
};
