
    import { Component, Vue } from 'vue-property-decorator';
    import anylogger from 'anylogger';
    import { AuthenticationStorage, AuthenticatorEvent, AuthRevokeReason } from '@/lib/http/AuthenticatorService';
    import UserResource from '@/lib/api/resource/user/UserResource';
    import { discardResource } from '@/lib/semanticNetworkMigrationUtils';
    import { DashboardQueryParamsAvailable, RouteParserUtils } from '@/lib/base/RouteParserUtils';
    import InactivityObserver from '@/components/idle/InactivityObserver.vue';
    import { IdleTimer } from '@/components/idle/IdleTimer';
    import AnalyticsUtil from '@/lib/analytics/AnalyticsUtil';

    const log = anylogger('AuthenticationProvider.vue');

    @Component({
        components: { InactivityObserver },
    })
    export default class AuthenticationProvider extends Vue {
        /**
         * Flag to determine if the user is authenticated or not.
         *
         * By default, is false, until the following two things happen:
         * 1. At the time the application loads, the user had interacted with application in the last 60 minutes.
         * Otherwise, it is logged out. This is done by checking the '_expirationTime' in the local storage.
         * 2. The authentication on demand model (through the api) has determined the user is authenticated.
         * @see {@link AuthenticatorService}
         */
        protected isAuthenticated = false;

        /**
         * Initialize logout counter value from query parameter, to guarantee a navigation
         * after auth revoke always occurs
         * e.g.: if user refresh the page, with the query parameter already included in
         * the uri (http://localhost:8080/#/?l=1) and variable is initialized from zero,
         * we could hit a redundant navigation error
         */
        private logoutCounter = RouteParserUtils.parseQueryParameterValueNumber(
            this.$route, DashboardQueryParamsAvailable.LogoutCounter) || 0;

        protected created(): void {
            log.info('Authentication Provider created');
            this.$authEvent.$on(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
            this.$authEvent.$on(AuthenticatorEvent.authRequired, this.onAuthRequired);
            this.$authEvent.$on(AuthenticatorEvent.authenticated, this.onAuthConfirmed);
        }

        protected async mounted(): Promise<void> {
            log.info('Authentication Provider mounted');
            await this.tryOnLoadAuthentication();
        }

        protected beforeDestroy(): void {
            this.$authEvent.$off(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
            this.$authEvent.$off(AuthenticatorEvent.authRequired, this.onAuthRequired);
            this.$authEvent.$off(AuthenticatorEvent.authenticated, this.onAuthConfirmed);
        }

        /**
         *
         * Attempts to authenticate the user in 2 steps:
         *
         * 1. Check the expiration time of the last browser session is within the time
         * considered active (at the moment 60 minutes). If it is not revokes the authentication.
         * Note that this is not enough to show the <Login> dialog.
         *
         * 2. Performs a request to the API that requires the browser to authenticate. That means to be a request
         * **that definitely is going to hit the origin server and force the authentication**.
         * For that to happen the request has to be: a) not cacheable, b) requires authentication,
         * c) ideally small (for performance benefits).
         *
         * @see {@link AuthenticationStorage}
         * @see {@link AuthenticatorService}
         */
        private async tryOnLoadAuthentication(): Promise<void> {
            const _hasExpired = (): boolean => {
                const expirationTime = AuthenticationStorage.getExpirationTime();
                if (expirationTime === null) {
                    log.debug('No expiration time on load');
                    return false;
                }

                return IdleTimer.hasExpired(expirationTime);
            };

            if (_hasExpired()) {
                log.debug('Session had expired on load. Proceed to logout.');
                AuthenticationStorage.clearExpirationTime();
                this.logOutByInactivity();
                AnalyticsUtil.event(this.$gtag, this.$route.name, AnalyticsUtil.Event.InactivityTimeoutWhenReOpeningApp);
            } else {
                log.debug('Session has not expired on load. Proceed to authenticate on demand.');
            }

            // Force the browser to authenticate. Doing it in the nextTick allows for time in case
            // the credential were revoked.
            this.$nextTick(async () => {
                await this.forceBrowserToAuthenticate();
            });
        }

        /**
         * Performs a request to the API that requires the browser to authenticate.
         * That means to be a request **that definitely is going to hit the origin server and
         * force the authentication**.
         *
         * For that to happen the request has to be:
         * a) not cacheable,
         * b) requires authentication,
         * c) ideally small (for performance benefits).
         */
        private async forceBrowserToAuthenticate(): Promise<void> {
            await UserResource.getMeUser(this.$api, { forceLoad: true, ...this.$apiOptions });
        }

        private logOutByInactivity(): void {
            this.$authEvent.$emit(AuthenticatorEvent.authRevoke, { reason: AuthRevokeReason.Inactivity });
        }

        /**
         * An event that is generated when-ever the user is unauthenticated (logout, or idle expiry etc).
         *
         * WARNING: this is a hacky direct implementation of trying to log out, where
         * logging out:
         * 1. is throwing away data (this is done in this method)
         * 2. removing all authentication @see {@link AuthenticatorService)}
         * 3. and navigating back to a known location (home). (this is done in this method)
         */
        private async onAuthRevoke(): Promise<void> {
            log.info('Authentication Provider auth revoked');
            await this.clearAPICache();

            // This seems to be done only to have good UI experience, where the next time you are log
            // in you are already in the home page
            this.$router.push({ name: 'home', query: { l: `${++this.logoutCounter}` }, replace: true })
                .catch((err) => log.info('Failed to navigate to home page: %s', err));
        }

        private onAuthRequired(): void {
            this.isAuthenticated = false;
        }

        private onAuthConfirmed(): void {
            this.isAuthenticated = true;
        }

        /** Throws away local state cached in the $api */
        private async clearAPICache() {
            log.info('Cleanup cached API resource');
            if (this.$api.projects) {
                await discardResource(this.$api, 'projects');
            }
            if (this.$api.user) {
                await discardResource(this.$api, 'user');
            }
            if (this.$api.users) {
                await discardResource(this.$api, 'users');
            }
        }
    }
