
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';

    /** A Dialog to notify the user that the DICOM files could not be parsed. */
    @Component({ components: {} })
    export default class ScanUploadParseError extends Vue {
        /** whether the dialog is visible or not (It's visibility its controlled on the outside of this component) */
        @Prop({ required: true, validator: notNilValidator('isActive') })
        public isActive!: boolean;

        /** A parsing text error to display in the table */
        @Prop({ required: true })
        public value!: string;
    }
