export enum IdleTimerState {
    /**
     *                                  ┌──────────────────┐
     *                                  │                  │◄────────────────────────────┐
     *                                  │     Active       │                             │
     *                                  │ (initial state)  │                             │
     *                                  └──────────────────┘                             │
     *                                            │                                      │
     *                                            │                                      │
     *                           *Is the expirationTime in the local storage             │
     *                            getting close to the time out time?                    │
     *                                            │                                      │
     *                                            │ No (nothing to do, stays in Active)  │
     *                                        Yes │                                      │
     *                                            │                                      │
     *                                            ▼                                      │
     *                                  ┌──────────────────┐                             │
     *                                  │                  │          Back to Active     │
     *                                  │   Before Timeout │─────────────────────────────┘
     *                                  │ (dialog is shown)│       - The expirationTime in the local storage was extended.
     *                                  └──────────────────┘       Either:
     *                                            │                   a) The user in the current tab clicked the 'I am back' button OR
     *                                            │                   b) Other tab triggered an event that is considered user activity
     *                                            │
     *                            * Has the expirationTime been reached? ◄───────────────┐
     *                                            │                                      │
     *                                            │                                      │ (keeps checking using setInterval)
     *                                            │ No                                   │
     *                                            │──────────────────────────────────────┘
     *                                        Yes │
     *                                            │
     *                                            │
     *                                            ▼
     *                                  ┌───────────────────┐
     *                                  │                   │
     *                                  │       Timeout     │
     *                                  │                   │
     *                                  └───────────────────┘
     */

    /** The user is considered active */
    Active = 'active',

    /**
     * A state prior to the Timeout.
     * This states gives a chance to the subscriber of the timer to notify the user.
     */
    BeforeTimeout = 'before-timeout',

    /** This is a final state */
    Timeout = 'timeout',
}
