export enum ScanUploadState {
    /**
     *
     *                                  ┌──────────────────┐
     *                                  │                  │
     *                                  │     Initial      │
     *                                  │                  │
     *                                  └──────────────────┘
     *                                            │
     *                                            │ * Files are dragged into the browser
     *                                            ▼
     *                                  ┌──────────────────┐
     *                                  │                  │
     *                                  │   CollectingData │
     *                                  │                  │
     *                                  └──────────────────┘
     *                                            │
     *                                            │
     *                                            │
     *                            * Can we perform the patient data check (a.k.a: DICOm are not anonymised) and
     *                            is the patient data the same across all the DICOMs ?
     *                                            │
     *                                            │                         ┌──────────────────────┐
     *                                            │ No (to )                │                      │
     *                                            │─────────────────►       │  PatientInconsistent │ User clicks 'Ok'
     *                                        Yes │                         │   IdentityError      │  (back to Initial)────────►
     *                                            │                         │                      │
     *                                            │                         └──────────────────────┘
     *                                            │
     *                                            │
     *                                            │
     *                            *  Does Entered Patient and Dicom Patient data match?
     *                                            │
     *                               Yes          │    No
     *                             ┌──────────────────────────────┐
     *                             │                              │
     *                             │                              ▼
     *                             │                       ┌──────────────────────┐
     *                             │                       │                      │
     *                             │                       │  EnteredPatientVs    │ Cancel (back to Initial)
     *                             │                       │   DicomDataMismatch  │────────►
     *                             │                       │       (dialog)       │
     *                             │                       └──────────────────────┘
     *                             │                              │ User clicks 'Proceed Anyway'
     *                             │                              │
     *                             └──────────────────────────────┘
     *                                           │
     *                                           │
     *                                 * Is there exactly one valid series for upload?
     *                                           │
     *                                           │ No (to Failed)
     *                                           │─────────────────►
     *                                        Yes│
     *                                           │
     *                                           │
     *                                  * Are all files in the series valid?
     *                                           │
     *                                           │
     *                             ┌──────────────────────────────┐
     *                         Yes │                              │ Yes, but with warnings and/or errors
     *                 (AutoUpload)│                              ▼
     *                             │                        ┌──────────────────────┐
     *                             │                        │                      │
     *                             │                        │     WaitingForUser   │ Cancel (back to Initial)
     *                             │                        │      Confirmation    │ ────────►
     *                             │                        │                      │
     *                             │                        └──────────────────────┘
     *                             │                              │
     *                             │                              │ User click 'Upload'
     *                             │                              │
     *                             │                              │
     *                             └──────────────────────────────┘
     *                                           │
     *                                           ▼
     *                                ┌───────────────────────────┐
     *                                │                           │
     *                                │       UploadingData       │
     *                                │                           │
     *                                └───────────────────────────┘
     *                                          │
     *                                          └────────────────► (if success back to Initial state)
     *
     *
     *              *
     *              │
     *              │
     *              │
     *              │                 ┌───────────────────────────┐
     *              │(from anywhere)  │                           │
     *              └───────────────► │      *Failed              │
     *                                │                           │
     *                                └───────────────────────────┘
     */

    /**
     * Not visible. Can't 'upload'
     */
    Initial = 500,

    /**
     * The data is being collected up, read and collated.
     *
     * The user can not upload, but they can cancel.
     */
    CollectingData,

    /**
     * The data has been collected but the patient details are not consistent across all the DICOMs.
     *
     * Note 1: This step was added in the workflow as during development of FL-873.
     * This is a final error state. The user has to correct the files provided and upload again.
     *
     * Note 2: This state is for when the data is not consistent across the DICOMs.
     * Other errors are not relevant at this stage, they will be checked later and apply to other states.
     * Other potential errors include
     *  - {@link EnteredPatientVsDicomDataMismatch})
     *  - the data itself may be incorrect, e.g. a missing name or patient sex is not formatted as expected
     */
    PatientInconsistentIdentityError,

    /**
     * The data has been collected. The manually entered patient details and dicom patient data does not match.
     * It requires user confirmation to proceed.
     *
     * Note: This step was added in the workflow as during development of FL-873.
     * If there is patient mismatch and also error on the dicom data,
     * the patient dialog will be shown first, then the DICOM warning/info details (next step)
     * {@link ScanUploadState.WaitingForConfirmation}.
     */
    EnteredPatientVsDicomDataMismatch,

    /**
     * The data has been collected. The 'Upload' button should be active and the user
     * pressing the 'Upload' button should start the upload (and advance the state to
     * {@link ScanUploadState.UploadingData}.
     */
    WaitingForConfirmation,

    /**
     * The dialog is upload the data to the API. The 'Upload' button should be unavailable.
     *
     * When the upload completes, the dialog state will be set to {@link ScanUploadState.Initial}. If
     * the upload fails, then the state will go back to {@link ScanUploadState.WaitingForConfirmation}
     * and any error information should be shown to the user.
     */
    UploadingData,

    /**
     * The data collection has been performed but a single series is not avalable
     */
    Failed,
}

// Below UploadEventState values are used to emit signals from ScanUploadWorkflow component to it's parent CaseSettings
export enum UploadEventState {
    None = 0,
    Started = 1,
    Completed = 2,
    Error = 3,
}

export type UploadStateType = UploadEventState.None | UploadEventState.Started | UploadEventState.Completed | UploadEventState.Error;
