/**
 * A functor that creates a new group
 */
import { isArray } from 'lodash';
import { NumberUtil } from '@/lib/base/NumberUtil';
import { all } from 'ramda';

type GroupFactory<T> = (key: string | number) => T;

/**
 * A functor that adds an item to a group.
 */
type ItemAppender<T, G> = (group: G, item: T) => void;

type Accumulator<T> = { [key: string]: T };

type Selector<T> = (item: T) => string | number;

export default class ArrayUtil {
    /**
     * A typescript group-by function implemented using reduce().
     *
     * @param items the array (list) of items to group
     * @param selector the functor to select (or calculate) the grouping key.
     * @param groupFactory an optional factory to create a group (defaults to an array of items)
     * @param itemAppender an optional functor to add items to the group (default to array.push())
     */
    public static groupBy<T, G>(
        items: T[],
        selector: Selector<T>,
        groupFactory: GroupFactory<G>,
        itemAppender: ItemAppender<T, G>): Accumulator<G> {
        const initial: Accumulator<G> = {};

        return items.reduce(
            (groups, value) => {
                // calculate/get the key (this must be string like)
                const key = selector(value);
                // get or create the group (warning assignment within expression)
                const group: G = groups[key] || (groups[key] = groupFactory(key));
                // add the item to the group
                itemAppender(group, value);
                return groups;
            },
            initial);
    }

    private static simpleArrayFactory<G>(): G[] {
        return [];
    }

    private static simpleArrayAppender<T>(group: T[], item: T): void {
        group.push(item);
    }

    /**
     * Checks if a value is an array.
     */
    public static isArray(maybeArray: unknown): maybeArray is [] {
        return isArray(maybeArray);
    }

    /**
     * Checks if a value is a number array.
     */
    public static isArrayNumber(maybeArray: unknown): maybeArray is number[] {
        return this.isArray(maybeArray) && all(NumberUtil.isFiniteNumber, maybeArray);
    }
}
