import { isNil } from 'ramda';
import anylogger from 'anylogger';

const log = anylogger('notNilValidator');

/**
 * A not null or undefined validator to be used for validating properties on Vue instances
 *
 * E.g: if we want to validate 'firstName' is not null or undefined, and we want to log a message that gives
 * us some hint, we can do:
 *
 * @Prop({ required: true, validator: notNilValidator('firstName') })
 * protected firstName!: string;
 *
 * @param propName: used for logging purposes only
 * @return the validator to be executed, which expects the value
 */
export const notNilValidator = function(propName: string) {
    return function(propValue: unknown): boolean {
        if (isNil(propValue)) {
            log.error('Prop %s is null or undefined: %s', propName, propValue);
            return false;
        }

        return true;
    };
};
