import { AnatomicalCoordinateSystem, Axis } from '@/lib/api/representation/interfaces';
import { NumberUtil } from '@/lib/base/NumberUtil';
import { NumberArray3 } from '@/lib/api/representation/geometry/arrays';

/**
 * A simple axis composed of a direction vector and a displacement along that vector
 */
export class SimpleAxis implements Axis {
    public vector: NumberArray3;
    public position: number;

    constructor(vector: NumberArray3, position: number) {
        this.position = position;
        this.vector = [vector[0], vector[1], vector[2]];
    }

    public copyFrom(src: Axis): void {
        this.position = src.position;
        NumberUtil.copyNumberArray3(src.vector, this.vector);
    }
}

/**
 * A cartesian anatomic coordinate system.
 *
 * Defined by an origin point and 3 axes in body directions:
 *   - Anterior - Posterior
 *   - Superior - Inferior
 *   - Medial - Lateral
 */
export class ACS implements AnatomicalCoordinateSystem {
    public origin: NumberArray3;
    public ap: SimpleAxis;
    public si: SimpleAxis;
    public ml: SimpleAxis;

    constructor(other?: AnatomicalCoordinateSystem) {
        this.origin = [0, 0, 0];
        this.ap = new SimpleAxis([0, 0, 0], 0);
        this.si = new SimpleAxis([0, 0, 0], 0);
        this.ml = new SimpleAxis([0, 0, 0], 0);
        if (other) {
            this.copyFrom(other);
        }
    }

    // public copyTo(dst: AnatomicalCoordinateSystem): void {
    //     NumberUtil.copyNumberArray3(this.origin, dst.origin);
    //     this.ap.copyTo(dst.ap);
    //     this.si.copyTo(dst.si);
    //     this.ml.copyTo(dst.ml);
    // }

    public copyFrom(src: AnatomicalCoordinateSystem): void {
        NumberUtil.copyNumberArray3(src.origin, this.origin);
        if (src.ap && src.si && src.ml) {
            this.ap.copyFrom(src.ap);
            this.si.copyFrom(src.si);
            this.ml.copyFrom(src.ml);
        } else {
            throw new Error('Source ACS does not have ap, si, or ml axes');
        }
    }
}
