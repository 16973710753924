import { HipRenderOrder } from '@/hipPlanner/assembly/objects/HipRenderOrder';

export enum HipImplantAlias {
    Stem = 'stem',
    Head = 'head',
    Cup = 'cup',
    Liner = 'liner',
}

/**
 * Get the {@link HipRenderOrder} corresponding to the given {@link HipImplantAlias}.
 */
export function implantRenderOrder(alias: HipImplantAlias): HipRenderOrder {
    switch (alias) {
        case HipImplantAlias.Stem:
            return HipRenderOrder.Stem;
        case HipImplantAlias.Head:
            return HipRenderOrder.Head;
        case HipImplantAlias.Cup:
            return HipRenderOrder.Cup;
        case HipImplantAlias.Liner:
            return HipRenderOrder.Liner;
    }
}
