import TimeoutUtil from '@/lib/base/TimeoutUtil';
import { LinkedRepresentation } from 'semantic-link/lib/interfaces';
import assert from 'assert';

export type ResourcePredicate<T extends LinkedRepresentation> = (resource: T) => boolean | never;
export type Predicate = () => boolean | never;

export type CancellationOptions = {
    signal?: AbortSignal,
    timeoutInMilliseconds?: number,
}

/**
 * Custom error wait resource error
 * @see https://bobbyhadz.com/blog/typescript-extend-error-class
 */
export class WaitResourceTimeoutError extends Error {
    constructor(timeout: number) {
        super(`Timeout of ${timeout} waiting for operation to complete`);

        //  because we are extending a built-in class
        Object.setPrototypeOf(this, WaitResourceTimeoutError.prototype);
    }
}

const THREE_SECONDS_IN_MILLISECONDS = 3000;

/**
 * Utility that provide a simple way to wait for certain things to happen on a {@link LinkedRepresentation}
 */
export class WaitResourceUtil {
    /**
     * Waits until a predicate returns true
     *
     *  1. the resource condition is met
     *  2. the predicate throws an error
     *  3. the operation is cancelled (optional on @param axiosCancellationOptions)
     *  4. timeout is reached (optional on @param axiosCancellationOptions)
     */
    static async waitUntil<T extends LinkedRepresentation>(
        predicate: ResourcePredicate<T> | Predicate,
        intervalInMilliseconds = THREE_SECONDS_IN_MILLISECONDS,
        cancellation: CancellationOptions,
        /** an optional function that will fetch the resource before evaluation the predicate: */
        beforeResourcePredicate?: () => Promise<T>): Promise<void> {
        const { signal, timeoutInMilliseconds } = cancellation || {};

        let timeout = false;
        const timeoutHandle = (timeoutInMilliseconds !== undefined) ?
            window.setTimeout(() => {
                timeout = true;
            }, timeoutInMilliseconds) :
            null;

        const clearTimeoutHandler = () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
        };

        // Loop around until either:
        //  1. the predicate condition is met
        //  2. the predicate throws an error
        //  3. the operation is cancelled
        //  4. timeout is reached
        do {
            signal?.throwIfAborted();
            const conditionMet = beforeResourcePredicate ?
                predicate(await beforeResourcePredicate()) :
                (predicate as Predicate)();
            if (conditionMet) {
                clearTimeoutHandler();
                return;
            } else {
                if (timeout) {
                    clearTimeoutHandler();
                    assert.ok(timeoutInMilliseconds !== undefined, 'timeoutInMilliseconds !== undefined');
                    throw new WaitResourceTimeoutError(timeoutInMilliseconds);
                }
            }
            await TimeoutUtil.setTimeout(intervalInMilliseconds, signal);
            // eslint-disable-next-line no-constant-condition
        } while (true);
    }
}
