import { LinkedRepresentation } from 'semantic-link';
import { NameRepresentation } from '@/lib/api/representation/NameRepresentation';

export enum PatientSex {
    Male = 'male',
    Female = 'female',
    Other = 'other',
}

export type PatientSexType = PatientSex.Male | PatientSex.Female | PatientSex.Other;

export interface PatientCreateDataRepresentation {
    /** the name of the patient */
    name?: NameRepresentation;

    sex?: PatientSexType;

    /** The date of birth of the patient. This is timezone agnostic and of the form 'YYY-MM-DD'. */
    birth_date?: string;
}

export const PATIENT_DATE_OF_BIRTH_FORMAT = 'yyyy-MM-dd';

export interface PatientRepresentation extends LinkedRepresentation {

    /** the name of the patient */
    name?: NameRepresentation;

    sex?: PatientSexType;

    /** The date of birth of the patient. This is timezone agnostic and of the form 'YYYY-MM-DD'. */
    birth_date?: string;

    /**
     * Weight of the patient, in kilograms
     *
     * Note: This field is currently not used, but will be utilised in the near future
     */
    weight?: string;

    /**
     * Height of the patient, in kilograms
     *
     * Note: This field is currently not used, but will be utilised in the near future
     */
    height?: string;

    /**
     * External patient ID, usually given by the surgeon
     *
     * Note: This field is currently not used, but will be utilised in the near future
     */
    patient_id_ext?: string;
}
