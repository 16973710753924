import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SpinopelvicRepresentation, SpinopelvicState } from '@/stores/spinopelvic/types';
import anylogger from 'anylogger';
import { requestFromState } from '@/stores/spinopelvic/mapping';

export const log = anylogger('SpinopelvicRequests');

export async function loadProjectSpinopelvic(
    axios: AxiosInstance,
    projectUri: string,
): Promise<SpinopelvicRepresentation | null> {
    const projectSpinopelvicUrl = `${projectUri}/spinopelvic`;

    const conf: AxiosRequestConfig = {};
    conf.validateStatus = (status: number) => {
        // make sure 404 status code doesn't raise any errors, this is expected if the project does not
        // have any spinopelvic data
        return (status >= 200 && status < 300) || status == 404;
    };

    return await axios
        .get(projectSpinopelvicUrl, conf)
        .then((response: AxiosResponse): SpinopelvicRepresentation | null => {
            if (response.status === 404) {
                return null;
            }

            return response.data;
        });
}

export async function saveProjectSpinopelvic(
    axios: AxiosInstance,
    projectUri: string,
    state: SpinopelvicState,
): Promise<void> {
    const projectSpinopelvicUrl = `${projectUri}/spinopelvic`;

    await axios.put(projectSpinopelvicUrl, requestFromState(state));
}
