
    import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
    import AppSvg from '@/components/basic/AppSvg.vue';

    @Component({components: {AppSelect, AppSvg}})
    export default class AppSelect extends Vue {
        @VModel({type: String}) selected!: string;
        @Prop(String) readonly placeholderText: string | undefined;
        @Prop(Array) readonly options!: { value: string, text: string; }[];
        @Prop(String) readonly error: string | undefined;
        @Prop(Boolean) readonly disabled: boolean | undefined;
    }
