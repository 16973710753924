import { DoubleSide, Matrix4, Mesh, MeshBasicMaterial, PlaneGeometry, Vector3 } from 'three';
import { MeshBasicMaterialParameters } from 'three/src/materials/MeshBasicMaterial';
import ViewerUtils from '@/lib/planning/viewer/ViewerUtils';
import { asVector3, AsVector3, setLocalTransform } from '@/lib/base/ThreeUtil';

/**
 * The parameters that define a set of parameters that can be applied to the creation of a {@PlaneGeometry}.
 */
export interface PlaneGeometryParameters {
    /**
     * Width of the sides on the X axis.
     *
     */
    readonly width?: number;

    /**
     * Width of the sides on the Y axis.
     */
    readonly height?: number;

    /**
     * Number of segmented faces along the width of the sides.
     */
    readonly widthSegments?: number;

    /**
     * Number of segmented faces along the height of the sides.
     */
    readonly heightSegments?: number;
}

/**
 * Default material values
 * Note: Default values have been chosen based on the existing values used in the app, to not change the interface
 */
const DEFAULT_MATERIAL_PARAMETERS: MeshBasicMaterialParameters = {
    opacity: 0.4,
    color: 0x4343f9, // some kind of blue
    transparent: true,
    wireframe: false,
};

const DEFAULT_GEOMETRY_PARAMETERS: PlaneGeometryParameters = {
    width: 100,
    height: 100,
    widthSegments: 50,
    heightSegments: 1,
};

export default class PlaneFactory {
    /**
     * Make a plane mesh representation
     */
    public static makePlaneMesh(
        position: AsVector3,
        direction: AsVector3,
        materialParameters: MeshBasicMaterialParameters = {},
        geometryParameters: PlaneGeometryParameters = {},
        up?: AsVector3): Mesh {
        const materialParams = { ...DEFAULT_MATERIAL_PARAMETERS, ...materialParameters };
        const meshMaterial = new MeshBasicMaterial({
            color: materialParams.color,
            side: DoubleSide,
            transparent: materialParams.transparent,
            opacity: materialParams.opacity,
            wireframe: materialParams.wireframe,
        });
        const geometryParams = { ...DEFAULT_GEOMETRY_PARAMETERS, ...geometryParameters };
        const planeGeometry = new PlaneGeometry(
            geometryParams.width, geometryParams.height, geometryParams.widthSegments, geometryParams.heightSegments);

        const planeMesh = new Mesh(planeGeometry, meshMaterial);
        const positionVec = asVector3(position);
        const directionVec = asVector3(direction);

        if (up) {
            // We want the z-axis of the transform to point the same way as 'direction'.
            // The 'lookAt' function will result in a transform such that the z-axis points from the target to the eye.
            // Thus we want to 'look' from eye to target in the *opposite direction* to the supplied vector.
            const transform = new Matrix4().lookAt(
                new Vector3(), directionVec.negate(), asVector3(up));
            transform.setPosition(positionVec);
            setLocalTransform(planeMesh, transform);
        } else {
            // position the plane mesh at the target cross-section position point
            planeMesh.position.copy(positionVec);

            // make the plane mesh look (rotate) towards the cross-section direction
            planeMesh.lookAt(ViewerUtils.getPositionAlongDirection(positionVec.clone(), directionVec.clone(), 10));
        }

        return planeMesh;
    }
}
