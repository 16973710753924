/**
 * The hip measurements that are a result of the templating process
 */
export enum HipTemplateMeasurementName {
    /**
     * Femoral stem anteversion angle. An 'angle' measurement
     */
    StemAnteversion = 'anteversion',

    /**
     * The leg length difference introduced by the femoral part of the assembly (stem + head).
     *
     * **Axis**: The medial-lateral axis of the coordinate system it was measured. The coordinate system will vary
     * according to the alignment mode (none, app, fpp).
     *
     * **Combined leg length**: This value wil be sum to the acetabular leg length difference to achieve the combined
     * leg length difference.
     *
     * @see https://docs.google.com/presentation/d/11Pmya0pl4vhe672Kf28tnwJaMc5TM7qj/edit#slide=id.p72
     * @see LegLengthTargetRepresentation.ll
     */
    StemLegLengthDifference = 'leg.length.difference',

    /**
     * The leg offset difference introduced by the femoral part of the assembly (stem + head)
     *
     * **Axis**: The medial-lateral axis of the coordinate system it was measured. The coordinate system will vary
     * according to the alignment mode (none, app, fpp).
     *
     * **Combined leg offset**: This value wil be sum to the acetabular offset difference to achieve the combined offset
     * difference.
     *
     * @see https://docs.google.com/presentation/d/11Pmya0pl4vhe672Kf28tnwJaMc5TM7qj/edit#slide=id.p73
     * @see LegLengthTargetRepresentation.os
     */
    StemLegOffsetDifference = 'leg.offset.difference',

    /**
     * The resection plane for this stem. A 'plane' measurement
     */
    StemResectionPlane = 'resection',

    /**
     *  the distance between the greater trochanter and the resection plane on the stem
     */
    StemResectionGreaterTrochanter = 'resection.greater-trochanter',

    /**
     * Lesser Trochanter
     * The resection distance from the lesser trochanter is indicated as
     * the vertical distance from the superior slope of the lesser trochanter to where the resection
     * plane exits the neck.
     *
     * **Note**: that the lesser trochanter is not always visible. To show the neck cut as a clear line, the view
     * is perpendicular to the stem, and so depending on stem anteversion, the lesser trochanter will not always be
     * oriented to protrude medially from the femur.
     *
     * @see https://docs.google.com/presentation/d/11Pmya0pl4vhe672Kf28tnwJaMc5TM7qj/edit#slide=id.p81
     */
    StemResectionLesserTrochanter = 'resection.lesser-trochanter',

    StemResectionGreaterTrochanterMedial = 'resection.greater-trochanter.medial',
    /**
     * The distance from the 'saddle' landmark to the top of the stem. This is a definition
     * of measurement that is not widely used. The more widely used definition of the saddle
     * distance relates to the resection plane.
     */
    StemSaddle = 'saddle',

    CupAnteversion = 'anteversion',
    CupAbduction = 'abduction',

    /**
     * The leg length difference introduced by the acetabular part of the assembly (cup + liner).
     *
     * **Axis**: The medial-lateral axis of the coordinate system it was measured. The coordinate system will vary
     * according to the alignment mode (none, app, fpp).
     *
     * **Combined leg length**: This value wil be sum to the femoral leg length difference to achieve the combined
     * leg length difference.
     *
     * @see https://docs.google.com/presentation/d/11Pmya0pl4vhe672Kf28tnwJaMc5TM7qj/edit#slide=id.p72
     * @see LegLengthTargetRepresentation.ll
     */
    CupLegLengthDifference = 'leg.length.difference',

    /**
     * The leg offset difference introduced by the acetabular part of the assembly (stem + head)
     *
     * **Axis**: The medial-lateral axis of the coordinate system it was measured. The coordinate system will vary
     * according to the alignment mode (none, app, fpp).
     *
     * **Combined leg offset**: This value wil be sum to the femoral offset difference to achieve the combined offset
     * difference.
     *
     * @see LegLengthTargetRepresentation.os
     * @see https://docs.google.com/presentation/d/11Pmya0pl4vhe672Kf28tnwJaMc5TM7qj/edit#slide=id.p73
     */
    CupLegOffsetDifference = 'leg.offset.difference',
}
