/**
 * The base url of the api.
 */
export function apiBaseUrl(): string {
    const element = document.querySelector('head link[rel="api"]');
    if (element === undefined || element === null) {
        throw new Error('api link meta tag missing');
    }

    const url = element.getAttribute('href');
    if (url === undefined || url === null || url.length === 0) {
        throw new Error('api link meta tag href empty');
    }

    // raises type error when url not valid
    // new URL(url);

    const trailingSlash = /\/$/;
    return url.replace(trailingSlash, '');
}
