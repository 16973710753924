/**
 * A tagged template function that can be used to allow regex expressions with
 * whitespace and comments.
 *
 * This uses the raw form of the template.
 *
 * @see https://keleshev.com/verbose-regular-expressions-in-javascript
 * @see https://gist.github.com/keleshev/c49465caed1f114b2bb3f2b730e221ca
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
 */
export function verboseRegExp(input: TemplateStringsArray): RegExp {
    if (input.raw.length !== 1) {
        throw Error('verboseRegExp: interpolation is not supported');
    }

    const source = input.raw[0];
    const regexp = /(?<!\\)\s|[/][/].*|[/][*][\s\S]*[*][/]/g;
    const result = source.replace(regexp, '');

    return new RegExp(result);
}
