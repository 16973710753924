
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { AuthenticatorEvent, AuthRevokeReason } from '@/lib/http/AuthenticatorService';
    import { NameRepresentationUtil } from '@/components/case-settings/utils/NameRepresentationUtil';
    import { Product, ProductCollectionRepresentation } from '@/lib/api/representation/ProductRepresentation';
    import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
    import UserResource from '@/lib/api/resource/user/UserResource';

    import anylogger from 'anylogger';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import AppBarButton from '@/components/app-bar/AppBarMenuButton.vue';
    import AppBarMenuContent from '@/components/app-bar/AppBarMenuContent.vue';
    import { ProductUtil } from '@/components/case/ProductUtil';

    const log = anylogger('ProfileDropdown');

    interface MenuItemData {
        readonly name: string;
        readonly title: string;
        /**
         * If link is not defined, then no route event is generated, and click can be custom
         */
        readonly link?: string;
        disabled: boolean;
        visible: boolean;
    }

    /**
     * A simple menu in the top right corner of the application to allow the user to
     * navigate to application level views, and perform application level operations.
     *
     * see
     *   - {@link https://vuetifyjs.com/en/examples/layouts/google-contacts}
     *   - {@link https://stackoverflow.com/questions/47586022/router-link-with-vue-and-vuetify-confusion}
     */
    @Component({ components: { AppBarButton, AppBarMenuContent } })
    export default class ProfileDropdown extends Vue {
        /**
         * Reactive / computed property that will disable/enable different properties
         */
        protected get items(): MenuItemData[] {
            const hasHip = this.products ? ProductUtil.hasProduct(this.products, Product.Hip) : false;
            return [
                {
                    name: Product.Hip,
                    title: 'Your hip preferences',
                    link: '/profile/hip/settings',
                    disabled: !hasHip,
                    visible: hasHip,
                },
                {
                    name: 'logout',
                    title: 'Log Out',
                    link: undefined, // explicitly no link, so click handler is custom
                    disabled: false,
                    visible: true,
                },
            ];
        }

        protected isLoading = false;
        /** Whether the menu is visible/active */
        protected isActive = false;

        /** The user resource for the identity the user is authenticated as. */
        private user: UserRepresentation | null = null;

        /**
         * The list of products that the user is subscribed to (i.e. those products that the
         * user has been provisioned to be able to use (create, discover, ...).
         */
        private products: ProductCollectionRepresentation | null = null;

        protected created(): void {
            this.$authEvent.$on(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
        }

        protected beforeDestroy(): void {
            this.$authEvent.$off(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
        }

        protected get visibleItems(): MenuItemData[] {
            return this.items.filter((item) => item.visible);
        }

        protected get username(): string {
            return this.user?.name ? NameRepresentationUtil.format(this.user.name) : 'anonymous';
        }

        private async onAuthRevoke(): Promise<void> {
            this.user = null;
            this.products = null;
        }

        private onItemClicked(item: MenuItemData): void {
            if (item.name === 'logout') {
                this.$authEvent.$emit(AuthenticatorEvent.authRevoke, { reason: AuthRevokeReason.LogOut });
            }
        }

        @Watch('isActive') // watch the active boolean, which is the v-model/value
        @IsLoading('isLoading')
        protected async onActive(isActive: boolean): Promise<void> {
            if (isActive) {
                this.user = await UserResource.getMeUser(this.$api, this.$apiOptions);
                if (this.user) {
                    this.products = await UserResource.getProducts(this.user, this.$apiOptions);
                    if (this.products) {
                        // nothing to do (user and product loaded successfully)
                    } else {
                        log.warn('failed to load the list of products');
                    }
                } else {
                    log.warn('Failed to get \'me\' user');
                }
            } // else the dialog closing
        }
    }
