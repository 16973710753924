
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import JSONContent from '@/components/case-manage/extra/JSONContent.vue';
    import {
        FemoralRankingRepresentation, instanceOfFemoralRankingVersion1,
    } from '@/lib/api/representation/case/surgical-template/hip/femoral/FemoralRankingRepresentation';
    import { isEmpty } from 'ramda';
    import LinkRelation from '@/lib/api/LinkRelation';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';
    import { UriDeconstructionUtil } from '@/components/case-plan/UrlDeconstructionUtil';
    import Hyperlink from '@/components/case-manage/Hyperlink.vue';
    import HTMLContent from '@/components/case-manage/extra/HTMLContent.vue';

    /**
     * This is the case surgeon
     */
    // eslint-disable-next-line no-undef
    @Component({ components: { HTMLContent, JSONContent, HyperlinkButton, Hyperlink } })
    export default class FemoralRanking extends Vue {
        @Prop({ required: true })
        public value!: FemoralRankingRepresentation | null;

        // /** the name of the product - this is used for language string loading */
        // @Prop({ required: true })
        // public product!: Product;

        get hasData(): boolean {
            return !!this.value?.raw_ && !isEmpty(this.value.raw_);
        }

        /**
         * Extract the user id from a self link
         * e.g.:  "title": "2021-06-07 03:31:50.706585+00:00 admin@formuslabs.com" => admin
         */
        private get historyId(): string {
            return UriDeconstructionUtil.pathLastNumberOfLink(this.value, LinkRelation.self);
        }

        private get isVersion1(): boolean {
            return this.hasData && instanceOfFemoralRankingVersion1(this.value?.raw_);
        }
    }
