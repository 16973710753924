import { AxiosInstance } from 'axios';
import { ComponentSelectorOptions, isValueTextOption } from '@/components/data/combobox/types/component-selectors/types';
import { ComponentSelectorRepresentation } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { getHipStemTypeOptions } from '@/lib/api/resource/components/HipStemTypeResource';

export const STEMTYPE_KEY_SEPARATOR = '$$';

export function selectorKey({ system, type }: ComponentSelectorRepresentation) {
    return `${system}${STEMTYPE_KEY_SEPARATOR}${type}`;
}

export async function getHipStemTypeTextOptions(http: AxiosInstance) {
    /**
     * Form inputs cannot use complex objects as value, therefore we need to convert value into a simple string.
     */
    const stemTypeOptions: ComponentSelectorOptions = await getHipStemTypeOptions(http);
    return stemTypeOptions
        .filter(isValueTextOption)
        .map(({ text, value }) => ({ text, value: selectorKey(value) }));
}
