import { parseHeadMetaTag } from '@/lib/metaTagUtil';

const DEFAULT_BANNER = 'Investigational Device - To Be Used by Qualified Investigators Only';

export const showTopBanner = (): boolean => {
    return parseHeadMetaTag('show-top-banner', 'false') === 'true' || false;
};

export const topBannerText = (): string => {
    return parseHeadMetaTag('top-banner-text', DEFAULT_BANNER);
};