import { CollectionRepresentation, LinkedRepresentation } from 'semantic-link';

/**
 * Product name enumeration
 *
 * The string values match the name of the product found in the 'products' table in the database
 */
export enum Product {
    Hip = 'hip',
}

export enum ProductId {
    Hip = '4'
}

export type ProductType = Product.Hip;

export const AVAILABLE_PRODUCTS = [Product.Hip];

export interface ProductRepresentation extends LinkedRepresentation {
    name: ProductType;
}

export type ProductCollectionRepresentation = CollectionRepresentation<ProductRepresentation>;
