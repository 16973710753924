import {
    ApiCreateOptions,
    ApiGetOptions,
    ApiOptions,
    ApiUpdateOptions,
    ApiUtil,
    instanceOfCollection,
    instanceOfTrackedRepresentation,
    NamedRepresentationFactory,
    Nullable,
    RepresentationUtil,
    SingletonMerger,
    SparseRepresentationFactory,
    Status,
    Tracked,
    TrackedRepresentationFactory,
    TrackedRepresentationUtil,
    UriList,
} from '@/lib/semantic-network';
import { CollectionRepresentation, LinkedRepresentation, LinkUtil, RelationshipType, Uri } from 'semantic-link';
import anylogger from 'anylogger';
import { DocumentRepresentation } from '@/lib/semantic-network/interfaces/document';
import ResourceUtil from '@/lib/api/ResourceUtil';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { getPropertyValue } from '@/lib/base/ObjectUtils';
import { ResourceType } from '@/lib/semantic-network/interfaces/resourceFactoryOptions';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import { ResourceAssignOptions } from '@/lib/semantic-network/interfaces/resourceAssignOptions';

const log = anylogger('SemanticNetworkMigrationUtils');

export type CacheOptions = ApiOptions
    & Pick<AxiosRequestConfig, 'signal'> // allows for cancelling request;

export const get = _get;

/**
 * A create method that doesn't require a 'create-form'.
 */
export const create = createWithoutForm;

export interface ApiExtraCreateOptions {
    /** only used in the context of a singleton which is set on the create context (but not using the 'rel'). */
    readonly createdName?: string;
    readonly createdRel?: RelationshipType;
    /** if the 'createRel' is provided (and it is known), create a sparse object of this type */
    readonly createdSparseType?: ResourceType,

    /**
     * When creating a new singleton resource - that is one that is stored not in
     * a child collection, the name to use (which overrides/modifies the in memory
     * name by which the resource is identified).
     */
    readonly singletonName?: string;
    /** A simple link relation that is only a string (not a {@link RelationshipType}) */
    readonly singletonRel?: string;
    readonly singletonTitle?: string;

    /**
     * When creating a resource if the resulting resource is 'virtual'. That it has no repeatable URI
     * that can be determined via navigation (A series of GETs), then put it into a virtual collection.
     */
    readonly createdVirtualCollectionName?: string;
}

/**
 * Options to control conditional http requests.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Conditional_requests}
 * @see {@link https://developer.mozilla.org/en-US/docs/Glossary/CORS-safelisted_response_header}
 */
export interface ConditionalOptions {

    /**
     * If set (to true) [the default is false], then use the etag of a previous operation
     * to perform a conditional operation.
     *
     * If the resource does not have an etag from a previous operation an error is throw.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/If-Match}
     */
    readonly ifMatch?: boolean;

    /**
     *
     * Note: the last modified header will be available regardless of the CORS response as it
     * is ['safe listed'](https://developer.mozilla.org/en-US/docs/Glossary/CORS-safelisted_response_header) header.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/If-Unmodified-Since}
     */
    readonly ifUnmodifiedSince?: boolean;
}

export interface RequestHeaderOptions {
    readonly contentType?: string;
}

export interface ApiExtraGetOptions {
    /**
     * A flag to remove/discard nested resources that are tracked and might be obsolete.
     *
     * Note: As per my understanding this is an existing limitation on semantic network
     * to be able to realize which resource are obsolete after a parent resource is updated.
     *
     * Detail:
     * When a named resource is fetched on a parent resource using a force load:
     * ```
     *     ApiUtil.get(resource, {name: 'subResourceName', forceLoad: true})
     * ```
     *
     * ...the parent resource is force loaded and the `links[]` possibly updated.
     * When the library proceeds to load the sub resource it will check if the resource is already tracked on the parent.
     * There is no association in the library between the tracked resources and the links[], so
     * The library **does not perform any checks to whether the sub resource tracked in the parent
     * is in sync to the new link that references the sub-resource**.
     *
     * This could lead to unexpected behaviour which results in a:
     * - a fresh copy of the parent resource (expected).
     * - BUT an obsolete sub-resource which was refreshed (force loaded) but is not in sync with the links[] (this is not expected)
     *
     * Related code in semantic network just for reference
     * ```
     *  export class NamedRepresentationFactory {
     *
     *  public static async load<TReturn extends LinkedRepresentation,
     *         T extends LinkedRepresentation | TReturn = LinkedRepresentation,
     *         TResult extends TReturn = T extends TReturn ? T : TReturn>(
     *         resource: T,
     *         options?: ResourceQueryOptions & ResourceAssignOptions & LoaderJobOptions): Promise<Nullable<Tracked<TResult>>> {
     *
     *          .
     *          .
     *         if (rel && name) {
     *             if (TrackedRepresentationUtil.isTracked(resource, name)) {
     *             TODO: here there should be a way to check if the resource is obsolete
     *                   the check could be a reverse map of the tracked singleton/collection sets and the rel/name
     * ```
     *
     */
    forceLoadNamedResource?: boolean;
}

export type GetOptions = ApiGetOptions & ApiExtraGetOptions;

export type CreateOptions =
    ApiCreateOptions
    & ResourceAssignOptions
    & RequestHeaderOptions
    & AxiosRequestConfig
    & ApiExtraCreateOptions;

export type UpdateOptions = ConditionalOptions & ApiUpdateOptions & RequestHeaderOptions & AxiosRequestConfig;

export type Representation = {
    [key: string]: unknown;
};

/**
 * Get the name of a child resource based on either:
 *   1. an explicit name is the 'name' option
 *   2. a camel case name based on the link relation using a custom naming strategy
 *   3. a camel case name based on the link relation in the options
 */
export function getName<T extends LinkedRepresentation>(resource: T, options?: ApiOptions): string {
    const { rel, name, nameStrategy } = { ...options };
    if (name) {
        return name;
    }
    if (rel) {
        if (nameStrategy) {
            const relName = nameStrategy(rel);
            if (relName) {
                return relName;
            }
        }
        const defaultName = NamedRepresentationFactory.defaultNameStrategy(rel, resource);
        if (defaultName) {
            return defaultName;
        }
    }
    throw new Error('Failed to map name');
}

/**
 * Discard a named resource from the 'network of data'. This is used when a user
 * logs out, thus 'revoking' the data.
 *
 * This is a synchronous function that only acts on the local state.
 */
export function discardResource<T extends LinkedRepresentation>(
    resource: T, rel: RelationshipType, options?: ApiOptions): void {
    const name = getName(resource, { rel, ...options });
    if (TrackedRepresentationUtil.isTracked(resource, name)) {
        const namedResource = RepresentationUtil.getProperty(resource, name) as unknown as LinkedRepresentation;
        if (namedResource) {
            if (instanceOfTrackedRepresentation(namedResource)) {
                const trackedState = TrackedRepresentationUtil.getState(namedResource);
                trackedState.status = Status.deleted;
                removeProperty(resource, name);
            }
        }
    }
}

/**
 * - Remove the tracked representation from the target (meaning, removes from the internal 'state'
 * object that tracks 'collection' and 'singleton')
 *
 * TODO: get this function added to RepresentationUtil.
 */
export function removeProperty<T extends LinkedRepresentation, U extends LinkedRepresentation, K extends Extract<keyof T, string>>(
    target: T,
    prop: K | string,
    _options?: ResourceAssignOptions): T {
    if (instanceOfTrackedRepresentation(target)) {
        const resourceToDelete = RepresentationUtil.getProperty(target, prop) as unknown as U;
        if (resourceToDelete) {
            if (instanceOfTrackedRepresentation(resourceToDelete)) {
                // remove as a tracked collection/singleton on state
                if (instanceOfCollection(resourceToDelete)) {
                    TrackedRepresentationUtil.getState(target).collection.delete(prop as string);
                } else {
                    TrackedRepresentationUtil.getState(target).singleton.delete(prop as string);
                }
            } else {
                log.warn(
                    'target is not a tracked representation and cannot delete resource; \'%s\'',
                    LinkUtil.getUri(target, LinkRelation.self));
            }
        }
    }

    delete target[prop as K];

    return target as T;
}

export function makeSparseResourceFromUri<T extends LinkedRepresentation>(uri: Uri): Tracked<T> {
    return SparseRepresentationFactory.make<T>({ sparseType: 'singleton', uri });
}

async function optionalChild(
    context: LinkedRepresentation,
    createdRel: RelationshipType | undefined,
    createdName: string | undefined,
    createdSparseType: ResourceType | undefined,
    options?: CreateOptions): Promise<LinkedRepresentation> {
    if (createdRel) {
        const url = LinkUtil.getUri(context, createdRel);
        if (url) {
            const createResourceName = getName(context, {
                ...options,
                rel: createdRel,
                name: createdName,
            }) as keyof LinkedRepresentation;
            const existingValue = getPropertyValue(
                context, createResourceName);
            if (existingValue) {
                if (instanceOfTrackedRepresentation(existingValue)) {
                    return existingValue;
                } else {
                    throw new Error(`The child ${createResourceName} is not tracked`);
                }
            }
            if (createdSparseType) {
                const sparse = SparseRepresentationFactory.make({ sparseType: createdSparseType, uri: url });
                ResourceUtil.setPropertyButDontTrack(context, createResourceName, sparse, options);
                return sparse;
            }
            const hydrated = await ApiUtil.get<LinkedRepresentation>(
                context, { includeItems: false, ...options, rel: createdRel, name: createdName });
            if (hydrated) {
                return hydrated;
            } else {
                throw new Error(`Failed to get resource ${createdRel}`);
            }
        } else {
            throw new Error(`No such relationship ${createdRel}`);
        }
    }
    return context;
}

function makeVirtualCollection<T extends LinkedRepresentation, TResult extends CollectionRepresentation>(
    child: T,
    name: keyof T & string, options?: CreateOptions): TResult {
    const virtualCollection = child[name];
    if (virtualCollection) {
        if (instanceOfTrackedRepresentation(virtualCollection)) {
            if (instanceOfCollection(virtualCollection)) {
                return virtualCollection as unknown as TResult;
            } else {
                throw new Error(`Pool ${virtualCollection} is not a collection`);
            }
        } else {
            throw new Error(`Attribute ${virtualCollection} is not a tracked resource`);
        }
    } else {
        const virtualCollection = SparseRepresentationFactory.make<TResult>(
            { ...options, sparseType: 'collection', status: Status.virtual });
        SingletonMerger.add(child, name, virtualCollection, options);
        return virtualCollection;
    }
}

export async function _get<TReturn extends LinkedRepresentation,
    T extends LinkedRepresentation | TReturn = LinkedRepresentation,
    TResult extends TReturn = T extends TReturn ? T : TReturn>(
    resource: T | Tracked<T>,
    options?: GetOptions): Promise<Nullable<TResult | Tracked<TResult>>> {
    const {
        rel = undefined,
        forceLoad = false,
        forceLoadNamedResource = false,
        name = NamedRepresentationFactory.defaultNameStrategy(rel, resource),
    } = { ...options };

    if (options && forceLoadNamedResource) {
        // avoid passing down unnecessary properties that the library does not understand.
        delete options.forceLoadNamedResource;
    }

    if (forceLoadNamedResource) {
        if (forceLoad) {
            const relIsNotSelfOrEmpty = rel && rel !== LinkRelation.self;
            if (relIsNotSelfOrEmpty && rel && name) {
                removeProperty(resource, name);

                return ApiUtil.get(resource, options);
            } else {
                throw new Error(`rel: '${rel}' and name: '${name}' need to be present when using 'forceLoadNamedResource'`);
            }
        } else {
            throw new Error(`'forceLoadNamedResource' only works with forceLoad`);
        }
    } else {
        return ApiUtil.get(resource, options);
    }
}

/**
 * This is a simple create that performs a POST using the provided document.
 *
 * The ApiUtil create function is setup to work with a 'create-form' and a 'submit'. This
 * requires an API that has more maturity (in terms of the Richardson maturity model)
 * that is implemented on the Formus Django rest framework implementation.
 *
 * Note: the 'rel' attribute of the options is used to determine the POST noun. It is not used
 * to set the name of the new resource in parent 'on' context.
 *
 * Note: As of writing the semantic-network library incorrectly requires the post
 * data (create data) to be based on a LinkedRepresentation. This is a false dependency.
 *
 * Note: This is a low level semantic-network style function and returns undefined if the
 * operation fails, not null (which would be Vue 2 reactive property friendly).
 */
export async function createWithoutForm<TResult extends LinkedRepresentation>(
    document: LinkedRepresentation | Representation | UriList | LinkedRepresentation[] | Representation[],
    options?: CreateOptions): Promise<TResult | undefined> {
    // IMPORTANT: Using the concept of 'kwargs' (python), remove those options from the {@param options}
    // argument that have logically been consumed by this part of the code, before calling the lower
    // layers. This is important for the options that go to Axios as it makes a brute force recursive
    // copy of the options which can result in an infinitely recursive copy (and a crash).
    const {
        createdRel,
        createdName,
        createdSparseType,
        name: _name,
        createContext = undefined,
        createdVirtualCollectionName,
        singletonName,
        singletonRel,
        singletonTitle,
        ...createOptions
    } = { ...options };
    if (instanceOfTrackedRepresentation(createContext)) {
        // Create the resource
        const item = await TrackedRepresentationFactory.create<LinkedRepresentation, TResult>(
            createContext, document as unknown as DocumentRepresentation, createOptions);
        if (item) {
            // The actual resource at the origin server has been created and a sparse resource
            // based on the URI of that resource has been created. Now it needs to be added to the
            // in memory tree of resources so that it can be found. The supported cases are:
            //    1. set as a named resource (e.g. 'ActiveStudy' on a case)
            //    2. add in a named collection (e.g. 'studies' on a case)
            //    3. set in a location specified by a 'rel', where the URL is an 'alias' style URL
            //    4. store in a virtual collection (useful for search results)
            //    5. do not store the sparse resource (only return the resource to the caller)
            //
            // Optionally the `options.createdRel`, and `options.createdName' can be used to reference a
            // resource to store the object, which is relative to the createContext. If this object is not
            // present then it is not possible to know whether it is a singleton or a collection object
            // without imposing a round-trip to the origin server to get the resource. The
            // `options.createdSparseType` can be set to save this round trip.
            //

            const childContext = await optionalChild(createContext, createdRel, createdName, createdSparseType, options);

            // If the created resource is 'virtual' (e.g. a search resource), then add the resource to the virtual
            // collection; then stop and don't add it anywhere else.
            if (createdVirtualCollectionName) {
                const virtualCollection = makeVirtualCollection(
                    childContext, createdVirtualCollectionName as keyof LinkedRepresentation, options);
                RepresentationUtil.addItemToCollection(virtualCollection, item);
                return item;
            }

            // add the new resource to a collection *or* as a singleton
            if (instanceOfCollection(childContext)) {
                RepresentationUtil.addItemToCollection(childContext, item);
                return item;
            } else {
                if (singletonRel || singletonName) {
                    const singletonObjectName = getName(
                        childContext, { ...createOptions, name: singletonName, rel: singletonRel });
                    TrackedRepresentationUtil.add(childContext, singletonObjectName, item, options);
                }
                const itemUri = getRequiredUri(item, LinkRelation.self);
                if (singletonRel) {
                    ResourceUtil.setLink2(childContext, singletonRel, singletonTitle, itemUri);
                }
                return item;
            }
        } else {
            if (createOptions.signal?.aborted) {
                log.debug('request cancelled');
                return undefined;
            }
        }
        throw new Error(`Error creating resource`);
    }
    throw new Error(`Error creating resource: parent context not tracked`);
}

/**
 * Update a resource (this is the main entry point).
 *
 * Logically this method needs to have a local 'resource' and an update document which is a partial
 * of the resource (i.e. the fields that need to be updated). This method performs
 * a wire level update, and if the changes are accepted merges the document back into the resource.
 */
export async function update<T extends LinkedRepresentation>(
    resource: T,
    updateDocument: Partial<T>,
    options?: UpdateOptions): Promise<T | never> {
    return await conditionalUpdate(resource, updateDocument, options);
}

/**
 * Add support for conditional updates.
 */
export async function conditionalUpdate<T extends LinkedRepresentation>(
    resource: T,
    updateDocument: Partial<T>,
    options?: UpdateOptions): Promise<T | never> {
    const { ifMatch, ifUnmodifiedSince, headers = {} as AxiosRequestHeaders, ...otherOptions } = { ...options };
    if (instanceOfTrackedRepresentation(resource)) {
        const trackedState = TrackedRepresentationUtil.getState(resource);
        if (trackedState) {
            if (ifMatch) {
                const eTag = trackedState.headers.etag; // Note: axios used lower case names
                if (eTag) {
                    // Note setting this field will require CORS allow headers to include 'If-Match'.
                    headers['If-Match'] = eTag;
                } else {
                    // Likely reasons include:
                    // - resource doesn't support etags
                    // - a GET on the resource hasn't been performed
                    // - CORS is blocking the eTag
                    throw new Error(`Entity tag header not available`);
                }
            }
            if (ifUnmodifiedSince) {
                const lastModified = trackedState.headers['last-modified']; // Note: axios used lower case names
                if (lastModified) {
                    // Note setting this field will require CORS allow headers to include 'If-Unmodified-Since'.
                    headers['If-Unmodified-Since'] = lastModified;
                } else {
                    // Likely reasons include:
                    // - resource doesn't support last modified
                    // - a GET on the resource hasn't been performed
                    throw new Error(`Last modified header not available`);
                }
            }
            return await compatabilityUpdate<T>(resource, updateDocument, { ...otherOptions, headers });
        } else {
            throw new Error(`Tracked resource has no state`);
        }
    } else {
        throw new Error(`Resource must be tracked`);
    }
}

/**
 * Update a resource.
 *
 * Logically this method needs to have a local 'resource' and an update document which is a partial
 * of the resource (i.e. the fields that need to be updated). This method performs
 * a wire level update, and if the changes are accepted merges the document back into the resource.
 *
 * Note: this method exists to change the signature of the {@parameter options}.
 */
export async function compatabilityUpdate<T extends LinkedRepresentation>(
    resource: T,
    updateDocument: Partial<T>,
    options?: UpdateOptions): Promise<T | never> {
    return await ApiUtil.update<T>(resource, updateDocument, options);
}
