
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { StudyRepresentation, StudyState } from '@/lib/api/representation/case/study/StudyRepresentation';
    import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import MessageLevel from '@/components/case-settings/MessageLevel.vue';
    import { DicomFileMessages, SeriesMessageRepresentation } from '@/lib/api/representation/SeriesRepresentation';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { LinkUtil } from 'semantic-link';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import DraggableResource from '@/components/case-manage/DraggableResource.vue';
    import { StudyFittingResource } from '@/lib/api/resource/case/study/StudyFittingResource';
    import { formatLength } from '@/lib/filters/format/formatLength';
    import { formatNumber } from '@/lib/filters/format/formatNumber';
    import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
    import StudyMeasurementsLoader from '@/components/case-manage/study/StudyMeasurementsLoader.vue';
    import { Product } from '@/lib/api/representation/ProductRepresentation';
    import anylogger from 'anylogger';
    import StudyViewer from '@/components/case-manage/study/StudyViewer.vue';
    import DuplicateStudyFiles from '@/components/case-manage/study/DuplicateStudyFiles.vue';
    import DuplicateStudyModels from '@/components/case-manage/study/DuplicateStudyModels.vue';
    import AxiosUtil from '@/lib/AxiosUtil';

    interface FileMessage {
        filename: string;
        message: SeriesMessageRepresentation;
    }

    const log = anylogger('Study');

    /**
     * This is a case component **DEBUG** component that shows the admin the state of study
     */
    @Component({
        components: {
            StudyViewer,
            MessageLevel,
            HyperlinkButton,
            DraggableResource,
            StudyMeasurementsLoader,
            DuplicateStudyFiles,
            DuplicateStudyModels,
        },
    })
    export default class Study extends Vue {
        @Prop({ required: true })
        study!: StudyRepresentation;

        @Prop({ required: true })
        project!: CaseRepresentation;

        /** The URI of the active study on the case */
        @Prop({ required: true })
        public activeStudyUri!: string;

        protected isLoading = false;

        /** import the study state URIs for the template (above) */
        protected studyState = StudyState;

        protected selectedDataTab = 'messages';

        /** whether the delete study action is 'busy' */
        protected onDeleteStudyActive = false;
        /** Whether the delete study dialog is visible */
        protected isDeleteVisible = false;

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            await CaseStudyResource.getStudy(this.study, this.$apiOptions);
            await StudyFittingResource.get(this.study, this.$apiOptions);
        }

        /**
         * Determine if this study is the 'active' study
         */
        protected get isActive(): boolean {
            const canonical = LinkUtil.getUri(this.study, LinkRelation.canonical);
            if (canonical && canonical === this.activeStudyUri) {
                return true;
            }
            const self = LinkUtil.getUri(this.study, LinkRelation.self);
            if (self && self === this.activeStudyUri) {
                return true;
            }
            return false;
        }

        protected caseProduct(): Product | null {
            if (this.project) {
                const title = LinkUtil.getTitle(this.project, LinkRelation.product);
                if (title) {
                    return title as Product;
                }
            }
            return null;
        }

        /**
         * Flatten the normalised excluded files messages into a flat array of {@link FileMessage}
         */
        protected excludeFileMessages(): FileMessage[] {
            function makeFileMessage(filename: string, m: SeriesMessageRepresentation): FileMessage {
                return {
                    filename,
                    message: m,
                } as FileMessage;
            }

            function makeX(excludedFiles: DicomFileMessages[]): FileMessage[][] {
                return excludedFiles.map<FileMessage[]>(
                    (f) => f.messages ? f.messages.map<FileMessage>(m => makeFileMessage(f.name, m)) : []);
            }

            const xx: FileMessage[] = [];
            return this.study.excluded_files ? xx.concat(...makeX(this.study.excluded_files)) : [];
        }

        protected get fittingHeaders(): { text: string, value: string }[] {
            return [
                {
                    text: 'Model Name',
                    value: 'name',
                },
                {
                    text: 'PC mahalanobis distance',
                    value: 'pcMahalanobisDistance',
                },
                {
                    text: 'PC RMS Error',
                    value: 'pcRmsError',
                },
                {
                    text: 'RBF RMS Error',
                    value: 'rbfRmsError',
                },
            ];
        }

        protected get fittingItems() {
            if (this.study?.fitting?.fit) {
                return this.study.fitting.fit.map(item => {
                    return {
                        name: item.name,
                        pcMahalanobisDistance: formatNumber(item.principle_component.mahalanobis_distance, 1),
                        pcRmsError: formatLength(item.principle_component.rms_error, 2),
                        rbfRmsError: formatLength(item.radial_basis_function.rms_error, 2),
                    };
                });
            }
            return [];
        }

        protected get selfUri(): string {
            return getRequiredUri(this.study, LinkRelation.self);
        }

        /**
         * Mark a study as deleted.
         */
        protected async onDelete(): Promise<void> {
            this.onDeleteStudyActive = true;
            try {
                if (this.study) {
                    const studyUri = LinkUtil.getUri(this.study, LinkRelation.self);
                    if (studyUri) {
                        try {
                            const r = await this.$http.delete(studyUri);
                            if (r.status === 204) {
                                //
                                // TODO: remove the study from the project
                                //
                                this.isDeleteVisible = false;
                            } else {
                                log.warn('Failed to delete study: %d', r.status);
                            }
                        } catch (err: unknown) {
                            if (AxiosUtil.isAxiosError(err) && err.response?.status === 409) {
                                log.info('Study not deleted - is it in use?');
                                // TODO: update the UI to indicate an error
                            } else {
                                throw err;
                            }
                        }
                    } else {
                        log.error('Invalid study');
                    }
                } else {
                    log.error('No study');
                }
            } finally {
                this.onDeleteStudyActive = false;
            }
        }
    }
