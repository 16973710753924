import AxisLine3D from '@/debugView/assembly/geometry-shapes/AxisLine3D';
import { asVector3, AsVector3 } from '@/lib/base/ThreeUtil';
import Angle3D from '@/debugView/assembly/geometry-shapes/Angle3D';
import MaterialUtils from '@/lib/viewer/MaterialUtils';
import Plane3D from '@/debugView/assembly/geometry-shapes/Plane3D';
import { MeshBasicMaterialParameters } from 'three/src/materials/MeshBasicMaterial';

/**
 * The parameters to construct an {@link AxisLine3D}
 */
export interface AxisConstructionParameters {
    /**
     * The length of the line to be constructed
     */
    readonly length?: number;

    /**
     * The radius of the cylinder object used to render a line
     */
    readonly radius?: number;

    /**
     * The hexadecimal color
     */
    readonly color?: number;

    /**
     * The offsets of the origin point along the provided axis of the cylindrical line do be created.
     */
    readonly offset?: number;
}

const DEFAULT_AXIS_PARAMETERS = {
    length: 180,
    radius: 0.3,
    offset: -30,
};

export default class GeometryShapesFactory {
    /** Makes an axis line 3d. Defaults are hardcoded */
    public static makeAxis3D(
        name: string,
        position: AsVector3,
        axis: AsVector3,
        params: AxisConstructionParameters = DEFAULT_AXIS_PARAMETERS): AxisLine3D {
        const paramsWithDefaults = { ...DEFAULT_AXIS_PARAMETERS, ...params };
        return new AxisLine3D(
            { name },
            asVector3(position),
            asVector3(axis),
            paramsWithDefaults.length,
            paramsWithDefaults.radius,
            paramsWithDefaults.color ?? MaterialUtils.randomColor(),
            paramsWithDefaults.offset);
    }

    /** Makes an angle from two axis line 3d */
    public static makeAngle3D(name: string, position: AsVector3, reference: AxisLine3D, target: AxisLine3D): Angle3D {
        return new Angle3D({ name }, position, reference, target);
    }

    /** Makes a plane 3d */
    public static makePlane3D(
        name: string,
        position: AsVector3,
        direction: AsVector3,
        materialParameters: MeshBasicMaterialParameters = {},
        up?: AsVector3): Plane3D {
        return new Plane3D({ name }, position, direction, materialParameters, undefined, up);
    }
}
