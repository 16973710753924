import { LandmarkRepresentation } from '@/lib/api/representation/base/landmark/LandmarkRepresentation';
import { Product } from '@/lib/api/representation/ProductRepresentation';

/**
 * Utility class for {@link LandmarkRepresentation}
 */
export class LandmarkUtil {
    /**
     * Get a landmark by group name and landmark name.
     */
    public static getLandmark(landmarks: LandmarkRepresentation[], name: string): LandmarkRepresentation | null {
        return landmarks.find((l) => l.name === name) ?? null;
    }

    /**
     * @returns i18n identifier for the group name
     */
    public static i18nGroupId(product: Product, name: string): string {
        return `landmark.${product}.group.${name.replace(/\./g, '-')}.name`;
    }

    /**
     * @returns the i18n id for a a landmark.
     * @see {@link Measurements.en.json5}
     */
    public static i18nId(product: Product, group: string, name: string): string {
        return `landmark.${product}.${group}.${name.replace(/\./g, '-')}`;
    }
}
