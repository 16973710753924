import ResourceService from '@/lib/api/ResourceService';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { CacheOptions, update } from '@/lib/semanticNetworkMigrationUtils';
import { SurgicalSpecificationRepresentation } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import { ProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';
import { getIfLink } from '@/lib/api/SemanticNetworkUtils';
import { CollectionRepresentation } from 'semantic-link';
import { ApiUtil } from '@/lib/semantic-network';

export default class SurgicalSpecificationResource implements ResourceService {
    /**
     * Gets a specification from the cache
     */
    public static async getSpecification<T extends SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>>(
        specification: T, options?: CacheOptions): Promise<T | null> {
        return await ApiUtil.get<T>(specification, options) ?? null;
    }

    /**
     * Retrieve the surgical specifications for this case
     */
    public static async getSurgicalSpecification<T extends SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>>(
        caseResource: CaseRepresentation,
        options?: CacheOptions): Promise<T | null> {
        return await getIfLink<CaseRepresentation, T>(caseResource, LinkRelation.surgicalSpecification, options);
    }

    /**
     * Get the collection of all surgical specification for a project. This will include both 'user'
     * and 'acid' surgical specification.
     *
     * The surgical specification has a lifecycle that it is created on the project and can be a user/acid
     * surgical specification. After it is created it can be used (snap-shotted) as a version that is used
     * by a surgical template. This means that the user/acid surgical template may or may not be associated
     * with the current user/acid surgical template.
     */
    public static async getCaseSurgicalSpecifications<T extends SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>>(
        caseResource: CaseRepresentation, options?: CacheOptions): Promise<CollectionRepresentation<T> | null> {
        return await ApiUtil.get<CollectionRepresentation<T>>(
            caseResource, { rel: LinkRelation.surgicalSpecifications, ...options }) ?? null;
    }

    /**
     * Get a sparse list of history records for the surgical specification.
     */
    public static async getHistory<T extends SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>>(
        surgicalSpecification: T, options?: CacheOptions):
        Promise<CollectionRepresentation<T> | null> {
        return await ApiUtil.get<CollectionRepresentation<T>>(
            surgicalSpecification, { rel: LinkRelation.history, includeItems: false, ...options }) ?? null;
    }

    /**
     * Retrieve the surgical preference for a surgical specification
     */
    public static async getSurgeonPreferences<T extends SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>,
        TResult extends ProductPreferencesRepresentation>(
        surgicalSpecification: T,
        options?: CacheOptions): Promise<TResult | null> {
        return await getIfLink<T, TResult>(surgicalSpecification, LinkRelation.preferences, options);
    }

    public static async updateSurgicalSpecification<T extends SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>>(
        surgicalSpecification: T, updatedSurgicalSpecification: T, options?: CacheOptions): Promise<T> {
        return update(surgicalSpecification, updatedSurgicalSpecification, options);
    }

    /**
     * Retrieve the current (and "new") surgical specification for this case {@see getSurgicalSpecification}
     * or throws an exception
     */
    public static async getSurgicalSpecificationOrThrow(
        caseResource: CaseRepresentation,
        options?: CacheOptions): Promise<SurgicalSpecificationRepresentation<ProductPreferencesRepresentation> | never> {
        const specification = await this.getSurgicalSpecification(caseResource, options);

        if (specification) {
            return specification;
        }

        throw new Error('No surgical specification exist on case resource');
    }
}
