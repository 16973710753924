import { Line3, Plane, Vector3 } from 'three';
import { compact } from 'lodash';
import { FaceUtil } from '@/lib/base/three-js/FaceUtil';
import { Face3Positions } from '@/lib/viewer/component-coverage/BufferGeometryUtil';

/**
 * Utility to deal with three js implementation of a {@Plane}
 *
 * @see {@link https://threejs.org/docs/?q=Plane#api/en/math/Plane}
 */
export class PlaneUtil {
    /**
     * Get any intersection points from a plane that interests a face lines.
     *
     * Background:
     * If a face is intersected it will be intersected:
     * - on **2 points.** if the plane is not coplanar to any of the lines.
     * - on **3 points.** if the plane is coplanar to any of the lines.
     *
     * The current strategy is to make 3 lines from the face vertices, and check whether each line
     * intersects the plane.
     *
     * Given a face = F1, intersected by a Plane = P.
     *
     * If neither lines are in the plane:
     *
     *```
     *                           A                                  Intersections:
     *                           |\                                 Line AB is intersected.
     *                           | \                                Line AC is intersected.
     *                           |  \                               Line BC is not intersected.
     *                    _______*___*________________P
     *                           |    \
     *                           | F1  \
     *                           |______\
     *                           B       C
     *
     *                           A                                  Intersections:
     *                           |\                                 Line AB is intersected (edge point is on the plane)
     *                           | \                                Line AC is intersected (edge point is on the plane)
     *                           |  \                               Line BC is intersected (is on the plane)
     *                           |   \
     *                           |    \
     *                           | F1  \
     *                   ________*______*_________
     *                           B       C
     *```
     *
     * Note that if two faces are intersected as demonstrated below, there is one point that will be duplicated,
     * given line AC belong to both faces
     *
     *```
     *
     *                           A______D
     *                           |\     |
     *                           | \ F2 |
     *                           |  \   |
     *                    _______*___*__*______________Plane
     *                           |    \ |
     *                           | F1  \|
     *                           |______|
     *                           B       C
     * ```
     */
    static intersectFace(worldPlane: Plane, facePositions: Face3Positions): Vector3[] {
        const { lineAB, lineBC, lineCA } = FaceUtil.makeLines(facePositions);
        return PlaneUtil.intersectLines(worldPlane, [lineAB, lineBC, lineCA]);
    }

    /**
     * Get the intersection point of a plane and a line.
     */
    private static intersectLine(plane: Plane, line: Line3): Vector3 | undefined {
        const pointOfIntersection = new Vector3();
        const pointIntersects = plane.intersectLine(line, pointOfIntersection);
        if (pointIntersects) {
            return pointOfIntersection.clone();
        }
    }

    /**
     * @return the intersection points where a plane intersect the lines. Null/undefined values are excluded.
     */
    private static intersectLines(worldPlane: Plane, lines: Line3[]): Vector3[] {
        return compact(lines.map((line: Line3) => PlaneUtil.intersectLine(worldPlane, line)));
    }
}
