
    import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
    import plannerEventBus from '@/lib/planning/events/PlannerEventBus';
    import ProjectStore from '@/hipPlanner/components/state/project/ProjectStore';
    import { Route } from 'vue-router';
    import { makeAbsolute } from '@/router/UriMapping';
    import anylogger from 'anylogger';
    import ApiResource from '@/lib/api/resource/ApiResource';
    import { RefreshAction } from '@/lib/refresh-actions/RefreshAction';

    const log = anylogger('ProjectStoreProvider');

    const REFRESH_DATA_INTERVAL_TIME = 30 * 1000;

    /**
     * A component in charge of setting up the [hip planning global state]{@link HipTemplateController}
     * and providing it to the children components through the provide/inject pattern.
     */
    @Component({
        components: {},
    })
    export default class ProjectStoreProvider extends Vue {
        private refresh = new RefreshAction(
            REFRESH_DATA_INTERVAL_TIME /* ms */, this.initOrReloadStore, { intersected: true });

        @Prop({ required: true })
        public readonly apiUri!: string;

        @Watch('$route')
        protected async onRouteChange(to: Route, from: Route): Promise<void> {
            log.info('Change from %s to %s', from.params.apiUri, to.params.apiUri);
            return await this.initStore(makeAbsolute(to.params.apiUri));
        }

        /**
         * The single project top view global state.
         *
         * It can be included in any children that wants to react to the global state:
         *
         * ```
         *    @InjectReactive()
         *    protected state!: ProjectState;
         * ```
         */
        @ProvideReactive()
        public store: ProjectStore | null = null;

        protected async mounted(): Promise<void> {
            await ApiResource.getApi(this.$api, this.$apiOptions);
            this.refresh.enable().onMounted();
        }

        protected beforeDestroy(): void {
            this.refresh.onDestroy();
            this.store?.stop();
        }

        private async initStore(apiUri: string): Promise<void> {
            this.store = new ProjectStore(apiUri, plannerEventBus, this.$api, this.$apiOptions);

            // @ts-ignore HACK for dev debugging. Should not make prod environments
            // window.store = this.store;

            await this.store.start();

            this.$emit('initialised', this.store);
        }

        private async initOrReloadStore(): Promise<void> {
            if (this.store) {
                await this.store.reloadState();
            } else {
                await this.initStore(this.apiUri);
            }
        }
    }
