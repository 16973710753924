import { DataSet, parsePN } from 'dicom-parser';
import { DateTime } from 'luxon';
import { PatientName } from '@/lib/dicom/PatientName';

/**
 * Support for reading values from a {@link DataSet} from the 'dicomParser' package.
 */
export class DataSetUtil {
    public static getUiValue(dataSet: DataSet, tag: string): string | undefined {
        const count = dataSet.numStringValues(tag);
        if (count === 1) {
            return dataSet.string(tag, 0);
        }
        return undefined;
    }

    public static getIsValue(dataSet: DataSet, tag: string): number | undefined {
        const count = dataSet.numStringValues(tag);
        if (count === 1) {
            return dataSet.intString(tag, 0);
        }
        return undefined;
    }

    public static getUsValue(dataSet: DataSet, tag: string): number | undefined {
        return dataSet.uint16(tag, 0);
    }

    /**
     * Get CS (Code String) values.
     */
    public static getCsValues(dataSet: DataSet, tag: string): string[] {
        const csValues: string[] = [];

        const count = dataSet.numStringValues(tag);
        for (let index = 0; index < (count || 0); ++index) {
            const codeValue = dataSet.string(tag, index);
            if (codeValue) {
                csValues.push(codeValue);
            }
        }
        return csValues;
    }

    /**
     * Get CS (Code String) value, with multiplicity of one (1).
     */
    public static getCsValue(dataSet: DataSet, tag: string): string | undefined {
        const count = dataSet.numStringValues(tag);
        if (count === 1) {
            return dataSet.string(tag, 0);
        }
        return undefined;
    }

    public static getDsValues(dataSet: DataSet, tag: string): Array<number | undefined> | undefined {
        const count = dataSet.numStringValues(tag);
        let dsValues: Array<number | undefined> | undefined;
        if (count && count > 0) {
            dsValues = [];
            for (let index = 0; index < count; ++index) {
                const aNumber = dataSet.floatString(tag, index);
                dsValues.push(aNumber);
            }
        }
        return dsValues;
    }

    public static getDsValue(dataSet: DataSet, tag: string): number | undefined {
        const count = dataSet.numStringValues(tag);
        if (count === 1) {
            return dataSet.floatString(tag, 0);
        }
        return undefined;
    }

    /**
     * Get a date (DA) field. This is a date without a time of date (i.e. it is not a timestamp)
     *
     * > A string of characters of the format YYYYMMDD; where YYYY shall contain year, MM shall
     * > contain the month, and DD shall contain the day, interpreted as a date of the Gregorian
     * > calendar system.
     * >      Example:
     * > "19930822" would represent August 22, 1993.
     */
    public static getDaValue(dataSet: DataSet, tag: string): Date | undefined {
        const count = dataSet.numStringValues(tag);
        if (count === 1) {
            const dateStr = dataSet.string(tag, 0);
            if (dateStr) {
                // The Dicom parser code has support for parse the date string into components, however
                // luxon is available and is better tested and implemented.
                //      return parseDA()
                return this.getDate(dateStr);
            }
        } // else not exactly one value
        return undefined;
    }

    /**
     * The Dicom parser code has support for parse the date string into components, however
     * luxon is available and is better tested and implemented.
     *
     * @return a date from if the date string follows the dicom format 'yyyyMMdd'
     */
    public static getDate(dateStr: string) {
        return DateTime.fromFormat(dateStr, 'yyyyMMdd').toJSDate();
    }

    /**
     * Get a patient name (PN) field.
     *
     * @see {@link http://dicom.nema.org/dicom/2013/output/chtml/part05/sect_H.3.html}
     */
    public static getPnValue(dataSet: DataSet, tag: string): PatientName | undefined {
        const count = dataSet.numStringValues(tag);
        if (count === 1) {
            return parsePN(dataSet.string(tag, 0));
        } // else not exactly one value
        return undefined;
    }

    /**
     * Get the length of a field which can be a 'OB' or a 'OW' value representation.
     *
     * Note: The VR field is not deserialised, so the field value representation (VR) can not be checked.
     */
    public static getObOrOwLengthValue(dataSet: DataSet, tag: string): number | undefined {
        const info = dataSet.elements[tag];
        if (info) {
            return info.length;
        }
        return undefined;
    }
}
