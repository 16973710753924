import { AxiosRequestConfig } from 'axios';
import { keys } from 'lodash';

/**
 * Reduce an object to only contain properties from the [AxiosRequestConfig]{@link AxiosRequestConfig}
 *
 * @see https://stackoverflow.com/a/58280921
 * Note: This was introduced as semantic-network was throwing an error as a result of passing a deep recursive object that
 * is not an axios request configuration.
 */
export function reduceToAxiosRequestConfig(maybeOptions?: AxiosRequestConfig | any): Partial<AxiosRequestConfig> {
    let reduced: AxiosRequestConfig = {};

    if (!maybeOptions) {
        return reduced;
    }

    keys(AXIOS_REQUEST_CONFIG_ENUMERATION).forEach((key: string) => {
        if (maybeOptions[key] !== undefined) {
            reduced[key as keyof AxiosRequestConfig] = maybeOptions[key];
        }
    });

    return reduced;
}

type KeysEnumeration<T> = { [P in keyof Required<T>]: T[P] };
/**
 * An object to list the properties of the AxiosRequestConfig. Given it is of type {@link KeysEnumeration<AxiosRequestConfig>}
 * the compilation process will force as to keep the keys enumurated in sync with Axios. This means that if
 * the axios library is updated and properties added/deleted to the AxiosRequestConfig interface,
 * this will let us know.
 *
 * As described [here]{@link https://stackoverflow.com/a/54308812} there is no easy way to enumerate
 * the keys of an interface that do not implicate duplicate of code.
 * The best we can get at the moment is type safe.
 *
 * @see https://stackoverflow.com/a/58280921
 */
const AXIOS_REQUEST_CONFIG_ENUMERATION: KeysEnumeration<AxiosRequestConfig> = {
    url: undefined,
    method: undefined,
    baseURL: undefined,
    transformRequest: undefined,
    transformResponse: undefined,
    headers: undefined,
    params: undefined,
    paramsSerializer: undefined,
    data: undefined,
    timeout: undefined,
    timeoutErrorMessage: undefined,
    withCredentials: undefined,
    adapter: undefined,
    auth: undefined,
    responseType: undefined,
    responseEncoding: undefined,
    xsrfCookieName: undefined,
    xsrfHeaderName: undefined,
    onUploadProgress: undefined,
    onDownloadProgress: undefined,
    maxContentLength: undefined,
    validateStatus: undefined,
    maxBodyLength: undefined,
    maxRedirects: undefined,
    beforeRedirect: undefined,
    socketPath: undefined,
    httpAgent: undefined,
    httpsAgent: undefined,
    proxy: undefined,
    cancelToken: undefined,
    decompress: undefined,
    transitional: undefined,
    signal: undefined,
    insecureHTTPParser: undefined,
    env: undefined,
};
