import { Vector3 } from 'three';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import assert from 'assert';
import { CrossSectionPlane } from '@/lib/planning/cross-section/CrossSectionPlane';
import { CrossSectionPlaneUtils } from '@/lib/planning/cross-section/CrossSectionPlaneUtils';
import { CrossSectionNameEnum } from '@/lib/planning/cross-section/CrossSectionPlaneController';
import { OutlineParams } from '@/lib/planning/cross-section/OutlineParams';

/** Make the stem coronal cross-section plane */
export function makeStemCoronalCrossSection(
    assembly: HipPlannerAssembly, outlineParams: OutlineParams): CrossSectionPlane {
    const { normal, origin } = makeStemCoronalPlane(assembly);

    return CrossSectionPlaneUtils.makePlaneWithOptionalVisibility(
        CrossSectionNameEnum.HipStemCoronal, origin, normal, outlineParams);
}

export function updateStemCoronalCrossSection(
    assembly: HipPlannerAssembly, plane: CrossSectionPlane): CrossSectionPlane {
    const { normal, origin } = makeStemCoronalPlane(assembly);

    plane.direction = normal;
    plane.updateWorldPosition(origin);

    return plane;
}

/** @return the data to make a coronal plane */
function makeStemCoronalPlane(assembly: HipPlannerAssembly): { normal: Vector3; origin: Vector3 } {
    assert.ok(assembly.isInNativePosition,
        'Cannot calculate stem coronal plane when assembly is not in native configuration.');
    assert.ok(!!assembly.paAxis, 'Cannot calculate stem rotation without stem pa-axis info');

    return {
        normal: assembly.paAxis.worldDirection, // the paAxis is in the stemGroup, so is always up to date
        origin: assembly.stemGroup.worldPosition, // the origin moves with the stem group
    };
}
