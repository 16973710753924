import numeral from 'numeral';
import { NumberUtil } from '@/lib/base/NumberUtil';

/**
 * Format an a number as an angle
 *
 * E.g:
 * 38.33333 => formatLength(38.33333, 1) = 38.3 mm
 * 38.33333 => formatLength(38.33333, 2) = 38.33 mm
 */
export const formatLength = function(value: number | undefined, precision: number): string {
    if (NumberUtil.isFiniteNumber(value)) {
        return numeral(value).format('0.' + '0'.repeat(precision)) + ' mm';
    } else {
        return '--';
    }
};
