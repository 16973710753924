import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { getResectionPlaneNormalAndOrigin } from '@/hipPlanner/assembly/controllers/cross-sections/StemNeckCut';
import { BufferGeometry, Matrix4, Plane, Vector3 } from 'three';
import { setWorldTransform } from '@/lib/base/ThreeUtil';
import anylogger from 'anylogger';
import { logCalculation } from '@/hipPlanner/assembly/controllers/adjustments/logCalculation';

const log = anylogger('adjustment-calculation');

export function calculateLTResectionDistance(assembly: HipPlannerAssembly): number {
    // All calculation are made in the same space as the femur mesh geometry, which is equivalent to the original
    // CT space. This avoids having to transform the vertices.
    // This transform takes objects from world-space to femur-space
    const worldToFemurSpace = assembly.femoralAssembly.femur.worldTransform.clone().invert();

    // Get the distal direction along the femoral shaft-axis in femur-space.
    // The shaft axis points in the proximal direction, so it needs to be negated
    const distalDirection = assembly.femoralAssembly.shaftAxis.worldDirection
        .negate()
        .transformDirection(worldToFemurSpace);

    // Get the resection plane in femur-space
    const { normal, origin } = getResectionPlaneNormalAndOrigin(assembly);
    const resectionPlane = new Plane(normal)
        .translate(origin)
        .applyMatrix4(worldToFemurSpace);

    // Get the most distal vertex position within 2mm of the resection plane
    const distalFemurVertex = findMostExtremeVertexCloseToPlane(
        assembly.femoralAssembly.femur.object3D.geometry,
        distalDirection,
        resectionPlane,
        2.0,
    );

    if (distalFemurVertex === undefined) {
        log.warn('No vertices found close to resection plane');
        return 0;
    }
    const [distalFemurVertexIndex, distalFemurPoint] = distalFemurVertex;

    // Project the vertex-position so it sits in the plane
    const distalPlanePoint = new Vector3();
    resectionPlane.projectPoint(distalFemurPoint, distalPlanePoint);

    // Set the resection-intersection point position
    setWorldTransform(
        assembly.femoralAssembly.ltResectionIntersectionPoint.object,
        new Matrix4().setPosition(
            distalPlanePoint.clone().applyMatrix4(assembly.femoralAssembly.femur.worldTransform),
        ),
    );

    // Find the LT resection-point in femur-space
    const ltResectionPoint = assembly.femoralAssembly.ltResectionPoint.worldPosition
        .applyMatrix4(worldToFemurSpace);

    // The LT resection distance is the distance between the plane point and the LT resection-point
    // in the distal direction
    const result = distalDirection.dot(ltResectionPoint.clone().sub(distalPlanePoint));

    logCalculation(
        'LT resection distance (femur-space)', [
            ['distal-direction', distalDirection],
            ['resection-plane-normal', resectionPlane.normal],
            ['resection-plane-distance', resectionPlane.constant],
            ['distal-femur-point', distalFemurPoint],
            ['distal-femur-vertex-index', distalFemurVertexIndex],
            ['distal-plane-point', distalPlanePoint],
            ['lt-resection-point', ltResectionPoint],
            ['result', result],
        ],
    );

    return result;
}

/**
 * Find the most extreme vertex in some direction that is no more than some distance from a plane
 *
 * @param direction the 'extreme' direction
 * @param geometry
 * @param plane
 * @param maxDistance the maximum distance in mm from the plane for vertices to be considered
 *
 * @returns the position of the extreme vertex, or undefined if there are no vertices inside the max-distance
 */
function findMostExtremeVertexCloseToPlane(
    geometry: BufferGeometry,
    direction: Vector3,
    plane: Plane,
    maxDistance: number,
): [number, Vector3] | undefined {
    const positionAttribute = geometry.getAttribute('position');
    log.info('Femur mesh vertex-count: %d', positionAttribute.array.length / 3);
    const array = positionAttribute.array;
    const position = new Vector3();
    const extremePosition = new Vector3();
    let extremeDistance = Number.NEGATIVE_INFINITY;
    let extremeIndex = -1;

    for (let i = 0; i < positionAttribute.count; ++i) {
        position.set(array[3 * i], array[3 * i + 1], array[3 * i + 2]);
        if (plane.distanceToPoint(position) <= maxDistance) {
            const distance = position.dot(direction);
            if (distance > extremeDistance) {
                extremeIndex = i;
                extremeDistance = distance;
                extremePosition.copy(position);
            }
        }
    }

    return Number.isFinite(extremeDistance) ? [extremeIndex, extremePosition] : undefined;
}
