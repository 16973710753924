import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { SurgicalSpecificationRepresentation } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import ResourceUtil from '@/lib/api/ResourceUtil';
import SurgicalSpecificationResource
    from '@/lib/api/resource/case/surgical-specification/SurgicalSpecificationResource';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import { ProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';

/**
 * A factory for concrete classes of {@link SurgicalSpecificationModel},
 *
 * This is to ensure the two-stage construct is called.
 */
export default class SurgicalSpecificationModelFactory {
    /**
     * Get the preferences from the surgical specifications. If the preferences were already loaded it returns it,
     * otherwise goes and get it.
     *
     * @throws if the preferences are not fully hydrated at the application level. This will ensure that the
     * preferences were not partially or erroneously created (e.g: if they are sparse resource, forbidden resource)
     */
    static async getPreferences<TP extends ProductPreferencesRepresentation>(
        specification: SurgicalSpecificationRepresentation<TP>, options?: CacheOptions): Promise<TP> {
        const surgeonPreferences = specification.preferences;
        if (surgeonPreferences) {
            return await ResourceUtil.ensureHydratedResource(surgeonPreferences);
        } else {
            await SurgicalSpecificationResource.getSurgeonPreferences(specification, options);
            const preferences = specification.preferences;
            if (preferences) {
                return await ResourceUtil.ensureHydratedResource(preferences);
            }
        }

        const uri = `${getRequiredUri(specification, LinkRelation.canonicalOrSelf)}`;
        throw new Error(`Could not get preferences for ${uri}`);
    }
}
