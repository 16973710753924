
    import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
    import {
        CaseCollectionRepresentation,
        CaseRepresentation,
    } from '@/lib/api/representation/case/CaseRepresentation';
    import { SearchUtil } from '@/views/home/SearchUtil';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';

    @Component({})
    export default class HomeSearchResult extends Vue {
        @InjectReactive()
        private searchText!: string;

        @Prop({ required: true, validator: notNilValidator('isSearching') })
        protected isSearching!: boolean;

        @Prop({ required: true })
        protected projects!: CaseCollectionRepresentation | null;

        protected get areProjects(): boolean {
            return (this.projects?.items?.length ?? 0) > 0;
        }

        private get isSearchEmpty(): boolean {
            return SearchUtil.isSearchEmpty(this.searchText);
        }

        /** the list (array) of cases (projects) */
        protected get projectItems(): CaseRepresentation[] {
            return this.projects ? this.projects.items : [];
        }
    }
