import ResourceService from '@/lib/api/ResourceService';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { CacheOptions, update } from '@/lib/semanticNetworkMigrationUtils';
import { PatientRepresentation } from '@/lib/api/representation/PatientRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import { ApiUtil } from '@/lib/semantic-network';

export default class PatientResource implements ResourceService {
    public static async getCasePatient(caseResource: CaseRepresentation, options?: CacheOptions):
        Promise<PatientRepresentation | null> {
        return await ApiUtil.get<PatientRepresentation>(
            caseResource, { rel: LinkRelation.patient, ...options }) ?? null;
    }

    public static async updateCasePatient(
        patientResource: PatientRepresentation,
        updatedFields: Partial<PatientRepresentation>,
        options?: CacheOptions): Promise<PatientRepresentation> {
        return update(patientResource, updatedFields, { ifMatch: true, ifUnmodifiedSince: true, ...options });
    }
}
