import CupRotationUtil from '@/hipPlanner/components/state/CupRotationUtil';
import { Vector2, Vector3 } from 'three';
import {
    AnatomicRadiansCupRotation,
    RadiographicRadiansCupRotation,
} from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateModel';
import { AnatomicAxes } from '@/lib/api/resource/case/study/AnatomicAxes';
import { areAcute } from '@/lib/geometry/vector3';
import { angleFromPlane, angleInPlanarProjection } from '@/lib/geometry/angle';

/**
 *  Default value tolerance in numerical calculations: this needs to be a small positive value.
 */
const DEFAULT_TOLERANCE = 1e-8;

/**
 * Utility to deal with different acetabular angle calculations.
 *
 * Typescript implementation to mimic python: `acid.lib.atlas.utils.acetabular_angle_util.AcetabularAngleUtil`
 */
export default class AcetabularAngleUtil {
    /**
     * Convert radiographic anteversion and inclination to anatomic anteversion and inclination
     *
     * Client side implementation of server side conversion:
     * @see acid.lib.atlas.utils.acetabular_angle_util.AcetabularAngleUtil.radiographic_to_anatomic
     *
     * Formula
     * --------
     * transverse = np.cos(atv_radiographic) * np.sin(inc_radiographic)
     * sup_inferior = np.cos(atv_radiographic) * np.cos(inc_radiographic)
     *
     * atv_anatomic = np.arctan2(np.sin(atv_radiographic), transverse)
     * inc_anatomic = np.arccos(sup_inferior)
     * return atv_anatomic, inc_anatomic
     */
    public static toAnatomic(angles: RadiographicRadiansCupRotation): RadiographicRadiansCupRotation {
        const transverse = Math.cos(angles.anteversion) * Math.sin(angles.inclination);
        const superiorInferior = Math.cos(angles.anteversion) * Math.cos(angles.inclination);

        const anatomicAnteversion = Math.atan2(Math.sin(angles.anteversion), transverse);
        const anatomicInclination = Math.acos(superiorInferior);

        return CupRotationUtil.make(anatomicAnteversion, anatomicInclination);
    }

    /**
     * Convert anatomic anteversion and inclination to radiographic anteversion and inclination
     *
     * Client side implementation of server side conversion:
     * @see acid.lib.atlas.utils.acetabular_angle_util.AcetabularAngleUtil.radiographic_to_anatomic
     *
     * Formula
     * -------
     * transverse = np.cos(atv_anatomic) * np.sin(inc_anatomic)
     * post_anterior = np.sin(atv_anatomic) * np.sin(inc_anatomic)
     * sup_inferior = np.cos(inc_anatomic)
     *
     * atv_radiographic = np.sign(post_anterior) * np.arccos(Math.mag([transverse, sup_inferior]))
     *
     * if abs(transverse) > DEFAULT_TOLERANCE or abs(sup_inferior) > DEFAULT_TOLERANCE:
     *   inc_radiographic = np.arctan2(transverse, sup_inferior)
     * else:
     *   # Degenerate case: in posterior or anterior direction. Fallback is 90 degrees.
     *   inc_radiographic = math.pi/2
     */
    public static toRadiographic(angles: AnatomicRadiansCupRotation): AnatomicRadiansCupRotation {
        const transverse = Math.cos(angles.anteversion) * Math.sin(angles.inclination);
        const posteriorAnterior = Math.sin(angles.anteversion) * Math.sin(angles.inclination);
        const superiorInferior = Math.cos(angles.inclination);

        const radiographicAnteversion = Math.sign(posteriorAnterior) *
            Math.acos(new Vector2(transverse, superiorInferior).length());

        const calculateRadiographicInclination = (): number => {
            if (Math.abs(transverse) > DEFAULT_TOLERANCE || Math.abs(superiorInferior) > DEFAULT_TOLERANCE) {
                return Math.atan2(transverse, superiorInferior);
            } else {
                // Degenerate case: in posterior or anterior direction. Fallback is 90 degrees.
                return Math.PI / 2;
            }
        };

        const radiographicInclination = calculateRadiographicInclination();

        return CupRotationUtil.make(radiographicAnteversion, radiographicInclination);
    }

    /**
     * Calculate radiographic anteversion angle from an 'acetabular vector'. This is the angle between the
     * 'acetabular vector' and the coronal plane.
     * For both cups and acetabular planes the 'acetabular vector' points laterally (away from the pelvis).
     *
     * Definition of Radiographic angles (Murray 1992):
     * The Radiographic Inclination (RI) is defined as the angle between the longitudinal axis and the acetabular
     *      axis when this is projected onto the coronal plane.
     */
    public static radiographicAnteversion(acetabularVector: Vector3, axes: AnatomicAxes): number {
        const anterior = axes.posterior.clone().negate();
        return angleFromPlane(acetabularVector, anterior);
    }

    /**
     * Calculate radiographic inclination angle from an 'acetabular vector'. This is the angle between the
     * 'acetabular vector' and the inferior axis as projected in the coronal plane.
     * For both cups and acetabular planes the 'acetabular vector' points laterally (away from the pelvis).
     *
     * Definition of Radiographic angles (Murray 1992):
     * The Radiographic Inclination (RI) is defined as the angle between the longitudinal axis and the acetabular
     *      axis when this is projected onto the coronal plane.
     */
    public static radiographicInclination(acetabularVector: Vector3, axes: AnatomicAxes): number {
        const transverse = areAcute(acetabularVector, axes.left) ? axes.left : axes.left.clone().negate();
        const inferior = axes.superior.clone().negate();
        return angleInPlanarProjection(acetabularVector, inferior, transverse, 90);
    }
}
