import { RawLocation } from 'vue-router/types/router';

export enum StepStateColours {
    /** Green */
    Complete = 'success',
    /** Blue */
    Active = 'custom-blue-button',
    Inactive = 'grey',
    Error = 'error',
}

/**
 * A functor that given a click event gets the required route
 */
type GetLocationFunction = (e: Event | MouseEvent) => RawLocation;

export interface CaseStepData {
    index: number;
    title: string;
    state: StepStateColours;
    last: boolean;
    icon: string;

    makeLocation(e: Event | MouseEvent): RawLocation;
}

export class CaseStepDataImpl implements CaseStepData {
    icon: string;
    index: number;
    last: boolean;
    private readonly _location: GetLocationFunction;
    private readonly _state: () => StepStateColours;
    title: string;

    constructor(
        index: number,
        title: string,
        state: () => StepStateColours,
        last: boolean,
        icon: string,
        location: GetLocationFunction) {
        this.icon = icon;
        this.index = index;
        this.last = last;
        this._location = location;
        this._state = state;
        this.title = title;
    }

    get state(): StepStateColours {
        return this._state();
    }

    public makeLocation(e: Event): RawLocation {
        return this._location(e);
    }
}
