import { Vector3 } from 'three';
import { BodySide } from '@/lib/api/representation/interfaces';
import { count } from 'ramda';

const ORIGIN = new Vector3(0, 0, 0);
const LEFT = new Vector3(1, 0, 0);
const RIGHT = new Vector3(-1, 0, 0);
const POSTERIOR = new Vector3(0, 1, 0);
const ANTERIOR = new Vector3(0, -1, 0);
const SUPERIOR = new Vector3(0, 0, 1);
const INFERIOR = new Vector3(0, 0, -1);

type MediolateralOffset = {
    left?: number
    right?: number
    medial?: number
    lateral?: number
    side?: BodySide
}

type LongitudinalOffset = {
    superior?: number
    inferior?: number
};

type AnterioposteriorOffset = {
    anterior?: number
    posterior?: number
}

export default {
    get Origin(): Vector3 {
        return ORIGIN.clone();
    },
    get Left(): Vector3 {
        return LEFT.clone();
    },
    get Right(): Vector3 {
        return RIGHT.clone();
    },
    get Posterior(): Vector3 {
        return POSTERIOR.clone();
    },
    get Anterior(): Vector3 {
        return ANTERIOR.clone();
    },
    get Superior(): Vector3 {
        return SUPERIOR.clone();
    },
    get Inferior(): Vector3 {
        return INFERIOR.clone();
    },
    medial(side: BodySide): Vector3 {
        return side === BodySide.Left ? RIGHT.clone() : LEFT.clone();
    },
    lateral(side: BodySide): Vector3 {
        return side === BodySide.Left ? LEFT.clone() : RIGHT.clone();
    },
    makeVector(offsets: MediolateralOffset & LongitudinalOffset & AnterioposteriorOffset): Vector3 {
        return new Vector3(getLeft(offsets), getPosterior(offsets), getSuperior(offsets));
    },
};

const countDefined = count(v => v !== undefined);

function getLeft(offset: MediolateralOffset): number {
    if (countDefined([offset.left, offset.right, offset.medial, offset.lateral]) > 1) {
        throw Error(`Conflicting mediolateral offset values: ${offset}`);
    }
    if (offset.left) {
        return offset.left;
    } else if (offset.right) {
        return -offset.right;
    } else if (offset.medial) {
        switch (offset.side) {
            case BodySide.Left:
                // For a left-side case medial is to the right
                return -offset.medial;
            case BodySide.Right:
                // For a right-side case medial is to the left
                return offset.medial;
            case undefined:
                throw Error(`Medial offset given without body-side: ${offset}`);
        }
    } else if (offset.lateral) {
        switch (offset.side) {
            case BodySide.Left:
                // For a left-side case lateral is to the left
                return offset.lateral;
            case BodySide.Right:
                // For a right-side case lateral is to the right
                return -offset.lateral;
            case undefined:
                throw Error(`Medial offset given without body-side: ${offset}`);
        }
    } else {
        return 0;
    }
}

function getPosterior(offset: AnterioposteriorOffset) {
    if (countDefined([offset.anterior, offset.posterior]) > 1) {
        throw Error(`Conflicting anterioposterior offset values: ${offset}`);
    }
    if (offset.anterior) {
        return -offset.anterior;
    } else if (offset.posterior) {
        return offset.posterior;
    } else {
        return 0;
    }
}

function getSuperior(offset: LongitudinalOffset) {
    if (countDefined([offset.inferior, offset.superior]) > 1) {
        throw Error(`Conflicting longitudinal offset values: ${offset}`);
    }
    if (offset.superior) {
        return offset.superior;
    } else if (offset.inferior) {
        return -offset.inferior;
    } else {
        return 0;
    }
}
