
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import NewCase from '@/components/case/NewCase.vue';
    import CaseListTitle from '@/components/title/CaseListTitle.vue';
    import CaseListItem from '@/views/administer/case-item/CaseListItem.vue';
    import AdministerCasesMenu from '@/views/administer/cases-menu/AdministerCasesMenu.vue';
    import _ from 'lodash';

    enum Events {
        Search = 'search'
    }

    /**
     * Time in milliseconds where the search is debounced.
     *
     * This number has to be low enough to produce a good UX,
     * but not that that low that we hit the API multiple unnecessary times. Ideally the whole interaction
     * should be below 250ms.
     *
     * Note: Based on how this request performs on real environments we might need to adjust this value.
     * As this article says { @see https://stackoverflow.com/a/44755058 } this number has:
     * > In general the reason for making use of a debounce operation can be summed up
     * > as having one of two purposes:
     * > Reducing the cost of providing dynamic interactive elements (where cost in our case is more request to the API).
     * > Reducing visual "noise" to avoid distracting the user with page updates while they are busy.
     */
    const DEBOUNCE_SEARCH_IN_MILLISECONDS = 150;

    @Component({ components: { AdministerCasesMenu, CaseListItem, CaseListTitle, NewCase } })
    export default class HomeSearchInput extends Vue {
        @InjectReactive()
        protected searchText!: string;

        protected onInput = _.debounce(this.emitSearch.bind(this), DEBOUNCE_SEARCH_IN_MILLISECONDS);

        /**
         * The minimum amount of character before the search is fired
         * During development this value was changed to 2 or 3 characters to evaluate the UX.
         * Having a value different from 1 was letting the search box and search results text in inconsistent ways,
         * so to simplify 1 is chosen. This will likely need to be revisited in the future.
         */
        protected minimumLength = 1;

        private focused = false;

        protected onSearchClick(): void {
            this.emitSearch(this.searchText);
        }

        protected emitSearch(value: string): void {
            if (value) {
                const amountOfCharacters = value.trim().length;
                if (amountOfCharacters >= this.minimumLength) {
                    this.$emit(Events.Search, value);
                } else if (amountOfCharacters > 0) {
                    // no emit
                } else {
                    this.$emit(Events.Search, '');
                }
            } else {
                this.$emit(Events.Search, '');
            }
        }

        protected onFocus(): void {
            this.focused = true;
        }

        protected onBlur(): void {
            this.focused = false;
        }

        protected get iconColor(): string {
            return this.focused ? 'custom-blue-button' : 'custom-grey';
        }
    }
