enum ViewPath {
    Home = '/',
    About = '/about',
    Privacy = '/privacy',
    Terms = '/terms',
    ResetPassword = '/password/reset',
    ForgotPassword = '/auth/reset',
    ProfileHipSettings = '/profile/hip/settings',
    CaseSettings = '/case/settings',
    DicomImage = '/case/dicom',
    CaseManage = '/case/manage',
    Case3d = '/case/3d',
    CasePlan = '/case/plan',
    Organisation = '/org',
    AdministerCase = '/admin/case'
}

export default ViewPath;
