
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import SlotUtil from '@/components/shared/SlotUtil';

    /**
     * A button used in the app bar header
     */
    @Component({
        components: {},
    })
    export default class AppBarButton extends Vue {
        @Prop({ required: true, validator: notNilValidator('text') })
        public text!: string;

        @Prop({ required: false })
        public icon?: string;

        @Prop({ required: true, validator: notNilValidator('on') })
        public on!: unknown;

        protected get hasIconSlot(): boolean {
            return SlotUtil.hasSlot(this, 'icon');
        }
    }
