export enum AlertIconTypes {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}

export type AlertIconType =
    AlertIconTypes.Success |
    AlertIconTypes.Info |
    AlertIconTypes.Warning |
    AlertIconTypes.Error;

export const AVAILABLE_ALERT_ICON_TYPES = Object.values(AlertIconTypes);
