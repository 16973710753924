import { equals } from 'ramda';
import { NumberArray3 } from '@/lib/api/representation/geometry/arrays';

export class NumberUtil {
    /**
     * Check a value is a finite number.
     * Values of NaN, Infinite, -Infinite, null, undefined, strings, objects, arrays will all evaluate to False
     *
     * Value of 0, 1, numbers will evaluate to True
     *
     * This function exists for type-safety in the IDE. Number.ifFinite() expects a number but we often
     * have values that can be null or undefined or a number.
     *
     * This constitutes a Type Guard so that if True, value is known by the IDE to be a number.
     *
     * @see {@link http://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types}
     */
    public static isFiniteNumber(value: unknown): value is number {
        return Number.isFinite(value);
    }

    /**
     * Sum two numbers if they are "finite", based on {@link NumberUtil.isFiniteNumber}. Otherwise return null
     */
    public static sumIfFinite(maybeNumber: number | null, maybeOtherNumber: number | null): number | null {
        return NumberUtil.isFiniteNumber(maybeNumber) && NumberUtil.isFiniteNumber(maybeOtherNumber) ?
            maybeNumber + maybeOtherNumber :
            null;
    }

    /**
     * Check if two values are equal within a tolerance.
     */
    public static areEqualWithinTolerance(a: number, b: number, tolerance: number): boolean {
        return Math.abs(a - b) < tolerance;
    }

    /**
     * Check if two values are equal within a tolerance.
     */
    public static areEqualNullableWithinTolerance(a: number | null, b: number | null, tolerance: number): boolean {
        return equals(a, b) ||
            (NumberUtil.isFiniteNumber(a) &&
            NumberUtil.isFiniteNumber(b) &&
            NumberUtil.areEqualWithinTolerance(a, b, tolerance));
    }

    /**
     * return a number so that the value is constrained from:
     *
     *       min <= val <= max
     */
    public static constrain(val: number, min: number, max: number): number {
        return Math.min(Math.max(val, min), max);
    }

    /**
     * Convert a float or string into an integer
     */
    public static toInt(val: number | string): number {
        return parseInt(val.toString().replace(/[^\d.]/g, ''), 10);
    }

    /**
     * Computed property to separate the internal value from the displayed value.
     *
     * Round a float or string to fixed decimal points.
     */
    public static roundFloat(val: number, decimalPlaces: number): number {
        return Number(val.toFixed(decimalPlaces));
    }

    /**
     * Round a number to nearest 0.5
     *
     * @see https://stackoverflow.com/a/6138087
     */
    public static roundHalf(value: number): number {
        return Math.round(value * 2) / 2;
    }

    /**
     * Copy the values of one array to another
     */
    public static copyNumberArray3(src: NumberArray3, dst: NumberArray3): void {
        dst[0] = src[0];
        dst[1] = src[1];
        dst[2] = src[2];
    }
}
