import assert from 'assert';
import { cloneDeep } from 'lodash';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { CatalogComponentRepresentation } from '@/lib/api/representation/catalog/CatalogComponentRepresentation';

export function cloneCatalogComponent<T extends CatalogComponentRepresentation>(resource?: T | null): T {
    assert.ok(resource, 'catalog component must be present for clone');

    // TODO: FL-1414 when cloning cup components, the collisoin mask property is being cloned and is showing a warning
    //           'THREE.BufferAttribute: .length has been deprecated. Use .count instead.'
    return cloneDeep(resource);
}

export function cloneSurgicalTemplate(template: HipSurgicalTemplateRepresentation): HipSurgicalTemplateRepresentation {
    return {
        links: cloneDeep(template.links),
        state: template.state,
        created: template.created,
        updated: template.updated,
        record_state: template.record_state,
        target_leg_length_change: template.target_leg_length_change,
        target_offset_change: template.target_offset_change,
        cup_rotation: cloneDeep(template.cup_rotation),
        cup_offset: cloneDeep(template.cup_offset),
        stem_transform: cloneDeep(template.stem_transform),
    };
}