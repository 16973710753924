import Vue from 'vue';
import MessageBus from '@/lib/http/MessageBus';

export interface AuthEventBusPluginOptions {
    authEventBus: MessageBus;
}

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
/**
 * This is a global event bus for communicating between independent components. It should not be used
 * for communication between children back to parent. Vue has a different convention for this.
 *
 * See https://alligator.io/vuejs/global-event-bus/
 *
 * Please create constants for all global events so that we can track and understand their purpose.
 *
 *
 * @example
 *
 *  Subscribe (receive the events)
 *  ==============================
 *
 *  // Import the EventBus.
 *  import EventBus, { unauthorised } from './EventBus.js';
 *
 *   Option One: directly handle
 *
 *      // Listen for the event and its payload.
 *      this.$authEvent.$on(unauthorised, args => {
 *         // code here
 *      });
 *
 *   Option Two: handler
 *
 *      const handler = arg => {}
 *      this.$authEvent.$on(unauthorised, handler)
 *
 *   Note: options is useful if you need to deregister handlers (see below)
 *
 *  Publish (send the events)
 *  ========================
 *
 *      // Import the EventBus.
 *      import EventBus, { unauthorised } from './EventBus.js';
 *
 *
 *      //some function and inside
 *      function(){
 *          // Send the event and payload
 *      this.$authEvent.$emit(unauthorised, 'object');
 *
 *  Removing events
 *  ===============
 *
 *  Option One: de-resigister all listeners to an event
 *
 *      this.$authEvent.$off(unauthorised)
 *
 *  Option Two: de-register specific listeners to an event
 *
 *      this.$authEvent.$off(unauthorised, handler)
 *
 *  Options Three: de-regisiter all listeners to all events
 *
 *      this.$authEvent.$off()
 *
 * @param {Vue} VueConstructor
 * @param {AuthEventBusPluginOptions} options
 * @constructor
 */
export function AuthEventBusPlugin<T extends AuthEventBusPluginOptions>(
    VueConstructor: typeof Vue,
    options?: T): void {
    const authEventBus = (options?.authEventBus) ? options.authEventBus : new Vue();

    // attach to the root view, access via this.$authEvent
    Object.defineProperties(VueConstructor.prototype, {
        $authEvent: {
            get() {
                return authEventBus;
            },
        },
    });
}

/**
 * Declare the $authEvent object that is globally installed on all
 * Vue components.
 */
declare module 'vue/types/vue' {
    interface Vue {
        $authEvent: Vue;
    }
}
