import { MetalType } from '@/lib/planning/objects-3D/AcidObject3d';
import anylogger from 'anylogger';
import { Bones } from '@/lib/constants/Bones';
import { HipImplantAlias } from '@/hipPlanner/assembly/objects/HipImplantAlias';
import { BONE_BACKSIDE_COLOR, BONE_COLOR, METAL_COLOR, STEM_COLOR, WHITE_COLOR } from '@/lib/viewer/colors';
import MaterialFactory, { MaterialParameters, MaterialSet } from '@/lib/viewer/MaterialFactory';

const log = anylogger('AliasMaterialFactory');

/**
 * Transparent bone-colored material
 */
const TRANSPARENT_BONE_MATERIAL: MaterialParameters = {
    color: BONE_COLOR,
    opacity: 0.7,
};

/**
 * Bone-colored material with a solid back-side.
 */
const OPAQUE_BONE_MATERIAL: MaterialParameters = {
    color: BONE_COLOR,
    transparent: true,
    solidBackSideColor: BONE_BACKSIDE_COLOR,
};

/**
 * White material for femur inner surface.
 */
const INNER_SURFACE_MATERIAL: MaterialParameters = {
    color: WHITE_COLOR,
    transparent: true,
};

/**
 * White material for components
 */
const COMPONENT_MATERIAL: MaterialParameters = {
    color: WHITE_COLOR,
    solidBackSideColor: WHITE_COLOR,
    transparent: true,
};

/**
 * Light blue material for hip stem
 */
const STEM_MATERIAL: MaterialParameters = {
    color: STEM_COLOR,
    solidBackSideColor: STEM_COLOR,
    transparent: true,
};

/**
 * purple colored material, for metal objects
 */
const METAL_MATERIAL: MaterialParameters = {
    color: METAL_COLOR,
    solidBackSideColor: METAL_COLOR,
};

export default class AliasMaterialFactory {
    /**
     * Create viewing modes mesh materials - primarily those associated with
     */
    public static makeMaterialsForAlias(alias: string): MaterialSet {
        return MaterialFactory.makeMaterials(AliasMaterialFactory.createParameters(alias), alias);
    }

    /**
     * Create material parameters to be used for making mesh materials
     */
    public static createParameters(alias: string): MaterialParameters {
        switch (alias) {
            case HipImplantAlias.Stem:
                return STEM_MATERIAL;

            case HipImplantAlias.Head:
            case HipImplantAlias.Cup:
            case HipImplantAlias.Liner:
                return COMPONENT_MATERIAL;

            case Bones.OperativeFemur:
                return TRANSPARENT_BONE_MATERIAL;

            case Bones.ContraFemur:
            case Bones.OperativeHemi:
            case Bones.ContraHemi:
            case Bones.Sacrum:
                return OPAQUE_BONE_MATERIAL;

            case Bones.InnerSurface:
                return INNER_SURFACE_MATERIAL;

            case MetalType.Metal:
                return METAL_MATERIAL;

            default: {
                log.warn('Model alias %s does not have specified material parameters. Using random color', alias);
                const randomHexColor = Math.random() * 0xFFFFFF;
                return {
                    color: randomHexColor,
                };
            }
        }
    }
}
