import { toSitePath } from './UriMapping';
import { getUri, LinkedRepresentation, LinkUtil } from 'semantic-link';
import { makePrefix } from './resolver';
import viewPath from '@/router/viewPath';
import { Location } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import {
    SurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import { RelationshipType } from 'semantic-link/lib/filter';
import { Product } from '@/lib/api/representation/ProductRepresentation';
import anylogger from 'anylogger';

const log = anylogger('navigate');

export function makeSitePath(representation: LinkedRepresentation, path: string): string {
    return makeSitePathFromRel(representation, LinkRelation.self, path);
}

export function makeSitePathFromRel(representation: LinkedRepresentation, rel: RelationshipType, path: string): string {
    if (representation) {
        const selfUri = getUri(representation, rel);
        if (selfUri) {
            return toSitePath(selfUri, makePrefix(path));
        }
    }
    throw new Error(`${rel} link not available in ${representation}`);
}

/**
 *
 * @param representation a representation of a resource which is the destination of the path
 * @param path the local client side url path.
 * @param query optional additional query parameters
 */
export function navigatePath(
    representation: LinkedRepresentation,
    path: string,
    query: Dictionary<string | Array<string | null> | null | undefined> = {}): Location {
    return {
        ...{
            path: makeSitePath(representation, path),
        },
        ...query,
    };
}

/***********
 * Redirects
 * =========
 */
// export function navigatePush(representation: LinkedRepresentation, path: string, query: any = {}): Promise<Route> {
//     return router.push(navigatePath(representation, path, query));
// }

/**
 * Redirect to the worker Profile page based on a worker representation
 */
export function navigatePathCaseSettings(caseRepresentation: CaseRepresentation): string {
    return makeSitePath(caseRepresentation, viewPath.CaseSettings);
}

export function navigatePathCaseManage(caseRepresentation: CaseRepresentation): string {
    return makeSitePath(caseRepresentation, viewPath.CaseManage);
}

export function navigatePathCaseDicom(caseRepresentation: CaseRepresentation): string {
    return makeSitePath(caseRepresentation, viewPath.DicomImage);
}

/** Navigate to the post-approval 'plan' page from a project representation */
export function navigatePathCasePlan(caseRepresentation: CaseRepresentation): string {
    return makeSitePath(caseRepresentation, viewPath.CasePlan);
}

/**
 * Navigate to the post-approval 'plan' page from a surgical template representation.
 *
 * Note: The surgical template is in the context of a case, so the 'up' rel is used to refer to the project.
 */
export function navigatePathSurgicalTemplatePlan(surgicalTemplate: SurgicalTemplateRepresentation): string {
    return makeSitePathFromRel(surgicalTemplate, LinkRelation.up, viewPath.CasePlan);
}

/** Navigate to the hip 3d planning page */

export function navigatePathPlanner(caseRepresentation: CaseRepresentation): string {
    const productName = LinkUtil.getTitle(caseRepresentation, LinkRelation.product);
    switch (productName) {
        case Product.Hip:
            return navigatePathHipPlanner(caseRepresentation);
        default:
            log.warn('Unsupported product \'%s\'', productName);
            return '';
    }
}

function navigatePathHipPlanner(caseRepresentation: CaseRepresentation): string {
    return makeSitePath(caseRepresentation, viewPath.Case3d);
}
