
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { PatientRepresentation } from '@/lib/api/representation/PatientRepresentation';
    import { NameRepresentationUtil } from '@/components/case-settings/utils/NameRepresentationUtil';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import {
        PatientComparison,
        PatientComparisonError,
        PatientComparisonErrorType,
    } from '@/components/case-settings/scan/workflow/PatientComparisonUtil';

    enum Events {
        Cancel = 'cancel',
        Submit = 'submit',
    }

    /**
     * A Dialog to :
     * - present to the user a mismatch between the patient details and the patient data in the scan
     * - allow the user to proceed with the upload
     *
     * Visibility: It's visibility its controlled on the outside of this component
     */
    @Component({ components: {} })
    export default class ScanUploadPatientMismatchDialog extends Vue {
        @Prop({ required: true, validator: notNilValidator('isActive') })
        public isActive!: boolean;

        /** The case (project) */
        @Prop({ required: true })
        public value!: PatientComparison;

        protected async onCancel(): Promise<void> {
            // Log analytic event
            this.$emit(Events.Cancel);
        }

        protected async onSubmit(): Promise<void> {
            // Log analytic event
            this.$emit(Events.Submit);
        }

        protected get nameError(): PatientComparisonError | null {
            return this.value.errorFields?.find((error: PatientComparisonError) => {
                return error.type === PatientComparisonErrorType.Name;
            }) ?? null;
        }

        protected get sexError(): PatientComparisonError | null {
            return this.value.errorFields?.find((error: PatientComparisonError) => {
                return error.type === PatientComparisonErrorType.Sex;
            }) ?? null;
        }

        protected get dateOfBirthError(): PatientComparisonError | null {
            return this.value.errorFields?.find((error: PatientComparisonError) => {
                return error.type === PatientComparisonErrorType.DateOfBirth;
            }) ?? null;
        }

        protected get formattedEnteredPatientName(): string {
            return NameRepresentationUtil.format(this.value.patient.name);
        }

        protected get formattedDicomPatientName(): string {
            return NameRepresentationUtil.format(this.dicomPatient.name);
        }

        protected get formattedEnteredPatientSex(): string {
            return this.value.patient.sex ?? '';
        }

        protected get formattedDicomPatientSex(): string {
            return this.dicomPatient.sex ?? '';
        }

        protected get formattedEnteredPatientDateOfBirth(): string {
            return this.value.patient.birth_date ?? '';
        }

        protected get formattedDicomPatientDateOfBirth(): string {
            return this.dicomPatient.birth_date ?? '';
        }

        private get dicomPatient(): PatientRepresentation {
            return this.value.anotherPatient;
        }
    }
