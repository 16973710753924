import { AxesHelper, Matrix4 } from 'three';
import SpriteText from 'three-spritetext';
import { setLocalTransform } from '@/lib/base/ThreeUtil';

export function makeAxesHelper(
    properties: {
        name?: string,
        scale?: number,
        visible?: boolean,
        textHeight?: number,
        textColor?: string,
        matrixAutoUpdate?: boolean,
        transform?: Matrix4,
    } = {}): AxesHelper {
    const axes = new AxesHelper(properties.scale ?? 50);
    axes.visible = properties.visible ?? true;
    if (properties.name) {
        axes.name = properties.name;
        const text = new SpriteText(properties.name, properties.textHeight ?? 5, properties.textColor ?? 'white');
        text.visible = true;
        axes.add(text);
    }
    if (properties.matrixAutoUpdate) {
        axes.matrixAutoUpdate = properties.matrixAutoUpdate;
    }
    if (properties.transform) {
        setLocalTransform(axes, properties.transform);
    }
    return axes;
}
