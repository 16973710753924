import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import {
    SurgicalTemplateRecordState,
    SurgicalTemplateRepresentation,
    SurgicalTemplateState,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import { CancellationOptions, WaitResourceUtil } from '@/lib/api/resource/WaitResourceUtil';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import { equals, isNil } from 'ramda';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { DateTime } from 'luxon';
import { isHipSurgicalTemplate } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateUtil';
import _ from 'lodash';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import HipTemplateController from '@/hipPlanner/components/state/HipTemplateController';
import ResourceUtil from '@/lib/api/ResourceUtil';

const ONE_SECOND_IN_MILLISECONDS = 1000;

export class SurgicalTemplateUtil {
    /**
     * Wait until the user surgical template is in {@link SurgicalTemplateState.ManualProcessing} state.
     *
     * Note: this method is likely to be used after the user surgical template is just created.
     *
     * Background: The user surgical template has a **two stage creation process**. This means, that compute code has to
     * execute asynchronously to get the surgical template fully created.
     * From the client point of view, we need to wait until the resource record reaches that state.
     * The second stage of the create, only needs to create the alignment transform, so it should only take a few
     * milliseconds.
     */
    public static async waitUntilManualProcessing<T extends SurgicalTemplateRepresentation>(
        surgicalTemplate: T,
        pollInterval = ONE_SECOND_IN_MILLISECONDS,
        cancellationOptions: CancellationOptions,
        apiOptions: CacheOptions): Promise<T> {
        await WaitResourceUtil.waitUntil(
            SurgicalTemplateUtil.isManualProcessing,
            pollInterval,
            cancellationOptions,
            async function(): Promise<T> {
                await ResourceUtil.refresh(surgicalTemplate, apiOptions);
                return surgicalTemplate;
            });

        return surgicalTemplate;
    }

    /**
     * Wait until the surgical template record state reaches the 'completed' state
     *
     * Note: The record state being 'completed' can mean different things depending on the particular version.
     * Basically, it states that whatever logic needed to be run for that version, was run successfully.
     *
     * E.g.:
     * - It the case of a new user surgical template, it can mean that the two-stage construct of a
     * history version worked successfully.
     * - In the case of updating an existing user surgical template parameters, it can mean the
     * resection of the models and range of motion run successfully.
     *
     */
    public static async waitUntilUserTemplateIsComplete(
        controller: HipTemplateController,
        pollInterval = ONE_SECOND_IN_MILLISECONDS,
        cancellationOptions: CancellationOptions): Promise<void> | never {
        return WaitResourceUtil.waitUntil(
            () => {
                return SurgicalTemplateUtil.isRecordStateCompleted(controller.store.userTemplate);
            },
            pollInterval,
            cancellationOptions);
    }

    /**
     * Returns whether the surgical template main record has components resource
     */
    static hasComponents<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return LinkUtil.matches(surgicalTemplate, LinkRelation.componentSet);
    }

    /**
     * Returns whether the surgical template main record is in the new state or not
     */
    static isNew<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return surgicalTemplate.state === SurgicalTemplateState.New;
    }

    /**
     * Returns whether the surgical template main record is in the processing state or not
     */
    static isProcessing<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return surgicalTemplate.state === SurgicalTemplateState.Processing;
    }

    /**
     * Returns whether the surgical template main record is in the completed state or not
     */
    static isCompleted<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return surgicalTemplate.state === SurgicalTemplateState.Completed;
    }

    /**
     * Returns whether the surgical template main record is in the completed state or not
     */
    static isError<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return surgicalTemplate.state === SurgicalTemplateState.Error;
    }

    /**
     * Returns whether the surgical template main record is in manual processing state or not
     */
    static isManualProcessing<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return surgicalTemplate.state === SurgicalTemplateState.ManualProcessing;
    }

    /**
     * Returns whether the surgical template history record is completed or not
     */
    static isRecordStateCompleted<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return surgicalTemplate.record_state === SurgicalTemplateRecordState.Completed;
    }

    /**
     * Returns whether the surgical template history record is in the 'error' state or not
     */
    static isRecordStateError<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return surgicalTemplate.record_state === SurgicalTemplateRecordState.Error;
    }

    /**
     * Returns whether the surgical template history record is in the 'new' state or not
     */
    static isRecordStateNew<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return surgicalTemplate.record_state === SurgicalTemplateRecordState.New;
    }

    /**
     * Returns whether the surgical template history record is in the 'processing' state or not
     */
    static isRecordStateProcessing<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return surgicalTemplate.record_state === SurgicalTemplateRecordState.Processing;
    }

    /**
     * Returns true if the surgical template study uri matches the uri in the self link of the study
     */
    static belongsToStudy<T extends SurgicalTemplateRepresentation>(
        surgicalTemplate: T, study: StudyRepresentation): boolean {
        const surgicalTemplateStudyUri = LinkUtil.getUri(surgicalTemplate, LinkRelation.study);
        const studyUri = LinkUtil.getUri(study, LinkRelation.self);
        return !isNil(studyUri) && !isNil(surgicalTemplateStudyUri) && equals(studyUri, surgicalTemplateStudyUri);
    }

    /**
     * Returns if the surgical template is in the completed state and has a plan link
     */
    static isCompletedWithAPlan<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return SurgicalTemplateUtil.isCompleted(surgicalTemplate) && SurgicalTemplateUtil.hasPlanLink(surgicalTemplate);
    }

    /**
     * Returns whether the surgical template has a plan link or not
     */
    static hasPlanLink<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        return LinkUtil.matches(surgicalTemplate, LinkRelation.plan);
    }

    /**
     * Returns whether the surgical template is in the completed state, but it has not been related with a plan for
     * some arbitrary time.
     *
     * Note: This heuristic allows to guess that the plan creation failed without having to fetch the plan resource
     * The strategy logic here will be that the plan creation process should not take too long
     * and this will help to reduce the number of HTTP request by no polling the acid surgical template indefinitely.
     *
     * TODO: Once we 'compressed' the two 'completed' records on the acid surgical template, this might need an upgrade
     */
    static isCompletedButPlanPossiblyFailed<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): boolean {
        const updatedAtTime = SurgicalTemplateUtil.updatedAtTime(surgicalTemplate);
        return SurgicalTemplateUtil.isCompleted(surgicalTemplate) &&
            !SurgicalTemplateUtil.hasPlanLink(surgicalTemplate) &&
            !!updatedAtTime &&
            updatedAtTime.plus({ minute: 10 }) < DateTime.utc();
    }

    static updatedAtTime<T extends SurgicalTemplateRepresentation>(surgicalTemplate: T): DateTime | null {
        if (surgicalTemplate.updated) {
            const when = DateTime.fromISO(surgicalTemplate.updated, { zone: 'utc' });
            if (when) {
                return when;
            }
        }
        return null;
    }

    public static stateLabel(state?: string): string {
        switch (state) {
            case SurgicalTemplateState.New:
                return 'new';
            case SurgicalTemplateState.Processing:
                return 'processing';
            case SurgicalTemplateState.Completed:
                return 'completed';
            case SurgicalTemplateState.ManualProcessing:
                return 'manual';
            case SurgicalTemplateState.Error:
                return 'error';
            default:
                return 'unknown';
        }
    }

    public static recordStateLabel(state?: SurgicalTemplateRecordState): string {
        switch (state) {
            case SurgicalTemplateRecordState.New:
                return 'new';
            case SurgicalTemplateRecordState.Processing:
                return 'processing';
            case SurgicalTemplateRecordState.Completed:
                return 'completed';
            case SurgicalTemplateRecordState.Error:
                return 'error';
            default:
                return 'unknown';
        }
    }

    /**
     * A utility to do type specific logic, rather than having to code this everywhere
     * using multiple if/else guards.
     *
     * Can be called with a return value or a function, or a combination of those.
     */
    public static withProduct<RT>(
        surgicalTemplate: SurgicalTemplateRepresentation,
        hip: ((surgicalTemplate?: HipSurgicalTemplateRepresentation) => RT) | RT): RT {
        function valueOrCallback<T extends SurgicalTemplateRepresentation>(
            valueOrCallback: RT | ((surgicalTemplate: T) => RT)): RT {
            if (_.isFunction(valueOrCallback)) {
                return valueOrCallback(surgicalTemplate as T);
            }
            return valueOrCallback;
        }

        if (isHipSurgicalTemplate(surgicalTemplate)) {
            return valueOrCallback<HipSurgicalTemplateRepresentation>(hip);
        }

        throw new Error('not a hip product');
    }
}
