import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import CupRotationUtil from '@/hipPlanner/components/state/CupRotationUtil';
import assert from 'assert';
import { CupOffset, CaseStem } from '@/hipPlanner/components/state/types';
import SurgicalTemplateSynchroniser from '@/hipPlanner/stores/template/SurgicalTemplateSynchroniser';
import {
    AngleDegree,
} from '@/lib/api/representation/case/surgical-template/common/AnteversionInclinationAngleRepresentation';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { makeNullRigidTransform, toRepresentation } from '@/lib/base/RigidTransform';
import StemController from '@/hipPlanner/assembly/controllers/StemController';
import { executeOperation, HipPlannerStore, PlannerAbortError } from '@/hipPlanner/stores/planner/hipPlannerStore';
import anylogger from 'anylogger';
import { WaitResourceUtil } from '@/lib/api/resource/WaitResourceUtil';
import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
import makeTemplateRepresentation from '@/hipPlanner/stores/template/makeTemplateRepresentation';
import {
    anyCupAssemblyChange,
    anyStemAssemblyChange,
} from '@/lib/api/resource/case/surgical-template/hipTemplateComparison';
import { watch } from 'vue';
import { VueObserver } from '@/hipPlanner/assembly/controllers/VueObserver';
import { approvePlan, ApprovePlanResult } from '@/hipPlanner/stores/template/approvePlan';
import { asyncWatchUntil } from '@/util/asyncWatch';

const log = anylogger('Hip template controller');

/**
 * The global store of the surgical template.
 *
 * The plan is that:
 * 1. It will be the living version/local copy of surgical template (user changes) until
 * it is migrated into the server & network of data.
 * 2. Provide reactivity and read access to the surgical template state.
 * 3. Provide a light-weight version of the {@link HipSurgicalTemplateRepresentation} (without all the nested resources)
 */
export default class HipTemplateController extends VueObserver {
    private _stemController: StemController | null = null;

    constructor(
        private templateStore: HipTemplateStore,
        public plannerStore: HipPlannerStore,
        public synchroniser: SurgicalTemplateSynchroniser,
        private shutdownController: AbortController) {
        super();
        this._copyTemplateToState(templateStore.userTemplate);
        this.addWatches(
            watch(
                () => templateStore.anatomicCupRotation,
                rotation => {
                    log.info('Anatomic cup rotation is %f/%f', rotation.anteversion, rotation.inclination);
                },
                { immediate: true },
            ),
            watch(
                () => templateStore.radiographicCupRotation,
                rotation => {
                    log.info('Radiographic cup rotation is %f/%f', rotation.anteversion, rotation.inclination);
                },
                { immediate: true },
            ),
        );
    }

    public get store(): HipTemplateStore {
        return this.templateStore;
    }

    public initialize(stemController: StemController): void {
        this._stemController = stemController;
    }

    public off(): void {
        super.off();
        this.synchroniser.stop();
        this.shutdownController.abort(PlannerAbortError);
    }

    /**
     * Linear waiting strategy until the current user changes are sent
     * to the server, and synchronized and the components data can be queried.
     *
     * @return if the components can be queried or not. If the there was an error, or the caller
     * made use of the cancellation and called abort, it returns false.
     */
    public waitUntilCanQueryComponents(): Promise<boolean> {
        return waitUntilCanQueryComponents(this, this.shutdownController.signal);
    }

    /**
     * Updates the stem & head. This will set the current stem & head to the stem assembly combo.
     * Note: This will revert any change done by the user in the head selection panel.
     */
    public async setStem(stem: CaseStem): Promise<void> {
        await this.templateStore.setStem(
            stem, {
                // clean up stem transform when selecting a new stem/head combo
                // Note: If the user goes back to a previous selection its selection will not be retained
                stemTransform: toRepresentation(makeNullRigidTransform()),
            });
        await this.templateStore.setHeadOffset(stem.headOffset);
        await this.stemController.updateSelectedStem();
    }

    /**
     * Computed property to disable 'reset' button when there are no changes
     */
    public get isResetStemEnabled(): boolean {
        const automatedTemplate = this.templateStore.automatedTemplate;
        assert.ok(automatedTemplate, 'Expected automated template to be already loaded');

        const currentTemplate = makeTemplateRepresentation(this.templateStore);
        return anyStemAssemblyChange(
            automatedTemplate, currentTemplate, this.plannerStore.enableStemTransform);
    }

    public async resetStem(): Promise<void> {
        log.info('Resetting the stem');
        await this.templateStore.resetStem();
        await this.stemController.updateSelectedStem();
    }

    /**
     * Updates **only** the neck part of the femoral assembly (e.g: xr, high-offset, standard-offset, etc),
     * Note: This will not make any change to the current head selected by the user.
     */
    public async setStemNeck(stem: CaseStem): Promise<void> {
        await this.templateStore.setStemNeck(stem);
        await this.stemController.updateSelectedStem(false);
    }

    /**
     * Updates **only** the head selection of the femoral assembly
     * Note: This will not make any change to the current stem neck variation selected by the user.
     */
    public async setHeadOffset(offset: number): Promise<void> {
        await this.templateStore.setHeadOffset(offset);
        await this.stemController.updateSelectedStem(false);
    }

    public get isResetCupEnabled(): boolean {
        const automatedTemplate = this.templateStore.automatedTemplate;
        assert.ok(automatedTemplate, 'Expected automated template to be already loaded');

        const currentTemplate = makeTemplateRepresentation(this.templateStore);
        return anyCupAssemblyChange(automatedTemplate, currentTemplate);
    }

    public async resetCup(): Promise<void> {
        await this.templateStore.resetCup();
    }

    public setCupOffset(offset: CupOffset): void {
        this.templateStore.cupOffset = offset;
    }

    /**
     * Updates the cup anteversion
     *
     * Note: The cup rotation has to be converted from the 'radiographic' angle mode
     * to the 'anatomic' angle mode for storage.
     */
    public setCupAnteversion(value: AngleDegree): void {
        const rotationAnatomic = CupRotationUtil.toAnatomic(
            CupRotationUtil.make(value, this.store.radiographicCupRotation.inclination));
        log.info(
            'Setting cup anteversion to %f (radiographic). Anatomic anteversion/inclination is %f/%f',
            value, rotationAnatomic.anteversion, rotationAnatomic.inclination
        );
        this.store.cupRotation = rotationAnatomic;
    }

    /**
     * Updates the cup inclination and schedules a server update.
     *
     * Note: The cup rotation has to be converted from the 'radiographic' angle mode
     * to the 'anatomic' angle mode for storage.
     */
    public setCupInclination(value: AngleDegree): void {
        const rotationAnatomic = CupRotationUtil.toAnatomic(
            CupRotationUtil.make(this.store.radiographicCupRotation.anteversion, value));
        log.info(
            'Setting cup inclination to %f (radiographic). Anatomic anteversion/inclination is %f/%f',
            value, rotationAnatomic.anteversion, rotationAnatomic.inclination
        );
        this.store.cupRotation = rotationAnatomic;
    }

    public get stemController(): StemController {
        if (this._stemController) {
            return this._stemController;
        } else {
            throw Error('HipTemplateController has not been in initialized with stemController');
        }
    }

    public async approvePlan(): Promise<ApprovePlanResult> {
        // This is a little bit ugly: the root problem is that ApproveButton is currently
        // managing its own error state, and this should be moved to be state on a store
        let approveResult: ApprovePlanResult | null = null;

        await executeOperation('approve-plan', async () => {
            // Force an update if it is pending and then wait until it is complete
            this.synchroniser.forceUpdate();
            const sync = await asyncWatchUntil(
                () => !this.synchroniser.hasUpdate, {
                    immediate: true,
                    signal: this.shutdownController.signal ,
                });
            if (sync === 'complete') {
                approveResult = await approvePlan(this.templateStore);
            } else {
                assert(sync === 'aborted');
                approveResult = { reason: 'shutting-down' };
            }
        });

        assert(approveResult !== null);
        return approveResult;
    }

    private _copyTemplateToState(surgicalTemplate: HipSurgicalTemplateRepresentation): void {
        const cupRotation = surgicalTemplate.cup_rotation;
        if (cupRotation) {
            log.info('Initial cup anteversion/inclination is %d/%d', cupRotation.anteversion, cupRotation.inclination);
            this.store.cupRotation = {
                anteversion: cupRotation.anteversion || 0,
                inclination: cupRotation.inclination || 0,
            };
        }

        const cupOffset = surgicalTemplate.cup_offset;
        if (cupOffset) {
            this.store.cupOffset = {
                ap: cupOffset.ap || 0,
                si: cupOffset.si || 0,
                ml: cupOffset.ml || 0,
            };
        }

        this.store.targetLegLengthChange = this.store.userTemplate.target_leg_length_change;
        this.store.targetOffsetChange = this.store.userTemplate.target_offset_change;
    }
}

/**
 * Linear waiting strategy until the current user changes are sent
 * to the server, and synchronized and the components data can be queried.
 *
 * @return if the components can be queried or not. If the there was an error, or the caller
 * made use of the cancellation and called abort, it returns false.
 */
async function waitUntilCanQueryComponents(controller: HipTemplateController, signal: AbortSignal): Promise<boolean> {
    try {
        await doWaitUntilCanQueryComponents(controller, signal);
        return true;
    } catch (e: unknown) {
        if (e instanceof PlannerAbortError) {
            log.debug('Wait until can-query cancelled');
        } else {
            log.error(e);
        }
    }

    return false;
}

/**
 * Utility that waits until the user changes are done before trying to load the components.
 *
 * TODO: Note this is a compensation for the lack of architecture on the client,
 *       where the write/read access to the surgical template should be orchestrated.
 *       E.g: The architecture could consider a queue with write/read operations,
 *       where write (PUT) and read (GET) operations can be scheduled,
 *       and before executing a read access, the write operations are finished.
 *
 * Note: This utility uses the client side artifact 'form' which holds the state
 *       of the white dialog.
 *       It is the intent of this utility to check the client side states,
 *       and the record_state of the surgical template, given in that
 *       way we can avoid querying the components if a change has been
 *       - scheduled, but not executed yet.
 *       - executed, and not done yet.
 *       The record_state of the surgical template does not know about the UI state.
 *
 * @see SurgicalTemplateFormState
 * @see SurgicalTemplateRecordState
 */
async function doWaitUntilCanQueryComponents(
    controller: HipTemplateController, signal: AbortSignal): Promise<void> | never {
    await WaitResourceUtil.waitUntil(
        () => {
            if (controller.synchroniser.hasUpdate ||
                !SurgicalTemplateUtil.hasComponents(controller.store.userTemplate)) {
                log.debug('waiting until can query components in progress');
                return false;
            } else {
                log.debug('waiting until can query components done');
                return true;
            }
        },
        100,
        { signal });
}
