import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
import {
    SurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import { DateTime } from 'luxon';
import { PlanState } from '@/lib/api/representation/case/plan/PlanState';

/** returns if a plan has a report associated */
export function hasReport(plan?: PlanRepresentation): boolean {
    return !!plan && LinkUtil.matches(plan, LinkRelation.report);
}

export function getReportUri(plan?: PlanRepresentation): string {
    if (plan && hasReport(plan)) {
        const uri = LinkUtil.getUri(plan, LinkRelation.report);
        if (uri) {
            return uri;
        }
    }
    return '';
}

export function getReportTitle(plan?: PlanRepresentation): string {
    if (plan) {
        const title = LinkUtil.getTitle(plan, LinkRelation.report);
        if (title) {
            return title;
        }
    }
    return '';
}

/**
 * @return whether a plan was originated by a particular template canonical version
 *
 * Note:
 * When a plan is created is always created on the context of a surgical template history version.
 * The plan representation has an 'up' link that tells which is the context in which was created.
 * From that point of view, we can tell from a plan which surgical template originated that plan.
 *
 * When a new surgical template version is created (due to a new user change),
 * we can programmatically compare the versions of the latest surgical template
 * and the context in which the plan was created.
 */
export function isCreatedByTemplate(plan: PlanRepresentation, template: SurgicalTemplateRepresentation): boolean {
    const latestTemplateUri = LinkUtil.getUri(template, LinkRelation.canonical);
    const templateThatTriggeredPlanGenerationUri = LinkUtil.getUri(plan, LinkRelation.up);

    return !!latestTemplateUri &&
        !!templateThatTriggeredPlanGenerationUri &&
        latestTemplateUri === templateThatTriggeredPlanGenerationUri;
}

export function isCreating(plan: PlanRepresentation): boolean {
    return ![PlanState.Completed.toString(), PlanState.Error.toString()].includes(plan.state || '') &&
        !isStale(plan);
}

/**
 * Check if the plan looks stale. A stale plan is one that is more than a short period old. In
 * general a plan is completed or fails in a matter of minutes. If the pubsub job that is creating
 * the plan crashes hard (or the VM is preempted) then the state may be left dangling in an indeterminate
 * state - this heuristic is a fallback to detect that condition.
 *
 *                      arbitrary time = 1 day
 *                 +-----------------------------+
 *                 |                             |
 *  Not stale      |   Not stale yet             | Stale
 *                 |                             |
 *                 |             timeA           | timeB          timeC
 * ----------------|---------------|-------------|--|-------------|-----------|
 *                 |                             |                           |
 *             plan created on         plan created on + arbitrary time
 *                                   ( max datetime where it should have been completed )
 *
 * Which is the same as: created_on < timeA < (created_on + arbitrary time) < timeB < timeC
 */
export function isStale(plan: PlanRepresentation): boolean {
    const date = createdOn(plan);
    return !!date && date.plus({ day: 1 }) < DateTime.utc();
}

/**
 * Check if the creation of the project plan looks to be broken.
 *
 * 1. If the plan says {@link  PlanState.Error} then it is hard broken.
 * 2. If the plan is old (stale) and it hasn't got to the {@link  PlanState.Completed} state then it is broken.
 */
export function isCreationBroken(plan: PlanRepresentation): boolean {
    return PlanState.Error.toString() === plan.state ||
        (isStale(plan) && PlanState.Completed.toString() !== plan.state);
}

/**
 * Get the created on date from the plan representation as a Luxon timestamp ({@link DateTime}).
 */
export function createdOn(plan: PlanRepresentation): DateTime | null {
    if (plan.created_on) {
        const when = DateTime.fromISO(plan.created_on, { zone: 'utc' });
        if (when) {
            return when;
        }
    }
    return null;
}
