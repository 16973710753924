import { AxiosInstance, AxiosResponse } from 'axios';
import { UserHipRole } from '@/stores/hipUser/types';
import { ProductId } from '@/lib/api/representation/ProductRepresentation';

export async function getUserRoles(axios: AxiosInstance, userUri: string): Promise<UserHipRole[]> {
    const userRolesUrl = `${userUri}/roles/product/${ProductId.Hip}`;
    return await axios
        .get(userRolesUrl)
        .then((response: AxiosResponse): UserHipRole[] => {
            return response.data.items;
        });
}