import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';

/**
 * Guard to determinate if x is a {@link HipSurgicalTemplateRepresentation}
 */
export function isHipSurgicalTemplate(x: unknown): x is HipSurgicalTemplateRepresentation {
    const asHipSurgicalTemplate = x as HipSurgicalTemplateRepresentation;
    return asHipSurgicalTemplate &&
        'cup_offset' in asHipSurgicalTemplate &&
        'cup_rotation' in asHipSurgicalTemplate;
}
