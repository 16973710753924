
    import { Component, Vue } from 'vue-property-decorator';
    import StyleUtil from '@/components/shared/StyleUtil';

    /**
     * A layout for the different dicom images view (axial, coronal, sagittal).
     * This component is highly coupled to the <CatStackImage> component.
     * The idea is to scale (usually down) cat stack images to fit in the layout boxes keeping
     * the aspect ratio of the slices.
     *
     * Difficulty
     * -----------
     * At the moment, the layout is implemented pre-defining the width/height in pixels of the main image
     * for the different media query breakpoints.
     *   1. The main view is a square of side L:
     *
     *   2. S1 (first secondary view) and S2 (second secondary view) are also a square.
     *      Both images are divided by a gap. In css this gap is expressed as a margin.
     *      The length of side of the square of S1 (and S2) is as follows:
     *
     *      SL =  (L - top margin between both images) / 2
     *
     *                   Width = L            SL
     *                ┌────────────────────┐ ┌────────┐
     *                │                    │ │  S1    │                  (zoom into S1/S2)
     *   Height = L   │                    │ │        │ SL             ─────────────────────┐
     *                │        Main        │ └────────┘ ◄─── gap                            │
     *                │        View        │ ┌────────┐                                     │
     *                │                    │ │  S2    │ SL                                  │
     *                │                    │ │        │                                     │
     *                └────────────────────┘ └────────┘                                     │
     *                                         SL                                           │
     *                                                                                      ▼
     *                                                                          ┌──────────────────────┐
     *                                                                          │      Padding         │
     *                                                                          │   ┌──────────────┐   │
     *                                                  The S1/S2 views         │   │              │   │
     *                                                  have a padding          │ P │   S1         │ P │
     *                                                                          │   │              │   │
     *                                                                          │   │              │   │
     *                                                                          │   └──────────────┘   │
     *                                                                          │     Padding          │
     *                                                                          └──────────────────────┘
     */
    @Component({ components: {} })
    export default class extends Vue {
        protected secondaryImagePadding = 20;

        /** The secondary images width is relative to the main one */
        protected get secondaryImageSquareSideLength(): number {
            // Accounts for 20px of margin between images
            // TODO: This could be implement to dynamically get the margin/padding values of the secondary images
            //       using the `getComputedStyle()`. @see BasicViewerUtil as a example
            const marginTopBetweenSecondaryImages = 20;

            return (this.mainImageSquareSideLength - marginTopBetweenSecondaryImages) * 0.5;
        }

        protected get mainImageStyle(): unknown {
            return {
                width: StyleUtil.toWidthOrHeight(this.mainImageSquareSideLength),
                maxWidth: StyleUtil.toWidthOrHeight(this.mainImageSquareSideLength),
                height: StyleUtil.toWidthOrHeight(this.mainImageSquareSideLength),
                maxHeight: StyleUtil.toWidthOrHeight(this.mainImageSquareSideLength),
            };
        }

        protected get secondaryImageStyle(): unknown {
            return {
                width: StyleUtil.toWidthOrHeight(this.secondaryImageSquareSideLength),
                maxWidth: StyleUtil.toWidthOrHeight(this.secondaryImageSquareSideLength),
                height: StyleUtil.toWidthOrHeight(this.secondaryImageSquareSideLength),
                maxHeight: StyleUtil.toWidthOrHeight(this.secondaryImageSquareSideLength),
            };
        }

        private get mainImageSquareSideLength(): number {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 100;
                case 'sm':
                    return 150;
                case 'md':
                    return 175;
                case 'lg':
                    return 512;
                case 'xl':
                    return 650;
                default:
                    return 512;
            }
        }
    }
