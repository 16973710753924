
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import JSONContent from '@/components/case-manage/extra/JSONContent.vue';
    import {
        FemoralSuitabilityRepresentation,
    } from '@/lib/api/representation/case/surgical-template/hip/femoral/FemoralSuitabilityRepresentation';
    import { isEmpty } from 'ramda';
    import LinkRelation from '@/lib/api/LinkRelation';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';
    import { UriDeconstructionUtil } from '@/components/case-plan/UrlDeconstructionUtil';
    import Hyperlink from '@/components/case-manage/Hyperlink.vue';

    /**
     * This is the case surgeon
     */
    // eslint-disable-next-line no-undef
    @Component({ components: { JSONContent, HyperlinkButton, Hyperlink } })
    export default class FemoralSuitability extends Vue {
        @Prop({ required: true })
        public value!: FemoralSuitabilityRepresentation | null;

        get hasData(): boolean {
            return !!this.value?.raw_ && !isEmpty(this.value.raw_);
        }

        /** Extract the history id from a path */
        private get historyId(): string {
            return UriDeconstructionUtil.pathLastNumberOfLink(this.value, LinkRelation.self);
        }
    }
