import { Triangle, Vector3 } from 'three';

export default class AreaUtil {
    /**
     * Calculate the area of a triangle. This was copied from the3js source.
     */
    public static areaOfTriangle(pointA: Vector3, pointB: Vector3, pointC: Vector3): number {
        return new Triangle(pointA, pointB, pointC).getArea();
    }
}
