
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { NameRepresentationUtil } from '@/components/case-settings/utils/NameRepresentationUtil';
    import { NameRepresentation } from '@/lib/api/representation/NameRepresentation';
    import HomeTextHighlight from '@/views/home/HomeTextHighlight.vue';

    /**
     * A simple component that display a name from a {@link NameRepresentation}. This name
     * is present in a user (aka surgeon) or a patient.
     */
    @Component({
        components: { HomeTextHighlight },
    })
    export default class Username extends Vue {
        @Prop({ required: false, default: null })
        public value?: NameRepresentation | null;

        @Prop({ required: false, default: false })
        public highlightText!: boolean;

        /**
         * A text that will be displayed when the formatted name is not available
         */
        @Prop({ required: false })
        public notAvailableText?: string;

        protected get name(): string {
            return NameRepresentationUtil.format(this.value);
        }

        protected get nameIsPopulated(): boolean {
            return this.name.length > 0;
        }
    }
