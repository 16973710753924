import { DicomInfo } from '@/lib/dicom/DicomInfo';
import fileType, { FileTypeResult } from 'file-type';
import ContentType from '@/lib/http/mimetype';
import { DataSetUtil } from '@/lib/dicom/DataSetUtil';
import {
    SeriesInstanceUid,
    TagBurnedInAnnotation,
    TagColumns,
    TagImageOrientationPatient,
    TagImagePositionPatient,
    TagImageType,
    TagInstanceNumber,
    TagModality,
    TagPatientDateOfBirth,
    TagPatientIdentityRemoved,
    TagPatientName,
    TagPatientSex,
    TagPhotometricIntepretation,
    TagPixelData,
    TagPixelSpacing,
    TagRows,
    TagSliceThickness,
    TagSopClassUid,
    TagTransferSyntaxUid,
} from '@/lib/dicom/DicomTags';
import { DicomMessage, DicomMessageLevel } from '@/lib/dicom/DicomSeries';
import DicomUtils from '@/lib/dicom/DicomUtil';
import { DataSet, Element } from 'dicom-parser';
import { DicomPatientSexValuesType, DicomSeriesUtil } from '@/lib/dicom/DicomSeriesUtil';

export class DicomInfoFactory {
    /**
     * Construct a small info data structure that is a summary of the DICOM file to allow
     * the main series to be identified.
     *
     * It is important to note that the original parsed DICOM (and DataSet) needs to be discarded
     * so as to free memory.
     */
    public static makeDicomInfo(f: File): Promise<DicomInfo> {
        return DicomUtils
            .readFileType(f)
            .then((theFileType: fileType.FileTypeResult) => {
                if (theFileType && theFileType.mime === ContentType.Dicom) {
                    return DicomUtils.parseFile(f)
                        .then((dataSet) => {
                            return DicomInfoFactory.makeDicomInfoFromDataSet(f, theFileType, dataSet);
                        });
                } else {
                    return this.makeNonDicomFileInfo(f, theFileType);
                }
            });
    }

    private static makeNonDicomFileInfo(f: File, theFileType: fileType.FileTypeResult): DicomInfo {
        const info = {
            file: f,
            isExcluded: true,
            messages: [],
            imageType: [],
        };
        DicomSeriesUtil.appendMessage(
            info.messages,
            DicomMessageLevel.Info,
            theFileType?.mime ?
                `File '${f.name}' is not a DICOM file (${theFileType.mime})` :
                `File '${f.name}' is not a DICOM file`);
        return info;
    }

    /**
     * Create DICOM summary information from a full file.
     *
     * This function will map the tuple of
     *
     * @param theFile the file where the data is stored. This may not be a filesystem file, depending on where
     *                the file transfer/drag-n-drop operation has been started from.
     * @param theFileType the type of file (by using 'magic) that the file is. For this code path this is always
     *                a DICOM file type
     * @param dataSet the DICOM meta data from the file
     */
    private static makeDicomInfoFromDataSet(theFile: File, theFileType: FileTypeResult, dataSet: DataSet): DicomInfo {
        const messages: DicomMessage[] = [];

        // add an DICOM parsing messages to our message list.
        if (dataSet.warnings) {
            messages.concat(...dataSet.warnings.map(m => DicomSeriesUtil.makeMessage(DicomMessageLevel.Warning, m)));
        }

        // Go through the DICOM fields and validate the data. This needs to be done early before the DICOM
        // content is discarded (as it will consume valuable RAM in the browser).
        //
        // What we have seen is that some DICOM files have dates inserted into them in the incorrect format. They
        // look sane as dates, but do not conform to the standard.
        //
        // TODO: what should we do with the invalid fields (other than logging a message)
        const _invalidFields = Object
            .values(dataSet.elements)
            .filter((e: Element) => this.isInvalidDicomField(e, dataSet, messages));

        return {
            file: theFile,
            fileType: theFileType,
            // create the messages for the file, starting by using the parser messages
            messages,
            isExcluded: false,
            modality: DataSetUtil.getCsValue(dataSet, TagModality),
            sopClassUid: DataSetUtil.getUiValue(dataSet, TagSopClassUid),
            imageType: DataSetUtil.getCsValues(dataSet, TagImageType),
            transferSyntaxUid: DataSetUtil.getUiValue(dataSet, TagTransferSyntaxUid),
            instanceNumber: DataSetUtil.getIsValue(dataSet, TagInstanceNumber),
            seriesInstanceUid: DataSetUtil.getUiValue(dataSet, SeriesInstanceUid),
            imagePositionPatient: DataSetUtil.getDsValues(dataSet, TagImagePositionPatient),
            imageOrientationPatient: DataSetUtil.getDsValues(dataSet, TagImageOrientationPatient),
            pixelSpacing: DataSetUtil.getDsValues(dataSet, TagPixelSpacing),
            rows: DataSetUtil.getUsValue(dataSet, TagRows),
            columns: DataSetUtil.getUsValue(dataSet, TagColumns),
            sliceThickness: DataSetUtil.getDsValue(dataSet, TagSliceThickness),
            burnedInAnnotation: DataSetUtil.getCsValue(dataSet, TagBurnedInAnnotation),
            patientIdentityRemoved: DataSetUtil.getCsValue(dataSet, TagPatientIdentityRemoved),
            patientName: DataSetUtil.getPnValue(dataSet, TagPatientName),
            patientBirthDate: DataSetUtil.getDaValue(dataSet, TagPatientDateOfBirth),
            patientSex: DataSetUtil.getCsValue(dataSet, TagPatientSex) as DicomPatientSexValuesType,
            pixelDataLength: DataSetUtil.getObOrOwLengthValue(dataSet, TagPixelData),
            photometricInterpretation: DataSetUtil.getCsValue(dataSet, TagPhotometricIntepretation),
        };
    }

    /**
     * Check if a DICOM field is invalid.
     *
     * At this stage only 'DA" (date) fields are checked.
     */
    private static isInvalidDicomField(e: Element, dataSet: DataSet, messages: DicomMessage[]) {
        if (e.vr === 'DA') {
            this.validateDicomDaField(dataSet, e, messages);
        }
        return false; // assumed to be valid
    }

    /**
     * Check a DICOM date field is valid.
     */
    private static validateDicomDaField(dataSet: DataSet, e: Element, messages: DicomMessage[]) {
        const count = dataSet.numStringValues(e.tag);
        if (count && count > 0) {
            for (let index = 0; index < count; ++index) {
                const dateStr = dataSet.string(e.tag, index);
                if (dateStr && dateStr.length === 8) {
                    // ok
                } else {
                    DicomSeriesUtil.appendMessage(
                        messages,
                        DicomMessageLevel.Warning,
                        `Date (DA) field (${e.tag}) is invalid and should be of the from 'YYYYMMDD' but has the value '${dateStr}'`);
                }
            }
        } else {
            DicomSeriesUtil.appendMessage(
                messages,
                DicomMessageLevel.Warning,
                `Date (DA) field (${e.tag}) is empty`);
        }
    }
}
