import { Vue } from 'vue/types/vue';

export default class SlotUtil {
    /**
     *  Check if there is content for a slot, meaning, something like this exists:
     *
     *  E.g: with slot name being "custom-button":
     *  <template v-slot:custom-button="{on}">
     *
     *  {@see https://stackoverflow.com/questions/44077277/only-show-slot-if-it-has-content}
     */
    public static hasSlot(vueInstance: Vue, slotName: string): boolean {
        return !!vueInstance.$slots[slotName] || !!vueInstance.$scopedSlots[slotName];
    }
}
