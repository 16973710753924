
    import { mapStores } from 'pinia';
    import { Component, Vue } from 'vue-property-decorator';
    import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate';
    import { confirmed, required, min } from 'vee-validate/dist/rules';
    import { rules } from '@/plugins/veevalidate';
    import { ResetPasswordStore, useResetPassword } from '@/views/auth/stores/resetPasswordStore';
    import ViewPath from '@/router/viewPath';

    extend('required', required);
    extend('confirmed', confirmed);
    extend('min', min);
    extend('atLeastOneNumber', rules.atLeastOneNumber);
    extend('atLeastOneLowercase', rules.atLeastOneLowercase);
    extend('atLeastOneUppercase', rules.atLeastOneUppercase);
    extend('atLeastOneSpecialChar', rules.atLeastOneSpecialChar);

    localize({
        en: {
            messages: {
                min: 'password must be at least 8 characters long.',
                confirmed: 'password confirmation does not match new password.',
            },
        },
    });

    /**
     * this is part 2 of 2 of the reset password workflow.
     *
     * users can reset their password as long as they have a valid reset password token.
     */
    @Component({
        components: { ValidationProvider, ValidationObserver },
        computed: { ...mapStores(useResetPassword) },
    })
    export default class ResetPassword extends Vue {
        $refs!: {
            observer: typeof ValidationObserver
        };

        resetPasswordStore!: ResetPasswordStore;

        viewPaths = ViewPath;

        mounted(): void {
            this.resetPasswordStore.$reset();
            this.resetPasswordStore.email = this.$route.query.e as string;
            this.resetPasswordStore.token = this.$route.query.t as string;
        }

        async onReset() {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                this.resetPasswordStore.doPasswordReset();
            }
        }
    }
