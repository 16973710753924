import { BackSide, Color, FrontSide, Material } from 'three';
import ThreeMaterialFactory from '@/lib/viewer/ThreeMaterialFactory';

/**
 * A representation of a color that is a value and not an actual {@link Color} object.
 * We do not to define material parameters using Color objects as it can lead to colors being accidentally shared and
 * mutated.
 */
export type ColorValue = number | string;

/**
 * The parameters that define a set of materials that can be applied to objects in the 3D scene.
 * These parameters correspond to a {@link MaterialSet set} of threejs {@link Material materials}.
 */
export interface MaterialParameters {
    /**
     * The primary color of the surface of the object.
     *
     * Default color is white.
     */
    readonly color?: ColorValue;

    /**
     * Optional color to make the back-side of the object. Will be a solid color i.e. not lit like a 3d object.
     *
     * Default is for the back-side to have the same material as the front-side.
     */
    readonly solidBackSideColor?: ColorValue;

    /**
     * True for transparent objects and/or for {@link Object3D.renderOrder renderOrder} sorting.
     *
     * Default is false (no transparency).
     */
    readonly transparent?: boolean;

    /**
     * How opaque an object is, where 0.0 is fully transparent and 1.0 is fully opaque.
     *
     * Default is 1.0 (fully opaque).
     */
    readonly opacity?: number;

    /**
     * Support for individual color per-vertex. Will be mixed with the primary color if it is set.
     *
     * Default is false (no vertex colors).
     */
    readonly vertexColors?: boolean;
}

/**
 * A set of materials that can be applied to an object in the 3D scene.
 */
export interface MaterialSet {
    /**
     * The material that will be applied to objects in {@link ViewingMode.Normal normal mode}. If there is a backSide
     * material in the set it will be applied to frontSide faces only, otherwise it will be applied to both faces.
     */
    readonly normal: Material;

    /**
     * Optional material that will be applied to backSide faces of objects in {@link ViewingMode.Normal normal mode}.
     */
    readonly backSide?: Material;

    /**
     * Material that will be applied in {@link ViewingMode.Xray xray mode}
     */
    readonly xray: Material;
}

export default class MaterialFactory {
    /**
     * Make a {@link MaterialSet set of materials} given the supplied parameters.
     */
    public static makeMaterials(params: MaterialParameters, name?: string): MaterialSet {
        const primaryColor = params.color ? new Color(params.color) : undefined;
        const opacity = params.opacity ?? 1.0;
        const transparent = params.transparent || opacity < 1.0;

        const normal = ThreeMaterialFactory.makePhongMaterial({
            color: primaryColor,
            opacity,
            transparent,
            vertexColors: params.vertexColors ?? false,
        }, name ? name + '-normal' : 'normal');

        const xray = ThreeMaterialFactory.makeXRayMaterial(
            { color: primaryColor },
            name ? name + '-xray' : 'xray');

        if (params.solidBackSideColor) {
            // Create backside material as a solid color.

            // The eventual rendered color will be gamma corrected along with the final rendered image.
            // To make this rendered color match the color in the parameters we need to 'gamma un-correct'
            // the color we assign to the material, using the default gamma factor.
            // See https://threejs.org/docs/api/en/renderers/WebGLRenderer.html#gammaFactor
            const backsideColor = new Color(params.solidBackSideColor).convertGammaToLinear();

            const backSide = ThreeMaterialFactory.makeSolidMaterial({
                color: backsideColor,
                side: BackSide,
                opacity,
                transparent,
            });

            normal.side = FrontSide;
            if (name) {
                normal.name = name ? name + '-front-normal' : 'front-normal';
                backSide.name = name ? name + '-back-normal' : 'back-normal';
            }
            return { normal, backSide, xray };
        } else {
            return { normal, xray };
        }
    }
}
