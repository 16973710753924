
    import { Component, Vue } from 'vue-property-decorator';
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
    import { email, required } from 'vee-validate/dist/rules';
    import { AuthenticatorEvent, AuthRevokeReason, AuthRevokeReasonType } from '@/lib/http/AuthenticatorService';
    import AuthenticationUtils, { ResourceAuthenticationScheme } from '@/lib/api/resource/auth/AuthenticationUtils';

    import anylogger from 'anylogger';
    import { AxiosError } from 'axios';
    import LoggedOutByInactivityMessage from '@/components/authentication/notice/LoggedOutByInactivityNotice.vue';
    import FormusUnavailableNotice from '@/components/authentication/notice/FormusUnavailableNotice.vue';
    import ViewPath from '@/router/viewPath';

    const log = anylogger('Login');

    // Add the v-validate rules used in this component
    extend('required', required);
    extend('email', email);

    /**
     * Login:
     *    - waits for the unauthorised event (triggered by no network)
     *
     * Known residuals:
     *  - there is not default action (i.e. pressing enter doesn't perform a login)
     *  - there is no way to cancel or retry the login
     *  - firefox doesn't save username/password
     *
     *  Validation:
     *    This component uses v-validate v3
     *
     *    see
     *      - https://www.baianat.com/labs/code/veevalidate-3-0
     */
    @Component({
        components: {
            FormusUnavailableNotice,
            LoggedOutByInactivityMessage,
            ValidationProvider,
            ValidationObserver,
        },
    })
    export default class Login extends Vue {
        protected viewPaths = ViewPath;

        public declare $refs: Vue['$refs'] & { observer: InstanceType<typeof ValidationObserver> };

        /**
         * Whether the login dialog is visible
         */
        protected isVisible = false;

        /** Whether the password can be seen by the user - defaults to hidden */
        protected showPassword = false;

        /**
         * Whether the 'submit' button is enabled.
         *
         * The button is disabled once it is pressed and a http request is
         * made to perform the authentication.
         *
         * Note: no cancel support is provided.
         */
        protected submitEnabled = true;

        /** This is the error message shown to the user. This is for non-validation errors. */
        protected error = '';

        /** Input field for the username/email address */
        protected email = '';

        /** Input password input field value */
        protected password = '';

        /**
         * An optional alternative notice to display instead of the login
         * fields. This is used when the application and the API is unavailable
         * to end users.
         */
        protected serverUnavailableNotice: string | null = null;

        /** How the server says to do the authentication */
        private resourceAuthentication: ResourceAuthenticationScheme | null = null;

        private logOutReason: AuthRevokeReason.Inactivity | AuthRevokeReason.LogOut | null = null;

        protected created(): void {
            log.debug('Authentication Login created');
            this.$authEvent.$on(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
            this.$authEvent.$on(AuthenticatorEvent.authRequired, this.loginRequired);
        }

        protected beforeDestroy(): void {
            this.$authEvent.$off(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
            this.$authEvent.$off(AuthenticatorEvent.authRequired, this.loginRequired);
        }

        /**
         * Login/authenticate based on www-authenticate headers in the 401 response/error
         */
        private loginRequired(resourceAuthentication: ResourceAuthenticationScheme, _error: AxiosError): void {
            log.info('Authentication required, showing login dialog %o', resourceAuthentication);
            this.error = '';
            this.submitEnabled = true;
            this.isVisible = true;
            this.resourceAuthentication = resourceAuthentication;
            this.email = '';
            this.password = '';
        }

        onSubmit(): void {
            this.error = '';
            this.submitEnabled = false;

            const all: Promise<boolean> = this.$refs.observer.validate();
            all
                .then((isValid) => {
                    if (isValid && this.resourceAuthentication) {
                        // Fake web request
                        log.info('Performing authentication to \'%s\'', this.resourceAuthentication.uri);
                        return AuthenticationUtils.createAuthenticationToken(
                            this.$http, this.resourceAuthentication.uri, this.email, this.password)
                            .then((tokens) => {
                                this.isVisible = false;
                                this.submitEnabled = true;
                                this.email = '';
                                this.password = '';
                                log.info('Authentication for %s complete', this.email);
                                this.$authEvent.$emit(AuthenticatorEvent.authConfirmed, tokens);
                            });
                    } else {
                        log.info('Validation failed');
                        this.submitEnabled = true;
                    }
                })
                .catch(e => {
                    if (e.response && e.response.status === 403) {
                        this.submitEnabled = true;
                        this.error = `Login failed, your email address and/or password are incorrect`;
                    } else if (e.response && e.response.status === 451) {
                        this.submitEnabled = true;
                        this.error = `Formus is unavailable due to legal reasons`;
                        this.serverUnavailableNotice = e.response.data;
                    } else {
                        this.submitEnabled = true;
                        this.error = `Failed to login : ${e}`;
                    }
                });
        }

        public get isDialogVisible(): boolean {
            return this.isVisible && this.$route.meta?.requireAuth;
        }

        private onAuthRevoke(payload?: AuthRevokeReasonType): void {
            this.logOutReason = payload?.reason ?? null;
        }

        protected get isLogOutByInactivity(): boolean {
            return this.logOutReason === AuthRevokeReason.Inactivity;
        }
    }
