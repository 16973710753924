
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import DistanceMeasurement from '@/components/case-manage/measurement/DistanceMeasurement.vue';
    import RatioMeasurement from '@/components/case-manage/measurement/RatioMeasurement.vue';
    import AngleMeasurement from '@/components/case-manage/measurement/AngleMeasurement.vue';
    import RadialMeasurement from '@/components/case-manage/measurement/RadialMeasurement.vue';
    import PlaneMeasurement from '@/components/case-manage/measurement/PlaneMeasurement.vue';
    import { Product } from '@/lib/api/representation/ProductRepresentation';
    import AxisMeasurement from '@/components/case-manage/measurement/AxisMeasurement.vue';
    import MeasurementsUtil from '@/lib/api/resource/case/study/MeasurementsUtil';
    import {
        MeasurementGroupRepresentation,
    } from '@/lib/api/representation/case/measurements/MeasurementGroupRepresentation';

    /**
     * A **recursive** measurement group container used mostly in the manage page of 'studies' and 'surgical-templates'
     */
    @Component({
        // Being a recursive component, it was not working on production builds.
        // @see https://stackoverflow.com/questions/62794714/vue-typescript-recursive-component
        //
        name: 'MeasurementGroup',
        components: {
            DistanceMeasurement,
            AngleMeasurement,
            RatioMeasurement,
            RadialMeasurement,
            PlaneMeasurement,
            AxisMeasurement,
            // eslint-disable-next-line no-use-before-define
            MeasurementGroup,
        },
    })
    export default class MeasurementGroup extends Vue {
        @Prop({ required: true })
        public value!: MeasurementGroupRepresentation | null;

        /** the name of the product - this is used for language string loading */
        @Prop({ required: true })
        public product!: Product;

        /** i18n identifier for the group name */
        protected groupName(name: string): string {
            return MeasurementsUtil.i18nGroupId(this.product, name);
        }
    }
