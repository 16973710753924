
    import ProfileDropdown from '@/components/profile/ProfileDropdown.vue';
    import Login from '@/components/authentication/Login.vue';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import CaseStateStepper from '@/components/case/state-stepper/CaseStateStepper.vue';
    import { TitleComponent } from '@/components/TitleUtil';
    import Notifications from '@/components/notifications/Notifications.vue';

    import anylogger from 'anylogger';
    import SupportMenu from '@/components/SupportMenu.vue';
    import ViewPath from '@/router/viewPath';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import { RouteUtils } from '@/router/RouteUtils';
    import AsyncComputed from 'vue-async-computed-decorator';
    import UserResource from '@/lib/api/resource/user/UserResource';
    import { OrganisationNames } from '@/lib/api/representation/OrganisationRepresentation';
    import { isHipCaseRepresentation } from '@/lib/api/representation/case/CaseUtil';
    import CaseResource from '@/lib/api/resource/case/CaseResource';
    import { AuthenticatorEvent } from '@/lib/http/AuthenticatorService';
    import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
    import { showTopBanner } from '@/featureFlags/showTopBanner';

    const log = anylogger('AppBar');

    const settingsComponents = { CaseStateStepper, Notifications, ProfileDropdown, Login, SupportMenu };

    /**
     * The top bar of the application
     * - Renders branding logos: formuslabs and zimmer (depending on user authenticated or not, and user organization)
     * - Placeholder for dynamic <title-component> on each route view page. {@see TitleComponent}
     * - User support
     * - User Profile dropdown
     */
    @Component({ components: settingsComponents })
    export default class AppBar extends Vue {
        public declare $refs: Vue['$refs'] & { main: HTMLElement };

        /** binding of RouteUtils so they are available on template */
        protected RouteUtils = RouteUtils;

        /** The user resource for the identity the user is authenticated as. */
        private user: UserRepresentation | null = null;

        protected created(): void {
            this.$authEvent.$on(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
            this.$authEvent.$on(AuthenticatorEvent.authenticated, this.onAuthConfirmed);
        }

        protected beforeDestroy(): void {
            this.$authEvent.$off(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
            this.$authEvent.$off(AuthenticatorEvent.authenticated, this.onAuthConfirmed);
        }

        /**
         * When user logout out, delete current user
         */
        private async onAuthRevoke(): Promise<void> {
            log.debug('User remove after auth revoke event');
            this.user = null;
        }

        /**
         * On {@link AuthenticatorEvent.authConfirmed} handler
         *
         * TODO: This is a quick implementation and probably not quiet right. Not much thought,
         * neither designed was in consideration.
         * At this moment, the {@link AuthenticatorEvent.authConfirmed} event is only emitted from the <Login/> dialog,
         * and is the only auth confirmed event we have on the app. If at some point, we have other auth confirmation
         * events, like google authentication, this will be called too.
         */
        private async onAuthConfirmed(): Promise<void> {
            log.debug('User fetched after auth confirmed event');
            this.user = await UserResource.getMeUser(this.$api, this.$apiOptions);
        }

        /**
         * The component type and the component property data for that component
         * as a single value.
         *
         * Note: This is a single value (with two concepts) so that the value can be set
         * in a single event by the child view component.
         */
        @Prop({ required: true, validator: notNilValidator('title') })
        protected title!: TitleComponent;

        /** alias/export of {@link ViewPath.Home} */
        protected get homePath(): string {
            return ViewPath.Home;
        }

        @AsyncComputed({ default: false })
        protected async isHipCase(): Promise<boolean> {
            /**
             * XXX
             * TODO: Case should be in global state (vuex ?), or typed at least
             */
            const caseResource = this.title.titleData?.project as CaseResource;
            return isHipCaseRepresentation(caseResource);
        }

        @AsyncComputed({ default: false })
        protected async userBelongsToZimmerOrganization(): Promise<boolean> {
            if (this.user) {
                await UserResource.getOrganisation(this.user, this.$apiOptions);

                if (this.user.organisation) {
                    return this.user.organisation.name === OrganisationNames.ZB;
                } else {
                    log.warn('User not assigned to any organisation');
                }
            }
            return false;
        }

        get hasBanner(): string {
            return showTopBanner() ? 'has-banner' : '';
        }
    }
