import { AuthenticationStorage } from '@/lib/http/AuthenticatorService';
import anylogger from 'anylogger';
import {
    USER_ACTIVITY_EVENTS_WHILE_IN_ACTIVE_STATE,
    USER_ACTIVITY_EVENTS_WHILE_IN_BEFORE_TIMEOUT_STATE,
} from '@/components/idle/events';
import { IdleTimerConfig, IdleTimerOnStateCallback, Milliseconds } from '@/components/idle/IdleTimerConfig';
import { IdleTimerState } from '@/components/idle/IdleTimerState';
import { debounce, DebouncedFunc } from 'lodash';

const log = anylogger('IdleTimer');

/**
 * A class that constantly monitors for user activity in the browser
 * and allows to subscribe to the changes on the user idleness state.
 *
 * The user idleness is monitored:
 * 1. In the **current tab** through listening to particular events that are triggered on the windows object.
 * 2. In other tabs through constantly checking the shared **expiration time on the local storage**
 *
 * Side-effects:
 * 1. To the local storage with the expiration time.
 *
 * @see IdleTimerState
 * @see AuthenticationStorage
 *
 *
 *
 * ┌───────────────────────────────────────────────────────────────────────────────────────┐
 * │A browser instance                                                                     │
 * │                                                                                       │
 * │                                ┌──────────────────────────┐                           │
 * │                                │ LocalStorage             │                           │
 * │                                │ ==========               │                           │
 * │                                │                          │                           │
 * │               ┌───────────────►│-expirationTime           │◄─────────┐                │
 * │               │                │                          │          │                │
 * │               │                │                          │          │                │
 * │               │                │                          │          │                │
 * │ constantly    │                └────▲──────────────▲──────┘          │ constantly     │
 * │ read the      │                     │               │                │ read the       │
 * │ expirationTime│                     │               │                │ expirationTime │
 * │ (setInterval) │                     │               │                │ (setInterval)  │
 * │               │                     │               │                │                │
 * │               │                ┌────┴───────────────┴─────┐          │                │
 * │               │                │  Update expirationTime   │          │                │
 * │               │                │  Date.now() + timeout    │          │                │
 * │               │                └────┬───────────────┬─────┘          │                │
 * │               │                     │               │                │                │
 * │           ┌───┴─────┐               │               │          ┌─────┴────┐           │
 * │           │         │               │               │          │          │           │
 * │           │ Tab 1   ├───────────────┘               └──────────┤ Tab 2    │           │
 * │           │ ====    │ user activity               user activity│ ====     │           │
 * │           │         │                                          │          │           │
 * │           └─────────┘                                          └──────────┘           │
 * │                                                                                       │
 * │                                                                                       │
 * │                                                                                       │
 * │                                                                                       │
 * └───────────────────────────────────────────────────────────────────────────────────────┘
 *
 * This class has been adapted from  here.
 * @see {@link https://medium.com/tinyso/how-to-detect-inactive-user-to-auto-logout-by-using-idle-timeout-in-javascript-react-angular-and-b6279663acf2}
 * @see {@link https://codesandbox.io/embed/sleepy-euler-kw4m0?fontsize=14&hidenavigation=1&theme=dark}
 */
export class IdleTimer {
    /** @see {@link IdleTimerConfig.timeout} */
    protected readonly timeout: Milliseconds;

    /** @see {@link IdleTimerConfig.beforeTimeout} */
    protected readonly beforeTimeout: Milliseconds;

    /** @see {@link IdleTimerConfig.onStateChange} */
    protected readonly onStateChange: IdleTimerOnStateCallback;

    protected readonly watchHasExpired: number;

    private events: Record<IdleTimerState, string[]> = {
        [IdleTimerState.Active]: USER_ACTIVITY_EVENTS_WHILE_IN_ACTIVE_STATE,
        [IdleTimerState.BeforeTimeout]: USER_ACTIVITY_EVENTS_WHILE_IN_BEFORE_TIMEOUT_STATE,

        // This is a final state. There are not events that consider the user as active.
        [IdleTimerState.Timeout]: [],
    };

    protected state = IdleTimerState.Active;

    /**
     * A function to debounce the update of the expiration time to the local storage.
     *
     * Note:
     * 1. It is important to note this handler has to be bounded to the function and unique, so when
     * removing events the unique handler is removed.
     * 2. The function is debounced to avoid performing a side effect to the local storage with every user activity.
     */
    private readonly debounceUpdateExpirationTime: DebouncedFunc<() => void>;

    private _stopped = false;

    constructor({ timeout, beforeTimeout, onStateChange }: IdleTimerConfig) {
        log.info('Inactivity Observer started');
        this.timeout = timeout;
        this.beforeTimeout = beforeTimeout;
        this.onStateChange = onStateChange;

        // Immediate update of the expiration time.
        this.updateExpirationTime();

        this.debounceUpdateExpirationTime = debounce(
            this.updateExpirationTime.bind(this), 300, { maxWait: 3 * 1000 });

        this.addEventListeners();
        this.watchHasExpired = window.setInterval(this.checkExpirationTime.bind(this), 1000);
    }

    public static hasExpired(expirationTime: number): boolean {
        return expirationTime <= Date.now();
    }

    public static isAboutToExpire(expirationTime: number, beforeTimeout: number): boolean {
        const now = Date.now();
        const beforeTimeoutDate = new Date(expirationTime - beforeTimeout).getTime();
        return beforeTimeoutDate <= now && now < expirationTime;
    }

    public stop(): void {
        this._stopped = true;

        clearInterval(this.watchHasExpired);
        this.debounceUpdateExpirationTime.cancel();

        AuthenticationStorage.clearExpirationTime();

        this.removeEventListeners();
    }

    private checkExpirationTime(): void {
        if (this._stopped) {
            log.debug('Avoid executing hanging interval in IdleTimer');
            return;
        }

        if (this.state === IdleTimerState.Timeout) {
            log.debug('Avoid executing hanging interval in IdleTimer. Already in timeout state');
            return;
        }

        const expirationTime = AuthenticationStorage.getExpirationTime();
        if (expirationTime === null) {
            log.debug('Expiration time is null. Probably cleared on a different browser tab');
            this.setState(IdleTimerState.Timeout);
        } else {
            if (IdleTimer.hasExpired(expirationTime)) {
                this.setState(IdleTimerState.Timeout);
            } else {
                if (IdleTimer.isAboutToExpire(expirationTime, this.beforeTimeout)) {
                    this.setState(IdleTimerState.BeforeTimeout);
                } else {
                    this.setState(IdleTimerState.Active);
                }
            }
        }
    }

    /**
     * Update the expiration time in the browser local storage.
     *
     * Note: The side effect to the local storage is only done after 300ms that the user
     * had stopped interacting with the app. This is kind of a 'debounce' custom function.
     */
    private updateExpirationTime(): void {
        AuthenticationStorage.setExpirationTime(Date.now() + this.timeout);
    }

    /** Register the events that are considered as user activity for the given state */
    private addEventListeners(): void {
        (this.events[this.state] || []).forEach((eventName: string) => {
            window.addEventListener(eventName, this.debounceUpdateExpirationTime);
        });
    }

    /** Remove the events that are considered as user activity for the given state */
    private removeEventListeners(): void {
        (this.events[this.state] || []).forEach((eventName: string) => {
            window.removeEventListener(eventName, this.debounceUpdateExpirationTime);
        });
    }

    /**
     * 1. Conditionally set the new state of the time.
     * 2. Register the event listeners for the new state (and remove the ones of the previous state).
     * 3. Invokes [onStateChange]{@link this.onStateChange} with the new state.
     * 4. If timeout, stops.
     */
    private setState(state: IdleTimerState) {
        if (this.state !== state) {
            this.removeEventListeners();

            const previousState = this.state;
            this.state = state;

            this.addEventListeners();

            log.debug('idle timer state change from \'%s\' to: \'%s\'', previousState, this.state);

            this.onStateChange(this.state);

            if (this.state === IdleTimerState.Timeout) {
                this.stop();
            }
        } else {
            log.debug('State already %s, avoid setting it again.', this.state);
        }
    }
}
