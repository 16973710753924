import AcidObject3d, { AcidObject3dType, ObjectProperties } from '@/lib/planning/objects-3D/AcidObject3d';
import { CaseComponentRepresentation } from '@/lib/api/representation/case/CaseComponentRepresentation';
import ViewerUtils from '@/lib/planning/viewer/ViewerUtils';

export default class Implant3d extends AcidObject3d {
    constructor(
        /**
         * The case component representation metadata that lives with the implant 3d
         */
        private fittedComponent: CaseComponentRepresentation,
        properties: ObjectProperties) {
        super({ type: AcidObject3dType.Implant, ...properties });
        if (fittedComponent.tmatrix) {
            this.objectMatrix = ViewerUtils.makeMatrixFromArray(fittedComponent.tmatrix);
        } else {
            throw new Error('No tmatrix field');
        }
    }

    /**
     * Generic get case component
     * HACK until with have different Implant3D classes (stem, head, etc, that configure the generic)
     */
    public getCaseComponent<T extends CaseComponentRepresentation>(): T {
        return this.fittedComponent as T;
    }
}
