
    import { Component, Prop, Vue } from 'vue-property-decorator';

    export type RowChange = {
        label?: string,
        from: string | number | null,
        to: string | number | null
    }

    export class RowChangeFactory {
        public static make(from: string | number | null, to: string | number | null, label?: string): RowChange {
            return { label, from, to };
        }
    }

    /**
     * A simple ui component with to display a from -> to information.
     * e.g.:
     * 'processing' -> completed
     * Note: It only display the content if there is a difference.
     * Note: The 'from' is strike through. TODO: make it optional.
     */
    @Component({ components: {} })
    export default class ArrowFromTo extends Vue {
        @Prop({ required: false })
        private label!: string;

        @Prop({ required: true })
        private from!: string | number | null;

        @Prop({ required: true })
        private to!: string | number | null;
    }
