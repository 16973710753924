/**
 * Different modes for rendering the objects in the 3D scene
 */
export enum ViewingMode {
    /**
     * Standard shaded and lit appearance
     */
    Normal = 'normal',

    /**
     * Surfaces are transparent dependent on the angle they are viewed, becoming more opaque as they become tangential
     * to the view direction.
     */
    Xray = 'xray',
}
