/**
 *  Vee validate is used for forms/dialog field validation.
 *
 *  see
 *    - https://baianat.github.io/vee-validate/
 *    - https://www.mistergoodcat.com/post/vuejs-validation-with-typescript
 *
 *  NOTE: bundling size can be reduced
 * see https://baianat.github.io/vee-validate/concepts/bundle-size.html#minimal-bundle
 */
//
// Vue.use(
//     VeeValidate,
//     {
//         // Explicit validation of fields
//         inject: false,
//
//         // Delay in milli-second before fields are validated
//         delay: 100,
//
//         // When fields are validated. This can be changed on individual fields
//         // with the 'data-vv-validate-on' attribute.
//         //
//         // @see https://baianat.github.io/vee-validate/guide/events.html#changing-default-events
//         events: 'change|blur|input'
//     });

/**
 * Custom added rules for VeeValidate
 *
 * The VeeValidate package does not come with a lot of rules, but it has the flexibility
 * to easily add more when needed. Visit the list below for more info:
 *
 *      @see https://logaretm.github.io/vee-validate/guide/basics.html#adding-rules
 */
export const rules = {
    /**
     * Validation rule for checking a value that is inside an object item, in the form:
     *
     *      {
     *          text: string | number | object
     *          value: string | number | object
     *      }
     *      @see 'items' prop in v-select https://vuetifyjs.com/en/components/selects#selects
     *
     * The field under validation must have a non-empty (empty strings, undefined, null) value,
     * otherwise it will pass validation.
     */
    requiredItemValue: {
        message: 'This field is required.',
        validate: (selectItem: any) => {
            return {
                valid: selectItem?.value,
                required: true,
            };
        },
    },
    atLeastOneLowercase: {
        message: 'password must contain at least one lowercase character.',
        validate: (value: string) => /(?=.*[a-z])/.test(value),
    },
    atLeastOneUppercase: {
        message: 'password must contain at least one uppercase character.',
        validate: (value: string) => /(?=.*[A-Z])/.test(value),
    },
    atLeastOneNumber: {
        message: 'password must contain at least one number.',
        validate: (value: string) => /(?=.*[0-9])/.test(value),
    },
    atLeastOneSpecialChar: {
        message: 'password must contain at least one special character.',
        validate: (value: string) => /(?=.*[^a-zA-Z\d])/.test(value),
    },
};
