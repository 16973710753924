import ResourceService from '@/lib/api/ResourceService';
import { UserCollectionRepresentation, UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
import { ProductCollectionRepresentation } from '@/lib/api/representation/ProductRepresentation';
import { CacheOptions, create } from '@/lib/semanticNetworkMigrationUtils';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import { LinkUtil, Uri } from 'semantic-link';
import ResourceUtil from '@/lib/api/ResourceUtil';
import LinkRelation from '@/lib/api/LinkRelation';

import anylogger from 'anylogger';
import { OrganisationRepresentation } from '@/lib/api/representation/OrganisationRepresentation';
import { UserCreateDataRepresentation } from '@/lib/api/representation/user/UserCreateDataRepresentation';
import { ApiUtil, pooledCollectionMakeStrategy, pooledSingletonMakeStrategy } from '@/lib/semantic-network';
import ContentType from '@/lib/http/mimetype';

const log = anylogger('UserResource');
/**
 * User resource includes both surgeon and non-surgeon users.
 */
export default class UserResource implements ResourceService {
    /** Get a user */
    public static async getUser(user: UserRepresentation, options?: CacheOptions): Promise<UserRepresentation | null> {
        return await ApiUtil.get<UserRepresentation>(user, options) ?? null;
    }

    /**
     * Get the list of products available (registered/subscribed) to a user.
     */
    public static async getProducts(userResource: UserRepresentation, options?: CacheOptions):
        Promise<ProductCollectionRepresentation | null> {
        return await ApiUtil.get<ProductCollectionRepresentation>(
            userResource, { includeItems: false, ...options, rel: LinkRelation.products }) ?? null;
    }

    /**
     * Load a case (project) owner, using the top level 'users' collection as a cache.
     *
     * Note: this method will load the user from the top level sparse collection of all users so
     * that the user object will cached.
     */
    public static async getCaseOwner(
        apiResource: ApiRepresentation, caseResource: CaseRepresentation, options?: CacheOptions):
        Promise<UserRepresentation | null> {
        if (LinkUtil.matches(caseResource, LinkRelation.owner)) {
            const allUsers = ResourceUtil.makePooledCollection(
                apiResource, { ...options, rel: LinkRelation.users, includeItems: false });
            return await ApiUtil.get<UserRepresentation>(
                caseResource, {
                    ...options,
                    rel: LinkRelation.owner,
                    makeSparseStrategy: (o) => pooledSingletonMakeStrategy(allUsers, o),
                }) ?? null;
        }
        return null; // the case has no surgeon
    }

    /**
     * Load a case (project) surgeon, using the top level 'users' collection as a cache.
     *
     * Note: this method will load the user from the top level sparse collection of all users so
     * that the user object will cached.
     */
    public static async getCaseSurgeon(
        apiResource: ApiRepresentation,
        caseResource: CaseRepresentation,
        options?: CacheOptions): Promise<UserRepresentation | null> {
        if (LinkUtil.matches(caseResource, LinkRelation.surgeon)) {
            const allUsers = ResourceUtil.makePooledCollection(
                apiResource, { ...options, rel: LinkRelation.users, includeItems: false });
            return await ApiUtil.get<UserRepresentation>(
                caseResource, {
                    ...options,
                    rel: LinkRelation.surgeon,
                    makeSparseStrategy: (o) => pooledSingletonMakeStrategy(allUsers, o),
                }) ?? null;
        }
        return null; // the case has no surgeon
    }

    /**
     * The 'me user' resource is referenced using a simple non-user specific URL. This means that the
     * identity of the 'me user' can not be determined until the resource is fetched.
     *
     * This makes the loading of the 'me user' expensive in that it can not practically be cached. This
     * does however mean that the root of the API has no user dependent URLs and thus can be fetched
     * without any authentication.
     */
    public static async getMeUser(
        apiResource: ApiRepresentation, options?: CacheOptions): Promise<UserRepresentation | null> {
        return await ApiUtil.get<UserRepresentation>(
            apiResource, { rel: LinkRelation.meUser, ...options }) ?? null;
    }

    /**
     * Get the list of available surgeons for the user given a product type.
     *
     * This performs a search on the {@link LinkRelation.meUser} to get a list of surgeons (of type user)
     * that are available to the caller. Because the list of surgeons is in the context of the user it will
     * be held in memory of $api.
     */
    public static async getSurgeons(
        apiResource: ApiRepresentation,
        productUri: Uri,
        options?: CacheOptions): Promise<UserCollectionRepresentation | null> {
        const meUser = await UserResource.getMeUser(apiResource, options);
        if (meUser) {
            const allUsers = ResourceUtil.makePooledCollection(
                apiResource, { ...options, rel: LinkRelation.users, includeItems: false });
            if (allUsers) {
                return await create<UserCollectionRepresentation>(
                    productUri, {
                        includeItems: true,
                        ...options,
                        createContext: meUser,
                        rel: LinkRelation.userSurgeons,
                        name: productUri,
                        contentType: ContentType.UriList,
                        sparseType: 'collection' /* the 'search' result is a collection */,
                        makeSparseStrategy: (o) => pooledCollectionMakeStrategy(allUsers, o),
                    }) ?? null;
            }
        }
        throw new Error('Failed to get surgeons');
    }

    /**
     * Get the organization the user belongs to
     */
    public static async getOrganisation(userResource: UserRepresentation, options?: CacheOptions):
        Promise<OrganisationRepresentation | null> {
        return await ApiUtil.get<OrganisationRepresentation>(
            userResource, { rel: LinkRelation.organisation, ...options }) ?? null;
    }

    /**
     * Given a user representation, find the URI of the named product (or return undefined if the
     * named product is not available).
     *
     * e.g. getUserProductUri(user, 'hip')
     */
    public static async findUserProductByName(
        userResource: UserRepresentation,
        name: string,
        options?: CacheOptions): Promise<Uri | undefined> {
        // Get the list of products available to the user.
        const products = await ApiUtil.get<ProductCollectionRepresentation>(
            userResource, { rel: LinkRelation.products, ...options, includeItems: false });
        if (products) {
            return this.findProductByName(products, name);
        }
        return undefined;
    }

    private static findProductByName(products: ProductCollectionRepresentation, name: string): Uri | undefined {
        const product = products.items.filter(p => p.name === name);
        if (product.length === 1) {
            return LinkUtil.getUri(product[0].links, LinkRelation.canonicalOrSelf);
        }
        log.warn('product %s not found from %d products', name, products.items.length);
        return undefined;
    }

    /**
     * Create a user in the context of an organisation.
     *
     * Once the user is created a sparse resource is added to both:
     *   - the list of users in the organisation
     *   - the global top level list of users
     */
    public static async createOrganisationUser(
        organisation: OrganisationRepresentation,
        apiResource: ApiRepresentation,
        createData: UserCreateDataRepresentation,
        options?: CacheOptions): Promise<UserRepresentation | undefined> {
        // the top level list of all users is used as a pool.
        const allUsers = ResourceUtil.makePooledCollection(apiResource, { ...options, rel: LinkRelation.users });
        return await create<UserRepresentation>(
            createData,
            {
                ...options,
                createContext: organisation,
                rel: LinkRelation.users,
                createdRel: LinkRelation.users,
                contentType: ContentType.Json,
                makeSparseStrategy: (o) => pooledSingletonMakeStrategy(allUsers, o),
            });
    }
}
