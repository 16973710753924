
    // import {eventBus} from "semantic-link-utils/EventBus";
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import DragDropUtil from '../../lib/dragdrop/DragDropUtil';
    import ContentType from '@/lib/http/mimetype';

    import anylogger from 'anylogger';

    const log = anylogger('DragDrop');

    @Component
    export default class DragDrop extends Vue {
        /**
         * In-memory model that can be dragged out. This can either be a {@link LinkedRepresentation} or a
         * {@link Promise.<LinkedRepresentation>}
         */
        @Prop({ required: false })
        protected model?: any;

        /**
         * In-memory {@link LinkedRepresentation} that a dropped in the context of another {@link LinkedRepresentation}
         */
        private context: unknown = {};

        /**
         * Callback after the dropped model is loaded and then can be acted on (eg updated)
         *
         * Takes a callback function with the dropped document as the parameter
         */
        @Prop({ required: false, default: (doc: any) => doc })
        protected dropped!: (x: any, context: unknown) => any;

        /**
         * Pick the type of media to send from a drag event
         *
         * // TODO: is this really a content-type (cf accept)
         * @example 'application/json'
         * @example 'text/uri-list'
         * @example 'text/plain'
         * @example 'DownloadUrl'
         */
        @Prop({ required: false, default: '' })
        protected mediaType!: string;

        /**
         * Pick the type of media to return from a drop event
         *
         * @example 'application/json'
         * @example 'text/uri-list'
         * @example 'text/plain'
         * @example 'DownloadUrl'
         */
        @Prop({ required: false, default: '' })
        protected accept!: string;

        @Prop({ required: false, default: false })
        protected isAsync!: boolean;

        /**
         * An optional event bus to signal drag-n-drop events
         */
        @Prop({ required: false, default: this })
        protected eventBus!: Vue;

        /**
         * Whether draggable support is enabled. This is the value of the DOM attribute 'draggable'.
         */
        private draggable = 'true';

        protected mounted(): void {
            if (this.isAsync) {
                /**
                 * This was formerly
                 *
                 * ------------------------
                 * const self = this;
                 * ...
                 *     self.setDraggable();
                 * ------------------------
                 *
                 * It was unexplained/undocumented why the self assignment was necessary. Currently, isAsync is
                 * never true to this code is unreachable. However, if isAsync was to become true in the future,
                 * this current simplified implementation may cause issues. This comment is a record of what you
                 * may want to go back it.
                 */
                this.eventBus.$on('resource:ready', () => {
                    this.setDraggable();
                });
            } else {
                this.setDraggable();
            }
        }

        private setDraggable(): void {
            this.draggable = 'true';
            /*
                        let draggable = this.$refs.draggable;
                        if (draggable instanceof Element) {
                            draggable.setAttribute("draggable", "true");
                        } else {
                            log.warn("Expected draggable to be a DOM element");
                        }
            */
        }

        onDrop(event: DragEvent): boolean | undefined {
            log.debug('Drop');
            if (this.accept === undefined) {
                log.debug('No accept media type set using application/json');
            }

            return DragDropUtil.drop(
                event,
                document => this.dropped(
                    document,
                    this.context),
                this.accept || ContentType.Json);
        }

        onDragstart(event: DragEvent): void {
            log.debug('Drag start');
            if (this.mediaType === undefined) {
                log.debug('No mediaType set using application/json');
            }

            DragDropUtil.dragstart(event, this.model, [this.mediaType || ContentType.Json]);

            DragDropUtil.setSingleFileDragData(event);
        }

        onDragover(event: DragEvent): void {
            DragDropUtil.dragover(event);
        }

        onDragenter(event: DragEvent): void {
            DragDropUtil.dragenter(event);
        }

        onDragleave(event: DragEvent): void {
            DragDropUtil.dragleave(event);
        }

        onDragend(event: DragEvent): void {
            DragDropUtil.dragend(event);
        }
    }
