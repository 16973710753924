/**
 * The types of measurements
 */
export enum StudyMeasurementNames {
    // pelvis
    LegLengthDifference = 'hip_pelvis_leg_length_difference',
    AcetabularAnteversionAngleLeft = 'hip_pelvis_anteversion_left',
    AcetabularAnteversionAngleRight = 'hip_pelvis_anteversion_right',
    AcetabularAbductionAngleLeft = 'hip_pelvis_abduction_left',
    AcetabularAbductionAngleRight = 'hip_pelvis_abduction_right',
    CTPlane = 'hip_pelvis_ct_plane',
    AnteriorPelvicPlane = 'hip_pelvis_anterior_pelvic_plane',
    ISBPelvicPlane = 'hip_pelvis_isb_pelvic_plane',
    AcetabulumPlaneRight = 'hip_pelvis_acetabulum_plane_right',
    AcetabulumPlaneLeft = 'hip_pelvis_acetabulum_plane_left',

    //
    // CT Anteversion & Inclination currently are calculated on the hemi-pelvis.
    //
    HipHemiPelvisRadiographicAnteversionCT = 'hip_hemipelvis_radiographic_anteversion',
    HipHemiPelvisRadiographicInclinationCT = 'hip_hemipelvis_radiographic_inclination',

    //
    // APP Anteversion & Inclination currently are calculated on the pelvis.
    //
    HipAPPRadiographicAnteversionLeft = 'hip_pelvis_app_radiographic_anteversion_left',
    HipAPPRadiographicAnteversionRight = 'hip_pelvis_app_radiographic_anteversion_right',
    HipAPPRadiographicInclinationLeft = 'hip_pelvis_app_radiographic_inclination_left',
    HipAPPRadiographicInclinationRight = 'hip_pelvis_app_radiographic_inclination_right',

    // femur
    FemurAnteversionAngle = 'hip_femur_anteversion_angle',
    FemurShaftAxis = 'hip_femur_shaft_axis',
    FemurProximalShaftAxis = 'hip_femur_proximal_shaft_axis',
    FemurNeckAxis = 'hip_femur_neck_axis',
}
