
    import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

    @Component
    export default class AppRadioGroup extends Vue {
        @VModel({type: String}) selected!: string;
        @Prop(Array) readonly options!: { value: string, text: string; }[];
        @Prop(Boolean) readonly disabled?: boolean;
        @Prop(String) readonly error?: string;
    }
