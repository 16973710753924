import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import LinkRelation from '@/lib/api/LinkRelation';

import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import {
    HipCupCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipCupCatalogComponentRepresentation';
import {
    HipStemHeadCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemHeadCatalogComponentRepresentation';
import {
    HipComponentsCatalogRepresentation,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
import {
    HipStemCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
import {
    HipLinerCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipLinerCatalogComponentRepresentation';

export default class HipSurgicalTemplateResource extends SurgicalTemplateResource {
    /**
     * This will load the rel=stem and title='current' from the global collection of catalog components
     */
    public static getCurrentStem(
        surgicalTemplate: HipSurgicalTemplateRepresentation,
        componentsCatalog: HipComponentsCatalogRepresentation,
        options?: CacheOptions): HipStemCatalogComponentRepresentation | null {
        return SurgicalTemplateResource.getComponentByLinkSelector(
            surgicalTemplate,
            LinkRelation.hipCurrentStemComponent,
            componentsCatalog,
            LinkRelation.hipStemComponents,
            options);
    }

    /**
     * This will load the rel=cup and title='current' from the global collection of catalog components
     */
    public static getCurrentCup(
        surgicalTemplate: HipSurgicalTemplateRepresentation,
        componentsCatalog: HipComponentsCatalogRepresentation,
        options?: CacheOptions): HipCupCatalogComponentRepresentation | null {
        return SurgicalTemplateResource.getComponentByLinkSelector(
            surgicalTemplate,
            LinkRelation.hipCurrentCupComponent,
            componentsCatalog,
            LinkRelation.hipCupComponents,
            options);
    }

    /**
     * This will load the rel=head and title='current' from the global collection of catalog components
     */
    public static getCurrentStemHead(
        surgicalTemplate: HipSurgicalTemplateRepresentation,
        componentsCatalog: HipComponentsCatalogRepresentation,
        options?: CacheOptions): HipStemHeadCatalogComponentRepresentation | null {
        return SurgicalTemplateResource.getComponentByLinkSelector(
            surgicalTemplate,
            LinkRelation.hipCurrentStemHeadComponent,
            componentsCatalog,
            LinkRelation.hipStemHeadComponents,
            options);
    }

    /**
     * This will load the rel=head and title='current' from the global collection of catalog components
     */
    public static getCurrentCupLiner(
        surgicalTemplate: HipSurgicalTemplateRepresentation,
        componentsCatalog: HipComponentsCatalogRepresentation,
        options?: CacheOptions): HipLinerCatalogComponentRepresentation | null {
        return SurgicalTemplateResource.getComponentByLinkSelector(
            surgicalTemplate,
            LinkRelation.hipCurrentCupLinerComponent,
            componentsCatalog,
            LinkRelation.hipCupLinerComponents,
            options);
    }
}
