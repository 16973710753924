import { AcidObject3dBase, AcidObject3dType } from '@/lib/planning/objects-3D/AcidObject3d';
import { Matrix4 } from 'three';

export type GroupProperties = {
    name?: string,
    transform?: Matrix4,
    debugColor?: string,
}

export default class GroupObject extends AcidObject3dBase {
    constructor(properties: GroupProperties) {
        super({
            ...properties,
            type: AcidObject3dType.Group,
            matrixAutoUpdate: false,
        });
    }
}
