import { BufferGeometry } from 'three';
import { ModelRepresentation } from '@/lib/api/representation/ModelRepresentation';
import { ImplantSystemType } from '@/lib/api/representation/catalog/ImplantSystemRepresentation';
import { LandmarkRepresentation } from '@/lib/api/representation/base/landmark/LandmarkRepresentation';

/**
 * Catalogue Component Representation in a Catalog - Manufacturer form
 *
 * This class should be able to describe all catalog components
 */
export interface CatalogComponentRepresentation extends ModelRepresentation {
    /** name of the component */
    name: string;

    /**
     * The Implant 'system' is part of the system/type/size triplet used to identify and filter catalogue components.
     *
     * e.g. (e.g. comprehensive or tm)
     */
    system: ImplantSystemType;

    /**
     * The 'type' is part of the system/type/size triplet used to identify and filter catalogue components.
     */
    type: string;

    /**
     * The 'size' is part of the system/type/size triplet used to identify and filter catalogue components.
     *
     * e.g. the diameter of the component (or it might be a length, or just a t-shirt size).
     *   The value is in a string format '<number> mm', with 'mm' denoting millimeters.
     *    - Example value: '11 mm'
     */
    size: string;

    /** Landmarks associated with the component */
    landmarks?: LandmarkRepresentation[];

    /**
     * Materialised client side copy of the ply model.
     */
    model?: BufferGeometry;

    /**
     * Materialised client side copy of the 3d .ply model for a baseplate that is a region of the baseplate.
     *
     * The first (and only) region so far is the collision mask on the back of the baseplate used
     * to determine where the baseplate will touch/intersect with the bone.
     *
     * NOTE: Currently there is no specific meta-data associated with the collisionMask and in order to place
     *       the object in the correct 3d position/rotation we use the already in scene rendered component's
     *       (eg. baseplate) .worldMatrix.
     */
    collisionMask?: BufferGeometry;

    atlas_?: {
        landmarks?: LandmarkRepresentation[];
    }
}

/**
 * Guard to determinate if x is a {@link CatalogComponentRepresentation}
 */
export function isComponentFromCatalog(x: unknown): x is CatalogComponentRepresentation {
    return !!x && typeof x === 'object' && 'type' in x && 'size' in x && 'system' in x;
}
