import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';

export interface CaseStateStepperData {
    /**
     * The main case (project) representation. The following fields are also
     * likely to be valid (based on the state of the case):
     *    - The active study {@link CaseRepresentation.study}
     *    - {@link CaseRepresentation.acidSurgicalTemplate}
     *    - The user surgical template {@link CaseRepresentation.surgicalTemplate}
     */
    caseRepresentation: CaseRepresentation | null;
}

/**
 * A POCO implementation of {@link CaseStateStepperData}.
 */
export class CaseStateStepperDataImpl implements CaseStateStepperData {
    constructor(public caseRepresentation: CaseRepresentation | null) {
    }
}
