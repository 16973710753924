/**
 * Atlas measurement group names for hip/shoulder
 */
export enum MeasurementGroupNames {
    //
    //  Hip study measurement groups
    //
    HipFemurLeft = 'left-femur',
    HipFemurRight = 'right-femur',
    HipHemiLeft = 'left-hemi',
    HipHemiRight = 'right-hemi',
    HipPelvis = 'pelvis',

    //
    // Latest version of surgical template measurements
    //

    /** a measurement group to hold a collection of femeral group items */
    HipFemoralGroupsName = 'femoral-groups',
    /**  a measurement group to hold stem/head measurements */
    HipFemoralGroupName = 'femoral-group',

    /** a measurement group to hold cup/liner measurements */
    HipAcetabularGroupName = 'acetabular-group',
    /** a measurement group to hold a collection of acetabular group items */
    HipAcetabularGroupsName = 'acetabular-groups',

    //
    //  Surgical template measurement groups
    //
    /** The surgical template 'stem' measurements */
    HipStem = 'stem',
    /** The surgical template 'head' measurements */
    HipHead = 'head',
    /** The surgical template 'stem' measurements */
    HipCup = 'cup',
    /** The surgical template 'stem' measurements */
    HipLiner = 'liner',
}

export type MeasurementGroupName =
    MeasurementGroupNames.HipFemurLeft |
    MeasurementGroupNames.HipFemurRight |
    MeasurementGroupNames.HipHemiLeft |
    MeasurementGroupNames.HipHemiRight |
    MeasurementGroupNames.HipPelvis |

    //
    //  Surgical template measurement groups
    //
    MeasurementGroupNames.HipFemoralGroupsName |
    MeasurementGroupNames.HipFemoralGroupName |
    MeasurementGroupNames.HipStem |
    MeasurementGroupNames.HipHead |

    MeasurementGroupNames.HipAcetabularGroupsName |
    MeasurementGroupNames.HipAcetabularGroupName |
    MeasurementGroupNames.HipCup |
    MeasurementGroupNames.HipLiner;
