import anylogger, { BaseLevels, BaseLogger } from 'anylogger';

const LEVELS = ['error', 'warn', 'info', 'log', 'debug', 'trace'];

/**
 * Create a tagged logger that acts like a regular logger except it prefixes a 'tag' string value to the message.
 */
export function taggedLogger(
    tag: string,
    nameOrLogger?: string | BaseLogger): BaseLogger {

    const prefix = `[${tag}]`;
    const base = (nameOrLogger === undefined || typeof nameOrLogger === 'string') ?
        anylogger(nameOrLogger ?? tag) :
        nameOrLogger;

    function prependTag(message?: unknown): string {
        return message ? `${prefix} ${message}` : prefix;
    }

    /**
     * Function-call signature for the logger.
     *
     * NOTE: This function is overcomplicated because the 'BaseLogger' interface is overcomplicated.
     * If we wanted to ignore the 'pass the level as the first argument' call overload
     * (i.e. '(level: keyof L, message?: any, ...args: any[]): void')
     * it could be very simple.
     */
    const logger = (arg0?: unknown, arg1?: unknown, ...args: unknown[]): void => {
        if (typeof arg0 === 'string' && LEVELS.includes(arg0)) {
            base(arg0, prependTag(arg1), ...args);
        } else {
            base(arg0, arg1, ...args);
        }
    };

    return Object.assign(logger, {
        error(message?: unknown, ...args: unknown[]): void {
            base.error(prependTag(message), ...args);
        },
        warn(message?: unknown, ...args: unknown[]): void {
            base.warn(prependTag(message), ...args);
        },
        info(message?: unknown, ...args: unknown[]): void {
            base.info(prependTag(message), ...args);
        },
        log(message?: unknown, ...args: unknown[]): void {
            base.log(prependTag(message), ...args);
        },
        debug(message?: unknown, ...args: unknown[]): void {
            base.debug(prependTag(message), ...args);
        },
        trace(message?: unknown, ...args: unknown[]): void {
            base.trace(prependTag(message), ...args);
        },
        enabledFor(level: keyof BaseLevels): boolean {
            return base.enabledFor(level);
        },
    }) as BaseLogger;
}
