
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DateTime, Duration } from 'luxon';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';

    enum Events {
        Expired = 'expired',
        KeepMeLogged = 'keep-me-logged',
    }

    /**
     *
     * A dialog presented to the user with a countdown of 5 minutes to acknowledge the user
     * is actively using the application. Otherwise, after the 5 minutes it logs the user off.
     *
     * Usage:
     * - The visibility of this component is controlled from the outside.
     * - The components starts a timer and present to the user the information that is about to be logged out.
     * If that timer expires a 'logout' event is fired.
     * - To restart the timer the component has to be unmounted (given the timer is started on the 'mounted' event)
     */
    @Component({ components: {} })
    export default class InactivityDialog extends Vue {
        @Prop({ required: true, validator: notNilValidator('timeout') })
        private timeout!: number;

        private timePassedInMilliseconds? = 0;

        // This variable is only declared as an instance variable to property clean it up
        // when the component is destroyed.
        private updateTimeInterval?: number;

        private start!: DateTime;

        private expired = false;

        protected created(): void {
            this.start = DateTime.now();

            // Update the time passed every second.
            // Note that intervals are not a reliable source of time, so the relevant times are updated at a faster
            // rate that the desired 1 second countdown.
            // Most of the time this should run fast enough. Most of the time it does not, it will be
            // because the browser tab lost priority (user is idle), so in this case does not matter.
            // As soon as the user jumps back it will self corrected.
            this.updateTimeInterval = window.setInterval(this.updateTimePassed.bind(this), 500);
        }

        protected beforeDestroy(): void {
            clearInterval(this.updateTimeInterval);
        }

        private updateTimePassed(): void {
            this.timePassedInMilliseconds = DateTime.now().diff(this.start).toObject().milliseconds;
        }

        @Watch('timePassedInMilliseconds')
        private onTimePassedChange(): void {
            if (this.timePassedInMilliseconds === undefined) {
                throw new Error('expected timePassedInMilliseconds to defined');
            }

            const timeExpired = this.timePassedInMilliseconds > this.timeout;
            if (timeExpired) {
                this.onTimeExpired();
            }
        }

        private onTimeExpired(): void {
            this.expired = true;
            clearInterval(this.updateTimeInterval);
            this.$emit(Events.Expired);
        }

        protected onUserActivityClick(): void {
            clearInterval(this.updateTimeInterval);
            this.$emit(Events.KeepMeLogged);
        }

        /** A computed property to display the countdown time left */
        protected get countdown(): string {
            return Duration.fromMillis(this.timeout - (this.timePassedInMilliseconds ?? 0)).toFormat('mm:ss');
        }
    }
