
    import { Component, ProvideReactive, Vue } from 'vue-property-decorator';
    import NewCase from '@/components/case/NewCase.vue';
    import TitleUtil from '@/components/TitleUtil';
    import CaseListTitle from '@/components/title/CaseListTitle.vue';
    import {
        CaseCollectionRepresentation,
        CaseRepresentation,
    } from '@/lib/api/representation/case/CaseRepresentation';
    import CaseResource from '@/lib/api/resource/case/CaseResource';

    import anylogger from 'anylogger';
    import { AuthenticatorEvent } from '@/lib/http/AuthenticatorService';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import ApiResource from '@/lib/api/resource/ApiResource';
    import HomeProjectList from '@/views/home/HomeProjectList.vue';
    import HomeNoData from '@/views/home/HomeNoData.vue';
    import HomeSearchInput from '@/views/home/HomeSearchInput.vue';
    import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
    import RouteParseMixin from '@/components/shared/RouteParseMixin';
    import { SearchUtil } from '@/views/home/SearchUtil';
    import AnalyticsUtil from '@/lib/analytics/AnalyticsUtil';
    import { HomeUtil } from '@/views/home/HomeUtil';
    import HomeSearchResult from '@/views/home/HomeSearchResult.vue';
    import SingleSearchProjectService, { SearchTaskState } from '@/views/home/SingleSearchProjectService';
    import HomeWelcome from '@/views/home/HomeWelcome.vue';
    import Bugsnag from '@bugsnag/js';

    const log = anylogger('Home');

    /**
     * The estimated height in pixels of the toolbar
     * @see `AppBar.vue`
     */
    const TOOLBAR_HEIGHT = 80;

    /**
     * This is the home page with the dashboard view of cases for a user.
     *
     * Note: By using `<v-virtual-scroll>` component we displays a virtual, infinite list using
     * a lazy approach that helps to:
     *   1) Not mount components that are not visible in the screen.
     *      In users will thousand of cases, it will mean less watchers, less memory used, etc,
     *      leading to a better performance.
     *   2) Not rendering DOM elements until they are needed: Same as 1) but at a browser level.
     *   The less the browser spend building the virtual dom and painting it, the early the javascript
     *   code will execute, leading to a better UX.
     */
    @Component({
        components: {
            HomeSearchResult,
            NewCase,
            HomeNoData,
            HomeProjectList,
            HomeSearchInput,
            HomeWelcome,
        },
        mixins: [RouteParseMixin],
    })
    export default class Home extends Vue {
        public declare $refs: Vue['$refs'] & { home: Vue };

        /** The raw collection of cases/projects. */
        protected projects: CaseCollectionRepresentation | null = null;

        protected isLoading = false;

        /** The text search model bounded to the search box */
        @ProvideReactive()
        private searchText: string = SearchUtil.DEFAULT_VALUE;

        /**
         * Default home container height in pixels.
         * Note: The main purpose of this variable is to have a property to mutate on the `v-resize` call
         */
        private homeHeight = 800;
        private searchService = new SingleSearchProjectService(this.$api, this.$apiOptions);

        /** not used for now */
        private error = false;

        protected created(): void {
            log.info('HOME CREATED');
            this.$authEvent.$on(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
        }

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            log.info('HOME MOUNTED');
            log.debug('Creating dashboard/home');
            TitleUtil.$emit(this, { titleComponentType: CaseListTitle, notificationComponentType: '' });

            await ApiResource.getApi(this.$api, this.$apiOptions);

            this.projects = await this.getProjects();
        }

        /**
         *  Load projects from the api
         *  Note: by default **forceLoad option to true** to retrieve a fresh list of projects.
         */
        private async getProjects(options?: CacheOptions): Promise<CaseCollectionRepresentation | null> {
            return await CaseResource.getCaseList(
                this.$api, { ...this.$apiOptions, ...options });
        }

        protected beforeDestroy(): void {
            log.info('HOME DESTROYED');
            this.$authEvent.$off(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
        }

        /**
         * The height of the client container.
         *
         * Note: Setting this value roughly accurately, helps to not have a double vertical scroll bar,
         * given the `<v-virtual-scroll>` will have an internal scrollbar.
         */
        protected get homeContainerHeight(): number {
            return this.homeHeight;
        }

        /**
         * The project item height based on the viewport information.
         *
         * Note: the `<v-virtual-scroll>` does not support dynamic content at this stage.
         * @see https://vuetifyjs.com/en/features/breakpoints/#breakpoint-service
         */
        protected get itemHeight(): number {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                case 'sm':
                case 'md':
                    return 160;

                case 'lg':
                case 'xl':
                    return 130;

                default:
                    return 130;
            }
        }

        private async onAuthRevoke(): Promise<void> {
            this.projects = null;
        }

        /**
         * On resize sets the new home container height.
         *
         * Setting this value roughly accurately, helps to not have a double vertical scroll bar,
         * given the `<v-virtual-scroll>` will have already an internal scrollbar.
         *
         * Using the `$refs.home.$el.clientHeight` does not seem to give as the expected height when resizing,
         * so using the `window.innerHeight` for now.
         */
        private onResize(): void {
            const clientHeight = this.$refs.home.$el.clientHeight;
            const windowInnerHeight = window.innerHeight;
            const sizeWithoutHeight = windowInnerHeight - TOOLBAR_HEIGHT;

            // e.g: if height is 400px, and only fit 2 cards of 150px, we remove the extra 100px,
            // so no extra partial card is shown.
            const extraHeightToDelete = sizeWithoutHeight % this.itemHeight;
            this.homeHeight = sizeWithoutHeight - extraHeightToDelete;

            log.info(
                'clientHeight: %d, window.innerHeight: %d, window: %s, toolbar: %s, extra: %s',
                clientHeight,
                windowInnerHeight,
                this.homeHeight,
                TOOLBAR_HEIGHT,
                extraHeightToDelete);
        }

        private get projectItems(): CaseRepresentation[] {
            return this.projects?.items || [];
        }

        /**
         * Note
         * -----
         * Do not use @IsLoading('isSearching') for this particular scenario. The function is not re-entrant,
         * and previous search will mutate the isSearching flag unexpectedly.
         */
        private async doSearch(searchText: null | string): Promise<void> {
            try {
                this.searchText = searchText ?? SearchUtil.DEFAULT_VALUE;

                const searchTask = await this.searchService.search(this.searchText);
                const projects = searchTask.getResult();

                // Only assign results of task that were completed
                if (searchTask.state === SearchTaskState.Completed && projects) {
                    this.projects = projects;
                }

                // Only track in GA completed or error requests
                // (interim aborted searches because the user is still typing are not logged)
                if (searchTask.state === SearchTaskState.Completed || searchTask.state === SearchTaskState.Error) {
                    AnalyticsUtil.event(
                        this.$gtag,
                        AnalyticsUtil.ScreenName.Home,
                        AnalyticsUtil.Event.HomeSearch,
                        HomeUtil.makeSearchAnalyticsParams(this.searchText, projects));
                }
            } catch (err: unknown) {
                if (err instanceof Error) {
                    log.error('Unexpected error searching cases: %s', err.message);
                    Bugsnag.notify(err);
                } else {
                    log.error('Unexpected error searching cases: %s', err);
                }
                this.error = true;
            }
        }

        private get isSearchEmpty(): boolean {
            return SearchUtil.isSearchEmpty(this.searchText);
        }

        protected get isSearching(): boolean {
            return this.searchService.isSearching;
        }
    }
