
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { LinkUtil } from 'semantic-link';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';
    import {
        SurgicalSpecificationRepresentation,
    } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
    import { ProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';

    /**
     * This is a case component **DEBUG** component that shows the admin the state of study
     */
    @Component({ components: { HyperlinkButton } })
    export default class SurgicalSpecification extends Vue {
        @Prop({ required: true })
        value!: SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>;

        /** The URI of the active 'user' surgical template surgical specification */
        @Prop({ required: true })
        public userSurgicalSpecificationUri!: string | null;

        protected isLoading = false;

        protected get isUserSurgicalTemplate(): boolean {
            // get the non-canonical 'self' link
            const selfUri = LinkUtil.getUri(this.value, LinkRelation.self);
            return !!selfUri && selfUri === this.userSurgicalSpecificationUri;
        }

        protected get isAcidSurgicalTemplate(): boolean {
            // get the non-canonical 'self' link
            const selfUri = LinkUtil.getUri(this.value, LinkRelation.self);
            return !!selfUri && selfUri === this.userSurgicalSpecificationUri;
        }
    }
