import { render, staticRenderFns } from "./TopBanner.vue?vue&type=template&id=33c406f0&scoped=true&"
import script from "./TopBanner.vue?vue&type=script&setup=true&lang=ts&"
export * from "./TopBanner.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./TopBanner.vue?vue&type=style&index=0&id=33c406f0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c406f0",
  null
  
)

export default component.exports