import {
    AngleMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/AngleMeasurementValueRepresentation';
import MeasurementsUtil from '@/lib/api/resource/case/study/MeasurementsUtil';
import { MeasurementGroupNames } from '@/lib/api/representation/case/measurements/MeasurementGroupName';
import { NumberUtil } from '@/lib/base/NumberUtil';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';

export default class HipSurgicalTemplateMeasurementsUtil {
    /**
     * The combined version measurement
     *
     * Note:
     * 1. The stem anteversion being used here is a stem measurement (from the stem fitted - components data)
     * 1. The cup anteversion being used here is a cup measurement (from the cup fitted - components data).
     *
     * Formula:
     * -------
     * Widmer and Zurfluh (2004) proposed the following formula, suggesting that cup anteversion has a higher impact
     * on combined version.
     *
     * Cup anteversion + 0.7 x Stem Anteversion = 37.3° (Target combined version)
     *
     * Target
     * ------
     * Should be targeted for 37.3 degrees to minimise impingement in the hip
     *
     * @see {@link https://doi.org/10.1016/j.orthres.2003.11.001}
     */
    public static getCombinedVersion(
        stemVersion: AngleMeasurementValueRepresentation,
        cupVersion: AngleMeasurementValueRepresentation): number | null {
        const scaledStemVersion = NumberUtil.isFiniteNumber(stemVersion.value) ? 0.7 * stemVersion.value : null;
        return NumberUtil.sumIfFinite(scaledStemVersion, cupVersion.value);
    }

    /**
     * Get leg length target measured in millimetres (mm)
     *
     * **Formula**: Femoral offset + Acetabular offset
     * 1. **Femoral offset**: The distance from the native femoral head centre to the new head centre in the
     * inferior-to superior direction. This value is store server side on {@property HipStemRepresentation.ll_diff}
     *
     *  @see https://docs.google.com/presentation/d/11Pmya0pl4vhe672Kf28tnwJaMc5TM7qj/edit#slide=id.p72
     */
    public static getCombinedLegLengthDifference(
        femoralLegLengthDifference: AngleMeasurementValueRepresentation,
        acetabularLegLengthDifference: AngleMeasurementValueRepresentation): number | null {
        return NumberUtil.sumIfFinite(femoralLegLengthDifference.value, acetabularLegLengthDifference.value);
    }

    /**
     * Get the combined (a.k.a functional) offset measured in millimetres (mm)
     *
     * **Formula**: Femoral offset difference + Acetabular offset difference
     * 1. **Femoral offset**: the distance from the native head centre to the femoral head centre in the medial
     * direction. This value is store server side on {@property HipStemRepresentation.offset_diff}.
     *
     * How adjustment affect this value
     * --------------------------------
     * Adjustments that increase the leg offset:
     *   - If the femoral head is moved laterally (opposite to the sagittal plane).
     *   - If the acetabular cup is moved laterally (opposite to the sagittal plane).
     *
     * Adjustments that decrease the leg offset:
     *   - If the femoral head is moved laterally (towards the sagittal plane).
     *   - If the acetabular cup is moved laterally (towards the sagittal plane).
     *
     * @see https://docs.google.com/presentation/d/11Pmya0pl4vhe672Kf28tnwJaMc5TM7qj/edit#slide=id.p73
     */
    public static getCombinedLegOffsetDifference(
        femoralLegOffsetDifference: AngleMeasurementValueRepresentation,
        acetabularLegOffsetDifference: AngleMeasurementValueRepresentation): number | null {
        return NumberUtil.sumIfFinite(femoralLegOffsetDifference.value, acetabularLegOffsetDifference.value);
    }

    /**
     * @returns whether the measurements correspond to **new** format. Otherwise is considered **legacy**
     *
     * Note: The new format of hip measurements include 'femoral-groups' or 'acetabular-groups' in the root level.
     */
    public static isNewFormat(measurements: MeasurementsRepresentation): boolean {
        const femoralGroups = MeasurementsUtil.getGroupByName(
            measurements, MeasurementGroupNames.HipFemoralGroupsName);
        const acetabularGroups = MeasurementsUtil.getGroupByName(
            measurements, MeasurementGroupNames.HipFemoralGroupsName);

        return femoralGroups !== null || acetabularGroups !== null;
    }
}
