
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import CodeDiff from 'vue-code-diff';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import {
        SurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { isHipSurgicalTemplate } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateUtil';
    import { LinkUtil } from 'semantic-link';
    import ObjectUtil from '@/lib/base/ObjectUtils';
    import StringUtil from '@/lib/base/StringUtils';

    /**
     * A component that given a surgical template renders the difference between itself and its previous
     *
     */
    @Component({ components: { CodeDiff } })
    export default class SurgicalTemplateJSONDiff extends Vue {
        /** whether the main v-card/panel is loading data */
        private isLoading = false;

        /** the current surgical template */
        @Prop({ required: true, validator: notNilValidator('current') })
        private current!: SurgicalTemplateRepresentation;

        @Prop({ required: true, validator: notNilValidator('previous') })
        /** the previous surgical template */
        private previous!: SurgicalTemplateRepresentation;

        /** Whether it shows only the fields that are different or a shows more detailed view. **/
        private showDiffDetail = false;

        /** If it shows a side by side, or a line by line view */
        private sideBySideMode = false;

        /**
         * Returns the comparable data into a pretty json string. This is done to populate the <vue-code-diff> component
         * which expects strings.
         */
        protected get currentDetailToString(): string | null {
            return StringUtil.jsonPrettyFormat(this.currentComparableData);
        }

        /**
         * Returns the comparable data into a pretty json string. This is done to populate the <vue-code-diff> component
         * which expects strings.
         */
        protected get previousDetailToString(): string | null {
            return StringUtil.jsonPrettyFormat(this.previousComparableData);
        }

        /**
         * Returns **only** the fields that are different on the current record (from the comparable set of properties)
         *
         * Note: Returns a pretty json string. This is done to populate the <vue-code-diff> component
         * which expects strings.
         */
        protected get currentDiffToString(): string | null {
            const currentChanges = ObjectUtil.difference(this.currentComparableData, this.previousComparableData);
            return StringUtil.jsonPrettyFormat(currentChanges);
        }

        /**
         * Returns **only** the fields that are different on the previous record (from the comparable set of properties)
         *
         * Note: Returns a pretty json string. This is done to populate the <vue-code-diff> component
         * which expects strings.
         */
        protected get previousDiffToString(): string | null {
            const previousChanges = ObjectUtil.difference(this.previousComparableData, this.currentComparableData);
            return StringUtil.jsonPrettyFormat(previousChanges);
        }

        /**
         * Get the data that is of interested to compare.
         *
         * Specifically exclude most links, given the data is of interest to compare is the user input data:
         * e.g: components (stem, cup, etc), cup_offset, cup_rotation, etc.
         */
        private getDataToCompare<T extends SurgicalTemplateRepresentation>(
            surgicalTemplate: T | null): Record<string, unknown> | null {
            if (surgicalTemplate) {
                if (isHipSurgicalTemplate(surgicalTemplate)) {
                    return {
                        id: LinkUtil.getUri(surgicalTemplate, LinkRelation.self),
                        hasPlan: LinkUtil.getUri(surgicalTemplate, LinkRelation.plan) !== undefined,
                        components: LinkUtil.getUri(surgicalTemplate, LinkRelation.componentSet),
                        //
                        //
                        // Stem assembly attributes
                        //
                        //
                        stem: LinkUtil.getUri(surgicalTemplate, LinkRelation.hipStemComponent),
                        head: LinkUtil.getUri(surgicalTemplate, LinkRelation.hipStemHeadComponent),

                        //
                        //
                        // Cup assembly attributes
                        //
                        //
                        cup: LinkUtil.getUri(surgicalTemplate, LinkRelation.hipCupLinerComponent),
                        liner: LinkUtil.getUri(surgicalTemplate, LinkRelation.hipCupLinerComponent),
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        cup_rotation: surgicalTemplate.cup_rotation,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        cup_offset: surgicalTemplate.cup_offset,

                        //
                        //
                        // General attributes
                        //
                        //
                        state: surgicalTemplate.state,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        record_state: surgicalTemplate.record_state,
                        created: surgicalTemplate.created,
                        // updated: surgicalTemplate.updated,
                    };
                }
            }

            return null;
        }

        /**
         * @returns the output format mode for the <vue-code-diff> component
         */
        private get outputFormat(): string {
            return this.sideBySideMode ? 'side-by-side' : 'line-by-line';
        }

        private get currentComparableData(): Record<string, unknown> {
            return this.getDataToCompare(this.current) || {};
        }

        private get previousComparableData(): Record<string, unknown> {
            return this.getDataToCompare(this.previous) || {};
        }
    }
