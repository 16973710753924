import { CollectionRepresentation, LinkedRepresentation } from 'semantic-link';
import { CatStackCollectionAndBBoxRepresentation } from '@/lib/api/representation/CatStackRepresentation';
import { DicomFileMessages, SeriesMessageRepresentation } from '@/lib/api/representation/SeriesRepresentation';
import {
    SegmentedModelCollectionRepresentation,
} from '@/lib/api/representation/case/study/SegmentedModelRepresentation';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import {
    HipAnatomicalLandmarksRepresentation,
} from '@/lib/api/representation/case/study/landmarks/StudyLandmarksRepresentation';
import { FittingRepresentation } from '@/lib/api/representation/case/study/FittingRepresentation';

export enum StudyState {
    /**
     *  The resource is new.
     */
    New = 'https://schema.formuslabs.com/state/study/new',
    /**
     *  The study is in a state that DICOM files (and messages) can be posted to the study and they will be accepted.
     */
    Uploading = 'https://schema.formuslabs.com/state/study/uploading',
    /**
     *  The study is in an immutable state (from the API perspective), but is fully mutable from the
     *  server backend processing perspective.
     */
    Processing = 'https://schema.formuslabs.com/state/study/processing',
    /**
     * The study is in an immutable state (from the API perspective), and a valid cat stack has
     * been produced (and is available to show the user).
     * When in this state the 'segmentation' and other model creation steps will be performed.
     */
    CatStack = 'https://schema.formuslabs.com/state/study/catstack',
    /**
     * The catstack has been produced and the GPU has started to perform
     */
    SegmentationProcessing = 'https://schema.formuslabs.com/state/study/seg-processing',
    /**
     *  The study has been segmented and the 3d models may be available.
     */
    Segmented = 'https://schema.formuslabs.com/state/study/segmented',
    /**
     * The study has been segmented and the (non-GPU) CPU based processing has commenced.
     */
    PostProcessing = 'https://schema.formuslabs.com/state/study/post-processing',
    Completed = 'https://schema.formuslabs.com/state/study/completed',
    Error = 'https://schema.formuslabs.com/state/study/error',
}

/**
 * A study is a resource that is created in the context of a case. It is the primary resource
 * that represents a collection of DICOM files.
 *
 * @see {@link SeriesCreateDataRepresentation}
 */
export interface StudyRepresentation extends LinkedRepresentation {
    name: string;

    /**
     * A URI representing the state of the study.
     *
     * Expected values:
     *   https://schema.formuslabs.com/state/study/uploading
     *   https://schema.formuslabs.com/state/study/processing
     *   https://schema.formuslabs.com/state/study/completed
     *   https://schema.formuslabs.com/state/study/error
     */
    state: string;

    /**
     * When the study was created.
     *
     * This is an ISO-9660 datetime string with better than second precision.
     */
    created?: string;

    /**
     * When the study was last modified.
     *
     * This is an ISO-9660 datetime string with better than second precision.
     */
    updated?: string;

    /** A unique DICOM identifier for the series */
    series?: string;

    /** The expected number of files to be uploaded as part of the DICOM study */
    file_count?: number;

    /**
     * An optional set of messages that were generated during the client parsing and organising
     * of the DICOM files.
     *
     * IMPORTANT: the messages related to the individual DICOM files are stored with the DICOM file resources.
     */
    messages?: SeriesMessageRepresentation[];

    /**
     * The messages for file that were excluded from upload because they were deemed to not be required
     * to perform the pre-surgical planning. It is expected that additional files will come from the PACS
     * system.
     */
    excluded_files?: DicomFileMessages[];

    /**
     * client-side proxy artifact
     */
    catStacks?: CatStackCollectionAndBBoxRepresentation;

    /** client-side models collection. This is the collection of all models */
    models?: SegmentedModelCollectionRepresentation;

    /**
     * client-side measurements
     */
    measurements?: MeasurementsRepresentation;

    /** client-side models collection. This is the collection of models where the link relation has the title 'bones' */
    boneModels?: SegmentedModelCollectionRepresentation;

    /** client-side models collection. This is the collection of models where the link relation has the title 'metal' */
    metalModels?: SegmentedModelCollectionRepresentation;

    /**
     * Anatomical landmarks
     */
    landmarks?: HipAnatomicalLandmarksRepresentation | null;

    /** optional segmented model (bone) fit results */
    fitting?: FittingRepresentation | null;
}

export type StudyCollectionRepresentation = CollectionRepresentation<StudyRepresentation>;
