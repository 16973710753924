
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import BlankTitle from '@/components/title/BlankTitle.vue';
    import TitleUtil from '@/components/TitleUtil';

    import anylogger from 'anylogger';
    import AnalyticsUtil from '@/lib/analytics/AnalyticsUtil';

    const log = anylogger('Privacy');

    @Component({ components: { BlankTitle } })
    export default class Privacy extends Vue {
        protected async mounted(): Promise<void> {
            log.info('Creating privacy');
            TitleUtil.$emit(this, { titleComponentType: BlankTitle, notificationComponentType: '' });

            AnalyticsUtil.screenView(this.$gtag, AnalyticsUtil.ScreenName.Privacy);
        }
    }
