import { UploadData } from '@/components/case-settings/scan/workflow/ScanUploadService';

export class DicomUploadTask {
    /**
     //      * A percentage (0-100 inclusive) of the upload that has been started. This
     //      * is likely to be greater than or equal to the completed value.
     //      */
    /** the number of operations (from total) that have been started. */
    private started = 0;

    /** the number of operations (from total) that have been completed) */
    private completed = 0;

    private abortController = new AbortController();

    constructor(public readonly data: UploadData) {
    }

    public getUploadData(): UploadData {
        return this.data;
    }

    /** Increment the number of operation that have been started and provide the number
     * of operations to the dialog as a percentage. */
    public incrementStarted(): void {
        ++this.started;
    }

    /** Increment the number of operation that have been completed and provide the number
     * of operations to the dialog as a percentage. */
    public incrementCompleted(): void {
        ++this.completed;
    }

    public get total(): number {
        return this.data.value.items.length;
    }

    /**
     * A percentage (0-100 inclusive) of the upload that has been started. This
     * is likely to be greater than or equal to the completed value.
     */
    public get startedPercentage(): number {
        return this.completed * 100 / this.total;
    }

    /** A percentage (0-100 inclusive) of the upload that has been completed. */
    public get completedPercentage(): number {
        return this.started * 100 / this.total;
    }

    public cancel(): void {
        return this.abortController.abort();
    }

    public getAbortSignal(): AbortSignal {
        return this.abortController.signal;
    }
}
