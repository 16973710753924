
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { CatStackRepresentation, ImageSize } from '@/lib/api/representation/CatStackRepresentation';
    import { NumberUtil } from '@/lib/base/NumberUtil';
    import { CatStackImageUtil, DrawCanvasInfo } from '@/lib/catstack/CatStackImageUtil';

    import anylogger from 'anylogger';
    import { CatStackValue } from '@/components/case-dicom/types';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';

    const DEFAULT_WIDTH = 512;
    const DEFAULT_HEIGHT = 512;

    export const enum IndicatorLine {
        Horizontal = 'horizontal',
        Vertical = 'vertical',
        None = 'none',
    }

    const log = anylogger('CatStack');
    /**
     * A component to show a catstack jpeg as a sprite using HTML 5
     *
     * @see {@link http://www.williammalone.com/articles/create-html5-canvas-javascript-sprite-animation/}
     */
    @Component
    export default class CatStackImage extends Vue {
        /**
         * This is the sparsely populated case/project that will populated in the
         * created method (below). The property must be provided by the parent.
         */
        @Prop({ required: true, validator: notNilValidator('value') })
        public value!: CatStackValue;

        /** The parent container height */
        @Prop({ required: true, default: DEFAULT_HEIGHT })
        public containerHeight!: number;

        /** The parent container width */
        @Prop({ required: true, default: DEFAULT_WIDTH })
        public containerWidth!: number;

        /**
         * This along with the {@link indicatorOffset} allows a red indicator line to be drawn
         * either horizontally or vertically over the top of the catstack image to indicate the
         * position/slice of another view.
         */
        @Prop({ required: false, default: 'none' })
        public indicatorLine!: IndicatorLine;

        /**
         * The offset of the indicator line in the image (iff {@link indicatorLine} is
         * not 'none').
         */
        @Prop({ required: false, default: 0 })
        public indicatorOffset!: number;

        /**
         * Padding in pixels to apply in all directions (top, right, bottom and left)
         */
        @Prop({ required: false, default: 0 })
        protected padding!: number;

        private canvasContext: CanvasRenderingContext2D | null = null;

        public declare $refs: Vue['$refs'] & { canvasRef: HTMLCanvasElement };

        protected mounted(): void {
            this.canvasContext = (this.$refs.canvasRef).getContext('2d');

            this.renderSlice();

            // on container width / height re-render the slice
            this.$watch(() => this.containerWidth, this.renderSlice);
            this.$watch(() => this.containerHeight, this.renderSlice);
            // If the slice index changes, render that slice.

            // If the indicator position changes, redraw. This is because the slider is
            // manipulated on the three different slices.
            this.$watch(() => this.indicatorOffset, this.renderSlice);

            // Watch for the current slices pages.
            this.$watch(() => this.currentSliceIndex, this.renderSlice);
        }

        protected beforeDestroy(): void {
            // free the canvas context
            this.canvasContext = null;
        }

        /**
         * @returns the scale ratio needed to fit an image in the parent container.
         * - If the image is smaller thant the container, the scale ratio will be bigger than 1.
         * - If the image is bigger than the container, the scale ratio is will be between 0 and 1.
         * The ratio is chosen based on the smallest of the limiting factors (width, height or both).
         * E.g: if container is 500wx500h,
         * - a) image is 700wx300h => scale ratio will be 500/700
         * - b) image is 300wx800h => scale ratio will be 500/800
         * - c) image is 700wx900h => scale ratio will be 500/900, because the smallest ratio
         * is chosen to keep the aspect ratio of the image
         */
        private get scaleRatio(): number {
            return Math.min(this.widthScaleRatio, this.heightScaleRatio);
        }

        private get widthScaleRatio(): number {
            const catStackSize = this.catStackSize;
            return (this.containerWidth - (this.padding * 2)) / catStackSize.width;
        }

        private get heightScaleRatio(): number {
            const catStackSize = this.catStackSize;
            return (this.containerHeight - (this.padding * 2)) / catStackSize.height;
        }

        private get canvasWidth(): number {
            return this.catStackSize.width * this.scaleRatio;
        }

        private get canvasHeight(): number {
            return this.catStackSize.height * this.scaleRatio;
        }

        /**
         * Render a single slice from the image file, using the meta-data from.
         *
         * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage}
         */
        public renderSlice(): void {
            const targetHeight = this.canvasHeight;
            const targetWidth = this.canvasWidth;
            const canvasContext = this.canvasContext;
            const spriteImage = this.spriteImage;
            const catStack = this.catStack;

            if (canvasContext) {
                if (spriteImage) {
                    if (catStack.count > 0) {
                        const drawCanvasInfo = CatStackImageUtil.calculate(
                            catStack.image, catStack.slice, this.currentSliceIndex, targetWidth, targetHeight);

                        CatStackImageUtil.resizeCanvas(canvasContext, targetWidth, targetHeight);

                        // First draw the CT image, this will over-write any old image and any
                        // old indicator lines.
                        CatStackImageUtil.render(canvasContext, spriteImage, drawCanvasInfo);

                        // Now optionally draw an indicator line.
                        this.renderIndicatorLine(canvasContext, drawCanvasInfo);
                    } // else no catstack info
                } // else no image to show
            } // no canvas context
        }

        private renderIndicatorLine(context: CanvasRenderingContext2D, position: DrawCanvasInfo): void {
            const offset = this.indicatorOffset;

            switch (this.indicatorLine) {
                case IndicatorLine.Horizontal:
                    CatStackImageUtil.renderHorizontalLine(context, position, offset);
                    break;
                case IndicatorLine.Vertical:
                    CatStackImageUtil.renderVerticalLine(context, position, offset);
                    break;
                case IndicatorLine.None:
                    // nothing to do
                    break;
                default:
                    log.debug('Unknown indicator mode %s', this.indicatorLine);
            }
        }

        private get spriteImage(): HTMLImageElement | null {
            return (this.value.spriteImage as HTMLImageElement | null);
        }

        private get catStack(): CatStackRepresentation {
            return this.value.catStack;
        }

        private get currentSliceIndex(): number {
            return NumberUtil.constrain(this.value.currentIndex, 0, this.catStack.count - 1);
        }

        private get catStackSize(): ImageSize {
            return this.value.catStack.world;
        }
    }
