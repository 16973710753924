import GroupObject, { GroupProperties } from '@/hipPlanner/assembly/objects/GroupObject';
import AxisObject, { AxisProperties } from '@/hipPlanner/assembly/objects/AxisObject';
import { ThreeUtil } from '@/lib/base/ThreeUtil';

const X_AXIS_DEFAULT_COLOR = '#ff2222';
const Y_AXIS_DEFAULT_COLOR = '#44ff22';
const Z_AXIS_DEFAULT_COLOR = '#2244ff';
const DEFAULT_AXIS_LENGTH = 30;

export type CoordinatesProperties = GroupProperties & {
    visible?: boolean
    textHeight?: number
    headLength?: number
    x?: AxisProperties
    y?: AxisProperties
    z?: AxisProperties
}

export default class CoordinatesObject extends GroupObject {
    constructor(properties: CoordinatesProperties) {
        super(properties);
        this.theObject.visible = properties.visible ?? true;

        const addAxis = (axisProperties: AxisProperties): AxisObject => {
            const axis = new AxisObject({
                length: DEFAULT_AXIS_LENGTH,
                textHeight: properties.textHeight,
                headLength: properties.headLength,
                ...axisProperties,
            });
            this.theObject.add(axis.object);
            return axis;
        };

        this.x = addAxis({color: X_AXIS_DEFAULT_COLOR, dir: ThreeUtil.standardBasis.x, ...properties.x});
        this.y = addAxis({color: Y_AXIS_DEFAULT_COLOR, dir: ThreeUtil.standardBasis.y, ...properties.y});
        this.z = addAxis({color: Z_AXIS_DEFAULT_COLOR, dir: ThreeUtil.standardBasis.z, ...properties.z});
    }

    x: AxisObject;

    y: AxisObject;

    z: AxisObject;
}
