
    import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
    import AlertIcon from '@/components/ui/icons/AlertIcon.vue';
    import AppButton from '@/components/basic/AppButton.vue';
    import AppFormControl from '@/components/basic/AppFormControl.vue';
    import AppInputText from '@/components/inputs/AppInputText.vue';
    import AppSimpleTable from '@/components/basic/AppSimpleTable.vue';
    import AppSvg from '@/components/basic/AppSvg.vue';
    import { mapStores } from 'pinia';
    import { SpinopelvicStore, useSpinopelvicStore } from '@/stores/spinopelvic/store';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';
    import CupRotationUtil from '@/hipPlanner/components/state/CupRotationUtil';
    import PlanItemDetailsItem from '@/components/case-plan/PlanItemDetailsItem.vue';
    import NomogramPlot from '@/components/spinopelvic/NomogramPlot.vue';
    import SpinopelvicParameterWarnings from '@/components/spinopelvic/SpinopelvicParameterWarnings.vue';
    import { SpinopelvicCalculations, SpinopelvicWarnings } from '../spinopelvic/types';
    import { makeSpinopelvicCalculations } from '@/components/spinopelvic/calculations';
    import {
        HipSpecificationStore,
        useHipSpecificationStore,
    } from '@/hipPlanner/stores/specifications/hipSurgicalSpecificationStore';
    import { StudyLandmarksStore, useStudyLandmarksStore } from '@/stores/studyLandmarks/store';
    import assert from 'assert';
    import { makeSpinopelvicWarnings } from '@/components/spinopelvic/warnings';

    interface WarningTableRow {
        label: string,
        value: string,
    }

    @Component({
        components: {
            PlanItemDetailsItem,
            AlertIcon,
            AppButton,
            AppFormControl,
            AppInputText,
            AppSimpleTable,
            AppSvg,
            NomogramPlot,
            SpinopelvicParameterWarnings,
        },
        computed: {
            ...mapStores(
                useSpinopelvicStore,
                useHipSpecificationStore,
                useStudyLandmarksStore,
            ),
        },
    })
    export default class SpinopelvicPlanItem extends Vue {
        @Prop({required: true})
        id!: string;

        @InjectReactive()
        private planStore!: PlanReportStore;
        declare protected spinopelvicStore: SpinopelvicStore;
        declare protected hipSpecificationStore: HipSpecificationStore;
        declare protected studyLandmarksStore: StudyLandmarksStore;

        protected warnings: Array<WarningTableRow> = [];
        protected spinopelvicCalculations!: SpinopelvicCalculations;
        protected spinopelvicWarnings!: SpinopelvicWarnings;

        protected created(): void {
            if (this.planStore.cupRotation !== null) {
                this.spinopelvicCalculations = makeSpinopelvicCalculations(
                    this.spinopelvicStore,
                    this.studyLandmarksStore,
                    this.hipSpecificationStore.alignmentMode,
                );
                assert.ok(!!this.cupInclination, 'Cup Inclination not found.');
                assert.ok(!!this.cupAnteversion, 'Cup Anteversion not found.');
                this.spinopelvicWarnings = makeSpinopelvicWarnings(
                    this.cupInclination,
                    this.cupAnteversion,
                    this.spinopelvicStore,
                    this.spinopelvicCalculations,
                );
            }
        }

        protected get cupInclination(): number | null {
            if (this.planStore.cupRotation !== null) {
                const cupRotationDegrees = CupRotationUtil.toRadiographic(this.planStore.cupRotation);
                return cupRotationDegrees.inclination;
            }
            return null;
        }

        protected get cupAnteversion(): number | null {
            if (this.planStore.cupRotation !== null) {
                const cupRotationDegrees = CupRotationUtil.toRadiographic(this.planStore.cupRotation);
                return cupRotationDegrees.anteversion;
            }
            return null;
        }
    }
