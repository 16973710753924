import ResourceService from '@/lib/api/ResourceService';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { CacheOptions, create, CreateOptions } from '@/lib/semanticNetworkMigrationUtils';
import {
    StudyCollectionRepresentation,
    StudyRepresentation,
} from '@/lib/api/representation/case/study/StudyRepresentation';
import { DicomFileCollectionRepresentation } from '@/lib/api/representation/DicomFileRepresentation';
import { LinkSelector, LinkUtil } from 'semantic-link';
import ResourceUtil from '@/lib/api/ResourceUtil';
import LinkRelation from '@/lib/api/LinkRelation';

import anylogger from 'anylogger';
import {
    SeriesCreateDataRepresentation,
    StudyDuplicateCreateDataRepresentation,
} from '@/lib/api/representation/SeriesRepresentation';
import { ApiUtil, pooledSingletonMakeStrategy } from '@/lib/semantic-network';
import {
    SegmentedModelCollectionRepresentation,
} from '@/lib/api/representation/case/study/SegmentedModelRepresentation';
import ContentType from '@/lib/http/mimetype';
import { productVersion } from '@/lib/version';

const log = anylogger('CaseStudyResource');

export default class CaseStudyResource implements ResourceService {
    /**
     * Get a specific study that is either sparse (or just needs refreshing.)
     */
    public static async getStudy(study: StudyRepresentation, options?: CacheOptions):
        Promise<StudyRepresentation | null> {
        return await ApiUtil.get<StudyRepresentation>(study, options) ?? null;
    }

    /**
     * Get the active study for a case (project). This study resource may change over time, and
     * may not be present (if no DICOM files have been uploaded).
     *
     * The case (project) should have a link with a relationship of 'study' and a name of
     * 'active'. If present the URL/resource could be fetched directly. Instead the strategy
     * here is to load it from a virtual list of studies in the 'studies' collection.
     */
    public static async getActiveStudy(caseResource: CaseRepresentation, options?: CacheOptions):
        Promise<StudyRepresentation | null> {
        if (LinkUtil.matches(caseResource, LinkRelation.activeStudy)) {
            const caseStudies = ResourceUtil.makePooledCollection(caseResource, { rel: LinkRelation.studies });
            return await ApiUtil.get<StudyRepresentation>(
                caseResource, {
                    ...options,
                    rel: LinkRelation.activeStudy,
                    name: 'activeStudy',
                    makeSparseStrategy: (o) => pooledSingletonMakeStrategy(caseStudies, o),
                }) ?? null;
        }
        return null; // there is no active study
    }

    /**
     * Get the active 'study' resource for a single case (project). This may be not present (404 - Not found)
     * for cases (projects) that haven't had a CT scan uploaded.
     */
    public static async getCaseStudyWithFiles(caseResource: CaseRepresentation, options?: CacheOptions):
        Promise<StudyRepresentation | null> {
        const study = await this.getActiveStudy(caseResource, options);
        if (study) {
            const _fileCollection = await ApiUtil.get<DicomFileCollectionRepresentation>(
                study, { rel: LinkRelation.ctFiles, includeItems: false, ...options });
        }
        return study;
    }

    /**
     * Get a sparse list of studies uploaded/created for the case.
     */
    public static async getCaseStudies(caseResource: CaseRepresentation, options?: CacheOptions):
        Promise<StudyCollectionRepresentation | null> {
        return await ApiUtil.get<StudyCollectionRepresentation>(
            caseResource, { rel: LinkRelation.studies, includeItems: false, ...options }) ?? null;
    }

    /**
     * Create a new study in the context of a case.
     */
    public static async createStudy(
        caseResource: CaseRepresentation,
        createData: SeriesCreateDataRepresentation | StudyDuplicateCreateDataRepresentation,
        options?: CreateOptions): Promise<StudyRepresentation | null> {
        // the pool of all studies for a case
        const caseStudies = await ResourceUtil.makePooledCollection(
            caseResource, { ...options, rel: LinkRelation.studies });

        // Create the new study in the context of the case studies collection. Because this is also the pool, the
        // new study is not explicitly added to the studies collection. The study is **not** the active study until
        // after the study is made active with an update (i.e. there is no singleton link).
        return await create<StudyRepresentation>(
            {
                ...createData,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                web_component_version: productVersion(),
            },
            {
                ...options,
                createContext: caseResource,
                rel: LinkRelation.studies,
                contentType: ContentType.Json,
                makeSparseStrategy: (o) => pooledSingletonMakeStrategy(caseStudies, o),
            }) ?? null;
    }

    public static async getSegmentedModels(study: StudyRepresentation, options?: CacheOptions): Promise<void> {
        log.info('Loading 3D meshes for bones and metal...');
        // Get the list of all models, and get their meta-data (JSON representation)
        if (!await this.getSegmentedModelCollectionRepresentation(
            study, LinkRelation.studyBoneModels, 'boneModels', options)) {
            log.warn('Failed to load bone models');
        }

        // load the segmented metal models of the active study
        if (!await this.getSegmentedModelCollectionRepresentation(
            study, LinkRelation.studyMetalModels, 'metalModels', options)) {
            log.warn('Failed to load metal models');
        }
    }

    /**
     * Get the representation of a collection of named segmented models belonging to a study
     */
    private static async getSegmentedModelCollectionRepresentation(
        study: StudyRepresentation,
        relation: LinkSelector,
        name: string,
        options?: CacheOptions): Promise<SegmentedModelCollectionRepresentation | null> {
        return await ApiUtil.get<SegmentedModelCollectionRepresentation>(
            study, { includeItems: true, ...options, rel: relation, name }) ?? null;
    }
}
