import { getUri, LinkedRepresentation, RelationshipType } from 'semantic-link';
import { AxiosInstance } from 'axios';
import { BufferGeometry } from 'three';
import { CaseComponentRepresentation } from '@/lib/api/representation/case/CaseComponentRepresentation';
import { ModelRepresentation } from '@/lib/api/representation/ModelRepresentation';
import ResourceService from '@/lib/api/ResourceService';
import LinkRelation from '@/lib/api/LinkRelation';

import AxiosBrowserCacheUrlMutation from '@/lib/http/AxiosBrowserCacheUrlMutation';

export default class HipSurgicalTemplateComponentResource implements ResourceService {

    /**
     * Get a non-json/xml/yaml native representation of the resource, e.g PLY file
     * for a model resource.
     * The result is a promise of the given media type.
     * Important: the media type uses the built-in media type converters of Axios,
     * of which one exists for PLY files.
     */
    public static getMediaType<T extends LinkedRepresentation, TResult>(
        r: T,
        rel: RelationshipType,
        itemName: keyof T,
        axios: AxiosInstance): Promise<TResult> {
        const uri = getUri(r, rel);

        if (uri) {
            return axios.get<TResult>(
                uri,
                AxiosBrowserCacheUrlMutation.makeMutationOption({
                    headers: {
                        accept: 'model/*,*/*;q=0.5',
                    },
                    responseType: 'arraybuffer',
                }))
                .then((response) => {
                    if (response && response.status === 200) {
                        const data = response.data as TResult;
                        r[itemName] = data as any;
                        return data;
                    }
                    throw new Error(`Failed to get media: ${status}`);
                });
        }
        return Promise.reject(new Error('No such link relation'));
    }

    /**
     * Get the geometry model of a selected implant component (stem, cup, liner, etc.) in its unaligned position
     */
    public static getComponentModelPLY<T extends CaseComponentRepresentation>(
        axios: AxiosInstance,
        component: T): Promise<never | BufferGeometry> {
        return this.getMediaType<ModelRepresentation, BufferGeometry>(
            component, LinkRelation.component, 'model', axios);
    }
}

