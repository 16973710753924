import { render, staticRenderFns } from "./SpinopelvicWarningsTable.vue?vue&type=template&id=3b859d11&scoped=true&"
import script from "./SpinopelvicWarningsTable.vue?vue&type=script&lang=ts&"
export * from "./SpinopelvicWarningsTable.vue?vue&type=script&lang=ts&"
import style0 from "./SpinopelvicWarningsTable.vue?vue&type=style&index=0&id=3b859d11&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b859d11",
  null
  
)

export default component.exports