import { Vue } from 'vue-property-decorator';
import { PlannerEvent } from '@/lib/planning/events/PlannerEvent';
import anylogger from 'anylogger';
import HipViewerObjectUtil from '@/hipPlanner/assembly/objects/HipViewerObjectUtil';
import Implant3d from '@/hipPlanner/assembly/objects/Implant3d';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { HipPlannerViewStore } from '@/hipPlanner/stores/plannerView/hipPlannerView';
import { addHandlers } from '@/lib/vue/addHandlers';
import { VueObserver } from '@/hipPlanner/assembly/controllers/VueObserver';

const log = anylogger('HipMaterial');

/**
 * A renderless component that listen to changes on the cup assembly being updated and set the hip implant material
 * to x-ray or normal mode depending on the current UI state.
 *
 * In theory, given that this occurs asynchronously, the user might be able to see this for a fraction of a
 * second, but this behaviour was not observed.
 *
 * Note:
 * 1. The current material mode is set on the global state vuex
 * @see {@interface HipPlannerViewSettingsByMode}
 *
 * 2. Components on load are default to the 'normal' mode.
 */
export default class HipImplantsMaterialMode extends VueObserver {

    /**
     * The current material model on the pinia state
     */
    constructor(private plannerViewStore: HipPlannerViewStore, private plannerEventBus: Vue) {
        super();
        this.addWatches(
            addHandlers(plannerEventBus, [
                [PlannerEvent.HipPlannerCupAssemblyCupSet, this.onAssemblyChange.bind(this)],
            ]),
        );
    }

    /**
     * On cup assembly change, set the material mode of the implants
     *
     * Note: There is no distinction at this point to which of the implants of the assembly the material is applied.
     */
    public onAssemblyChange(assembly: HipPlannerAssembly): void {
        log.debug('onCupAssemblyChange handler called');
        HipViewerObjectUtil.onEachImplant3D(assembly, (implant3D: Implant3d) => {
            implant3D.changeViewMode(this.plannerViewStore.currentMaterialMode);
        });
    }
}
