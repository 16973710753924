import ArrayUtil from '@/lib/collection/ArrayUtil';
import { formatNumber } from '@/lib/filters/format/formatNumber';

/**
 * Formats an array of numbers of **any length**.
 *
 * Note:
 * 1. Precision is 2 by default:
 * ```
 * 1. formatArrayNumber([1, 2, 3]) => '[1.00, 2.00, 3.00]'.
 * 2. formatArrayNumber([1, 2, 3], 0) => '[1, 2, 3]'.
 * ```
 *
 * 1. If it is not array, it will be formatted with dashes.
 * ```
 * formatArrayNumber({}) => '[--]'.
 * ```
 *
 * 1. Members of the array that are not number will be formatted as dashes
 * ```
 * formatArrayNumber([1,'a',null]) => '[1, --, --]'.
 * ```
 */
export const formatArrayNumber = function(value: number[], precision = 2): string {
    if (ArrayUtil.isArray(value)) {
        const formattedNumbers = value.map((item: number) => formatNumber(item, precision));
        return `[${formattedNumbers.join(', ')}]`;
    } else {
        return '[--]';
    }
};
