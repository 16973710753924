
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { DateTime } from 'luxon';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';

    /**
     * Current approach is to use the luxon `toRelative`
     *
     * @see https://github.com/moment/luxon/blob/master/src/datetime.js
     *
     */
    @Component
    export default class Ago extends Vue {
        /**
         * A luxon DateTime to show. The API will provide timestamps as an ISO-8601 UTC string.
         * The caller must convert these to a luxon {@link DateTime} type.
         *
         * e.g. to convert the string timestamp:
         * ```
         * DateTime.fromISO(this.value, { zone: 'utc' })
         * ```
         */
        @Prop({ required: true, validator: notNilValidator('value') })
        public value!: DateTime;

        private now: DateTime | null = null;

        protected mounted(): void {
            this.scheduleUpdateNow();
        }

        /**
         * Schedule the update of the current now time. The 'now' is used to decide the rate of update of the ago time.
         *
         * Note:
         * If the value provided is recent (less than a minute), the ago time will be updated every second.
         * Otherwise, every minute.
         */
        protected scheduleUpdateNow() {
            const timeToNow = DateTime.now().diff(this.value, ['minutes']).toObject();
            const minutesPassed = timeToNow.minutes;
            if (minutesPassed !== undefined) {
                this.now = DateTime.now();
                // If it is a plan created in the last minute, update the time ago every second. Otherwise, less often;
                const nextUpdateNow = minutesPassed <= 1 ? 1000 : 30 * 1000;
                setTimeout(() => this.scheduleUpdateNow(), nextUpdateNow);
            }
        }

        /** A computed property that formats the to the relative time */
        protected get formattedValue(): string | null {
            /** This variable is only used to trigger the reactivity */
            const _now = this.now;

            if (this.value?.isValid) {
                return this.value.toRelative();
            }

            return null;
        }
    }
