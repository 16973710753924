
    import { Component, Vue } from 'vue-property-decorator';

    import anylogger from 'anylogger';

    const log = anylogger('CaseTitle');

    @Component
    export default class CaseListTitle extends Vue {
        protected created(): void {
            log.debug('Case title created');
        }
    }
