
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { mapStores } from 'pinia';
    import {
        SpinopelvicStore,
        useSpinopelvicStore,
    } from '@/stores/spinopelvic/store';
    import assert from 'assert';
    import {
        ANTEVERSION_Y_MAX,
        ANTEVERSION_Y_MIN,
        INCLINATION_X_MAX,
        INCLINATION_X_MIN,
    } from '@/components/spinopelvic/conts';
    import Plotly, { Shape } from 'plotly.js-basic-dist';
    import { Config, Data, Layout } from 'plotly.js';
    import { SafeZoneDataSet, SpinopelvicCalculations } from '@/components/spinopelvic/types';
    import {
        yAxisCupCSIArray,
        anteversionSafeZonePath,
        inclinationSafeZonePath,
        patientSafeZonePath,
    } from '@/components/spinopelvic/NomogramPlotUtils';
    import { makeSafeZoneDataSet } from '@/components/spinopelvic/safeZoneDataSet';

    @Component({
        computed: {
            ...mapStores(useSpinopelvicStore),
        },
    })
    export default class NomogramPlot extends Vue {
        @Prop({required: true})
        id!: string;

        @Prop({required: true})
        cupInclination!: number;

        @Prop({required: true})
        cupAnteversion!: number;

        @Prop({required: true})
        spinopelvicCalculations!: SpinopelvicCalculations;

        declare protected spinopelvicStore: SpinopelvicStore;

        protected get plotElement(): HTMLElement {
            const element = document.getElementById(this.elementId);
            assert.ok(element, 'no plot element found.');
            return element;
        }

        protected mounted(): void {
            this.plot();
        }

        protected destroyed(): void {
            Plotly.purge(this.plotElement);
        }

        @Watch('spinopelvicCalculations')
        @Watch('cupInclination')
        @Watch('cupAnteversion')
        protected plot(): void {
            Plotly.newPlot(
                this.plotElement,
                this.plotData,
                this.plotLayout,
                this.plotConfig
            );
        }

        protected get plotData(): Data[] {
            const cupAnteversionLower = {
                x: this.safeZoneDataSet.xAxisArray,
                y: yAxisCupCSIArray(
                    this.safeZoneDataSet.xAxisArray,
                    this.spinopelvicCalculations.csiLowerBound,
                    this.spinopelvicCalculations.pelvicTilt
                ),
                mode: 'lines',
                line: {
                    color: 'grey',
                    width: 1,
                },
                showlegend: false,
            };
            const cupAnteversionUpper = {
                x: this.safeZoneDataSet.xAxisArray,
                y: yAxisCupCSIArray(
                    this.safeZoneDataSet.xAxisArray,
                    this.spinopelvicCalculations.csiUpperBound,
                    this.spinopelvicCalculations.pelvicTilt
                ),
                mode: 'lines',
                line: {
                    color: 'grey',
                    width: 1,
                },
                showlegend: false,
            };
            const cupAnteversionMarker = {
                x: [this.cupInclination],
                y: [this.cupAnteversion],
                mode: 'markers',
                marker: {
                    symbol: 'circle-open-dot',
                    size: 20,
                    color: 'black',
                },
                name: 'Planned Orientation',
            };

            return [
                cupAnteversionLower,
                cupAnteversionUpper,
                cupAnteversionMarker,
            ];
        }

        protected get plotLayout(): Partial<Layout> {
            const plotBoundary: Partial<Shape> = {
                type: 'rect',
                x0: INCLINATION_X_MIN,
                y0: ANTEVERSION_Y_MIN,
                x1: INCLINATION_X_MAX,
                y1: ANTEVERSION_Y_MAX,
                line: {
                    width: 1,
                    color: '#7C8893',  // #custom-grey
                },
            };

            return {
                width: 700,
                height: 500,
                font: {
                    family: 'Proxima Nova',
                },
                xaxis: {
                    title: 'Inclination (\xB0)',
                    range: [INCLINATION_X_MIN, INCLINATION_X_MAX],
                    zeroline: false,
                },
                yaxis: {
                    title: 'Anteversion (\xB0)',
                    range: [ANTEVERSION_Y_MIN, ANTEVERSION_Y_MAX],
                    zeroline: false,
                },
                showlegend: true,
                legend: {
                    orientation: 'h',
                },
                autosize: true,
                margin: {
                    t: 0,
                    b: 40,
                    l: 40,
                    r: 40,
                },
                shapes: [
                    plotBoundary,
                    this.anteversionSafeZoneShape,
                    this.inclinationSafeZoneShape,
                    this.patientSafeZoneShape,
                ],
            };
        }

        get elementId(): string {
            return `${this.id}-nomogram-plot`;
        }

        protected get plotConfig(): Partial<Config> {
            return {
                staticPlot: true,
                displayModeBar: false,
            };
        }

        protected get safeZoneDataSet(): SafeZoneDataSet {
            return makeSafeZoneDataSet(
                this.spinopelvicCalculations
            );
        }

        protected get anteversionSafeZoneShape(): Partial<Shape> {
            return {
                type: 'path',
                path: anteversionSafeZonePath(this.safeZoneDataSet),
                fillcolor: '#99A4AC',  // #custom-grey-1
                opacity: 0.2,
                line: {
                    width: 0,
                },
            };
        }

        protected get inclinationSafeZoneShape(): Partial<Shape> {
            return {
                type: 'path',
                path: inclinationSafeZonePath(this.safeZoneDataSet),
                fillcolor: '#99A4AC',  // #custom-grey-1
                opacity: 0.2,
                line: {
                    width: 0,
                },
            };
        }

        protected get patientSafeZoneShape(): Partial<Shape> {
            return {
                type: 'path',
                path: patientSafeZonePath(this.safeZoneDataSet, this.spinopelvicCalculations),
                fillcolor: '#00B695',  // #custom-green,
                opacity: 0.5,
                line: {
                    width: 1,
                },
            };
        }
    }
