import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import ResourceUtil from '@/lib/api/ResourceUtil';
import assert from 'assert';
import LinkRelation from '@/lib/api/LinkRelation';
import { isCupPosition, isCupRotation } from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateModel';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { isApproxEmptyRigidTransform, isRigidTransform, toRepresentation } from '@/lib/base/RigidTransform';
import { cloneDeep } from 'lodash';
import { LinkedRepresentation } from 'semantic-link';
import { getRequiredSelfUri } from '@/lib/api/SemanticNetworkUtils';

/**
 * Create the surgical-template representation implied by the UI-state of the {@link HipTemplateStore}
 */
export default function makeTemplateRepresentation(store: HipTemplateStore): HipSurgicalTemplateRepresentation {
    function makeStemTransform() {
        const stemTransform = store.stemTransform;
        assert.ok(isRigidTransform(stemTransform));

        if (store.enableStemTransform) {
            // As an update it makes sense that if the stem-transform is in its default ('empty') state we use
            // `null` instead of a nested structure without meaning. This should make it more obvious when it
            // actually changes.

            // The stem transform will be null when:
            // 1. The stem is changed
            // 2. The stem is reset to a template without a transform
            // 3. Stem targets are changed
            return isApproxEmptyRigidTransform(stemTransform) ? null : toRepresentation(stemTransform);
        } else {
            return null;
        }
    }

    assert.ok(store.userTemplate, 'template must be defined');

    // Update stem & head links
    const stemUri = store.stemUri;
    const headUri = store.headUri;

    assert.ok(!!stemUri, 'stem link must be defined');
    assert.ok(!!headUri, 'head link must be defined');

    // Update cup & liner links, rotation & offset
    // The cup and liner are also set, given they are used to perform validations (cup matches liner)
    //
    const cupUri = store.cupUri;
    const linerUri = store.linerUri;
    const cupRotation = store.cupRotation;
    const cupOffset = store.cupOffset;

    assert.ok(!!cupUri, 'cup link must be defined');
    assert.ok(!!linerUri, 'liner link must be defined');
    assert.ok(isCupRotation(cupRotation));
    assert.ok(isCupPosition(cupOffset));

    const template: Partial<HipSurgicalTemplateRepresentation> & LinkedRepresentation = {
        links: [],
        state: store.userTemplate.state,
        record_state: store.userTemplate.record_state,
        target_leg_length_change: store.targetLegLengthChange,
        target_offset_change: store.targetOffsetChange,
        cup_rotation: cloneDeep(cupRotation),
        cup_offset: cloneDeep(cupOffset),
        stem_transform: makeStemTransform(),
    };

    ResourceUtil.setLink2(
        template,
        LinkRelation.hipCurrentStemComponent.rel as string,
        LinkRelation.hipCurrentStemComponent.title as string,
        stemUri);

    ResourceUtil.setLink2(
        template,
        LinkRelation.hipCurrentStemHeadComponent.rel as string,
        LinkRelation.hipCurrentStemHeadComponent.title as string,
        headUri);

    ResourceUtil.setLink2(
        template,
        LinkRelation.hipCurrentCupComponent.rel as string,
        LinkRelation.hipCurrentCupComponent.title as string,
        cupUri);

    ResourceUtil.setLink2(
        template,
        LinkRelation.hipCurrentCupLinerComponent.rel as string,
        LinkRelation.hipCurrentCupLinerComponent.title as string,
        linerUri);

    // This link is needed at the moment by the semantic-network library to do the PUT
    ResourceUtil.setLink2(template, LinkRelation.self as string, undefined, getRequiredSelfUri(store.userTemplate));

    return template as HipSurgicalTemplateRepresentation;
}
