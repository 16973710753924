import Vue from 'vue';
import VueGtagPlugin from 'vue-gtag';
import anylogger from 'anylogger';
import Router from 'vue-router';

const log = anylogger('InitVueGtag');

export const APP_NAME = 'ACID';

export default class InitVueGtag {
    /**
     * Initialise the Vue GTag plugin.
     *
     * What is important here is the integration with each environment. Each environment must
     * be able to plu-in/configure it's own unique google analytics id. If this id is not defined
     * then it is assumed to be a development environment and no analytics are used.
     *
     * The main document must have a meta tag of the form:
     *    <code>
     *         <meta name="acid-analytics-id" content="">
     *    </code>
     *  If the content is a non-empty string then the plugin will be initialised.
     *
     *  Deployment
     *  ==========
     *
     *  The following google analyics UA identifiers are defined (and should be configured appropriately):
     *      UA-103574522-1 - public business card web site
     *      UA-103574522-2 - Acid production application app.formuslabs.com
     *      UA-103574522-3 - Acid trial application app.trial.formuslabs.com
     *      UA-103574522-4 - Acid trial application app.qa.formuslabs.com
     *      UA-103574522-5 - Acid trial application app.dev.formuslabs.com
     *
     * @see {@link https://matteo-gabriele.gitbook.io/vue-gtag/}
     * @see {@link https://developers.google.com/analytics/devguides/collection/gtagjs}
     * @see {@link https://analytics.google.com/analytics/web/#/}
     * @see {@link https://www.simoahava.com/analytics/cookieflags-field-google-analytics/}
     * @see {@link https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id#cookie_domain_configuration}
     */
    public static init(router: Router): void {
        const uaId = InitVueGtag.getUaId();
        if (uaId) {
            log.debug('Using analytics UA identifier %s', uaId);
            Vue.use(
                VueGtagPlugin,
                {
                    config: { id: uaId, params: { cookie_flags: 'SameSite=None;Secure' } },
                    appName: APP_NAME,
                },
                router);
        } else {
            log.debug('No analytics UA identifier, assuming developer mode');
            Vue.use(
                VueGtagPlugin,
                {
                    disableScriptLoad: true,
                    bootstrap: false,
                });
        }
    }

    /**
     * Get an optional UA id from the head of the HTML document. This will default to being not present in
     * development environments. In deployment environments a non-empty string is expected.
     */
    public static getUaId(): string | null | undefined {
        return document
            .head
            .querySelector('meta[name=acid-analytics-id][content]')
            ?.getAttribute('content');
    }
}
