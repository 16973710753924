import ResourceService from '@/lib/api/ResourceService';
import LinkRelation from '@/lib/api/LinkRelation';
import { BufferGeometry } from 'three';
import { CatalogComponentRepresentation } from '@/lib/api/representation/catalog/CatalogComponentRepresentation';
import { getPLYMemoized } from '@/lib/api/resource/components/PLYMemoization';

export default class RegionResource implements ResourceService {
    /**
     * Get a collision mask as a PLY file of a component resource,
     * and put it into the collisionMask attribute of the resource
     *
     * @see CatalogComponentRepresentation.collisionMask
     */
    public static getCollisionMaskPLYFile<T extends CatalogComponentRepresentation>(
        resource: T): Promise<never | BufferGeometry> {
        return getPLYMemoized<T, BufferGeometry>(
            resource,
            LinkRelation.collisionMaskRegion,
            LinkRelation.collisionMask);
    }
}
