
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import CaseListItem from '@/components/case/CaseListItem.vue';
    import NewCase from '@/components/case/NewCase.vue';
    import CaseListTitle from '@/components/title/CaseListTitle.vue';
    import {
        CaseCollectionRepresentation,
        CaseRepresentation,
    } from '@/lib/api/representation/case/CaseRepresentation';
    import LinkedRepresentationUtil from '@/lib/LinkedRepresentationUtil';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';

    /**
     * This is the home page with the dashboard view of cases for a user.
     *
     * Note: By using `<v-virtual-scroll>` component we displays a virtual, infinite list using
     * a lazy approach that helps to:
     *   1) Not mount components that are not visible in the screen.
     *      In users will thousand of cases, it will mean less watchers, less memory used, etc,
     *      leading to a better performance.
     *   2) Not rendering DOM elements until they are needed: Same as 1) but at a browser level.
     *   The less the browser spend building the virtual dom and painting it, the early the javascript
     *   code will execute, leading to a better UX.
     */
    @Component({ components: { CaseListItem, CaseListTitle, NewCase } })
    export default class HomeProjectList extends Vue {
        @Prop({ required: true, validator: notNilValidator('height') })
        protected height!: number;

        /** The raw collection of cases/projects. */
        @Prop({ required: true, validator: notNilValidator('value') })
        protected value!: CaseCollectionRepresentation;

        /** the list (array) of cases (projects) */
        protected get projectItems(): CaseRepresentation[] {
            return this.value?.items ? this.value.items : [];
        }

        protected getItemKey(item: CaseRepresentation): string {
            return LinkedRepresentationUtil.getItemKey(item);
        }

        /**
         * The project item height based on the viewport information.
         *
         * Note: the `<v-virtual-scroll>` does not support dynamic content at this stage.
         * @see https://vuetifyjs.com/en/features/breakpoints/#breakpoint-service
         */
        protected get itemHeight(): number {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                case 'sm':
                case 'md':
                    return 160;

                case 'lg':
                case 'xl':
                    return 130;

                default:
                    return 130;
            }
        }
    }
