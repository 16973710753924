import assert from 'assert';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { OrthographicCamera, PerspectiveCamera, Vector3 } from 'three';

export type CameraAnimationResult = 'completed' | 'cancelled';

const UPDATE_TIMEOUT_MILLISECONDS = 10;
const DEFAULT_DURATION_MILLISECONDS = 400;

/**
 * An interface for camera animation. It has to provide a way to call the animation and a way to cancel it.
 */
export type CameraAnimation = {
    /** A promise that is resolved when the animation completes or is cancelled*/
    animation: Promise<CameraAnimationResult>

    /** Allows cancellation of the animation. */
    cancel(): void
}

/**
 * Animate the camera from one place to another. Animates both camera position and target (look-at) point.
 *
 * @param camera
 * @param controls
 * @param position The final position of the camera
 * @param target The final point the controls should be focussing on
 * @param up The final 'up' directional vector of the camera.
 * @param duration The time the animation will take, in milliseconds. Default value is 1 second.
 */
export function animateCamera(
    camera: PerspectiveCamera | OrthographicCamera,
    controls: TrackballControls,
    position: Vector3,
    target: Vector3,
    up: Vector3,
    duration = DEFAULT_DURATION_MILLISECONDS,
): CameraAnimation {
    if (duration <= 0) {
        throw Error('Duration must be positive');
    }
    const counterLoops = Math.ceil(duration / UPDATE_TIMEOUT_MILLISECONDS);

    const initialTarget = controls.target.clone();
    const initialPosition = camera.position.clone();
    const initialUp = camera.up.clone();

    let shouldCancel = false;

    // Add an event-listener that will react to controller interaction by cancelling the animation
    const onInteractionStart = () => shouldCancel = true;
    controls.addEventListener('start', onInteractionStart);

    return {
        animation: new Promise<CameraAnimationResult>((resolve, reject) => {
            let intervalHandle: number | null = null;

            const cleanup = () => {
                assert(intervalHandle != null);
                clearInterval(intervalHandle);
                controls.removeEventListener('start', onInteractionStart);
            };

            let counter = 0;

            intervalHandle = window.setInterval(() => {
                try {
                    if (shouldCancel) {
                        cleanup();
                        resolve('cancelled');
                    } else if (counter === counterLoops) {
                        // Set final values
                        camera.position.copy(position);
                        camera.up.copy(up);
                        controls.target.copy(target);

                        cleanup();
                        resolve('completed');
                    } else {
                        const t = interpolate(counter / counterLoops);
                        camera.position.lerpVectors(initialPosition, position, t);
                        camera.up.lerpVectors(initialUp, up, t);
                        controls.target.lerpVectors(initialTarget, target, t);
                        controls.update();
                        ++counter;
                    }
                } catch (e) {
                    cleanup();
                    reject(new Error('Camera animation did not finish as expected'));
                }
            }, UPDATE_TIMEOUT_MILLISECONDS);
        }),
        cancel: () => shouldCancel = true,
    };
}

const INTERPOLATION_FACTOR = 1.0 as const;
const A = 4 + INTERPOLATION_FACTOR;
const B = A / 3;

/**
 * This is an interpolation function, which:
 *
 *   - returns 0 if t is less than or equal to 0
 *   - returns 1 if t is greater than or equal to 1
 *   - otherwise interpolates between 0 and 1
 */
function interpolate(t: number): number {
    if (t < 0) {
        return 0;
    } else if (t > 1) {
        return 1;
    } else {
        const u = 1 - t;
        return Math.pow(t, 4) +
            4 * Math.pow(t, 3) * u +
            A * Math.pow(t, 2) * Math.pow(u, 2) +
            B * t * Math.pow(u, 3);
    }
}
