import { AcidObject3dBase } from '@/lib/planning/objects-3D/AcidObject3d';
import { includes } from 'ramda';
import { AcidMeshObject3d, instanceOfAcidMeshObject3D } from '@/hipPlanner/assembly/objects/AcidMeshObject3d';
import Implant3d from '@/hipPlanner/assembly/objects/Implant3d';
import { SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { AcidObject3dMainClassTypes } from '@/hipPlanner/assembly/objects/AcidObject3dMainClassTypes';
import { AliasArray } from '@/hipPlanner/components/viewer-filter-bar/ViewerFilterItem';

type OnAcidObject3dBaseCallBack = (acidObject: AcidObject3dBase) => void;
type OnAcidMeshObject3dCallBack = (acidObject: AcidMeshObject3d) => void;
type OnImplant3dCallBack = (acidObject: Implant3d) => void;

/**
 * Utility to iterate of objects of the hip viewer
 */
export default class HipViewerObjectUtil {
    /** Find objects by alias */
    public static findByAlias(assembly: SceneAssembly, alias: string): AcidObject3dBase | null {
        return HipViewerObjectUtil.find(assembly, (acidObject): boolean => {
            return acidObject.alias === alias;
        });
    }

    /** Each iterator over certain aliases of hip viewer */
    public static onEachByAlias(
        objects: AcidObject3dMainClassTypes[],
        aliases: AliasArray,
        callback: OnAcidObject3dBaseCallBack): void {
        HipViewerObjectUtil.forEach(objects, (acidObject: AcidObject3dBase) => {
            if (includes(acidObject.alias, aliases)) {
                callback(acidObject);
            }
        });
    }

    /** Each iterator over each {@link AcidMeshObject3d} of the hip viewer */
    public static onEachMeshObject3D(
        objects: AcidObject3dMainClassTypes[], callback: OnAcidMeshObject3dCallBack): void {
        HipViewerObjectUtil.forEach(objects, (acidObject: AcidObject3dBase) => {
            if (instanceOfAcidMeshObject3D(acidObject)) {
                callback(acidObject);
            }
        });
    }

    /** Iterator over each {@link Implant3d} of the cup assembly */
    public static onEachImplant3D(assembly: HipPlannerAssembly, callback: OnImplant3dCallBack): void {
        [
            assembly.cup,
            assembly.liner,
            assembly.stem,
            assembly.head].forEach((implant3D: Implant3d | undefined) => {
            if (implant3D instanceof Implant3d) {
                callback(implant3D);
            }
        });
    }

    /** Find the first object of the viewer that return true */
    private static find(assembly: SceneAssembly, condition: (acidObject: AcidObject3dBase) => boolean): AcidObject3dBase | null {
        let found: AcidObject3dBase | null = null;
        assembly.hipObjects.forEach((acidObjectParent) => {
            acidObjectParent.traverse((acidObject: AcidObject3dBase) => {
                // TODO Using !found to not override found object
                // TODO need to short-circuit traverse but do not know how
                if (!found && condition(acidObject)) {
                    found = acidObject;
                }
            });
        });

        return found;
    }

    /** Each iterator over each object of hip viewer */
    private static forEach(objects: AcidObject3dMainClassTypes[], callback: OnAcidObject3dBaseCallBack): void {
        objects.forEach((acidObjectParent) => {
            acidObjectParent.traverse((acidObject: AcidObject3dBase) => {
                callback(acidObject);
            });
        });
    }
}
