
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import TitleUtil from '@/components/TitleUtil';
    import CaseTitle from '@/components/title/CaseTitle.vue';
    import CatStackImage from '@/components/case/dicoms/CatStackImage.vue';
    import {
        CatStackCollectionRepresentation,
        CatStackRepresentation,
    } from '@/lib/api/representation/CatStackRepresentation';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
    import CaseCatStackResource from '@/lib/api/resource/case/study/CastStackResource';
    import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
    import CaseResource from '@/lib/api/resource/case/CaseResource';
    import Notifications from '@/components/notifications/Notifications.vue';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import { sequentialWaitAllVoid } from '@/lib/base/AsyncUtil';

    import anylogger from 'anylogger';
    import { Uri } from 'semantic-link';
    import { Route } from 'vue-router';
    import { makeAbsolute } from '@/router/UriMapping';
    import ApiResource from '@/lib/api/resource/ApiResource';
    import DicomViewerLayout from '@/components/case-dicom/DicomViewerLayout.vue';
    import { CatStackValue } from '@/components/case-dicom/types';
    import DicomViewerSlider from '@/components/case-dicom/DicomViewerSlider.vue';

    const log = anylogger('DicomImage');

    const AxialItemName = 'Axial';
    const CoronalItemName = 'Coronal';
    const SagittalItemName = 'Sagittal';

    @Component({ components: { DicomViewerSlider, DicomViewerLayout, CaseTitle, CatStackImage } })
    export default class DicomImage extends Vue {
        @Prop({ required: true })
        public apiUri!: string;

        protected tabs = 'axial-tab';

        protected isLoading = true;

        /** The case (project) */
        protected project: CaseRepresentation | null = null;

        protected study: StudyRepresentation | null = null;

        /**
         * The sagittal/axial/ catstack collection.
         */
        protected catstacks: CatStackCollectionRepresentation | null = null;

        protected axial: CatStackValue | null = null;
        protected coronal: CatStackValue | null = null;
        protected sagittal: CatStackValue | null = null;

        /**
         * Catstack slices in each direction are generated every n actual slices
         * In the future, this number should be made available from catstack info
         */
        protected sliceSampling = 2;

        @Watch('$route')
        @IsLoading('isLoading')
        protected async onRouteChange(to: Route, from: Route): Promise<void> {
            log.info('Change from %s to %s', from.params.apiUri, to.params.apiUri);
            return await this.loadCase(makeAbsolute(to.params.apiUri));
        }

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            try {
                return await this.loadCase(this.apiUri);
            } catch (err) {
                log.error('Unexpected error loading catstack page: %s', err);
            }
        }

        /**
         * Load the data and setup the title bar.
         */
        protected async loadCase(apiUri: Uri): Promise<void> {
            await ApiResource.getApi(this.$api, this.$apiOptions);

            // Load case (project) data
            this.project = await CaseResource.getCaseByUri(this.$api, apiUri, this.$apiOptions);

            // emit the project so that the title bar can show the project title and track
            // the project state to update the stepper
            TitleUtil.$emit(this, {
                titleComponentType: CaseTitle,
                titleData: { project: this.project },
                notificationComponentType: Notifications,
                notificationData: { project: this.project },
            });

            if (this.project) {
                this.study = await CaseStudyResource.getActiveStudy(this.project, this.$apiOptions);
                if (this.study) {
                    this.catstacks = await CaseCatStackResource.getCaseCatStackCollection(this.study, this.$apiOptions);

                    if (this.catstacks?.items) {
                        await sequentialWaitAllVoid(
                            this.catstacks.items,
                            async (catStack: CatStackRepresentation) => {
                                if (catStack?.name) {
                                    try {
                                        log.debug('load \'%s\' start', catStack.name);
                                        switch (catStack.name) {
                                            case AxialItemName:
                                                this.axial = await this.loadCatStackValue(catStack);
                                                break;

                                            case CoronalItemName:
                                                this.coronal = await this.loadCatStackValue(catStack);
                                                break;

                                            case SagittalItemName:
                                                this.sagittal = await this.loadCatStackValue(catStack);
                                                break;

                                            default:
                                                log.error('Unexpected catstack image \'%s\' ignored', catStack.name);
                                        }

                                        log.debug('loaded \'%s\' done', catStack.name);
                                    } catch (err) {
                                        log.error('Failed to load sagittal image: %s', err);
                                    }
                                }
                            });
                    }
                }
            } else {
                log.warn('Failed to load case');
            }
        }

        private async loadCatStackValue(catStackRepresentation: CatStackRepresentation): Promise<CatStackValue> {
            const catStack = await CaseCatStackResource.getCaseCatStack(catStackRepresentation, this.$apiOptions);
            const currentIndex = catStack ? catStack.count / 2 : 0;
            const spriteImage = await CaseCatStackResource.getCaseCatStackImage(this.$http, catStackRepresentation);

            if (catStack) {
                return {
                    catStack,
                    currentIndex,
                    spriteImage,
                };
            } else {
                throw new Error('Could not load catstack');
            }
        }

        /**
         * Axial slices are ordered feet to head, opposite to the appearance of the orthogonal slices -->
         * therefore, we invert the axial index when calculating the indicator line in the orthogonal slices -->
         */
        protected get axialIndicatorOffset(): number {
            return this.axial?.catStack ? (this.axial.catStack.count - this.axial.currentIndex) * this.sliceSampling : 0;
        }

        protected get coronalIndicatorOffset(): number {
            return (this.coronal?.currentIndex ?? 0) * this.sliceSampling;
        }

        protected get sagittalIndicatorOffset(): number {
            return (this.sagittal?.currentIndex ?? 0) * this.sliceSampling;
        }
    }
