
    /**
     * see
     *  - https://vuetifyjs.com/en/components/dialogs
     */
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { LinkUtil } from 'semantic-link';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';

    import anylogger from 'anylogger';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';

    const log = anylogger('RemoveCase');

    @Component({ components: { HyperlinkButton } })
    export default class RemoveCase extends Vue {
        @Prop({ required: true })
        project!: CaseRepresentation | null;

        /** Anti-nike dialog for delete */
        protected isVisible = false;

        /** loading anti-nike dialog */
        protected isLoading = false;

        protected onSubmit = false;

        protected async onDelete(): Promise<void> {
            this.onSubmit = true;
            try {
                if (this.project) {
                    const caseUri = LinkUtil.getUri(this.project, ['canonical', 'self']);
                    if (caseUri) {
                        const r = await this.$http.delete(caseUri);
                        if (r.status === 204) {
                            //
                            // TODO: remove the case from the this.$api.projects collection
                            //
                            this.isVisible = false;
                            // The current path is no longer valid (because it has been deleted, so
                            // replace the route rather than pushing the route.
                            await this.$router
                                .replace({ name: 'home' })
                                .catch((e) => {
                                    log.error('Error navigating home: %s', e.message);
                                });
                        } else {
                            log.warn('Failed to delete case: %d', r.status);
                        }
                    } else {
                        log.error('Invalid case');
                    }
                } else {
                    log.error('No case');
                }
            } finally {
                this.onSubmit = false;
            }
        }
    }
