import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { CaseCollectionRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import CaseResource from '@/lib/api/resource/case/CaseResource';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import CaseSearchUtil from '@/views/administer/CaseSearchUtil';
import { SearchUtil } from '@/views/home/SearchUtil';
import anylogger from 'anylogger';

const log = anylogger('SingleSearchProjectService');

export enum SearchTaskState {
    /** The task is new, and has been added to the list of searches. */
    New = 'new',
    /** The search task has not been completed yet. It does not have any associated results yet. */
    InProgress = 'in-progress',

    // =====================================
    // Final states
    //

    /** The search task has been completed successfully. It should have associated results with it */
    Completed = 'completed',
    /** The update task failed for some reason. It may have results */
    Error = 'error',
    /** The search task was aborted. Probably another task superseded it. It may have results */
    Aborted = 'aborted',
}

type SearchTaskI = {
    /** The test search used */
    value: string,
    /** The state of the task */
    state: SearchTaskState,
    /** A function to abort the task */
    abort: () => void,
}

/** A search task that is abort-able */
export class SearchTask implements SearchTaskI {
    public state = SearchTaskState.New;

    /** An abort controller to abort the underlying http request */
    private readonly controller: AbortController;

    /**
     * The result will be available when completed.
     * If the task was aborted or in error, it may also have a result.
     */
    private result: CaseCollectionRepresentation | null = null;

    constructor(public readonly value: string) {
        this.controller = new AbortController();
        this.controller.signal.addEventListener('abort', () => {
            this.setState(SearchTaskState.Aborted);
        });
    }

    public abort(): void {
        this.controller.abort();
    }

    public getAbortSignal(): AbortSignal {
        return this.controller.signal;
    }

    public getResult(): CaseCollectionRepresentation | null {
        return this.result;
    }

    /** If the result passed is not null, set the state as completed */
    public setResult(value: CaseCollectionRepresentation | null): void {
        this.result = value;

        if (this.result !== null) {
            this.setState(SearchTaskState.Completed);
        }
    }

    /** Set the state if not on any final state */
    public setState(state: SearchTaskState): void {
        const canTransition = (): boolean => {
            switch (this.state) {
                case SearchTaskState.New:
                    if (state === SearchTaskState.InProgress) {
                        return true;
                    }
                    break;
                case SearchTaskState.InProgress:
                    if (state === SearchTaskState.Aborted ||
                        state === SearchTaskState.Completed ||
                        state === SearchTaskState.Error) {
                        return true;
                    }
                    break;
                default:
                    return false;
            }

            return false;
        };
        if (canTransition()) {
            this.state = state;
        }
    }
}

/**
 * A service that allows to run one search at a time. Pending searches requests are aborted.
 *
 * TODO: Caching of results could be implemented.
 */
export default class SingleSearchProjectService {
    protected searchTasks: SearchTask[] = [];

    constructor(protected $api: ApiRepresentation, private $apiOptions: CacheOptions) {
    }

    public async search(value: string): Promise<SearchTask> {
        const _createSearchTask = () => {
            return new SearchTask(value);
        };

        const previousSearches = this.searchTasks;
        const searchTask = _createSearchTask();
        try {
            this.abortSearchTasks(previousSearches);

            this.searchTasks.push(searchTask);
            searchTask.setState(SearchTaskState.InProgress);

            await this._doSearch(searchTask);
        } catch (error: unknown) {
            log.error(error);
            searchTask.setState(SearchTaskState.Error);
        } finally {
            // nothing to do
        }

        return searchTask;
    }

    public get isSearching(): boolean {
        const latestSearchTask = this.getLatestSearchTask();

        if (latestSearchTask) {
            return latestSearchTask.state === SearchTaskState.InProgress;
        }

        return false;
    }

    public get isError(): boolean {
        const latestSearchTask = this.getLatestSearchTask();

        if (latestSearchTask) {
            return latestSearchTask.state === SearchTaskState.Error;
        }

        return false;
    }

    /** Get the latest task if any */
    private getLatestSearchTask(): SearchTask | null {
        return this.searchTasks[this.searchTasks.length - 1] || null;
    }

    /**
     *  Load projects from the api
     *  Note: by default **forceLoad option to true** to retrieve a fresh list of projects.
     */
    private async getProjects(options?: CacheOptions): Promise<CaseCollectionRepresentation | null> {
        return await CaseResource.getCaseList(
            this.$api, { ...this.$apiOptions, forceLoad: true, ...options });
    }

    private async _doSearch(searchTask: SearchTask): Promise<SearchTask> {
        const abortSignal = searchTask.getAbortSignal();

        const search = async (): Promise<CaseCollectionRepresentation | null> => {
            if (SearchUtil.isSearchEmpty(searchTask.value)) {
                return await this.getProjects({ signal: abortSignal, forceLoad: false });
            } else {
                return await CaseSearchUtil.searchByCaseText(
                    this.$api,
                    searchTask.value,
                    { ...this.$apiOptions, ...{ signal: abortSignal } });
            }
        };

        const result = await search();
        searchTask.setResult(result);

        return searchTask;
    }

    private abortSearchTasks(searches: SearchTask[]): void {
        searches.forEach((searchTask: SearchTask) => {
            if (searchTask.state === SearchTaskState.New || searchTask.state === SearchTaskState.InProgress) {
                searchTask.abort();
            }
        });
    }

    stop(): void {
        this.abortSearchTasks(this.searchTasks);
    }
}
