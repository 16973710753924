import { AxiosInstance } from 'axios';
import { HipCaseStore } from '@/hipPlanner/stores/case/hipCaseStore';
import HipTemplateController from '@/hipPlanner/components/state/HipTemplateController';
import SurgicalTemplateSynchroniser from '@/hipPlanner/stores/template/SurgicalTemplateSynchroniser';
import plannerEventBus from '@/lib/planning/events/PlannerEventBus';
import { makeSceneAssembly, SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { BodySideType } from '@/lib/api/representation/interfaces';
import anylogger from 'anylogger';
import AcidPlannerModeController from '@/hipPlanner/assembly/controllers/AcidPlannerModeController';
import { ViewerFilterObserver } from '@/hipPlanner/components/viewer-filter-bar/ViewerFilterObserver';
import { HipPlannerViewStore } from '@/hipPlanner/stores/plannerView/hipPlannerView';
import CameraMan from '@/lib/planning/camera/CameraMan';
import CameraManUtil from '@/lib/planning/camera/CameraManUtil';
import BasicViewer from '@/lib/scene/BasicViewer';
import StemController from '@/hipPlanner/assembly/controllers/StemController';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import {
    loadHipPlannerAssembly,
    shouldEnableStemTransform,
} from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { loadAnatomicalAssemblies } from '@/hipPlanner/assembly/controllers/anatomicalAssemblies';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { HipSpecificationStore } from '@/hipPlanner/stores/specifications/hipSurgicalSpecificationStore';
import { HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import { markRaw } from 'vue';
import { CrossSectionPlaneController } from '@/lib/planning/cross-section/CrossSectionPlaneController';
import HipCrossSectionPlanes from '@/hipPlanner/assembly/controllers/cross-sections/HipCrossSectionPlanes';
import {
    CupCoverageController,
    makeCupCoverageController,
} from '@/hipPlanner/assembly/controllers/CupCoverageController';
import CupController from '@/hipPlanner/assembly/controllers/CupController';
import HipReRankingObserver from '@/hipPlanner/assembly/controllers/HipReRankingObserver';
import AdjustmentCalculator from '@/hipPlanner/assembly/controllers/adjustments/AdjustmentCalculator';
import HipImplantsMaterialMode from '@/hipPlanner/assembly/controllers/HipImplantsMaterialMode';
import { HipUserStore } from '@/stores/hipUser/store';
import { loadComponents } from '@/hipPlanner/stores/template/loadComponents';
import { SpinopelvicStore } from '@/stores/spinopelvic/store';
import LinkedRepresentationUtil from '@/lib/LinkedRepresentationUtil';
import assert from 'assert';
import { StudyLandmarksStore } from '@/stores/studyLandmarks/store';

const log = anylogger('hipPlannerServices');

/**
 * Feature flag to set if manual stem positioning enable or disabled.
 *
 * Do not use this flag to control the display/flow of the application.
 * It is better to use {@see HipPlannerState.enableStemTransform}
 */
const _MANUAL_STEM_POSITION_FEATURE_ENABLED = true;

export interface HipPlannerServices {
    plannerAssembly: HipPlannerAssembly | null;
    templateController: HipTemplateController | null;
    sceneAssembly: SceneAssembly | null;
    operativeSide: BodySideType | null;
    plannerModeController: AcidPlannerModeController | null;
    viewerFilterObserver: ViewerFilterObserver | null;
    stemController: StemController | null;
    crossSectionPlanes: HipCrossSectionPlanes | null;
    cupCoverageController: CupCoverageController | null;
    cupController: CupController | null;
    rerankingObserver: HipReRankingObserver | null;
    adjustmentCalculator: AdjustmentCalculator | null;
    implantsMaterial: HipImplantsMaterialMode | null;
    servicesReady: boolean;
}

/**
 * Load case-data, and create and initialize various controllers and other services needed
 * in the hip planner view.
 *
 * Once created, services are assigned to the services object, which allows access to various
 * components via the Vue provide/inject mechanism.
 */
export async function loadServices(
    services: HipPlannerServices,
    caseStore: HipCaseStore,
    templateStore: HipTemplateStore,
    plannerStore: HipPlannerStore,
    viewStore: HipPlannerViewStore,
    specificationStore: HipSpecificationStore,
    hipUserStore: HipUserStore,
    spinopelvicStore: SpinopelvicStore,
    studyLandmarksStore: StudyLandmarksStore,
    apiOptions: Partial<CacheOptions>,
    apiUri: string,
    cancelSignal: AbortSignal
): Promise<void> {
    // Create an abort-controller that will signal an 'abort' event when the planner is being exited.
    // (e.g. when navigating to a different case)
    // TODO: This could probably be the same signal as the cancelSignal (which cancels loading).
    const shutdownController = new AbortController();

    cancelSignal.throwIfAborted();

    await caseStore.loadCase(apiUri);
    assert.ok(!!caseStore.project, 'no project loaded');
    assert.ok(!!caseStore.study, 'no study loaded');
    await studyLandmarksStore.load(caseStore.study);
    await spinopelvicStore.load(LinkedRepresentationUtil.getItemKey(caseStore.project));
    await hipUserStore.load();

    cancelSignal.throwIfAborted();

    const surgicalSpecification = verify('surgical specification', caseStore.surgicalSpecification);

    await specificationStore.init(surgicalSpecification);

    cancelSignal.throwIfAborted();

    services.sceneAssembly = markRaw(makeSceneAssembly());

    const plannerModeController = new AcidPlannerModeController(plannerStore, viewStore);
    services.plannerModeController = markRaw(plannerModeController);

    services.templateController = markRaw(new HipTemplateController(
        templateStore,
        plannerStore,
        new SurgicalTemplateSynchroniser(caseStore, templateStore, plannerStore, apiOptions, shutdownController.signal),
        shutdownController,
    ));

    cancelSignal.throwIfAborted();

    services.operativeSide = verify('operative side', caseStore.caseResource?.side);

    // Reset planning view-state
    viewStore.resetHipPlanning();

    cancelSignal.throwIfAborted();

    /** Listen to changes on viewer button cluster */
    services.viewerFilterObserver = markRaw(new ViewerFilterObserver(plannerEventBus, plannerModeController));

    cancelSignal.throwIfAborted();

    services.servicesReady = true;
}

/**
 * Load hip-planning data and add it to the scene
 */
export async function loadScene(
    services: HipPlannerServices,
    featureFlagResectedFemur: boolean,
    caseStore: HipCaseStore,
    templateStore: HipTemplateStore,
    plannerStore: HipPlannerStore,
    viewStore: HipPlannerViewStore,
    http: AxiosInstance,
    apiOptions: Partial<CacheOptions>,
    viewer: BasicViewer,
    cancelSignal: AbortSignal | undefined): Promise<void> {
    const templateController = verify('template controller', services.templateController);

    cancelSignal?.throwIfAborted();

    const cameraMan = new CameraMan(viewer.camera, viewer.cameraControls);
    const sceneOrigin = verify('scene-origin', caseStore.sceneOrigin);
    CameraManUtil.setupInitialView(cameraMan, sceneOrigin);

    cancelSignal?.throwIfAborted();

    const study = verify('study', caseStore.study);
    const sceneAssembly = verify('scene assembly', services.sceneAssembly);
    const surgicalTemplate = verify('surgical template', caseStore.surgicalTemplate);
    const studyMeasurements = verify('study measurements', caseStore.studyMeasurements);
    const side = verify('operative side', services.operativeSide);
    verify('components catalog', caseStore.componentsCatalog);

    cancelSignal?.throwIfAborted();

    log.info('Loading components...');
    const [stem, cup, head, liner] = await loadComponents(caseStore, templateStore, cancelSignal);

    cancelSignal?.throwIfAborted();

    log.info('Loading anatomy...');
    await loadAnatomicalAssemblies(
        sceneAssembly,
        http,
        apiOptions,
        study,
        surgicalTemplate,
        featureFlagResectedFemur,
        cancelSignal);

    cancelSignal?.throwIfAborted();

    log.info('Loading hip-planner assembly...');
    // Add the planner-assembly to the scene
    const assembly = await loadHipPlannerAssembly(
        sceneAssembly, http, studyMeasurements, cup, stem, liner, head, side, cancelSignal);

    cancelSignal?.throwIfAborted();

    // Check whether we have loaded the data necessary to support stem transforms
    if (_MANUAL_STEM_POSITION_FEATURE_ENABLED && shouldEnableStemTransform(assembly)) {
        log.info('Enabling manual stem-transform');
        plannerStore.enableStemTransform = true;
    } else {
        log.warn('Manual stem transformation will be disabled');
        plannerStore.enableStemTransform = false;
    }

    verify('planner mode controller', services.plannerModeController).setAssembly(assembly);

    cancelSignal?.throwIfAborted();

    plannerStore.initialiseAlignmentCoords(
        assembly.cup.getCaseComponent<HipCupRepresentation>().global_cs);
    plannerStore.calculateAdjustments(assembly);
    plannerStore.calculateAdjustmentsStemOld(stem, head, head.offset);

    const implantsMaterial = new HipImplantsMaterialMode(viewStore, plannerEventBus);
    services.implantsMaterial = markRaw(implantsMaterial);

    const crossSectionPlanes = new HipCrossSectionPlanes(
        sceneAssembly, assembly, new CrossSectionPlaneController(), templateController.store, viewStore);
    services.crossSectionPlanes = markRaw(crossSectionPlanes);

    services.plannerAssembly = markRaw(assembly);
    services.cupCoverageController = markRaw(makeCupCoverageController(plannerStore, assembly, sceneAssembly, viewStore));

    CameraManUtil.setupHipCameraPresets(cameraMan, assembly);

    services.stemController = markRaw(new StemController(
        templateController.store,
        plannerStore,
        viewStore,
        http,
        assembly,
        implantsMaterial,
        crossSectionPlanes,
    ));

    services.rerankingObserver = new HipReRankingObserver(templateStore, plannerStore);

    services.cupController = markRaw(new CupController(
        templateController.store,
        plannerStore,
        assembly,
        sceneAssembly,
        http));

    templateController.initialize(services.stemController);

    services.adjustmentCalculator = new AdjustmentCalculator(
        assembly,
        plannerStore,
        templateController.store,
        plannerEventBus);

    cancelSignal?.throwIfAborted();

    // TODO: BAD BAD BAD: Need to get rid of planner mode controller
    verify('planner mode controller', services.plannerModeController).init(sceneAssembly, cameraMan);

    cancelSignal?.throwIfAborted();

    // await caseStore.syncManualTemplate();
    //
    // cancelSignal?.throwIfAborted();
    //
    templateController.synchroniser.start();

    // needs to be after synchroniser was started
    if (!await templateController.waitUntilCanQueryComponents()) {
        // Nothing to do
        // This will likely to be the scenario where the component was 'unmounted'
        // while the 'mounted' method was in progress and the waiting token was cancelled.
        //
        // Note: if the 'mounted' method is in progress, and the 'destroyed' is called,
        // the 'mounted' in progress will continue to run. That is the reason why
        // we conditionally execute the load of the components.
        log.debug('waiting until can query components condition was not met');
    }
}

function verify<T>(name: string, value: T | null | undefined): T {
    if (value) {
        return value;
    } else {
        throw Error(`Cannot load services: ${name} is undefined`);
    }
}

/** Convenient method to check if an Error is caused by a particular abort signal */
export function isErrorCausedBy(e: unknown, name: string): e is Error {
    const asError = (e as Error);
    return asError !== undefined && asError !== null && asError.name === name;
}

export function clear(services: HipPlannerServices) {
    services.plannerAssembly = null;
    services.templateController = null;
    services.sceneAssembly = null;
    services.operativeSide = null;
    services.plannerModeController = null;
    services.viewerFilterObserver = null;
    services.stemController = null;
    services.crossSectionPlanes = null;
    services.cupCoverageController = null;
    services.cupController = null;
    services.rerankingObserver = null;
    services.adjustmentCalculator = null;
    services.implantsMaterial = null;
}
