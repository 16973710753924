import { Color, CylinderGeometry, Material, Matrix4, Mesh, MeshBasicMaterial, Vector3 } from 'three';
import AcidObject3d, { ObjectProperties } from '@/lib/planning/objects-3D/AcidObject3d';

/**
 * An axis line 3d.
 *
 * The line is created using a thin cylinder as the mesh
 */
export default class AxisLine3D extends AcidObject3d {
    constructor(
        properties: ObjectProperties,
        public originPoint: Vector3,
        public axis: Vector3,
        _length: number,
        _radius: number,
        public color: Color | string | number,
        /** Offsets the origin point along the provided axis of the cylindrical line do be created */
        public offset: number) {
        super(properties);

        const position0 = this.originPoint.clone();
        const position1 = new Vector3();
        const length = _length || 30;
        const radius = _radius || 0.3;

        if (this.offset) {
            position0.addVectors(position0, new Vector3().copy(this.axis).multiplyScalar(this.offset));
        }

        position1.addVectors(position0, new Vector3().copy(this.axis).multiplyScalar(length));

        this._theObject = AxisLine3D.makeCylinderMesh(position0, position1, radius, radius, this.makeMaterial());

        // ViewerUtils.setObjectMaterials(line, line.material);
        this._theObject.name = `axis-line-${this.name}`;

        return this;
    }

    private makeMaterial(): Material {
        return new MeshBasicMaterial({ color: this.color });
    }

    /**
     * Function for adding cylinder mesh from 2 points
     *
     * TODO move to generic utility
     *
     * @param {Vector3} point1
     * @param {Vector3} point2
     * @param {number} radiusTop
     * @param {number} radiusBottom
     * @param {Material} material
     * @returns {Mesh}
     */
    public static makeCylinderMesh(
        point1: Vector3, point2: Vector3, radiusTop: number, radiusBottom: number, material: Material): Mesh {
        const direction = new Vector3().subVectors(point2, point1);
        const orientation = new Matrix4();
        orientation.lookAt(point1, point2, new Vector3(1, 0, 0));

        const edgeGeometry = new CylinderGeometry(
            radiusTop,
            radiusBottom,
            direction.length(),
            32,
            1);
        const edge = new Mesh(edgeGeometry, material);
        edge.applyMatrix4(orientation);

        // position based on midpoints
        edge.position.x = (point2.x + point1.x) / 2;
        edge.position.y = (point2.y + point1.y) / 2;
        edge.position.z = (point2.z + point1.z) / 2;
        return edge;
    }
}
