
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
    import {
        SurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';

    /**
     * This is a component to visualize the record_state with icons and tooltips
     */
    @Component({ components: {} })
    export default class RecordState extends Vue {
        /**
         * A surgical template history item
         */
        @Prop({ required: true })
        private value!: SurgicalTemplateRepresentation;

        /** binding of utility so can be used in template */
        protected util = SurgicalTemplateUtil;
    }
