import { replace } from 'ramda';

/**
 * A Regex that matches the following strings:
 * 1. +0, +0.0, +0.00, +0.000, etc
 * 2. -0, -0.0, -0.00, -0.000, etc
 */
const WRONG_FORMATTED_ZERO = /^[+-]0+(\.0*)?$/g;

/**
 * Formats a negative number with a negative sign.
 *
 * Note:
 * Accounts for bug described in https://github.com/adamwdraper/Numeral-js/issues/113.
 * The bug is described as solved on branch 2.1.0, but seems it stop being maintained on 2016.
 * @see https://github.com/adamwdraper/Numeral-js/compare/feature/2.1.0.
 */
export const withZeroSignedFix = function(value: string): string {
    return value.match(WRONG_FORMATTED_ZERO) ?
        replace(/^[+-]/, '', value) :
        value;
};
