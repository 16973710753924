
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { isNil } from 'ramda';
    import { includedInValidator } from '@/lib/vue/prop-validators/includedInValidator';
    import { AlertIconType, AlertIconTypes, AVAILABLE_ALERT_ICON_TYPES } from '@/components/ui/icons/AlertIconType';
    import { instanceOfTooltip, Tooltip, TooltipPosition } from '@/components/ui/tooltips/Tooltip';

    /**
     * A simple icon component with different types available: 'success', 'info', 'warning', 'error'
     * TODO Only warning implemented for now
     *
     * Optionally configures a tooltip {@see Tooltip}
     *
     * TODO Add support to show a dynamic v-dialog
     */
    @Component({ components: {} })
    export default class AlertIcon extends Vue {
        @Prop({
            required: false,
            default: AlertIconTypes.Warning,
            validator: includedInValidator('type', AVAILABLE_ALERT_ICON_TYPES),
        })
        private readonly type!: AlertIconType;

        /**
         * The icon size
         */
        @Prop({ required: false, default: 25 })
        protected readonly size!: number;

        /**
         * The tooltip over the icon
         */
        @Prop({ required: false, default: null })
        private readonly tooltip!: string | Tooltip | null;

        protected get hasTooltip(): boolean {
            return !isNil(this.tooltip);
        }

        protected get tooltipText(): string | null {
            if (this.tooltip) {
                if (instanceOfTooltip(this.tooltip)) {
                    return this.tooltip.text;
                } else {
                    return this.tooltip;
                }
            }
            return null;
        }

        protected get tooltipPosition(): TooltipPosition | null {
            if (this.tooltip) {
                if (instanceOfTooltip(this.tooltip)) {
                    return this.tooltip.position;
                }
            }
            return { right: true };
        }

        // protected get isSuccess(): boolean {
        //     return this.type === AlertIconTypes.Success;
        // }

        protected get isInfo(): boolean {
            return this.type === AlertIconTypes.Info;
        }

        protected get isWarning(): boolean {
            return this.type === AlertIconTypes.Warning;
        }

        protected get isError(): boolean {
            return this.type === AlertIconTypes.Error;
        }
    }
