/**
 * Currently, this is implemented using Axios and AxiosRequestConfig
 */
import { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

import anylogger from 'anylogger';

const log = anylogger('RequestQueue');

type Resolve = (value?: any) => void;
type Reject = (err?: any) => void;
type BufferItem = [AxiosRequestConfig, Resolve, Reject];

export default class RequestQueue {
    private readonly http: AxiosInstance;
    /**
     * A simple container of requests that have failed in a non-fatal way. These
     * requests can be retried/re-issued again.
     *
     * The buffer contains the retryable request, along with the resolve/reject
     * function from the promise that has been returned to the original caller.
     */
    private buffer: BufferItem[] = [];

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    /**
     * Add a request config so that it can be retried later on.
     *
     * This function assumes the caller has created a promise and
     * passed the reject/resolve function through.
     */
    public push(config: AxiosRequestConfig, resolve: Resolve, reject: Reject): void {
        log.debug('[Http] queuing \'%s\'', config.url);
        this.buffer.push([config, resolve, reject]);
    }

    /**
     * Add a request config so that it can be retried later on.
     *
     * This version will create a promise that is returned to the caller.
     *
     * TODO: Think of a better name for the function
     */
    public pushAsPromise(config: AxiosRequestConfig): AxiosPromise {
        return new Promise((resolve, reject) => {
            this.push(config, resolve, reject);
        });
    }

    /**
     *
     * @param {AxiosRequestConfig} config request config (usually from a response.config)
     * @returns {Promise.<T>}
     * @private
     */
    private retry(config: AxiosRequestConfig): AxiosPromise<any> {
        return this.http(config);
    }

    /**
     * Re-issue/submit all requests in the queue/holding buffer.
     *
     * while we dequeue all the requests, we are only returning the first. In practice,
     * there is likely to be only one when not in a provisioning mode. We should also see that
     * there is at least one. Hence, reject reject if the list is empty.
     *
     * @returns {Promise}
     */
    public retryAll(): Promise<any> {
        const requests = this.buffer.splice(0, this.buffer.length);
        if (requests.length) {
            return Promise.all(
                requests.map(([request, resolve, reject]) => {
                    log.debug('Retry \'%s\'', request.url);
                    return this.retry(request)
                        .then((result) => resolve(result))
                        .catch((err) => reject(err));
                }));
        }
        return Promise.resolve([]);
    }
}
