/**
 *
 * Re-ranking workflow state
 * =========================
 *
 * Note: there is no concept on the server if the surgical template is re-ranking or not. There is a concept around
 * business logic being:
 * 1. scheduled (record_state: 'new')
 * 2. processing (record_state: 'processing')
 * 3. completed (record_state: 'processing')
 * 4. error (record_state: 'error')
 *
 * Note:
 * By combining the state of the surgical template resource and the UI interaction with re-ranking components
 * (target dialog, stem animations), we can keep the state of the re-ranking workflow.
 *
 *                                  +--------------------+
 *                                  |                    |
 *                +---------------> |       Idle         |-----------------------------+
 *                |                 |                    |                             |
 *                |                 +--------------------+                             |
 *                |                           |                                        |
 *                |                           |  - targets dialog submitted            |
 *                |                           |                                        |
 *                |                           v                                        |
 *                |                 +--------------------+                             |
 *                |                 |                    |                             |
 *                |                 |      Started       |-----------------------------|
 *                |                 |                    |                             |
 *                |                 +--------------------+                             |
 *                |                            |                                       |
 *                |                            |  - surgical template record state     |
 *                |            +---------------|    change to 'new' or 'processing'    |
 *                |            |               |                                       |
 *                |            |               v                                       |
 *                |            |     +--------------------+                            |
 *                |            |     |                    |                            |
 *                |            |     |     Processing     |----------------------------|
 *                |            |     |                    |                            |
 *                |            |     +--------------------+                            |
 *                |            |               |                                       |
 *                |            |               |  - surgical template record state     |
 *                |            |               |    change to 'completed'              |
 *                |            |               |                                       |
 *                |            |               v                                       |
 *                |            |     +--------------------+                            |
 *                |            |     |                    |                            |
 *                |            +---->| CompletedAtServer  |----------------------------|
 *                |                  |                    |                            |
 *                |                  +--------------------+                            |
 *                |                           |                                        |
 *                |                           |   - UI animations with new data        |
 *                |                           |   are done:  the new stem was rendered |
 *                |                           |                                        |
 *                |                           v                                        |
 *                |                  +--------------------+                            |
 *                |                  |                    |                            |
 *                +------------------|   UIUpdated        |                            |
 *                |                  |                    |                            |
 *                |                  +--------------------+                            |
 *                |                                                                    |
 *                |                  +--------------------+                            |
 *                |                  |                    |                            |
 *                +------------------|      Error         |<---------------------------+
 *                                   |                    |
 *                                   +--------------------+
 *
 */
export enum ReRankingState {
    /**
     * Initial state
     */
    Idle = 'idle',
    /**
     * The targets (target leg length & offset) edit dialog was confirmed and a re-ranking is expected to be
     * triggered on the backed.
     */
    Started = 'started',
    /**
     * The client detects the business logic associated with the re-ranking process has started.
     *
     * Conditions:
     * 1. The surgical template record state changed to 'new' or 'processing'.
     * 2. The re-ranking must have been in the {@property ReRankingState.Started}
     */
    Processing = 'processing',
    /**
     * The client detect the business logic associated with the re-ranking process has finished.
     *
     * Detection:
     * The surgical template record state changed to 'completed'.
     * The re-ranking state must have been in {@property ReRankingState.Processing} or {@property ReRankingState.Started}
     *
     * Note
     * ----
     * Previous state could have been {@property ReRankingState.Started}: It is unlikely but there is a small chance
     * that the client did not picked up the transition between the record state 'new' or 'processing' and only realizes
     * that the business logic is 'completed'. This is unlikely given the re-ranking takes some times and that time
     * should be enough for the client to fetch the surgical template while it was in 'new' or 'processing'.
     */
    CompletedAtServer = 'completed-at-server',
    /**
     * This is a final state for a re-ranking process.
     *
     * A UI pure event, which signals that the planner mode changed to 'stem' and the scene was updated with the
     * latest recommended stem.
     *
     * Background:
     * This state is important from a UI point of view to orchestrate the visibility of different components and do not
     * show intermittent state until the new stem was selected.
     *
     * E.g.: The leg length/offset adjustments on the adjustment panel are shown as being 'calculated' until this is
     * done.
     */
    UpdatingScene = 'updating-scene',
    SceneUpdated = 'scene-updated',
    /**
     * The client detected an error during re-ranking.
     *
     * E.g.:
     * a. The client attempted to transition to a state without being in the right previous state
     * b. The client detected an error in the update service (PUT/GET request while re-ranking failed)
     */
    Error = 'error',
}

export function isReRankingServerSide(state: ReRankingState): boolean {
    return state === ReRankingState.Started ||
        state === ReRankingState.Processing ||
        state === ReRankingState.CompletedAtServer;
}

export function isReRanking(state: ReRankingState): boolean {
    return isReRankingServerSide(state) || state === ReRankingState.UpdatingScene;
}

export function isIdle(state: ReRankingState): boolean {
    return state === ReRankingState.Idle;
}
