
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import { navigatePathCaseManage } from '@/router/navigate';

    @Component({ components: {} })
    /**
     * A <v-menu> to be added in the top right corner of the </CaseListItem>
     *
     * This is a natural place for the user (at this point an admin) to perform individual actions on the case.
     *
     * Current Actions:
     * - Take you to the manage page so individual actions can be performed there
     */
    export default class CaseItemMenu extends Vue {
        /**
         * The plan that is being presented as a vcard
         */
        @Prop({ required: true })
        public value!: CaseRepresentation;

        /**
         * The v-model for the v-menu
         * @see {@link https://vuetifyjs.com/en/api/v-menu/}
         */
        private isMenuOpened = false;

        protected onManageCaseClicked(): void {
            this.$router.push(navigatePathCaseManage(this.value));
        }

        /**
         * Handler when the v-menu is clicked
         */
        protected async onLoadMenuOptions(): Promise<void> {
            // nothing to do for now (this is place to load data on demand if needed)
        }
    }
