import {
    ComponentOffsetRepresentation,
} from '@/lib/api/representation/case/surgical-template/common/ComponentOffsetRepresentation';
import {
    AnteversionInclinationAngleRepresentation,
} from '@/lib/api/representation/case/surgical-template/common/AnteversionInclinationAngleRepresentation';
import { DeepNonNullable } from '@/lib/base/CustomTypes';
import { NumberUtil } from '@/lib/base/NumberUtil';

/**
 * A non nullable version of the component offset representation
 * Valid for doing calculations
 */
export type CupPosition = DeepNonNullable<ComponentOffsetRepresentation>;
export type CupRotation = DeepNonNullable<AnteversionInclinationAngleRepresentation>;

/**
 * A declarative type expressing that the angles should be in **anatomic** and **degrees**, but **does not enforce it**.
 */
export type AnatomicDegreesCupRotation = CupRotation;

/**
 * A declarative type expressing that the angles should be in **anatomic** and **radians**, but **does not enforce it**.
 */
export type AnatomicRadiansCupRotation = CupRotation;

/**
 * A declarative type expressing that the angles are **radiographic** and **degrees*, but **does not enforce it **.
 */
export type RadiographicDegreesCupRotation = CupRotation;

/**
 * A declarative type expressing that the angles are **radiographic** and **radians**, but **does not enforce it **.
 */
export type RadiographicRadiansCupRotation = CupRotation;

export function isCupPosition(cupOffset: unknown): cupOffset is CupPosition {
    const asCupOffset = cupOffset as ComponentOffsetRepresentation;
    return NumberUtil.isFiniteNumber(asCupOffset.ap) &&
        NumberUtil.isFiniteNumber(asCupOffset.si) &&
        NumberUtil.isFiniteNumber(asCupOffset.ml);
}

export function isCupRotation(cupRotation: unknown): cupRotation is CupRotation {
    const asCupRotation = cupRotation as AnteversionInclinationAngleRepresentation;
    return NumberUtil.isFiniteNumber(asCupRotation.anteversion) && NumberUtil.isFiniteNumber(asCupRotation.inclination);
}
