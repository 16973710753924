/**
 * This is a documentation file of the client-side artifact representations
 */

import { LinkedRepresentation } from 'semantic-link';
import { MatrixArray16, NumberArray3 } from '@/lib/api/representation/geometry/arrays';

/**
 * Values match the case used in CaseRepresentation
 */
export enum BodySide {
    Left = 'left',
    Right = 'right',
}

export type BodySideType = BodySide.Left | BodySide.Right;

export interface AlignmentTransformRepresentation extends LinkedRepresentation {
    tmatrix: MatrixArray16;
}

export interface ResectionDistances {
    greater_trochanter: number;
    greater_trochanter_medial: number;
    lesser_trochanter: number;
}

export interface ResectionPlane {
    normal: NumberArray3;
    origin: NumberArray3;
    x: NumberArray3;
    y: NumberArray3;
}

/**
 * Anatomical coordinate system representation
 */
export interface Axis {
    vector: NumberArray3;
    position: number;
}

export interface CoordinateSystem {
    origin: NumberArray3;
    X: Axis;
    Y: Axis;
    Z: Axis;
}

export interface AnatomicalCoordinateSystem {
    origin: NumberArray3;
    ap: Axis;
    si: Axis;
    ml: Axis;
}

export enum HipCupAnteversionMode {
    /**
     * Combined mode where the anteversion angle is determined by the ACID product.
     */
    Auto = 'auto',

    /**
     * The anteversion angle is manually set by the surgeon.
     */
    Manual = 'manual',
}

export type HipCupAnteversionModeType = HipCupAnteversionMode.Auto | HipCupAnteversionMode.Manual;

/**
 * Cup anteversion mode select option (in a dropdown list)
 */
export interface SelectCupAnteversionModeOption {
    text: string;
    value: HipCupAnteversionModeType;
}
