import { Vue } from 'vue/types/vue';
import { LinkUtil, Uri } from 'semantic-link';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { PatientRepresentation } from '@/lib/api/representation/PatientRepresentation';
import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import ProjectStoreService from '@/hipPlanner/components/state/project/ProjectStoreService';
import { HipCaseRepresentation } from '@/lib/api/representation/case/HipCaseRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import { Product } from '@/lib/api/representation/ProductRepresentation';
import { UriDeconstructionUtil } from '@/components/case-plan/UrlDeconstructionUtil';

export type ProjectState = {

    // TODO: Consider to add an states instead of boolean (isLoading, isFetching, isError, etc)
    initialised: boolean;

    project: HipCaseRepresentation | null;

    surgeon: UserRepresentation | null;

    patient: PatientRepresentation | null;

    activeStudy: StudyRepresentation | null;

    automatedTemplate: HipSurgicalTemplateRepresentation | null;

    manualTemplate: HipSurgicalTemplateRepresentation | null;

    automatedPlan: PlanRepresentation | null;

    /**
     * The current manual plan.
     * Before a user approves a plan this value will be null.
     * If a user had already approved a plan, it will be populated and available.
     */
    manualPlan: PlanRepresentation | null;
}

/**
 * The global store of the project in a flat form.
 */
export default class ProjectStore {
    protected readonly _state: ProjectState = {
        initialised: false,

        project: null,

        surgeon: null,

        patient: null,

        activeStudy: null,

        automatedTemplate: null,

        manualTemplate: null,

        automatedPlan: null,

        /**
         * The current manual plan.
         * Before a user approves a plan this value will be null.
         * If a user had already approve a plan, it will be populated and available.
         */
        manualPlan: null,
    };

    private _apiGetService: ProjectStoreService;

    constructor(
        private readonly _caseUri: Uri,
        private readonly _eventBus: Vue,
        private readonly _apiResource: ApiRepresentation,
        private _apiOptions: CacheOptions) {
        this._apiGetService = new ProjectStoreService(_apiResource, _apiOptions);
    }

    public async start(): Promise<void> {
        await this.loadState();
        this._state.initialised = true;
    }

    private async loadState(): Promise<void> {
        const project = await this._apiGetService.getProject<HipCaseRepresentation>(this._caseUri);

        if (project) {
            await this._apiGetService.getInitialProjectData(project);
        }

        this.setState(project);
    }

    protected setState(project: HipCaseRepresentation | null) {
        this._state.project = project;
        this._state.surgeon = project?.surgeon ?? null;
        this._state.patient = project?.patient ?? null;
        this._state.activeStudy = project?.activeStudy ?? null;
        this._state.automatedTemplate = project?.acidSurgicalTemplate as HipSurgicalTemplateRepresentation ?? null;
        this._state.automatedPlan = project?.acidSurgicalTemplate?.currentPlan ?? null;
        this._state.manualTemplate = project?.surgicalTemplate as HipSurgicalTemplateRepresentation ?? null;
        this._state.manualPlan = project?.surgicalTemplate?.currentPlan ?? null;
    }

    public stop(): void {
        // nothing
        // TODO: cancellation
    }

    // =========================================================================================
    //
    // Getters (computed - reactive) properties to access the data in the component
    //
    //

    public get initialised(): boolean {
        return this._state.initialised;
    }

    /** TODO: This is a bit misleading (does not tell if it is loading, in error, etc) */
    protected get isLoading(): boolean {
        return !this.initialised;
    }

    public get project(): HipCaseRepresentation | null {
        return this._state.project;
    }

    public get projectId(): string {
        return UriDeconstructionUtil.pathNameOfLink(this.project, LinkRelation.self);
    }

    public get projectName(): string {
        return this.project?.name ?? '';
    }

    public get automatedTemplate(): HipSurgicalTemplateRepresentation | null {
        return this._state.automatedTemplate;
    }

    public get automatedPlan(): PlanRepresentation | null {
        return this._state.automatedPlan;
    }

    public get manualTemplate(): HipSurgicalTemplateRepresentation | null {
        return this._state.manualTemplate;
    }

    public get manualPlan(): PlanRepresentation | null {
        return this._state.manualPlan;
    }

    public get patient(): PatientRepresentation | null {
        return this._state.patient;
    }

    public get surgeon(): UserRepresentation | null {
        return this._state.surgeon;
    }

    /**
     * Get the product name of the case (project). This will be empty until the
     * case is loaded, but when then return 'hip'.
     */
    protected get projectType(): string {
        if (this.project) {
            return LinkUtil.getTitle(this.project, LinkRelation.product);
        }
        return '';
    }

    public get isHipProject(): boolean {
        return this.projectType === Product.Hip;
    }

    // =========================================================================================
    //
    // Actions
    //
    //

    public async reloadState(): Promise<void> {
        await this.loadState();
    }
}
