import { Color, ColorRepresentation, Material, ShaderMaterial, Vector3 } from 'three';
import { ViewingMode } from '@/lib/viewer/ViewingMode';
import { CrossSectionPlane } from '@/lib/planning/cross-section/CrossSectionPlane';
import { ModelVisibility, ObjectMaterial } from '@/hipPlanner/assembly/controllers/AcidPlannerModeSettings';
import AcidObject3d from '@/lib/planning/objects-3D/AcidObject3d';

export type ColoredMaterial = Material & { color: Color };

export default class MaterialUtils {
    /** Returns a random color */
    public static randomColor(): number {
        return Math.random() * 0xffffff;
    }

    public static toColor(value: unknown): Color {
        if (value instanceof Color) {
            return value.clone();
        } else if (value instanceof Vector3) {
            return new Color(value.x, value.y, value.z);
        } else if (typeof value === 'number') {
            return new Color(value);
        } else {
            throw new Error(`Cannot convert value of type ${typeof value} to color`);
        }
    }

    /**
     * Get material color
     */
    public static getColor(material: Material): Color {
        if (material instanceof ShaderMaterial) {
            if (material.uniforms?.uColor?.value) {
                return this.toColor(material.uniforms.uColor?.value);
            } else if (material.uniforms.diffuse?.value) {
                return this.toColor(material.uniforms.diffuse.value);
            } else {
                throw new Error('Trying to get color of shader material without color uniform');
            }
        } else if (this.hasColor(material)) {
            return material.color;
        } else {
            throw new Error('Trying to get color of material without color property');
        }
    }

    /**
     * Set material color
     *
     * Supported Threejs material types:
     *  - Material, MeshBasicMaterial, MeshPhongMaterial, ShaderMaterial
     */
    public static setColor(material: Material | Material[], color: ColorRepresentation): void {
        color = new Color(color);
        if (!Array.isArray(material)) {
            //
            // Changing material color can be found in different places,
            // so check where the property is located and change it accordingly
            //
            if (material instanceof ShaderMaterial) {
                //
                // uColor value is a Vector3, not Color
                //
                material.uniforms?.uColor?.value?.set(color.r, color.g, color.b);
                material.uniforms?.diffuse?.value?.setRGB(color.r, color.g, color.b);
                // let the renderer know that the material uniforms have been updated
                material.uniformsNeedUpdate = true;
            } else if (this.hasColor(material)) {
                material.color = color;
            } else {
                throw new Error('Trying to set color of material without color property');
            }
            // let the renderer know that the material has been updated
            material.needsUpdate = true;
        } else {
            throw new Error('Cannot update material color. Materials array currently not supported');
        }
    }

    /**
     * Add the cross-section plane to the material of the object
     *
     * A clipping plane in the material is how the cross-section plane is done
     */
    public static addCrossSectionToAcidMeshMaterial(
        acidObject: AcidObject3d, crossSectionPlane: CrossSectionPlane): void {
        acidObject.addCrossSectionPlaneToMaterial(crossSectionPlane.plane, ViewingMode.Normal);
        acidObject.addCrossSectionPlaneToMaterial(crossSectionPlane.plane, ViewingMode.Xray);
    }

    public static applyObjectMaterial(
        object: AcidObject3d, objectMaterial: ObjectMaterial, isXrayMode: boolean): void {
        const visibility = objectMaterial.visibility;

        // Show or hide the back-side mesh
        if (!isXrayMode && visibility === ModelVisibility.FrontSideTransparent) {
            object.showBacksideMesh();
        } else {
            object.hideBacksideMesh();
        }

        // Determine object transparency.
        // Xray objects are always transparent in nature, and must NOT use depth writing, in order to "fake" xray vision
        const transparent =
            isXrayMode ||
            visibility === ModelVisibility.Transparent ||
            visibility === ModelVisibility.FrontSideTransparent;

        // Set object visibility
        object.theObject.visible = visibility !== ModelVisibility.Hidden;

        // Set material properties
        const material = object.theObject.material as Material;
        material.transparent = transparent;
        material.depthWrite = !transparent;

        // Set material opacity
        const opacity = material.transparent ? objectMaterial.opacity ?? 1.0 : 1.0;
        if (material instanceof ShaderMaterial && material.uniforms.opacity) {
            material.uniforms.opacity.value = opacity;
        } else {
            material.opacity = opacity;
        }
    }

    private static hasColor(material: Material): material is ColoredMaterial {
        return 'color' in material;
    }
}
