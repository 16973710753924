import { LinkedRepresentation } from 'semantic-link';

export enum OrganisationNames {
    Formus = 'Formus Labs',
    ZB = 'Zimmer Biomet',
}

export interface OrganisationRepresentation extends LinkedRepresentation {
    /**
     * The organization name
     */
    name: string;
}
