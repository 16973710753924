import { Object3D, Vector3 } from 'three';

/**
 * Utility to wrap our knowledge of three js around a {@link Object3DUtil}
 *
 * Convention: When a three-js method updates the input, we should have a utility that does the same but
 * in an immutable way.
 */
export class Object3DUtil {
    /**
     * Update the object, its parents and children matrix world;
     */
    public static updateMatrixWorldIncludingParentsAndChildren(object: Object3D): void {
        object.updateWorldMatrix(true, false);
    }

    /**
     * Update the object and its parents matrix world. It does not update the children.
     */
    public static updateMatrixWorldIncludingParents(object: Object3D): void {
        object.updateWorldMatrix(true, false);
    }

    /**
     * Update the object and children matrix world. It does not update the parents.
     */
    public static updateMatrixWorldIncludingChildren(object: Object3D): void {
        object.updateWorldMatrix(false, true);
    }

    /**
     * Update the object and children matrix world. It does not update the parents.
     */
    public static updateLocalMatrix(object: Object3D): void {
        object.updateMatrix();
    }

    /**
     * @returns a new vector from local object space to the world space.
     * Note: This method ensures the matrix world is up to date.
     */
    public static localToWorld(object: Object3D, vector: Vector3): Vector3 {
        Object3DUtil.updateMatrixWorldIncludingParents(object);
        return object.localToWorld(vector.clone());
    }

    /**
     * @returns a new vector from world space to the local space.
     * Note: This method ensures the matrix world is up to date.
     */
    public static worldToLocal(object: Object3D, vector: Vector3): Vector3 {
        Object3DUtil.updateMatrixWorldIncludingParents(object);
        return object.worldToLocal(vector.clone());
    }

    /**
     * Update the object local matrix, the object world matrix and children matrix world.
     * It does not update the parents.
     */
    public static updateMatricesIncludingChildren(object: Object3D): void {
        this.updateMatrixWorldIncludingChildren(object);
        this.updateLocalMatrix(object);
    }

    /**
     * Remove all children from an object
     */
    public static removeChildren(object: Object3D): void {
        //
        // Reverse loop to remove all children.
        // @see {@link https://stackoverflow.com/a/35061099}
        //
        for (let i = object.children.length; i > 0; i--) {
            const child = object.children[i - 1];
            object.remove(child);
        }
    }

    public static isVisible(object: Object3D): boolean {
        return object.visible;
    }

    /**
     * Check if object ancestors (can be parent and higher) are visible.
     */
    public static areAncestorsVisible(object3d: Object3D): boolean {
        // assume that all ancestors are visible at start
        let allVisible = true;

        object3d.traverseAncestors((ancestor) => {
            if (!ancestor.visible) {
                allVisible = false;
            } // else check the next object
        });

        return allVisible;
    }
}
