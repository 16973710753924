
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { LinkedRepresentation, LinkUtil } from 'semantic-link';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { RelationshipType } from 'semantic-link/lib/filter';

    /**
     * A simple <a > tag link for a representation.
     * - Opens in a different target.
     * - Provides optional display of the tooltip.
     * - Add underline style on hover.
     *
     * Done as a separate component to avoid duplication of code regarding styles
     * @see https://stackoverflow.com/questions/16839284/underline-a-tag-when-hovering/16852308
     */
    @Component
    export default class Hyperlink extends Vue {
        @Prop({ required: true })
        public value!: LinkedRepresentation | null;

        @Prop({ required: false, default: () => LinkRelation.canonicalOrSelf })
        public rel!: RelationshipType;

        @Prop({ required: false, default: true })
        public showTooltip!: boolean;

        @Prop({ required: false, default: null })
        public text?: string | null;

        protected get uri(): string | null {
            return this.value ? LinkUtil.getUri(this.value, this.rel) || null : null;
        }

        protected get anchorText(): string | null {
            return this.text || this.uri;
        }
    }
