import { LinkedRepresentation } from 'semantic-link';

export interface FemoralRankingVersion1 {
    version: 1,
    context: unknown,
    logs: string
}

/**
 * A guard to detect whether the object is a {@link FemoralRankingVersion1}
 */
export function instanceOfFemoralRankingVersion1(item: unknown): item is FemoralRankingVersion1 {
    const asRankingVersion1 = item as FemoralRankingVersion1;
    return asRankingVersion1 !== undefined &&
        asRankingVersion1.version === 1 &&
        asRankingVersion1.context !== undefined &&
        asRankingVersion1.logs !== undefined;
}

export interface FemoralRankingRepresentation extends LinkedRepresentation {
    raw_: FemoralRankingVersion1 | unknown;
}
