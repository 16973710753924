
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import PlanDownloadReportButton from '@/components/case-plan/PlanDownloadReportButton.vue';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';
    import {trackEvent} from '@/analytics/amplitude';
    import {caseEventProperties, planEventProperties} from '@/analytics/event';

    @Component({ components: { PlanDownloadReportButton } })
    export default class PlanDownloadReportAutomatedButton extends Vue {
        @InjectReactive() planStore!: PlanReportStore;

        /** whether the report download is loading data */
        protected isDownloading = false;

        /**
         * Turn a button click handler into a downlink link ('A' element) to allow the
         * user to download a file.
         *
         * @see {@link https://stackoverflow.com/a/53775165}
         * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a}
         */
        @IsLoading('isDownloading')
        protected async onDownloadReport(): Promise<void> {
            await this.planStore.downloadReport();
            trackEvent('plan_downloaded', {
                ...caseEventProperties(this.planStore.project),
                ...planEventProperties(this.planStore.planId, this.planStore.isAutomatedPlan),
            });
        }
    }
