
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
    import { SpinopelvicCalculations, SpinopelvicWarnings, TableRow } from '@/components/spinopelvic/types';
    import AlertIcon from '@/components/ui/icons/AlertIcon.vue';
    import AppSimpleTable from '@/components/basic/AppSimpleTable.vue';
    import {
        LUMBAR_LORDOSIS_LIMIT,
        PFA_LOWER_LIMIT,
        PFA_UPPER_LIMIT,
        STANDING_PELVIC_TILT_LIMIT,
    } from '@/components/spinopelvic/conts';
    import { mapStores } from 'pinia';
    import { SpinopelvicStore, useSpinopelvicStore } from '@/stores/spinopelvic/store';
    import { formatAngle } from '@/lib/filters/format/formatAngle';

    @Component({
        components: {
            AlertIcon,
            AppSimpleTable,
        },
        computed: {
            ...mapStores(useSpinopelvicStore),
        },
    })
    export default class SpinopelvicWarningsTable extends Vue {
        @Prop({required: true})
        spinopelvicCalculations!: SpinopelvicCalculations;

        @Prop({required: true})
        spinopelvicWarnings!: SpinopelvicWarnings;

        declare protected spinopelvicStore: SpinopelvicStore;

        protected warnings: Array<TableRow> = [];

        protected mounted() {
            this.updateSpinopelvicWarnings();
        }

        @Watch('spinopelvicCalculations')
        @Watch('spinopelvicWarnings')
        protected updateSpinopelvicWarnings(): void {
            this.warnings = [];

            if (this.spinopelvicWarnings.standingPTWarning) {
                this.warnings.push({
                    label: 'Spinopelvic Tilt',
                    value: `> ${STANDING_PELVIC_TILT_LIMIT}°`,
                });
            }

            if (this.spinopelvicWarnings.lumbarLordosisWarning) {
                this.warnings.push({
                    label: 'Lumbar Lordosis',
                    value: `< ${LUMBAR_LORDOSIS_LIMIT}°`,
                });
            }

            if (this.spinopelvicWarnings.pelvicFemoralAngleWarning) {
                let warning = '';
                if (this.spinopelvicStore.measurements.pelvicFemoralAngle < PFA_LOWER_LIMIT) {
                    warning = `< ${PFA_LOWER_LIMIT}°`;
                } else if (this.spinopelvicStore.measurements.pelvicFemoralAngle > PFA_UPPER_LIMIT) {
                    warning = `> ${PFA_UPPER_LIMIT}°`;
                }
                this.warnings.push({
                    label: 'Pelvic Femoral Angle',
                    value: warning,
                });
            }

            if (this.spinopelvicWarnings.inclinationLowerWarning) {
                this.warnings.push({
                    label: 'Cup Inclination',
                    value: `< ${formatAngle(this.spinopelvicWarnings.limits.inclinationLowerLimit, 2)}`,
                });
            }

            if (this.spinopelvicWarnings.inclinationUpperWarning) {
                this.warnings.push({
                    label: 'Cup Inclination',
                    value: `> ${formatAngle(this.spinopelvicWarnings.limits.inclinationUpperLimit, 2)}`,
                });
            }

            if (this.spinopelvicWarnings.anteversionLowerWarning) {
                this.warnings.push({
                    label: 'Cup Anteversion',
                    value: `< ${formatAngle(this.spinopelvicWarnings.limits.anteversionLowerLimit, 2)}`,
                });
            }

            if (this.spinopelvicWarnings.anteversionUpperWarning) {
                this.warnings.push({
                    label: 'Cup Anteversion',
                    value: `> ${formatAngle(this.spinopelvicWarnings.limits.anteversionUpperLimit, 2)}`,

                });
            }
        }

    }
