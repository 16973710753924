
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import QRCodeLoader from '@/components/case-plan/actions/QRCodeLoader.vue';
    import PlanDownloadReportAutomatedButton from '@/components/case-plan/PlanDownloadReportAutomatedButton.vue';
    import PlanDownloadReportUserButton from '@/components/case-plan/PlanDownloadReportUserButton.vue';
    import PlanDownloadModelsButton from '@/components/case-plan/PlanDownloadModelsButton.vue';
    import ProjectStore from '@/hipPlanner/components/state/project/ProjectStore';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';

    @Component({
        components: {
            PlanDownloadModelsButton,
            PlanDownloadReportAutomatedButton,
            PlanDownloadReportUserButton,
            QRCodeLoader,
        },
    })
    export default class PlanItemActions extends Vue {
        @InjectReactive() store!: ProjectStore;
        @InjectReactive() planStore!: PlanReportStore;

        protected get isCreating(): boolean {
            return this.planStore.isCreating;
        }

        protected get isCreationBroken(): boolean {
            return this.planStore.isCreationBroken;
        }

        protected get hasReportLink(): boolean {
            return this.planStore.hasReportLink;
        }

        /**
         * The sub-title for the v-card is the technical identifier for the plan. The theory here
         * is that this is the specific unique identifier for the plan that is as short as possible that
         * can be used to discuss the plan unique over support channels (e.g. the phone).
         */
        protected get planIdentifier(): string {
            const surgicalTemplateHistoryId = this.planStore.templateHistoryId;
            const planId = this.planStore.planId;
            if (planId && surgicalTemplateHistoryId) {
                return `${surgicalTemplateHistoryId}.${planId}`;
            }

            return '';
        }
    }
