
    import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

    @Component
    export default class AppInputText extends Vue {
        @VModel({type: String}) text!: string;
        @Prop(String) readonly placeholder?: string;
        @Prop(String) readonly unit?: string;
        @Prop(String) readonly error?: string;
        @Prop(Boolean) readonly disabled?: boolean;
        @Prop(Boolean) readonly nonEditable?: boolean;
    }
