import numeral from 'numeral';
import { NumberUtil } from '@/lib/base/NumberUtil';

/**
 * Filter floats to 1 decimal place
 */
export function formatNumber(value: number | undefined, precision = 0): string {
    if (NumberUtil.isFiniteNumber(value)) {
        return numeral(value).format('0.' + '0'.repeat(precision));
    } else {
        return '--';
    }
}
