
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import {
        HipComponentsCatalogRepresentation,
    } from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import HipSurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateResource';
    import { StemOptionsFactory } from '@/hipPlanner/components/form/panels/stem/StemOptionsFactory';
    import {
        HipStemCatalogComponentRepresentation,
    } from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
    import ArrowFromTo, { RowChange } from '@/components/case-manage/surgical-template/history/row/ArrowFromTo.vue';
    import {
        HipSurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
    import {
        HipStemHeadCatalogComponentRepresentation,
    } from '@/lib/api/representation/catalog/hip/HipStemHeadCatalogComponentRepresentation';
    import {
        anyStemAssemblyChange,
        hasHeadUriChanged,
        hasStemUriChanged,
    } from '@/lib/api/resource/case/surgical-template/hipTemplateComparison';
    import { mapState } from 'pinia';
    import { useHipCaseStore } from '@/hipPlanner/stores/case/hipCaseStore';

    /**
     * A simple ui component with to display a from -> to information.
     * The 'from' can be strike through.
     *
     * e.g.:
     * 'processing' -> completed
     * */
    @Component({
        methods: { anyStemAssemblyChange },
        components: { ArrowFromTo },
        computed: {
            ...mapState(useHipCaseStore, ['componentsCatalog']),
        },
    })
    export default class StemChanges extends Vue {
        @Prop({ required: true, validator: notNilValidator('current') })
        private current!: HipSurgicalTemplateRepresentation;

        @Prop({ required: true, validator: notNilValidator('previous') })
        private previous!: HipSurgicalTemplateRepresentation;

        private currentStem: HipStemCatalogComponentRepresentation | null = null;
        private previousStem: HipStemCatalogComponentRepresentation | null = null;
        private currentStemHead: HipStemHeadCatalogComponentRepresentation | null = null;
        private previousStemHead: HipStemHeadCatalogComponentRepresentation | null = null;

        protected componentsCatalog!: HipComponentsCatalogRepresentation;

        @IsLoading('isLoading')
        protected mounted(): void {
            if (hasStemUriChanged(this.current, this.previous)) {
                this.currentStem = HipSurgicalTemplateResource.getCurrentStem(
                    this.current, this.componentsCatalog, this.$apiOptions);
                this.previousStem = HipSurgicalTemplateResource.getCurrentStem(
                    this.previous, this.componentsCatalog, this.$apiOptions);
            }

            if (hasHeadUriChanged(this.current, this.previous)) {
                this.currentStemHead = HipSurgicalTemplateResource.getCurrentStemHead(
                    this.current, this.componentsCatalog, this.$apiOptions);
                this.previousStemHead = HipSurgicalTemplateResource.getCurrentStemHead(
                    this.previous, this.componentsCatalog, this.$apiOptions);
            }
        }

        protected get changes(): (RowChange | null)[] {
            return [
                hasStemUriChanged(this.current, this.previous) ?
                    { label: 'Stem', from: this.fromStem, to: this.toStem } :
                    null,
                hasHeadUriChanged(this.current, this.previous) ?
                    { label: 'Head', from: this.fromHead, to: this.toHead } :
                    null,
            ];
        }

        protected get fromStem(): string | null {
            return this.previousStem ? StemOptionsFactory.makeStemLabel(this.previousStem) : null;
        }

        protected get toStem(): string | null {
            return this.currentStem ? StemOptionsFactory.makeStemLabel(this.currentStem) : null;
        }

        protected get fromHead(): string | null {
            return this.previousStemHead ? this.formatHeadOffset(this.previousStemHead) : null;
        }

        protected get toHead(): string | null {
            return this.currentStemHead ? this.formatHeadOffset(this.currentStemHead) : null;
        }

        private formatHeadOffset(head: HipStemHeadCatalogComponentRepresentation): string {
            return StemOptionsFactory.formatHeadOffset(head);
        }
    }
