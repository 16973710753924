import ArrayUtil from '@/lib/collection/ArrayUtil';
import { zip } from 'ramda';
import { NumberUtil } from '@/lib/base/NumberUtil';
import { formatNumberSignPrecision } from '@/lib/filters/format/formatNumberSignPrecision';

const TICK_CHARACTER = '✓';
const CROSS_CHARACTER = '✗';

/**
 * | Precision             | Tolerance  |
 * | :--------------- | :------------------ |
 * | 1   | 0.1 |
 * | 2   | 0.01 |
 * | 3   | 0.001 |
 * | 4   | 0.0001 |
 */
function toleranceFromPrecision(precision: number): number {
    return 1 / Math.pow(10, precision + 1);
}

/**
 * Format the differences between each member of two arrays.
 *
 * Note:
 * 1. Precision is 2 by default:
 * ```
 * 1. formatArrayNumberDiff([1, 2, 3], [1, 2, 4]) => `[✓, ✓, ✗ (+1.00)]`.
 * ```
 *
 * 1. Only the differences are displayed
 * ```
 * 1. formatArrayNumberDiff([[1, 2, 3], [1, 2, 3.001], 2]) =>  `[✓, ✓, ✓]`
 * 1. formatArrayNumberDiff([[1, 2, 3], [1, 2, 3.001], 3]) => `[✓, ✓, ✗ (0.001)]`
 *
 * Strategy:
 * The values are rounded first to the level or precision required.
 * Then they are compared using a tolerance which is calculated based on the precision.
 */
export const formatArrayNumberDiff = function(
    value: number[], otherValue: number[], precision = 2): string {
    if (ArrayUtil.isArray(value) && ArrayUtil.isArray(otherValue)) {
        const values = zip(value, otherValue);
        const tolerance = toleranceFromPrecision(precision);
        const formattedDiffs = values.map((pair: [number, number]): string => {
            const firstValue = NumberUtil.roundFloat(pair[0], precision);
            const secondValue = NumberUtil.roundFloat(pair[1], precision);
            if (NumberUtil.areEqualWithinTolerance(firstValue, secondValue, tolerance)) {
                return TICK_CHARACTER;
            } else {
                return `${CROSS_CHARACTER} (${formatNumberSignPrecision(secondValue - firstValue, precision)})`;
            }
        });
        return `[${formattedDiffs.join(', ')}]`;
    } else {
        return '[--]';
    }
};
