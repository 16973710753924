enum Name {
    // Auth views
    ResetPassword = 'set-password',
    ForgotPassword = 'send-password-reset',

    // Global views
    Home = 'home',
    About = 'about',
    Privacy = 'privacy',
    Terms = 'terms',

    // User Profile views
    ProfileHipSettings = 'profile-hip-settings',

    // Case views
    CaseSettings = 'case-settings',
    CaseManage = 'case-manage',
    CaseCatStackViewer = 'case-dicom',
    CaseHipPlanner = 'case-segmented-model',
    CasePlan = 'case-plan',

    // Administration Views
    AdministerCase = 'administer-case',
    Organisation = 'organisation',

}

export default Name;
