import { defineStore } from 'pinia';
import { State } from '@/stores/spinopelvic/types';
import { spinopelvicFeatureFlag } from '@/featureFlags/spinopelvic';
import { loadProjectSpinopelvic, saveProjectSpinopelvic } from '@/stores/spinopelvic/requests';
import _ from 'lodash';
import { stateFromResponse } from '@/stores/spinopelvic/mapping';
import { isPopulated, isValid, validators } from '@/stores/spinopelvic/validation';
import anylogger from 'anylogger';
import { emptySpinopelvic } from '@/stores/spinopelvic/initialStates';
import { SpinopelvicMeasurements } from '@/components/spinopelvic/types';

export const log = anylogger('SpinopelvicStore');


export const useSpinopelvicStore = defineStore('spinopelvic', {
    state: (): State => ({
        featureEnabled: spinopelvicFeatureFlag(),
        isDirty: false,
        isReady: false,
        isLoading: false,
        isSubmitting: false,
        isSubmitted: false,
        hasError: false,
        projectUri: '',
        spinopelvic: {...emptySpinopelvic},
        original: {...emptySpinopelvic},
    }),
    getters: {
        isValid(state: State): boolean {
            return isValid(state.spinopelvic);
        },
        hasChanged(state: State): boolean {
            return this.isEnabled ? !_.isEqual(state.original, state.spinopelvic) : false;
        },
        inputFieldErrorMsg(): string {
            return 'Please provide a valid number (up to 2dp) and all hip-spine input must be entered';
        },
        isEnabled(state: State): boolean {
            // Using a computed value here so we can add the ability turn on/off feature via dev query params if needed
            // const spinopelvicQuery = this.$router.currentRoute.query['spinopelvic'] === 'true' || false;
            return state.featureEnabled;
        },
        hasData(state: State): boolean {
            return isPopulated(state.original.standingPelvicTilt) && isPopulated(state.original.pelvicFemoralAngle) &&
                isPopulated(state.original.lumbarLordosis) && isPopulated(state.original.sacralSlope) &&
                isPopulated(state.original.pelvicTilt);
        },
        measurements(state: State): SpinopelvicMeasurements {
            return {
                standingPelvicTilt: Number(state.original.standingPelvicTilt),
                sacralSlope: Number(state.original.sacralSlope),
                lumbarLordosis: Number(state.original.lumbarLordosis),
                pelvicFemoralAngle: Number(state.original.pelvicFemoralAngle),
                pelvicTilt: Number(state.original.pelvicTilt),
            };
        },
    },
    actions: {
        validateField(field: string, error: string) {
            if (validators[field] === undefined) {
                throw new Error(`no validator for ${field}`);
            }
            if (!this.isDirty) {
                return '';
            }

            return validators[field](this.spinopelvic) ? '' : error;
        },
        validateState(): boolean {
            if (!this.isValid ) {
                this.isDirty = true;
                return false;
            }
            return true;
        },
        async load(projectUri: string): Promise<void> {
            if (this.isEnabled) {
                this.isDirty = false;
                this.isLoading = true;
                this.projectUri = projectUri;

                try {
                    const response = await loadProjectSpinopelvic(this.$http, this.projectUri);

                    if (response !== null) {
                        this.spinopelvic = stateFromResponse(response);
                        this.$patch({
                            original: {...this.spinopelvic},
                        });
                    } else {
                        this.$patch({
                            spinopelvic: {...emptySpinopelvic},
                            original: {...emptySpinopelvic},
                        });
                    }

                    this.isReady = true;
                } catch (err){
                    log.error('Error: Failed to load spinopelvic resource: %o', err);
                    this.hasError = true;
                    throw new Error('Failed to load spinopelvic measurements');
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async save(): Promise<void> {
            if (this.isEnabled) {
                this.isSubmitting = true;
                this.isSubmitted = false;
                try {
                    if (!this.validateState()) {
                        this.hasError = true;
                        this.isSubmitting = false;
                        log.warn('There are invalid input fields. Save action is not performed.');
                        return;
                    }

                    await saveProjectSpinopelvic(this.$http, this.projectUri, this.spinopelvic);

                    this.$patch({
                        original: {...this.spinopelvic},
                    });

                    this.isDirty = false;
                    this.hasError = false;
                    this.isSubmitted = true;
                } catch (err) {
                    log.error('Error: Failed to update spinopelvic resource: %o', err);
                    this.hasError = true;
                    throw new Error('Failed to save spinopelvic measurements');
                } finally {
                    this.isSubmitting = false;
                }
            }
        },
    },
});

export type SpinopelvicStore = ReturnType<typeof useSpinopelvicStore>;
