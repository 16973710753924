import { Line3, Matrix4, Vector3 } from 'three';
import { Face3Positions } from '@/lib/viewer/component-coverage/BufferGeometryUtil';
import { applyMatrix4 } from '@/lib/base/ThreeUtil';

export interface Face3Lines {
    lineAB: Line3;
    lineBC: Line3;
    lineCA: Line3;
}

/**
 * There is not concept of Face on three-js since version 126 Utility.
 */
export class FaceUtil {
    /**
     * @returns a new {@link Face3Positions} with the matrix applied to each of the vertices.
     */
    public static applyMatrix4(facePositions: Face3Positions, matrix: Matrix4): Face3Positions {
        return facePositions.map((vertex: Vector3): Vector3 => applyMatrix4(matrix, vertex)) as Face3Positions;
    }

    /**
     * Make line objects from an object's face position vectors
     */
    public static makeLines(facePositions: Face3Positions): Face3Lines {
        const [a, b, c] = facePositions;

        // Make lines out of the three face points
        const lineAB = new Line3(a, b);
        const lineBC = new Line3(b, c);
        const lineCA = new Line3(c, a);
        return { lineAB, lineBC, lineCA };
    }

    /**
     * @returns the face centroid
     * @see {@link https://brilliant.org/wiki/triangles-centroid/}
     */
    public static getCentroid(positions: Face3Positions): Vector3 {
        const [v0, v1, v2] = positions;
        const faceCentroid = new Vector3(0, 0, 0);
        faceCentroid.set(0, 0, 0);
        faceCentroid.add(v0);
        faceCentroid.add(v1);
        faceCentroid.add(v2);
        faceCentroid.divideScalar(3);
        return faceCentroid;
    }
}
