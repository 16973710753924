
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
    import PlanDownloadReportUserWarning from '@/components/case-plan/PlanDownloadReportUserWarningDialog.vue';
    import PlanDownloadReportButton from '@/components/case-plan/PlanDownloadReportButton.vue';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import { LinkUtil } from 'semantic-link';
    import LinkRelation from '@/lib/api/LinkRelation';
    import {
        HipSurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';
    import { isCreatedByTemplate } from '@/lib/api/representation/case/plan/planRepresentationUtil';
    import {trackEvent} from '@/analytics/amplitude';
    import {caseEventProperties, planEventProperties} from '@/analytics/event';

    @Component({
        components: {
            PlanDownloadReportButton,
            PlanDownloadReportUserWarning,
        },
    })
    export default class PlanDownloadReportUserButton extends Vue {
        @InjectReactive() planStore!: PlanReportStore;

        /** The latest template on the case for the context in where this plan exist (either 'automated' or 'manual') */
        @InjectReactive()
        public latestTemplate!: HipSurgicalTemplateRepresentation | null;

        protected debug = false;
        protected showWarningDialog = false;
        protected isDownloading = false;

        protected async onDownloadReportIntent(): Promise<void> {
            if (this.currentPlanWasCreatedByLatestUserSurgicalTemplate) {
                await this.onDownloadReport();
            } else {
                this.showWarningDialog = true;
            }
        }

        protected async onDownloadFromWarningDialog(): Promise<void> {
            this.closeWarningDialog();
            await this.onDownloadReport();
        }

        private closeWarningDialog(): void {
            this.showWarningDialog = false;
        }

        /**
         * Turn a button click handler into a downlink link ('A' element) to allow the
         * user to download a file.
         *
         * @see {@link https://stackoverflow.com/a/53775165}
         * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a}
         */
        @IsLoading('isDownloading')
        protected async onDownloadReport(): Promise<void> {
            await this.planStore.downloadReport();
            trackEvent('plan_downloaded', {
                ...caseEventProperties(this.planStore.project),
                ...planEventProperties(this.planStore.planId, this.planStore.isAutomatedPlan),
            });
        }

        protected get currentPlanWasCreatedByLatestUserSurgicalTemplate(): boolean {
            if (this.latestTemplate) {
                if (this.plan) {
                    return isCreatedByTemplate(this.plan, this.latestTemplate);
                }
            }

            return false;
        }

        protected get surgicalTemplateCanonical(): string | undefined {
            return this.latestTemplate ? LinkUtil.getUri(this.latestTemplate, LinkRelation.canonical) : '';
        }

        protected get surgicalTemplatePlan(): string | undefined {
            return this.latestTemplate ? LinkUtil.getUri(this.latestTemplate, LinkRelation.currentPlan) : '';
        }

        protected get planSelf(): string | undefined {
            return this.plan ? LinkUtil.getUri(this.plan, LinkRelation.self) : '';
        }

        protected get planParent(): string | undefined {
            return this.plan ? LinkUtil.getUri(this.plan, LinkRelation.up) : '';
        }

        protected get planReport(): string | undefined {
            return this.plan ? LinkUtil.getUri(this.plan, LinkRelation.report) : '';
        }

        protected get plan(): PlanRepresentation | null {
            return this.planStore.plan;
        }
    }
