import {
    MeasurementGroupRepresentation,
} from '@/lib/api/representation/case/measurements/MeasurementGroupRepresentation';
import { Link } from 'semantic-link';

export class MeasurementsGroupRepresentationUtil {
    public static belongsToModel(group: MeasurementGroupRepresentation, link: Link): boolean {
        const models = group.models;

        return (models || []).some((modelLink: Link) => {
            return modelLink.rel === link.rel && modelLink.href === link.href;
        });
    }
}
