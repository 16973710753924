
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import {
        SurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
    import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
    import {
        FemoralRankingRepresentation,
    } from '@/lib/api/representation/case/surgical-template/hip/femoral/FemoralRankingRepresentation';
    import FemoralRanking from '@/components/case-manage/surgical-template/ranking/Ranking.vue';

    /**
     * A loader for measurements in the context of a surgical template
     */
    @Component({ components: { FemoralRanking } })
    export default class SurgicalTemplateRankingLoader extends Vue {
        @Prop({ required: true })
        public readonly value!: SurgicalTemplateRepresentation;

        protected ranking: FemoralRankingRepresentation | null = null;

        /** whether the main list of plans (and the project) is being loaded */
        private loading = true;

        @IsLoading('loading')
        protected async onIntersect(
            entries: IntersectionObserverEntry[], observer: IntersectionObserver, isIntersecting: boolean): Promise<void> {
            if (isIntersecting) {
                if (this.value) {
                    // if the surgical template has a plan, then load it (this is a conditional get)
                    this.ranking = await SurgicalTemplateResource.getRanking(
                        this.value, { ...this.$apiOptions, forceLoad: true });
                }
            }
        }
    }
