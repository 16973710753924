import numeral from 'numeral';
import { NumberUtil } from '@/lib/base/NumberUtil';
import { withZeroSignedFix } from '@/lib/filters/format/withZeroSignedFix';

/**
 * Format an a number as an angle
 *
 * E.g:
 * 38.33333 => formatLengthSign(38.33333, 1) = +38.3 mm
 * 38.33333 => formatLengthSign(-38.33333, 2) = -38.33 mm
 * 0 => formatLengthSign(-38.33333, 2) = 0 mm
 * 0.23 => formatLengthSign(-38.33333, 2) = +0.23 mm
 * -0.23 => formatLengthSign(-38.33333, 2) = -0.23 mm
 *
 * Note:
 * Accounts for bug described in https://github.com/adamwdraper/Numeral-js/issues/113.
 * The bug is described as solved on branch 2.1.0, but seems it stop being maintained on 2016.
 * @see https://github.com/adamwdraper/Numeral-js/compare/feature/2.1.0.
 */
export const formatLengthSign = function(
    value: number | undefined | null, precision = 0): string {
    if (NumberUtil.isFiniteNumber(value)) {
        const formattedLength = numeral(value).format('+0.' + '0'.repeat(precision));

        return withZeroSignedFix(formattedLength) + ' mm';
    } else {
        return '--';
    }
};
