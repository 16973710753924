
    import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
    import { DicomGroups, DicomSeries } from '@/lib/dicom/DicomSeries';
    import { ScanUploadState } from '@/components/case-settings/scan/workflow/ScanUploadState';
    import MessageLevel from '@/components/case-settings/MessageLevel.vue';
    import ScanUploadService, {
        ValidatedResult,
    } from '@/components/case-settings/scan/workflow/ScanUploadService';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import { DicomUploadTask } from '@/components/case-settings/scan/workflow/DicomUploadTask';

    enum Events {
        Cancel = 'cancel',
        Submit = 'submit',
    }

    /**
     * This the upload confirmation/status dialog. This dialog will provide progress while the
     * DICOM files are parsed, collated and uploaded.
     *
     * Unlike the other dialogs in this series the activation is based on a drag-n-drop event, rather
     * than a button press. This component should have the drag-n-drop area in an activator slot.
     */
    @Component({ components: { MessageLevel } })
    export default class ScanUploadStatusDialog extends Vue {
        @Prop({ required: true, validator: notNilValidator('value') })
        public value!: ValidatedResult;

        @Prop({ required: true, validator: notNilValidator('state') })
        public uploadState!: ScanUploadState;

        /** An optional error message */
        protected error = '';

        @InjectReactive() private uploadService!: ScanUploadService | null;

        protected created(): void {
            // nothing to do
        }

        protected get seriesCount(): number {
            return this.groups ? Object.keys(this.groups).length : 0;
        }

        protected get candidateSeries(): DicomSeries[] {
            return this.groups ? Object.values(this.groups).filter((g) => !g.isExcluded) : [];
        }

        protected get isVisible(): boolean {
            return this.isWaitingForUserConfirmation || this.isUploadingData || this.isFailed;
        }

        protected get isWaitingForUserConfirmation(): boolean {
            return this.uploadState === ScanUploadState.WaitingForConfirmation;
        }

        /** * Whether the 'upload' button shows a busy spinner. */
        protected get isUploadingData(): boolean {
            return this.uploadState === ScanUploadState.UploadingData;
        }

        /** * Whether the 'upload' button shows a busy spinner. */
        protected get isFailed(): boolean {
            return this.uploadState === ScanUploadState.Failed;
        }

        protected onCancel(): void {
            this.clear();
            this.$emit(Events.Cancel);
        }

        protected onSubmit(): void {
            this.$emit(Events.Submit);
        }

        /** * cleanup the dialog. */
        private clear(): void {
            this.error = '';
        }

        protected get groups(): DicomGroups {
            return this.value.groups;
        }

        protected get uploadTask(): DicomUploadTask | null {
            return this.uploadService?.currentUploadTask ?? null;
        }
    }
