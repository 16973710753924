export type TooltipPosition = { [key in 'bottom' | 'top' | 'left' | 'right']?: boolean };

/**
 * An interface that represents partially a vuetify Tooltip
 *
 * see {@link https://vuetifyjs.com/en/api/v-tooltip/}
 */
export interface Tooltip {
    text: string;
    position: TooltipPosition;
}

/**
 * A guard to detect whether the object is a {@link Tooltip}
 */
export function instanceOfTooltip(item: unknown): item is Tooltip {
    const asTooltip = item as Tooltip;
    return asTooltip.text !== undefined && asTooltip.position !== undefined;
}
