import { CacheOptions, update } from '@/lib/semanticNetworkMigrationUtils';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import {
    anyCupAssemblyChange,
    anyStemAssemblyChange,
    hasTargetsChanged,
} from '@/lib/api/resource/case/surgical-template/hipTemplateComparison';
import {
    TemplateSyncErrorType, TemplateSyncState, TemplateSyncUpdate,
} from '@/hipPlanner/stores/template/TemplateSyncState';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { HipCaseStore } from '@/hipPlanner/stores/case/hipCaseStore';
import { cloneSurgicalTemplate } from '@/hipPlanner/stores/template/customDeepClone';
import { taggedLogger } from '@/util/taggedLogger';
import makeTemplateRepresentation from '@/hipPlanner/stores/template/makeTemplateRepresentation';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { isReRanking } from '@/hipPlanner/stores/planner/ReRankingState';
import { WatchHandleList } from '@/hipPlanner/assembly/controllers/VueObserver';
import { watch, WatchStopHandle } from 'vue';
import { validateTemplate } from '@/hipPlanner/stores/template/validateTemplate';
import { formatTemplateDifferences, templatesAreEqual } from '@/hipPlanner/stores/template/templateProperties';
import { defaultLevelLogger } from '@/util/defaultLevelLogger';
import { asyncWatchUntil } from '@/util/asyncWatch';
import { asyncTimeout } from '@/util/asyncTimeout';
import { TimeoutError } from '@/util/errors';

const logger = taggedLogger('template-sync');
const SYNCHRONIZATION_LOG_LEVEL = 'log' as const;
const log = defaultLevelLogger(SYNCHRONIZATION_LOG_LEVEL, logger);

/** Time in milliseconds between each automatic fetch */
const FETCH_TIMEOUT = 5000;

/** Time in milliseconds to debounce an update */
const UPDATE_TIMEOUT = 3000;

export default class SurgicalTemplateSynchroniser {
    private nextUpdateTaskId = 1;
    protected readonly _state: TemplateSyncState;
    private watchHandles = new WatchHandleList();
    private readonly running: Promise<void>;
    private isInitialized = false;

    constructor(
        protected caseStore: HipCaseStore,
        protected templateStore: HipTemplateStore,
        private plannerStore: HipPlannerStore,
        private options: CacheOptions,
        private shutdownSignal: AbortSignal) {
        this._state = templateStore.sync;
        this.running = this.run();
    }

    /**
     * True if there is an update that is queued or currently being saved
     */
    public get hasUpdate(): boolean {
        return this._state.queuedUpdate !== null || this._state.isSaving;
    }

    public get isSaving(): boolean {
        return this._state.isSaving;
    }

    public get hasError(): boolean {
        return this._state.error !== null;
    }

    public start(): void {
        if (!this.isInitialized) {
            log('Initializing');
            // Watch the ui-state on the template-store for changes and create an update when it changes
            this.watchHandles.add(
                watchTemplateChanges(this.templateStore, (template) => this.update(template)),
            );
            this.isInitialized = true;
        }

        if (this._state.shouldPause) {
            log('Starting...');
            this._state.shouldPause = false;
        } else {
            logger.debug('Ignoring start: already starting');
        }
    }

    public pause(): void {
        if (!this._state.shouldPause) {
            log('Pausing...');
            this._state.shouldPause = true;
        } else {
            logger.debug('Ignoring pause: already pausing');
        }
    }

    /**
     * Stop the service. Following attempts to `start` will throw an exception.
     */
    public stop(): Promise<void> {
        this.watchHandles.stop();
        this._state.shouldStop = true;
        return this.running;
    }

    /**
     * If there is a queued update then push it as soon as possible
     */
    public forceUpdate(): void {
        if (this._state.queuedUpdate) {
            this._state.forceUpdate = true;
        }
    }

    /**
     * Queue an update to the surgical-template
     */
    protected update(template: HipSurgicalTemplateRepresentation): void {
        this._state.queuedUpdate = {
            id: this.nextUpdateTaskId++,
            scheduledTime: Date.now() + UPDATE_TIMEOUT,
            value: cloneSurgicalTemplate(template),
        };
    }

    private get shouldRun(): boolean {
        return !this._state.shouldStop && !this._state.shouldPause;
    }

    private async run(): Promise<void> {
        while (!this._state.shouldStop) {
            if (this._state.shouldPause) {
                this._state.runState = 'paused';
                log('Paused');
                await asyncWatchUntil(() => !this._state.shouldPause, { signal: this.shutdownSignal });
                if (this._state.shouldStop) {
                    break;
                }
                this._state.runState = 'running';
                log('Started');
            }

            const update = this._state.queuedUpdate;
            const now = Date.now();

            if (update) {
                // There is a queued update, so check if it should be applied
                if (now >= update.scheduledTime || this._state.forceUpdate) {
                    // The scheduled-time for the update has elapsed, so apply it
                    this._state.queuedUpdate = null;
                    await this.applyUpdate(update);
                } else {
                    // Wait for the scheduled update time
                    log('Scheduling update...');
                    await this.waitForTimeoutOrChange(
                        update.scheduledTime - now,
                        () => !this.shouldRun || this._state.forceUpdate,
                    );
                }
            } else {
                // There is no queued update, so schedule a fetch
                logger.debug('Scheduling sync...');
                await this.waitForTimeoutOrChange(
                    FETCH_TIMEOUT,
                    () => !this.shouldRun && this._state.queuedUpdate === null,
                );
                if (this.shouldRun && this._state.queuedUpdate === null) {
                    // There is still no queued update, so execute a fetch
                    logger.debug('Syncing');
                    await this.fetchTemplate();
                }
            }
        }
        log('Stopped');
        this._state.runState = 'stopped';
    }

    private async waitForTimeoutOrChange(delay: number, change: () => boolean): Promise<void> {
        const changed = asyncWatchUntil(change, { signal: this.shutdownSignal });
        const timeout = asyncTimeout(delay, this.shutdownSignal);
        try {
            await Promise.race([changed, timeout]);
        } catch (e) {
            if (!this._state.shouldStop) {
                throw e;
            }
        }
    }

    private get _template(): HipSurgicalTemplateRepresentation {
        return this.templateStore.userTemplate;
    }

    private setError(type: TemplateSyncErrorType, error?: unknown): void {
        this._state.error = { type, error };
        this._state.shouldStop = true;
    }

    private async fetchTemplate(): Promise<void> {
        try {
            logger.debug('Fetching template');
            await this.caseStore.syncManualTemplate({
                signal: this.shutdownSignal,
            });
            this.checkTemplateForConflicts();
        } catch (err) {
            if (err instanceof TimeoutError) {
                logger.error('timed-out while attempting to fetch surgical template');
                this.setError('get-timed-out', err);
            } else {
                logger.error('template was not fetched successfully');
                this.setError('get-failed', err);
            }
        }
    }

    private checkTemplateForConflicts() {
        if (this._state.error !== null) {
            log('Ignoring template due to being in error.');
        } else if (this.isDirty()) {
            log('Skipping template-merge check while saving (has changes not flushed yet)');
        } else {
            const currentTemplate = makeTemplateRepresentation(this.templateStore);
            const options = {
                includeStemProperties: !isReRanking(this.plannerStore.rerankingState),
                includeStemTransform: this.plannerStore.enableStemTransform,
            };
            if (templatesAreEqual(currentTemplate, this._template, options)) {
                logger.debug('No conflicts detected in merge');
            } else {
                this.setError(
                    'merge-error',
                    [
                        'Fetched changes to surgical template are incompatible:',
                        ...formatTemplateDifferences(currentTemplate, this._template, options),
                    ].join('\n  '),
                );
            }
        }
    }

    private async applyUpdate(updateTask: TemplateSyncUpdate): Promise<void> {
        log('Pushing update-%d', updateTask.id);

        this._state.isSaving = true;
        const updatedTemplate = await update<HipSurgicalTemplateRepresentation>(
            this._template, updateTask.value, this.options);

        if (!updatedTemplate) {
            // TODO: This should be the case of a 412 (precondition failed), but at the moment semantic-network
            //  does not keep track of it.
            logger.error('Failed to PUT update-%d', updateTask.id);
            this.setError('put-failed');
            return;
        }

        log('Resyncing after update-%d', updateTask.id);
        await this.fetchTemplate();
        log('Finished update-%d', updateTask.id);
        this._state.isSaving = false;
    }

    /**
     * @returns whether the latest update task not been completed
     * and has changes compared to the template in the network of data.
     */
    private isDirty(logMessage?: string): boolean {
        const hasChanges = (
            otherTemplate: HipSurgicalTemplateRepresentation): boolean => {
            const hasTargetChanges = hasTargetsChanged(this._template, otherTemplate);
            const hasFemoralChanges = anyStemAssemblyChange(
                this._template, otherTemplate, this.templateStore.enableStemTransform);
            const hasAcetabularChanges = anyCupAssemblyChange(this._template, otherTemplate);

            if (logMessage && (hasTargetChanges || hasFemoralChanges || hasAcetabularChanges)) {
                log([
                    logMessage,
                    hasTargetChanges ? 'target changes detected' : null,
                    hasFemoralChanges ? 'femoral changes detected' : null,
                    hasAcetabularChanges ? 'target changes detected' : null,
                ].filter(s => s !== null).join('\n  '));
            }

            return hasTargetChanges || hasFemoralChanges || hasAcetabularChanges;
        };

        const updateTask = this._state.queuedUpdate;
        return updateTask !== null && hasChanges(updateTask.value);
    }
}


/**
 * Watch the UI-state on the given template store, and invoke the callback with the corresponding
 * API-template representation when it changes.
 * @param templateStore
 * @param callback
 */
function watchTemplateChanges(
    templateStore: HipTemplateStore,
    callback: (template: HipSurgicalTemplateRepresentation) => void,
): WatchStopHandle {
    return watch(
        () => [
            templateStore.targetLegLengthChange,
            templateStore.targetOffsetChange,
            templateStore.cupRotation,
            templateStore.cupOffset,
            templateStore.stem,
            templateStore.head,
            templateStore.cup,
            templateStore.liner,
            templateStore.stemTransform,
        ],
        () => {
            const template = makeTemplateRepresentation(templateStore);
            if (validateTemplate(template, templateStore)) {
                callback(template);
            } else {
                throw new Error('Failed to validate template');
            }
        },
    );
}

