import { AxiosInstance } from 'axios';
import { SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import anylogger from 'anylogger';
import { SegmentedModelRepresentation } from '@/lib/api/representation/case/study/SegmentedModelRepresentation';
import Bone3d from '@/hipPlanner/assembly/objects/Bone3d';
import Object3DFactory from '@/hipPlanner/assembly/objects/Object3DFactory';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';

const log = anylogger('anatomical-assemblies');

/**
 * Load various anatomical (and metal) objects and attach them to the scene-assembly
 */
export async function loadAnatomicalAssemblies(
    assembly: SceneAssembly,
    axios: AxiosInstance,
    apiOptions: Partial<CacheOptions>,
    study: StudyRepresentation,
    surgicalTemplate: HipSurgicalTemplateRepresentation,
    featureFlagResectedFemur: boolean,
    cancelSignal?: AbortSignal): Promise<void> {
    cancelSignal?.throwIfAborted();

    await CaseStudyResource.getSegmentedModels(study, apiOptions);

    cancelSignal?.throwIfAborted();

    await addSegmentedModelsToScene(axios, assembly, study);

    cancelSignal?.throwIfAborted();

    if (featureFlagResectedFemur) {
        await addResectedFemurToScene(axios, assembly, surgicalTemplate);
    }
}

/**
 * Add the segmented bone models to the main scene
 */
async function addSegmentedModelsToScene(
    axios: AxiosInstance,
    assembly: SceneAssembly,
    study: StudyRepresentation): Promise<void> {
    log.debug('Adding segmented models to scene');

    if (study.boneModels) {
        for (const model of study.boneModels.items) {
            await addSegmentedModelToScene(axios, assembly, model);
        }
    } else {
        log.warn('No segmented bone models available');
    }

    if (study.metalModels) {
        for (const model of study.metalModels.items) {
            await addSegmentedModelToScene(axios, assembly, model);
        }
    } else {
        log.warn('No segmented metal models available');
    }
}

/**
 * Load the geometry of a segmented model and added it to the 3D scene
 */
async function addSegmentedModelToScene(
    axios: AxiosInstance,
    assembly: SceneAssembly,
    segmentedModel: SegmentedModelRepresentation): Promise<Bone3d> {
    try {
        const bone = await Object3DFactory.makeBone3D(segmentedModel, axios);

        assembly.addToHipObject(bone).scene.add(bone.theObject);
        return bone;
    } catch (e) {
        log.error(e);
        throw new Error(
            `Failed to load segmented model with uri ${LinkUtil.getUri(segmentedModel, LinkRelation.self)}`);
    }
}

/**
 * Add the resected femur to scene
 *
 * The resected model in stored in the active study models, so needs to be picked up from surgical template models
 *
 * Note: this is not called but is useful for dev purposes and check if Neck Cut Cross Section match the bone
 */
async function addResectedFemurToScene(
    axios: AxiosInstance,
    assembly: SceneAssembly,
    surgicalTemplate: HipSurgicalTemplateRepresentation): Promise<Bone3d | null> {
    const resectedFemur = await SurgicalTemplateResource.getModelByTitle(
        surgicalTemplate,
        'type operative-femur (resection-remaining)',
        { forceLoad: true });

    if (resectedFemur) {
        return await addSegmentedModelToScene(
            axios, assembly, resectedFemur as SegmentedModelRepresentation);
    } else {
        log.warn('No resected femur available');
        return null;
    }
}
