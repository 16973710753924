import {
    LandmarkGroupRepresentation,
    StudyLandmarksRepresentation,
} from '@/lib/api/representation/case/study/landmarks/StudyLandmarksRepresentation';
import { LandmarkRepresentation } from '@/lib/api/representation/base/landmark/LandmarkRepresentation';
import { LandmarkUtil } from '@/lib/api/representation/base/landmark/LandmarkUtil';

/**
 * Helper class for mapping various landmark sets extracted from a hip or shoulder {@link StudyLandmarksRepresentation}
 */
export class StudyLandmarkUtil {
    /**
     * Get all the landmarks from a group
     */
    public static getLandmarkGroup(
        landmarks: StudyLandmarksRepresentation, groupName: string): LandmarkGroupRepresentation[] {
        return landmarks.groups.filter((group: LandmarkGroupRepresentation) => group.name === groupName);
    }

    /**
     * Get a landmark by group name and landmark name.
     */
    public static getLandmark(
        landmarks: StudyLandmarksRepresentation,
        groupName: string,
        landmarkName: string): LandmarkRepresentation | null {
        const allLandmarks = StudyLandmarkUtil
            .getLandmarkGroup(landmarks, groupName)
            .flatMap((g) => g.landmarks);

        return LandmarkUtil.getLandmark(allLandmarks, landmarkName);
    }
}
