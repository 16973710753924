import axios, { AxiosError, AxiosResponse } from 'axios';

export default class AxiosUtil {
    /**
     * Determines whether the payload is an error thrown by Axios
     *
     * @param {*} payload The value to test
     * @returns {boolean} True if the payload is an error thrown by Axios, otherwise false
     *
     * Taken from: https://github.com/axios/axios/blob/master/lib/helpers/isAxiosError.js
     */
    public static isAxiosError(payload: unknown): payload is AxiosError {
        return (typeof payload === 'object') && (payload as AxiosError).isAxiosError;
    }

    /**
     * Check if an Axios response returns an "OK" status code (200)
     */
    public static isResponseOk(response?: AxiosResponse): boolean {
        return response?.status === 200;
    }

    /**
     * Check if an Axios response returns a "Created" status code (201)
     */
    public static isResponseCreated(response?: AxiosResponse): boolean {
        return response?.status === 201;
    }

    /**
     * Check if an Axios response returns an "Accepted" status code (202)
     */
    public static isResponseAccepted(response?: AxiosResponse): boolean {
        return response?.status === 202;
    }

    /**
     * Check if an Axios response returns a "No Content" status code (204)
     */
    public static isResponseNoContent(response?: AxiosResponse): boolean {
        return response?.status === 204;
    }

    /**
     * Check if an Axios response returns a "Forbidden" status code (403)
     */
    public static isResponseForbidden(response?: AxiosResponse): boolean {
        return response?.status === 403;
    }

    /**
     * Check if an Axios response has a code '403'
     */
    private static isCodeForbidden(error: AxiosError): boolean {
        return error?.code === '403';
    }

    /**
     * Check if an Axios response has a code '404'
     */
    private static isCodeNotFound(error: AxiosError): boolean {
        return error?.code === '404';
    }

    /**
     * Check if an Axios error response returns a "Forbidden" status code (403)
     */
    public static isNotFoundError(error: Error): boolean {
        return AxiosUtil.isAxiosError(error) &&
            (AxiosUtil.isCodeNotFound(error) || AxiosUtil.isResponseNotFound(error.response));
    }

    /**
     * Check if an Axios response returns a "Not Found" status code (404)
     */
    public static isResponseNotFound(response?: AxiosResponse): boolean {
        return response?.status === 404;
    }

    /**
     * Check if an Axios error response returns a "Forbidden" status code (403)
     */
    public static isForbiddenError(error: Error): boolean {
        return AxiosUtil.isAxiosError(error) &&
            (AxiosUtil.isCodeForbidden(error) || AxiosUtil.isResponseForbidden(error.response));
    }

    /**
     * Check if an Axios error is a cancellation error.
     *
     * Note: An axios cancellation error does not evaluate truthy `isAxiosError`.
     * @see https://github.com/axios/axios/issues/2918
     */
    static isCancelled(error: unknown): boolean {
        return axios.isCancel(error);
    }
}
