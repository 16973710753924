import { BufferGeometry, Mesh } from 'three';
import CupComponentCoverageUtil from '@/hipPlanner/assembly/controllers/CupComponentCoverageUtil';
import { CollisionCoverageResults } from '@/lib/viewer/component-coverage/types';
import { ComponentCoverageController } from '@/lib/viewer/component-coverage/ComponentCoverageController';
import HipViewerObjectUtil from '@/hipPlanner/assembly/objects/HipViewerObjectUtil';
import { Bones } from '@/lib/constants/Bones';
import { AcidMeshObject3d } from '@/hipPlanner/assembly/objects/AcidMeshObject3d';
import { SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
import { HipPlannerViewStore } from '@/hipPlanner/stores/plannerView/hipPlannerView';
import { watchEffect } from 'vue';
import plannerEventBus from '@/lib/planning/events/PlannerEventBus';
import { PlannerEvent } from '@/lib/planning/events/PlannerEvent';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import RegionResource from '@/lib/api/resource/components/RegionResource';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import Implant3d from '@/hipPlanner/assembly/objects/Implant3d';
import anylogger from 'anylogger';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { addHandlers } from '@/lib/vue/addHandlers';
import { WatchHandleList } from '@/hipPlanner/assembly/controllers/VueObserver';

const log = anylogger('HipCupCoverageCalculator');

/**
 * Cup specific coverage controller.
 */
export class CupCoverageController extends ComponentCoverageController {
    private handles = new WatchHandleList();

    /**
     * The cup coverage info object is passed to the component coverage controller and the values
     * are updated whenever the cup collision coverage is calculated
     */
    protected state: CollisionCoverageResults = {
        area: NaN,
        coverage: NaN,
        totalArea: NaN,
        isCalculating: false,
        intersectionMesh: new Mesh(),
        faceAreaMetrics: [],
    };

    constructor(
        private plannerStore: HipPlannerStore,
        private assembly: HipPlannerAssembly,
        private sceneAssembly: SceneAssembly,
        plannerViewStore: HipPlannerViewStore,
        coveringMesh: Mesh) {
        const collisionObject = ComponentCoverageController.makeCollisionObject();
        super(collisionObject, coveringMesh);

        // The cup group looks like a logical place to add the collision mask, instead of the scene root.
        assembly.cupGroup.add(collisionObject);

        const toggleCollisionMaskVisibility = () => {
            this.collisionObject.theObject.visible = plannerViewStore.showComponentCollision;
        };

        this.handles.add(
            watchEffect(toggleCollisionMaskVisibility),
            addHandlers(plannerEventBus, [
                [PlannerEvent.HipPlannerCupAssemblyCupSet, this.onCupSet.bind(this)],
                [PlannerEvent.HipPlannerCupAssemblyMoved, this.onCupMoved.bind(this)],
            ]),
        );

        this.scheduleUpdateCupCoverage3D();
    }

    public off() {
        if (this.collisionObject?.object3D) {
            this.sceneAssembly.scene.remove(this.collisionObject.object3D);
        }
        this.handles.stop();
    }

    /**
     * Calculate the cup coverage using an intersection volume which is generated based
     * the mesh, collision mask, and cup diameter.
     */
    private async calculateCoverage(cup3D: Implant3d, mask: BufferGeometry): Promise<CollisionCoverageResults | null> {
        return await this.updateCoverage(
            cup3D.theObject, mask, CupComponentCoverageUtil.makeCoverageStrategy());
    }

    private onCupSet(): void {
        this.scheduleUpdateCupCoverage3D();
    }

    private onCupMoved(): void {
        this.scheduleUpdateCupCoverage3D();
    }

    /**
     * Helper method that is called when cup coverage needs to be updated
     *
     * This method uses the component coverage controller to initiate cup coverage update
     */
    private async scheduleUpdateCupCoverage3D(): Promise<void> {
        log.debug('schedule update cup coverage 3d');

        const cup3D = this.assembly.cup;
        const cup = cup3D.getCaseComponent() as HipCupRepresentation;

        // we do not want to await for this async function
        if (cup) {
            try {
                this.state.isCalculating = true;
                this.plannerStore.cupCoverage = {
                    value: this.state.coverage,
                    isCalculating: this.state.isCalculating,
                };
                const collisionMask = await CupCoverageController.getCollisionMask(cup);
                const coverage = await this.calculateCoverage(cup3D, collisionMask);

                if (coverage) {
                    this.state = coverage;
                    this.plannerStore.cupCoverage = {
                        value: this.state.coverage,
                        isCalculating: this.state.isCalculating,
                    };
                } else {
                    // coverage cancelled, nothing to do
                }
            } catch (err: unknown) {
                if (err instanceof Error) {
                    log.error('Unexpected coverage calculation failure: %s', err.message);
                } else {
                    log.error(err);
                }

                this.state.isCalculating = false;
                this.plannerStore.cupCoverage = {
                    value: null,
                    isCalculating: this.state.isCalculating,
                };
            }
        } else {
            throw new Error('Could not calculate coverage. No cup fitted component');
        }
    }

    /**
     * Get the collision-mask from the cup fitted component.
     * cup (cup-bucket) -> component -> collision-mask
     */
    private static async getCollisionMask(cup: HipCupRepresentation): Promise<BufferGeometry> {
        const cupComponent = cup.component;
        if (cupComponent) {
            const collisionMask = await RegionResource.getCollisionMaskPLYFile(cupComponent);
            if (collisionMask) {
                return collisionMask;
            } else {
                throw new Error(`No collision mask for cup ${getRequiredUri(cupComponent, LinkRelation.self)}`);
            }
        } else {
            throw new Error('Cup without component');
        }
    }
}

export function makeCupCoverageController(
    plannerStore: HipPlannerStore,
    assembly: HipPlannerAssembly,
    sceneAssembly: SceneAssembly,
    plannerViewStore: HipPlannerViewStore): CupCoverageController {
    // find the operative hemipelvis object
    const hemipelvis = HipViewerObjectUtil.findByAlias(sceneAssembly, Bones.OperativeHemi) as AcidMeshObject3d;
    if (hemipelvis) {
        return new CupCoverageController(plannerStore, assembly, sceneAssembly, plannerViewStore, hemipelvis.theObject);
    } else {
        throw new Error('Cannot create cup-coverage controller: no hemipelvis');
    }
}
