import Bone3d from '@/hipPlanner/assembly/objects/Bone3d';
import { SegmentedModelRepresentation } from '@/lib/api/representation/case/study/SegmentedModelRepresentation';
import { AxiosInstance } from 'axios';
import { BufferGeometry, Mesh } from 'three';
import HipSurgicalTemplateComponentResource
    from '@/lib/api/resource/case/surgical-template/components/HipSurgicalTemplateComponentResource';
import { ModelRepresentation } from '@/lib/api/representation/ModelRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import { LinkUtil } from 'semantic-link';
import assert from 'assert';
import { mapStudySegmentModelToType } from '@/hipPlanner/assembly/controllers/AcidPlannerModeSettings';
import { Bones } from '@/lib/constants/Bones';
import AcidObject3d, { AcidObject3dType } from '@/lib/planning/objects-3D/AcidObject3d';
import { HipRenderOrder } from '@/hipPlanner/assembly/objects/HipRenderOrder';
import ViewerUtils from '@/lib/planning/viewer/ViewerUtils';
import AliasMaterialFactory from '@/hipPlanner/assembly/controllers/AliasMaterialFactory';

/**
 * A factory for creating instances of {@link AcidObject3d}
 *
 * Note: The creation of this objects is still messy, and was dispersed and inline in different places.
 * This a is a **temporary** factory that intents to hide the complexity of creating them.
 */
export default class Object3DFactory {
    /**
     * Creates a {@link Bone3d} using a [segmented model]{@link SegmentedModelRepresentation}
     *
     * @param segmentedModel
     * @param axios: the instance of axios used to load the {@link SegmentedModelRepresentation.model}
     * if it is not present.
     */
    public static async makeBone3D(
        segmentedModel: SegmentedModelRepresentation, axios: AxiosInstance): Promise<Bone3d> {
        const modelUri = LinkUtil.getUri(segmentedModel, LinkRelation.self);
        assert.ok(modelUri, `Could not load 3d model with uri: ${modelUri}`);

        await this.maybeLoadModel(segmentedModel, axios);
        assert.ok(segmentedModel.model, `No model for segmented model with uri: ${modelUri}`);

        const mappedKey = mapStudySegmentModelToType(segmentedModel);
        const boneType = (mappedKey === Bones.InnerSurface) ?
            AcidObject3dType.BoneInner :
            AcidObject3dType.Bone;
        const bone = new Bone3d({ type: boneType, name: modelUri, alias: mappedKey });
        assignMesh(bone, segmentedModel.model);
        bone.setRenderOrder(HipRenderOrder.Default);
        return bone;
    }

    /** Returns the {@link SegmentedModelRepresentation.model} if it is present, otherwise loads it */
    private static async maybeLoadModel(
        segmentedModel: SegmentedModelRepresentation, axios: AxiosInstance): Promise<BufferGeometry> {
        // Check if the model is loaded for this item
        if (segmentedModel.model) {
            return segmentedModel.model;
        } else {
            // Load the buffered geometry into the "model" key of the model
            const model = await HipSurgicalTemplateComponentResource.getMediaType<ModelRepresentation, BufferGeometry>(
                segmentedModel, LinkRelation.self, LinkRelation.model, axios);

            if (model) {
                return segmentedModel.model;
            }

            throw new Error(`Could not load model ${segmentedModel.name}`);
        }
    }
}

/**
 * Create a mesh from some geometry and set it as the object of the given AcidObject.
 */
export function assignMesh(object: AcidObject3d, geometry: BufferGeometry): void {
    // Make the geometry to be used for the mesh
    // We clone the geometry, in order to preserve the original, since we may need to reuse it
    const geometryCopy = ViewerUtils.makeMeshBufferGeometry(geometry.clone());

    // create a mesh object
    object.theObject = new Mesh(geometryCopy);
    // Give a name to the mesh, which at the moment corresponds to the key name of the element listed in case-data
    object.theObject.name = object.name;
    // Apply normal material
    object.applyNormalMaterialFromRepresentation(AliasMaterialFactory.makeMaterialsForAlias);
}
