import { SpinopelvicStore } from '@/stores/spinopelvic/store';
import { SpinopelvicCalculations, SpinopelvicWarnings } from '@/components/spinopelvic/types';
import {
    ANTEVERSION_10_DEGREES, ANTEVERSION_30_DEGREES,
    INCLINATION_LOWER_LIMIT,
    INCLINATION_UPPER_LIMIT,
    LUMBAR_LORDOSIS_LIMIT,
    PFA_LOWER_LIMIT,
    PFA_UPPER_LIMIT,
    STANDING_PELVIC_TILT_LIMIT,
} from '@/components/spinopelvic/conts';
import { anteversionFromAIRI, anteversionRotated, inclinationRotated } from '@/components/spinopelvic/calculations';

export const makeSpinopelvicWarnings = (
    cupInclination: number,
    cupAnteversion: number,
    spinopelvicStore: SpinopelvicStore,
    spinopelvicCalculation: SpinopelvicCalculations,
): SpinopelvicWarnings => {
    const standingPTWarning = spinopelvicStore.measurements.standingPelvicTilt > STANDING_PELVIC_TILT_LIMIT;
    const lumbarLordosisWarning = spinopelvicStore.measurements.lumbarLordosis < LUMBAR_LORDOSIS_LIMIT;
    const pelvicFemoralAngleWarning = spinopelvicStore.measurements.pelvicFemoralAngle < PFA_LOWER_LIMIT ||
        spinopelvicStore.measurements.pelvicFemoralAngle > PFA_UPPER_LIMIT;

    const csiLower = anteversionFromAIRI(
        cupInclination,
        spinopelvicCalculation.csiLowerBound,
        spinopelvicCalculation.pelvicTilt,
    );
    const csiUpper = anteversionFromAIRI(
        cupInclination,
        spinopelvicCalculation.csiUpperBound,
        spinopelvicCalculation.pelvicTilt,
    );
    const anteversionLower = anteversionRotated(
        cupInclination,
        ANTEVERSION_10_DEGREES,
        spinopelvicCalculation.pelvicTilt
    );
    const anteversionUpper = anteversionRotated(
        cupInclination,
        ANTEVERSION_30_DEGREES,
        spinopelvicCalculation.pelvicTilt
    );
    const inclinationLowerLimit = inclinationRotated(
        INCLINATION_LOWER_LIMIT,
        cupAnteversion,
        spinopelvicCalculation.pelvicTilt
    );
    const inclinationUpperLimit = inclinationRotated(
        INCLINATION_UPPER_LIMIT,
        cupAnteversion,
        spinopelvicCalculation.pelvicTilt
    );

    const anteversionLowerLimit = Math.min(csiLower, anteversionLower);
    const anteversionUpperLimit = Math.min(csiUpper, anteversionUpper);

    const anteversionLowerWarning = cupAnteversion < anteversionLowerLimit;
    const anteversionUpperWarning = cupAnteversion > anteversionUpperLimit;

    const inclinationLowerWarning = cupInclination < inclinationLowerLimit;
    const inclinationUpperWarning = cupInclination > inclinationUpperLimit;

    const hasWarnings = standingPTWarning || lumbarLordosisWarning || pelvicFemoralAngleWarning ||
        inclinationUpperWarning || inclinationLowerWarning;
    return {
        hasWarnings: hasWarnings,
        standingPTWarning: standingPTWarning,
        lumbarLordosisWarning: lumbarLordosisWarning,
        pelvicFemoralAngleWarning: pelvicFemoralAngleWarning,
        inclinationLowerWarning: inclinationLowerWarning,
        inclinationUpperWarning: inclinationUpperWarning,
        anteversionLowerWarning: anteversionLowerWarning,
        anteversionUpperWarning: anteversionUpperWarning,
        limits: {
            anteversionLowerLimit: anteversionLowerLimit,
            anteversionUpperLimit: anteversionUpperLimit,
            inclinationLowerLimit: inclinationLowerLimit,
            inclinationUpperLimit: inclinationUpperLimit,
        },
    };
};
