
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import AppSvg from '@/components/basic/AppSvg.vue';

    @Component({components: {AppButton, AppSvg}})
    export default class AppButton extends Vue {
        @Prop(String) readonly title: string | undefined;
        @Prop(String) readonly message: string | undefined;
        @Prop(String) readonly icon: string | undefined;
        @Prop(Boolean) readonly closable: boolean | undefined;
        @Prop(String) readonly flavour: 'good' | 'bad' | 'warn' | 'inform' | undefined;
    }
