import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { OrthographicCamera, PerspectiveCamera, Vector3 } from 'three';

export default class CameraActions {

    /**
     * Move the camera to a new position and rotate it towards a specific point
     *
     * @param camera
     * @param controls
     * @param targetPosition The new position of the camera
     * @param lookAtPoint Point where the camera will be looking at (rotated towards)
     * @param cameraUp Normalized "up" directional vector of the camera. Default is on the Y axis
     * @param distance Distance between the camera and the lookAtPoint. OrthographicCamera uses this
     *                 to set its viewing frustum
     */
    public static moveTo(
        camera: PerspectiveCamera | OrthographicCamera,
        controls: TrackballControls,
        targetPosition: Vector3,
        lookAtPoint: Vector3,
        cameraUp: Vector3 = new Vector3(0, 1, 0),
        distance?: number): void {
        // set the camera position (rotation stays the same)
        camera.position.copy(targetPosition);

        // Update the "up" directional vector of the camera
        // We must update the "up" direction, because panning the camera depends on it. If it's not updated
        // with correct direction, panning may cause/become zooming in/out.
        //
        // Updating the up vector will ensure that the camera roll is at the correct rotation.
        // The up direction is also used by the .lookAt() method, which rolls and focuses the camera
        // to a specified vector point.
        //
        // When we update the up vector, there is no need to update the camera quaternion.
        camera.up.copy(cameraUp);

        // If we have an OrthographicCamera, update the camera frustum based on the distance.
        // This is important because when using OrthographicCamera the objects' size are constant (do not change)
        // no matter of the objects' distance from the camera.
        //
        if (distance && camera instanceof OrthographicCamera) {
            CameraActions.updateCameraAspect(camera, distance / 2, distance / 2, distance);
        }

        // Update the camera "look at" point using the camera controls.
        //
        // We DO NOT update the camera look at point using the camera.lookAt(), because we control the camera
        // through the camera controls. If we don't update the camera controls, they may become out-of-sync
        // with camera and cause very sharp/sudden movement of the camera when the user starts manipulating the camera
        // manually.
        controls.target.copy(lookAtPoint);
        controls.update();
    }

    /**
     * Update a camera frustum aspect ratio
     *
     * @param camera Camera to be updated
     * @param width The width of the canvas view
     * @param height The height of the canvas view
     * @param distance Optional distance used for the OrthographicCamera.
     *                 It doesn't affect the PerspectiveCamera position.
     */
    public static updateCameraAspect(
        camera: PerspectiveCamera | OrthographicCamera,
        width: number,
        height: number,
        distance?: number): void {
        const cameraAspect = width / height;

        if (camera instanceof PerspectiveCamera) {
            camera.aspect = cameraAspect;
        } else {
            const halfViewX = distance ? distance / 2 : width / 2;
            const halfViewY = distance ? distance / 2 : height / 2;
            // update viewing frustum
            camera.left = -halfViewX * cameraAspect;
            camera.right = halfViewX * cameraAspect;
            camera.top = halfViewY;
            camera.bottom = -halfViewY;
        }
        // We MUST update the projection matrix, otherwise the aspect ratio will not render correctly
        camera.updateProjectionMatrix();
    }
}
