import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import {
    CaseSearchRepresentation,
    ProjectIdSearch,
    ProjectTextSearch,
} from '@/lib/api/representation/CaseSearchRepresentation';
import { NumberUtil } from '@/lib/base/NumberUtil';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import { CaseCollectionRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import CaseResource from '@/lib/api/resource/case/CaseResource';
import assert from 'assert';

export class CaseSearchRepresentationBuilder {
    private id: ProjectIdSearch | null = null;
    private text: ProjectTextSearch | null = null;

    // public withName(text: string): this {
    //     throw new Error('not implemented yet');
    // }

    // public withPatient(text: string): this {
    //     throw new Error('not implemented yet');
    // }

    // public withSurgeon(text: string): this {
    //     throw new Error('not implemented yet');
    // }

    // public withDate(text: string): this {
    //     throw new Error('not implemented yet');
    // }

    public withIds(text: string): this {
        this.id = this.parseIds(text);
        return this;
    }

    public withText(text: string): this {
        this.text = text;
        return this;
    }

    // public withProduct(text: string): this {
    //     throw new Error('not implemented yet');
    // }

    //
    // TODO:
    //  1. Improve parsing to accept ranges and be more strict
    //  2. Add testing
    //
    private parseIds(text: string): ProjectIdSearch | null {
        if (text.length > 0) {
            const tokens = text.match(/\d+/g);

            if (tokens) {
                return tokens.map((token: string): number => {
                    const numericId = Number.parseInt(token, 10);
                    if (NumberUtil.isFiniteNumber(numericId)) {
                        return numericId;
                    } else {
                        throw new Error(`${numericId} is not a valid number`);
                    }
                });
            }
        }

        return null;
    }

    public build(): CaseSearchRepresentation {
        if (this.id) {
            return { id: this.id };
        }

        if (this.text) {
            return { text: this.text };
        }

        return {};
    }
}

export default class CaseSearchUtil {
    /**
     * Search case by ids
     *
     * 1. POST a search to the api with a JSON document expressing the search: TODO: not done yet
     * e.g of the json document:
     * `{
     *     'id': '[1, 2, 3, 5-10]'
     *  }`
     * 2. Using the search uri retrieved, it loads each of the cases.
     * It will try to load them from the global collection of projects ('projects' attribute
     * in the root of the {@link ApiRepresentation}), and if they are not present it will fetch them.
     * @param apiResource
     * @param idsSearchText: a space sparated list of case ids
     * @param options
     */
    static async searchByCaseIds(
        apiResource: ApiRepresentation,
        idsSearchText: string,
        options?: CacheOptions): Promise<CaseCollectionRepresentation | null> {
        assert.ok(apiResource.projects, 'projects collection must be present on the api root object');
        const searchData = CaseSearchUtil.buildSearchById(idsSearchText);
        return await CaseResource.search(apiResource, searchData, options);
    }

    static async searchByCaseText(
        apiResource: ApiRepresentation,
        searchText: string,
        options?: CacheOptions): Promise<CaseCollectionRepresentation | null> {
        assert.ok(apiResource.projects, 'projects collection must be present on the api root object');
        const searchData = CaseSearchUtil.buildSearchByText(searchText);
        return await CaseResource.search(apiResource, searchData, options);
    }

    /**
     * Given a string with number sparated by space (e.g: 1 2 30 50),
     * returns a {@interface CaseSearchRepresentation} with the 'id' populated.
     */
    private static buildSearchByText(text: string): CaseSearchRepresentation {
        const search = new CaseSearchRepresentationBuilder();

        return search
            .withText(text)
            .build();
    }

    /**
     * Given a string with number sparated by space (e.g: 1 2 30 50),
     * returns a {@interface CaseSearchRepresentation} with the 'id' populated.
     */
    private static buildSearchById(text: string): CaseSearchRepresentation {
        const search = new CaseSearchRepresentationBuilder();

        return search
            .withIds(text)
            .build();
    }
}
