import { CaseSettings } from '@/stores/caseSettings/types';
import { PatientSex } from '@/lib/api/representation/PatientRepresentation';
import { HipCupFitMethodEnum } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { HipCupAnteversionMode } from '@/lib/api/representation/interfaces';
import {
    isInteger,
    isPositiveInteger,
    isNotEmpty,
    isDateIsoString,
} from '@/stores/utils/validation';

export const caseSettingsValidators: { [key: string]: (c: CaseSettings) => boolean } = {
    caseInfo: (c: CaseSettings) => (
        c.caseInfo !== undefined &&
        inputFieldValidators['caseName'](c)
    ),
    patient: (c: CaseSettings) => (
        c.patient !== undefined &&
        inputFieldValidators['patientGivenName'](c) &&
        inputFieldValidators['patientFamilyName'](c) &&
        inputFieldValidators['patientSex'](c) &&
        inputFieldValidators['patientBirthDate'](c)
    ),
    surgicalSpecification: (c: CaseSettings) => (
        c.surgicalSpecification !== undefined  &&
        inputFieldValidators['selectedStemTypes'](c) &&
        inputFieldValidators['legLengthChange'](c) &&
        inputFieldValidators['offsetChange'](c) &&
        inputFieldValidators['anteversionAngle'](c) &&
        inputFieldValidators['inclinationAngle'](c) &&
        (
            c.surgicalSpecification.fittingMode === HipCupFitMethodEnum.Beverland ||
            c.surgicalSpecification.fittingMode === HipCupFitMethodEnum.Fossa ||
            c.surgicalSpecification.fittingMode === HipCupFitMethodEnum.Acid
        )
    ),
};

export const inputFieldValidators: { [key: string]: (c: CaseSettings) => boolean } = {
    caseName: ({ caseInfo }) => isNotEmpty(caseInfo.name),

    surgeryDate: ({ caseInfo }) => !isNotEmpty(caseInfo.surgeryDate) ||
        (isNotEmpty(caseInfo.surgeryDate) && isDateIsoString(caseInfo.surgeryDate)),

    patientGivenName: ({ patient }) => isNotEmpty(patient.name.given),

    patientFamilyName: ({ patient }) => isNotEmpty(patient.name.family),

    patientSex: ({ patient }) => patient.sex !== undefined &&
     (patient.sex === PatientSex.Male || patient.sex === PatientSex.Female),

    patientBirthDate: ({ patient }) =>
        isNotEmpty(patient.birth_date) &&
        isDateIsoString(patient.birth_date),

    selectedSurgeon: ({ selectedSurgeon }) => isNotEmpty(selectedSurgeon),

    selectedStemTypes: ({ surgicalSpecification }) => surgicalSpecification.stemTypes.length > 0,

    legLengthChange: ({ surgicalSpecification }) =>
        isNotEmpty(surgicalSpecification.legLengthChange) &&
        isInteger(surgicalSpecification.legLengthChange),

    offsetChange: ({ surgicalSpecification }) =>
        isNotEmpty(surgicalSpecification.offsetChange) &&
        isInteger(surgicalSpecification.offsetChange),

    anteversionAngle: ({ surgicalSpecification }) => (
        surgicalSpecification.anteversionMode === HipCupAnteversionMode.Auto ||
        surgicalSpecification.fittingMode === HipCupFitMethodEnum.Beverland ||
        (
            surgicalSpecification.anteversionMode === HipCupAnteversionMode.Manual &&
            isNotEmpty(surgicalSpecification.anteversionAngle) &&
            isPositiveInteger(surgicalSpecification.anteversionAngle)
        )
    ),

    inclinationAngle: ({ surgicalSpecification }) => (
        isNotEmpty(surgicalSpecification.inclinationAngle) &&
        isPositiveInteger(surgicalSpecification.inclinationAngle)
    ),

    fittingMode: ({ surgicalSpecification }) => surgicalSpecification.fittingMode !== undefined &&
    (
        surgicalSpecification.fittingMode === HipCupFitMethodEnum.Acid ||
        surgicalSpecification.fittingMode === HipCupFitMethodEnum.Beverland ||
        surgicalSpecification.fittingMode === HipCupFitMethodEnum.Fossa
    ),
};

export const isValid = (caseSettings: CaseSettings): boolean =>
    Object
        .values(caseSettingsValidators)
        .map(validator => validator(caseSettings))
        .every(result => result);
