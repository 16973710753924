/**
 * Commonly used date formats
 */
export const SHORT_DATE = 'yyyy-MM-dd';

export default class DateUtil {
    /**
     * A simple (trivial) variable replacement for a '${date}' variable.
     */
    public static replaceDateVariable(replacement?: string): string | undefined {
        const formatter = new Intl.DateTimeFormat('default', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        // eslint-disable-next-line no-template-curly-in-string
        return replacement?.replace('${date}', formatter.format(Date.now()));
    }
}
