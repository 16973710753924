import assert from 'assert';
import anylogger from 'anylogger';
import { defineStore } from 'pinia';
import { isReRankingServerSide, ReRankingState } from '@/hipPlanner/stores/planner/ReRankingState';
import { HipStemRepresentation } from '@/lib/api/representation/case/hip/HipStemRepresentation';
import { CaseHead, CaseStem } from '@/hipPlanner/components/state/types';
import { useHipCaseStore } from '@/hipPlanner/stores/case/hipCaseStore';
import { ACS } from '@/lib/base/CoordinateSystem';
import { AnatomicalCoordinateSystem } from '@/lib/api/representation/interfaces';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import {
    calculateLegLengthAndOffset,
    calculateResectionDistance,
    calculateStemAnteversion,
} from '@/hipPlanner/assembly/controllers/adjustments/adjustmentCalculation';
import {
    calculateAdjustmentsStemOld,
    calculateLegLengthAndOffsetCupOld,
} from '@/hipPlanner/assembly/controllers/adjustments/oldAdjustmentCalculation';
import { showLTResection } from '@/featureFlags/showLTResection';

const log = anylogger('hip-planner-store');

export type ResectionDistance = {
    lt: number
}

export type LegLengthAndOffset = {
    legLengthChange: number
    offsetChange: number
}

export type CupCoverageState = {
    /** value from 0-1, with 1 being 100% coverage */
    value: number | null;
    isCalculating: boolean;
}

/** Operations that take some time and are reflected in the UI. Only one operation can be executing at a time */
export type PlannerOperation = 'reset-cup' | 'reset-stem' | 'approve-plan'

/**
 * An 'error' that is raised when planner actions are being aborted.
 */
export class PlannerAbortError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'PlannerAbortError';
        Object.setPrototypeOf(this, PlannerAbortError.prototype);
    }
}

export type HipPlannerState = {
    currentOperation: PlannerOperation | null | 'error' | 'aborted'

    /**
     * Coordinate system with an origin at the native hip-joint-centre and alignment to
     * APP or CT coordinates depending on the selected alignment-mode.
     */
    alignmentCoords: ACS

    /** State of the 'reranking' process that occurs when targets are changed */
    rerankingState: ReRankingState

    cupCoverage: CupCoverageState

    resection: ResectionDistance

    adjustments: LegLengthAndOffset & { stemVersion: number }

    oldAdjustments: {
        cup: LegLengthAndOffset
        /**
         * @deprecated
         *
         * TODO: Remove once MSP is implemented
         *
         * Change in leg length of the selected stem head centre from the femur head native joint centre.
         *
         * Lifecycle
         * =========
         *
         * 1. On load
         * ----------
         * On load, this values will be populated with current
         * fitted stem's {@property HipStemRepresentation.ll_diff} and {@property HipStemRepresentation.offset_diff}
         * that is calculated server side.
         * Note that this means that the server took in consideration the head to calculate the {@property HipStemRepresentation.ll_diff.
         *
         * 2. On stem/head changes client side
         * ---------------------------------
         * These values should only change when the selected fitted stem and head changes:
         * 1. stem selection: given the head offset associated with a stem might change.
         * 2. offsets selection: given the offset selection actually changes the selected stem.
         * 3. head offset selection: given the head offset changed
         * 4. re-ranking: given that might change the current stem.
         */
        stem: LegLengthAndOffset & { version: number }
    },
    /** True if stem-transformation controls should be enabled */
    enableStemTransform: boolean;
}

export const useHipPlannerStore = defineStore('hipPlanner', {
    state: (): HipPlannerState => {
        return {
            currentOperation: null,
            alignmentCoords: new ACS(),
            rerankingState: ReRankingState.Idle,
            cupCoverage: {
                value: null,
                isCalculating: true,
            },
            resection: {
                lt: 0,
            },
            adjustments: {
                stemVersion: 0,
                legLengthChange: 0,
                offsetChange: 0,
            },
            oldAdjustments: {
                stem: {
                    version: 0,
                    legLengthChange: 0,
                    offsetChange: 0,
                },
                cup: {
                    legLengthChange: 0,
                    offsetChange: 0,
                },
            },
            enableStemTransform: false,
        };
    },
    getters: {
        isReRankingServerSide(state: HipPlannerState): boolean {
            return isReRankingServerSide(state.rerankingState);
        },
    },
    actions: {
        calculateAdjustmentsStemOld(stem: CaseStem, head: CaseHead, headOffset: number): void {
            const {
                legLengthChange,
                offsetChange,
            } = calculateAdjustmentsStemOld(stem, head, headOffset, useHipCaseStore().operativeSide);
            this.oldAdjustments.stem.legLengthChange = legLengthChange;
            this.oldAdjustments.stem.offsetChange = offsetChange;
        },
        calculateAdjustments(assembly: HipPlannerAssembly): void {
            const apiStemVersion = assembly.stem.getCaseComponent<HipStemRepresentation>().angle_anteversion;

            this.oldAdjustments.stem.version = apiStemVersion;
            this.oldAdjustments.cup = calculateLegLengthAndOffsetCupOld(assembly, this.alignmentCoords);

            const {
                legLengthChange,
                offsetChange,
            } = calculateLegLengthAndOffset(assembly);
            this.adjustments.legLengthChange = legLengthChange;
            this.adjustments.offsetChange = offsetChange;

            // recalculate resection heights here...
            if (showLTResection()) {
                this.resection = calculateResectionDistance(assembly);
            }

            // If stem-transform is enabled we recalculate the stem anteversion,
            // otherwise we just use the value from the original template.
            this.adjustments.stemVersion = this.enableStemTransform ? calculateStemAnteversion(assembly) : apiStemVersion;
        },
        initialiseAlignmentCoords(coords: AnatomicalCoordinateSystem): void {
            assert.ok(
                !!coords,
                'Could not initialise hip cup state. Selected cup is missing component or global CS data');

            this.alignmentCoords = new ACS(coords); // TODO change global cs ACS() representation
        },
        /**
         * Execute some blocking 'operation'. This should be invoked directly from a UI interaction, and
         * will block other actions until the operation is complete.
         *
         * - Only one operation can be in execution at once
         * - Errors thrown by the operation will set a global-error state that prevents further operations.
         *
         * @param operationId an identifier for the operation
         * @param operation the action to execute
         */
        async executeOperation(operationId: PlannerOperation, operation: () => void | Promise<void>): Promise<void> {
            // Check that we are not already in an operation
            if (this.currentOperation !== null) {
                if (this.currentOperation === 'error') {
                    log.error("Ignoring operation '%s' owing to error", operationId);
                } else if (this.currentOperation === 'aborted') {
                    log.warn("Ignoring operation '%s': aborted", operationId, this.currentOperation);
                } else {
                    log.error("Cannot execute operation '%s': already executing '%s'",
                        operationId, this.currentOperation);
                }
                return;
            }

            // Execute the operation, and capture any errors
            try {
                setCurrentOperation(this, operationId);
                await operation();
                setCurrentOperation(this, null);
            } catch (e) {
                if (e instanceof PlannerAbortError) {
                    log.info("Aborted operation '%s': %s", operationId, e.message);
                    setCurrentOperation(this, 'aborted');
                } else if (e instanceof Error) {
                    log.error("%s in operation '%s': %s", e.name, operationId, e.message);
                    setCurrentOperation(this, 'error');
                } else {
                    log.error("Error in operation '%s': %s", operationId, e);
                    setCurrentOperation(this, 'error');
                }
            }
        },
        /**
         * Execute some non-blocking 'action'. This should be invoked directly from a UI interaction.
         *
         * Errors thrown by the action will set a global-error state that prevents further operations.
         *
         * @param actionId an identifier for the action
         * @param action the action to execute
         */
        async execute(actionId: string, action: () => void | Promise<void>): Promise<void> {
            // TODO: we may want to block actions when an operation is in progress?
            try {
                await action();
            } catch (e) {
                if (e instanceof PlannerAbortError) {
                    log.info("Aborted action '%s': %s", actionId, e.message);
                    setCurrentOperation(this, 'aborted');
                } else if (e instanceof Error) {
                    log.error("%s in action '%s': %s", e.name, actionId, e.message);
                    setCurrentOperation(this, 'error');
                } else {
                    log.error("Error in action '%s': %s", actionId, e);
                    setCurrentOperation(this, 'error');
                }
            }
        },
    },
});

export type HipPlannerStore = ReturnType<typeof useHipPlannerStore> & HipPlannerState;

export function execute(actionId: string, action: () => void | Promise<void>): Promise<void> {
    return useHipPlannerStore().execute(actionId, action);
}

export function executeOperation(operationId: PlannerOperation, operation: () => void | Promise<void>): Promise<void> {
    return useHipPlannerStore().executeOperation(operationId, operation);
}

/** Set the current-operation value if not currently aborted or in error */
function setCurrentOperation(store: HipPlannerStore, operation: PlannerOperation | null | 'error' | 'aborted') {
    if (store.currentOperation === 'error' || store.currentOperation === 'aborted') {
        return;
    } else {
        store.currentOperation = operation;
    }
}
