import { BaseLevels, BaseLogger } from 'anylogger';
import { taggedLogger } from '@/util/taggedLogger';
import { defaultLevelLogger, LogFunction } from '@/util/defaultLevelLogger';

/**
 * Default tag applied to validation logging
 */
const VALIDATION_LOG_TAG = 'validation' as const;

export function validationLogger(nameOrLogger?: string | BaseLogger): LogFunction {
    return defaultLevelLogger(validationLogLevel(), taggedLogger(VALIDATION_LOG_TAG, nameOrLogger));
}

/**
 * The default log-level for validation logging
 */
const VALIDATION_DEFAULT_LOG_LEVEL = 'debug' as const;

let VALIDATION_LOG_LEVEL: keyof BaseLevels | undefined = undefined;

function validationLogLevel(): keyof BaseLevels {
    if (VALIDATION_LOG_LEVEL !== undefined) {
        return VALIDATION_LOG_LEVEL;
    }

    for (const entry of document.cookie.split(';')) {
        const match = entry.match(/.*validation-logging=(.*)/);
        if (match) {
            const level = match[1];
            if (isKnownLogLevel(level)) {
                console.info(`Found cookie ${match[0]} - creating validation loggers at '${level}' level`);
                VALIDATION_LOG_LEVEL = level;
                return level;
            }
            console.warn(
                `Found cookie ${match[0]} - unknown level '${level}' (expected one of ${logLevels.join(', ')})`
            );
        }
    }

    VALIDATION_LOG_LEVEL = VALIDATION_DEFAULT_LOG_LEVEL;
    return VALIDATION_LOG_LEVEL;
}

const logLevels = ['error', 'warn', 'info', 'log', 'debug', 'trace'];

function isKnownLogLevel(level: string): level is keyof BaseLevels {
    return logLevels.includes(level);
}

