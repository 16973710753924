import { Vector3 } from 'three';
import { asVector3, AsVector3 } from '@/lib/base/ThreeUtil';

/**
 *  Default value tolerance in numerical calculations: this needs to be a small positive value.
 */
const DEFAULT_TOLERANCE = 1e-8;

/** @returns a new vector multiplied by the scalar */
export function multiplyScalar(vector: AsVector3, scalar: number): Vector3 {
    return asVector3(vector).multiplyScalar(scalar);
}

/**
 * @returns a new vector with the result of summing the given vectors.
 *
 * ```
 *    Note: It can be called with one, two or multiple arguments.
 *    e.g:
 *    addVectors(v1) => v1
 *    addVectors(v1, v2) => v1+v2
 *    addVectors(v1, v2, v3) => v1+v2+v3
 *    addVectors(v1, v2, v3, ...vn) => v1+v2+v3+...vn,
 * ```
 */
export function addVectors(...vectors: AsVector3[]): Vector3 {
    if (vectors.length === 0) {
        return new Vector3();
    } else {
        const [head, ...tail] = vectors;
        return asVector3(head).add(addVectors(...tail));
    }
}

/**
 * Create a unit-vector (a vector of length 1) with the same direction as the given vector
 */
export function normalize(vector: Vector3): Vector3 {
    if (vector.length() < DEFAULT_TOLERANCE) {
        throw Error('Attempting to normalize zero vector');
    }
    return vector.clone().normalize();
}

/**
 * Check whether the given vectors lie on the same line, meaning they have either exactly
 * the same or exactly opposite directions
 */
export function areCollinear(vector1: Vector3, vector2: Vector3): boolean {
    return vector1.clone().cross(vector2).length() < DEFAULT_TOLERANCE;
}

/**
 * Create a unit vector (a vector of length 1) that is orthogonal to both the given vectors.
 */
export function orthogonalUnit(vector1: Vector3, vector2: Vector3): Vector3 {
    return normalize(vector1.clone().cross(vector2));
}

/**
 * Return true if the angle between vector1 and vector2 is acute (less than 90 degrees)
 */
export function areAcute(vector1: Vector3, vector2: Vector3): boolean {
    return vector1.dot(vector2) > 0;
}
