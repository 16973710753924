
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component({ components: {} })
    export default class HTMLContent extends Vue {
        @Prop({ required: true })
        public value!: string;

        // return JSON.stringify(JSON.parse(value), null, 2);

        get formattedValue(): string {
            // replace \n with <br/>
            return this.value.replace(/\n/g, '<br />');
        }
    }
