
    import { Component, Vue } from 'vue-property-decorator';
    import InactivityDialog from '@/components/idle/InactivityDialog.vue';
    import { AuthenticatorEvent } from '@/lib/http/AuthenticatorService';
    import { IdleTimer } from '@/components/idle/IdleTimer';
    import anylogger from 'anylogger';
    import { IdleTimerState } from '@/components/idle/IdleTimerState';
    import { dispatchRestartIdleTimer } from '@/components/idle/events';
    import { LOG_OUT_TOTAL_INACTIVITY_TIME_OUT, LOG_OUT_COUNTDOWN_TIME } from '@/components/idle/constants';
    import AnalyticsUtil from '@/lib/analytics/AnalyticsUtil';

    const log = anylogger('InactivityObserver');

    /**
     * A component that controls the visibility of the {@link InactivityDialog}.
     * It is responsible for checking the global state for 'idleness'
     */
    @Component({ components: { InactivityDialog } })
    export default class InactivityObserver extends Vue {
        /**
         * The dialog will be visible if either:
         * - The user has been idle for certain time, so the dialog is displayed.
         * - Once the dialog is shown, the user has not clicked the 'Im back' button.
         *
         * Once the dialog is shown, the UX decision is to hide the dialog only when
         * the user clicks the button 'I'm back'.
         */
        protected isDialogVisible = false;

        private idleTimer: IdleTimer | null = null;
        private countdownTimeout = LOG_OUT_COUNTDOWN_TIME;

        protected created(): void {
            this.$authEvent.$on(AuthenticatorEvent.authRevoke, this.onAuthRevoke);

            this.idleTimer = new IdleTimer({
                timeout: LOG_OUT_TOTAL_INACTIVITY_TIME_OUT,
                beforeTimeout: this.countdownTimeout,
                onStateChange: this.onStateChange.bind(this),
            });
        }

        protected beforeDestroy(): void {
            this.$authEvent.$off(AuthenticatorEvent.authRevoke, this.onAuthRevoke);
            this.idleTimer?.stop();
        }

        private async onAuthRevoke(): Promise<void> {
            log.debug('Inactivity observer auth revoked');
            this.idleTimer?.stop();
        }

        private onStateChange(state: IdleTimerState): void {
            switch (state) {
                case IdleTimerState.Active:
                    this.onActive();
                    break;
                case IdleTimerState.BeforeTimeout:
                    this.onBeforeTimeout();
                    break;
                case IdleTimerState.Timeout:
                    this.onTimeout();
                    break;
                default:
                    throw new Error('unexpect state: %s', state);
            }
        }

        private onKeepMeLogged(): void {
            this.hideDialog();
            dispatchRestartIdleTimer();
        }

        private onActive(): void {
            this.hideDialog();
        }

        private onBeforeTimeout(): void {
            this.showDialog();
        }

        private onTimeout(): void {
            this.hideDialog();
            this.logOut();
            AnalyticsUtil.event(this.$gtag, this.$route.name, AnalyticsUtil.Event.InactivityTimeoutWhileUsingApp);
        }

        private hideDialog() {
            this.isDialogVisible = false;
        }

        protected showDialog(): void {
            this.isDialogVisible = true;
        }

        private logOut(): void {
            this.$emit('logout');
        }
    }
