
    import { Component, Prop, Vue } from 'vue-property-decorator';

    interface Content {
        label: string,
        value: string,
    }

    @Component
    export default class AppSimpleTable extends Vue {
        @Prop(Array) readonly contents: Content[] | undefined;
        @Prop(Boolean) readonly showHeaderRow: boolean | undefined;
    }
