import { isNotEmpty, isFloat, isInRange } from '@/stores/utils/validation';
import { SpinopelvicState } from '@/stores/spinopelvic/types';

const LOWER_LIMIT = -360;
const UPPER_LIMIT = 360;
const INCLUSIVE = true;

export const allFieldsEmpty = (state: SpinopelvicState): boolean => {
    return (!isNotEmpty(state.standingPelvicTilt) && !isNotEmpty(state.pelvicFemoralAngle) &&
        !isNotEmpty(state.lumbarLordosis) && !isNotEmpty(state.sacralSlope) &&
        !isNotEmpty(state.pelvicTilt));
};

export const isPopulated = (value: string): boolean => {
    return isNotEmpty(value) && isFloat(value, 2) && isInRange(value, LOWER_LIMIT, UPPER_LIMIT, INCLUSIVE);
};

export const validators: { [key: string]: (state: SpinopelvicState) => boolean } = {
    standingPelvicTilt: (state) =>
        isPopulated(state.standingPelvicTilt) || allFieldsEmpty(state),
    pelvicFemoralAngle: (state) =>
        isPopulated(state.pelvicFemoralAngle) || allFieldsEmpty(state),
    lumbarLordosis: (state) =>
        isPopulated(state.lumbarLordosis) || allFieldsEmpty(state),
    sacralSlope: (state) =>
        isPopulated(state.sacralSlope) || allFieldsEmpty(state),
    pelvicTilt: (state) =>
        isPopulated(state.pelvicTilt) || allFieldsEmpty(state),
};

export const isValid = (state: SpinopelvicState): boolean =>
    Object
        .values(validators)
        .map(validator => validator(state))
        .every(result => result === true);
