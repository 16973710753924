import LandmarksResource from '@/lib/api/resource/case/LandmarksResource';
import { Vector3 } from 'three';
import { StudyLandmarkUtil } from '@/lib/api/representation/case/study/landmarks/StudyLandmarkUtil';
import { asVector3 } from '@/lib/base/ThreeUtil';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { PlannerLoadingContext } from '@/hipPlanner/stores/case/loading';

/**
 * Calculate the 'origin' point for a hip case. This is a point in the centre of the pelvis between
 * the left and right asis.
 */
export async function calculateSceneOrigin(
    study: StudyRepresentation, context: PlannerLoadingContext): Promise<Vector3> {
    const landmarks = await LandmarksResource.getStudyLandmarks(study, context.apiOptions);
    if (!landmarks) {
        throw Error('Cannot calculate scene-origin: study is missing landmarks');
    }

    const leftItem = StudyLandmarkUtil.getLandmark(landmarks, 'pelvis', 'pelvis-lasis');
    const rightItem = StudyLandmarkUtil.getLandmark(landmarks, 'pelvis', 'pelvis-rasis');

    if (leftItem && rightItem) {
        // Create the scene origin from the midpoint between the left and right points
        return asVector3(leftItem.coordinates)
            .add(asVector3(rightItem.coordinates))
            .divideScalar(2);
    } else {
        throw Error('Cannot calculate scene-origin: pelvis landmarks are missing');
    }
}
