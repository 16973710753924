import { LineSegments, Mesh, Plane, Vector3 } from 'three';
import ViewerUtils from '@/lib/planning/viewer/ViewerUtils';
import { AcidObject3dBase, AcidObject3dType } from '@/lib/planning/objects-3D/AcidObject3d';
import { CrossSectionPlane } from '@/lib/planning/cross-section/CrossSectionPlane';
import debounce, { DebounceResult } from '@/lib/base/AsyncDebounce';
import { CancelToken } from 'axios';
import anylogger from 'anylogger';
import { CrossSectionPlaneName } from '@/lib/planning/cross-section/types';
import { OutlineModel, OutlineUtils } from '@/lib/planning/cross-section/OutlineUtils';
import { Object3DWrapperUtil } from '@/lib/planning/objects-3D/Object3DWrapperUtil';
import { formatArrayNumber } from '@/lib/filters/format/formatArrayNumber';
import PlaneFactory from '@/lib/viewer/PlaneFactory';
import { OutlineParams } from '@/lib/planning/cross-section/OutlineParams';

const log = anylogger('CrossSectionPlaneModel');

/**
 * Slicing functionality control (eg. enabled/disabled) of planes that are part of an object's material
 * cannot be individually controlled, therefore we need to "fake hide" the planes by setting their constant
 * value somewhere very from its origin point.
 * A constant positive value of 10000 (1mm = 1 spacial unit in ThreeJS scene. eg. 10m = 10000 ) should be
 * far enough that the plane will not cut through any object rendered into the scene
 */
const DISABLED_OFFSET = 10000;

/**
 * Cross-section plane model class
 */
export class CrossSectionPlaneModel implements CrossSectionPlane {
    get outlineCollection(): AcidObject3dBase {
        return this._outlineCollection;
    }

    get name(): CrossSectionPlaneName {
        return this._name;
    }

    get direction(): Vector3 {
        return this._plane.normal;
    }

    set direction(value: Vector3) {
        this.updatePlaneNormal(value);
        this.updateMeshNormal(value);
    }

    get visible(): boolean {
        return this._mesh.visible;
    }

    set visible(isVisible: boolean) {
        this._mesh.visible = isVisible;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    /**
     * Sets internal {@link _enabled} flag and sync plane and mesh with current state
     */
    set enabled(isEnabled: boolean) {
        this._enabled = isEnabled;
        this.syncPlaneAndMeshWithCurrentState();
    }

    get plane(): Plane {
        return this._plane;
    }

    get mesh(): Mesh {
        return this._mesh;
    }

    get object3d(): Mesh {
        return this._mesh;
    }

    /** The cross-section plane, used for slicing the 3d models */
    private readonly _plane: Plane;
    /** The cross-section plane mesh representation, used for visualisation */
    private readonly _mesh: Mesh;

    private readonly _outlineCollection: AcidObject3dBase;

    /** Collection of objects that will be outlined when this cross-section plane is active */
    private readonly _outlineModels: OutlineModel[];

    /**
     * Function that updates the cross-section outline only once every 500 milliseconds.
     * We need to debounce this method, because it is a computationally heavy and doesn't happen instantaneously
     */
    private readonly _updateOutline: DebounceResult<void, never>;

    /**
     * whether the plane is enabled or not
     */
    private _enabled = false;

    /**
     * Get a lookAt point that the plane mesh will be facing at
     */
    private static getPlaneMeshLookAtPoint(position: Vector3, direction: Vector3) {
        return ViewerUtils.getPositionAlongDirection(position.clone(), direction.clone(), 10);
    }

    /**
     * Make a Plane using position and direction
     */
    private static makePlane(position: Vector3, direction: Vector3): Plane {
        const plane = new Plane(direction, 0);
        plane.translate(position);
        return plane;
    }

    /**
     * Make a plane 3d object representation of this cross-section plane
     */
    public static makePlaneMesh(position: Vector3, direction: Vector3): Mesh {
        return PlaneFactory.makePlaneMesh(position, direction);
    }

    /**
     * Initialise the cross-section model
     */
    constructor(
        private _name: CrossSectionPlaneName,
        /* The cross-section plane WORLD position */
        private _worldPosition: Vector3,
        direction: Vector3,
        outlineParams: OutlineParams,
        /**
         * Flag to control if mesh visibility must always be in sync with enabled flag
         * e.g.: Used for neck cut cross section plane
         * TODO. Change in favour of enum: always visible, optional, not allowed / subclass pattern
         */
        public readonly visibleWhenEnabled: boolean) {
        this._outlineCollection = new AcidObject3dBase({
            type: AcidObject3dType.Group,
            name: outlineParams.name,
            alias: outlineParams.name,
        });
        this._outlineModels = outlineParams.models;
        this._plane = CrossSectionPlaneModel.makePlane(this._worldPosition, direction);
        this._mesh = PlaneFactory.makePlaneMesh(this._worldPosition, direction);
        // default plane mesh visibility is 'false'
        this.visible = false;
        // The cross-section is disabled on initialisation and enabled when needed
        this.enabled = false;
        // update cross-section outline using an async debounce, with a 500 milliseconds debounce
        this._updateOutline = debounce(this.performUpdateOutline.bind(this), 500);
    }

    /**
     * Update the cross-section outline of the objects that share this cross-section plane
     */
    public updateOutline(): void {
        this.cancelUpdateAndClearOutline();
        if (this.enabled) {
            this.debounceOutlineUpdate();
        }
    }

    /**
     * Sets internal variable {@link _worldPosition} and sync plane and mesh with that position
     * @param worldPosition
     */
    public updateWorldPosition(worldPosition: Vector3): void {
        this.setWorldPosition(worldPosition);
        this.syncPlaneAndMeshWithCurrentState();
    }

    /**
     * Syncs plane and mesh with current internal state
     *
     * Note:
     * The plane (not the mesh):
     * - When disabled, it is moved to somewhere in the space where it is not visible, so it fakes
     * the hidden functionality
     * - When enabled, the plane has to be brought to the current position
     *
     * When enabling - disabling the mesh:
     * - The mesh can easily be set to visible / not visible, so there is no need bring it back to the current position
     * - Given the mesh is visible or not visible, and its presence does not affect the outline or clippin planes, it
     * is always in sync with the world position
     */
    private syncPlaneAndMeshWithCurrentState(): void {
        if (this.enabled) {
            this.movePlaneToPosition(this._worldPosition);
            this.updateOutline();
        } else {
            this.movePlaneToInvisiblePlace();
            this.cancelUpdateAndClearOutline();
        }

        this.moveMeshToPosition(this._worldPosition);
        if (this.visibleWhenEnabled) {
            this.visible = this.enabled;
        }

        log.debug(
            'Sync cross section \'%s\' end: plane constant: %d, position: %s, world position: %s',
            this.name,
            this.plane.constant,
            formatArrayNumber(this._mesh.position.toArray()),
            formatArrayNumber(this._mesh.getWorldPosition(new Vector3()).toArray()));
    }

    private setWorldPosition(position: Vector3): void {
        this._worldPosition = position.clone();
    }

    /**
     * Move mesh to **local** worldPosition
     *
     * Note:
     * On hip planning, the mesh is attached to the scene, meaning its worldPosition is in the world coordinate system
     * On shoulder planning, the mesh is attached to the reference object, e.g.: baseplate
     */
    private moveMeshToPosition(worldPosition: Vector3): void {
        if (this._mesh.parent) {
            const localPosition = this._mesh.parent.worldToLocal(worldPosition.clone());
            this._mesh.position.copy(localPosition.clone());
        } else {
            this._mesh.position.copy(worldPosition.clone());
        }
    }

    private movePlaneToPosition(worldPosition: Vector3): void {
        this._plane.constant = 0;
        this._plane.translate(worldPosition);
    }

    private movePlaneToInvisiblePlace(): void {
        this._plane.constant = DISABLED_OFFSET;
        log.debug('Disabling cross-section by setting the constant value to %s', this._plane.constant);
    }

    /**
     * This is the preferred method to synchronize the plane and the outline with the mesh current position
     */
    public async syncPlaneAndOutlineWithMesh(): Promise<void> {
        this.cancelUpdateAndClearOutline();
        this.syncPlaneWithMeshPosition();
        await this.debounceOutlineUpdate();
    }

    /**
     * The main method used for calling the update outline method
     */
    private async debounceOutlineUpdate(): Promise<void> {
        try {
            return await this._updateOutline.function();
        } catch (e) {
            log.debug('Debounced cross-section outline update failure ignored: %s', e);
        }
    }

    private cancelExistingOutlineUpdate(): void {
        // TODO: this should check if call is in progress?
        if (this._updateOutline) {
            this._updateOutline.cancel();
        }
    }

    /**
     * This is the function that performs the cross-section outline update within the context of `debounce`
     */
    private async performUpdateOutline(token: CancelToken): Promise<void> {
        const outlineCollection = this._outlineCollection;
        OutlineUtils.clearCrossSectionOutlines(outlineCollection);

        const aliases = Object3DWrapperUtil.aliases(this._outlineModels);
        log.debug('Make outline called for models (%d): %s', aliases.length, aliases.join(', '));
        const contourLines = await OutlineUtils.makeOutlineObjects(token, this._outlineModels, this._plane);

        contourLines.forEach((lineSegment: LineSegments) => outlineCollection.theObject.add(lineSegment));

        log.debug('Cross-section %s outline updated', this._name);
    }

    /**
     * Set the new direction and position of the plane based on the the object3d/mesh current position
     */
    private syncPlaneWithMeshPosition(): void {
        const { object3d } = this;
        const direction = object3d.getWorldDirection(new Vector3());
        const position = object3d?.getWorldPosition(new Vector3());
        this.updatePlaneNormal(direction);
        this.movePlaneToPosition(position);
        log.debug('Plane position and direction sync with mesh');
    }

    /**
     * - Cancel existing update outline (if any) and clear outlines
     */
    private cancelUpdateAndClearOutline(): void {
        this.cancelExistingOutlineUpdate();
        OutlineUtils.clearCrossSectionOutlines(this._outlineCollection);
    }

    private updateMeshNormal(value: Vector3): void {
        this._mesh.lookAt(CrossSectionPlaneModel.getPlaneMeshLookAtPoint(this._mesh.position, value));
    }

    private updatePlaneNormal(value: Vector3) {
        this._plane.normal.copy(value);
    }
}
