import { NameRepresentation } from '@/lib/api/representation/NameRepresentation';
import { PatientName } from '@/lib/dicom/PatientName';

export class NameRepresentationUtil {
    /**
     * Format a {@link NameRepresentation} to a human readable string.
     */
    public static format(name?: NameRepresentation | null): string {
        if (name) {
            if (name.family && name.given && name.middle) {
                return `${name.given} ${name.middle} ${name.family}`;
            } else if (name.family && name.given) {
                return `${name.given} ${name.family}`;
            } else if (name.family) {
                return `${name.family}`;
            } else if (name.given) {
                return `${name.given}`;
            }
        }
        return '';
    }

    /**
     * Format a {@link NameRepresentation} to a human readable string.
     */
    public static fromDicom(name?: PatientName | null): NameRepresentation | null {
        if (name) {
            return {
                title: name.prefix,
                family: name.familyName,
                given: name.givenName,
                middle: name.middleName,
            };
        }

        return null;
    }
}
