
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { MessageLevel, MessageRepresentation } from '@/lib/api/representation/MessageRepresentation';
    import MessageResource from '@/lib/api/resource/case/MessageResource';
    import Ago from '@/components/lib/Ago.vue';
    import { DateTime, FixedOffsetZone, ToRelativeUnit } from 'luxon';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import anylogger from 'anylogger';

    const log = anylogger('NotificationListItem');

    @Component({ components: { Ago } })
    export default class NotificationListItem extends Vue {
        /**
         * This is the sparsely populated message that will populated in the
         * created method (below). The property must be provided by the parent.
         */
        @Prop({ required: true })
        public value!: MessageRepresentation;

        protected isLoading = true;

        /**
         * Get the severity level icon of a message item
         */
        protected get messageIcon(): string {
            switch (this.value.level) {
                case MessageLevel.Info:
                    return 'mdi-information';
                case MessageLevel.Warning:
                    // return 'mdi-alert-circle';
                    return 'mdi-information';
                case MessageLevel.Error:
                    // return 'mdi-alert';
                    return 'mdi-information';
            }
            return '';
        }

        /**
         * Get the severity level color of a message item
         */
        protected get messageItemColor(): string {
            switch (this.value.level) {
                case MessageLevel.Info:
                    return 'custom-blue-button';
                case MessageLevel.Warning:
                    return 'custom-yellow';
                case MessageLevel.Error:
                    return 'custom-red';
            }
            return '';
        }

        /**
         * Get the created on representation of the message as string (eg. "2 hours ago"). Every notifcation
         * should have a timestamp.
         */
        protected get createdOn(): string | null {
            const timestamp = this.value?.timestamp;
            if (timestamp) {
                const when = DateTime.fromISO(timestamp);
                if (when) {
                    return when.toRelativeCalendar({ unit: this.getDateTimeUnit(when) });
                } else {
                    log.warn('Failed to parse timestamp from notifcation message');
                }
            } else {
                log.warn('Notification has no timestamp');
            }
            return null;
        }

        /**
         * Get the DateTime 'unit' option to get used when formatting a date/time to string. The input
         * datetime must have a timezone. It is expected that the input datetime in the UTC timezone.
         *
         * Note: this uses Luxon date times - Luxon doesn't support 'utc now'.
         *
         * @see {@link https://github.com/moment/luxon/issues/364}
         */
        protected getDateTimeUnit(datetime: DateTime): ToRelativeUnit {
            const now = DateTime.fromMillis(Date.now(), { zone: FixedOffsetZone.utcInstance });
            const ago = now.diff(datetime, ['days', 'hours', 'minutes', 'seconds']);

            switch (true) {
                case ago.days >= 1:
                    return 'days';
                case ago.hours > 1:
                    return 'hours';
                case ago.minutes >= 1:
                    return 'minutes';
                // check that the minutes > 0 in case the datetime was not converted properly
                case ago.minutes < 1 && ago.minutes > 0:
                    return 'seconds';
                default:
                    return 'days';
            }
        }

        @IsLoading('isLoading')
        protected async onIntersect(
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
            isIntersecting: boolean): Promise<void> {
                if (isIntersecting) {
                    if (this.value) {
                        await MessageResource.getCaseMessage(this.value, this.$apiOptions);
                    } else {
                        log.debug('Case message representation not loaded. Cannot make NotificationListItem');
                    }
                }
            }
    }
