/**
 * Product name enumeration
 *
 * The string values match the name of the procedure
 */
export enum Procedure {
    Hip = 'hip',
}

/**
 * The name of the procedure.
 *
 * Spine do not have procedure
 *
 * Known (and supported) values ia 'hip' only.
 */
export type ProcedureType = Procedure.Hip;

export const AVAILABLE_PROCEDURES = [Procedure.Hip];
