import { AxiosResponse } from 'axios';
import contentDisposition from 'content-disposition';
import ContentType from '@/lib/http/mimetype';

export default class DownloadUtil {
    /**
     * Perform a logical 'save-as' operation in the browser to save a response to a file on the local
     * computer.
     *
     * The response must have a content type of 'blob'.
     */
    public static httpResponseSaveAs(response: AxiosResponse<Blob>, filename: string): void {
        const blob = new Blob(
            [response.data],
            { type: DownloadUtil.getContentType(response.headers, ContentType.Pdf) });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = DownloadUtil.getContentDispositionFilename(response.headers, filename);
        link.click();
    }

    /**
     */
    public static getContentType(headers: any, defaultValue: string): string {
        return headers['content-type'] ? headers['content-type'] : defaultValue;
    }

    /**
     * CORS will generally prohibit the client from accessing the Content-Disposition
     * http header, unless explicitly allowed.
     *
     * @see {@link https://github.com/jshttp/content-disposition#readme}
     */
    public static getContentDispositionFilename(headers: any, defaultValue: string): string {
        if (headers['content-disposition']) {
            const disposition = contentDisposition.parse(headers['content-disposition']);
            if (disposition?.parameters.filename) {
                return disposition.parameters.filename;
            }
        }
        return defaultValue;
    }
}
