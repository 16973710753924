
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { DicomMessageLevel } from '@/lib/dicom/DicomSeries';

    /**
     * A message level icon
     */
    @Component
    export default class MessageLevel extends Vue {
        /** The message level to show an icon for */
        @Prop({ required: true })
        public value!: DicomMessageLevel;
    }
