
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import TitleUtil from '@/components/TitleUtil';
    import CaseTitle from '@/components/title/CaseTitle.vue';
    import Notifications from '@/components/notifications/Notifications.vue';
    import ProjectStoreProvider from '@/hipPlanner/components/state/project/ProjectStoreProvider.vue';
    import Plan from '@/views/case/Plan.vue';
    import ProjectStore from '@/hipPlanner/components/state/project/ProjectStore';

    /**
     * A top level page with a set of v-cards with plans for the case (project).
     *
     * Each project will have zero or more plans. After the surgical details and the
     * DICOM have been configured, segmentation and other processing is done that will
     * create an initial 'ACID' plan with the automated plan. The surgeon can then modify
     * the parameters of the case to approve additional plans.
     *
     * Note: This component is responsible for loading data. Other than the skeleton loader it has no
     * visible elements. The task of refreshing and keeping the list of plans up to date is the responsibility
     * of the child {@link Plans} component.
     */
    @Component({ components: { ProjectStoreProvider, Plan } })
    export default class PlanContainerLoader extends Vue {
        @Prop({ required: true })
        public readonly apiUri!: string;

        private onInit(store: ProjectStore) {
            TitleUtil.$emit(this, {
                titleComponentType: CaseTitle,
                titleData: { project: store.project },
                notificationComponentType: Notifications,
                notificationData: { project: store.project },
            });
        }
    }
