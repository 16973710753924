import { MetalType } from '@/lib/planning/objects-3D/AcidObject3d';

import anylogger from 'anylogger';
import { Bones } from '@/lib/constants/Bones';
import { SegmentedModelRepresentation } from '@/lib/api/representation/case/study/SegmentedModelRepresentation';
import { SegmentedModelSubTypes } from '@/lib/api/representation/case/study/types';
import { HipImplantAlias } from '@/hipPlanner/assembly/objects/HipImplantAlias';

const log = anylogger('AcidPlannerModeSettings');

export type hipPlannerModeObjectSettingKey =
    MetalType.Metal |
    Bones.Unknown |
    Bones.OperativeFemur |
    Bones.ContraFemur |
    Bones.OperativeHemi |
    Bones.ContraHemi |
    Bones.Sacrum |
    Bones.InnerSurface |
    HipImplantAlias.Stem |
    HipImplantAlias.Head |
    HipImplantAlias.Cup |
    HipImplantAlias.Liner;

export enum ModelVisibility {
    Solid = 'solid',
    Hidden = 'hidden',
    Transparent = 'transparent',
    FrontSideTransparent = 'frontSideTransparent'
}

export interface ObjectMaterial {
    visibility: ModelVisibility;
    opacity?: number;
}

export type HipPlannerModeRepresentation = {
    [objectSetting in hipPlannerModeObjectSettingKey]: ObjectMaterial;
};

function makeSolid(): ObjectMaterial {
    return {
        visibility: ModelVisibility.Solid,
    };
}

function makeFrontSideTransparent(opacity: number): ObjectMaterial {
    return {
        visibility: ModelVisibility.FrontSideTransparent,
        opacity,
    };
}

function makeHidden(): ObjectMaterial {
    return {
        visibility: ModelVisibility.Hidden,
    };
}

const INNER_CORTICAL_SURFACE_OPACITY = 0.6;
const FEMUR_OPACITY = 0.5;

/**
 * Hip planner mode settings for each of the available modes (combined, stem, and cup)
 */
export const hipPlannerModeObjectSettings = {
    combined: {
        [Bones.OperativeFemur]: makeFrontSideTransparent(FEMUR_OPACITY),
        [Bones.ContraFemur]: makeSolid(),
        [Bones.OperativeHemi]: makeSolid(),
        [Bones.ContraHemi]: makeSolid(),
        [Bones.InnerSurface]: makeFrontSideTransparent(INNER_CORTICAL_SURFACE_OPACITY),
        [Bones.Sacrum]: makeSolid(),
        metal: makeSolid(),
        stem: makeSolid(),
        head: makeSolid(),
        cup: makeSolid(),
        liner: makeSolid(),
    } as HipPlannerModeRepresentation,

    stem: {
        [Bones.OperativeFemur]: makeFrontSideTransparent(FEMUR_OPACITY),
        [Bones.ContraFemur]: makeSolid(),
        [Bones.OperativeHemi]: makeSolid(),
        [Bones.ContraHemi]: makeSolid(),
        [Bones.InnerSurface]: makeFrontSideTransparent(INNER_CORTICAL_SURFACE_OPACITY),
        [Bones.Sacrum]: makeSolid(),
        metal: makeSolid(),
        stem: makeSolid(),
        head: makeSolid(),
        cup: makeHidden(),
        liner: makeHidden(),
    } as HipPlannerModeRepresentation,

    cup: {
        [Bones.OperativeFemur]: makeHidden(),
        [Bones.ContraFemur]: makeSolid(),
        [Bones.OperativeHemi]: makeSolid(),
        [Bones.ContraHemi]: makeSolid(),
        [Bones.InnerSurface]: makeFrontSideTransparent(INNER_CORTICAL_SURFACE_OPACITY),
        [Bones.Sacrum]: makeSolid(),
        metal: makeSolid(),
        stem: makeHidden(),
        head: makeHidden(),
        cup: makeSolid(),
        liner: makeSolid(),
    } as HipPlannerModeRepresentation,
};

/**
 */
export function mapStudySegmentModelToType(model: SegmentedModelRepresentation): hipPlannerModeObjectSettingKey {
    if (model) {
        if (model.sub_type === SegmentedModelSubTypes.Segmentation) {
            if (model.type === 'operative-femur') {
                return Bones.OperativeFemur;
            } else if (model.type === 'contralateral-femur') {
                return Bones.ContraFemur;
            } else if (model.type === 'operative-femur-internal') {
                return Bones.InnerSurface;
            } else if (model.type === 'sacrum') {
                return Bones.Sacrum;
            } else if (model.type === 'operative-hemipelvis') {
                return Bones.OperativeHemi;
            } else if (model.type === 'contralateral-hemipelvis') {
                return Bones.ContraHemi;
            } else if (model.type === 'metal') {
                return MetalType.Metal;
            }
            //     *       OperativeFemur =
            // *       OperativeFemurInternal = 'operative-femur-internal'
            // *       OperativeHemipelvis = 'operative-hemipelvis'
            // *       ContralateralFemur = 'contralateral-femur'
            // *       ContralateralHemipelvis = 'contralateral-hemipelvis'
            // *       Sacrum = 'sacrum'
            //     *
            //     *       #
            //     *       # Models for the shoulder planner
            // *       #
            // *       OperativeHumerus = 'operative-humerus'
            // *       OperativeHumerusInternal = 'operative-humerus-internal'
            // *       OperativeScapula = 'operative-scapula'
            // *       OperativeClavicle = 'operative-clavicle'
            // *       ContralateralHumerus = 'contralateral-humerus'
            // *       ContralateralScapula = 'contralateral-scapula'
            // *       ContralateralClavicle = 'contralateral-clavicle'
            //
        }
        log.warn('Expected bone of type %s, %s to be supported', model.type, model.sub_type);
    }
    return Bones.Unknown;
}
