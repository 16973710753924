import { AsVector3 } from '@/lib/base/ThreeUtil';
import PlaneFactory, { PlaneGeometryParameters } from '@/lib/viewer/PlaneFactory';
import { MeshBasicMaterialParameters } from 'three/src/materials/MeshBasicMaterial';
import AcidObject3d, { ObjectProperties } from '@/lib/planning/objects-3D/AcidObject3d';

/**
 * An plane in 3d represented
 */
export default class Plane3D extends AcidObject3d {
    constructor(
        properties: ObjectProperties,
        private _position: AsVector3,
        private _direction: AsVector3,
        private _materialParameter: MeshBasicMaterialParameters = {},
        private _geometryParameter: PlaneGeometryParameters = { width: 300, height: 300 },
        private _up?: AsVector3) {
        super(properties);
        this.theObject = PlaneFactory.makePlaneMesh(
            this._position, this._direction, this._materialParameter, this._geometryParameter, this._up);
        this.theObject.name = `plane-${this.name}`;
    }
}
