import {
    SpinopelvicRepresentation,
    SpinopelvicState,
} from '@/stores/spinopelvic/types';

export const stateFromResponse = (representation: SpinopelvicRepresentation): SpinopelvicState => {
    return {
        standingPelvicTilt: representation.standing_pelvic_tilt ? String(representation.standing_pelvic_tilt) : '',
        pelvicFemoralAngle: representation.pelvic_femoral_angle ? String(representation.pelvic_femoral_angle) : '',
        lumbarLordosis: representation.lumbar_lordosis ? String(representation.lumbar_lordosis) : '',
        sacralSlope: representation.sacral_slope ? String(representation.sacral_slope) : '',
        pelvicTilt: representation.pelvic_tilt ? String(representation.pelvic_tilt) : '',
    };
};

export const requestFromState = (state: SpinopelvicState): SpinopelvicRepresentation => {
    return {
        standing_pelvic_tilt: state.standingPelvicTilt !== '' ? Number(state.standingPelvicTilt) : null,
        pelvic_femoral_angle: state.pelvicFemoralAngle !== '' ? Number(state.pelvicFemoralAngle) : null,
        lumbar_lordosis: state.lumbarLordosis !== '' ? Number(state.lumbarLordosis) : null,
        sacral_slope: state.sacralSlope !== '' ? Number(state.sacralSlope) : null,
        pelvic_tilt: state.pelvicTilt !== '' ? Number(state.pelvicTilt) : null,
    };
};


