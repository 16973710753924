import {
    AnyCaseConfigurationDataRepresentation,
    CaseSurgeonConfigurationDataRepresentation,
} from '@/components/case-settings/utils/ConfigureCaseUtil';
import UserResource from '@/lib/api/resource/user/UserResource';
import { CaseCreateDataRepresentation, CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import DateUtil from '@/lib/DateUtil';
import CaseResource from '@/lib/api/resource/case/CaseResource';
import { LinkUtil, Uri } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import anylogger from 'anylogger';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import { map } from 'ramda';
import { NameRepresentation } from '@/lib/api/representation/NameRepresentation';
import { compact } from 'lodash';
import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';

const log = anylogger('provisionCase');

/**
 * Provision a case from the given config.
 *
 * This is usually because a provision file has been dragged onto the 'new case' button.
 */
export async function provisionCase(
    api: ApiRepresentation,
    apiOptions: Partial<CacheOptions>,
    config: AnyCaseConfigurationDataRepresentation): Promise<CaseRepresentation> {
    if (!config?.name || !config.product) {
        throw Error('Invalid case data');
    }

    const user = await UserResource.getMeUser(api, { ...apiOptions, forceLoad: true });
    if (!user) {
        throw Error('Invalid user');
    }

    const productUri = await UserResource.findUserProductByName(user, config.product, apiOptions);
    if (!productUri) {
        throw Error('Invalid product');
    }

    const surgeon = await findSurgeon(api, apiOptions, config.surgeon || {}, productUri);

    const createData: CaseCreateDataRepresentation = {
        product: productUri,
        name: DateUtil.replaceDateVariable(config.name) || '',
        description: DateUtil.replaceDateVariable(config.description),
        side: config.side,
        surgeon,
        patient: config.patient,
        specification: config.specification,
    };

    const caseRepresentation = await CaseResource.createCase(api, createData, apiOptions);
    if (!caseRepresentation) {
        throw Error('Failed to create case');
    }

    log.info('Case %s created', caseRepresentation);
    return caseRepresentation;
}

async function findSurgeon(
    api: ApiRepresentation,
    apiOptions: Partial<CacheOptions>,
    surgeonConfig: CaseSurgeonConfigurationDataRepresentation,
    product: Uri): Promise<Uri | undefined> {
    const surgeonCollection = await UserResource.getSurgeons(api, product, apiOptions);

    const email = surgeonConfig.email;
    const givenName = surgeonConfig?.name?.given;
    const familyName = surgeonConfig?.name?.family;

    if (!email && !givenName && !familyName) {
        throw Error('Surgeon has no given- or family-name, and no email');
    }

    log.info('Attempting to find surgeon matching %s', formatSurgeon(surgeonConfig));

    const availableSurgeons = surgeonCollection?.items ?? [];
    const matchingSurgeons = availableSurgeons.filter(surgeonMatches(surgeonConfig));

    if (matchingSurgeons.length === 0) {
        log.info('Failed to find matching surgeon from:\n%s',
            map(s => '  ' + formatSurgeon(s), availableSurgeons).join('\n'),
        );
        throw Error(`Failed to match surgeon ${formatSurgeon(surgeonConfig)}`);
    }

    if (matchingSurgeons.length > 1) {
        throw Error(`More than one surgeon matches ${formatSurgeon(surgeonConfig)}`);
    }

    const surgeon = matchingSurgeons[0];
    log.info('Matched surgeon %s', formatSurgeon(surgeon));

    const surgeonUri = LinkUtil.getUri(surgeon, LinkRelation.self);
    if (!surgeonUri) {
        throw Error('Failed to get surgeon uri');
    }

    return surgeonUri;
}

/**
 * Format info about a surgeon into a string
 */
function formatSurgeon(surgeon: { name?: NameRepresentation, email?: string }): string {
    const name = surgeon?.name;
    return compact([
        name?.given ?? null,
        name?.family ?? null,
        surgeon.email ?? null,
    ]).join(' ');
}

/**
 * Create a predicate that check whether an available surgeon matches the given config
 */
function surgeonMatches(config: CaseSurgeonConfigurationDataRepresentation): (surgeon: UserRepresentation) => boolean {
    const emailExpression = /([\w-.]+\+)*([\w-.]+@[\w-.]+)/;

    const matchesEmail = (surgeonEmail: string): boolean => {
        if (!config.email) {
            // If config email is undefined we always match
            return true;
        } else {
            // Otherwise check the surgeon email matches
            const matches = surgeonEmail.match(emailExpression);
            return !!matches && matches[2].toLowerCase() === config.email?.toLowerCase();
        }
    };

    const matchesName = (configName?: string, surgeonName?: string) => {
        if (!configName) {
            // If config name is undefined we always match
            return true;
        } else {
            // Otherwise check we have the surgeon name and the name matches
            return !!surgeonName && surgeonName.toLowerCase() === configName.toLowerCase();
        }
    };

    return (surgeon: UserRepresentation): boolean => {
        return matchesEmail(surgeon.email) &&
            matchesName(config.name?.given, surgeon.name?.given) &&
            matchesName(config.name?.family, surgeon.name?.family);
    };
}
