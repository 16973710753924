
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import LinkRelation from '@/lib/api/LinkRelation';
    import {
        SurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
    import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
    import { aperture } from 'ramda';
    import {
        SurgicalTemplateCollectionRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateCollectionRepresentation';
    import { LinkUtil } from 'semantic-link';
    import SurgicalTemplateJSONDiff
        from '@/components/case-manage/surgical-template/history/SurgicalTemplateJSONDiff.vue';
    import HipSurgicalTemplateHistoryContent
        from '@/components/case-manage/surgical-template/history/HipSurgicalTemplateHistoryContent.vue';
    import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
    import SurgicalTemplateHistoryRow
        from '@/components/case-manage/surgical-template/history/SurgicalTemplateHistoryRow.vue';
    import WithSurgicalTemplateProduct from '@/components/shared/with-product/WithSurgicalTemplateProduct.vue';

    type TableHeader = {
        text: string,
        value: string,
        width?: string
    }

    type DiffRow = {
        uri?: string,
        current: SurgicalTemplateRepresentation,
        previous: SurgicalTemplateRepresentation
    }

    /**
     * This is a case component **DEBUG** component that shows the admin the state of surgical template history
     *
     * The table use the v-intersect pattern, so data is loaded on demand as rows are intersected on the browser.
     */
    @Component({
        components: {
            WithSurgicalTemplateProduct,
            HipSurgicalTemplateHistoryContent,
            SurgicalTemplateHistoryRow,
            SurgicalTemplateJSONDiff,
        },
    })
    export default class SurgicalTemplateHistory extends Vue {
        @Prop({ required: true })
        value!: SurgicalTemplateRepresentation;

        protected isLoading = false;

        /** Property use by the v-data-table to track the expanded panel */
        protected expanded = [];
        protected itemsPerPage = -1; // -1 for no pagination.

        /** The history of surgical template. */
        protected history: SurgicalTemplateCollectionRepresentation | null = null;

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            this.history = await SurgicalTemplateResource.getHistory(this.value, this.$apiOptions);
        }

        protected get selfUri(): string {
            return getRequiredUri(this.value, LinkRelation.self);
        }

        protected get headers(): TableHeader[] {
            return [
                {
                    text: 'Id',
                    value: 'history-id',
                },
                {
                    text: 'User',
                    value: 'user',
                },
                {
                    text: 'Time',
                    value: 'time',
                    width: '10%',
                },
                ...this.headersByProduct,
                {
                    text: 'State',
                    value: 'state',
                },
                {
                    text: 'Record state',
                    value: 'recordState',
                },
                {
                    text: 'Components',
                    value: 'components',
                },
                {
                    text: 'Measurements',
                    value: 'measurements',
                },
                {
                    text: 'RoM',
                    value: 'range-of-motion',
                },
                {
                    text: 'Plan',
                    value: 'plan',
                },
                {
                    text: 'Models',
                    value: 'models',
                    width: '8%',
                },
                { text: '', value: 'data-table-expand' },
            ];
        }

        protected get headersByProduct(): TableHeader[] {
            return SurgicalTemplateUtil.withProduct<TableHeader[]>(this.value, this.hipHeaders);
        }

        private get hipHeaders(): TableHeader[] {
            return [
                {
                    text: 'Femoral system',
                    value: 'femoral',
                    width: '30%',
                },
                {
                    text: 'Acetabular system',
                    value: 'acetabular',
                    width: '30%',
                },
                {
                    text: 'Targets',
                    value: 'targets',
                },
            ];
        }

        /** the list (array) of cases (projects) */
        protected get historyItems(): SurgicalTemplateRepresentation[] {
            return this.history?.items ? this.history.items : [];
        }

        /**
         * @returns a list where each item is an array with the current and the previous item
         *
         * @see https://ramdajs.com/docs/#aperture
         * e.g.: aperture(2, [1, 2, 3, 4, 5]); //=> [[1, 2], [2, 3], [3, 4], [4, 5]]
         */
        protected get currentAndPreviousPairs(): [SurgicalTemplateRepresentation, SurgicalTemplateRepresentation][] {
            return aperture(2, this.historyItems);
        }

        protected get historyDiffItems(): DiffRow[] {
            if (this.historyItems.length > 0) {
                return this.currentAndPreviousPairs.map(item => {
                    return this.makeDiffRow(item[0], item[1]);
                });
            }

            return [];
        }

        private makeDiffRow(current: SurgicalTemplateRepresentation, previous: SurgicalTemplateRepresentation): DiffRow {
            return {
                uri: LinkUtil.getUri(current, LinkRelation.self),
                current,
                previous,
            };
        }
    }
