import {
    anteversionFromAIRI,
    anteversionRotated,
    inclinationRotated,
} from '@/components/spinopelvic/calculations';
import { SafeZoneDataSet, SpinopelvicCalculations } from '@/components/spinopelvic/types';
import {
    ANTEVERSION_10_DEGREES,
    ANTEVERSION_30_DEGREES,
    INCLINATION_LOWER_LIMIT,
    INCLINATION_UPPER_LIMIT,
} from '@/components/spinopelvic/conts';

export const axisArray = (min: number, max: number, data_points: number): number[] => {
    const step = (max - min) / data_points;

    return Array.from(
        {length: data_points + 1},
        (value, index) => min + index * step,
    );
};

export const yAxisCupCSIArray = (
    inclinationArray: number[], anteInclination: number, pelvicTilt: number,
): number[] => {
    const anteversion: number[] = [];
    for (let i = 0; i < inclinationArray.length; i++) {
        anteversion.push(anteversionFromAIRI(inclinationArray[i], anteInclination, pelvicTilt));
    }
    return anteversion;
};

export const yAxisAnteversionArray = (
    inclinationArray: number[], anteversion: number, pelvicTilt: number,
): number[] => {
    const y: number[] = [];
    for (let i = 0; i < inclinationArray.length; i++) {
        y.push(anteversionRotated(inclinationArray[i], anteversion, pelvicTilt));
    }
    return y;
};

export const xAxisInclinationArray = (inclination: number, anteversionArray: number[], pelvicTilt: number): number[] => {
    const x: number[] = [];
    for (let i = 0; i < anteversionArray.length; i++) {
        x.push(inclinationRotated(inclination, anteversionArray[i], pelvicTilt));
    }
    return x;
};

export const anteversionSafeZonePath = (dataSet: SafeZoneDataSet): string => {
    // Draw a shape with an area defines the area between the upper and lower limits on the anteversion axis
    let pathString = '';

    for (let i = 0; i < dataSet.xAxisArray.length; i++) {
        pathString += ' L ' + dataSet.xAxisArray[i] + ', '+ dataSet.anteversionUpperLimit[i];
    }

    for (let i = dataSet.xAxisArray.length-1; i >= 0; i--) {
        pathString += ' L ' + dataSet.xAxisArray[i] + ', '+ dataSet.anteversionLowerLimit[i];
    }

    if(pathString !== '') {
        pathString += ' Z';
        pathString = pathString.replace(/^.{2}/g, 'M ');
    }

    return fixPathString(pathString);
};

export const inclinationSafeZonePath = (dataSet: SafeZoneDataSet): string => {
    // Draw a shape with an area defines the area between the upper and lower limits on the anteversion axis
    let pathString = '';

    for (let i = 0; i < dataSet.yAxisArray.length; i++) {
        pathString += ' L ' + dataSet.inclinationLowerLimit[i] + ', '+ dataSet.yAxisArray[i];
    }

    for (let i = dataSet.yAxisArray.length-1; i >= 0; i--) {
        pathString += ' L ' + dataSet.inclinationUpperLimit[i] + ', '+ dataSet.yAxisArray[i];
    }

    return fixPathString(pathString);
};

export const patientSafeZonePath = (dataSet: SafeZoneDataSet, spinopelvicCalculations: SpinopelvicCalculations): string => {
    // Draw a shape with an area that intersects all 6 spinopelvic lines:
    //  - upper/lower CSI limit curves
    //  - upper/lower anteversion curves
    //  - upper/lower inclination curves
    // This shape is drawn by iterating data points from left to right for the upper half of the shape, then going
    // backwards (right to left) and drawing the bottom half of the shape.
    // It is possible to have no reshape drawn if the lines do not intersect appropriately.
    let pathString = '';

    // Draw top left to top right corner
    for (let i = 0; i < dataSet.xAxisArray.length; i++) {
        const x = dataSet.xAxisArray[i];
        const anteversion = dataSet.anteversionUpperLimit[i];
        const inclinationLower = inclinationRotated(
            INCLINATION_LOWER_LIMIT,
            anteversion,
            spinopelvicCalculations.pelvicTilt,
        );
        const inclinationUpper = inclinationRotated(
            INCLINATION_UPPER_LIMIT,
            anteversion,
            spinopelvicCalculations.pelvicTilt,
        );
        if (isBetween(x, inclinationLower, inclinationUpper) &&
            isBetween(anteversion, dataSet.cupLowerArray[i], dataSet.cupUpperArray[i])) {
            let value = anteversion;
            if (value >= dataSet.cupUpperArray[i]) {
                value = dataSet.cupUpperArray[i];
            }
            pathString += ' L ' + x + ', '+ value;
        }
    }

    // Draw top right to bottom right corner
    for (let i = dataSet.yAxisArray.length - 1; i >= 0; i--) {
        const y = dataSet.yAxisArray[i];
        const inclination = dataSet.inclinationUpperLimit[i];
        const anteversionLower = anteversionRotated(
            inclination,
            ANTEVERSION_10_DEGREES,
            spinopelvicCalculations.pelvicTilt,
        );
        const anteversionUpper = anteversionRotated(
            inclination,
            ANTEVERSION_30_DEGREES,
            spinopelvicCalculations.pelvicTilt,
        );
        const cupUpper = anteversionFromAIRI(
            inclination,
            spinopelvicCalculations.csiUpperBound,
            spinopelvicCalculations.pelvicTilt,
        );
        const cupLower = anteversionFromAIRI(
            inclination,
            spinopelvicCalculations.csiLowerBound,
            spinopelvicCalculations.pelvicTilt,
        );
        if (isBetween(y, anteversionLower, anteversionUpper) && isBetween(y, cupLower, cupUpper)) {
            let value = y;
            if (y >= cupUpper) {
                value = cupUpper;
            } else if (y <= cupLower) {
                value = cupLower;
            }
            pathString += ' L ' + inclination + ', '+ value;
        }
    }

    // Draw bottom right to bottom left corner
    for (let i = dataSet.xAxisArray.length - 1; i >= 0; i--) {
        const x = dataSet.xAxisArray[i];
        const anteversion = dataSet.anteversionLowerLimit[i];
        const inclinationLower = inclinationRotated(
            INCLINATION_LOWER_LIMIT,
            anteversion,
            spinopelvicCalculations.pelvicTilt,
        );
        const inclinationUpper = inclinationRotated(
            INCLINATION_UPPER_LIMIT,
            anteversion,
            spinopelvicCalculations.pelvicTilt,
        );
        if (isBetween(x, inclinationLower, inclinationUpper) &&
            isBetween(anteversion, dataSet.cupLowerArray[i], dataSet.cupUpperArray[i])) {
            let value = anteversion;
            if (value <= dataSet.cupLowerArray[i]) {
                value = dataSet.cupLowerArray[i];
            }
            pathString += ' L ' + x + ', '+ value;
        }
    }

    // Draw bottom left to top left corner
    for (let i = 0; i < dataSet.yAxisArray.length; i++) {
        const y = dataSet.yAxisArray[i];
        const inclination = dataSet.inclinationLowerLimit[i];
        const anteversionLower = anteversionRotated(
            inclination,
            ANTEVERSION_10_DEGREES,
            spinopelvicCalculations.pelvicTilt,
        );
        const anteversionUpper = anteversionRotated(
            inclination,
            ANTEVERSION_30_DEGREES,
            spinopelvicCalculations.pelvicTilt,
        );
        const cupUpper = anteversionFromAIRI(
            inclination,
            spinopelvicCalculations.csiUpperBound,
            spinopelvicCalculations.pelvicTilt,
        );
        const cupLower = anteversionFromAIRI(
            inclination,
            spinopelvicCalculations.csiLowerBound,
            spinopelvicCalculations.pelvicTilt,
        );
        if (isBetween(y, anteversionLower, anteversionUpper) && isBetween(y, cupLower, cupUpper)) {
            let value = y;
            if (y >= cupUpper) {
                value = cupUpper;
            } else if (y <= cupLower) {
                value = cupLower;
            }
            pathString += ' L ' + inclination + ', '+ value;
        }
    }

    return fixPathString(pathString);
};

export const fixPathString = (pathString: string): string => {
    if(pathString !== '') {
        pathString += ' Z';
        pathString = pathString.replace(/^.{2}/g, 'M ');
    }
    return pathString;
};

export const isBetween = (value: number, min: number, max: number): boolean => {
    return (min <= value && value <= max);
};
