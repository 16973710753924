import { LinkSelector, RelationshipType } from 'semantic-link';
import LinkRelationTitle from '@/lib/api/LinkRelationTitle';

export default class LinkRelation {
    public static readonly version = 'version';
    public static readonly self = 'self';
    public static readonly submit = 'submit';
    public static readonly canonical = 'canonical';
    public static readonly nonCanonical = 'noncanonical';
    public static readonly previous = 'previous';
    public static readonly componentRanking = 'component-ranking';

    /** a relationship selector for canonical or self. */
    public static readonly canonicalOrSelf: RelationshipType = [LinkRelation.canonical, LinkRelation.self];
    // Canonical = "canonical"
    public static readonly up = 'up';

    public static readonly authenticator = 'authenticator';
    public static readonly resetPassword = 'reset';
    public static readonly setPassword = 'set';

    public static readonly project = 'project';

    /** @deprecated This is a raw legacy status from portal */
    public static readonly projectStatus = 'status';
    public static readonly projects = 'projects';
    public static readonly organisation = 'organisation';
    public static readonly organisations = 'organisations';

    public static readonly products = 'products';
    public static readonly product = 'product';

    public static readonly preferences = 'preferences';
    public static readonly surgeonPreferences: LinkSelector = { rel: LinkRelation.preferences, title: 'surgeon' };

    /** @deprecated These have moved to the study */
    public static readonly projectBoneModels: LinkSelector = { rel: 'models', title: 'bones' };
    public static readonly studyBoneModels: LinkSelector = { rel: 'models', title: 'bones' };
    public static readonly studyMetalModels: LinkSelector = { rel: 'models', title: 'metal' };
    public static readonly models = { rel: 'models', title: '' };
    public static readonly model = 'model';

    public static readonly report = 'report';
    public static readonly reportMeasurements = 'report-measurements';

    /** @deprecated About to be deleted */
    public static readonly caseModel = 'case-model';
    public static readonly alignmentTransform = 'alignment-transform';
    public static readonly rangeOfMotion = 'range-of-motion';
    public static readonly surgicalSpecification = 'surgical-specification';
    public static readonly surgicalSpecifications = 'surgical-specifications';
    public static readonly boundingBox = 'bounding-box';
    public static readonly catStack = 'cat-stack';
    public static readonly catStacks = 'cat-stacks';
    public static readonly patient = 'patient';
    public static readonly landmarks = 'landmarks';
    public static readonly fitting = 'fitting';
    public static readonly measurements = 'measurements';
    public static readonly messages = 'messages';

    public static readonly femoralSuitability = 'femoral-suitability';
    public static readonly femoralRanking = 'femoral-ranking';

    public static readonly component = 'component';
    public static readonly componentSet = 'components';
    public static readonly hipComponentsCatalogue = 'hip-components-catalogue';

    public static readonly region = 'region';
    public static readonly collisionMask = 'collisionMask';
    public static readonly collisionMaskRegion: LinkSelector = {
        rel: LinkRelation.region,
        title: 'collision-mask',
    };

    public static readonly study = 'study';
    /**
     * The active study is used when uploading a new set of DICOMs, it should **not** be used to discover
     * the study used for a surgical template.
     */
    public static readonly activeStudy: LinkSelector = { rel: LinkRelation.study, title: 'active' };
    public static readonly studies = 'studies';

    public static readonly surgicalTemplate = 'surgical-template';

    /**
     * client side artifact of the 'up' link on a plan representation to the specific surgical template version.
     */
    public static readonly template = 'template';
    public static readonly history = 'history';

    /** the current 'user' surgical template */
    public static readonly currentSurgicalTemplate: LinkSelector = {
        rel: LinkRelation.surgicalTemplate,
        title: LinkRelationTitle.manualSurgicalTemplate,
    };

    /** the current automated 'acid' surgical template */
    public static readonly acidSurgicalTemplate: LinkSelector = {
        rel: LinkRelation.surgicalTemplate,
        title: LinkRelationTitle.automatedSurgicalTemplate,
    };

    public static readonly parentSurgicalTemplate: LinkSelector = {
        rel: LinkRelation.surgicalTemplate,
        title: LinkRelationTitle.parentSurgicalTemplate,
    };

    public static readonly surgicalTemplates = 'surgical-templates';
    public static readonly caseSurgicalTemplates = LinkRelation.surgicalTemplates;
    public static readonly studySurgicalTemplates = LinkRelation.surgicalTemplates;

    public static readonly plan = 'plan';
    public static readonly plans = 'plans';
    public static readonly planFiles = 'files';
    public static readonly projectPlans = LinkRelation.plans;
    public static readonly surgicalTemplatePlans = LinkRelation.plans;
    public static readonly currentPlan: LinkSelector = {
        rel: LinkRelation.plan,
        title: LinkRelationTitle.currentPlan,
    };

    public static readonly currentSurgicalTemplatePlan = LinkRelation.currentPlan;
    /** @deprecated The 'user' plan is now located on the acid surgical template */

    public static readonly ctFiles = 'files';
    public static readonly ctFile = 'file';

    /**
     * Note: a surgeon is a user.
     * TODO: change this to a link of the form `{rel: user, title: surgeon}`
     */
    public static readonly surgeon = 'surgeon';

    /**
     * Note: an owner is a user.
     * TODO: change this to a link of the form `{rel: user, title: owner}`
     */
    public static readonly owner = 'owner';
    public static readonly user = 'user';
    public static readonly meUser: RelationshipType = { rel: LinkRelation.user, title: 'me' };

    public static readonly users = 'users';

    /** the collection of surgeon users on that are known/available to a user */
    public static readonly userSurgeons: RelationshipType = { rel: LinkRelation.users, title: 'surgeon' };

    //
    //  Hip components basic relationships
    //
    public static readonly hipStemComponent = 'stem';
    public static readonly hipStemHeadComponent = 'head';
    public static readonly hipCupComponent = 'cup';
    public static readonly hipCupLinerComponent = 'liner';

    public static readonly hipStemComponents = 'stems';
    public static readonly hipStemHeadComponents = 'heads';
    public static readonly hipCupComponents = 'cups';
    public static readonly hipCupLinerComponents = 'liners';

    //
    // Hip Surgical Template
    //
    public static readonly hipCurrentStemComponent: LinkSelector = {
        rel: LinkRelation.hipStemComponent,
        title: LinkRelationTitle.currentComponent,
    };

    public static readonly hipCurrentStemHeadComponent: LinkSelector = {
        rel: LinkRelation.hipStemHeadComponent,
        title: LinkRelationTitle.currentComponent,
    };

    public static readonly hipCurrentCupComponent: LinkSelector = {
        rel: LinkRelation.hipCupComponent,
        title: LinkRelationTitle.currentComponent,
    };

    public static readonly hipCurrentCupLinerComponent: LinkSelector = {
        rel: LinkRelation.hipCupLinerComponent,
        title: LinkRelationTitle.currentComponent,
    };
}
