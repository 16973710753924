/***
 * Module for constants and static methods used in the Home search
 */
export class SearchUtil {
    public static readonly DEFAULT_VALUE = '';

    /** At the moment the separator is the spaces */
    public static readonly TOKEN_SEPARATOR = ' ';

    public static isSearchEmpty(value: null | string): boolean {
        return !value || value === SearchUtil.DEFAULT_VALUE;
    }

    static getSearchTokens(searchText: string): string[] {
        return searchText.split(SearchUtil.TOKEN_SEPARATOR);
    }
}
