
    /**
     * see
     *  - https://vuetifyjs.com/en/components/dialogs
     */
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { LinkUtil } from 'semantic-link';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';

    import anylogger from 'anylogger';
    import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
    import ResourceUtil from '@/lib/api/ResourceUtil';
    import { StudyProcessOptionsRepresentation } from '@/lib/api/representation/SeriesRepresentation';
    import AxiosUtil from '@/lib/AxiosUtil';
    import assert from 'assert';
    import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';

    const log = anylogger('RemoveCase');

    /**
     * A simple status dialog that is shown to the user (administrator) while an duplicate
     * study is created based on an existing study. The existing study must have valid DICOM files
     * uploaded, which are used by the new study.
     */
    @Component
    export default class DuplicateStudyFiles extends Vue {
        @Prop({ required: true })
        public value!: CaseRepresentation;

        @Prop({ required: true })
        public study!: StudyRepresentation;

        protected isVisible = false;
        protected isWorking = false;
        protected createOptions: StudyProcessOptionsRepresentation = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_notifications: false,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            send_email: false,
        };

        protected errorText = '';

        protected onShow(): void {
            this.isWorking = false;
            this.errorText = '';
            this.createOptions.send_email = false;
            this.createOptions.create_notifications = false;
            this.isVisible = true;
        }

        protected async onCreate(): Promise<void> {
            this.isVisible = true;
            this.isWorking = true;
            this.errorText = '';
            try {
                try {
                    const study = await CaseStudyResource.createStudy(
                        this.value,
                        {
                            name: 'From DICOM files',
                            study: LinkUtil.getUri(this.study, LinkRelation.self),
                            operation: 'files',
                            options: this.createOptions,
                        },
                        this.$apiOptions);
                    if (study) {
                        log.info('New study %s', getRequiredUri(study, LinkRelation.self));

                        // refresh the project as the active study should have changed
                        await ResourceUtil.refresh(this.value, this.$apiOptions);

                        this.errorText = ``;
                        this.isVisible = false;
                    } else {
                        this.errorText = `Error creating study`;
                    }
                } catch (err: unknown) {
                    assert.ok(err instanceof Error);
                    if (AxiosUtil.isForbiddenError(err)) {
                        this.errorText = `Access denied`;
                    } else {
                        this.errorText = `Error creating duplicate study: ${err.message}`;
                    }
                }
            } finally {
                this.isWorking = false;
            }
        }
    }
