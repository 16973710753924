
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { LinkedRepresentation, LinkUtil } from 'semantic-link';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { RelationshipType } from 'semantic-link/lib/filter';

    /**
     * A simple open hyperlink button.
     */
    @Component
    export default class HyperlinkButton extends Vue {
        @Prop({ required: true })
        public value!: LinkedRepresentation | null;

        @Prop({ default: () => LinkRelation.canonicalOrSelf })
        public rel!: RelationshipType;

        protected get uri(): string | null {
            return this.value ? LinkUtil.getUri(this.value, this.rel) || null : null;
        }
    }
