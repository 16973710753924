
    import { Component, Vue } from 'vue-property-decorator';
    import ViewPath from '@/router/viewPath';

    /**
     * This is a small pop-up dialog that allows a user to create a new case.
     *
     * The overall objective here is to get the user to enter the absolute minimal amount
     * of information so that the case (project) can be created.
     */
    @Component({ components: {} })
    export default class BackToDashboard extends Vue {
        protected get homePath(): string {
            return ViewPath.Home;
        }
    }
