import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { RelationshipType } from 'semantic-link/lib/filter';
import { LinkUtil } from 'semantic-link';

export function findUrl(project: CaseRepresentation, linkRel: RelationshipType): string {
    const url = LinkUtil.getUri(project.links, linkRel);
    if (!url) {
        throw new Error(`${linkRel} link missing`);
    }
    return url;
}
