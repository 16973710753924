
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { NumberUtil } from '@/lib/base/NumberUtil';
    import { isString } from 'lodash';

    class StylesUtil {
        /**
         * Converts a number or string to pixel or percentage
         * e.g:
         * 1. 50 => 50px
         * 2. '50px' => 50px
         * 3. '50%' => 50%
         * @param value
         */
        public static toPxOrPercentage = (value: null | undefined | number | string): string => {
            if (value !== null && value !== undefined) {
                // https://stackoverflow.com/questions/30342731/javascript-regex-to-find-numbers-followed-by-px-or
                if (isString(value) && value.match(/[0-9]*\.?[0-9]+(px|%)/i)) {
                    return value;
                }

                if (NumberUtil.isFiniteNumber(isString(value) ? parseInt(value) : value)) {
                    return `${value}px`;
                }
            }

            return '';
        };
    }

    enum CustomButtonTypes {
        good = 'good',
        bad = 'bad',
        warn = 'warn',
        action = 'action',
        naked = 'naked'
    }

    /**
     * An attempt to wrap vuetify v-btn component:
     *
     * Approach taken from: https://dev.to/kouts/create-wrapper-components-for-vuetify-components-2aah
     */
    @Component({})
    export default class CustomButton extends Vue {
        @Prop({ required: true })
        protected type!: CustomButtonTypes.good |
            CustomButtonTypes.bad |
            CustomButtonTypes.warn |
            CustomButtonTypes.action |
            CustomButtonTypes.naked;

        /** It can be expressed in pixels or as a percentage */
        @Prop({ required: false })
        protected height!: number | string | null | undefined;

        /** It can be expressed in pixels or as a percentage */
        @Prop({ required: false })
        protected width!: number | string | null | undefined;

        @Prop({ required: false })
        protected loading!: boolean;

        protected get customStyles(): unknown {
            return {
                height: StylesUtil.toPxOrPercentage(this.height),
                width: StylesUtil.toPxOrPercentage(this.width),
            };
        }
    }
