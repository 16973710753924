import { StudyRepresentation, StudyState } from '@/lib/api/representation/case/study/StudyRepresentation';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import { equals, isNil } from 'ramda';

export class StudyUtil {
    /**
     * Returns whether the study is in the catstack state
     */
    static isInCatstackState(study: StudyRepresentation): boolean {
        return study.state === StudyState.CatStack;
    }

    /**
     * Returns whether the study in in the segmented-processing state
     */
    static isInSegmentedProcessing(study: StudyRepresentation): boolean {
        return study.state === StudyState.SegmentationProcessing;
    }

    /**
     * Returns whether the study in in the segmented state
     */
    static isInSegmentedState(study: StudyRepresentation): boolean {
        return study.state === StudyState.Segmented;
    }

    /**
     * Returns whether the study in in the post-processing state
     */
    static isInPostProcessing(study: StudyRepresentation): boolean {
        return study.state === StudyState.PostProcessing;
    }

    /**
     * Returns whether the study is in the completed state or not
     */
    static isCompleted(study: StudyRepresentation): boolean {
        return study.state === StudyState.Completed;
    }

    /**
     * Returns whether the study is in the error state or not
     */
    static isError(study: StudyRepresentation): boolean {
        return study.state === StudyState.Error;
    }

    /**
     * Returns true if the active study uri on a case matches the uri in the self link of the study
     */
    static isActiveStudy<T extends CaseRepresentation>(project: T, study: StudyRepresentation): boolean {
        const activeStudyUri = LinkUtil.getUri(project, LinkRelation.activeStudy);
        const studyUri = LinkUtil.getUri(study, LinkRelation.self);
        return !isNil(studyUri) && !isNil(activeStudyUri) && equals(studyUri, activeStudyUri);
    }
}
