
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    import { getUri } from 'semantic-link';
    import { navigatePathCaseSettings } from '@/router/navigate';
    import { ProductCollectionRepresentation } from '@/lib/api/representation/ProductRepresentation';
    import { CaseCreateDataRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
    import UserResource from '@/lib/api/resource/user/UserResource';
    import CaseResource from '@/lib/api/resource/case/CaseResource';
    import LinkRelation from '@/lib/api/LinkRelation';
    import anylogger from 'anylogger';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import { ProductOption, ProductUtil } from '@/components/case/ProductUtil';
    import { isEmpty } from 'ramda';
    import Bugsnag from '@bugsnag/js';
    import DragDropUtil from '@/lib/dragdrop/DragDropUtil';
    import {
        AnyCaseConfigurationDataRepresentation,
    } from '@/components/case-settings/utils/ConfigureCaseUtil';
    import assert from 'assert';
    import { productVersion } from '@/lib/version';
    import { provisionCase } from './newCase';

    const log = anylogger('NewCase');

    // Add the v-validate required rule
    extend('required', required);

    /**
     * This is a small pop-up dialog that allows a user to create a new case.
     *
     * The overall objective here is to get the user to enter the absolute minimal amount
     * of information so that the case (project) can be created.
     */
    @Component({ components: { ValidationProvider, ValidationObserver } })
    export default class NewCase extends Vue {
        public declare $refs: Vue['$refs'] & { observer: InstanceType<typeof ValidationObserver> };

        isDialogVisible = false;

        /** Flag to support enabling/disabled create button */
        isCreatingCase = false;

        /** the type of case */
        selectedProductOption: ProductOption | null = null;

        /** The name of the case */
        name = '';

        /** This is the error message shown to the user. This is for non-validation errors. */
        error = '';

        /** whether the dialog is loading its data */
        isLoading = true;

        /**
         * The user resource for the identity the user is authenticated as.
         */
        private user: UserRepresentation | null = null;

        /**
         * The list of products that the user is subscribed to (i.e. those products that the
         * user has been provisioned to be able to use - create, discover, ...).
         */
        private products: ProductCollectionRepresentation | null = null;

        /**
         * A computed property that provides the list of project types
         * available for the chips.
         */
        protected get productOptions(): ProductOption[] {
            return this.products ? ProductUtil.getProductOptions(this.products) : [];
        }

        protected onCancel(): void {
            this.isDialogVisible = false;
        }

        @IsLoading('isCreatingCase')
        protected async onCreateCase(): Promise<void> {
            log.info('onCreate');
            const isValid = await this.$refs.observer.validate();
            try {
                if (isValid) {
                    const createData = this.makeCreatePayload();
                    if (createData) {
                        const newCase = await CaseResource.createCase(this.$api, createData, this.$apiOptions);
                        if (newCase) {
                            this.$router.push(navigatePathCaseSettings(newCase))
                                .then(() => {
                                    this.isDialogVisible = false;
                                })
                                .catch((err) => log.error('Failed to navigate to home page: %s', err));
                        } else {
                            log.error('Failed to create new case');
                        }
                    }
                } else {
                    log.error('Invalid validated data');
                }
            } catch (err: unknown) {
                assert.ok(err instanceof Error);
                log.error('Unexpected error creating case: %o', err);
                this.error = err.message;
                Bugsnag.notify(err);
            }
        }

        /**
         * Make create payload depending on the selected product options.
         * Note: 'side' will not be present on payload for 'spine' cases
         */
        private makeCreatePayload(): CaseCreateDataRepresentation | null {
            const productOption = this.selectedProductOption;
            if (productOption) {
                const productUri = getUri(productOption.productType, LinkRelation.self);
                if (productUri) {
                    // TODO
                    // There should be an interface of products that specify if has side or not
                    // By doing that, then we could be doing a guard clause about the product
                    if (ProductUtil.isProductWithSide(productOption.productType.name)) {
                        return {
                            name: this.name,
                            product: productUri,
                            side: productOption.procedureSide,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            web_component_version: productVersion(),
                        };
                    } else {
                        return {
                            name: this.name,
                            product: productUri,
                        };
                    }
                } else {
                    log.error('The select product is not valid');
                }
            }

            return null;
        }

        protected mounted(): void {
            log.debug('New case dialog mounted');
        }

        @Watch('isDialogVisible') // watch the active boolean, which is the v-model/value
        @IsLoading('isLoading')
        protected async onActive(isActive: boolean): Promise<void> {
            if (isActive) {
                this.resetDialogState();
                this.user = await UserResource.getMeUser(this.$api, { ...this.$apiOptions, forceLoad: true });
                if (this.user) {
                    await this.setProducts(this.user);
                } else {
                    log.warn('Failed to get \'me\' user');
                }
            } // else the dialog closing
        }

        /**
         * Reset any previous user input
         */
        private resetDialogState(): void {
            this.error = '';
            this.isCreatingCase = false;

            // Reset user input
            this.name = '';
            this.selectedProductOption = null;
        }

        /**
         * Set products for the current user.
         */
        private async setProducts(user: UserRepresentation): Promise<void> {
            this.products = await UserResource.getProducts(user, { ...this.$apiOptions, forceLoad: true });
            if (this.productOptions && !isEmpty(this.productOptions)) {
                this.setDefaultOption(this.productOptions);
            } else {
                log.warn('no products have been subscribed to - it is not possible to create new cases');
            }
        }

        /**
         * If there is **only** one product option available select it by default
         * @private
         */
        private setDefaultOption(productOptions: ProductOption[]): void {
            if (productOptions.length === 1) {
                this.selectedProductOption = productOptions[0];
            }
        }

        /**
         * Drop a new case configuration
         */
        protected async onDrop(event: DragEvent): Promise<void> {
            try {
                log.info('Attempting to create new case from drag-and-drop');
                const config = await DragDropUtil.onSimpleSingleJsonFileDrop(event);
                await provisionCase(this.$api, this.$apiOptions, config as AnyCaseConfigurationDataRepresentation);
            } catch (err) {
                log.error('Failed to create new case: %s', err);
            }
        }

        protected onDragover(event: DragEvent): boolean {
            return DragDropUtil.dragover(event);
        }

        protected onDragenter(event: DragEvent): boolean {
            return DragDropUtil.dragenter(event);
        }

        protected onDragleave(event: DragEvent): void {
            DragDropUtil.dragleave(event);
        }
    }
