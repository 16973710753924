import { Matrix, Matrix3, Matrix4, Object3D, Vector, Vector2, Vector3, Vector4 } from 'three';
import { all, identity, zipWith } from 'ramda';
import { XYZCoordinates } from '@/lib/api/representation/base/XYZCoordinates';
import { getPosition } from '@/lib/base/matrix';
import { NumberArray3 } from '@/lib/api/representation/geometry/arrays';

/**
 * A Module that keeps three js related functions that we tend to re-use
 *
 * ************************************IMPORTANT********************************
 *
 * Every function defined here should be a ***PURE*** function and avoid mutating stuff, to avoid falling into
 * the traps of three js (which sometimes mutates the arguments you pass, and sometimes not)
 */

/**
 * Default tolerance for floating point comparisons
 */
export const DEFAULT_TOLERANCE = 1e-8;

export interface BasisVectors {
    readonly x: Vector3;
    readonly y: Vector3;
    readonly z: Vector3;
}

/** The types that can be converted to a Vector3*/
export type AsVector3 = XYZCoordinates | Vector3 | NumberArray3;

export class ThreeUtil {
    /**
     * Get the 'standard'/identity basis vectors
     */
    public static get standardBasis(): BasisVectors {
        return {
            x: new Vector3(1, 0, 0),
            y: new Vector3(0, 1, 0),
            z: new Vector3(0, 0, 1),
        };
    }

    /**
     * Create the pure rotation matrix that transforms out of the 'basis' defined by the supplied basis vectors
     */
    public static makeBasisMatrix(basis: BasisVectors): Matrix4 {
        const { x, y, z } = basis;
        return new Matrix4().makeBasis(x, y, z);
    }

    /**
     * Create the pure rotation matrix3 that transforms out of the 'basis' defined by the supplied basis vectors
     */
    public static makeBasisMatrix3(basis: BasisVectors): Matrix3 {
        const { x, y, z } = basis;
        return new Matrix3().set(
            x.x, y.x, z.x,
            x.y, y.y, z.y,
            x.z, y.z, z.z);
    }

    /**
     * Returns a set of 'basis' vectors for the matrix.
     */
    public static getBasis(matrix: Matrix4): BasisVectors {
        const x = new Vector3();
        const y = new Vector3();
        const z = new Vector3();
        matrix.extractBasis(x, y, z);
        return { x, y, z };
    }
}

/**
 * Returns a Matrix3 using the upper 3x3 elements of a Matrix4
 */
export function getRotationMatrix(matrix4: Matrix4): Matrix3 {
    return (new Matrix3()).setFromMatrix4(matrix4.clone());
}

/**
 * Given a matrix4, it takes the upper 3x3 elements of the matrix, and apply it to a vector
 */
export function applyOnlyRotationMatrix(matrix: Matrix4, v: AsVector3): Vector3 {
    const m3 = getRotationMatrix(matrix);
    return asVector3(v).applyMatrix3(m3);
}

/**
 * Given a matrix4, it takes the upper 3x3 elements of the matrix, and apply it to a vector
 */
export function applyMatrix4(matrix: Matrix4, v: Vector3 | NumberArray3): Vector3 {
    return asVector3(v).applyMatrix4(matrix);
}

export function setPosition(object: Object3D, vector: Vector3) {
    object.position.set(...vector.toArray());

    if (object.matrixAutoUpdate) {
        // nothing to do, three js will update it when needed.
    } else {
        object.matrix.setPosition(vector);
    }
    object.updateMatrixWorld(true);
}

/**
 * Set the local transform (object-to-parent, aka {@link Object3D.matrix}) of an {@link Object3D}. If the object has
 * no parent, or the parent is the {@link THREE.Scene}, this transform is the same as the object's object-to-world
 * or {@link Object3D.matrixWorld} once it has been updated.
 *
 * **Note** There are two different ways to set an object's transformation, and they are not compatible to use
 * on the same object:
 * 1. Setting {@link Object3D.matrixAutoUpdate} = true, modifying the position, quaternion, and scale properties and
 *  then calling {@link Object3D.updateMatrix}
 * 1. Setting {@link Object3D.matrixAutoUpdate} = false and modifying the object's {@link Object3D.matrix} directly.
 *  In this case we should NOT call {@link Object3D.updateMatrix} as it will clobber the manual changes made to the
 *  matrix, recalculating the matrix from position, scale, and so on.
 *
 * @see https://threejs.org/docs/#manual/en/introduction/Matrix-transformations
 */
export function setLocalTransform(object: Object3D, matrix: Matrix4): void {
    object.position.setFromMatrixPosition(matrix);
    object.setRotationFromMatrix(matrix);

    if (object.matrixAutoUpdate) {
        // nothing to do, three js will update it when needed.
    } else {
        object.matrix.copy(matrix);
    }

    // The object needs to be explicitly forced to update it's matrixWorld property
    object.updateMatrixWorld(true);
}

/**
 * Set the local transform (object-to-parent, aka {@link Object3D.matrix}) of an {@link Object3D} so that it has
 * a given world-transform, aka {@link Object3D.matrixWorld}, taking account of its parent transform if it exists.
 */
export function setWorldTransform(object: Object3D, matrix: Matrix4): void {
    if (object.parent) {
        // If the object has a parent we need to work out its local transform relative to the parent.
        const parentTransform = worldTransform(object.parent);
        const objectToParent = parentTransform.invert().multiply(matrix);
        setLocalTransform(object, objectToParent);
    } else {
        // If the object has no parent we just need to set its transform directly
        setLocalTransform(object, matrix);
    }
}

/**
 * Get the object-to-world transform of an {@link Object3D} i.e. its {@link Object3D.matrixWorld}, ensuring that
 * it is updated first.
 */
export function worldTransform(object: Object3D): Matrix4 {
    object.updateWorldMatrix(true, false);
    return object.matrixWorld.clone();
}

/**
 * Get the position of an object in world-space
 */
export function worldPosition(object: Object3D): Vector3 {
    return getPosition(worldTransform(object));
}

/**
 * Given:
 * - a source {@link Object3D} or source-to-world transform matrix
 * - a target {@link Object3D} or target-to-world transform matrix
 *
 * Create a source-to-target transform matrix i.e. the matrix that takes source points and vectors to target points
 * and vectors.
 *
 * **Note** that for an {@link Object3D} like a {@link Mesh} the object-to-world transform is given by
 * {@link Object3D.matrixWorld}.
 *
 * @param source A source object *or* source-to-world transform.
 * @param target A target object *or* target-to-world transform.
 */
export function sourceToTarget(source: Matrix4 | Object3D, target: Matrix4 | Object3D): Matrix4 {
    /**
     * This is a copy of an old world-transform function. Copying here only because I am paranoid
     * about side effects.
     * TODO: Replace with standard world-transform function {@link worldTransform}
     * */
    function oldWorldTransform(object: Object3D): Matrix4 {
        object.updateMatrixWorld(true);
        return object.matrixWorld.clone();
    }

    const sourceMatrix = source instanceof Matrix4 ? source.clone() : oldWorldTransform(source);
    const targetMatrix = target instanceof Matrix4 ? target.clone() : oldWorldTransform(target);

    // The given transforms are S (source -> world) and T (target -> world).
    // The desired transform is source -> target which is given by source -> world followed by world -> target.
    // world -> target is the inverse of T
    // So the resulting composition needs to be S followed by inverse(T)
    // Matrix multiplication composes transforms from *right to left* so as a matrix multiplication the composition
    // is inverse(T) * S
    return targetMatrix.invert().multiply(sourceMatrix);
}

/**
 * @returns whether the object is an instance of the three-js implementation of {@link Vector3}
 */
export function isVector3(maybeAVector: unknown): maybeAVector is Vector3 {
    const asVector3 = maybeAVector as Vector3;
    return asVector3.isVector3;
}

/**
 * @returns whether the object is an instance of the {@link XYZCoordinates}, but not a {@link Vector3}
 */
export function isXYZCoordinate(maybeXYZCoordinate: unknown): maybeXYZCoordinate is XYZCoordinates {
    const asVector3 = maybeXYZCoordinate as XYZCoordinates;
    return !isVector3(maybeXYZCoordinate) && 'x' in asVector3 && 'y' in asVector3 && 'z' in asVector3;
}

/**
 * Check whether the given numbers, matrices or vectors are approximately equal
 */
export function approxEquals(left: number, right: number, tolerance?: number): boolean;
export function approxEquals<TMatrix extends Matrix>(left: TMatrix, right: TMatrix, tolerance?: number): boolean;
export function approxEquals<TVector extends Vector>(left: TVector, right: TVector, tolerance?: number): boolean;
export function approxEquals<NumberedArray extends number[]>(
    left: NumberedArray, right: NumberedArray, tolerance?: number): boolean;
export function approxEquals<Type>(left: Type, right: Type, tolerance: number = DEFAULT_TOLERANCE): boolean {
    if (typeof left === 'number' && typeof right === 'number') {
        return Math.abs(left - right) < tolerance;
    } else if (left instanceof Vector2 && right instanceof Vector2) {
        return Math.abs(left.x - right.x) < tolerance &&
            Math.abs(left.y - right.y) < tolerance;
    } else if (left instanceof Vector3 && right instanceof Vector3) {
        return Math.abs(left.x - right.x) < tolerance &&
            Math.abs(left.y - right.y) < tolerance &&
            Math.abs(left.z - right.z) < tolerance;
    } else if (left instanceof Vector4 && right instanceof Vector4) {
        return Math.abs(left.x - right.x) < tolerance &&
            Math.abs(left.y - right.y) < tolerance &&
            Math.abs(left.z - right.z) < tolerance &&
            Math.abs(left.w - right.w) < tolerance;
    } else if ((left instanceof Matrix4 && right instanceof Matrix4) ||
        (left instanceof Matrix3 && right instanceof Matrix3)) {
        return all(identity, zipWith((leftElement: number, rightElement: number) => {
            return Math.abs(leftElement - rightElement) < tolerance;
        }, left.elements, right.elements));
    } else if ((left instanceof Array && right instanceof Array)) {
        if (left.length !== right.length) {
            return false;
        }

        return all(identity, zipWith((leftElement: number, rightElement: number) => {
            return Math.abs(leftElement - rightElement) < tolerance;
        }, left, right));
    } else {
        throw TypeError();
    }
}

/** * Returns a new vector.
 *
 * 1. If a vector is passed, returns a ***new*** vector, not the same copy.
 * 1. If a xyz coordinate is passed, returns a new vector.
 * 1. If a numbered array is passed, returns a new vector.
 */
export function asVector3(value: AsVector3): Vector3 {
    if (isVector3(value)) {
        return value.clone();
    } else {
        if (isXYZCoordinate(value)) {
            return new Vector3(value.x, value.y, value.z);
        } else {
            return new Vector3(...value);
        }
    }
}

/**
 * Set the world-transform of this object without changing the world-transforms of its children
 */
export function shiftWorldTransform(object: Object3D, transform: Matrix4) {
    const childrenAndTransforms = removeChildren(object);
    setWorldTransform(object, transform);
    for (const [c, t] of childrenAndTransforms) {
        object.attach(c);
        setWorldTransform(c, t);
    }
}

/**
 * Set the local-transform of this object without changing the world-transforms of its children
 */
export function shiftLocalTransform(object: Object3D, transform : Matrix4) {
    const childrenAndTransforms = removeChildren(object);
    setLocalTransform(object, transform);
    for (const [c, t] of childrenAndTransforms) {
        object.attach(c);
        setWorldTransform(c, t);
    }
}


/**
 * Remove the children of this object, returning them along with their original transform matrix in world space
 */
function removeChildren(object: Object3D): [Object3D, Matrix4][] {
    const children = Array.from(object.children);
    return children.map(c => {
        const transform = worldTransform(c);
        object.remove(c);
        return [c, transform];
    });
}
