import assert from 'assert';

const IS_POSITIVE_INTEGER = /^[0-9]+?$/;
const IS_INTEGER = /^-?[0-9]+?$/;  // Numeric value that can be positive or negative
const IS_DECIMAL_NUMBER = /^-?[0-9]+?(\.[0-9]+)?$/;

export const isNotEmpty = (field: string): boolean =>
    field !== undefined && field.length > 0;

export const isPositiveInteger = (field: string): boolean =>
    IS_POSITIVE_INTEGER.test(field);

export const isInteger = (field: string): boolean =>
    IS_INTEGER.test(field);

export const isFloat = (field: string, decimalPlace: number | null = null): boolean => {
    const isFloat = IS_DECIMAL_NUMBER.test(field);
    let inDecimalPlace = true;
    if (decimalPlace !== null) {
        const fields = field.split('.');
        const decimals = fields.length === 2 ? fields[1].length : 0;
        inDecimalPlace = decimals <= decimalPlace;
    }
    return isFloat && inDecimalPlace;
};

export const isDateIsoString = (field: string): boolean => {
    try {
        const parsedDate = new Date(Date.parse(field));
        const parsedDateIsoString = parsedDate.toISOString().substring(0, 10);
        return (parsedDateIsoString === field);
    } catch (e) {
        return false;
    }
};

export const isInRange = (field: string, lowerLimit: number, upperLimit: number, inclusive: boolean): boolean => {
    assert.ok(lowerLimit < upperLimit, 'lower limit must be less than upper limit value');

    if (field === '') {
        return false;
    }

    const value = Number(field);

    if (!Number.isFinite(value)) {
        return false;
    } else if (inclusive) {
        return lowerLimit <= value && value <= upperLimit;
    } else {
        return lowerLimit < value && value < upperLimit;
    }
};
