import { Object3D } from 'three';
import AxisLine3D from '@/debugView/assembly/geometry-shapes/AxisLine3D';
import { asVector3, AsVector3 } from '@/lib/base/ThreeUtil';
import AcidObject3d, { ObjectProperties } from '@/lib/planning/objects-3D/AcidObject3d';

/**
 * An angle in 3d represented by two axis lines
 */
export default class Angle3D extends AcidObject3d {
    constructor(
        properties: ObjectProperties,
        private _position: AsVector3,
        private _referenceAxis: AxisLine3D,
        private _targetAxis: AxisLine3D) {
        super(properties);

        this._theObject = new Object3D();
        this._theObject.name = `angle-${properties.name}`;

        const asVector = asVector3(this._position);
        this._theObject.position.set(asVector.x, asVector.y, asVector.z);
        this._theObject.attach(this._referenceAxis.theObject);
        this._theObject.attach(this._targetAxis.theObject);
    }
}
