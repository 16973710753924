import { Vector3 } from 'three';
import { formatFloat, formatVector } from '@/lib/base/formatMath';
import { validationLogger } from '@/lib/logging/validationLogging';

const logValidation = validationLogger('adjustment-calculation');

export function logCalculation(title: string, values: [string, number | Vector3][]) {
    const formatValue = (value: number | Vector3) => typeof (value) == 'number' ?
        formatFloat(value) :
        formatVector(value);
    logValidation([
        title,
        ...values.map(([header, value]) => `  ${header}: ${formatValue(value)}`),
    ].join('\n'));
}
