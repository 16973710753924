
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import {
        MeasurementsRepresentation,
    } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
    import { Product } from '@/lib/api/representation/ProductRepresentation';
    import MeasurementsUtil from '@/lib/api/resource/case/study/MeasurementsUtil';
    import MeasurementGroup from '@/components/case-manage/measurement/MeasurementGroup.vue';

    /**
     * This is the case surgeon
     */
    @Component({ components: { MeasurementGroup } })
    export default class Measurements extends Vue {
        @Prop({ required: true })
        public value!: MeasurementsRepresentation | null;

        /** the name of the product - this is used for language string loading */
        @Prop({ required: true })
        public product!: Product;

        /** i18n identifier for the group name */
        protected groupName(name: string): string {
            return MeasurementsUtil.i18nGroupId(this.product, name);
        }
    }
