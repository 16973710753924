import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';

import ContentType from '@/lib/http/mimetype';

import anylogger from 'anylogger';
import { LoaderUtils, LoadingManager } from 'three';

const log = anylogger('ModelGeometryLoader');

export type HttpRequestData = string | Blob | any;

/**
 * The PLY File appears to being corrupted. The header lines are getting out of order, including the PLY header
 * not being on the first line.
 */
function checkHeader(data: string) {
    // The PLY file **must** start with a 'ply' (lower case) prefix
    if (data.startsWith('ply')) {
        // ok
    } else {
        log.warn('PLY file has a bad header: %s', data.substr(0, 300));
    }
}

/**
 * An Axios transform response function that handles converting
 * a 'model/ply*' response to a Geometry
 *
 * IMPORTANT: This is an synchronous style interface. The 'responseType' in
 * the Axios options must be set to 'arraybuffer' (or string).
 */
export function transformPlyResponse(data: HttpRequestData, headers: any): HttpRequestData {
    if (headers) {
        if ('content-type' in headers) {
            const contentType = headers['content-type'];
            if (contentType === ContentType.ModelPly ||
                contentType === ContentType.ModelPlyAscii ||
                contentType === ContentType.ModelXPly ||
                contentType === ContentType.ModelXPlyAscii ||
                contentType === ContentType.ModelXPlyBinary) {
                if (typeof data === 'string') {
                    log.debug('PLY file loaded as geometry from string (set the response type to be an arraybuffer)');
                    checkHeader(data);
                    const loader = new PLYLoader(new LoadingManager());
                    return loader.parse(data as string);
                } else if (data instanceof ArrayBuffer) {
                    log.debug('PLY file loaded as geometry from array buffer');
                    checkHeader(LoaderUtils.decodeText(new Uint8Array(data)));
                    const loader = new PLYLoader(new LoadingManager());
                    return loader.parse(data);
                } else {
                    log.warn(
                        'Response content \'%s\' is of type %s (expected a string or ArrayBuffer)',
                        contentType,
                        typeof data);
                }
            }
        }
    }
    // Return the data *without* any transformation
    return data;
}
