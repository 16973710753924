import { Representation } from '@/lib/semanticNetworkMigrationUtils';

export interface SeriesMessageRepresentation {
    /** the level message as a simple string (e.g. 'info', 'warn', 'debug') */
    level: string;

    /** the text message with parameters replaced */
    message: string;
}

export interface StudyProcessOptionsRepresentation extends Representation {

    /**
     * Whether workflow should create 'notifications' for the user as to progress. This allows
     the study workflow code to be used for both interactive and non-interactive workflows (i.e.
     a study processing where we don't want to alert the user).
     */
    create_notifications: boolean;

    /**
     * Whether workflow should send a success or failure email to the surgeon/case creator. This allows
     * the study workflow code to be used for both interactive and non-interactive workflows (i.e.
     * a study processing where we don't want to alert the user).
     */
    send_email: boolean;
}

export interface StudyDuplicateCreateDataRepresentation extends Representation {
    /** The name of the series */
    readonly name: string;
    /** the uri of of an exiting study on the same project */
    readonly study: string;
    /** either 'files' or 'models' */
    readonly operation: string;
    /** the version number associated with the frontend application */
    readonly web_component_version: string;
    /** optional processing options */
    readonly options?: StudyProcessOptionsRepresentation;
}

export interface DicomFileMessages {
    /** The original filename */
    name: string;

    /** an optional set of messages */
    messages?: SeriesMessageRepresentation[];
}

/**
 * Study create data
 */
export interface SeriesCreateDataRepresentation extends Representation {
    /**
     * The name of the series
     */
    readonly name: string;

    /**
     * A series identifier
     */
    readonly series?: string;

    /**
     * The number of files in the series. This is set by the client when the study is created. The client
     * then creates those files in the context of the study. The client must ensure that the study has the
     * correct number of files.
     */
    readonly file_count?: number;

    /**
     * Series messages. An optional set of messages that describe issues detected in the series during
     * processing by the client.
     *
     * Notes:
     *   <ul>
     *      <li>the messages for each file are stored on the individual file resources</li>
     *      <li>the messages for each files not uploaded are included below for completeness/diagnostics/audit</li>
     *   </ul>
     */
    readonly messages?: SeriesMessageRepresentation[];

    /**
     * The messages for file that were excluded from upload because they were deemed to not be required
     * to perform the pre-surgical planning. It is expected that additional files will come from the PACS
     * system.
     */
    readonly excluded_files?: DicomFileMessages[];
}

export function makeSeriesCreateDataRepresentation(
    name: string,
    series?: string,
    fileCount?: number,
    messages?: SeriesMessageRepresentation[],
    excludedFiles?: DicomFileMessages[]): SeriesCreateDataRepresentation {
    return {
        name,
        series,
        file_count: fileCount,
        messages,
        excluded_files: excludedFiles,
    };
}

/**
 * This is the data that is recorded as meta-data when creating a DICOM file in the context of
 * a study.
 *
 * This data is provided during the create (POST) as metat data in the headers of the http request as
 * the body is used for the file content.
 *
 * @see {@link SeriesCreateDataRepresentation}
 */
export interface StudyDicomFileCreateDataRepresentation {
    name: string;
    messages?: SeriesMessageRepresentation[];
}
