import { ResourceMergeFactory } from './resourceMergeFactory';
import { MergeOptions } from '@/lib/semantic-network/interfaces/mergeOptions';
import anylogger from 'anylogger';
import { CreateFormMergeStrategy } from '@/lib/semantic-network/interfaces/createFormMergeStrategy';

const log = anylogger('defaultCreateFormStrategy');

export const defaultCreateFormStrategy: CreateFormMergeStrategy = async (documentResource, form, options?: MergeOptions) => {
    try {
        return await ResourceMergeFactory.createMerge(documentResource, form, options);
    } catch (e) {
        if (typeof e === 'string') {
            log.error('[Merge form] unknown create error %s', e);
        } else {
            log.error('[Merge form] unknown create error %o', e);
        }
    }
};
