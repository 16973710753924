import {
    HipSurgicalTemplateMeasurements,
} from '@/lib/api/resource/case/surgical-template/measurements/HipSurgicalTemplateMeasurements';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import {
    AngleMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/AngleMeasurementValueRepresentation';
import {
    DistanceMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/DistanceMeasurementValueRepresentation';
import assert from 'assert';
import { isEmpty, isNil } from 'ramda';
import { MeasurementValueTypes } from '@/lib/api/representation/case/measurements/MeasurementValueType';
import {
    MeasurementGroupRepresentation,
} from '@/lib/api/representation/case/measurements/MeasurementGroupRepresentation';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import { Uri } from 'semantic-link';
import MeasurementsUtil from '@/lib/api/resource/case/study/MeasurementsUtil';
import {
    MeasurementGroupName,
    MeasurementGroupNames,
} from '@/lib/api/representation/case/measurements/MeasurementGroupName';
import HipSurgicalTemplateMeasurementsUtil
    from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateMeasurementsUtil';
import {
    MeasurementsGroupRepresentationUtil,
} from '@/lib/api/resource/case/surgical-template/measurements/MeasurementsGroupRepresentationUtil';
import {
    HipTemplateMeasurementName,
} from '@/lib/api/representation/case/surgical-template/hip/measurements/HipTemplateMeasurementName';

/**
 * @deprecated These have been also superseeded by the the report measurements
 * @see {@link HipReportMeasurementsRepresentation}
 */
export class HipNewSurgicalTemplateMeasurements extends HipSurgicalTemplateMeasurements {
    constructor(
        protected surgicalTemplate: HipSurgicalTemplateRepresentation, protected data: MeasurementsRepresentation) {
        super();
    }

    public getStemAnteversion(): AngleMeasurementValueRepresentation | null {
        const femoralGroup = this.getCurrentFemoralGroup();

        if (femoralGroup) {
            return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
                femoralGroup, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemAnteversion);
        }

        return null;
    }

    public getStemResectionLesserTrochanter(): DistanceMeasurementValueRepresentation | null {
        const femoralGroup = this.getCurrentFemoralGroup();

        if (femoralGroup) {
            return MeasurementsUtil.getMeasurementByGroupAndName<DistanceMeasurementValueRepresentation>(
                femoralGroup, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemResectionLesserTrochanter);
        }

        return null;
    }

    public getStemResectionGreaterTrochanter(): DistanceMeasurementValueRepresentation | null {
        const femoralGroup = this.getCurrentFemoralGroup();

        if (femoralGroup) {
            return MeasurementsUtil.getMeasurementByGroupAndName<DistanceMeasurementValueRepresentation>(
                femoralGroup, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemResectionGreaterTrochanter);
        }

        return null;
    }

    public getStemSaddleDistance(): DistanceMeasurementValueRepresentation | null {
        const femoralGroup = this.getCurrentFemoralGroup();

        if (femoralGroup) {
            return MeasurementsUtil.getMeasurementByGroupAndName<DistanceMeasurementValueRepresentation>(
                femoralGroup, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemSaddle);
        }

        return null;
    }

    public getStemLegLengthDifference(): AngleMeasurementValueRepresentation | null {
        const femoralGroup = this.getCurrentFemoralGroup();

        if (femoralGroup) {
            return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
                femoralGroup, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemLegLengthDifference);
        }

        return null;
    }

    public getStemLegOffsetDifference(): AngleMeasurementValueRepresentation | null {
        const femoralGroup = this.getCurrentFemoralGroup();

        if (femoralGroup) {
            return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
                femoralGroup, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemLegOffsetDifference);
        }

        return null;
    }

    /**
     * Warning: On the legacy measurements the cup anteversion was stored as a measurement.
     * On the new measurements it does not exists as such. The values are taken from
     * the cup_rotation of the surgical template which is on the CT/Anatomic combination.
     */
    getCupAnteversion(): AngleMeasurementValueRepresentation | null {
        const anteversion = this.surgicalTemplate.cup_rotation.anteversion;

        assert.ok(!isNil(anteversion), 'anteversion must be present on surgical tempalte');

        return {
            name: 'asdfasdf',
            type: MeasurementValueTypes.Angle,
            value: anteversion,
        };
    }

    public getCombinedVersion(): number | null {
        const stemVersion = this.getStemAnteversion();
        const cupVersion = this.getCupAnteversion();

        if (stemVersion && cupVersion) {
            return HipSurgicalTemplateMeasurementsUtil.getCombinedVersion(stemVersion, cupVersion);
        }

        return null;
    }

    private getCurrentFemoralGroup(): MeasurementGroupRepresentation | null {
        const currentStem = this.surgicalTemplate.stem;
        const currentHead = this.surgicalTemplate.head;
        assert.ok(currentStem, 'stem must be defined in surgical template');
        assert.ok(currentHead, 'head must be defined in surgical template');

        const femoralGroup = HipNewSurgicalTemplateMeasurements.getFemoralGroup(
            this.data,
            getRequiredUri(currentStem, LinkRelation.self),
            getRequiredUri(currentHead, LinkRelation.self));

        return femoralGroup || null;
    }

    private getCurrentAcetabularGroup(): MeasurementGroupRepresentation | null {
        const currentCup = this.surgicalTemplate.cup;
        const currentLiner = this.surgicalTemplate.liner;
        assert.ok(currentCup, 'cup must be defined in surgical template');
        assert.ok(currentLiner, 'liner must be defined in surgical template');

        const acetabularGroup = HipNewSurgicalTemplateMeasurements.getAcetabularGroup(
            this.data,
            getRequiredUri(currentCup, LinkRelation.self),
            getRequiredUri(currentLiner, LinkRelation.self));

        return acetabularGroup || null;
    }

    private static getFemoralGroup(
        measurements: MeasurementsRepresentation, stemUri: Uri, headUri: Uri): MeasurementGroupRepresentation | null {
        const femoralGroups = MeasurementsUtil.getGroupByName(measurements, MeasurementGroupNames.HipFemoralGroupsName);

        if (femoralGroups && !isNil(femoralGroups.groups) && !isEmpty(femoralGroups.groups)) {
            const femoralGroup = femoralGroups.groups.find(
                (group: MeasurementGroupRepresentation): MeasurementGroupRepresentation | null => {
                    const stemGroup = this.getGroupIfModelMatch(group, MeasurementGroupNames.HipStem, stemUri);
                    const headGroup = this.getGroupIfModelMatch(group, MeasurementGroupNames.HipHead, headUri);

                    return stemGroup && headGroup;
                });

            if (femoralGroup) {
                return femoralGroup;
            }
        }

        return null;
    }

    public static getAcetabularGroup(
        measurements: MeasurementsRepresentation, cupUri: Uri, linerUri: Uri): MeasurementGroupRepresentation | null {
        const acetabularGroups = MeasurementsUtil.getGroupByName(
            measurements, MeasurementGroupNames.HipAcetabularGroupsName);

        if (acetabularGroups && !isNil(acetabularGroups.groups) && !isEmpty(acetabularGroups.groups)) {
            const acetabularGroup = acetabularGroups.groups.find(
                (group: MeasurementGroupRepresentation): MeasurementGroupRepresentation | null => {
                    const cupGroup = this.getGroupIfModelMatch(group, MeasurementGroupNames.HipCup, cupUri);
                    const linerGroup = this.getGroupIfModelMatch(group, MeasurementGroupNames.HipLiner, linerUri);

                    return cupGroup && linerGroup;
                });

            if (acetabularGroup) {
                return acetabularGroup;
            }
        }

        return null;
    }

    private static getGroupIfModelMatch(
        group: MeasurementGroupRepresentation,
        subGroupName: MeasurementGroupName, stemUri: string): MeasurementGroupRepresentation | null {
        const subGroup = MeasurementsUtil.getGroupByName(group, subGroupName);
        if (subGroup) {
            const belongsToStem = MeasurementsGroupRepresentationUtil.belongsToModel(
                subGroup, { rel: subGroupName, href: stemUri });
            if (belongsToStem) {
                return subGroup;
            }
        }

        return null;
    }

    public getCupLegLengthDifference(): AngleMeasurementValueRepresentation | null {
        const acetabularGroup = this.getCurrentAcetabularGroup();

        if (acetabularGroup) {
            return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
                acetabularGroup, MeasurementGroupNames.HipCup, HipTemplateMeasurementName.CupLegLengthDifference);
        }

        return null;
    }

    public getCupLegOffsetDifference(): AngleMeasurementValueRepresentation | null {
        const femoralGroup = this.getCurrentAcetabularGroup();

        if (femoralGroup) {
            return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
                femoralGroup, MeasurementGroupNames.HipCup, HipTemplateMeasurementName.CupLegOffsetDifference);
        }

        return null;
    }
}
