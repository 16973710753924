import ResourceService from '@/lib/api/ResourceService';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import { FittingRepresentation } from '@/lib/api/representation/case/study/FittingRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { ApiUtil } from '@/lib/semantic-network';

export class StudyFittingResource implements ResourceService {
    /**
     * Get the fit results for a study.
     */
    public static async get(studyResource: StudyRepresentation, options?: CacheOptions):
        Promise<FittingRepresentation | null> {
        if (LinkUtil.matches(studyResource, LinkRelation.fitting)) {
            return await ApiUtil.get<FittingRepresentation>(
                studyResource, { rel: LinkRelation.fitting, ...options }) ?? null;
        }
        return null;
    }
}
