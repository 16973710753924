
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { RelationshipType } from 'semantic-link/lib/filter';
    import { LinkUtil } from 'semantic-link';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { Representation } from '@/lib/semantic-network';

    /**
     * A draggable resource, where the resource is provided in the following formats:
     *   - a tagged URI reference
     *   - JSON
     *   - plain text
     */
    @Component
    export default class DraggableResource extends Vue {
        /** the representation of the resource to be dragged */
        @Prop({ required: true })
        public value!: Representation;

        /** the link relation to use from {@link value} */
        @Prop({ default: LinkRelation.self })
        public relationship!: RelationshipType;

        @Prop({ required: true })
        public resourceType!: string;

        protected onDragStart(evt: DragEvent, _item: Element): void {
            if (evt?.dataTransfer) {
                evt.dataTransfer.dropEffect = 'copy';
                evt.dataTransfer.effectAllowed = 'copy';
                const uri = LinkUtil.getUri(this.value, this.relationship);
                if (uri) {
                    evt.dataTransfer.setData(
                        'application/resource+json',
                        JSON.stringify({
                            type: this.resourceType,
                            href: uri,
                        }));
                    evt.dataTransfer.setData('text/uri-list', uri);
                    evt.dataTransfer.setData('text/plain', `Resource ${uri} of type ${this.resourceType}`);
                }
                evt.dataTransfer.setData('application/json', JSON.stringify(this.value));
            }
        }
    }
