
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { CaseStepData, CaseStepDataImpl, StepStateColours } from '@/components/case/state-stepper/CaseStateData';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';

    import anylogger from 'anylogger';
    import { CaseStateStepperDataImpl } from '@/components/case/state-stepper/CaseStateStepperData';
    import CaseStateStepperController from '@/components/case/state-stepper/CaseStateStepperController';
    import { RefreshAction } from '@/lib/refresh-actions/RefreshAction';
    import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
    import { PlanningQueryParamsAvailable } from '@/lib/base/RouteParserUtils';
    import { EventHandlerUtils } from '@/lib/base/EventHandlerUtils';
    import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
    import {
        SurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
    import ResourceUtil from '@/lib/api/ResourceUtil';
    import { StudyUtil } from '@/lib/api/resource/case/study/StudyUtil';
    import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
    import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
    import Axios, { CancelTokenSource } from 'axios';
    import AxiosUtil from '@/lib/AxiosUtil';

    const log = anylogger('CaseStateStepper');

    /**
     * Case State Stepper
     * This horizontal stepper tracks the state of the current case. This provides a simple
     * but colourful stepper that allows the user to navigate to any (practical) step
     * within the lifecycle of a case.
     *
     * Note this contains a hidden feature that allows admins to press shift-control and click on
     * the 'cog' to see the admin settings for a case.
     */
    @Component
    export default class CaseStateStepper extends Vue {
        /** The case (project)
         *  This property must be provided by the parent
         **/
        @Prop({ required: true })
        value!: CaseRepresentation;

        /**
         * The interval time (in ms) between refreshing the state
         * Defaults to 5 seconds
         */
        @Prop({ default: 5000 })
        interval!: number;

        /**
         * Random offset to the interval on each refresh
         */
        @Prop({ default: 0 })
        intervalOffset!: number;

        /**
         * Optional configuration to pass to the case stepper.
         * When true, the stepper is in charge of reloading the user surgical template to keep its state synchronized.
         * The user surgical template is used in the stepper to determinate if to show the plan page or not.
         *
         * @see {@link CaseStateStepperController.hasPlans}
         *
         * This adds the ability to turn of the refresh of the surgical template in the case stepper
         * on the 3D planning page, which has its own update lifecycle through a [surgical-template-poller]{@link SurgicalTemplateSynchroniser}
         *
         * @default: true
         */
        @Prop({ required: false, default: true })
        protected refreshUserSurgicalTemplate!: boolean;

        protected caseSteps: CaseStepData[] = [
            //
            // The 'settings' (cog) icon allows the user to see the normal case settings, however if they
            // are an admin (or they are just want to look at the technical details), then golding the
            // shift and control keys while clicking on the cog the admin/manage page will be navigated to.
            // 5
            new CaseStepDataImpl(
                0,
                'Details',
                this.getCaseDetailsColour,
                false,
                '$vuetify.icons.caseStepDetails',
                (e: Event) => {
                    if (EventHandlerUtils.isCtrlShiftClickEventOrSimilar(e)) {
                        return this.getCaseManagerLink();
                    }

                    return this.getCaseDetailsLink();
                },
            ),
            new CaseStepDataImpl(
                1,
                'Dicom Viewer',
                this.getDicomViewerColour,
                false,
                '$vuetify.icons.caseStepDicomViewer',
                this.getDicomViewerLink,
            ),
            //
            // The '3d' (planning) icon allows the user to see go the to 3d planning page, however if they
            // are an admin (or they are just want to look at the debug mode (i.e.: list of landmarks), then
            // holding the shift and control keys while clicking on the icon the 3d planning page will be navigated
            // to, but adding a query parameter flag, that tells the app it is on debug mode.
            //
            new CaseStepDataImpl(
                2,
                'Planning',
                this.getPlannerColour,
                false,
                '$vuetify.icons.caseStepPlanner',
                (e: Event) => {
                    if (EventHandlerUtils.isCtrlShiftClickEventOrSimilar(e)) {
                        return {
                            path: this.getPlannerLink(),
                            query: {
                                [PlanningQueryParamsAvailable.DebugMode]: 'true',
                            },
                        };
                    }

                    return this.getPlannerLink();
                }
            ),
            new CaseStepDataImpl(
                3,
                'pdf',
                this.getPlanColour,
                true,
                '$vuetify.icons.caseStepPdf',
                this.getPlanLink,
            ),
        ];

        private hasIntersected = false;
        private loading = false;
        private firstLoad = true;

        private stepperController: CaseStateStepperController | null = null;
        private refresh: RefreshAction | null = null;

        private refreshCancellation: CancelTokenSource | null = null;

        protected created(): void {
            this.stepperController = new CaseStateStepperController(new CaseStateStepperDataImpl(this.value));
            this.refresh = new RefreshAction(
                this.interval /* ms */, this.onRefresh, { offset: this.intervalOffset });
        }

        protected mounted(): void {
            this.refresh?.enable().onMounted();
        }

        protected beforeDestroy(): void {
            this.refresh?.onDestroy();
        }

        protected async onIntersect(
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
            isIntersecting: boolean): Promise<void> {
                this.refresh?.onIntersect(isIntersecting);

                if (isIntersecting) {
                    // refresh if we are visible and intersected
                    this.hasIntersected = true;
                } else {
                    this.refreshCancellation?.cancel();
                }
            }

        /**
         * Handler executed every certain time, based on the timeout configured for the refresh action
         */
        private async onRefresh(): Promise<void> {
            log.debug('Refresh timeout executed, will proceed to refresh data on case stepper');
            await this.refreshData(true);
        }

        /**
         * Handler executed as reponse to a reactive change on the project.
         *
         * Some scenarios that are going through this flow today are:
         *  a) When the user finishes uploading the studies, we do not want to wait to next refresh cycle.
         *  It is ideal to execute this function immediatly.
         *
         *  b) When the duplicate study or duplicate acid surgical tempalte is clicked, we do not want to wait to next
         *  refresh cycle. We want to execute this function immediatly.
         *
         *  TODO: Even the 'reactivity' concept here would make sense, it is maybe to powerful, and would trigger
         *  TODO: other refreshed that we are not expecting
         *  TODO: Consider another communication strategy (events or more refined reactivity watches, which would lead
         *  TODO: to smaller components)
         *
         */
        @Watch('value')
        private async onProjectChange(value: CaseRepresentation) {
            log.info('Project changes detected, will proceed to refresh data on case stepper: %o', value);
            // It is needed to skip project refresh to avoid entering into a recursive loop.
            this.refreshData(false);
        }

        /**
         * The main refresh function executed on:
         * 1. every timed refresh cycle
         * 2. as a reactive response to a change on the project.
         *
         * @param needRefreshProject: Optional flag whether the project needs to be refreshed or not prior to refresh
         * the children resources (study, acid/manual user surgical templates).
         *
         * The project might be wanted to be reloaded so changes on links (study, current/acid surgical template)
         * can be detected
         *
         * TODO: This is a temporary strategy until smells detailed on {@method onProjectChange} could be addressed
         */
        private async refreshData(needRefreshProject: boolean) {
            if (this.firstLoad) {
                this.loading = true;
            }
            try {
                this.refreshCancellation = Axios.CancelToken.source();

                log.debug(
                    'Case stepper (%s): intersected: %s',
                    getRequiredUri(this.project, LinkRelation.self),
                    this.hasIntersected);
                if (this.project && this.hasIntersected) {
                    if (needRefreshProject) {
                        this.refreshCancellation.token.throwIfRequested();
                        await ResourceUtil.refresh(this.project, this.$apiOptions);
                    }

                    this.refreshCancellation.token.throwIfRequested();
                    await this.maybeReloadCaseActiveStudy();

                    this.refreshCancellation.token.throwIfRequested();
                    await this.maybeReloadAcidSurgicalTemplate();

                    this.refreshCancellation.token.throwIfRequested();
                    await this.maybeReloadUserSurgicalTemplate();
                }
            } catch (error: unknown) {
                if (AxiosUtil.isCancelled(error)) {
                    log.info('stepper loading cancelled');
                } else {
                    throw error;
                }
            } finally {
                if (this.firstLoad) {
                    this.loading = false;
                    this.firstLoad = false;
                }
            }
        }

        /**
         * Reload the acid surgical tempalte if:
         * a) has not being loaded yet.
         * b) the acid surgical template is in a 'mutable' state, meaning that is still being processed
         * and it is likely to be updated soon.
         *
         * The plan heuristic allows to guess that the plan creation failed without having to fetch the plan resource.
         * The strategy here will be that the plan creation process should not take too long and this will help to
         * reduce the number of HTTP request by no polling the acid surgical template indefinitely.
         */
        private async maybeReloadAcidSurgicalTemplate(): Promise<SurgicalTemplateRepresentation | null> {
            const acidSurgicalTemplate = await SurgicalTemplateResource.getCaseAutomatedSurgicalTemplate(
                this.project, this.$apiOptions);
            if (acidSurgicalTemplate) {
                // if it is a sparse resource, we get it.
                await ResourceUtil.ensureHydratedResource(acidSurgicalTemplate);

                if (SurgicalTemplateUtil.isCompletedWithAPlan(acidSurgicalTemplate) ||
                    SurgicalTemplateUtil.isCompletedButPlanPossiblyFailed(acidSurgicalTemplate) ||
                    SurgicalTemplateUtil.isError(acidSurgicalTemplate)) {
                    // Nothing to do. The study is immutable at this point.
                    log.debug(
                        'the surgical template (%s) is in the state: %s. Skipping study reload.',
                        getRequiredUri(acidSurgicalTemplate, LinkRelation.self),
                        acidSurgicalTemplate.state);
                } else {
                    // active surgical template is still processing. It is reloaded so the state can be checked.
                    return await SurgicalTemplateResource.getCaseAutomatedSurgicalTemplate(
                        this.value, { forceLoad: true, ...this.$apiOptions });
                }
            } else {
                // There is no acid surgical template
            }

            return null;
        }

        /**
         * Reload the active study if:
         * a) has not being loaded yet.
         * b) the active study is in a 'mutable' state, meaning that is still being processed
         * and it is likely to be updated soon.
         */
        private async maybeReloadCaseActiveStudy(): Promise<StudyRepresentation | null> {
            const activeStudy = await CaseStudyResource.getActiveStudy(this.value, this.$apiOptions);
            if (activeStudy) {
                // if it is a sparse resource, we get it.
                await ResourceUtil.ensureHydratedResource(activeStudy);

                if (!StudyUtil.isCompleted(activeStudy) && !StudyUtil.isError(activeStudy)) {
                    // active study is still processing. It is reloaded so the state can be checked.
                    return await CaseStudyResource.getActiveStudy(
                        this.value, { forceLoad: true, ...this.$apiOptions });
                } else {
                    // Nothing to do. The study is immutable at this point.
                    log.debug(
                        'the study (%s) is in the state: %s. Skipping study reload.',
                        getRequiredUri(activeStudy, LinkRelation.self),
                        activeStudy.state);
                }
            } else {
                // There is no active study
            }

            return null;
        }

        /**
         * Conditionally reload the user surgical template
         *
         * @see {@link this.refreshUserSurgicalTemplate}
         *
         * TODO To evaluate if the polling of the user surgical template can be stop by any reason.
         *      At this point the lifecycle of this resource seems different to the study and the
         *      acid surgical template. The User surgical template does not reach a 'complete' state,
         *      it will be in 'manual processing' meaning, it can still change and we need to keep polling.
         */
        private async maybeReloadUserSurgicalTemplate(): Promise<void> {
            if (this.refreshUserSurgicalTemplate) {
                await SurgicalTemplateResource.getCaseUserSurgicalTemplate(
                    this.value, { forceLoad: true, ...this.$apiOptions });
            } else {
                log.debug('Surgical template is not refreshed on case stepper due to config');
            }
        }

        /**
         *  Do something when the step icon is clicked
         *  This method is called with the step's index value
         */
        private stepClicked(e: Event, step: CaseStepData): void {
            const location = step.makeLocation(e);

            log.debug('clicked on step %s with location %o (%o)', step.title, location, e);

            this.$router.push(location)
                .catch((err) => log.error('Failed to navigate to stepper location %o: %s', location, err));
        }

        /**
         * Case Details
         *   - is active if the case exists (always)
         *   - is complete is all require data is provided (TODO some way to check)
         */
        private getCaseDetailsColour(): StepStateColours {
            return this.stepperController?.caseDetailsStepState || StepStateColours.Inactive;
        }

        /** Make the URL to the case settings page. */
        private getCaseDetailsLink(): string {
            return this.stepperController?.caseDetailsStepLink || '';
        }

        /**
         * Dicom Viewer
         *   - is active & complete if catstack exists
         */
        private getDicomViewerColour(): StepStateColours {
            return this.stepperController?.dicomViewerStepState || StepStateColours.Inactive;
        }

        private getDicomViewerLink(): string {
            return this.stepperController?.dicomViewerStepLink || '';
        }

        /**
         * * Planning
         *   - is active if selected cup exists
         *   - is complete if case is approved
         */
        private getPlannerColour(): StepStateColours {
            return this.stepperController?.templateStepState || StepStateColours.Inactive;
        }

        private getPlannerLink(): string {
            return this.stepperController?.templateStepLink || '';
        }

        /**
         * * Plan page
         *   - is active if case is approved
         *   - is complete is case report exists
         */
        private getPlanColour(): StepStateColours {
            return this.stepperController?.planStepState || StepStateColours.Inactive;
        }

        private getPlanLink(): string {
            return this.stepperController?.planStepLink || '';
        }

        private getCaseManagerLink(): string {
            return this.stepperController?.caseManagerLink || '';
        }

        private get project(): CaseRepresentation {
            return this.value;
        }
    }
