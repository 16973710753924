import { LinkType, RelationshipType } from 'semantic-link';
import { AxiosInstance } from 'axios';
import { loadImageDataUrlMemoized } from '@/lib/http/image-data-loader/ImageDataMemoization';

/**
 * Perform an http GET of an url that must return an image which
 * is encodes as a data url string.
 *
 * The data url can be assigned to the image 'src' of an image (or v-img).
 *
 * see
 *   {@link https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL}
 */
export default class ImageDataUrlLoader {
    private readonly parent: LinkType;
    private readonly axios: AxiosInstance;
    private readonly relationship: RelationshipType;
    private dataUrlData: string | undefined = undefined;

    constructor(axios: AxiosInstance, parent: LinkType, relationship: RelationshipType) {
        this.relationship = relationship;
        this.axios = axios;
        this.parent = parent;
    }

    /**
     * Access to the data url once it is loaded.
     *
     * If the image is not loaded, an error occurs, or loading is in progress then
     * an undefined value will be returned.
     *
     * The data url string can be assigned as the source of an image.
     */
    public get dataUrl(): string | undefined {
        return this.dataUrlData;
    }

    /**
     * Asynchronously load the image and return the data url (or undefined on error).
     */
    public async load(): Promise<string | undefined> {
        this.dataUrlData = await loadImageDataUrlMemoized(this.axios, this.parent, this.relationship);
        return this.dataUrlData;
    }
}
