import Implant3d from '@/hipPlanner/assembly/objects/Implant3d';
import Bone3d from '@/hipPlanner/assembly/objects/Bone3d';

export type AcidMeshObject3d = Implant3d | Bone3d;

/**
 * Check if the object is of type AcidMeshObject3d
 * We have to check each individual type, since we can't use the union type here
 */
export function instanceOfAcidMeshObject3D(acidObject: unknown): acidObject is AcidMeshObject3d {
    return acidObject instanceof Implant3d || acidObject instanceof Bone3d;
}
