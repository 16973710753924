import ResourceService from '@/lib/api/ResourceService';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import { AuthenticatorRepresentation } from '@/lib/api/representation/AuthenticatorRepresentation';
import { AxiosInstance, AxiosResponse } from 'axios';
import {
    RequestPasswordResetRepresentation,
    SetPasswordRepresentation,
} from '@/lib/api/representation/RefreshTokenRepresentation';
import { getUri } from 'semantic-link';

import anylogger from 'anylogger';
import { ApiUtil } from '@/lib/semantic-network';

const log = anylogger('AuthenticatorResource');
/**
 * User resource includes both surgeon and non-surgeon users.
 */
export default class AuthenticatorResource implements ResourceService {
    /**
     * Load the authenticator resource. This is a global singleton resource that can be used
     * around authentication workflows.
     */
    public static async getAuthenticator(apiResource: ApiRepresentation, options?: CacheOptions):
        Promise<AuthenticatorRepresentation | null> {
        return await ApiUtil.get<AuthenticatorRepresentation>(
            apiResource, { rel: LinkRelation.authenticator, ...options }) ?? null;
    }

    /**
     * Load the authenticator resource. This is a global singleton resource that can be used
     * around authentication workflows.
     */
    public static async resetPassword(
        http: AxiosInstance,
        apiResource: ApiRepresentation,
        email: string,
        options?: CacheOptions):
        Promise<boolean> {
        const authenticator = await AuthenticatorResource.getAuthenticator(apiResource, options);
        if (authenticator) {
            const passwordResetTokenUrl = getUri(authenticator, LinkRelation.resetPassword);
            if (passwordResetTokenUrl) {
                const tResponse = await http.post<RequestPasswordResetRepresentation, AxiosResponse<void>>(
                    passwordResetTokenUrl, {
                        email,
                    });
                if (tResponse && (tResponse.status === 201 /* created */ || tResponse.status === 202 /* accepted */)) {
                    return true;
                }
                log.error('Failed to send password reset (status %d)', tResponse.status);
            }
            log.error('Failed to get authenticator');
        }
        return false;
    }

    /**
     * Using a one-time token (from an password reset email), set the users password.
     */
    static async setPassword(
        http: AxiosInstance,
        apiResource: ApiRepresentation,
        email: string,
        token: string,
        password: string,
        options?: CacheOptions): Promise<boolean> {
        const authenticator = await AuthenticatorResource.getAuthenticator(apiResource, options);
        if (authenticator) {
            const setPasswordUrl = getUri(authenticator, LinkRelation.setPassword);
            if (setPasswordUrl) {
                const tResponse = await http.post<SetPasswordRepresentation, AxiosResponse<void>>(setPasswordUrl, {
                    email,
                    token,
                    password,
                });
                if (tResponse && (tResponse.status === 201 /* created */ || tResponse.status === 202 /* accepted */)) {
                    return true;
                }
                log.error('Failed to set password (status %d)', tResponse.status);
            }
            log.error('Failed to get authenticator');
        }
        return false;
    }
}
