import { getUri, Uri } from 'semantic-link';
import { AxiosInstance, AxiosResponse } from 'axios';
import { Token } from 'auth-header';
import { AuthenticatorRepresentation } from '@/lib/api/representation/AuthenticatorRepresentation';
import {
    JwtAuthenticateRepresentation,
    JwtRefreshAuthenticateRepresentation,
    RefreshTokenRepresentation,
} from '@/lib/api/representation/RefreshTokenRepresentation';
import anylogger from 'anylogger';

const log = anylogger('Login');

/**
 * The information provided by the 'Resource' based www-authenticate scheme.
 */
export interface ResourceAuthenticationScheme {
    /**
     * The URI of the resource that provides the links to authentication resources.
     */
    uri: string;

    /**
     * The realm of the authentication scheme. This should be a different string for
     * each of the deployed environments.
     */
    realm: string;
}

export default class AuthenticationUtils {
    public static async getAuthenticator(
        http: AxiosInstance,
        authenticatorUri: Uri): Promise<AuthenticatorRepresentation | null> {
        const response = await http.get<AuthenticatorRepresentation>(authenticatorUri);
        if (response.status === 200) {
            return response.data;
        }
        return null;
    }

    /**
     * Create a JWT (JSON Web Token) fresh token using a users credentials (username and password).
     *
     * The server will return both a fresh token and an access token. The access token is used
     * as the 'Bearer' token for all authenticated requests up until it stops working (because it
     * has reached it's expiry time).
     */
    public static async createAuthenticationToken(
        http: AxiosInstance,
        authenticatorUri: Uri,
        username: string,
        password: string): Promise<RefreshTokenRepresentation | undefined> {
        const authenticator = await AuthenticationUtils.getAuthenticator(http, authenticatorUri);
        if (authenticator) {
            const jwAuthenticate = getUri(authenticator, 'jwt-authenticate');
            if (jwAuthenticate) {
                const tResponse = await http.post<JwtAuthenticateRepresentation, AxiosResponse<RefreshTokenRepresentation>>(
                    jwAuthenticate,
                    {
                        email: username,
                        password,
                    });
                if (tResponse.status === 200 || tResponse.status === 201) {
                    const data = tResponse.data;
                    if (data?.refresh && data.access) {
                        const token = data.access;
                        log.info('refresh token : %o', token);
                        return data;
                    }
                }
            }
        }
    }

    public static async createAccessToken(
        http: AxiosInstance,
        authenticatorUri: Uri,
        refreshToken: string): Promise<RefreshTokenRepresentation | undefined> {
        const authenticator = await AuthenticationUtils.getAuthenticator(http, authenticatorUri);
        if (authenticator) {
            const jwAuthenticate = getUri(authenticator, 'jwt-token');
            if (jwAuthenticate) {
                const tResponse = await http.post<JwtRefreshAuthenticateRepresentation, AxiosResponse<RefreshTokenRepresentation>>(
                    jwAuthenticate,
                    { refresh: refreshToken });
                if (tResponse.status === 200 || tResponse.status === 201) {
                    const data = tResponse.data;
                    if (data?.refresh && data.access) {
                        const _token = data.access;
                        return data;
                    }
                }
            }
        }
    }

    /**
     * Given a list of 'www-authenticate' header schemes, get the 'resource' scheme parameters
     */
    public static getResourceScheme(schemes?: Token[]): ResourceAuthenticationScheme | undefined {
        if (schemes && Array.isArray(schemes)) {
            const r = schemes.find((t: Token): boolean => t.scheme === 'Resource');
            if (r?.params.realm && typeof r.params.realm === 'string' &&
                r.params.uri && typeof r.params.uri === 'string') {
                return {
                    uri: r.params.uri,
                    realm: r.params.realm,
                };
            } else {
                log.warn('Malformed resource authentication scheme');
            }
        }
        log.warn('\'Resource\' authenticate scheme not found in %d schemes %o', (schemes || []).length, schemes || []);
    }
}
