/**
 * Standard colors that are used for 3D objects in the scene
 */

/**
 * Bright white, used for inner-cortical surfaces and components
 */
export const WHITE_COLOR = '#ffffff';

/**
 * Bone-color; used for bones.
 */
export const BONE_COLOR = '#d4c18c';

/**
 * Brown that is slightly darker than {@link BONE_COLOR}. Used for the back-side (inside surface)
 * of bone-colored objects.
 */
export const BONE_BACKSIDE_COLOR = '#bfb899';

/**
 * Light blue color used on stems
 */
export const STEM_COLOR = '#3571c0';

/**
 * Purple color used on metal objects
 */
export const METAL_COLOR = '#634691';
