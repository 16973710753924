
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import QRCode from '@/components/case-plan/actions/QRCode.vue';
    import { NameRepresentationUtil } from '@/components/case-settings/utils/NameRepresentationUtil';
    import { NameRepresentation } from '@/lib/api/representation/NameRepresentation';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';
    import ProjectStore from '@/hipPlanner/components/state/project/ProjectStore';

    /** This is the report that is printed when downloading the QR Code as pdf */
    @Component({ components: { QRCode } })
    export default class PrintableQRCode extends Vue {
        /** used in the template */
        protected formatName = NameRepresentationUtil.format;

        @InjectReactive() planStore!: PlanReportStore;
        @InjectReactive() store!: ProjectStore;

        protected get caseName(): string {
            return this.store.projectName;
        }

        protected get planId(): string {
            return this.planStore.planId;
        }

        protected get surgicalTemplateHistoryId(): string {
            return this.planStore.templateHistoryId;
        }

        protected get patientName(): NameRepresentation | null {
            return this.store.patient?.name || null;
        }

        protected get surgeonName(): NameRepresentation | null {
            return this.store.surgeon?.name || null;
        }
    }
