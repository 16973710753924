import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import UserResource from '@/lib/api/resource/user/UserResource';
import { NameRepresentationUtil } from '@/components/case-settings/utils/NameRepresentationUtil';
import anylogger from 'anylogger';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';

// export log for testing purposes
export const log = anylogger('LoadHipSurgeons');

export interface SurgeonSelectorOption {
    text: string,
    value: string,
}

export async function loadHipSurgeons(
    api: ApiRepresentation,
    options: CacheOptions,
    productUri: string
): Promise<SurgeonSelectorOption[]> {
    /*
    Retrieve the list of available hip surgeons a given use can see.
    Generally speaking:
    - a surgeon can only see themselves
    - a surgeon rep can see a select few surgeons linked to that account
    - a super admin can see all surgeons
     */
    log.info('Loading list of available surgeons... this may take some time.');

    const surgeonSelectorOptions: SurgeonSelectorOption[] = [];

    try {
        // Using the 'me' user resource, get a list of surgeons available to the user
        const surgeonCollection = await UserResource.getSurgeons(
            api, productUri, options);

        // Map the collection of surgeons to the type used for a combobox.
        surgeonCollection?.items.forEach((item): void => {
            const surgeonName = NameRepresentationUtil.format(item.name);
            const surgeonUri = LinkUtil.getUri(item, LinkRelation.canonicalOrSelf);
            if (surgeonUri) {
                surgeonSelectorOptions.push({
                    text: surgeonName,
                    value: surgeonUri,
                });
            } else {
                log.warn(`Skip surgeon: '${surgeonName}' is missing uri`);
            }
        });

        log.info(`Finished loading ${surgeonSelectorOptions.length} surgeon(s).`);

        return surgeonSelectorOptions;
    } catch (err) {
        throw new Error(`Failed to load surgeons list. ${err}`);
    }
}
