import { AxiosInstance } from 'axios';
import {
    DicomGroups,
    DicomMessage,
    DicomMessageLevel,
    DicomSeries,
} from '@/lib/dicom/DicomSeries';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import DicomUtils from '@/lib/dicom/DicomUtil';
import anylogger from 'anylogger';
import DragDropUtil from '@/lib/dragdrop/DragDropUtil';
import DicomUploadUtil from '@/lib/dicom/DicomUploadUtil';
import { DicomUploadTask } from '@/components/case-settings/scan/workflow/DicomUploadTask';
import { DicomInfo } from '@/lib/dicom/DicomInfo';
import {
    UploadDicomData,
    WorkerEvent,
} from '@/worker/types';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { AuthenticatedWorker } from '@/worker/AuthenticatedWorker';

export interface UploadData {
    value: DicomSeries,
    id: string
}

/**
 * A tagging type to specify the series have been already mutated with the 'isExcluded' flag and messages (error, warnings)
 * have been added, but no decision about if it is ok for upload has been made.
 */
export interface ValidatedResult {
    /**
     * The dicom groups being analyzed, and processed. This is exposed just for context and continuation
     * of the workflow.
     */
    groups: DicomGroups,
    /** The candidate for upload will be present if the state is not in error */
    candidate: UploadData | null,
}

const log = anylogger('ScanUploadService');

export default class ScanUploadService {
    constructor(
        private readonly project: CaseRepresentation,
        private readonly $http: AxiosInstance,
        private $apiOptions: CacheOptions) {
    }

    /**
     * Whether an upload is in progress, and thus whether to show the progress slider
     */
    protected _isUploading = false;

    public uploadTask: DicomUploadTask | null = null;

    private clearState(): void {
        this._isUploading = false;
        this.uploadTask = null;
        this.cancel();
    }

    public cancel() {
        this.uploadTask?.cancel();
    }

    /**
     * This is the first step of the process.
     * The data is collected, parsed and grouped by patient identity (name, sex, date of birth)
     */
    public async parse(data: DataTransfer): Promise<Record<string, DicomInfo[]> | null> {
        this.clearState();

        log.info(
            '%d types [%s], %d files, %d items',
            data.types.length, data.types, data.files.length, data.items.length);

        const entries = await DragDropUtil.getAllFileEntriesAsFiles(data);
        const dicoms = await DicomUtils.mapFilesToDicoms(entries);

        // Note: Enable the following calls during development
        // in case you want to mutate the files uploaded to see some edge cases
        // removeIdentity(dicoms);
        // mutateWithTestPatientNames(dicoms);

        return await DicomUtils.groupByIdentity(dicoms);
    }

    public async makeValidatedSeries(infos: DicomInfo[]): Promise<ValidatedResult> {
        try {
            const validatedGroups = await DicomUtils.makeValidatedDicomSeries(infos);
            DicomGroupUtil.logSeriesMessages(validatedGroups);
            const candidates = this.getNonExcludedGroups(validatedGroups);

            if (candidates.length === 1) {
                const [id, group] = candidates[0];
                return { groups: validatedGroups, candidate: { id, value: group } };
            } else {
                log.error('Finding one series failed. Got %d, %o', candidates.length, candidates);
                return { groups: validatedGroups, candidate: null };
            }
        } catch (error: unknown) {
            log.error('File upload failed: %s', error instanceof Error ? error.message : error);
        }

        return { groups: {}, candidate: null };
    }

    public startUploadWorker(
        study: StudyRepresentation,
        uploadTask: DicomUploadTask,
        worker: AuthenticatedWorker,
    ): void {
        this._isUploading = true;
        this.uploadTask = uploadTask;
        try {
            const ctFileCollectionUri = DicomUploadUtil.getStudyFilesUri(study);

            const { value: { items: fileInfos } } = uploadTask.getUploadData();

            const data: UploadDicomData = {
                event: WorkerEvent.Start,
                fileInfos: fileInfos,
                ctFileCollectionUri: ctFileCollectionUri,
            };

            worker.trigger(data);
        } catch (e) {
            // We do not care which type of exception it has been thrown.
            // We subordinate to the task being cancelled, and the upload process finishes.
            // Ideally checking for the typo of exception (https://stackoverflow.com/questions/61741423/abortcontroller-and-fetch-how-to-distinguish-network-error-from-abort-error)
            // will be a better solution.
            if (this.uploadTask.getAbortSignal().aborted) {
                log.info('scan upload task aborted');
                // the task has already been aborted
            } else {
                this.uploadTask.cancel();
                throw e;
            }
        } finally {
            this._isUploading = false;
        }
    }

    public get currentUploadTask(): DicomUploadTask | null {
        return this.uploadTask ?? null;
    }

    /**
     * Check if the series can be automatically uploaded without the user having to press the upload button.
     *
     * 1. There must be no series warning messages
     * 2. In the files that are due to be uploaded (not excluded), there must be no warning messages
     */
    public isSeriesOkForAutoUpload(series: DicomSeries): boolean {
        function isWarning(m: DicomMessage): boolean {
            return m.level <= DicomMessageLevel.Warning;
        }

        // Check there are no series warnings
        if (!series.messages.some((m) => isWarning(m))) {
            // check there are no file warnings
            if (!series.items
                .filter((item) => !item.isExcluded)
                .some((file) => file.messages.some((m) => isWarning(m)))) {
                return true;
            } else {
                log.debug('Some files have warnings');
            }
        } else {
            log.debug('Series has warnings');
        }
        return false;
    }

    protected getNonExcludedGroups(groups: DicomGroups): [string, DicomSeries][] {
        return Object.entries(groups || []).filter(([_key, group]) => !group.isExcluded);
    }
}

export class DicomGroupUtil {
    public static logSeriesMessages(series: DicomGroups): void {
        for (const [id, s] of Object.entries(series)) {
            // Summary of the series
            log.info('Series \'%s\', %d files, %d message, %s ', id,
                s.items.length,
                s.messages.length,
                s.isExcluded ? 'EXCLUDED' : 'CANDIDATE');
            // Series messages
            for (const m of s.messages) {
                log.info('    %s: %s', m.level, m.message);
            }
            // DICOM files messages
            for (const i of s.items) {
                for (const m of i.messages) {
                    log.info('        %s: %s', m.level, m.message);
                }
            }
        }
    }
}
