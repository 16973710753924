import { getUri } from 'semantic-link';
import memoize from 'memoizee';
import ImageDataUrlLoaderUtil from '@/lib/http/image-data-loader/ImageDataUrlLoaderUtil';
import { LinkedRepresentation } from 'semantic-link/lib/interfaces';

/**
 * By using memoize, we avoid doing multiple request for the same Image Blob
 * E.g: When changing steps on the shoulder app, catstack request should be only fired once (first step)
 */

// PLY are assume to not change over long period of times
// We are caching them for 1 week (assuming no page reload):
const ONE_WEEK_CACHE = 7 * 24 * 60 * 1000;
const loadImageDataUrlMemoized = memoize(ImageDataUrlLoaderUtil.loadImageDataUrl, {
    maxAge: ONE_WEEK_CACHE,
    promise: true,
    normalizer: function([_axios, parent, relationship]): string {
        const uri = getUri(parent as LinkedRepresentation, relationship);
        // the cache id is the url of the resource
        const cacheId = uri || JSON.stringify(parent);
        return cacheId;
    },
});

export {
    loadImageDataUrlMemoized,
};
