import ResourceService from '@/lib/api/ResourceService';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { CacheOptions, create, get } from '@/lib/semanticNetworkMigrationUtils';
import {
    PlanCollectionRepresentation,
    PlanRepresentation,
} from '@/lib/api/representation/case/plan/PlanRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import { makePlanCreateDataRepresentation } from '@/lib/api/representation/case/plan/PlanCreateDataRepresentation';
import { PlanFileCollectionRepresentation } from '@/lib/api/representation/case/plan/PlanFileRepresentation';

import ResourceUtil from '@/lib/api/ResourceUtil';
import {
    SurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import HipSurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateResource';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
import {
    HipSurgicalSpecificationRepresentation,
    SurgicalSpecificationRepresentationType,
} from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import MeasurementsResource from '@/lib/api/resource/case/MeasurementsResource';
import { ApiUtil, pooledSingletonMakeStrategy } from '@/lib/semantic-network';
import ContentType from '@/lib/http/mimetype';
import {
    HipComponentsCatalogRepresentation,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
import assert from 'assert';
import {
    HipReportMeasurementsRepresentation,
} from '@/lib/api/representation/case/plan/HipReportMeasurementsRepresentation';

/**
 * A plan resource is created in the context of a surgical template (history). When the plan resource
 * is created it will visible:
 *   - in the context of the project
 *   - in the context of the surgical template
 *   - in the context of the surgical template history
 *
 * The plan resource has data that binds it (strongly) into a specific version of the surgical template, which
 * in turn will bind it to a version o the surgical specification, which will bind it to the surgeon preferences.
 * The 'plan' resource is immutable and has strong non-repudiation characteristics (but it is not cryptographically
 * non-reputable).
 *
 * Note: Historically the plan was created in the context of the project. As of July 2020 this is being
 * moved to the surgical template context.
 */
export default class PlanResource implements ResourceService {
    /** Get a sparse list of plans created for the case. */
    public static async getCasePlans(caseResource: CaseRepresentation, options?: CacheOptions):
        Promise<PlanCollectionRepresentation | null> {
        return await ApiUtil.get<PlanCollectionRepresentation>(
            caseResource, { rel: LinkRelation.projectPlans, ...{ includeItems: false }, ...options }) ?? null;
    }

    /**
     * Get the list of 3d model files on a plan.
     */
    public static async getPlanFiles(planResource: PlanRepresentation, options?: CacheOptions):
        Promise<PlanFileCollectionRepresentation | null> {
        return await ApiUtil.get<PlanCollectionRepresentation>(
            planResource, { rel: LinkRelation.planFiles, ...{ includeItems: false }, ...options }) ?? null;
    }

    /**
     * Get the surgical specicification of the plan (through the surgical template history).
     */
    public static async getSurgicalSpecification<T extends SurgicalSpecificationRepresentationType>(
        plan: PlanRepresentation, options?: CacheOptions): Promise<T | null> {
        const surgicalTemplateHistory = await ApiUtil.get<HipSurgicalTemplateRepresentation>(
            plan, { rel: LinkRelation.up, ...options, name: LinkRelation.template });
        if (surgicalTemplateHistory) {
            // load the surgical specifications so that the APP (anterior pelvic plane) mode can be verified
            return await SurgicalTemplateResource.getSurgicalSpecification<T>(surgicalTemplateHistory, options);
        }

        return null;
    }

    /**
     * Get a plan. The plan can be a sparsely populated plan (i.e. it must have a self link).
     */
    public static async getPlan(plan: PlanRepresentation, options?: CacheOptions): Promise<PlanRepresentation | null> {
        return await ApiUtil.get<PlanRepresentation>(plan, options) ?? null;
    }

    /**
     * Get the report measurements which were introduced during the development of Manual Stem Positioning (release v1.7)
     * @param plan
     * @param options
     */
    public static async getReportMeasurements(plan: PlanRepresentation, options?: CacheOptions): Promise<HipReportMeasurementsRepresentation | null> {
        return await ApiUtil.get<HipReportMeasurementsRepresentation>(plan, {
            ...options,
            rel: LinkRelation.reportMeasurements,
            // name: 'reportMeasurements',
        }) ?? null;
    }

    /**
     * An upfront harvest of all data associated with the plan to make a copy for the third party service.
     */
    public static async getPlanForExternalIntegration(
        project: CaseRepresentation,
        plan: PlanRepresentation,
        componentsCatalog: HipComponentsCatalogRepresentation,
        api: ApiRepresentation,
        options?: CacheOptions): Promise<PlanRepresentation> {
        // Get the canonical version of the hip surgical template
        const template = await ApiUtil.get<HipSurgicalTemplateRepresentation>(
            plan, { rel: LinkRelation.up, ...options, name: LinkRelation.template });
        assert.ok(template, 'cannot get template from plan');

        const _specification = await PlanResource.getSurgicalSpecification<HipSurgicalSpecificationRepresentation>(plan);

        const _reportMeasurements = await PlanResource.getReportMeasurements(plan, options);
        const _surgicalTemplateMeasurements = await SurgicalTemplateResource.getMeasurements(template, options);
        const study = await SurgicalTemplateResource.getStudy(project, template, options);
        if (study) {
            const _studyMeasurements = await MeasurementsResource.getStudyMeasurements(study, options);
        }

        // The components are global to the API. Ensure the top level is loaded so that the hip components
        // can be referenced.
        const _stem = await HipSurgicalTemplateResource.getCurrentStem(template, componentsCatalog, options);
        const _head = await HipSurgicalTemplateResource.getCurrentStemHead(template, componentsCatalog, options);
        const _cup = await HipSurgicalTemplateResource.getCurrentCup(template, componentsCatalog, options);
        const _liner = await HipSurgicalTemplateResource.getCurrentCupLiner(template, componentsCatalog, options);

        return plan;
    }

    /**
     * Load the optional plan from the collection of all plans on a surgical template. Each surgical template
     * (whether it be a 'user' surgical template or an 'acid' surgical template) will optionally have a
     * current plan. If a new plan is created in the context of the surgical template then that new plan will
     * become current and the older plan will be (largely) unavailable to the user.
     *
     * This method will get the plan from the collection of all plans on the surgical template so that
     * client side a single (shared) instance of the object is instantiated.
     */
    public static async getSurgicalTemplatePlan<T extends PlanRepresentation>(
        surgicalTemplate: SurgicalTemplateRepresentation,
        caseResource: CaseRepresentation,
        options?: CacheOptions): Promise<T | null> {
        const casePlans = ResourceUtil.makePooledCollection(
            caseResource, { ...options, rel: LinkRelation.projectPlans });

        const plan = await get<T>(
            surgicalTemplate, {
                ...options,
                // The surgical template points to the current view of the plan. If the template has already
                // a hydrated virtual plan copy we want that copy to be in sync with the template updated links
                forceLoadNamedResource: options?.forceLoad,
                name: 'currentPlan',
                rel: LinkRelation.currentSurgicalTemplatePlan,
                makeSparseStrategy: (o) => pooledSingletonMakeStrategy(casePlans, o),
            }) ?? null;
        return plan;
    }

    /**
     * Create a Plan that is fixed/related to a surgical template history
     *
     * In business language: "Approves a case"
     *
     * The design principle here is that the surgeon is explicitly approving a specific version
     * of the surgical template. The UI must present the information that is being approved.
     * By linking together the various history record we have a chain of data that is explicit
     * and irrevocable (i.e. non-repudiation).
     *
     * It is important to note that the latest surgical template history is used while approving
     * the plan, since if an outdated version is used, the approved plan and therefore the PDF report
     * may contain the wrong data.
     */
    public static async createPlan(
        surgicalTemplate: SurgicalTemplateRepresentation,
        caseResource: CaseRepresentation,
        options?: CacheOptions): Promise<PlanRepresentation | null> | never {
        // The pool is the collection of all plans on a case
        const casePlans = ResourceUtil.makePooledCollection(
            caseResource, { ...options, rel: LinkRelation.projectPlans });

        // create the plan in the context of the surgical template. This will add the URI of the new
        // plan in both the pool **and** the surgical template plans collection.
        return await create<PlanRepresentation>(
            makePlanCreateDataRepresentation(
                getRequiredUri(surgicalTemplate, LinkRelation.canonical)),
            {
                ...options,
                createContext: surgicalTemplate,
                rel: LinkRelation.surgicalTemplatePlans,
                createdRel: LinkRelation.surgicalTemplatePlans,
                contentType: ContentType.Json,
                makeSparseStrategy: (o) => pooledSingletonMakeStrategy(casePlans, o),
            }) ?? null;
    }
}
