import {
    SurgicalTemplateRecordState,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import anylogger from 'anylogger';
import { ReRankingState } from '@/hipPlanner/stores/planner/ReRankingState';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { watch } from 'vue';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { VueObserver } from '@/hipPlanner/assembly/controllers/VueObserver';

const log = anylogger('HipReRanking');

/**
 *
 *
 * 1. It does state management over the re-ranking workflow.
 * @see {@class ReRankingState}
 * 2. Listen for events triggered on different parts of the application related with the re-ranking workflow.
 * 3. Emit re-ranking events according to the current state.
 *
 * Events that listen
 * ===================
 *
 * 1. Listen to event triggered when the target dialog is submitted.
 * @see { @event PlannerEvent.HipPlannerReRankingSubmitted)}
 * 2. Listen to event triggered when the user surgical template record state changes.
 * 3. Listen to event triggered when updating the surgical template.
 * 4. Listen to event when the UI was updated after a re-ranking.
 * @see {@event PlannerEvent.HipPlannerReRankingUIUpdated)}
 *
 * Events that emits:
 * ===================
 *
 * 1. @see {@event PlannerEvent.HipPlannerReRankingStarted)}
 * 2. @see {@event PlannerEvent.HipPlannerReRankingProcessing)}
 * 3. @see {@event PlannerEvent.HipPlannerReRankingCompletedAtServer)}
 * 4. @see {@event PlannerEvent.HipPlannerReRankingError)}
 */
export default class HipReRankingObserver extends VueObserver {
    constructor(
        private hipTemplateStore: HipTemplateStore,
        private hipPlannerStore: HipPlannerStore,
    ) {
        super();
        this.addWatches(
            watch(
                () => this.hipPlannerStore.rerankingState,
                (newState: ReRankingState, previousState: ReRankingState) => {
                    if (previousState === ReRankingState.UpdatingScene) {
                        this.afterUpdatingScene.bind(this)(newState);
                    }
                },
            ),
            watch(
                () => [this.hipTemplateStore.targetLegLengthChange, this.hipTemplateStore.targetOffsetChange],
                () => {
                    if (this.state === ReRankingState.Idle || this.state === ReRankingState.Error) {
                        this.setState(ReRankingState.Started);
                    } else {
                        this.setState(ReRankingState.Error);
                    }
                },
            ),
            watch(
                () => this.hipTemplateStore.userTemplateRecordState,
                (state) => this.onRecordStateChange(state),
            ),
        );
    }

    /**
     * Handler to listen changes on the surgical template record state
     */
    private onRecordStateChange(state: SurgicalTemplateRecordState): void {
        try {
            switch (state) {
                case SurgicalTemplateRecordState.Completed:
                    this.onRecordStateCompleted();
                    break;

                case SurgicalTemplateRecordState.Error:
                    this.onRecordStateError();
                    break;

                case SurgicalTemplateRecordState.New:
                case SurgicalTemplateRecordState.Processing:
                    this.onRecordStateNewOrProcessing();
                    break;

                default:
                    log.error('invalid record state %s', state);
                    this.setState(ReRankingState.Error);
            }
        } catch (e) {
            this.setState(ReRankingState.Error);
        }
    }

    private onRecordStateCompleted(): void {
        if (this.state === ReRankingState.Started || this.state === ReRankingState.Processing) {
            this.setState(ReRankingState.CompletedAtServer);
        } else {
            throw new Error(`invalid record state ${this.state}`);
        }
    }

    private onRecordStateError(): void {
        if (this.state === ReRankingState.Started || this.state === ReRankingState.Processing) {
            this.setState(ReRankingState.Error);
        } else {
            throw new Error(`invalid record state ${this.state}`);
        }
    }

    private onRecordStateNewOrProcessing(): void {
        if (this.state === ReRankingState.Started) {
            this.setState(ReRankingState.Processing);
        } else if (this.state === ReRankingState.Processing) {
            log.debug('re-ranking handler skipped, already processing');
        } else {
            throw new Error(`invalid record state ${this.state}`);
        }
    }

    private afterUpdatingScene(state: ReRankingState): void {
        if (state === ReRankingState.SceneUpdated) {
            // It means the cycle was completed, and back to the initial state
            this.setState(ReRankingState.Idle);
        } else {
            // something must have gone wrong while updating the scene
            this.setState(ReRankingState.Error);
        }
    }

    private setState(state: ReRankingState): void {
        this.hipPlannerStore.rerankingState = state;
    }

    private get state(): ReRankingState {
        return this.hipPlannerStore.rerankingState;
    }
}
