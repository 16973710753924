import ResourceService from '@/lib/api/ResourceService';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import {
    CatStackCollectionAndBBoxRepresentation,
    CatStackCollectionRepresentation,
    CatStackRepresentation,
} from '@/lib/api/representation/CatStackRepresentation';
import { getUri, LinkUtil } from 'semantic-link';
import { AxiosInstance } from 'axios';
import ImageDataUrlLoader from '@/lib/http/image-data-loader/ImageDataUrlLoader';
import LinkRelation from '@/lib/api/LinkRelation';
import { CatStackBoundingBoxRepresentation } from '@/lib/viewer/catstack/interfaces';
import ImageDataUrlLoaderUtil from '@/lib/http/image-data-loader/ImageDataUrlLoaderUtil';
import { ApiUtil } from '@/lib/semantic-network';

export default class CaseCatStackResource implements ResourceService {
    /**
     * Get the cat-stack list, the cat-stack items (json) and the cat-stack images.
     * Note: the bounding-box is not required for the DICOM viewer (just the 3js view)
     */
    public static async getCaseCatStackCollection(
        studyResource: StudyRepresentation,
        options: CacheOptions): Promise<CatStackCollectionAndBBoxRepresentation | null> {
        if (LinkUtil.matches(studyResource, LinkRelation.catStacks)) {
            return await ApiUtil.get<CatStackCollectionAndBBoxRepresentation>(
                studyResource, { rel: LinkRelation.catStacks, includeItems: false, ...options }) ?? null;
        }
        return null;
    }

    /**
     * Get the world-coordinates bounding box of the image volume of the catstack images
     */
    public static async getCaseCatStackBoundingBox(
        catStackCollectionResource: CatStackCollectionRepresentation,
        options?: CacheOptions): Promise<CatStackBoundingBoxRepresentation | null> {
        if (LinkUtil.matches(catStackCollectionResource, LinkRelation.boundingBox)) {
            return await ApiUtil.get<CatStackBoundingBoxRepresentation>(
                catStackCollectionResource, { rel: LinkRelation.boundingBox, ...options }) ?? null;
        }
        return null;
    }

    /**
     * Get the cat-stack item (json)
     */
    public static async getCaseCatStack(
        catstack: CatStackRepresentation, options?: CacheOptions): Promise<CatStackRepresentation | null> {
        return await ApiUtil.get<CatStackRepresentation>(catstack, options) ?? null;
    }

    /**
     * Get the cat-stack item (json)
     */
    public static getCaseCatStackImage(axios: AxiosInstance, catstack: CatStackRepresentation): Promise<HTMLImageElement | ImageBitmap> {
        const url = getUri(catstack, LinkRelation.self);
        if (url) {
            return ImageDataUrlLoaderUtil.loadImage(axios, url);
        }
        throw new Error('Invalid catstack item');
    }

    /**
     * Get the cat-stack image as a dataUrlImage
     */
    public static loadCaseCatStackDataUrlImage(axios: AxiosInstance, catstack: CatStackRepresentation): Promise<string | undefined> {
        catstack.dataUrlImage = new ImageDataUrlLoader(axios, catstack, LinkRelation.self);
        return catstack.dataUrlImage.load();
    }
}
