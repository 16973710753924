import { CollectionRepresentation, LinkedRepresentation } from 'semantic-link';
import { HipCupAnteversionModeType } from '@/lib/api/representation/interfaces';
import {
    AlignmentModeType,
    ComponentSelectorRepresentation,
    HipCupFitMethodType,
} from '@/lib/api/representation/SurgicalSpecificationRepresentation';

export interface ProductPreferencesRepresentation extends LinkedRepresentation {
    created: string;
    updated: string;
}

export type ProductPreferencesCollectionRepresentation = CollectionRepresentation<ProductPreferencesRepresentation>;

export interface HipCupProductPreferencesRepresentation {
    abduction_angle: number;

    /**
     * The antiversion angle mode. This can be 'manual' or 'auto'.
     */
    anteversion_mode: HipCupAnteversionModeType;

    /**
     * The anteversion angle is only applicable when the {@link anteversion_mode} is set
     * to {@link HipCupAnteversionMode.Manual.}
     */
    anteversion_angle: number;

    /** Cup Fitting Method (ACID, Fossa, Beverland) */
    fit_method: HipCupFitMethodType;
}

/**
 * The stem part of the {@link HipProductPreferencesRepresentation} settings
 */
export interface HipStemProductPreferencesRepresentation {
    /**
     * The stem selector
     */
    selector?: ComponentSelectorRepresentation[];
}

/**
 * @deprecated why have this class? The PUT uses the representation
 */
export interface HipProductPreferencesUpdateData {
    stem: HipStemProductPreferencesRepresentation;
    cup: HipCupProductPreferencesRepresentation;
    align_mode?: AlignmentModeType;
}

export interface HipProductPreferencesRepresentation extends ProductPreferencesRepresentation {
    /**
     * Stem settings.
     */
    stem: HipStemProductPreferencesRepresentation;

    /**
     * Cup settings
     */
    cup: HipCupProductPreferencesRepresentation;

    /**   Alignment Mode (None, APP, FPP) */
    align_mode?: AlignmentModeType;
}

export function isHipProductPreferencesRepresentation(representation: unknown): boolean {
    const keys = Object.keys(representation as Record<string, unknown>);
    return ['stem', 'cup', 'align_mode'].every(expected => keys.includes(expected));
}
