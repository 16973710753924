import { APP_NAME } from '@/plugins/vuegtag';
import { VueGtag } from 'vue-gtag';
import { HomeSearchAnalyticsData } from '@/views/home/HomeSearchAnalyticsData';

enum ScreenName {
    Notifications = 'notifications',
    About = 'about',
    Privacy = 'privacy',
    Terms = 'terms',
    Home = 'home',
    Plan = 'plan',
}

enum Event {
    HomeSearch = 'home-search',
    PlanDownloadAutomatedIntent = 'plan-download-automated-intent',
    PlanDownloadManualIntent = 'plan-download-manual-intent',
    PlanDownloadManualShowWarning = 'plan-download-manual-show-warning',
    PlanDownloadManualInWarningDialog = 'plan-download-manual-in-warning-dialog',

    // Inactivity events
    InactivityTimeoutWhileUsingApp = 'inactivity-timeout-while-using-app',
    InactivityTimeoutWhenReOpeningApp = 'inactivity-timeout-when-re-opening-app',
}

type KnownEventParams = HomeSearchAnalyticsData;

export default class AnalyticsUtil {
    public static readonly ScreenName = ScreenName;

    public static readonly Event = Event;

    /**
     * Proxy to gtag screenView method
     */
    public static screenView(tag: VueGtag, screenName: ScreenName): void {
        tag.screenview({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            app_name: APP_NAME,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            screen_name: screenName,
        });
    }

    /** Sends an event to google analytics */
    public static event(
        tag: VueGtag, screenName: ScreenName | string | null | undefined, event: Event, payload?: KnownEventParams): void {
        tag.event(event, { app_name: APP_NAME, screen_name: screenName, ...(payload || {}) });
    }
}
