
    import { Component, Vue } from 'vue-property-decorator';
    import anylogger from 'anylogger';

    const log = anylogger('BlankTitle');

    @Component
    export default class BlankTitle extends Vue {
        protected created(): void {
            log.debug('Case blank title created');
        }
    }
