import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import { CacheOptions, create, update } from '@/lib/semanticNetworkMigrationUtils';
import {
    CaseCollectionRepresentation,
    CaseCreateDataRepresentation,
    CaseRepresentation,
} from '@/lib/api/representation/case/CaseRepresentation';
import { LinkUtil, Uri } from 'semantic-link';
import ResourceUtil from '@/lib/api/ResourceUtil';
import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
import ResourceService from '@/lib/api/ResourceService';
import LinkRelation from '@/lib/api/LinkRelation';

import anylogger from 'anylogger';
import { CaseSearchRepresentation } from '@/lib/api/representation/CaseSearchRepresentation';
import ContentType from '@/lib/http/mimetype';
import { ApiUtil, pooledCollectionMakeStrategy, pooledSingletonMakeStrategy } from '@/lib/semantic-network';
import { verboseRegExp } from '@/lib/base/VerboseRegExp';
import { productVersion } from '@/lib/version';

const log = anylogger('CaseResource');

export default class CaseResource implements ResourceService {
    /**
     * Get the case (the case must be sparsely or fully populated).
     */
    public static async getCase(
        caseResource: CaseRepresentation,
        options?: CacheOptions): Promise<CaseRepresentation | null> {
        return await ApiUtil.get<CaseRepresentation>(caseResource, options) ?? null;
    }

    /**
     * Get a specific case (project) and nothing else.
     *
     * The whole purpose of this method is to ensure a single case (project) is loaded.
     * Note: Do not extend it (write a new method).
     */
    public static async getCaseByUri<T extends CaseRepresentation>(
        apiResource: ApiRepresentation,
        caseUri: Uri,
        options?: CacheOptions): Promise<T | null> {
        const allCases = ResourceUtil.makePooledCollection(apiResource, { rel: LinkRelation.projects });
        const project = pooledSingletonMakeStrategy(allCases, { ...options, uri: caseUri });
        return await ApiUtil.get<T>(project, options) ?? null;
    }

    /**
     * Get the sparsely populated list of projects (from the root of the API). At this stage the
     * list of projects is directly queried from the full list of cases (projects) without any
     * form of filtering or sorting, or pagination - this is likely to change.
     */
    public static async getCaseList(
        apiResource: ApiRepresentation,
        options?: CacheOptions): Promise<CaseCollectionRepresentation | null> {
        return await ApiUtil.get<CaseCollectionRepresentation>(
            apiResource, { includeItems: false, ...options, rel: LinkRelation.projects }) ?? null;
    }

    /**
     * Create a new hip case at the server and add it to the list of cases (sparsely).
     *
     * Note: This method is a little bit off centre in terms of using a pooled collection. The list of cases
     * for a user **should** use the 'me' user as the context to perform the *search*. Because this was one
     * of the first collections it has been implemented in a trivial way - perhaps now is the time to revisit
     * this API usage and implementation.
     */
    public static async createCase(
        apiResource: ApiRepresentation,
        createData: CaseCreateDataRepresentation,
        options?: CacheOptions): Promise<CaseRepresentation | undefined> {
        const caseList = ResourceUtil.makePooledCollection(apiResource, { ...options, rel: LinkRelation.projects });
        return await create<CaseRepresentation>(
            createData, {
                ...options,
                createContext: apiResource,
                rel: LinkRelation.projects,
                contentType: ContentType.Json,
                makeSparseStrategy: (o) => pooledSingletonMakeStrategy(caseList, o),
            });
    }

    /**
     * Executes a search (POST) to the project/cases collection and returns the Uri with the location of search.
     *
     * This uses the create method, but does not set the resulting search result in the data.
     *
     * Note: The search should hydrate the project from the pooled collection of all
     */
    public static async search(
        apiResource: ApiRepresentation,
        searchData: CaseSearchRepresentation,
        options?: CacheOptions): Promise<CaseCollectionRepresentation | null> {
        const allCases = await ApiUtil.get<CaseCollectionRepresentation>(
            apiResource, { ...options, rel: LinkRelation.projects, includeItems: false });
        if (allCases) {
            const _searches = ResourceUtil.makeSearchCollection(apiResource, 'projectSearches', options);
            return await create<CaseCollectionRepresentation>(
                searchData,
                {
                    includeItems: false,
                    ...options,
                    rel: LinkRelation.projects,
                    createContext: apiResource,
                    contentType: ContentType.Json,
                    createdVirtualCollectionName: 'caseSearches',
                    sparseType: 'collection' /* the 'search' result is a collection */,
                    makeSparseStrategy: (o) => pooledCollectionMakeStrategy(allCases, o),
                }) ?? null;
        }
        return null;
    }

    public static async updateCase(
        caseResource: CaseRepresentation,
        updatedCaseResource: CaseRepresentation,
        options?: CacheOptions): Promise<CaseRepresentation> {
        return await update(caseResource, updatedCaseResource, options);
    }

    /**
     * Put a new surgeon into play. This requires the following changes (if successful):
     *   - the 'surgeon' link
     *   - the surgeon representation
     */
    public static async updateCaseSurgeon(
        api: ApiRepresentation,
        caseResource: CaseRepresentation,
        surgeon: UserRepresentation,
        options?: CacheOptions): Promise<CaseRepresentation> {
        // the whole surgeon is provided, but just the link is required for the PUT
        const surgeonUri = LinkUtil.getUri(surgeon, LinkRelation.canonicalOrSelf);
        if (surgeonUri) {
            const clone = ResourceUtil.duplicateResourceWithoutMetadata(caseResource, options);
            if (clone) {
                ResourceUtil.setLink(
                    clone,
                    LinkRelation.surgeon,
                    {
                        rel: LinkRelation.surgeon,
                        href: surgeonUri,
                    });

                await update(caseResource, clone, options);
            }

            // TODO: stop this. Two no's:
            // TODO: 1. the surgeon user should be fetched from the global collection of users
            ResourceUtil.setResource(caseResource, LinkRelation.surgeon, surgeon, options);
            return caseResource;
        }
        return Promise.reject(new Error('Invalid surgeon'));
    }

    /**
     * Put a new study into play.
     *
     * The active study on a case (project) is a singleton and it must refer to a study that is
     * present in the collection of all studies on a case (project).
     */
    public static async updateCaseStudy(
        caseResource: CaseRepresentation,
        studyUri: Uri,
        options?: CacheOptions): Promise<CaseRepresentation | null> {
        // a non-caching reload of the data to confirm the latest data
        await ResourceUtil.refresh(caseResource, options);

        const updateData = ResourceUtil.duplicateResourceWithoutMetadata(caseResource, options);
        if (updateData) {
            updateData.web_component_version = productVersion();

            ResourceUtil.setLink(
                updateData,
                LinkRelation.study,
                {
                    rel: LinkRelation.study,
                    href: studyUri,
                    title: LinkRelation.activeStudy.title as string,
                });

            return await update(caseResource, updateData, options);
        }
        return null;
    }
}

export interface CaseIdentifierRange {
    start: number;
    end: number;
}

export type CaseIdentifier = number | CaseIdentifierRange;

/**
 * A regular expression to parse the search input.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 */
const searchRegex = verboseRegExp`^
            (
                \s*  // ignore leading whitespace
                
                // an optional id prefix
                (?:id:) \s*  
                (
                    // either a simple number (no sign)
                    (?<id>\d+) |
                    
                    // A range with two hyphen separated numbers
                    ((?<idStart>\d+) \s* - \s* (?<idEnd>\d+))
                )*
                    
                \s*  // ignore trailing whitespace
            )*
        $`;

export class ArrayUtil {
    /**
     * Correlate two arrays in a single array with pairs of items.
     */
    public static zip<TA, TB>(a?: TA[], b?: TB[]): [TA, TB][] | undefined {
        if (a && b) {
            return a.map(function(item, index: number): [TA, TB] {
                return [item, b[index]];
            });
        }
        return undefined;
    }
}

/**
 *
 */
export class SearchParserUtil {
    public static parse(text: string): CaseIdentifier[] | undefined {
        const tokens = text.matchAll(RegExp(searchRegex, 'g'));

        if (tokens) {
            log.info('%o', tokens);
            // const ids = tokens.groups?.id;
            // const idStart = tokens.groups?.idStart;
            // const idEnd = tokens.groups?.idEnd;
            // const ranges = ArrayUtil.zip(idStart, idEnd);
            return [];
        }
        return undefined;
    }
}
