import { WatchStopHandle } from 'vue';

export type EventHandler = [string, (...args: any[]) => void]

/**
 * Note: This is the preferred way to add handlers when using a Vue instance not inside a component
 * so they do not leak and they are unsubscribed.
 *
 *  Suggested use:
 *
 *  const unsubscribe = addHandlers(bus, [
 *      ['a', fnA],
 *      ['a', fnB],
 *  ]);
 *
 *  unsubscribe();
 */
export function addHandlers(vue: Vue, handlers: EventHandler[]): WatchStopHandle {
    handlers.forEach((eventHandler: EventHandler) => {
        vue.$on(eventHandler[0], eventHandler[1]);
    });

    return () => {
        handlers.forEach((eventHandler: EventHandler) => {
            vue.$off(eventHandler[0], eventHandler[1]);
        });
    };
}
