import { DateTime } from 'luxon';
import { parseHeadMetaTag } from '@/lib/metaTagUtil';

/**
 * The version of the Vue application which is baked into the package at build time.
 */
export function applicationVersion(): string {
    const version = process.env.VUE_APP_VERSION;
    return version || '';
}

/**
 * The overall product version which constitutes a release of the product (whole solution).
 */
export function productVersion(): string {
    return parseHeadMetaTag('acid-version');
}

/**
 * Regional product version suffix
 */
export function productVersionSuffix(): string {
    return parseHeadMetaTag('acid-version-suffix');
}

/**
 * The overall release date of the product.
 */
export function productReleaseDate(): DateTime {
    const timestamp = parseHeadMetaTag('acid-deploy-utc-time');

    const isoReleaseDate = DateTime.fromISO(timestamp);
    if (!isoReleaseDate.isValid) {
        throw new Error(`release date could not be parsed from: ${timestamp}`);
    }

    return isoReleaseDate;
}
