import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { StepStateColours } from '@/components/case/state-stepper/CaseStateData';
import { LinkUtil } from 'semantic-link';
import {
    navigatePathCaseDicom,
    navigatePathCaseManage,
    navigatePathCasePlan,
    navigatePathCaseSettings,
    navigatePathPlanner,
} from '@/router/navigate';
import LinkRelation from '@/lib/api/LinkRelation';
import anylogger from 'anylogger';
import { CaseStateStepperData } from '@/components/case/state-stepper/CaseStateStepperData';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import {
    SurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import { StudyUtil } from '@/lib/api/resource/case/study/StudyUtil';
import { canUserDoManualTemplating } from '@/lib/case/utils';

const log = anylogger('CaseStateStepper');

export default class CaseStateStepperController {
    constructor(private data: CaseStateStepperData) {
    }

    private get case(): CaseRepresentation | undefined | null {
        if (this.data) {
            return this.data.caseRepresentation;
        }
    }

    private get study(): StudyRepresentation | undefined | null {
        return this.data.caseRepresentation?.activeStudy || null;
    }

    private get acidSurgicalTemplate(): SurgicalTemplateRepresentation | undefined | null {
        return this.data.caseRepresentation?.acidSurgicalTemplate || null;
    }

    private get name(): string | undefined | null {
        if (this.data?.caseRepresentation) {
            return this.data.caseRepresentation.name;
        }
    }

    /**
     * Check this case has all required information filled out
     * TODO
     */
    public get hasAllRequiredInfo(): boolean {
        return false;
    }

    /**
     * Check this case has catstack images.
     *
     * The most basic test is that there is a study that has been
     * processed up to, or pass the 'catstack' phase of processing. This is about 10 seconds after
     * the study has been made active.
     */
    public get hasCatstacks(): boolean {
        const study = this.study;
        return !!study && (
            StudyUtil.isCompleted(study) ||
            StudyUtil.isInSegmentedProcessing(study) ||
            StudyUtil.isInSegmentedState(study) ||
            StudyUtil.isInPostProcessing(study) ||
            StudyUtil.isInCatstackState(study));
    }

    public get canUserDoManualTemplating(): boolean {
        return canUserDoManualTemplating(this.study, this.acidSurgicalTemplate);
    }

    /**
     * Check if this case has any plans.
     */
    public get hasPlans(): boolean {
        function hasAcidPlan(project?: CaseRepresentation | null): boolean {
            return !!project &&
                LinkUtil.matches(project, LinkRelation.acidSurgicalTemplate) &&
                !!project.acidSurgicalTemplate &&
                LinkUtil.matches(project.acidSurgicalTemplate, LinkRelation.currentSurgicalTemplatePlan);
        }

        function hasUserPlan(project?: CaseRepresentation | null): boolean {
            return !!project &&
                LinkUtil.matches(project, LinkRelation.currentSurgicalTemplate) &&
                !!project.surgicalTemplate &&
                LinkUtil.matches(project.surgicalTemplate, LinkRelation.currentSurgicalTemplatePlan);
        }

        return hasAcidPlan(this.case) || hasUserPlan(this.case);
    }

    /**
     * Link to the admin case management view
     */
    public get caseManagerLink(): string {
        return this.case ? navigatePathCaseManage(this.case) : '';
    }

    /**
     * Case details page state
     * - is active if the case exists (always)
     * - is complete is all require data is provided (TODO some way to check)
     */
    public get caseDetailsStepState(): StepStateColours {
        if (this.case) {
            if (this.hasAllRequiredInfo) {
                return StepStateColours.Complete;
            } else {
                return StepStateColours.Active;
            }
        } else {
            return StepStateColours.Inactive;
        }
    }

    /** Make the URL to the case settings page. */
    public get caseDetailsStepLink(): string {
        if (this.case && LinkUtil.matches(this.case, ['self', 'canonical'])) {
            return navigatePathCaseSettings(this.case);
        }
        log.info('Case stepper has a case with no URI - settings page not available');
        return '';
    }

    /**
     * DICOM preview page state
     * - is active and complete is catstack exists
     */
    public get dicomViewerStepState(): StepStateColours {
        return this.hasCatstacks ? StepStateColours.Active : StepStateColours.Inactive;
    }

    /** Make the URL to the dicom viewer page. */
    public get dicomViewerStepLink(): string {
        if (this.case) {
            return this.hasCatstacks ? navigatePathCaseDicom(this.case) : '';
        } else {
            return '';
        }
    }

    /**
     * 3d planning page state
     *   - is active if selected cup exists
     *   - is complete if case is approved
     */
    public get templateStepState(): StepStateColours {
        if (this.canUserDoManualTemplating) {
            // TODO: work out which plans need to be complete to get this state, if one or more
            // TODO: of the plans are 'completed' (100% success) we can show an overlay icon
            return StepStateColours.Active;
        } else {
            return StepStateColours.Inactive;
        }
    }

    /** Make the URL to the planning page. */
    public get templateStepLink(): string {
        if (this.case && this.canUserDoManualTemplating) {
            return navigatePathPlanner(this.case);
        } // else can't navigate to the 3d plan
        return '';
    }

    public get planStepState(): StepStateColours {
        if (this.canUserSeePlanPage) {
            return StepStateColours.Active;
        }
        return StepStateColours.Inactive;
    }

    /**
     * Note
     *
     * 1. The acid surgical template can end up without a plan if there a clinical warnings.
     * That's is why the plan page is accessible too if the user surgical template has a plan but the acid surgical
     * template don't.
     *
     * 2. It is also important to validate that the user surgical template belongs to the current acid
     * surgical template.
     * This is not being done today, but we can get away saying that the current acid surgical template needs
     * to be completed to access the plan page.
     *
     * Rational: The client does not have the ability to start a new user surgical template. It only can duplicate and
     * start a new 'acid' surgical template. If the client ends up having an acid surgical template in processing
     * and a user surgical template with a plan uri, it means the user surgical template belongs to a previous acid
     * surgical template.
     *
     * TODO:
     * TODO: Need to have a link on the surgical template to the parent acid surgical template 'noncanonical'
     */
    private get canUserSeePlanPage(): boolean {
        return this.canUserDoManualTemplating && this.hasPlans;
    }

    /** Make the URL to the plan page once it loaded */
    public get planStepLink(): string {
        if (this.case && this.canUserSeePlanPage) {
            return navigatePathCasePlan(this.case);
        }
        return '';
    }
}
