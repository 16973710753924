
    import { Component, Vue } from 'vue-property-decorator';
    import AppBarButton from '@/components/app-bar/AppBarMenuButton.vue';

    /**
     * A simple menu in the top right corner of the application to allow the user to
     * navigate to support links.
     *
     * see
     *   - {@link https://vuetifyjs.com/en/examples/layouts/google-contacts}
     *   - {@link https://stackoverflow.com/questions/47586022/router-link-with-vue-and-vuetify-confusion}
     */
    @Component({
        components: { AppBarButton },
    })
    export default class AppBarMenuContent extends Vue {
    }
