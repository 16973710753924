// noinspection ES6UnusedImports
import {
    Color,
    DoubleSide,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Plane,
    ShaderMaterial,
    Side,
} from 'three';
import xrayVertexShader from '@/lib/viewer/shaders/xray.vertex.shader.glsl';
import xrayFragmentShader from '@/lib/viewer/shaders/xray.fragment.shader.glsl';

/**
 * A basic subset of the three.js {@link MaterialParameters material parameters}
 */
export interface BasicMaterialParameters {
    readonly color?: Color;
    readonly clippingPlanes?: Plane[];
}

/**
 * An extended subset of the three.js {@link MaterialParameters material parameters}
 */
export type ExtendedMaterialParameters = BasicMaterialParameters & {
    readonly side?: Side,
    readonly opacity?: number;
    readonly transparent?: boolean;
    readonly vertexColors?: boolean;
};

/**
 * Default for the diffuse color (main surface color) of the standard
 * {@link https://en.wikipedia.org/wiki/Phong_shading phong shader} applied to 3D objects.
 */
const DEFAULT_COLOR = new Color('#ffffff');

/**
 * Default color for the {@link https://en.wikipedia.org/wiki/Specular_highlight specular highlight}
 * (shiny bit) applied to the standard {@link https://en.wikipedia.org/wiki/Phong_shading phong shader}.
 * Changing this affects the color and intensity of the specular highlight but not its size.
 */
const DEFAULT_SPECULAR = new Color('#eeeeee');

/**
 * Default shininess for the {@link https://en.wikipedia.org/wiki/Specular_highlight specular highlight}
 * applied to the standard {@link https://en.wikipedia.org/wiki/Phong_shading phong shader}.
 * Changing this affects the size of the specular highlight. It can be any non-negative number and
 * arbitrarily large, but some meaningful values are:
 * * shininess 0 is very matte or flat-looking, with large and less intense specular highlights
 * * shininess 100 is very shiny, with small and bright specular highlights
 */
const DEFAULT_SHININESS = 75.0;

/**
 * By default materials apply to both front- and back-sides of an object
 */
const DEFAULT_SIDE = DoubleSide;

/**
 * Default opacity is to be full opaque i.e. not transparent at all
 */
const DEFAULT_OPACITY = 1.0;

/**
 * Factory for the various types of three.js {@link Material} we use
 */
export default class ThreeMaterialFactory {
    /**
     * Create a three.js {@link MeshBasicMaterial}; this kind of material appears as a flat color,
     * without any shadowing or highlights caused by lights in the scene.
     */
    public static makeSolidMaterial(params: ExtendedMaterialParameters, name?: string): MeshBasicMaterial {
        const transparent = params.transparent ?? false;
        return new MeshBasicMaterial({
            name: name ?? 'solid',
            color: params.color ?? DEFAULT_COLOR,
            side: params.side ?? DEFAULT_SIDE,
            clippingPlanes: params.clippingPlanes ?? [],
            opacity: params.opacity ?? DEFAULT_OPACITY,
            transparent,
            vertexColors: params.vertexColors ?? false,
        });
    }

    /**
     * Create a three.js {@link MeshPhongMaterial}; this kind of material is lit by lights in
     * the scene and can appear like a matte surface or as a shiny/polished surface depending
     * on the parameters it is created with.
     */
    public static makePhongMaterial(params: ExtendedMaterialParameters, name?: string): MeshPhongMaterial {
        return new MeshPhongMaterial({
            name: name ?? 'phong',
            color: params.color ?? DEFAULT_COLOR,
            side: params.side ?? DEFAULT_SIDE,
            clippingPlanes: params.clippingPlanes ?? [],
            opacity: params.opacity ?? DEFAULT_OPACITY,
            specular: DEFAULT_SPECULAR,
            shininess: DEFAULT_SHININESS,
            transparent: params.transparent ?? false,
            vertexColors: params.vertexColors ?? false,
        });
    }

    /**
     * Create a three.js {@link ShaderMaterial} that is partially transparent. The transparency
     * of the material depends on the angle the surface is viewed, with a surface being transparent
     * when viewed directly and gaining opacity as the surface becomes tangent to the view direction.
     *
     * The result is some kind of visual approximation of the
     * {@link https://en.wikipedia.org/wiki/Fresnel_equations fresnel effect} on a transparent
     * surface. It is 'x-ray' in the very colloquial sense that objects are transparent, but is
     * actually not as much like an x-ray as it is like images from anj electron microscope.
     */
    public static makeXRayMaterial(params: BasicMaterialParameters, name?: string): ShaderMaterial {
        const color = params.color ?? DEFAULT_COLOR;
        return new ShaderMaterial({
            name: name ?? 'xray',
            uniforms: {
                diffuse: { value: color },
                uColor: { value: color },
            },
            transparent: true,
            depthWrite: false,
            vertexShader: xrayVertexShader,
            fragmentShader: xrayFragmentShader,
            side: DoubleSide,
            clipping: true,
            clippingPlanes: params.clippingPlanes ?? [],
            clipShadows: true,
        });
    }
}
