
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import AppCard from './AppCard.vue';

    @Component({components: {Modal, AppCard}})
    export default class Modal extends Vue {
        @Prop(String) readonly modalTitle: string | undefined;
        @Prop(String) readonly maxWidthInPct: string | undefined;

        get cssProps() {
            let mw;
            if (this.maxWidthInPct) {
                mw = this.maxWidthInPct + '%';
            } else {
                mw = '580px';
            }
            return {
                '--maxWidth': mw,
            };
        }

        protected mounted(): void {

            // Attempted nicer handling of internal scrolling by making only .modal-content-control scrollable
            // Commented for now as solution not optimal
            // Instead we are using scrolling on the entire modal (where needed) and look to a nicer frontend solution later

            /*
            // handling internal scrolling (if required) within the modal
            // first, grab all elements
            var holderElem = this.$refs.holderElem.$el,
                titleElem = this.$refs.titleElem,
                actionsElem = this.$refs.actionsElem,
                contentElem = this.$refs.contentElem;

            // Timeout used here as Storybook seems to 'animate' it's canvas into place, which means things aren't in their actual
            // rendered state immediately. This is gross.
            setTimeout(function() {

              // work out what the max height of the content area should be
              var contentMaxHeight = holderElem.clientHeight - titleElem.clientHeight - actionsElem.clientHeight - parseInt( getComputedStyle(holderElem).paddingTop ) * 3;

              // and set max-height directly on the elem
              contentElem.style.cssText = 'max-height: ' + contentMaxHeight + 'px;';

              // now check if scrolling is in fact present, and add a class if so
              if( contentElem.scrollHeight > contentElem.clientHeight ) {
                contentElem.classList.add('is-scrolling');
              }

            }, 1000);
            */

        }
    }
