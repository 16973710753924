import { Route } from 'vue-router';
import { RouteConfig } from 'vue-router/types/router';

import { makePath, resolve } from './resolver';
import Name from './Name';
import ViewPath from './viewPath';

import About from '@/views/info/About.vue';
import DicomImage from '@/views/case/DicomImage.vue';
import HomeContainer from '@/views/home/HomeContainer.vue';
import Manage from '@/views/case/Manage.vue';
import PlanContainerLoader from '@/views/case/PlanContainerLoader.vue';
import Privacy from '@/views/info/Privacy.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import Settings from '@/views/case/Settings.vue';
import Terms from '@/views/info/Terms.vue';
import ForgotPassword from '@/views/auth/ForgotPassword.vue';
import Preferences from '@/views/profile/Preferences.vue';

const addRequireAuth = (config: RouteConfig): RouteConfig => {
    return {
        ...config,
        meta: {
            ...config.meta,
            requireAuth: true,
        },
    };
};

const routeConfig: RouteConfig[] = [
    {
        path: ViewPath.ForgotPassword,
        name: Name.ForgotPassword,
        component: ForgotPassword,
        meta: {
            requireAuth: false,
        },
    },
    {
        path: ViewPath.ResetPassword,
        name: Name.ResetPassword,
        component: ResetPassword,
        /**
         * the email link sent to the user includes the reset token and the email address.
         */
        props: (route: Route) => ({ token: route.query.t, email: route.query.e }),
        meta: {
            requireAuth: false,
        },
    },

    //
    // Views that require authentication
    //
    ...[
        {
            path: ViewPath.Home,
            name: 'home',
            component: HomeContainer,
        },
        //
        // Simple global views
        // ===================
        //
        {
            path: ViewPath.About,
            name: Name.About,
            component: About,
        },
        {
            path: ViewPath.Privacy,
            name: Name.Privacy,
            component: Privacy,
        },
        {
            path: ViewPath.Terms,
            name: Name.Terms,
            component: Terms,
        },
        //
        // Profile views
        // =============
        //
        {
            path: ViewPath.ProfileHipSettings,
            name: Name.ProfileHipSettings,
            component: Preferences,
        },

        //
        // Case views
        // ==========
        //
        {
        // User visible settings for the case
            path: makePath(ViewPath.CaseSettings),
            name: Name.CaseSettings,
            component: Settings,
            props: resolve,
        },
        {
        // Hidden admin settings/information for the project
            path: makePath(ViewPath.CaseManage),
            name: Name.CaseManage,
            component: Manage,
            props: resolve,
        },
        {
        // Cat-stack viewer
            path: makePath(ViewPath.DicomImage),
            name: Name.CaseCatStackViewer,
            component: DicomImage,
            props: resolve,
        },
        {
        // 3-d planning view for a 'hip' case
            path: makePath(ViewPath.Case3d),
            name: Name.CaseHipPlanner,
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "hip" */ '../hipPlanner/views/HipPlannerRoot.vue'),
            props: resolve,
        },
        {
        // Post-approval pre-operative plan page
            path: makePath(ViewPath.CasePlan),
            name: Name.CasePlan,
            component: PlanContainerLoader,
            props: resolve,
        },

        {
            // a single organisation view
            path: makePath(ViewPath.Organisation),
            name: Name.Organisation,
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "org" */ '../views/organisation/Organisation.vue'),
            props: (route: Route): unknown => {
                return ({ ...resolve(route), step: route.params.step });
            },
        },

        {
            path: ViewPath.AdministerCase,
            name: Name.AdministerCase,
            component: () => import(/* webpackChunkName: "admin" */ '../views/administer/AdministerCases.vue'),
            props: resolve,
        },
    ].map(addRequireAuth),
];

export default routeConfig;
