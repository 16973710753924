
    import { Prop, Vue } from 'vue-property-decorator';
    import Component from 'vue-class-component';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import { Fragment } from 'vue-fragment';
    import ProductAssertionMixin from '@/components/shared/with-product/WithProductMixin';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';

    /**
     * A component with a `<slot> for each different product (hip/shoulder/spine)
     * that is called based on the inferred type of the value property
     *
     * Can be called with any of the following resources:
     * - surgical template
     * - a project
     *
     * E.g:
     *
     * ```
     * <template>
     *       <WithCaseProduct :value="value">
     *            <template v-slot:hip>
     *                <HipSurgicalTemplateHistoryContent
     *                    :current="current"
     *                    :previous="previous"
     *                    :is-loading="isLoading" />
     *            </template>
     *      </WithCaseProduct>
     * </template>
     * ```
     */
    @Component({
        components: { Fragment },
        mixins: [ProductAssertionMixin],
    })
    export default class WithCaseProduct extends Vue {
        @Prop({ required: true, validator: notNilValidator('value') })
        value!: CaseRepresentation;
    }
