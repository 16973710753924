import anylogger from 'anylogger';
import { values } from 'ramda';
import { CrossSectionPlane } from '@/lib/planning/cross-section/CrossSectionPlane';

const log = anylogger('CrossSectionPlaneController');

export enum CrossSectionNameEnum {
    OperativeFemurNeckCut = 'operative-femur-neck-cut',
    HipStemCoronal = 'hip-stem',
    HipCupCoronal = 'hip-cup',
}

export type CrossSectionPlaneName =
    CrossSectionNameEnum.HipCupCoronal
    | CrossSectionNameEnum.HipStemCoronal
    | CrossSectionNameEnum.OperativeFemurNeckCut;

export type CrossSectionPlaneCollection = {
    [name in CrossSectionPlaneName]: CrossSectionPlane | undefined;
};

/**
 * Cross-section plane controller
 *
 * This controller is used for setting up and control of cross-section (aka. clipping) planes
 * associated with an object's material.
 *
 * Control of the cross-section is done by the visual toolbar element, which will be able to change the plane
 * position and visibility
 */
export class CrossSectionPlaneController {
    /** Collection of all available cross-section planes */
    private _planeCollection: CrossSectionPlaneCollection = {
        [CrossSectionNameEnum.HipCupCoronal]: undefined,
        [CrossSectionNameEnum.HipStemCoronal]: undefined,
        [CrossSectionNameEnum.OperativeFemurNeckCut]: undefined,
    };

    /**
     * Get the cross-section plane for a given cross-section name
     */
    public getPlane(name: CrossSectionPlaneName): CrossSectionPlane | undefined {
        return this._planeCollection[name];
    }

    public hasPlane(name: CrossSectionPlaneName): boolean {
        return this.getPlane(name) !== undefined;
    }

    /**
     * Make a cross section plane
     */
    public addPlane(planeModel: CrossSectionPlane): void {
        this._planeCollection[planeModel.name as CrossSectionPlaneName] = planeModel;
    }

    /**
     * Update the outline of all defined cross-section planes
     */
    public updateAllPlanesOutlines(): void {
        this.onEachPlane((plane: CrossSectionPlane) => {
            plane.updateOutline();
        });
    }

    /**
     * Iterator over each plane
     */
    public onEachPlane(callback: (plane: CrossSectionPlane) => void): void {
        const planes = values(this._planeCollection);
        planes.forEach((plane: CrossSectionPlane | undefined) => {
            if (plane) {
                callback(plane);
            }
        });
    }

    /**
     * Allows to execute a callback on an existing plane
     */
    public onPlane(name: CrossSectionNameEnum, callback: (plane: CrossSectionPlane) => void): void {
        const plane = this.getPlane(name);
        if (plane) {
            callback(plane);
        } else {
            log.debug('No %s plane available', name);
        }
    }
}
