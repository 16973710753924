
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { mapStores } from 'pinia';

    import AppButton from '@/components/basic/AppButton.vue';
    import AppFormControl from '@/components/basic/AppFormControl.vue';
    import AppFormActions from '@/components/basic/AppFormActions.vue';
    import Modal from '@/components/basic/Modal.vue';
    import { CaseSettingsStore, useCaseSettings } from '@/stores/caseSettings/store';
    import { useHipUser } from '@/stores/hipUser/store';

    @Component({
        computed: { ...mapStores(useCaseSettings, useHipUser) },
        components: {
            AppButton,
            AppFormActions,
            AppFormControl,
            Modal,
        },
    })
    export default class UnassignSurgeon extends Vue {
        caseSettingsStore!: CaseSettingsStore;

        dialog = false;

        @Watch('caseSettingsStore.isSubmitting')
        private onIsSubmitting(isSubmitting: boolean): void {
            if (!isSubmitting) {
                // When finished submitting request, close dialog.
                this.dialog = false;
            }
        }
    }
