
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import { SearchUtil } from '@/views/home/SearchUtil';

    /**
     * A component that highlihgt text in the <slot> provided. The content has to be a text.
     * This is a wrapper of the `vue-text-highlight` library, which allow to customize the styles.
     */
    @Component({})
    export default class HomeTextHighlight extends Vue {
        @InjectReactive()
        protected searchText!: string;

        /** * Return whether the value is of a hip product */
        public get textHighlightQueries(): string[] {
            return SearchUtil.getSearchTokens(this.searchText);
        }
    }
