import Vue from 'vue';
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { PiniaPlugin } from 'pinia';

interface AxiosPluginOptions {

    /**
     * An optional pre-created Axios instance. If this instance
     * isn't provided, then a new instance will be created.
     */
    instance: AxiosInstance;

    /**
     * An optional set of options for creating an Axios instance
     */
    options: AxiosRequestConfig;
}

/*
 * Axios plugin install function.
 *
 * This will install a global instance of the {@link AxiosInstance} as this.$http on
 * all Vue instances.
 */
export default function AxiosPlugin<T extends AxiosPluginOptions>(
    VueConstructor: typeof Vue,
    pluginOptions?: T): void {
    let axiosInstance: AxiosInstance;
    if (pluginOptions?.instance) {
        axiosInstance = pluginOptions.instance;
    } else if (pluginOptions?.options) {
        axiosInstance = Axios.create(pluginOptions.options);
    } else {
        axiosInstance = Axios.create();
    }

    // attach to the Vue, access via this.$http
    VueConstructor.prototype.$http = axiosInstance;
    // Object.defineProperties(VueConstructor.prototype, {
    //     $http: {
    //         get() {
    //             return axiosInstance;
    //         },
    //     },
    //
    // });
}

export function AxiosPiniaPlugin(globalInstance: AxiosInstance): PiniaPlugin {
    return () => ({
        get $http() {
            return globalInstance;
        },
    });
}
