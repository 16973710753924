/**
 * A simple error class that allows a single causing exception to be captured.
 *
 * TODO: This design doesn't really work. It goes against some of the simplicity of
 * TODO: a javascript error. Instead write a utility that money patches the standard
 * TODO: Error class with the required data in a standard way.
 */
export default class Error2 extends Error {
    private readonly childError: Error;

    constructor(message: string, child: Error) {
        super(message);
        this.childError = child;
    }

    get child(): Error {
        return this.childError;
    }

    get stack(): string {
        return (super.stack || '') + this.childError.stack;
    }
}
