import { Route } from 'vue-router';
import anylogger from 'anylogger';
import { isEmpty } from 'ramda';
import { includes } from 'lodash';
import { NumberUtil } from '@/lib/base/NumberUtil';

const log = anylogger('RouteParserUtils');

/**
 * List of query parameters available on the 3D planning.
 * This started as proof of concept for visualizing the resected femur in the hip 3d app, and then to visualize the
 * 3d app in a debug mode. The idea behind is that the query parameters do not get out of control too quickly
 * before we have a plan more clear requirements how to enable / disable features
 */
export enum PlanningQueryParamsAvailable {
    AddModels = '_addModels',
    DebugMode = '_debugMode',
}

export type QueryParamsAvailableListType =
    PlanningQueryParamsAvailable.AddModels |
    PlanningQueryParamsAvailable.DebugMode;

export enum DashboardQueryParamsAvailable {
    LogoutCounter = 'l'
}

type QueryParamsAvailableNumberType = DashboardQueryParamsAvailable.LogoutCounter;

export enum QueryParamsValues {
    ResectedFemur = 'resectedFemur',
}

export type QueryParamsValueType = string;

/**
 * Whitelist of available values for QueryParamsAvailable.AddModels
 */
export const AVAILABLE_QUERY_PARAMS_VALUES = {
    [PlanningQueryParamsAvailable.AddModels]: [QueryParamsValues.ResectedFemur],
    [PlanningQueryParamsAvailable.DebugMode]: ['true', 'false'],
};

/**
 * Utility to parse Vue Route
 */
export class RouteParserUtils {
    /**
     * Returns an array of string with the values specified for the a query parameter
     *
     * e.g.:
     * 1. Given uri: someUri?_addModels=resectedFemur,otherBone
     *
     *   parseQueryParameterList(route, '_addModels') => ["resectedFemur","otherBone"];
     *
     * 2. Given uri: someUri?_addModels=
     *
     *   parseQueryParameterList(route, '_addModels') => [];
     * @param: queryParam
     */
    public static parseQueryParameterList(route: Route, queryParam: QueryParamsAvailableListType): string[] {
        const query = route.query;
        log.debug('query parameters: %o', query);
        const value = query[queryParam];
        if (value) {
            const valuesAsArray = value.toString().split(',');
            log.debug('query parameters list of models to add : %o', valuesAsArray);
            return valuesAsArray;
        }

        return [];
    }

    /**
     * Parse an numeric query parameter value if it can be converted to int, otherwise returns null
     * Note: only the first numeric value is returned
     *
     * e.g.:
     * 1. parseQueryParameterValueNumber(route from 'http://localhost:8080/#/?l=1', 'l')
     *     => 1
     *
     * 2. parseQueryParameterValueNumber(route from 'http://localhost:8080/#/?l=1,2, 'l')
     *     => 1 (Only first value is returned)
     *
     * 3) parseQueryParameterValueNumber(route from'http://localhost:8080/#/?l=asdfasdf, 'l')
     *     => null
     *
     * 4) parseQueryParameterValueNumber(route from'http://localhost:8080/#/, 'l')
     *     => null
     */
    public static parseQueryParameterValueNumber(
        route: Route, queryParam: QueryParamsAvailableNumberType): number | null {
        const query = route.query;
        log.debug('query parameters: %o', query);
        const value = query[queryParam];
        const valueElement = value?.[0];
        if (valueElement) {
            const valueAsInt = NumberUtil.toInt(valueElement);
            if (NumberUtil.isFiniteNumber(valueAsInt)) {
                log.debug('query parameters number value parsed: %d', valueAsInt);
                return valueAsInt;
            }

            log.warn('query parameters number value parsed is not a valid number: %d', valueAsInt);
        }

        return null;
    }

    /**
     * Returns true if a vue Route has a query param value
     *
     * e.g. 1: someUri?queryParam=value => true
     * e.g. 1: someUri?queryParam=value,otherValue => true
     * e.g. 1: someUri?queryParam=valueXXXDX => false
     * e.g. 1: someUri?queryParam=otherValue => false
     * e.g. 1: someUri?queryParam= => false
     *
     * @param route
     * @param queryParam
     * @param queryParamValue
     */
    public static hasQueryParamValue(
        route: Route, queryParam: QueryParamsAvailableListType, queryParamValue: QueryParamsValueType,
    ): boolean {
        const valuesForQueryParam = RouteParserUtils.parseQueryParameterList(route, queryParam);

        if (!isEmpty(valuesForQueryParam)) {
            RouteParserUtils.warnIfInvalidValues(valuesForQueryParam, queryParam);

            const included = includes(valuesForQueryParam, queryParamValue);
            if (included) {
                return included;
            }

            log.warn(
                '%s not found in %s query param. Current query params: %o',
                queryParamValue, queryParam, valuesForQueryParam);
        }

        return false;
    }

    /**
     * Logs a warning if some parameter value is not valid
     */
    private static warnIfInvalidValues(valuesForQueryParam: string[], queryParam: QueryParamsAvailableListType): void {
        valuesForQueryParam.forEach((value: string) => {
            if (!isEmpty(value) && !includes(AVAILABLE_QUERY_PARAMS_VALUES[queryParam], value)) {
                log.warn(
                    '%s not a valid query param value. Available values for %s are: %o',
                    value, queryParam, AVAILABLE_QUERY_PARAMS_VALUES[queryParam]);
            }
        });
    }
}
