import { DicomInfo } from '@/lib/dicom/DicomInfo';

/**
 * A level associated with messages in {@link DicomSeries} and {@link DicomInfo}.
 */
export enum DicomMessageLevel {
    Error = 200,
    Warning = 300,
    Info = 400,
    Diagnostic = 500,
}

export interface DicomMessage {
    /**
     * A unique message id (this allows the message to be added to the VueJS template
     * without generating errors (when there are duplicate messages).
     */
    id: number;

    /**
     * The enum value for the message level
     */
    level: DicomMessageLevel;

    /**
     * A formatted message
     */
    message: string;
}

export interface DicomSeries {
    /**
     * The list of DICOM files that have been identified as being in the series.
     *
     * DICOM series will shared the same SeriesInstanceUID. There may also be a single
     * dicom series with files that are not DICOM files or DICOM files that have no
     * SeriesInstanceUID.
     */
    items: DicomInfo[];

    /**
     * A simple boolean flag to indicate whether the series is eligible for being
     * the series that is used for segmentation by ACID.
     *
     * Note: in the future this could be a weighted flag. For now a series is considered
     * eligible (isExcluded == false) until it is excluded through checks that identify
     * reasons for it not to be considered a 'good' series.
     */
    isExcluded: boolean;

    /**
     * A list of error, warning, info messages about checks performed on the series.
     */
    messages: DicomMessage[];
}

/**
 * The dictionary data structure of a group of DICOM files that form a study (or
 * are part of a study).
 *
 * @see {@link DicomSeries}
 */
export type DicomGroups = { [id: string]: DicomSeries };

type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

const mapping: EnumDictionary<DicomMessageLevel, string> = {
    [DicomMessageLevel.Error]: 'error',
    [DicomMessageLevel.Warning]: 'warn',
    [DicomMessageLevel.Info]: 'info',
    [DicomMessageLevel.Diagnostic]: 'debug',
};

export class DicomMessageLevelUtil {
    public static toString(v: DicomMessageLevel): string {
        return v in mapping ? mapping[v] : 'error';
    }
}
