export default class TimeoutUtil {
    /**
     * A timeout that stops if the signal is aborted.
     *
     * Note: It does not throw the aborted signal error, it is responsibility of the caller to do:
     * `signal?.throwIfAborted()`
     */
    public static setTimeout(timeout: number, signal?: AbortSignal): Promise<void> {
        return new Promise<void>((resolve, _reject) => {
            let handle = window.setTimeout(() => {
                handle = 0;
                resolve();
            }, timeout);
            signal?.addEventListener('abort', () => {
                if (handle !== 0) {
                    window.clearTimeout(handle);
                    resolve();
                }
            });
        });
    }

    public static sleep(timeout: number): Promise<void> {
        return new Promise<void>((resolve) => {
            window.setTimeout(() => {
                resolve();
            }, timeout);
        });
    }
}
