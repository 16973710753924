import { Color, Intersection, Mesh, Vector3 } from 'three';
import { uniqBy } from 'ramda';
import { Face3Positions } from '@/lib/viewer/component-coverage/BufferGeometryUtil';

/**
 * Collision material index that corresponds to the material that will
 * be used to visualise the collision
 */
export enum FaceColorOpacity {
    Hidden = 0,
    Transparent = 0.7,
    Solid = 1,
}

/** Collision distance color and color alpha (opacity) */
export interface ColorWithOpacity {
    rgb: Color;
    opacity: FaceColorOpacity;
}

/** Options for creating a collision distance colors */
export interface CollisionFaceColorOptions {
    /** Within certain number of millimeters (default is 2mm) of the surface of the bone */
    cortical?: Color;
    /** Deeper than certain number of millimeters (default is 2mm) from the surface of the bone */
    cancellous?: Color;
    /** Outside of bone (no collision) */
    outside?: Color;
}

/** Collision distance colors representation */
export interface CollisionFaceColors {
    default: Color;
    cortical: Color;
    cancellous: Color;
    outside: Color;
}

/**
 * The result of evaluating a face intersection with a mesh.
 */
export class FaceAreaMetric {
    constructor(
        /** the area of the face */
        public area: number,
        /**
         * The face positions
         * Note: They can be used to construct a geometry after.
         */
        public positions: Face3Positions,
        /** the point intersection evaluation from the 'face centroid' */
        public intersection: PointIntersection) {
    }
}

/**
 * An interface for defining the result of a coverage calculation.
 */
export interface CollisionCoverageResults {
    /** value from 0-1, with 1 being 100% coverage */
    coverage: number;
    /** computed collision area of the component, in mm squared */
    area: number;
    /** total area of the component */
    totalArea: number;
    isCalculating: boolean;
    // ===============================================
    //
    // Attributes returned for debugging purposes
    //
    // ===============================================
    /**
     * The mesh used for to test the intersection of the faces from the full covering-mesh.
     */
    intersectionMesh: Mesh;
    /**
     * An array with the result of evaluating each face ray casting against the intersection mesh
     */
    faceAreaMetrics: FaceAreaMetric[],
}

/**
 * A class that holds a reference to the result of ray-casting a point,
 * through a direction against a mesh.
 */
export class PointIntersection {
    constructor(public value: IntersectionResult) {
    }

    /**
     * Weather the point is inside the mesh that or not.
     *
     * Algorithm used: [crossing number algorithm]{@link https://en.wikipedia.org/wiki/Point_in_polygon}
     * (a.k.a even-odd rule algorithm)
     * */
    public get inside(): boolean {
        const intersections = this.uniqueIntersections;
        return intersections.length % 2 === 1;
    }

    /**
     * @return the distance of the closest intersection.
     * This is independent of the point is inside/outside a mesh {@link PointIntersection.inside}
     */
    public get distance(): number | null {
        return this.closestIntersection?.distance || null;
    }

    private get closestIntersection(): Intersection | null {
        return this.uniqueIntersections[0] || null;
    }

    /**
     * @returns the intersection without duplicates.
     *
     * Background:
     *
     * 1. When calling `ray.intersectObject(mesh)`, we get back the intersections sorted by distance.
     * 1. For the purpose of analyzing if a point is inside a mesh or not, we are counting the
     * number of intersections returned, so we can apply the [crossing number algorithm]{@link https://en.wikipedia.org/wiki/Point_in_polygon}
     * 1. It has been observed that `ray.intersectObject(mesh)` can return multiple intersections
     * **with the same distance**.
     * This was discovered by accident during integration testing, and even the chances of
     * happening on the user facing app are low, a workaround was implemented.
     *
     * Scenario:
     * ---------
     * ```
     * 1. Consider a cube, where one of its sides has 8 faces and the 8 faces share the same vertex 'X'.
     * 2. When ray-casting exactly though the vertex X, `ray.intersectObject(mesh)` will return an intersection
     * results per face, meaning 8 intersections with the same distance.
     *
     *                           +---------------+
     *                           | `     |    ,  |
     *                           |    `  | ,     |
     *                           |-------X-------|
     *                           |    ,  | `     |
     *                           | ,     |   `   |
     *                           +---------------+
     *
     * Note: The diagram only show one way of generating the faces. This will depend on how three-js does it.
     * ```
     */
    public get uniqueIntersections(): Intersection[] {
        return uniqBy((intersection: Intersection) => intersection.distance, this.value.intersections);
    }
}

/**
 * A data class to keep the context of the origin/direction and the intersection results
 */
export class IntersectionResult {
    constructor(
        /** the origin point used in the face */
        public origin: Vector3,
        /** the direction of the normal */
        public direction: Vector3,
        /** the intersections result */
        public intersections: Intersection[]) {
    }
}

export interface MeshIntersectionConfig {
    mesh: Mesh;
    rayDirection: Vector3;
    origin: Vector3;
}

export class MeshIntersectionResult {
    public constructor(public config: MeshIntersectionConfig, public value: PointIntersection) {
    }
}

export interface CollisionPenetrationResult {
    intersectionResults: MeshIntersectionResult[],
    isCalculating: boolean,
}

export type RayDirectionCallback = () => Vector3;
/** Collision distance color and materialIndex */
export type FaceCollisionColorCallback = ((faceAreaMetric: FaceAreaMetric) => (ColorWithOpacity | null));

/** A Face of three positional vector points  */
export interface Face3Vertices {
    v1: Vector3;
    v2: Vector3;
    v3: Vector3;
}

/**
 * An interface that defines a per-component strategy for the ray-intersection based component coverage algorithm.
 */
export interface ComponentCoverageStrategy {
    /**
     * Make a face color rule/function according to implant component type and available distance criteria
     * TODO: Face area colouring: This seems to be second concern of the coverage algorithm.
     *       The coloring of each face is being done at the same time the face area is calculated.
     *       Investigate doing this separately to have separation of concerns and evaluate
     *       the potential drop in performance (given another iteration through the faces will be needed).
     *
     * Note: This is optional given it is not needed for tests, and seems this needs to go away from here.
     */
    getFaceColor: FaceCollisionColorCallback;

    /**
     * A callback that returns the direction from the component in which to search for the covering surface.
     * This direction should be specified in the local coordinate system of the component.
     */
    getRayDirection: RayDirectionCallback;

    /**
     * The render-order that should be applied to generated coverage mesh objects.
     * @see {@link Object3D.renderOrder}
     */
    coverageMeshRenderOrder: number;
}
