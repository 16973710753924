
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import ArrowFromTo, {
        RowChange,
        RowChangeFactory,
    } from '@/components/case-manage/surgical-template/history/row/ArrowFromTo.vue';
    import {
        HipSurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
    import {
        hasTargetLegLengthChange,
        hasTargetOffsetChange,
        hasTargetsChanged,
    } from '@/lib/api/resource/case/surgical-template/hipTemplateComparison';
    import { formatLengthSign } from '@/lib/filters/format/formatLengthSign';

    /**
     * A simple ui component with to display a from -> to information.
     * The 'from' can be strike through.
     *
     * e.g.:
     * 'processing' -> completed
     * */
    @Component({
        methods: { hasTargetsChanged },
        components: { ArrowFromTo },
    })
    export default class TargetChanges extends Vue {
        @Prop({ required: true, validator: notNilValidator('current') })
        private current!: HipSurgicalTemplateRepresentation;

        @Prop({ required: true, validator: notNilValidator('previous') })
        private previous!: HipSurgicalTemplateRepresentation;

        protected get changes(): (RowChange | null)[] {
            return [
                hasTargetLegLengthChange(this.current, this.previous) ?
                    RowChangeFactory.make(
                        formatLengthSign(this.previous.target_leg_length_change),
                        formatLengthSign(this.current.target_leg_length_change),
                        'Leg Length') :
                    null,
                hasTargetOffsetChange(this.current, this.previous) ?
                    RowChangeFactory.make(
                        formatLengthSign(this.previous.target_offset_change),
                        formatLengthSign(this.current.target_offset_change),
                        'Offset') :
                    null,
            ];
        }
    }
