/**
 * These are the custom colors what will be used since the 're-skin' of the UI.
 *
 * Note: They are imported in the vuetify framework so they can be used as classes:
 * e.g:
 * 1) As background <span class='custom-black'>
 * 2) As text color <span class='custom-black--text'>
 * 3) In the <style> block as:
 *   <style lang="scss">
 *       .some-class {
 *           color: var(--v-custom-grey-base); (!Note the suffix '-base' is added to the name)
 *       }
 *   </style>
 */

export const CustomColors = {
    'custom-black': '#3A3E42',
    'custom-black-1': '#5B636A',
    'custom-black-rich': '#2C2F33',

    'custom-grey': '#7C8893',
    'custom-grey-1': '#99A4AC',
    'custom-grey-2': '#B5C0C6',
    'custom-grey-light': '#D2DCDF',
    'custom-grey-light-1': '#DFE6E8',
    'custom-grey-light-2': '#EDF0F1',
    'custom-grey-body': '#FAFAFA',

    'custom-blue': '#0054A6',
    'custom-blue-rich': '#003471',
    'custom-blue-vibrant': '#20C4F4',
    'custom-blue-button': '#108CCD',
    'custom-other-blue': '#1A65AF',

    'custom-green': '#00B695',
    'custom-green-rich': '#0D9174',

    'custom-yellow': '#FFD059',
    'custom-highlight-yellow': '#FFE13A',

    'custom-orange': '#F47920',
    'custom-orange-rich': '#EA6301',

    'custom-red': '#C9252C',
    'custom-red-rich': '#AD040C',
    'custom-red-other': '#8a030a',
};

export default CustomColors;
