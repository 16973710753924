import { LinkedRepresentation, LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';

const IS_INTEGER = /^\d+$/;

export default class LinkedRepresentationUtil {
    /**
     * Given a linked representation, attempt to get the representation key for Vue component. Each
     * component must be unique and each component should be associated with an underlying resource
     * on the API. By using the key attribute of a component those constraints should be met.
     */
    public static getItemKey(item: LinkedRepresentation): string {
        if (item) {
            const url = LinkUtil.getUri(item, LinkRelation.canonicalOrSelf);
            if (url) {
                return url;
            }
        }
        return 'Unknown';
    }

    /**
     * Parsing the resource link to obtain the resource id that maps to the database entry.
     * TODO: Ideally the API should just return the id value in it's API response
     * @param url - API resource link in the format of https://domain/path/to/resource/:id
     * where :id is an integer value that maps to the resource id in the database
     */
    public static getIdFromUrl(url?: string): string {
        if (!url) {
            return '';
        }

        const parts = url.split('/');
        const id = parts.at(-1);

        if (id && IS_INTEGER.test(id)) {
            return id;
        }
        return '';
    }
}
