import { Matrix4, Vector3 } from 'three';
import { MatrixArray16 } from '@/lib/api/representation/geometry/arrays';

/**
 * Return the position of the matrix.
 */
export function getPosition(matrix: Matrix4 | MatrixArray16): Vector3 {
    if (matrix instanceof Matrix4) {
        return new Vector3().setFromMatrixPosition(matrix);
    } else {
        return new Vector3(matrix[3], matrix[7], matrix[11]);
    }
}

/**
 * Return the position of the matrix.
 *
 * By convention the API represents matrix in [Row-major order](https://en.wikipedia.org/wiki/Row-_and_column-major_order#Row-major_order)
 * Three js represents matrix4 internally in column-major order, so it needs to be transposed before calling 'toArray()'
 *
 * @see https://threejs.org/docs/#api/en/math/Matrix4
 */
export function toMatrixRepresentation(matrix: Matrix4): MatrixArray16 {
    return matrix.clone().transpose().toArray();
}

/**
 * Convert an API-style matrix (array of 16 numbers) into a Matrix4
 *
 * The array representing the matrix will usually be the transformation matrix that comes from master,
 * found in the case-data
 *
 * This is by convention in [Row-major order](https://en.wikipedia.org/wiki/Row-_and_column-major_order#Row-major_order)
 */
export function asMatrix4(matrixArray: MatrixArray16): Matrix4 {
    const matrix4 = new Matrix4();
    matrix4.set(
        matrixArray[0], matrixArray[1], matrixArray[2], matrixArray[3],
        matrixArray[4], matrixArray[5], matrixArray[6], matrixArray[7],
        matrixArray[8], matrixArray[9], matrixArray[10], matrixArray[11],
        matrixArray[12], matrixArray[13], matrixArray[14], matrixArray[15]);

    return matrix4;
}

export function identityMatrixRepresentation(): MatrixArray16 {
    return [
        1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1,
    ];
}
