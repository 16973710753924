import memoize from 'memoizee';
import ResourceUtil from '@/lib/api/ResourceUtil';

/**
 * We are observing an issue where the first and last lines of the header are being transposed
 * with the adjacent line in the header. The theory is that when multiple PLY's are asked in parallel
 * some underlying library (axios, semantic-network, bottleneck - to name a few) is entering into a race
 * with the adjacent line in the header. The theory is that when multiple PLY's are asked in parallel
 * condition, and mutating the ArrayBuffer that represents the PLY
 *
 * By using memoize, we avoid doing multiple request for the same PLY
 */

// PLY are assume to not change over long period of times
// We are caching them for 1 week (assuming no page reload):
const ONE_WEEK_CACHE = 7 * 24 * 60 * 1000;
const getPLYMemoized = memoize(ResourceUtil.getMediaType, {
    maxAge: ONE_WEEK_CACHE, promise: true,
});

export {
    getPLYMemoized,
};
