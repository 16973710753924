import { WatchStopHandle } from 'vue';

/**
 * A list of watch-stop-handles that get called when the stop() method is called.
 */
export class WatchHandleList {
    private handles: WatchStopHandle[] = [];

    public add(...stopHandles: WatchStopHandle[]) {
        this.handles.push(...stopHandles);
    }

    public stop() {
        this.handles.forEach(h => h());
        this.handles = [];
    }
}

/**
 * Base class for non-vue components that need to watch vue values.
 *
 * Nothing magical, just a list of watch handles that get called when the off() method is called.
 */
export class VueObserver {
    private watchHandles = new WatchHandleList();

    protected addWatches(...stopHandles: WatchStopHandle[]) {
        this.watchHandles.add(...stopHandles);
    }

    public off() {
        this.watchHandles.stop();
    }
}
