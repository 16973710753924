import PlanResource from '@/lib/api/resource/case/plan/PlanResource';
import anylogger from 'anylogger';
import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
import AxiosUtil from '@/lib/AxiosUtil';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { validateTemplate } from '@/hipPlanner/stores/template/validateTemplate';
import { PlannerAbortError } from '@/hipPlanner/stores/planner/hipPlannerStore';

const log = anylogger('approve-plan');

export type ApprovePlanFailureReason =
    /** There is a current change in progress */
    'template-change-in-progress' |

    /** The template being approved is not the latest one */
    'template-is-not-latest' |

    /** The current selection of values and components is not valid */
    'template-invalid-selection' |

    /** The current template is not completed, so a plan cannot be created in its context yet */
    'template-record-not-complete' |

    /** The user does not have permission to approve the plan */
    'no-permission' |

    /** The creation did not output a plan for some reason */
    'no-plan' |

    /** To be used when no other reason matches */
    'other' |

    /** The planner is shutting-down */
    'shutting-down'

export type ApprovePlanResult = 'complete' | {
    reason: ApprovePlanFailureReason
    error?: unknown
}

/**
 * It does a create (POST) on the plan collection of the latest surgical template
 *
 * Prior to executing the post, it runs a series of validation on the current ui state / template passed.
 *
 * 1. There should not be user pending changes to save prior to approval.
 * 2. The template selections and shape should be valid.
 * 3. The context where the plan is created (the latest user template has a record state of completed)
 * 4. The creation was successful and returned a plan
 */
export async function approvePlan(templateStore: HipTemplateStore): Promise<ApprovePlanResult> {
    log.info('Approving plan');
    try {
        //  Validate there are no updates to the surgical-template queued or being saved
        if (templateStore.sync.isSaving || templateStore.sync.queuedUpdate) {
            return fail('template-change-in-progress');
        }

        const template = templateStore.userTemplate;

        if (!SurgicalTemplateUtil.isRecordStateCompleted(template)) {
            return fail('template-record-not-complete');
        }

        // Validate the surgical template in a valid state before approving.
        // This accounts for:
        // 1. Client side errors where we are programmatically creating a bad surgical template representation
        // 2. Old cases where the liner use to not match the cup, and that was done server side.
        if (!validateTemplate(template, templateStore)) {
            return { reason: 'template-invalid-selection' };
        }

        return await PlanResource.createPlan(
            template, templateStore.project, templateStore.$apiOptions
        ) ? 'complete' : fail('no-plan');

    } catch (err: unknown) {
        if (err instanceof PlannerAbortError) {
            return fail('shutting-down');
        } else if (err instanceof Error && AxiosUtil.isForbiddenError(err)) {
            return fail('no-permission', err);
        } else {
            return fail('other', err instanceof Error ? err : undefined);
        }
    }
}

function fail(reason: ApprovePlanFailureReason, error?: unknown): ApprovePlanResult {
    return { reason, error };
}

