import { LinkedRepresentation } from 'semantic-link';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { SurgicalSpecificationRepresentation } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
import { ProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';
import { AlignmentTransformRepresentation } from '@/lib/api/representation/interfaces';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import {
    SegmentedModelCollectionRepresentation,
} from '@/lib/api/representation/case/study/SegmentedModelRepresentation';

/** The known states of an acid and/or user surgical template */
export enum SurgicalTemplateState {
    /**
     * The surgical template is 'new' and no business logic (processing code) has been run over
     * the surgical template. In this state the surgical template should no be considered fully
     * created and thus the data inside it should not be used.
     */
    New = 'https://schema.formuslabs.com/state/surgical/template/new',
    /**
     * Automated processes is running over the surgical template. This may take many minutes to complete.
     * During this state the data may be partial. It should not be trusted as valid and self consistent.
     */
    Processing = 'https://schema.formuslabs.com/state/surgical/template/processing',
    /**
     * The surgical template is available for manual processing. This will typically be done
     * by the web client via the API.
     *
     * Note: This state only belongs to user/manual surgical templates
     */
    ManualProcessing = 'https://schema.formuslabs.com/state/surgical/template/manual',
    /**
     * The process is completed and should be deemed successful in that a solution has been
     * calculated. From this state the surgical template should not be changed (mutated), except to be updated with
     * a the plan id. This is considered a final state.
     *
     * Note:
     * 1. It is **important** to note that the completed surgical template may have clinical or
     * other warnings.
     * 2. This state only belongs to automated/acid surgical tempaltes
     */
    Completed = 'https://schema.formuslabs.com/state/surgical/template/completed',
    /**
     * The processing of the surgical template has failed for some reason. The exact reason for
     * the failure is not described here (nor should additional states be created to attempt to
     * describe the one or more reasons that it could fail).
     *
     * This state is a final state. No changes should be made to the surgical template. If the
     * reason for the failure has been corrected, then a new surgical template should be created
     * and the processing re-run.
     */
    Error = 'https://schema.formuslabs.com/state/surgical/template/error',
}

/** The known states of a specific history version acid and/or user surgical template */
export enum SurgicalTemplateRecordState {
    New = 'https://schema.formuslabs.com/state/surgical/template/record/new',
    Processing = 'https://schema.formuslabs.com/state/surgical/template/record/processing',
    Completed = 'https://schema.formuslabs.com/state/surgical/template/record/completed',
    Error = 'https://schema.formuslabs.com/state/surgical/template/record/error',
}

export type SurgicalTemplateRecordStateType =
    SurgicalTemplateRecordState.New |
    SurgicalTemplateRecordState.Processing |
    SurgicalTemplateRecordState.Completed |
    SurgicalTemplateRecordState.Error;

export interface SurgicalTemplateRepresentation extends LinkedRepresentation {
    /**
     * The resource state (as a Uri).
     *
     * @see {@link SurgicalTemplateState}
     */
    state?: string;

    /**
     * The record state (as a Uri).
     *
     * @see {@link SurgicalTemplateRecordState}
     */
    record_state?: SurgicalTemplateRecordStateType;

    /**
     * When the case (project) was created.
     *
     * This is an ISO-9660 datetime string with better than second precision.
     */
    created?: string;
    /**
     * When the case (project) was last modified.
     *
     * This is an ISO-9660 datetime string with better than second precision.
     */
    updated?: string;

    /**
     * An optional client side 'study' link materialized
     */
    study?: StudyRepresentation | null;

    /**
     * An optional client side 'measurements' link materialized
     */
    measurements?: MeasurementsRepresentation | null;

    /**
     * Previous Surgical Template
     */
    previous?: SurgicalTemplateRepresentation | null;

    /**
     * Canonical Surgical Template
     */
    canonical?: SurgicalTemplateRepresentation | null;

    /**
     * The ACID surgical template used at the time this surgical template was created
     */
    parent?: SurgicalTemplateRepresentation;

    /**
     * Client side materialisation of the list of plans created in the context of the surgical template. Both
     * the 'current' version of the resource and canonical history version of the resource have this child
     * collection. However only the collection on a history item resource will support creating a plan by
     * performing a POST.
     */
    plans?: PlanRepresentation[];

    /**
     * The optional current plan for the surgical template. This will be set as soon as the plan is created
     */
    currentPlan?: PlanRepresentation | null;

    /**
     * Materialised from the 'surgical-specification' link relation
     */
    surgicalSpecification?: SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>;

    /**
     * A 4x4 transformation matrix used for global alignment of segmented models
     *
     * @see {@link LinkRel.alignmentTransform}
     */
    alignmentTransform?: AlignmentTransformRepresentation;

    /** The mapped title when queried from a collection. Note: that the model doesn't natively have a name attribute */
    name?: string;

    /**
     * Client-side models collection. This is the collection of models where the link relation has the title 'models'
     */
    models?: SegmentedModelCollectionRepresentation;
}
