import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import LinkedRepresentationUtil from '@/lib/LinkedRepresentationUtil';
import { LinkedRepresentation } from 'semantic-link';

/**
 * A mixin that adds getItemKey.
 *
 * E.g:
 *
 * ```
 * <template>
 *      <div :key=getItemKey(object)>
 *          show if hip
 *      </div>
 * </template>
 *
 *
 *  @Component({
 *       components: { },
 *       mixins: [LinkedRepresentationKey],
 *   })
 *  class AComponentUsingTheMixing extends Vue {
 *  }
 *  ```
 */
@Component
export default class GetItemKeyMixin extends Vue {
    protected getItemKey(item: LinkedRepresentation): string {
        return LinkedRepresentationUtil.getItemKey(item);
    }
}
