import Vue from 'vue';
import Router from 'vue-router';
import paths from '@/router/paths';
import { RouterOptions } from 'vue-router/types/router';

Vue.use(Router);

const options: RouterOptions = {
    routes: paths,
};
export default new Router(options);
