import { AcidObject3dBase } from '@/lib/planning/objects-3D/AcidObject3d';
import { uniqBy } from 'ramda';
import { OutlineModel } from '@/lib/planning/cross-section/OutlineUtils';

/**
 * Utility to avoid having mutable, inline code when dealing with implants and bones on the hip/shoulder application
 */
export class Object3DWrapperUtil {
    /**
     * Filter iterator over a tree
     *
     * @param tree: the tree being traversed
     * @param condition: the predicate use on each node to filter it.
     */
    public static filter<T extends AcidObject3dBase>(
        tree: AcidObject3dBase, condition: (object: AcidObject3dBase) => boolean): T[] {
        let filteredElements: AcidObject3dBase[] = [];
        tree.traverse((object: AcidObject3dBase) => {
            if (condition(object)) {
                filteredElements = [...filteredElements, object];
            }
        });

        return filteredElements as T[];
    }

    /**
     * Return a unique list of the object based on the {@link THREE.Object3D.uuid}
     */
    public static unique(objects: AcidObject3dBase[]): AcidObject3dBase[] {
        return uniqBy((object: AcidObject3dBase) => object.theObject.uuid, objects);
    }

    /**
     * @return the aliases of the objects.
     */
    public static aliases(objects: AcidObject3dBase[]): string[] {
        return objects.map((o: OutlineModel) => o.alias);
    }
}
