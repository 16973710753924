
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { CaseCollectionRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import DuplicateCasesFromStudyModels from '@/views/administer/cases-menu/DuplicateCasesFromStudyModels.vue';
    import DuplicateCasesFromStudyFiles from '@/views/administer/cases-menu/DuplicateCasesFromStudyFiles.vue';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';

    @Component({ components: { DuplicateCasesFromStudyModels, DuplicateCasesFromStudyFiles } })
    /**
     * A <v-menu> to be added in the top right corner of the <AdministerCases />
     *
     * This is a natural place for the user to perform 'advanced' actions on the plan, meaning, these are not the
     * actions that most users will be using in a day to day.
     *
     * Curren Actions:
     * - Download 3D models (.zip file)
     * - Print Intellijoint QR cod
     */
    export default class AdministerCasesMenu extends Vue {
        /** The selected cases to perform actions on */
        @Prop({ required: true, validator: notNilValidator('value') })
        public value!: CaseCollectionRepresentation;

        /** whether the report download is loading data */
        protected isModelsFetching = false;

        /**
         * The v-model for the v-menu
         * @see {@link https://vuetifyjs.com/en/api/v-menu/}
         */
        private isMenuOpened = false;

        /**
         * Handler when the v-menu is clicked
         */
        protected async onLoadMenuOptions(): Promise<void> {
            // nothing to do for now (this is place to load data on demand if needed)
        }

        /**
         * Handler to close the menu when the QR code dialog is opened.
         *
         * This is a compensation of a bug on vuetify: @see {@link https://github.com/vuetifyjs/vuetify/issues/11521}
         */
        protected onDialogOpened(_value: boolean): void {
            this.isMenuOpened = false;
        }

        @Watch('isVisible')
        protected onVisibilityChange(value: boolean): void {
            if (value) {
                this.$emit('open');
            } else {
                // not needed for now. this.$emit('close');
            }
        }

        protected get duplicateCasesIsDisabled(): boolean {
            return this.value.items.length === 0;
        }
    }
