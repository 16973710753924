import { defineStore } from 'pinia';
import { HipRoles, UserHipRole, UserHipRolesState } from '@/stores/hipUser/types';
import UserResource from '@/lib/api/resource/user/UserResource';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import LinkedRepresentationUtil from '@/lib/LinkedRepresentationUtil';
import assert from 'assert';
import { getUserRoles } from '@/stores/hipUser/requests';

export const useHipUser = defineStore('hipUser', {
    state: (): UserHipRolesState => ({
        isReady: false,
        isLoading: false,
        myUserUri: '',
        myOrgUri: '',
        userRoles: [],
    }),
    getters: {
        userId(): string {
            return LinkedRepresentationUtil.getIdFromUrl(this.myUserUri);
        },
        orgId(): string {
            return LinkedRepresentationUtil.getIdFromUrl(this.myOrgUri);
        },
        role(): HipRoles | null {
            if (this.userRoles.length == 0 ) {
                return null;
            }
            // Only return the first role the user possess.
            // In theory there should only be 1.
            return this.userRoles[0];
        },
        isAdmin(): boolean {
            return this.userRoles.includes(HipRoles.Admin);
        },
        isOrgAdmin(): boolean {
            return this.userRoles.includes(HipRoles.OrgAdmin);
        },
        isSurgeon(): boolean {
            return this.userRoles.includes(HipRoles.Surgeon);
        },
        isSurgeonAssist(): boolean {
            return this.userRoles.includes(HipRoles.SurgeonAssist);
        },
        isSales(): boolean {
            return this.userRoles.includes(HipRoles.Sales);
        },
    },
    actions: {
        async load(): Promise<void> {
            this.isLoading = true;

            await this.loadUserUris();
            await this.loadMyHipRoles();

            this.isReady = true;
            this.isLoading = false;
        },
        async loadUserUris(): Promise<void> {
            const me = await UserResource.getMeUser(this.$api, this.$apiOptions);
            assert.ok(me, 'could not load user');

            const myUserUri = LinkUtil.getUri(me, LinkRelation.self);
            assert.ok(myUserUri, 'could not get user self link');
            this.myUserUri = myUserUri;

            const myOrgUri = LinkUtil.getUri(me, LinkRelation.organisation);
            assert.ok(myOrgUri, 'could not get user org link');
            this.myOrgUri = myOrgUri;
        },
        async loadMyHipRoles() {
            const userHipRoles = await getUserRoles(this.$http, this.myUserUri);
            assert.ok(userHipRoles, 'could not get user roles');
            userHipRoles.forEach((userHipRole: UserHipRole) => {
                this.userRoles.push(userHipRole.title);
            });
        },
    },
});

export type HipUserStore = ReturnType<typeof useHipUser>;
