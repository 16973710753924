import { AmbientLight, HemisphereLight, Scene } from 'three';
import { AcidObject3dMainClassTypes } from '@/hipPlanner/assembly/objects/AcidObject3dMainClassTypes';
import anylogger from 'anylogger';
import { hipPlannerModeObjectSettingKey } from '@/hipPlanner/assembly/controllers/AcidPlannerModeSettings';
import AcidObject3d, { AcidObject3dBase } from '@/lib/planning/objects-3D/AcidObject3d';
import LPS from '@/lib/base/LPS';

const log = anylogger('SceneAssembly');

export class SceneAssembly {
    /**
     * Main group of 3d objects, containing all the bones, implants, and metal.
     * This is the node were the hip planner assembly root is later added
     */
    public readonly hip = new AcidObject3d({ name: 'Hip' });

    constructor(public readonly scene: Scene) {
        this.scene.add(this.hip.theObject);
    }

    public get hipObjects(): AcidObject3dBase[] {
        return this.hip.children;
    }

    public findObject(alias: hipPlannerModeObjectSettingKey): AcidObject3dMainClassTypes | undefined {
        return this.hip.children.find((i) => i.alias === alias);
    }

    /** Add the AcidObject3d that's in the main objects collection group */
    public addToHipObject(acidObject: AcidObject3dBase): this {
        if (!this.hip.children.includes(acidObject)) {
            this.hip.add(acidObject);
        } else {
            log.warn('object already exist');
        }

        return this;
    }
}

export function makeSceneAssembly(): SceneAssembly {
    const scene = new Scene();

    const light = new HemisphereLight(0xffffff, 0xffffff, 1);
    light.color.setHSL(1, 1, 1);
    light.groundColor.setHSL(0.2, 0.2, 0.2);
    light.position.copy(LPS.Superior.multiplyScalar(500));
    scene.add(light);

    scene.add(new AmbientLight(0x040404));

    return new SceneAssembly(scene);
}
