import {
    CaseCup,
    CaseHead,
    CaseLiner,
    CaseStem,
} from '@/hipPlanner/components/state/types';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import { cloneDeep } from 'lodash';
import { fromRepresentation } from '@/lib/base/RigidTransform';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { findCupAndLiner, findStemAndHead } from '@/hipPlanner/stores/template/findComponents';
import { HipCaseStore } from '@/hipPlanner/stores/case/hipCaseStore';

/**
 * Initial load of the current components
 */
export async function loadComponents(
    caseStore: HipCaseStore,
    templateStore: HipTemplateStore,
    signal?: AbortSignal,
): Promise<[CaseStem, CaseCup, CaseHead, CaseLiner]> {
    const fittedStem = await findStemAndHead(
        caseStore,
        getRequiredUri(templateStore.userTemplate, LinkRelation.hipCurrentStemComponent),
        getRequiredUri(templateStore.userTemplate, LinkRelation.hipCurrentStemHeadComponent),
        { signal },
    );
    const fittedCup = await findCupAndLiner(
        caseStore,
        getRequiredUri(templateStore.userTemplate, LinkRelation.hipCurrentCupComponent),
        getRequiredUri(templateStore.userTemplate, LinkRelation.hipCurrentCupLinerComponent),
        { signal },
    );
    templateStore.$patch(state => {
        state.stem = cloneDeep(fittedStem.stem);
        state.head = cloneDeep(fittedStem.head);
        state.cup = cloneDeep(fittedCup.cup);
        state.liner = cloneDeep(fittedCup.liner);

        state.stemTransform = fromRepresentation(templateStore.userTemplate.stem_transform);
        state.initialised = true;
    });

    return [fittedStem.stem, fittedCup.cup, fittedStem.head, fittedCup.liner];
}
