/**
 * The render-order that should be applied to 3D objects in the hip planning scene
 * @see {@link Object3D.renderOrder}
 *
 * @note The operative femur outer-surface and the inner-cortical-surface are out-of-order in a purely spatial sense
 * but it was decided that the inner-cortical-surface needed to be more visible, so is being rendered on-top of the
 * outer-surface.
 */
export enum HipRenderOrder {
    NeckCutPlane = 1,
    Default = 0,
    CoverageMask = -1,
    Cup = -2,
    Liner = -3,
    InnerCorticalSurface = -4,
    OperativeFemur = -5,
    Head = -6,
    Stem = -7,
}
