/**
 * The types of measurements
 */
import {
    RadialMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/RadialMeasurementValueRepresentation';
import {
    AxisMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/AxisMeasurementValueRepresentation';
import {
    AngleMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/AngleMeasurementValueRepresentation';
import {
    DistanceMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/DistanceMeasurementValueRepresentation';
import {
    PlaneMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/PlaneMeasurementValueRepresentation';
import {
    RatioMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/RatioMeasurementValueRepresentation';

export enum MeasurementValueTypes {
    Linear = 'linear',
    Radial = 'radial',
    Axis = 'axis',
    Angle = 'angle',
    Distance = 'distance',
    Plane = 'plane',
    Ratio = 'ratio',
    CoordinateSystem = 'coordinate_system'
}

export type MeasurementValueType =
    MeasurementValueTypes.Linear |
    MeasurementValueTypes.Radial |
    MeasurementValueTypes.Axis |
    MeasurementValueTypes.Angle |
    MeasurementValueTypes.Distance |
    MeasurementValueTypes.Plane |
    MeasurementValueTypes.Ratio |
    MeasurementValueTypes.CoordinateSystem;

export type MeasurementValueRepresentationType =
    DistanceMeasurementValueRepresentation |
    RadialMeasurementValueRepresentation |
    AxisMeasurementValueRepresentation |
    AngleMeasurementValueRepresentation |
    PlaneMeasurementValueRepresentation |
    RatioMeasurementValueRepresentation;
