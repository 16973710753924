import { isNil } from 'ramda';
import { StudyUtil } from '@/lib/api/resource/case/study/StudyUtil';
import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import {
    SurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';

/**
 * @return if the user can do manual templating
 *
 * A user can to manual templating if:
 * 1. the 'active' study is complete
 * 2. the 'acid' surgical template is completed
 * 3.  the 'acid' surgical template belongs to the active study
 */
export function canUserDoManualTemplating(
    study: StudyRepresentation | undefined | null,
    surgicalTemplate: SurgicalTemplateRepresentation | undefined | null,
): boolean {
    return isActiveStudyCompleted(study) &&
        isAcidSurgicalTemplateCompleted(surgicalTemplate) &&
        doesAcidSurgicalTemplateBelongToStudy(study, surgicalTemplate);
}

function isActiveStudyCompleted(study: StudyRepresentation | undefined | null ): boolean {
    return !isNil(study) && StudyUtil.isCompleted(study);
}

function isAcidSurgicalTemplateCompleted(surgicalTemplate: SurgicalTemplateRepresentation | undefined | null): boolean {
    return !isNil(surgicalTemplate) && SurgicalTemplateUtil.isCompleted(surgicalTemplate);
}

function doesAcidSurgicalTemplateBelongToStudy(
    study: StudyRepresentation | undefined | null,
    surgicalTemplate: SurgicalTemplateRepresentation | undefined | null,
): boolean {
    if (surgicalTemplate && study) {
        return SurgicalTemplateUtil.belongsToStudy(surgicalTemplate, study);
    }
    return false;
}