import {
    WorkerEvent,
    WorkerEvents,
    WorkerMessageEvent,
} from '@/worker/types';
import anylogger from 'anylogger';
import {
    AuthenticationStorage,
    AuthenticatorEvent,
    AuthRevokeReasonType,
} from '@/lib/http/AuthenticatorService';
import { partial } from 'lodash';
import Vue from 'vue';

export const log = anylogger('AuthenticatedWorker');

export class AuthenticatedWorker {
    public worker: Worker;
    private events: WorkerEvents;
    private eventBus: Vue;

    constructor(worker: Worker, eventBus: Vue) {
        this.worker = worker;
        this.events = {};
        this.eventBus = eventBus;
        this.eventBus.$on(
            AuthenticatorEvent.authRevoke,
            (message: string, _args: any) => this.onAuthRevoke(message as unknown as AuthRevokeReasonType)
        );

        this.worker.onmessage = partial(this.onWorkerMessage, this.events);
        this.on(WorkerEvent.Error, partial(this.onDefaultError));
    }

    private onAuthRevoke(_payload: AuthRevokeReasonType): void {
        this.worker.postMessage({
            event: WorkerEvent.AuthRevoke,
        });
    }

    private async onWorkerMessage(events: WorkerEvents, e: WorkerMessageEvent): Promise<void> {
        if(Object.prototype.hasOwnProperty.call(events, e.data.event)) {
            await events[e.data.event].call(this, e);
            return;
        }
        log.warn('Worker message event: %s received but not call back function registered.', e.data.event);
    }

    public authenticateWorkerSession(authToken: string): void {
        this.worker.postMessage({
            event: WorkerEvent.Authenticate,
            refreshToken: AuthenticationStorage.getRefreshToken(),
            authToken: authToken,
        });
    }

    private onDefaultError(e: WorkerMessageEvent): void {
        const error = e.data.error ? e.data.error : 'Unknown';
        log.error(`Worker failed: ${error}`);
        this.terminate();
    }

    trigger<T>(data: T): void {
        this.worker.postMessage(data);
    }

    on(event: WorkerEvent, callback: (e: WorkerMessageEvent) => void | Promise<void>): void {
        this.events[event] = callback;
    }

    terminate() {
        this.worker.terminate();
        log.info('Upload DICOMs web worker terminated');
    }
}