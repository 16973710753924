/**
 * Load support for Internationalisation (I18n).
 *
 * This comprises the following modules:
 *    - vue-i18n
 *    - vue-i18n-loader
 *    - vue-i18n-filter
 *
 * The purpose of this in the product at this point of time is to provide a place for string
 * loading; there is little to no intent to provide localised strings in the near future.
 *
 * Note: this setup is related to Vue I18n v8 (VueJS v2) (however this is VueJS v3 support available)
 *
 * Vue-i18n
 * ========
 *
 * This extends the basic support provided by VueJS.
 *
 * @see https://kazupon.github.io/vue-i18n/
 * @see https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
 * @see https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/
 *
 * Vue-i18n-loader  (@intlify/vue-i18n-loader)
 * ===============
 *
 * The loader is used to take <i18n> blocks from `.vue` files and incorporate them into the
 * language strings (that are compiled as code).
 *
 * This requires the webpack loader to be configured.
 *
 * **WARNING**: Using v2.1.2 of this package has problems, use v1.1.0
 *
 * @see https://vue-i18n.intlify.dev/guide/advanced/sfc.html
 * @see https://kazupon.github.io/vue-i18n/guide/sfc.html
 * @see https://github.com/intlify/vue-i18n-loader
 * @see https://github.com/kazupon/vue-i18n/tree/dev/examples/sfc
 * @see https://github.com/last-partizan/vue-i18n-loader-bug
 * @see https://github.com/matthew-white/vue-i18n-repro
 * @see https://github.com/intlify/vue-i18n-loader/issues/118
 *
 * Vue-i18n-filter
 * ===============
 *
 * Support for a filter in templates to use 't' as a filter rather than a function. This should
 * help make templates be more readable.
 *
 * @see https://github.com/chiaweilee/vue-i18n-filter
 *
 * vue-cli-plugin-i18n
 * ===================
 *
 * @see https://github.com/intlify/vue-cli-plugin-i18n
 */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueI18nFilter from 'vue-i18n-filter';

Vue.use(VueI18n);
Vue.use(VueI18nFilter);
const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    sync: false,
    messages: {},
});

export default i18n;
