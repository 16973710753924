/**
 * Representation for creating a user surgical template (not an acid surgical template)
 *
 * The surgical template needs needs a
 * - a study_id
 * - and surgical_specification_id
 *
 * Given we are creating the surgical template in the context of the study, the uri already includes the
 * and study_id
 * We only need to pass the surgical_specification_id in the payload
 */
import { Uri } from 'semantic-link';
import { Representation } from '@/lib/semanticNetworkMigrationUtils';

enum SurgicalTemplateType {
    Automated = 'automated',
    Manual = 'manual',
}

export interface SurgicalTemplateCreateDataRepresentation extends Representation {
    surgical_template: Uri;
    type: SurgicalTemplateType;
}

export function makeManualSurgicalTemplateCreateDataRepresentation(surgicalTemplate: Uri):
    SurgicalTemplateCreateDataRepresentation {
    return {
        surgical_template: surgicalTemplate,
        type: SurgicalTemplateType.Manual,
    };
}

export function makeAutomatedSurgicalTemplateCreateDataRepresentation(surgicalTemplate: Uri):
    SurgicalTemplateCreateDataRepresentation {
    return {
        surgical_template: surgicalTemplate,
        type: SurgicalTemplateType.Automated,
    };
}
