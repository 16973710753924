import ResourceService from '@/lib/api/ResourceService';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import {
    MessageCollectionRepresentation,
    MessageRepresentation,
} from '@/lib/api/representation/MessageRepresentation';
import { ApiUtil, pooledCollectionMakeStrategy } from '@/lib/semantic-network';
import { UserCollectionRepresentation } from '@/lib/api/representation/user/UserRepresentation';

export default class MessageResource implements ResourceService {
    /** Get a sparse list of messages for the case */
    public static async getCaseMessages(
        cacheCollection: MessageCollectionRepresentation,
        caseResource: CaseRepresentation,
        options?: CacheOptions): Promise<MessageCollectionRepresentation | null> {
        return await ApiUtil.get<UserCollectionRepresentation>(
            caseResource, {
                ...options,
                rel: LinkRelation.messages,
                makeSparseStrategy: (o) => pooledCollectionMakeStrategy(cacheCollection, o),
            }) ?? null;
    }

    /**
     * Get a case message item
     */
    public static async getCaseMessage(
        messageRepresentation: MessageRepresentation,
        options?: CacheOptions): Promise<MessageRepresentation | null> {
        return await ApiUtil.get<MessageRepresentation>(messageRepresentation, options) ?? null;
    }
}
