import ViewerUtils from '@/lib/planning/viewer/ViewerUtils';
import { MaterialSet } from '@/lib/viewer/MaterialFactory';
import { ViewingMode } from '@/lib/viewer/ViewingMode';
import anylogger from 'anylogger';
import { AxesHelper, BackSide, BufferGeometry, Material, Matrix4, Mesh, Object3D, Plane, Vector3 } from 'three';
import { includes } from 'ramda';
import { makeAxesHelper } from '@/lib/base/helpers';
import {
    setLocalTransform, setPosition, setWorldTransform, shiftLocalTransform, shiftWorldTransform, worldPosition,
    worldTransform,
} from '@/lib/base/ThreeUtil';
import BufferGeometryUtil from '@/lib/viewer/component-coverage/BufferGeometryUtil';

const log = anylogger('AcidObject3d');

export enum MetalType {
    Metal = 'metal',
}

export enum AcidObject3dType {
    Implant = 'implant',
    Bone = 'bone',
    BoneInner = 'boneInner',
    Metal = 'metal',
    Collision = 'collisionCoverage',
    Group = 'group',
}

export function isBone(type: AcidObject3dType): boolean {
    return type === AcidObject3dType.Bone || type === AcidObject3dType.BoneInner;
}

export type ObjectProperties = {
    type?: string,
    name?: string,
    alias?: string,
    label?: string,
    debugColor?: string,
    showAxes?: boolean,
    matrixAutoUpdate?: boolean,
    transform?: Matrix4,
}

/**
 * Base class of the AcidObject3d
 */
export class AcidObject3dBase {
    get children(): AcidObject3dBase[] {
        return this._children;
    }

    get parent(): AcidObject3dBase | null {
        return this._parent;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get alias(): string {
        return this._alias;
    }

    get theObject(): Object3D {
        return this._theObject;
    }

    set theObject(value: Object3D) {
        this._theObject = value;
    }

    /**
     * Human readable label of the object, used as the displayed text in the viewer
     */
    protected label = '';

    /**
     * This holds the actual 3d model object.
     * It can be a simple Object3D with no material or a Mesh object with material when become an AcidObject3d
     */
    protected _theObject: Object3D;

    /**
     * Transformation matrix that will be applied on the object before it is rendered in the scene
     * This transformation takes a body from a Catalogue CS to the Planning CS
     *
     * Important: Currently it's only defined in the api for implants.
     * To generalize the logic regarding transformations, we define the {@link objectMatrix} as the 4x4 identity matrix,
     * so applying it is idempotent
     */
    public objectMatrix: Matrix4 = new Matrix4();

    /**
     * Apply a 4x4 matrix to the mesh a updates the mesh matrices
     */
    public applyMatrix(matrix: Matrix4): void {
        this.object3D.applyMatrix4(matrix);
    }

    public get object3D(): Mesh {
        return this._theObject as Mesh;
    }

    // eslint-disable-next-line no-use-before-define
    private _parent: AcidObject3dBase | null = null;
    // eslint-disable-next-line no-use-before-define
    private readonly _children: AcidObject3dBase[];
    private _type: string;
    private _name: string;
    private readonly _alias: string;

    private readonly _axesHelper: AxesHelper | null = null;

    constructor(properties: ObjectProperties = {}) {
        const { type, name, alias, label, debugColor, showAxes, matrixAutoUpdate, transform } = properties;
        this._type = type ?? AcidObject3dType.Group;
        this._name = name ?? '';
        this._alias = alias ?? '';
        this.label = label ?? '';
        this._children = [];
        this._theObject = new Object3D();
        this._theObject.name = this._name;

        if (matrixAutoUpdate !== undefined) {
            this._theObject.matrixAutoUpdate = matrixAutoUpdate;
        }

        if (transform !== undefined) {
            setLocalTransform(this._theObject, transform);
        }

        this._axesHelper = makeAxesHelper({
            name: name ?? alias ?? label ?? type ?? 'axes',
            textColor: debugColor,
            visible: showAxes ?? false,
        });

        this._theObject.add(this._axesHelper);
    }

    public get axesHelper(): AxesHelper | null {
        return this._axesHelper;
    }

    public get worldTransform(): Matrix4 {
        return worldTransform(this._theObject);
    }

    public set worldTransform(value: Matrix4) {
        setWorldTransform(this._theObject, value);
    }

    public get worldPosition(): Vector3 {
        return worldPosition(this._theObject);
    }

    public set worldPosition(position: Vector3) {
        this.worldTransform = this.worldTransform.setPosition(position);
    }

    public get localTransform(): Matrix4 {
        return this._theObject.matrix.clone();
    }

    public set localTransform(transform: Matrix4) {
        setLocalTransform(this._theObject, transform);
    }

    public get localPosition(): Vector3 {
        return new Vector3().setFromMatrixPosition(this._theObject.matrix);
    }

    public set localPosition(position: Vector3) {
        setPosition(this._theObject, position);
    }

    /**
     * Set the world-transform of this object without changing the world-transforms of its children
     */
    public shiftWorldTransform(transform: Matrix4) {
        shiftWorldTransform(this._theObject, transform);
    }

    /**
     * Set the local-transform of this object without changing the world-transforms of its children
     */
    public shiftLocalTransform(transform: Matrix4) {
        shiftLocalTransform(this._theObject, transform);
    }

    /**
     * Attach a child object, retaining the child's world position
     */
    public attach(...children: AcidObject3dBase[]): void {
        for (const child of children) {
            child.clearParent();
            if (this._theObject) {
                this._theObject.attach(child.theObject);
                this._children.push(child);
                child._parent = this;
            }
        }
    }

    /**
     * Add objects to the underlying {@link Object3D}.
     * Each child object will retain its current local transform (matrix), so unlike {@link AcidObject3dBase.attach}
     * its world transform will not be retained.
     *
     * @see {@link Object3D.add}
     */
    public add(...children: AcidObject3dBase[]): void {
        for (const child of children) {
            child.clearParent();
            if (this._theObject) {
                // Attach the child to the parent, while retaining the child's world position
                this._theObject.add(child.theObject);
                this._children.push(child);
                child._parent = this;
            }
        }
    }

    public detach(...children: AcidObject3dBase[]): void {
        // We reverse loop the children, because we are removing items from the array
        for (let i = children.length - 1; i >= 0; i--) {
            const child = children[i];
            const index = this._children.indexOf(child);
            //
            // IMPORTANT: Check for a value of -1, because indexOf returns -1 when not found
            //
            if (index !== -1) {
                // Remove the child from the parent
                this._theObject.remove(child.theObject);
                this._children.splice(index, 1);
                child._parent = null;
            }
        }
    }

    /**
     * Reset the child's parent member if set
     */
    private clearParent() {
        if (this._parent) {
            const index = this._parent._children.indexOf(this);
            if (index < 0) {
                throw Error('Object to reattach is not a child of its member parent');
            }
            this._parent._children.splice(index, 1);
            this._parent = null;
        }
    }

    /**
     * Loops through all the descendents of the object using this method
     *
     * Based of the "traverse" function of a Object3D
     *   @see {@link https://github.com/mrdoob/three.js/blob/master/src/core/Object3D.js}
     */
    public traverse(onObjectCallback: (object: AcidObject3dBase) => void): void {
        onObjectCallback(this);

        const children = this._children;
        // loop through the children of this object
        for (const child of children) {
            // repeat the loop for all descendents of each children
            child.traverse(onObjectCallback);
        }
    }
}

export default class AcidObject3d extends AcidObject3dBase {
    public constructor(properties: ObjectProperties = {}) {
        super(properties);
    }

    get theObject(): Mesh {
        return this._theObject as Mesh;
    }

    set theObject(value: Mesh) {
        this._theObject = value;
    }

    /**
     * Material collection used for rendering the 3d object in different viewing mode (normal, xray)
     */
    private _materials?: MaterialSet;

    /**
     * A separate mesh used to draw the back-side faces of the object in a different material
     */
    private _backsideMesh?: Mesh;

    /**
     * Show the back-side (see {@link Material.side}) faces of the geometry from {@link theObject} with an opaque
     * copy of the front-side material.
     *
     * The first time this is called a back-side-mesh child object will be created, and the front-side material will be
     * cloned from the {@link theObject} material at that point.
     */
    public showBacksideMesh(): void {
        if (this._backsideMesh) {
            this._backsideMesh.visible = true;
        } else {
            const material = (this.theObject.material as Material).clone();
            material.side = BackSide;
            material.transparent = false;
            material.depthWrite = true;
            this.setBacksideMaterial(material);
        }
    }

    /**
     * Hide the back-side faces if they are visible.
     *
     * @See {@link showBacksideMesh}
     */
    public hideBacksideMesh(): void {
        if (this._backsideMesh) {
            this._backsideMesh.visible = false;
        }
    }

    /**
     * Change the viewing mode to available viewing mode presets (normal, xray)
     */
    public changeViewMode(viewMode: ViewingMode): void {
        if (this.theObject.material && this._materials) {
            // change the current object material with the selected mode material presets
            //
            // IMPORTANT: we should not clone the material, because we will lose control of
            //            the original clippingPlanes objects inside it
            this.theObject.material = this._materials[viewMode];

            // Only show the backside-mesh in normal mode
            if (this._backsideMesh) {
                this._backsideMesh.visible = viewMode === ViewingMode.Normal;
            }
        }
    }

    /**
     * Add a cross-section plane to this object's material
     *
     * The material option is one of the two available material modes (normal or xray)
     *  @see MaterialSet
     */
    public addCrossSectionPlaneToMaterial(plane: Plane, mode: ViewingMode): void {
        if (this._materials) {
            const clippingPlanes = this._materials[mode].clippingPlanes;
            if (includes(plane, clippingPlanes)) {
                log.debug('Cross section clipping plane already exist into material');
            } else {
                log.debug('Cross section clipping plane added into material');
                clippingPlanes.push(plane);

                // Also add the clipping plane to the backside-mesh
                if (mode === ViewingMode.Normal && this._backsideMesh) {
                    (this._backsideMesh.material as Material).clippingPlanes.push(plane);
                }
            }
        } else {
            log.error('Materials are not defined for this object');
        }
    }

    //
    // /**
    //  * Apply matrix to the mesh
    //  */
    // public applyMatrix(tMatrix: Matrix4): void {
    //     this.theObject.geometry.applyMatrix4(tMatrix);
    //     this.theObject.geometry.computeBoundingSphere();
    //     this.theObject.updateMatrix();
    // }

    /**
     * Apply a 4x4 matrix to the mesh a updates the mesh matrices
     */
    public applyMatrix(matrix: Matrix4): this {
        super.applyMatrix(matrix);

        // TODO
        // review if we need computeBoundingSphere here
        this.geometry.computeBoundingSphere();

        this.object3D.updateMatrix();
        this.object3D.updateMatrixWorld(true);

        return this;
    }

    /**
     * shortcut to mesh geometry
     */
    protected get geometry(): BufferGeometry {
        return this.object3D.geometry;
    }

    /**
     * Show the 3d object in the scene, usually because it has been hidden.
     */
    public show(): void {
        log.debug('Showing %s', this.theObject.name);
    }

    /**
     * Hide the 3d object in the scene.
     */
    public hide(): void {
        log.debug('Hiding %s', this.theObject.name);
    }

    /**
     * Get the bounding sphere center (aka. center of mass) point in world space
     */
    public getWorldBoundingSphereCenter(): Vector3 | undefined {
        const sphereCenter = this.getBoundingSphereCenter()?.clone();
        if (sphereCenter) {
            this.object3D.localToWorld(sphereCenter);
        } // else do nothing
        return sphereCenter;
    }

    /**
     * Apply to this object the normal material defined in the object representation
     */
    public applyNormalMaterialFromRepresentation(makeMaterials: (alias: string) => MaterialSet): void {
        // Create the viewing mode materials
        this._materials = makeMaterials(this.alias);
        if (this._materials.backSide) {
            this.setBacksideMaterial(this._materials.backSide);
        }
        this.theObject.material = this._materials.normal;
    }

    /**
     * Define the local origin of this object
     */
    public setObjectCentre(coords: Vector3): void {
        ViewerUtils.updateMeshObjectCenter(this.theObject, coords);
    }

    public getBoundingSphereCenter(): Vector3 {
        return BufferGeometryUtil.boundingSphere(this.theObject.geometry).center.clone();
    }

    /**
     * Assign a {@link Object3D.renderOrder render-order} to the underlying three-js objects
     */
    public setRenderOrder(value: number): void {
        this.theObject.renderOrder = value;
        if (this._backsideMesh) {
            this._backsideMesh.renderOrder = value;
        }
    }

    /**
     * Set the material of the backside-mesh, creating it if is not yet already created.
     */
    private setBacksideMaterial(material: Material): void {
        if (!this._backsideMesh) {
            this._backsideMesh = new Mesh(this.theObject.geometry, material);
            this._backsideMesh.name = 'back-side-mesh';
            this._backsideMesh.renderOrder = this.theObject.renderOrder;
            this.theObject.add(this._backsideMesh);
        } else {
            this._backsideMesh.material = material;
        }
    }
}

//
// /**
//  * The mask geometry is a custom made geometry used to detect collision with other objects
//  * It is a simplified geometry based on the original object model geometry
//  */
// private maskGeometry?: Geometry;
// /**
//  * The pivot point can be any point on/in the object, which is used for rotating it.
//  * This point may or may not be the center of mass of the object.
//  */
// private pivotPoint?: Vector3;
// /**
//  * These points are used as a reference where other objects can be connected with this object.
//  * eg. Tip of the stem point will be connected to the base of the head point
//  */
// private connectionPoints: Vector3[] = [];
// /**
//  * Any directional axes of the object. Some of them are:
//  *  - ML (Medial Lateral)
//  *  - SI (Superior Inferior)
//  *  - AP (Anterior Posterior)
//  *  - Normal (direction towards the object is facing)
//  */
// private axes?: Vector3[];
//
// /**
//  * This is the data that comes form the case-data route
//  */
// private data?: Promise<TData>;
