import { isMatrixArray16, isNumberArray3, MatrixArray16, NumberArray3 } from '@/lib/api/representation/geometry/arrays';
import { isEulerAngles } from '@/lib/base/EulerRotation';
import { approxEquals, asVector3 } from '@/lib/base/ThreeUtil';
import { Vector3 } from 'three';

/**
 * Identifier for the order that rotation are applied in an euler-rotation.
 *
 * Note that the rotation-order, following three-js, is the reverse of the order the matrices would be
 * multiplied to the points. So a point transformed by an 'XYZ' rotation will first be multiplied
 * by the z-rotation, then the y-rotation and then the x-rotation. To add to the confusion, this means
 * the matrix multiplication will be in the order as read so for an 'XYZ' rotation
 *
 * ` p_rotated = R_x * R_y * R_z * p `
 */
export type EulerRotationOrder = 'XYZ' | 'YZX' | 'ZXY' | 'XZY' | 'YXZ' | 'ZYX';

/**
 * The rotation order implied when no particular order is given
 */
export const defaultEulerOrder: EulerRotationOrder = 'XYZ';

/**
 * Representation of a rotation as represented in Euler angles.
 *
 * Euler angles describe a rotational transformation by rotating an object on its various axes in specified
 * amounts per axis and a specified axis order.
 *
 * The angles are specified in **radians**.
 *
 * See https://threejs.org/docs/#api/en/math/Euler
 */
export type EulerAnglesRepresentation = {
    type: 'euler-angles'
    x: number | null
    y: number | null
    z: number | null
    order: EulerRotationOrder | null
}

export enum TransformType {
    Rigid = 'rigid-transform',
}

/**
 * Representation of a rigid transformation
 */
export type RigidTransformRepresentation = {
    type: TransformType.Rigid,
    /** This is by convention in [Row-major order](https://en.wikipedia.org/wiki/Row-_and_column-major_order#Row-major_order) */
    matrix?: MatrixArray16
    translation?: NumberArray3
    rotation?: EulerAnglesRepresentation
}

export function isRigidTransformRepresentation(transform: unknown): transform is RigidTransformRepresentation {
    const maybeTransform = transform as RigidTransformRepresentation;

    return !!maybeTransform &&
        maybeTransform.type === TransformType.Rigid &&
        (!maybeTransform.matrix || isMatrixArray16(maybeTransform.matrix)) &&
        (!maybeTransform.translation || isNumberArray3(maybeTransform.translation)) &&
        (!maybeTransform.rotation || isEulerAngles(maybeTransform.rotation));
}

export function areEqualTransforms(a: RigidTransformRepresentation, b: RigidTransformRepresentation): boolean {
    if (a === b) {
        return true;
    }

    const isMatrixEqual = a.matrix === b.matrix ||
        (!!a.matrix && !!b.matrix && approxEquals(a.matrix, b.matrix));

    const isTranslationEqual = a.translation === b.translation ||
        (!!a.translation && !!b.translation && approxEquals(a.translation, b.translation));

    const isRotationEqual = a.rotation === b.rotation ||
        (!!a.rotation && !!b.rotation && areEqualRotations(a.rotation, b.rotation));

    return isMatrixEqual && isTranslationEqual && isRotationEqual;
}

/**
 * Whether it represents a no rotation.
 * Check that the x, y & z properties are close to 0 or null.
 */
export function isApproxEmptyRotation(rotation: EulerAnglesRepresentation): boolean {
    return approxEquals(_asVector3(rotation), asVector3([0, 0, 0]));
}

export function areEqualRotations(a: EulerAnglesRepresentation, b: EulerAnglesRepresentation): boolean {
    if (a === b) {
        return true;
    }

    const isTypeEqual = a.type === b.type;
    const isOrderEqual = a.order === b.order;
    const isXYZEqual = approxEquals(_asVector3(a), _asVector3(b));

    return isTypeEqual && isOrderEqual && isXYZEqual;
}

/** A utility used internally for simplicity when comparing values */
function _asVector3(a: EulerAnglesRepresentation): Vector3 {
    return new Vector3(a.x ?? undefined, a.y ?? undefined, a.z ?? undefined);
}
