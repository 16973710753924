import { GammaEncoding, WebGLRenderer } from 'three';

import anylogger from 'anylogger';
import { XRAnimationLoopCallback } from 'three/src/renderers/webxr/WebXR';
import { formatNumber } from '@/lib/filters/format/formatNumber';

const log = anylogger('RendererUtil');

const THREE_JS_BACKGROUND_COLOR = '#262626';

/** A utility class to interact with a three js renderer */
export default class RendererUtil {
    /**
     * Makes a the 3D planning renderer
     *
     * @param container: the DOM element where the scene canvas lives
     * @param canvas: A Canvas where the renderer draws its output.
     */
    public static make(canvas: HTMLCanvasElement): WebGLRenderer {
        return new WebGLRenderer({
            alpha: true,
            depth: true,
            stencil: false,
            antialias: true,
            canvas,
        });
    }

    /**
     * Makes a the 3D planning renderer for the specific container
     *
     * @param container: the DOM element where the scene canvas lives.
     * The size of the renderer is set according to this container
     * @param canvas: A Canvas where the renderer draws its output.
     */
    public static make3DRenderer(container: HTMLElement, canvas: HTMLCanvasElement): WebGLRenderer {
        const renderer = RendererUtil.make(canvas);

        log.debug(
            'Create render with width: %s, height: %s',
            formatNumber(container.clientWidth),
            formatNumber(container.clientHeight));

        renderer.setSize(container.clientWidth, container.clientHeight);
        RendererUtil.setOutputEncoding(renderer);
        RendererUtil.allowsCrossSectionPlanes(renderer);

        return renderer;
    }

    /** Stops a renderer and free-up the resources used by it */
    public static destroy(renderer: WebGLRenderer): void {
        RendererUtil.stop(renderer);
        RendererUtil.freeUpResources(renderer);
    }

    /**
     * For now only forces the context loss
     *
     * TODO
     * Analyze if need to free up resources manually as described here
     * https://stackoverflow.com/questions/23598471/how-do-i-clean-up-and-unload-a-webgl-canvas-context-from-gpu-after-use
     */
    public static freeUpResources(renderer: WebGLRenderer): WebGLRenderer {
        renderer.forceContextLoss();
        return renderer;
    }

    /**
     * Clean up the entire three.js scene and stop rendering
     *
     * See post from one of the main three.js contributors, on 13th August, 2020:
     *  - https://discourse.threejs.org/t/how-to-completely-clean-up-a-three-js-scene-from-a-web-app-once-the-scene-is-no-longer-needed/1549/15
     *
     * Also main three.js docs:
     *  - https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-object
     *
     */
    public static stop(renderer: WebGLRenderer): WebGLRenderer {
        renderer.dispose();
        return renderer;
    }

    /**
     * Render and continuously animate the all available canvases
     */
    public static animate(renderer: WebGLRenderer, callback: XRAnimationLoopCallback): WebGLRenderer {
        // The this must be bound in the callback. In theory the es2015 arrow function
        // should be able to do this, but it seems to be failing. Given the animate()
        // call may be called often it would be good it it wasn't too slow.
        //
        // see
        //  - https://www.sitepoint.com/bind-javascripts-this-keyword-react/
        renderer.setAnimationLoop(callback);

        log.debug('Renderer render info: %o', renderer.info.render);
        log.debug('Renderer memory info: %o', renderer.info.memory);

        return renderer;
    }

    /**
     * We are using clipping planes for the cross section and resection planes functionality
     *
     * Clipping planes:
     * @see https://threejs.org/docs/#api/en/materials/Material.clippingPlanes
     *
     * Renderer clipping plane is false by default
     * @see https://threejs.org/docs/#api/en/renderers/WebGLRenderer.localClippingEnabled
     *
     */
    public static allowsCrossSectionPlanes(renderer: WebGLRenderer): WebGLRenderer {
        renderer.localClippingEnabled = true;
        return renderer;
    }

    /**
     * Set a background color so we can see the wireframe objects
     */
    public static setClearColor(renderer: WebGLRenderer, color = THREE_JS_BACKGROUND_COLOR): WebGLRenderer {
        renderer.setClearColor(color);
        return renderer;
    }

    /**
     * Set the output encoding
     *
     * Comment was: "Auto-correct the gamma of textures and colors"
     */
    public static setOutputEncoding(renderer: WebGLRenderer, encoding = GammaEncoding): WebGLRenderer {
        renderer.outputEncoding = encoding;
        return renderer;
    }
}
