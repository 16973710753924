import { Vue } from 'vue-property-decorator';

export interface TitleData {
    /**
     * The name of the component to render in the title bar of the application.
     * The individual pages must emit and event to set this on created.
     *
     * e.g.
     *  this.$parent.$emit('update:titleContent', <component>);
     */
    titleComponentType: typeof Vue | '';
    titleData?: { [prop: string]: any };

    notificationComponentType: typeof Vue | '';
    notificationData?: { [prop: string]: any };
}

/**
 * Opaque data for the selected title component.
 * The individual pages must emit and event to set this on created.
 *
 * IMPORTANT: This data is unlikely to be reactive.
 *
 * e.g.
 *  this.$parent.$emit('update:titleData', <component>);
 */
export type TitleComponent = TitleData;

export default class TitleUtil {
    /**
     * Signal to main application that a view would like a specific title component to
     * be rendered.
     *
     * Every top level view component must call this in their created() method so that
     * the correct component is rendered that corresponds to the main content view
     * area.
     */
    public static $emit(vue: Vue, data: TitleComponent): void {
        vue.$emit('title', data);
    }
}
