
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import CaseStateStepper from '@/components/case/state-stepper/CaseStateStepper.vue';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';

    import { getRequiredSelfUri } from '@/lib/api/SemanticNetworkUtils';

    @Component({ components: { CaseStateStepper } })
    export default class CaseTitle extends Vue {
        /** The case (project) */
        @Prop({ required: true })
        public project!: CaseRepresentation;

        /**
         * Optional configuration to pass to the case stepper.
         * When true, the stepper is in charge of reloading the user surgical template to keep its state synchronized.
         * The user surgical template is used in the stepper to determinate if to show the plan page or not.
         *
         * @see {@link CaseStateStepperController.hasPlans}
         *
         * This adds the ability to turn of the refresh of the surgical template in the case stepper
         * on the 3D planning page, which has its own update lifecycle through a [surgical-template-poller]{@link SurgicalTemplateSynchroniser}
         *
         * @default: true
         */
        @Prop({ required: false, default: true })
        protected refreshUserSurgicalTemplate!: boolean;

        private readonly defaultTitle = '';

        get projectName(): string {
            return this.project?.name ? this.project.name : this.defaultTitle;
        }

        /** :key dependent on the current case so the stepper is re-rendered when the uri changes */
        get caseStepperKey(): string {
            return `case:${getRequiredSelfUri(this.project)}-refreshTemplate:${this.refreshUserSurgicalTemplate}`;
        }
    }
