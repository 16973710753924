import { Link, LinkedRepresentation, LinkUtil, Uri } from 'semantic-link';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { RelationshipType } from 'semantic-link/lib/filter';
import LinkRelation from '@/lib/api/LinkRelation';
import { ApiUtil } from '@/lib/semantic-network';

/**
 * Utility functions that sit on top of semantic-network library.
 *
 * Attempt to reduce boilerplate mainly on resources, and have a more declarative interface
 */

/**
 * Get a resource on a context if there is a link rel for it.
 *
 * If it does not match, returns null;
 */
const getIfLink = async <T extends LinkedRepresentation, TResult extends LinkedRepresentation>(
    context: T, rel: RelationshipType, options?: CacheOptions): Promise<TResult | null> => {
    if (LinkUtil.matches(context, rel)) {
        return await ApiUtil.get<TResult>(context, { rel, ...options }) ?? null;
    }
    return null; // the context has no rel matching
};

/**
 * Get a resource uri that must exist, if not throws an exception
 */
const getRequiredUri = <T extends LinkedRepresentation>(context: T, rel: RelationshipType): Uri | never => {
    const uri = LinkUtil.getUri(context, rel);

    if (uri) {
        return uri;
    }

    throw new Error(`Resource ${context} does not have required uri for ${rel}`);
};

/**
 * Find an item in an array by matching a related uri
 * @param items The array of items
 * @param uri The uri to match
 * @param rel The relationship to get
 */
export function findByUri<T extends LinkedRepresentation>(items: T[], uri: Uri, rel: RelationshipType): T;
/**
 * Find an item in an array by matching a related uri
 * @param items The array of items
 * @param uri The uri to match
 * @param rel The relationship to get
 * @param map Mapping from the given array to linked representation
 */
export function findByUri<T>(
    items: T[],
    uri: Uri,
    rel: RelationshipType,
    map: (context: T) => LinkedRepresentation): T;
export function findByUri<T>(
    items: T[],
    uri: Uri,
    rel: RelationshipType,
    map?: (context: T) => LinkedRepresentation): T | undefined {
    if (map) {
        return items.find((item: T): boolean => {
            return getRequiredUri(map(item), rel) === uri;
        });
    } else {
        return items.find((item: T): boolean => {
            return getRequiredUri(item as LinkedRepresentation, rel) === uri;
        });
    }
}

/**
 * Get a resource uri that must exist, if not throws an exception
 */
const getRequiredLink = <T extends LinkedRepresentation>(context: T, rel: RelationshipType): Link => {
    const link = LinkUtil.getLink(context, rel);

    if (link) {
        return link;
    }

    throw new Error(`Resource ${context} does not have required uri for ${rel}`);
};

/**
 * @return whether two representations have the same uri for the given rel
 *
 * Note: will return true even if the rel is 'undefined' or 'null' on both representations
 */
const haveSameUri = <T extends LinkedRepresentation>(
    representation: T, otherRepresentation: T, rel: RelationshipType): boolean => {
    return LinkUtil.getUri(representation, rel) === LinkUtil.getUri(otherRepresentation, rel);
};

/**
 * @return whether two representations have the same uri for the given rel.
 * @throws if any of the representations does not have the uri
 */
const haveSameRequiredUri = <T extends LinkedRepresentation>(
    representation: T, otherRepresentation: T, rel: RelationshipType): boolean => {
    return getRequiredUri(representation, rel) === getRequiredUri(otherRepresentation, rel);
};

const getRequiredSelfUri = <T extends LinkedRepresentation>(context: T): Uri | never => {
    return getRequiredUri(context, LinkRelation.self);
};

export {
    getIfLink,
    getRequiredUri,
    getRequiredSelfUri,
    haveSameUri,
    haveSameRequiredUri,
    getRequiredLink,
};
