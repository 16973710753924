import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import {
    AngleMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/AngleMeasurementValueRepresentation';
import {
    DistanceMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/DistanceMeasurementValueRepresentation';
import MeasurementsUtil from '@/lib/api/resource/case/study/MeasurementsUtil';
import { MeasurementGroupNames } from '@/lib/api/representation/case/measurements/MeasurementGroupName';
import {
    HipTemplateMeasurementName,
} from '@/lib/api/representation/case/surgical-template/hip/measurements/HipTemplateMeasurementName';
import {
    HipSurgicalTemplateMeasurements,
} from '@/lib/api/resource/case/surgical-template/measurements/HipSurgicalTemplateMeasurements';

/**
 * @deprecated: These measurements are a way to access legacy cases, prior to the new format
 * of separate measurements with 'femoral-groups' or 'acetabular-groups'.
 */
export default class HipLegacySurgicalTemplateMeasurements extends HipSurgicalTemplateMeasurements {
    constructor(
        protected data: MeasurementsRepresentation) {
        super();
    }

    public getStemAnteversion(): AngleMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            this.data, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemAnteversion);
    }

    public getStemResectionLesserTrochanter(): DistanceMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<DistanceMeasurementValueRepresentation>(
            this.data, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemResectionLesserTrochanter);
    }

    public getStemResectionGreaterTrochanter(): DistanceMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<DistanceMeasurementValueRepresentation>(
            this.data, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemResectionGreaterTrochanter);
    }

    public getStemSaddleDistance(): DistanceMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<DistanceMeasurementValueRepresentation>(
            this.data, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemSaddle);
    }

    public getStemLegLengthDifference(): AngleMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            this.data, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemLegLengthDifference);
    }

    public getStemLegOffsetDifference(): AngleMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            this.data, MeasurementGroupNames.HipStem, HipTemplateMeasurementName.StemLegOffsetDifference);
    }

    public getCupAnteversion(): AngleMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            this.data, MeasurementGroupNames.HipCup, HipTemplateMeasurementName.CupAnteversion);
    }

    public getCupLegLengthDifference(): AngleMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            this.data, MeasurementGroupNames.HipCup, HipTemplateMeasurementName.CupLegLengthDifference);
    }

    public getCupLegOffsetDifference(): AngleMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            this.data, MeasurementGroupNames.HipCup, HipTemplateMeasurementName.CupLegOffsetDifference);
    }
}
