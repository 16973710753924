import { Vector3 } from 'three';
import ComponentCoverageUtil from '@/lib/viewer/component-coverage/ComponentCoverageUtil';
import {
    ColorWithOpacity,
    ComponentCoverageStrategy,
    FaceAreaMetric,
    FaceCollisionColorCallback,
    FaceColorOpacity,
} from '@/lib/viewer/component-coverage/types';
import { HipRenderOrder } from '@/hipPlanner/assembly/objects/HipRenderOrder';

export default class CupComponentCoverageUtil extends ComponentCoverageUtil {
    public static makeCoverageStrategy(): ComponentCoverageStrategy {
        return {
            getFaceColor: this.makeCupColorCallback(),
            getRayDirection: this.getRayDirection,
            coverageMeshRenderOrder: HipRenderOrder.CoverageMask,
        };
    }

    /**
     * @return The ray direction in the component local space.
     *
     * Note:
     * ```
     * 1. How cup meshes are defined server-side,
     * ------------------------------------------
     *
     * o = Origin point
     * R = Ray direction on each face of M.
     *
     *        /|                                    A cup
     *      /  |         R <------------   ~ ~ -  ,
     *    /    |                                    ' ,
     *  /      |                        R <-----------  ,
     * |       |                        R <------------  ,
     * |  M    |                                          ,                      Y axis = -R
     * |  E    |                        o ----------------,--------------------------->
     * |  S    |                                          ,
     * |  H    |                        R <------------  ,
     * |       |                        R <------------ ,
     * |       |                   R <------------  , '
     * |      /             R <------------ _ ,  '
     * |    /
     * |  /
     * |/
     *
     * Note: The hemisphere of the cup points towards the positive Y direction.
     *       We want to search in the opposite direction. This direction is related
     *       to how we are filtering the covering mesh.
     */
    private static getRayDirection(): Vector3 {
        return new Vector3(0, -1, 0);
    }

    /**
     * Make a face color rule/function according to implant component type and available distance criteria
     */
    private static makeCupColorCallback(): FaceCollisionColorCallback {
        const faceColor = this.makeComponentCollisionFaceColor();
        return (result: FaceAreaMetric): ColorWithOpacity | null => {
            if (result.intersection.inside) {
                // Set the face collision color to be red (default color) if the face position is inside
                // the collision area, no matter the distance
                return { rgb: faceColor.default, opacity: FaceColorOpacity.Transparent };
            } else {
                return null;
            }
        };
    }
}
