export class EventHandlerUtils {
    /**
     * Return true if the shift and control keys (or command in the case of a mac computer) where held down
     * while clicking
     */
    public static isCtrlShiftClickEventOrSimilar(e: Event): boolean {
        const mouseEvent = e as MouseEvent;
        if (mouseEvent) {
            const { altKey, shiftKey, ctrlKey, metaKey } = mouseEvent;
            // On Windows we use the ctrlKey
            if (!altKey && shiftKey && ctrlKey && !metaKey) {
                return true;
            }

            // On Mac we use the command key
            // CommandKey key is represented as metaKey in mac
            // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/metaKey
            if (!altKey && shiftKey && metaKey) {
                return true;
            }
        }

        return false;
    }
}
