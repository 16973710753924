/**
 * Setup the cup coronal cross-section plane
 *
 * Note: This will add a clipping plane to all the segmented and implant models
 */
import { cupPosition } from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { asVector3 } from '@/lib/base/ThreeUtil';
import { HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { CrossSectionPlane } from '@/lib/planning/cross-section/CrossSectionPlane';
import { CrossSectionPlaneUtils } from '@/lib/planning/cross-section/CrossSectionPlaneUtils';
import { CrossSectionNameEnum } from '@/lib/planning/cross-section/CrossSectionPlaneController';
import { OutlineParams } from '@/lib/planning/cross-section/OutlineParams';

export function makeCupCoronalCrossSection(assembly: HipPlannerAssembly, outlineParams: OutlineParams): CrossSectionPlane {
    const cup = assembly.cup.getCaseComponent<HipCupRepresentation>();
    const normal = asVector3(cup.ap_vector);
    const origin = cupPosition(assembly);

    return CrossSectionPlaneUtils.makePlaneWithOptionalVisibility(
        CrossSectionNameEnum.HipCupCoronal, origin, normal, outlineParams);
}
