/**
 * Sequential support for waiting for one or more promises based on collections items iterator
 * that returns a Promise. This is like Promise.all(), however, this implementation
 * will execute (call) the items sequentially.  The result of the last item is available
 * to the next item.
 *
 */
export async function sequentialWaitAll<T, TR>(collection: T[], action: (item: T) => Promise<TR>, defaultValue: TR): Promise<TR> {
    return collection.reduce<Promise<TR>>(
        (p: Promise<TR>, item: T) => {
            return p.then(() => action(item));
        },
        Promise.resolve<TR>(defaultValue));
}

/**
 * Sequential support for waiting for one or more promises based on collections items iterator
 * that returns a Promise. This is like Promise.all(), however, this implementation
 * will execute (call) the items sequentially.  The result of the last item is available
 * to the next item.
 *
 */
export async function sequentialWaitAllVoid<T>(collection: T[], action: (item: T) => Promise<void>): Promise<void> {
    return collection.reduce<Promise<void>>(
        (p: Promise<void>, item: T) => {
            return p.then(() => action(item));
        },
        Promise.resolve<void>(undefined));
}
