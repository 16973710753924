
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import ArrowFromTo from '@/components/case-manage/surgical-template/history/row/ArrowFromTo.vue';
    import {
        SurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
    import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
    import { SegmentedModelRepresentation } from '@/lib/api/representation/case/study/SegmentedModelRepresentation';
    import LinkRelation from '@/lib/api/LinkRelation';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';

    /**
     * Displays the change in models of the surgical template.
     *
     * Note 1: Currently the surgical template only deals with resected models.
     * Note 2: This is only doing a number comparison of the length of the collection.
     * TODO: compare the actual uri list
     */
    @Component({ components: { ArrowFromTo, HyperlinkButton } })
    export default class ModelChanges extends Vue {
        @Prop({ required: true, validator: notNilValidator('current') })
        private current!: SurgicalTemplateRepresentation;

        @Prop({ required: true, validator: notNilValidator('previous') })
        private previous!: SurgicalTemplateRepresentation;

        /** Export hack for the template */
        protected linkRelation = LinkRelation;

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            if (this.current.models) {
                // nothing to do, they have been already loaded, possibly on other row.
            } else {
                await SurgicalTemplateResource.getModels(
                    this.current, { includeItems: false, ...this.$apiOptions });
            }

            if (this.previous.models) {
                // nothing to do, they have been already loaded, possibly on other row.
            } else {
                await SurgicalTemplateResource.getModels(
                    this.previous, { includeItems: false, ...this.$apiOptions });
            }
        }

        protected get from(): SegmentedModelRepresentation[] {
            return this.previous.models?.items ? this.previous.models?.items : [];
        }

        protected get to(): SegmentedModelRepresentation[] {
            return this.current.models?.items ? this.current.models?.items : [];
        }

        protected get change(): boolean {
            return this.from.length !== this.to.length;
        }
    }
