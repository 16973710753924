import assert from 'assert';
import { AxiosInstance } from 'axios';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { RigidTransform } from '@/lib/base/RigidTransform';
import { watch } from 'vue';
import {
    cupPosition,
    doInNativePosition,
    loadAndReplaceStemAndHead,
    setStemTransform,
} from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import anylogger from 'anylogger';
import HipImplantsMaterialMode from '@/hipPlanner/assembly/controllers/HipImplantsMaterialMode';
import HipCrossSectionPlanes from '@/hipPlanner/assembly/controllers/cross-sections/HipCrossSectionPlanes';
import { HipPlannerViewStore } from '@/hipPlanner/stores/plannerView/hipPlannerView';
import { PlannerMode } from '@/hipPlanner/assembly/controllers/AcidPlannerModeController';
import { Matrix4 } from 'three';
import { logMatrixComparison } from '@/lib/planning/scene/TroubleshootUtil';
import { worldTransform } from '@/lib/base/ThreeUtil';
import { VueObserver } from '@/hipPlanner/assembly/controllers/VueObserver';

const log = anylogger('StemController');

/**
 * Stem controller that creates/filters the selection options (recommended, type/offset, and stem size) lists
 * for the stem toolbar, and shows the selected stem and head models in the Viewer, based on the selected stem option
 */
export default class StemController extends VueObserver {
    constructor(
        private templateStore: HipTemplateStore,
        private plannerStore: HipPlannerStore,
        private viewStore: HipPlannerViewStore,
        private axios: AxiosInstance,
        private assembly: HipPlannerAssembly,
        private implantsMaterial: HipImplantsMaterialMode,
        private crossSectionPlanes: HipCrossSectionPlanes) {
        super();
        this.addWatches(
            watch(
                () => templateStore.stemTransform,
                (value: RigidTransform) => {
                    if (plannerStore.enableStemTransform) {
                        if (!assembly.isInNativePosition) {
                            // This warning tries to identify if this scenario occurs very often,
                            // The code below (`doInNativePosition`) will compensate for it,
                            // but ideally we would like to know why this happens.
                            log.warn('Attempt to set the stem transform when not in native position.');
                        }
                        // Only apply the transformation if we are in native arrangement
                        doInNativePosition(
                            'updating stem transform',
                            assembly,
                            () => setStemTransform(assembly, value)
                        );
                    } else {
                        log.debug('MSP is disabled. Skipping setStemTransform() on stem transform change');
                    }
                }, { deep: true }),
        );

        if (plannerStore.enableStemTransform) {
            // When the stem controller is initialised the stem transform from the server side is applied to the assembly
            doInNativePosition(
                'setting stem-transform',
                assembly,
                () => setStemTransform(assembly, templateStore.stemTransform)
            );
        } else {
            log.debug('MSP is disabled. Skipping setStemTransform() on stem controller initialization');
        }
    }

    public async updateSelectedStem(resetStemTransform = true): Promise<void> {
        const currentStem = this.templateStore.stem;
        const currentHead = this.templateStore.head;
        const headOffset = this.templateStore.currentHeadOffset;

        assert.ok(currentStem, 'currentStem must be defined');
        assert.ok(currentHead, 'currentHead must be defined');
        assert.ok(headOffset !== null, 'currentHeadOffset cannot be null');

        await loadAndReplaceStemAndHead(
            this.assembly,
            this.axios,
            currentStem,
            currentHead,
            resetStemTransform
        );

        if(this.viewStore.mode === PlannerMode.Cup) {
            this.assembly.stem.theObject.visible = false;
            this.assembly.head.theObject.visible = false;
        }

        if (resetStemTransform) {
            logStemTransformComparison('reset transform', this.assembly, currentStem.headTransform);
        }

        // Note: This is to support adjustments on cases prior to MSP
        //
        // For simplicity, given all the data is available here,
        // this side effect to the store is done here instead of in AdjustmentCalculator,
        // which will require passing in the vue event payload the components and headOffset.
        this.plannerStore.calculateAdjustmentsStemOld(currentStem, currentHead, headOffset);

        this.plannerStore.calculateAdjustments(this.assembly);
        this.implantsMaterial.onAssemblyChange(this.assembly);
        this.crossSectionPlanes.onStemUpdated(this.assembly);
        this.crossSectionPlanes.onCupAssemblyChange(this.assembly);
    }

}

export function logStemTransformComparison(
    reason: string, assembly: HipPlannerAssembly, headComponentTransform: Matrix4) {
    if (!assembly.isInNativePosition) {
        headComponentTransform.setPosition(cupPosition(assembly));
    }
    logMatrixComparison(`stem-transform ${reason}`, headComponentTransform, worldTransform(assembly.head.theObject));
}
