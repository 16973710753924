/** Sorting value used by the Array's sort method */
export type ArraySortValue = 1 | -1 | 0;

/**
 * Collection of list sorting utilities
 */
export class SortingUtil {
    /**
     * Soft items numerically
     */
    public static sortNumericString(size1?: string | number, size2?: string | number): ArraySortValue {
        const s1 = (typeof size1 === 'string') ? parseFloat(size1 || '') : size1 || 0;
        const s2 = (typeof size2 === 'string') ? parseFloat(size2 || '') : size2 || 0;

        if (s1 > s2) {
            return 1;
        } else if (s1 < s2) {
            return -1;
        } else {
            return 0;
        }
    }

    /**
     * Sort items alphanumerically
     */
    public static sortAlphanumeric(item1: string, item2: string): ArraySortValue {
        if (item1 > item2) {
            return 1;
        } else if (item1 < item2) {
            return -1;
        } else {
            return 0;
        }
    }
}
