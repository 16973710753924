import ulog, { LogLevel } from 'ulog';
import { applicationVersion } from '@/lib/version';
import AnyLoggerUlogAdapter from '@/lib/logging/AnyLoggerUlogAdapter';
import anylogger from 'anylogger';

const log = anylogger('acid:logging');

export default class Logging {
    /**
     * Logging is implemented by 'ulog'. All code uses the 'anylogger' facade as an interface to the
     * logging framework. The use of ulog should be able to replaced/replugged here in this module if
     * required.
     *
     * The ulog setup is a bit magical and it can not be defaulted based on any code criteria. This code
     * will leave the log level to it's default of WARN in deployed environments. It will set the level to
     * DEBUG in development environments iff no other explicit configuration is detected.
     */
    public static setupLogging(): void {
        const logLevel = Logging.getLogLevel();
        if (logLevel) {
            ulog.level = logLevel;
            log.info('Logging level set to \'%s\'', logLevel);
        }
        AnyLoggerUlogAdapter.init();
    }

    /**
     * Determine the level of logging to use.
     *
     * The default for ulog is 'WARN'. This is useful for deployed
     * situations. For developer situation we probably want to take the
     * performance hit and get a little more logging. This logs can be filtered
     * with the browser debugger.
     *
     * For developer environments, where the log level has not been set via the URL query parameter or
     * from local storage, the log level will be set to 'DEBUG'.
     *
     * In deployed environments to get a practical level of logging, add the URL parameter:
     *      'log=debug'  (sans quotes)
     *      'debug=*'  (sans quotes)
     * to change the logging level. e.g.
     *      https://app.dev.formuslabs.com?debug=*#/
     *      https://app.qa.formuslabs.com?debug=*#/
     *      https://app.trial.formuslabs.com?debug=*#/
     *
     * @see {@link https://github.com/Download/ulog}
     */
    public static getLogLevel(): LogLevel | undefined {
        const version = applicationVersion();
        if (version) {
            // Leave the configuration at default settings are the user may have set the configuration
            // via the URL query string or local storage. The default logging level is WARN which is appropriate
            // for deployed environments.
            return undefined;
        } else {
            if (!Logging.loggingLevelConfiguration()) {
                return ulog.TRACE;
            }
            return undefined;
        }
    }

    /**
     * Take a best guess what the ulog configuration is.  There is little chance to plug into the logging
     * configuration chain, so this adhoc strategy tries to detect if the logging is at default configuration
     * values.
     */
    private static loggingLevelConfiguration(): string | undefined {
        const query = location.search.substring(1);
        const queryArgs: string[] = (query?.split('&')) || [];
        const logLevel = this.getLocalStorageLogLevel();
        if (logLevel !== undefined && logLevel != null) {
            return logLevel;
        }

        const logString = queryArgs
            .map<string[]>((arg) => arg ? arg.split('=') : [arg])
            .find((arg) => arg[0] === 'log');
        if (logString && logString.length === 2 && logString[1]) {
            return logString[1];
        }
        return undefined;
    }

    private static getLocalStorageLogLevel(): string | undefined {
        try {
            return localStorage.getItem('log') || undefined;
        } catch (e) {
            // eat the value
            return undefined;
        }
    }
}
