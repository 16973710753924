import {
    HipCupCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipCupCatalogComponentRepresentation';
import {
    HipStemCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
import {
    HipLinerCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipLinerCatalogComponentRepresentation';
import { ComponentsCatalogRepresentation } from '@/lib/api/representation/catalog/ComponentsCatalogRepresentation';
import {
    HipStemHeadCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemHeadCatalogComponentRepresentation';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { ApiUtil } from '@/lib/semantic-network';
import LinkRelation from '@/lib/api/LinkRelation';
import { getRequiredSelfUri } from '@/lib/api/SemanticNetworkUtils';

/**
 * The hip components catalog
 *
 * It should exist a virtual collection attribute for each hip component available.
 *
 * This representation will be used, to load the components from the catalog.
 * Given the catalog components are not in the context of a case, it makes sense to have a list of resources
 * where we pull the components from.
 */
export interface HipComponentsCatalogRepresentation extends ComponentsCatalogRepresentation {
    /**
     * A client side collection of the catalog cups components
     */
    cups: HipCupCatalogComponentRepresentation[];

    /**
     * A client side collection of the stem head components
     */
    heads: HipStemHeadCatalogComponentRepresentation[];

    /**
     * A client side collection of the catalog hip cup liner components
     */
    liners: HipLinerCatalogComponentRepresentation[];

    /**
     * A client side collection of the catalog stem components
     */
    stems: HipStemCatalogComponentRepresentation[];
}

/**
 * Type for properties of ShoulderComponentsCatalogRepresentation which are collections
 */
export type HipCatalogCollectionName = keyof Omit<HipComponentsCatalogRepresentation, 'links'>;

export type CatalogComponentType<Name extends HipCatalogCollectionName> =
    HipComponentsCatalogRepresentation[Name] extends readonly (infer ComponentType)[] ? ComponentType: never;

/**
 * GET the hip components catalog
 */
export async function getHipComponentsCatalog(
    apiResource: ApiRepresentation, options?: CacheOptions):
    Promise<HipComponentsCatalogRepresentation | never> {
    const componentsCatalog = await ApiUtil.get<HipComponentsCatalogRepresentation>(
        apiResource, { rel: LinkRelation.hipComponentsCatalogue, ...options, name: 'hipComponentsCatalog' });
    if (componentsCatalog) {
        return componentsCatalog;
    } else {
        throw new Error('No Hip Components Catalog available');
    }
}

export function findComponentByUri<T extends HipCatalogCollectionName>(
    componentsCatalog: HipComponentsCatalogRepresentation,
    collectionName: T,
    uri: string): CatalogComponentType<T> | null {
    const components = componentsCatalog[collectionName];
    return components.find((component) => getRequiredSelfUri(component) === uri) as CatalogComponentType<T> ?? null;
}
