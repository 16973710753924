import {
    StudyLandmarksRepresentation,
} from '@/lib/api/representation/case/study/landmarks/StudyLandmarksRepresentation';

export interface StudyLandmarksState {
    landmarks: StudyLandmarksRepresentation | null,
}

export enum LandmarkGroup {
    RightFemur = 'right-femur',
    LeftFemur = 'left-femur',
    RightHemi = 'right-hemi',
    LeftHemi = 'left-hemi',
    Pelvis = 'pelvis',
}

export enum Landmark {
    HeadCentre = 'femur-hc',
    MedialEpicondyle = 'femur-mec',
    LateralEpicondyle = 'femur-lec',
    GreaterTrochanter = 'femur-gt',
    GreaterTrochanterResection = 'femur-gt-resection',
    GreaterTrochanterAnterior = 'femur-gtant',
    LesserTrochanter = 'femur-lt',
    KneeCentre = 'femur-kc',
    NeckSaddle = 'femur-neck-saddle',
    LesserTrochanterResection = 'femur-lt-resection',
    AcetabularCentre = 'hemipelvis-ac',
    AcetabularNotch = 'hemipelvis-an',
    HemiPelvisASIS = 'hemipelvis-asis',
    HemiPelvisPSIS = 'hemipelvis-psis',
    PubicSymphysis = 'hemipelvis-ps',
    PubicTuberosity = 'hemipelvis-pt',
    IschialSpine = 'hemipelvis-is',
    IschialTuberosity = 'hemipelvis-it',
    AnteriorAcetabularTransverseLigament = 'hemipelvis-atrans',
    PosteriorAcetabularTransverseLigament = 'hemipelvis-ptrans',
    HemiPelvisFossa = 'hemipelvis-fossa',
    AcetabularRimSuperior = 'hemipelvis-acetabular-rim-superior',
    AcetabularRimAnterior = 'hemipelvis-acetabular-rim-anterior',
    AcetabularRimPosterior = 'hemipelvis-acetabular-rim-posterior',
    AcetabularCentreLeft = 'pelvis-lac',
    AcetabularCentreRight = 'pelvis-rac',
    AcetabularNotchLeft = 'pelvis-lan',
    AcetabularNotchRight = 'pelvis-ran',
    PelvisASISLeft = 'pelvis-lasis',
    PelvisASISRight = 'pelvis-rasis',
    PelvisPSISLeft = 'pelvis-lpsis',
    PelvisPSISRight = 'pelvis-rpsis',
    PubisSymphysisLeft = 'pelvis-lps',
    PubisSymphysisRight = 'pelvis-rps',
    PubicTuberosityLeft = 'pelvis-lpt',
    PubicTuberosityRight = 'pelvis-rpt',
    IschialSpineLeft = 'pelvis-lis',
    IschialSpineRight = 'pelvis-ris',
    IschialTuberosityLeft = 'pelvis-lit',
    IschialTuberosityRight = 'pelvis-rit',
    AnteriorAcetabularTransverseLigamentLeft = 'pelvis-latrans',
    AnteriorAcetabularTransverseLigamentRight = 'pelvis-ratrans',
    PosteriorAcetabularTransverseLigamentLeft = 'pelvis-lptrans',
    PosteriorAcetabularTransverseLigamentRight = 'pelvis-rptrans',
    PelvisFossaLeft = 'pelvis-lfossa',
    PelvisFossaRight = 'pelvis-rfossa',
}
