import assert from 'assert';
import { DoubleSide, Matrix4, Mesh, MeshBasicMaterial, SphereBufferGeometry, Vector3 } from 'three';
import {
    cupWorldPosition,
    getAcetabularGroupPosition,
    orientCupGroupAnatomic,
    setCupPosition,
    updateCupAndLiner,
} from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { loadImplant } from '@/hipPlanner/assembly/controllers/implantLoading';

import { HipLinerRepresentation } from '@/lib/api/representation/case/hip/HipLinerRepresentation';

import anylogger from 'anylogger';
import { HipImplantAlias } from '@/hipPlanner/assembly/objects/HipImplantAlias';
import { isRotational, positionalPart, rotationalPart } from '@/lib/base/RigidTransform';
import { formatArrayNumber } from '@/lib/filters/format/formatArrayNumber';
import { asVector3 } from '@/lib/base/ThreeUtil';
import { addVectors } from '@/lib/geometry/vector3';
import { AxiosInstance } from 'axios';
import { SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import plannerEventBus from '@/lib/planning/events/PlannerEventBus';
import { PlannerEvent } from '@/lib/planning/events/PlannerEvent';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { ACS } from '@/lib/base/CoordinateSystem';
import { watch } from 'vue';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { AnatomicalCoordinateSystem, BodySide } from '@/lib/api/representation/interfaces';
import { VueObserver } from '@/hipPlanner/assembly/controllers/VueObserver';
import { validationLogger } from '@/lib/logging/validationLogging';
import { formatFloat, formatVector } from '@/lib/base/formatMath';

const log = anylogger('CupController');
const logValidation = validationLogger(log);

const SHOW_DEBUG_OBJECTS = false;

/**
 * Controller class for cup selection and adjustment. Orchestrate the calls to the assembly,
 * by converting the global-cs vectors into the scene coordinate system.
 */
export default class CupController extends VueObserver {
    constructor(
        private templateStore: HipTemplateStore,
        private plannerStore: HipPlannerStore,
        private assembly: HipPlannerAssembly,
        private sceneAssembly: SceneAssembly,
        private axios: AxiosInstance) {
        super();
        this.addWatches(
            watch(
                () => templateStore.cupRotation,
                () => {
                    this.updateCupRotation();
                    plannerEventBus.$emit(PlannerEvent.HipPlannerCupAssemblyMoved, this.assembly);
                },
            ),
            watch(
                () => templateStore.cupOffset,
                () => {
                    this.updateCupOffset();
                    plannerEventBus.$emit(PlannerEvent.HipPlannerCupAssemblyMoved, this.assembly);
                }),
            watch(
                () => [
                    templateStore.cup,
                    templateStore.liner,
                ],
                () => this.updateCupAndLinerModels(),
                { immediate: true },
            ),
            watch(
                () => plannerStore.alignmentCoords,
                this.updateAlignmentCoords.bind(this),
                { immediate: true },
            ),
        );
    }

    private get globalCS(): ACS {
        return this.plannerStore.alignmentCoords;
    }

    /**
     * Update the cup and liner models and their transform in the hip-planner assembly
     */
    private async updateCupAndLinerModels(): Promise<void> {
        const cup = this.templateStore.cup;
        const liner = this.templateStore.liner;

        assert.ok(cup !== null, 'cup cannot be null');
        assert.ok(liner !== null, 'liner cannot be null');

        // Load the cup model
        log.info('Changing cup to size %s', cup.outer_diameter);
        const cupModel = await loadImplant(this.axios, cup, HipImplantAlias.Cup);
        log.debug('cup transformation %o', cupModel.theObject.matrixWorld);

        // Load the liner model
        const linerModel = await loadImplant(this.axios, liner, HipImplantAlias.Liner);

        // Update the cup and liner models in the assembly
        const groupPosition = getAcetabularGroupPosition(liner);

        updateCupAndLiner(this.assembly, groupPosition, cupModel, linerModel);
        this.updateCupOffset();
        this.updateCupRotation();

        if (SHOW_DEBUG_OBJECTS && this.assembly.cup) {
            this.visualizeCupPoints();
        }

        plannerEventBus.$emit(PlannerEvent.HipPlannerCupAssemblyCupSet, this.assembly);
    }

    /**
     * Change the position of the cup relative to the native joint centre.
     */
    private updateCupOffset(): void {
        const offset = this.templateStore.cupOffset;
        const native_hjc = asVector3(this.globalCS.origin);
        const siVector = asVector3(this.globalCS.si.vector);
        const apVector = asVector3(this.globalCS.ap.vector);
        const mlVector = asVector3(this.globalCS.ml.vector);
        const cupPosition = addVectors(
            native_hjc,
            siVector.clone().multiplyScalar(offset.si),
            mlVector.clone().multiplyScalar(offset.ml),
            apVector.clone().multiplyScalar(offset.ap)
        );

        logValidation([
            'Cup position calculation:',
            `  native hjc: ${formatVector(native_hjc)}`,
            `  offsets: si: ${formatFloat(offset.si)}  ap: ${formatFloat(offset.ap)}  ml: ${formatFloat(offset.ml)}`,
            '  anatomical basis:',
            `    si: ${formatVector(siVector)}`,
            `    ap: ${formatVector(apVector)}`,
            `    ml: ${formatVector(mlVector)}`,
            `  cup-position: ${formatVector(cupPosition)}`,
        ].join('\n'));

        setCupPosition(this.assembly, cupPosition);
    }

    /**
     * Change the orientation of the cup
     */
    private updateCupRotation(): void {
        const rotation = this.templateStore.cupRotation;
        orientCupGroupAnatomic(
            this.assembly,
            rotation.anteversion,
            rotation.inclination,
            asVector3(this.globalCS.si.vector),
            asVector3(this.globalCS.ap.vector));
    }

    /**
     * Visualize all the point of interest of the cup and logs its position in the world space.
     * - Cup world position after [shifting using the Global CS]{@link updateCupOffset}.
     * - Cup liner head centre.
     * - Cup origin based on the Tmatrix.
     * - Cup assembly point.
     *
     * @see {@link }https://miro.com/app/board/o9J_l2Tpg4Y=/}
     */
    private visualizeCupPoints(): void {
        const makeSpherePoint = (radius: number, color: string) => {
            const geometry = new SphereBufferGeometry(
                radius, 10, 10, 0, Math.PI * 2, 0, Math.PI);
            return new Mesh(geometry, new MeshBasicMaterial(
                { transparent: true, opacity: 0.7, side: DoubleSide, vertexColors: true, color }));
        };

        const addPointToScene = (radius: number, color: string, position: Vector3) => {
            const point = makeSpherePoint(radius, color);
            point.position.set(position.x, position.y, position.z);
            this.sceneAssembly.scene.add(point);
        };

        const liner = this.assembly.liner;
        const cup = this.assembly.cup;
        const linerBucket = liner.getCaseComponent() as HipLinerRepresentation;

        const linerHeadCentre = asVector3(linerBucket.head_centre);
        const cupWorldPositionAfterShiftCup3D = cup.worldPosition;
        const cupOriginInTMatrix = asVector3(positionalPart(cup.objectMatrix));
        const cupAssemblyPosition = cupWorldPosition(this.assembly);

        addPointToScene(2, 'green', cupWorldPositionAfterShiftCup3D);
        addPointToScene(2, 'blue', linerHeadCentre);
        addPointToScene(2, 'red', cupOriginInTMatrix);
        addPointToScene(2, 'yellow', cupAssemblyPosition);

        log.info(
            'Current cup position (green): %s, \n' +
            'Liner head centre (blue):     %s, \n' +
            'Cup origin in T matrix (red): %s, \n' +
            'Cup assembly (yellow):        %s',
            formatArrayNumber(cupWorldPositionAfterShiftCup3D.toArray(), 4),
            formatArrayNumber(linerHeadCentre.toArray(), 4),
            formatArrayNumber(cupOriginInTMatrix.toArray(), 4),
            formatArrayNumber(cupAssemblyPosition.toArray(), 4));
    }

    private updateAlignmentCoords(coords: AnatomicalCoordinateSystem): void {
        const ml = asVector3(coords.ml.vector);
        const left = this.assembly.side === BodySide.Left ? ml : ml.negate();
        const posterior = asVector3(coords.ap.vector);
        const superior = asVector3(coords.si.vector).negate();
        const origin = asVector3(coords.origin);

        if (!isRotational(rotationalPart(new Matrix4().makeBasis(left, posterior, superior)))) {
            log.warn('Alignment coordinates are not orthonormal');
        }

        logValidation([
            'Alignment coordinates:',
            `  left (${left ? 'lateral' : 'medial'}): ${formatVector(left)}`,
            `  posterior: ${formatVector(posterior)}`,
            `  superior: ${formatVector(superior)}`,
            `  origin: ${formatVector(origin)}`,
        ].join('\n'));

        this.assembly.alignmentCoordinates.worldTransform =
            new Matrix4().makeBasis(left, posterior, superior).setPosition(origin);
        this.assembly.ctCoordinates.worldTransform =
            new Matrix4().setPosition(origin);
    }
}
