
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import SurgicalSpecificationResource
        from '@/lib/api/resource/case/surgical-specification/SurgicalSpecificationResource';
    import {
        SurgicalSpecificationRepresentation,
    } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
    import { ProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';
    import SurgicalSpecification from '@/components/case-manage/SurgicalSpecification.vue';

    /**
     * A top level page with a set of v-cards with plans for the case (project).
     *
     * Each project will have zero or more plans. After the surgical details and the
     * DICOM have been configured, segmentation and other processing is done that will
     * create an initial 'ACID' plan with the automated plan. The surgeon can then modify
     * the parameters of the case to approve additional plans.
     *
     * Note: This component is responsible for loading data. Other than the skeleton loader it has no
     * visible elements.
     */
    @Component({ components: { SurgicalSpecification } })
    export default class SurgicalSpecificationLoader extends Vue {
        @Prop({ required: true })
        public readonly value!: SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>;

        /** The URI of the active 'user' surgical template surgical specification */
        @Prop({ required: true })
        public userSurgicalSpecificationUri!: string | null;

        /** whether the main list of plans (and the project) is being loaded */
        private loading = true;

        @IsLoading('loading')
        protected async onIntersect(
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
            isIntersecting: boolean): Promise<void> {
                if (isIntersecting) {
                    await SurgicalSpecificationResource.getSpecification(this.value, {
                        ...this.$apiOptions,
                        forceLoad: true,
                    });
                }
            }
    }
