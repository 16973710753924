import assert from 'assert';
import { ArrowHelper, Matrix4, Vector3 } from 'three';
import SpriteText from 'three-spritetext';
import { asVector3, AsVector3, worldTransform } from '@/lib/base/ThreeUtil';
import { AcidObject3dBase } from '@/lib/planning/objects-3D/AcidObject3d';
import anylogger from 'anylogger';

const log = anylogger('axis-object');

/** Properties of a line-segment given by an optional origin, and *either*:
 *  - A direction vector and length
 *  - An end-point
 *  */
export type LineSegmentProperties = {
    /** Start of a line-segment as a point. If undefined it is assumed to be [0, 0, 0] */
    origin?: Vector3

    /** Direction of the line-segment as a direction-vector from its origin. */
    dir?: Vector3

    /** Length of the line-segment as a distance from its origin. */
    length?: number

    /** End of the line-segment as a point */
    end?: Vector3

}

export type AxisDisplayProperties = {
    name?: string
    visible?: boolean
    textHeight?: number
    color?: string
    headLength?: number
    headWidth?: number
}

export type AxisProperties = AxisDisplayProperties & LineSegmentProperties;

export default class AxisObject {
    private readonly _object: ArrowHelper;

    constructor(properties: AxisProperties = {}) {
        let { dir, length } = properties;
        const origin = properties.origin || new Vector3();

        // Check if the 'end' property is present, and if so calculate dir and length from it
        if (properties.end) {
            assert.ok(!!dir && !!length,
                'Ambiguous properties for axis: direction/length and end both given');
            [dir, length] = calculateDirAndLength(origin, properties.end);
        }

        this._object = new ArrowHelper(
            dir,
            origin,
            length,
            properties.color,
            properties.headLength ?? 3.0,
            properties.headWidth ?? 2.0,
        );
        this._object.visible = properties.visible ?? true;
        if (properties.name) {
            this._object.name = properties.name;
            const text = new SpriteText(properties.name, properties.textHeight ?? 5, properties.color ?? 'white');
            text.visible = true;
            this._object.add(text);
        }
    }

    public get object(): ArrowHelper {
        return this._object;
    }

    public get visible(): boolean {
        return this._object.visible;
    }

    public set visible(value: boolean) {
        this._object.visible = value;
    }

    public get matrix(): Matrix4 {
        return this._object.matrix;
    }

    public get name(): string {
        return this._object.name;
    }

    /**
     * The direction that the axis is pointed, in world-space
     */
    get worldDirection(): Vector3 {
        return this.localDirection.transformDirection(worldTransform(this._object));
    }

    /**
     * The direction that the axis is pointed, in local-space
     *
     * By three-js convention points this is always in the y-direction
     */
    get localDirection(): Vector3 {
        return new Vector3().setY(1);
    }
}

const DEFAULT_AXIS_COLOR = '#cccccc';
const DEFAULT_AXIS_VISIBLE = true;
const DEFAULT_AXIS_LENGTH = 30;

/**
 * Attach an axis to the given object.
 */
export function attachAxis(
    parent: AcidObject3dBase,
    origin: AsVector3,
    dir: AsVector3,
    options?: {
        name?: string,
        color?: string,
        visible?: boolean
        length?: number,
    }): AxisObject {
    const name = options?.name ?? 'axis';
    log.debug('Adding %s to %s', name, parent.name);
    const axis = new AxisObject({
        name,
        origin: asVector3(origin),
        dir: asVector3(dir),
        color: options?.color ?? DEFAULT_AXIS_COLOR,
        visible: options?.visible ?? DEFAULT_AXIS_VISIBLE,
        length: options?.length ?? DEFAULT_AXIS_LENGTH,
    });
    parent.theObject.attach(axis.object);
    return axis;
}

/**
 * Calculate the direction and length of a vector given its origin and end points
 */
function calculateDirAndLength(origin: Vector3, end: Vector3): [Vector3, number] {
    const dir = end.clone().sub(origin);
    const length = dir.length();
    dir.normalize();
    return [dir, length];
}
