/**
 * The function being attributed/annotated can be of any type.
 */
export type AnyAsyncFunction = (...params: unknown[]) => Promise<unknown>;

type _Decorator<T, K extends keyof T> = (target: T, propertyKey: K, descriptor: TypedPropertyDescriptor<AnyAsyncFunction>) => void;

/**
 * A method decorator to set a boolean while the method is being called/is executing. The method
 * being decorated must be an async method (i.e. it must return a Promise).
 *
 * This is intended to be used to support setting a boolean on a Vue component for the
 * `isLoading` boolean that is then put on the 'loading' attribute of a v-card.
 *
 * @example
 * <code>
 *   <template>
 *      <v-card :loading="isLoading">
 *          <...>
 *      </v-card>
 *   </template>
 *   protected isLoading: boolean = false;
 *
 *   \@IsLoading('isLoading')
 *   protected async created(): Promise<void> {
 *      await CaseStudyResource.getStudy(this.study);
 *   }
 *   </code>
 *
 * @param loadingVariable a string with the name of a `boolean` member variable that is
 *      set to true while the function is executing. Typically this will be the value 'isLoading'.
 * @see {@link https://stackoverflow.com/questions/47512544/typescript-decorate-async-function}
 * @see {@link https://www.typescriptlang.org/docs/handbook/decorators.html}
 * @see {@link MethodDecorator}
 * @see {@link createDecorator}
 */
export function IsLoading<T, K extends keyof T>(loadingVariable: keyof T): any {
    // The returned function intercepts the original method being attributed.
    return (target: T, propertyKey: K, descriptor: TypedPropertyDescriptor<AnyAsyncFunction>) => {
        // Save the original method being decorated.
        const originalAsyncMethodImplementation = descriptor.value as AnyAsyncFunction;

        // Define a new method implementation that wraps the original implementation but
        // sets the referenced variable to true before the call, and false after the call.
        descriptor.value = async function(...params: unknown[]): Promise<unknown> {
            // Set the boolean variable to true. This code is rather ugly in terms of typing. The
            // actual code is a simple boolean assignment, but the types need coercion.
            // @ts-ignore I have tried to get this type safe, but haven't managed to yet
            (this as T)[loadingVariable] = true;
            try {
                // Call the original async method.
                return await originalAsyncMethodImplementation.apply(this, params as unknown as unknown[]);
            } finally {
                // @ts-ignore I have tried to get this type safe, but haven't managed to yet
                (this as T)[loadingVariable] = false;
            }
        };
    };
}
