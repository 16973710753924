
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
    import PlanResource from '@/lib/api/resource/case/plan/PlanResource';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import PlanReportDownloadService from '@/components/case-plan/services/PlanReportDownloadService';
    import { PlanState } from '@/lib/api/representation/case/plan/PlanState';
    import { hasReport, getReportTitle, getReportUri } from '@/lib/api/representation/case/plan/planRepresentationUtil';

    /**
     * A simple administration view of a plan. Display enough information to allow support
     * staff and developers access to the plans quickly.
     */
    @Component({ components: { HyperlinkButton } })
    export default class Plan extends Vue {
        @Prop({ required: true })
        public value!: PlanRepresentation;

        protected isLoading = false;
        protected isDownloading = false;

        protected planDownloadService = new PlanReportDownloadService(this.$http);

        protected planState = PlanState;

        protected get reportLink(): string {
            return getReportUri(this.value);
        }

        protected get reportTitle(): string {
            return getReportTitle(this.value);
        }

        protected get hasReport(): boolean {
            return hasReport(this.value);
        }

        protected async created(): Promise<void> {
            this.isLoading = true;
            try {
                // materialise the plan (the plan resource will have been sparsely loaded to start with)
                await PlanResource.getPlan(this.value, this.$apiOptions);
            } finally {
                this.isLoading = false;
            }
        }

        @IsLoading('isDownloading')
        protected async onDownloadReport(): Promise<void> {
            await this.planDownloadService.download(this.value);
        }

        /**
         * Called by template to duplicate the plan. This will force the processing to be re-run and a new
         * plan to be created.
         */
        protected async onDuplicate(): Promise<void> {
            // await CaseSurgicalTemplateResource.createDuplicateAcidSurgicalTemplate(
            //         this.project,
            //         this.$apiOptions);
        }

        protected get selfUri(): string {
            return getRequiredUri(this.value, LinkRelation.self);
        }
    }
