
    import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

    @Component
    export default class AppInputDate extends Vue {
        @VModel({type: String}) text!: string;
        @Prop(String) readonly error?: string;
        @Prop(Boolean) readonly disabled?: boolean;
        @Prop(Boolean) readonly nonEditable?: boolean;
    }
