
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';

    @Component
    export default class PlanItemDetailsItem extends Vue {
        @Prop({ required: true, validator: notNilValidator('label') })
        public label!: string;

        @Prop({ required: false, validator: notNilValidator('value') })
        public value!: string;
    }
