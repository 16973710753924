export enum BreakIdlenessCustomEvents {
    /** Fire this event on the 'window' object to 'break' the idleness */
    RestartTimer = 'restart-idle-timer',
}

/**
 * The events on the window object that are tracked as user activity while in the [Active State]{@link IdleTimerState.Active}
 * Whenever any of these events is fired on the windows object, the user is considered active, and the
 * expiration time is reset.
 *
 * Note: These event need to be fired on the 'window' object to 'break' the idleness, and restart the timer.
 */
export const USER_ACTIVITY_EVENTS_WHILE_IN_ACTIVE_STATE = [
    // These events will be automatically fired by the browser.
    'mousemove',
    'scroll',
    'keydown',
    'touchstart',

    /**
     * Custom events that can be fired by the application code.
     * If you need to customize these events in the future, add this as a configurationin {@link IdleTimerConfig}
     * For simplicity this event is hardcoded here.
     */
    BreakIdlenessCustomEvents.RestartTimer,
];
/**
 * The events on the window object that are tracked as user activity while
 * in the [Active State]{@link IdleTimerState.BeforeTimeout}.
 *
 * Note: Once the [dialog]{@link InactivityDialog} is shown the UX decision is to hide the dialog only when the
 * user clicks the button that says 'I'm back'.
 *
 * Whenever any of these events is fired on the windows object, the user is considered active, and the
 * expiration time is reset.
 */
export const USER_ACTIVITY_EVENTS_WHILE_IN_BEFORE_TIMEOUT_STATE = [
    /**
     * Custom events that can be fired by the application code.
     * If you need to customize these events in the future, add this as a configurationin {@link IdleTimerConfig}
     * For simplicity this event is hardcoded here.
     */
    BreakIdlenessCustomEvents.RestartTimer,
];

/** Dispatch an event to restart the idle timer */
export function dispatchRestartIdleTimer(): void {
    window.dispatchEvent(new Event(BreakIdlenessCustomEvents.RestartTimer));
}
