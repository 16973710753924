import AcidObject3d, { AcidObject3dType, isBone, ObjectProperties } from '@/lib/planning/objects-3D/AcidObject3d';
import { hipPlannerModeObjectSettingKey } from '@/hipPlanner/assembly/controllers/AcidPlannerModeSettings';
import assert from 'assert';

type BoneProperties = ObjectProperties & {
    type: AcidObject3dType.BoneInner | AcidObject3dType.Bone
    alias: hipPlannerModeObjectSettingKey
};

export default class Bone3d extends AcidObject3d {
    constructor(properties: BoneProperties) {
        super(properties);
        assert.ok(isBone(properties.type));
    }
}
