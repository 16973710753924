import ResourceService from '@/lib/api/ResourceService';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import {
    StudyLandmarksRepresentation,
} from '@/lib/api/representation/case/study/landmarks/StudyLandmarksRepresentation';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { LinkUtil } from 'semantic-link';
import { ApiUtil } from '@/lib/semantic-network';

export default class LandmarksResource implements ResourceService {
    public static async getStudyLandmarks(studyResource: StudyRepresentation, options?: CacheOptions):
        Promise<StudyLandmarksRepresentation | null> {
        if (LinkUtil.matches(studyResource, LinkRelation.landmarks)) {
            return await ApiUtil.get<StudyLandmarksRepresentation>(
                studyResource, { rel: LinkRelation.landmarks, ...options }) ?? null;
        }
        return null;
    }
}
