import {
    PATIENT_DATE_OF_BIRTH_FORMAT,
    PatientRepresentation,
    PatientSex,
    PatientSexType,
} from '@/lib/api/representation/PatientRepresentation';
import { DicomInfo } from '@/lib/dicom/DicomInfo';
import { NameRepresentationUtil } from '@/components/case-settings/utils/NameRepresentationUtil';
import StringUtil from '@/lib/base/StringUtils';
import { NameRepresentation } from '@/lib/api/representation/NameRepresentation';
import { DicomPatientSexValues } from '@/lib/dicom/DicomSeriesUtil';
import { DateTime } from 'luxon';

export enum PatientComparisonErrorType {
    Name = 'name',
    DateOfBirth = 'date-of-birth',
    Sex = 'sex',
}

export interface PatientComparisonError {
    type: PatientComparisonErrorType,
    values: unknown[],
}

export interface PatientComparison {
    isSame: boolean,
    patient: PatientRepresentation,
    anotherPatient: PatientRepresentation,
    errorFields?: PatientComparisonError[]
}

export class PatientComparisonUtil {
    /** Maps the patient details in a dicom file to the client/api {@link PatientRepresentation} */
    public static fromDicom(dicomInfo: DicomInfo): PatientRepresentation {
        const mapPatientName = (info: DicomInfo): NameRepresentation | null => {
            return NameRepresentationUtil.fromDicom(info.patientName ?? null);
        };

        const mapPatientSex = (info: DicomInfo): PatientSexType | null => {
            switch (info.patientSex) {
                case DicomPatientSexValues.Male:
                    return PatientSex.Male;

                case DicomPatientSexValues.Female:
                    return PatientSex.Female;

                case DicomPatientSexValues.Other:
                    return PatientSex.Other;

                default:
                    return null;
            }
        };

        const mapPatientDate = (info: DicomInfo): string | null => {
            if (info.patientBirthDate) {
                return DateTime.fromJSDate(info.patientBirthDate).toFormat(PATIENT_DATE_OF_BIRTH_FORMAT) ?? null;
            }

            return null;
        };

        return {
            links: [], // The links are empty, and only for compliance with the typing.
            name: mapPatientName(dicomInfo) ?? undefined,
            sex: mapPatientSex(dicomInfo) ?? undefined,
            birth_date: mapPatientDate(dicomInfo) ?? undefined,
        };
    }

    /**
     * Compare two different patient's metadata.
     *
     * Note: The underlying checks are case insensitive string comparisons.
     */
    public static compare(patient: PatientRepresentation, anotherPatient: PatientRepresentation): PatientComparison {
        /** @returns A string without leading and trailing spaces. If no string is passed, and empty string is returned */
        const comparableString = (str: string | undefined) => (str ?? '').trim();
        const compareName = (): void => {
            if (
                StringUtil.isCaseInsensitiveEqual(
                    comparableString(patient.name?.family), comparableString(anotherPatient.name?.family)) &&
                StringUtil.isCaseInsensitiveEqual(
                    comparableString(patient.name?.given), comparableString(anotherPatient.name?.given)) &&
                StringUtil.isCaseInsensitiveEqual(
                    comparableString(patient.name?.middle), comparableString(anotherPatient.name?.middle))) {
                // nothing to do
            } else {
                errors.push({
                    type: PatientComparisonErrorType.Name,
                    values: [NameRepresentationUtil.format(patient.name), NameRepresentationUtil.format(anotherPatient.name)],
                });
            }
        };
        const compareBirthDate = (): void => {
            if (StringUtil.isCaseInsensitiveEqual(
                comparableString(patient.birth_date), comparableString(anotherPatient.birth_date))) {
                // nothing to do
            } else {
                errors.push({
                    type: PatientComparisonErrorType.DateOfBirth,
                    values: [patient.birth_date, anotherPatient.birth_date],
                });
            }
        };
        const compareSex = (): void => {
            if (StringUtil.isCaseInsensitiveEqual(comparableString(patient.sex), comparableString(anotherPatient.sex))) {
                // nothing to do
            } else {
                errors.push({ type: PatientComparisonErrorType.Sex, values: [patient.sex, anotherPatient.sex] });
            }
        };

        const errors: PatientComparisonError[] = [];

        compareName();
        compareBirthDate();
        compareSex();

        return {
            isSame: errors.length === 0,
            patient,
            anotherPatient,
            errorFields: errors,
        };
    }
}
