import { HipStemRepresentation } from '@/lib/api/representation/case/hip/HipStemRepresentation';
import {
    HipStemCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
import { HipHeadRepresentation } from '@/lib/api/representation/case/hip/HipHeadRepresentation';
import {
    HipStemHeadCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemHeadCatalogComponentRepresentation';
import { HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import {
    HipCupCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipCupCatalogComponentRepresentation';
import { HipLinerRepresentation } from '@/lib/api/representation/case/hip/HipLinerRepresentation';
import {
    HipLinerCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipLinerCatalogComponentRepresentation';
import { LinkedRepresentation } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import {
    CatalogComponentRepresentation,
    isComponentFromCatalog,
} from '@/lib/api/representation/catalog/CatalogComponentRepresentation';
import { AxiosInstance } from 'axios';
import { CaseComponentRepresentation } from '@/lib/api/representation/case/CaseComponentRepresentation';
import {
    findComponentByUri,
    HipCatalogCollectionName,
    HipComponentsCatalogRepresentation,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { CaseComponent } from '@/hipPlanner/components/state/types';
import { getRequiredSelfUri, getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import ResourceUtil from '@/lib/api/ResourceUtil';
import assert from 'assert';

export type StemComponentsRepresentation = HipStemRepresentation & {
    component: HipStemCatalogComponentRepresentation;
    head: HipHeadRepresentation & {
        component: HipStemHeadCatalogComponentRepresentation
    };
}

export type CupComponentsRepresentation = HipCupRepresentation & {
    component: HipCupCatalogComponentRepresentation;
    liner: HipLinerRepresentation & {
        component: HipLinerCatalogComponentRepresentation;
    }
}

export type FittedComponentsRepresentation = LinkedRepresentation & {
    stems: StemComponentsRepresentation[],
    cups: CupComponentsRepresentation[],
}

/**
 * Fetch the API-representation of fitted-components from the given uri.
 *
 * The fitted-components is a link on the surgical template; either
 * [component-ranking]{@link LinkRelation.componentRanking} or
 * [component-set]{@link LinkRelation.componentSet} ('components').
 *
 * The resulting fitted-components will all have their catalog-component (component property)
 * set, as the typing should indicate.
 */
export async function getFittedComponentsRepresentation(
    componentsCatalog: HipComponentsCatalogRepresentation,
    http: AxiosInstance,
    fittedComponentsUri: string,
    options?: CacheOptions,
): Promise<FittedComponentsRepresentation> {
    // Load the current fitted components
    const { data: components } = await http.get<FittedComponentsRepresentation>(
        fittedComponentsUri, { signal: options?.signal });
    if (!components) {
        throw Error(`Failed to GET fitted-components from ${fittedComponentsUri}`);
    }

    // Attach the catalog components to the stems and heads
    return {
        ...components,
        stems: components.stems.map(
            stem => attachStemCatalogComponents(stem, componentsCatalog, options)
        ),
        cups: components.cups.map(
            cup => attachCupCatalogComponents(cup, componentsCatalog, options)
        ),
    };
}

/**
 * If the given case-component does not have it's catalog-component property *component* set, then
 * find the catalog-component and set it as the *component* property
 *
 * Returns the functional-component with *component* property set.
 */
function attachCatalogComponent<CatalogComponent extends CatalogComponentRepresentation, Properties>(
    caseComponent: CaseComponentRepresentation & { component? : CatalogComponent } & Properties,
    componentsCatalog: HipComponentsCatalogRepresentation,
    collectionName: HipCatalogCollectionName,
    options?: CacheOptions,
): CaseComponent<CatalogComponent, Properties> {
    if (caseComponent.component === undefined) {
        const catalogComponentUri = getRequiredUri(caseComponent, LinkRelation.component);
        const catalogComponent = findComponentByUri(componentsCatalog, collectionName, catalogComponentUri);

        if (catalogComponent) {
            ResourceUtil.setResource(caseComponent, LinkRelation.component, catalogComponent, options);
        } else {
            throw new Error(
                `Failed to find catalog-component ${catalogComponentUri} for case-component ` +
                `${getRequiredSelfUri(caseComponent)}`
            );
        }
    }
    assert(caseComponent.component);

    if (isComponentFromCatalog(caseComponent.component)) {
        return caseComponent as CaseComponent<CatalogComponent, Properties>;
    } else {
        throw Error(`Invalid catalog-component on case-component ${getRequiredSelfUri(caseComponent)}`);
    }
}

/**
 * Attach stem and head catalog-components to the case-stem and case-head representations
 */
function attachStemCatalogComponents(
    stem: HipStemRepresentation,
    componentsCatalog: HipComponentsCatalogRepresentation,
    options?: CacheOptions,
): StemComponentsRepresentation {
    attachCatalogComponent(stem, componentsCatalog, LinkRelation.hipStemComponents, options);

    if (!stem.head) {
        throw Error('Case-stem representation is missing head');
    }
    attachCatalogComponent(stem.head, componentsCatalog, LinkRelation.hipStemHeadComponents, options);

    if (!isStemComponentsRepresentation(stem)) {
        throw Error('Failed to attach catalog-components to case-stem');
    }
    return stem;
}

/**
 * Attach cup and liner catalog-components to the case-cup and case-liner representations
 */
function attachCupCatalogComponents(
    cup: HipCupRepresentation,
    componentsCatalog: HipComponentsCatalogRepresentation,
    options?: CacheOptions,
): CupComponentsRepresentation {
    attachCatalogComponent(cup, componentsCatalog, LinkRelation.hipCupComponents, options);

    if (!cup.liner) {
        throw Error('Case-cup representation is missing liner');
    }
    attachCatalogComponent(cup.liner, componentsCatalog, LinkRelation.hipCupLinerComponents, options);

    if (!isCupComponentsRepresentation(cup)) {
        throw Error('Failed to attach catalog-components to case-cup');
    }
    return cup;
}

function isStemComponentsRepresentation(stem: HipStemRepresentation): stem is StemComponentsRepresentation {
    return stem?.component !== undefined &&
        isComponentFromCatalog(stem.component) &&
        stem?.head !== undefined &&
        isComponentFromCatalog(stem.head.component);
}

function isCupComponentsRepresentation(cup: HipCupRepresentation): cup is CupComponentsRepresentation {
    return cup?.component !== undefined &&
        isComponentFromCatalog(cup.component) &&
        cup?.liner !== undefined &&
        isComponentFromCatalog(cup.liner.component);
}
