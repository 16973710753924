import { getUri } from 'semantic-link';
import { DicomMessage, DicomMessageLevelUtil, DicomSeries } from '@/lib/dicom/DicomSeries';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import {
    makeSeriesCreateDataRepresentation,
    SeriesCreateDataRepresentation,
    SeriesMessageRepresentation,
} from '@/lib/api/representation/SeriesRepresentation';

import anylogger from 'anylogger';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
import { DicomUploadTask } from '@/components/case-settings/scan/workflow/DicomUploadTask';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';

const log = anylogger('DicomUploadUtil');

export default class DicomUploadUtil {
    public static async getStudy(
        caseItem: CaseRepresentation,
        task: DicomUploadTask,
        options?: CacheOptions,
    ): Promise<StudyRepresentation> {
        const { value: series, id: seriesId } = task.getUploadData();
        const study = await CaseStudyResource.createStudy(
            caseItem, this.makeStudyCreateData(seriesId, series), { signal: task.getAbortSignal(), ...options });
        if (!study || !await CaseStudyResource.getStudy(study, options)) {
            throw new Error('Failed to get study');
        }
        return study;
    }

    public static getStudyFilesUri(
        study: StudyRepresentation,
    ): string {
        const ctFileCollectionUri = getUri(study, 'files');
        if (!ctFileCollectionUri) {
            throw new Error('Study has no file collection');
        }
        return ctFileCollectionUri;
    }

    /**
     * Make a message in wire format (with enum as a string)
     */
    public static makeMessage(message: DicomMessage): SeriesMessageRepresentation {
        return {
            level: DicomMessageLevelUtil.toString(message.level),
            message: message.message,
        };
    }

    private static makeStudyCreateData(seriesId: string, series: DicomSeries): SeriesCreateDataRepresentation {
        return makeSeriesCreateDataRepresentation(
            'drag-n-drop upload',
            seriesId,
            series.items.length,
            series.messages?.map(DicomUploadUtil.makeMessage),
            series
                .items
                ?.filter(info => info.isExcluded)
                .map(info => {
                    return {
                        name: info.file.name || '',
                        messages: info.messages?.map(DicomUploadUtil.makeMessage),
                    };
                }));
    }
}
