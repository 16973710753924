import { ImageSize } from '@/lib/api/representation/CatStackRepresentation';
import CustomColors from '@/styles/_colors';

/**
 * A {x,y} offset (for an image)
 *
 * @see {@link ImageSize}
 */
export interface ImageOffset {
    x: number;
    y: number;
}

export interface ImageInfo {
    offset: ImageOffset;
    size: ImageSize;
}

export interface DrawCanvasInfo {
    source: ImageInfo,
    destination: ImageInfo,

    /** The width scale ratio from the source to the destination */
    widthScaleRatio: number;

    /** The height scale ratio from the source to the destination */
    heightScaleRatio: number;
}

/**
 * Code used to calculate which image to show in a catstack sprite image.
 */
export class CatStackImageUtil {
    /**
     *  Calculate the size/position of the image to show.
     *
     *  This determines the size and position of a the slice given a linear offset. The image
     *  has the slices (sprites) organised in a square (ish) grid. This code will determine where
     *  in the grid the image is located. The images are stored in stripes *down* the page (i.e.
     *  *not* in a left-to-right order).
     */
    public static calculate(
        imageSize: ImageSize,
        sliceSize: ImageSize,
        index: number,
        targetWidth: number,
        targetHeight: number): DrawCanvasInfo {
        const imagesPerColumn = imageSize.height / sliceSize.height;

        const columnIndex = index % imagesPerColumn;
        const rowIndex = Math.trunc(index / imagesPerColumn);

        const xOffset = rowIndex * sliceSize.width;
        const yOffset = columnIndex * sliceSize.height;

        return {
            source: {
                offset: { x: xOffset, y: yOffset }, size: sliceSize,
            },
            destination: {
                offset: { x: 0, y: 0 },
                size: { height: targetHeight, width: targetWidth },
            },
            widthScaleRatio: targetWidth / sliceSize.width,
            heightScaleRatio: targetHeight / sliceSize.height,
        };
    }

    /**
     * Render a given image on a 2d canvas context, given the position/size information.
     */
    public static render(
        context: CanvasRenderingContext2D, image: HTMLImageElement, drawCanvasInfo: DrawCanvasInfo): void {
        context.drawImage(
            image,
            drawCanvasInfo.source.offset.x,
            drawCanvasInfo.source.offset.y,
            drawCanvasInfo.source.size.width,
            drawCanvasInfo.source.size.height,
            drawCanvasInfo.destination.offset.x,
            drawCanvasInfo.destination.offset.y,
            drawCanvasInfo.destination.size.width,
            drawCanvasInfo.destination.size.height);
    }

    /**
     * Resize a canvas according to a width/height.
     * @see https://stackoverflow.com/questions/4938346/canvas-width-and-height-in-html5
     */
    public static resizeCanvas(context: CanvasRenderingContext2D, width: number, height: number): void {
        context.canvas.width = width;
        context.canvas.height = height;
        context.canvas.style.width = `${width}px`;
        context.canvas.style.height = `${height}px`;
    }

    /**
     * Put a RED line on the canvas. This will put a single <b>horizontal</b> line accross the whole
     * image (i.e. the full width), at distance specified by `offset`.
     *
     * This line is used to indicate the position of the slice in another dimension. Historically
     * this has been done with <div> and css.
     *
     * Note: This method makes no attempt to clear or invalid any portion of the canvas. It is
     * assumed that an another method will do this (e.g. by drawing the entire catstack CT image
     * on the canvas).
     *
     * @see  {@link renderVerticalLine}
     */
    public static renderHorizontalLine(context: CanvasRenderingContext2D, drawCanvasInfo: DrawCanvasInfo, offset: number): void {
        // Reset the current path
        context.beginPath();
        context.strokeStyle = CustomColors['custom-orange-rich'];
        context.lineWidth = 1;

        // starting point
        const { destination, heightScaleRatio } = drawCanvasInfo;
        context.moveTo(destination.offset.x, destination.offset.y + (offset * heightScaleRatio));

        // end point
        context.lineTo(
            destination.offset.x + destination.size.width,
            destination.offset.y + (offset * heightScaleRatio));

        // Make the line visible
        context.stroke();
    }

    /**
     * Put a RED line on the canvas. This is the same as {@link renderHorizontalLine} except the
     * line is vertical.
     *
     * @see  {@link renderHorizontalLine}
     */
    public static renderVerticalLine(context: CanvasRenderingContext2D, drawCanvasInfo: DrawCanvasInfo, offset: number): void {
        // Reset the current path
        context.beginPath();
        context.strokeStyle = CustomColors['custom-orange-rich'];
        context.lineWidth = 1;

        const { destination, widthScaleRatio } = drawCanvasInfo;

        // Staring point
        context.moveTo(
            (destination.offset.x + offset) * widthScaleRatio,
            destination.offset.y);

        // End point
        context.lineTo(
            (destination.offset.x + offset) * widthScaleRatio,
            destination.offset.y + destination.size.height);

        // Make the line visible
        context.stroke();
    }
}
