import { Euler, Matrix4 } from 'three';
import { isRigid, RigidMatrix4 } from '@/lib/base/RigidTransform';
import assert from 'assert';
import {
    defaultEulerOrder,
    EulerAnglesRepresentation,
    EulerRotationOrder,
} from '@/lib/api/representation/geometry/transforms';

/**
 * Create a rotation as represented in Euler angles
 */
export function makeEulerAngles(values?: Partial<EulerAnglesRepresentation>): EulerAnglesRepresentation {
    return {
        type: 'euler-angles',
        order: values?.order ?? null,
        x: values?.x ?? null,
        y: values?.y ?? null,
        z: values?.z ?? null,
    };
}

export function eulerAnglesFromTransform(
    transform: RigidMatrix4, order: EulerRotationOrder | null): EulerAnglesRepresentation {
    assert.ok(isRigid(transform));
    const angles = new Euler().setFromRotationMatrix(transform, order ?? undefined);
    return makeEulerAngles({ x: angles.x, y: angles.y, z: angles.z, order });
}

/**
 * Guard function to identify euler-angles
 */
export function isEulerAngles(representation?: unknown): representation is EulerAnglesRepresentation {
    return !!representation && (representation as EulerAnglesRepresentation).type === 'euler-angles';
}

/**
 * Create the matrix representation of an euler-angles transformation
 */
export function makeEulerTransform(representation: EulerAnglesRepresentation): RigidMatrix4 {
    return new Matrix4().makeRotationFromEuler(
        new Euler(
            representation.x ?? undefined,
            representation.y ?? undefined,
            representation.z ?? undefined,
            representation.order ?? defaultEulerOrder),
    );
}
