
    import { Component, PropSync, ProvideReactive, Vue, Watch } from 'vue-property-decorator';

    import { DicomMessageLevel } from '@/lib/dicom/DicomSeries';
    import { ScanUploadState, UploadEventState } from '@/components/case-settings/scan/workflow/ScanUploadState';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';

    import anylogger from 'anylogger';
    import { DicomSeriesUtil } from '@/lib/dicom/DicomSeriesUtil';
    import Bugsnag from '@bugsnag/js';
    import ScanUploadService, {
        UploadData,
        ValidatedResult,
    } from '@/components/case-settings/scan/workflow/ScanUploadService';
    import assert from 'assert';
    import ScanUploadPatientMismatchDialog from '@/components/case-settings/scan/ScanUploadPatientMismatchDialog.vue';
    import ScanUploadStatusDialog from '@/components/case-settings/scan/ScanUploadStatusDialog.vue';
    import {
        PatientComparison,
        PatientComparisonUtil,
    } from '@/components/case-settings/scan/workflow/PatientComparisonUtil';
    import { DicomInfo } from '@/lib/dicom/DicomInfo';
    import ScanUploadPatientInconsistentIdentityError
        from '@/components/case-settings/scan/ScanUploadPatientInconsistentIdentityError.vue';
    import ScanUploadParseError from '@/components/case-settings/scan/ScanUploadParseError.vue';
    import { AuthenticatedWorker } from '@/worker/AuthenticatedWorker';
    import { WorkerEvent, WorkerMessageEvent } from '@/worker/types';
    import CaseResource from '@/lib/api/resource/case/CaseResource';
    import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
    import DicomUploadUtil from '@/lib/dicom/DicomUploadUtil';
    import { DicomUploadTask } from '@/components/case-settings/scan/workflow/DicomUploadTask';
    import { partial } from 'lodash';
    import { authService } from '@/main';
    const log = anylogger('ScanUploadStatusDialog');

    enum DicomMetadataMessages {
        // Message added to the metadata of the scan when there were no issues.
        // TODO: the text could be updated, but that will create inconsistencies among different scans.
        UploadedWithoutDicomWarnings = 'Single series auto-uploaded',

        // Message added to the metadata of the scan when there were issues with the DICOm itself.
        // TODO: the text could be updated, but that will create inconsistencies among different scans.
        UploadedByUserWithDicomWarnings = 'Series uploaded by user',

        // Message added to the metadata of the scan to mark that even the patient and DICOM patient
        // differ, the user proceeded with the upload.
        UserConfirmedPatientDicomMismatch = 'Single series confirmed by user even though manually entered patient details do not match dicom metadata',
    }

    /**
     * This the upload confirmation/status dialog. This dialog will provide progress while the
     * DICOM files are parsed, collated and uploaded.
     *
     * Unlike the other dialogs in this series the activation is based on a drag-n-drop event, rather
     * than a button press. This component should have the drag-n-drop area in an activator slot.
     */
    @Component({
        components: {
            ScanUploadParseError,
            ScanUploadStatusDialog,
            ScanUploadPatientMismatchDialog,
            ScanUploadPatientInconsistentIdentityError,
        },
    })
    export default class ScanUploadWorkflow extends Vue {
        @PropSync('files', { default: null })
        public dataTransfer!: DataTransfer | null;

        @PropSync('case', { default: null })
        public caseItem!: CaseRepresentation | null;

        /**
         * The lifecycle enum for the dialog. The visibility of the dialog also
         * follows this enum.
         *
         * TODO: This should be part of the {@link ScanUploadService}.
         */
        public state: ScanUploadState = ScanUploadState.Initial;

        @ProvideReactive()
        private uploadService: ScanUploadService | null = null;

        private parseError: string | null = null;

        /** The value needed to display when on {@link ScanUploadState.PatientInconsistentIdentityError} */
        private patientInconsistentIdentityErrorValue: Record<string, DicomInfo[]> | null = null;

        /** The value needed to display when on {@link ScanUploadState.EnteredPatientVsDicomDataMismatch} */
        private enteredPatientComparison: PatientComparison | null = null;

        private uploadCandidate: DicomInfo[] | null = null;
        private validatedUploadCandidate: ValidatedResult | null = null;

        protected created(): void {
            assert.ok(!!this.caseItem, 'caseItem expected');
            this.uploadService = new ScanUploadService(this.caseItem, this.$http, this.$apiOptions);
        }

        protected onCancel(): void {
            log.info('cancel');
            this.uploadService?.cancel();
            this.transitionToInitialState();
        }

        /**
         * cleanup and hide the dialog.
         */
        private transitionToInitialState(): void {
            this.dataTransfer = null;
            this.state = ScanUploadState.Initial;
            this.$emit('uploadEvent', UploadEventState.None);
        }

        private transitionToCollectingDataState(): void {
            this.dataTransfer = null;
            this.state = ScanUploadState.CollectingData;
        }

        /**
         * The mechanism by which the upload is triggers is that the component hosting
         * this dialog will set the {@ref dataTransfer} variable with the drag-n-drop
         * data transfer object.
         */
        @Watch('dataTransfer')
        private async onFiles(data: DataTransfer | null): Promise<void> {
            assert.ok(!!this.uploadService, 'uploadService not defined');

            if (data) {
                this.transitionToCollectingDataState();

                let groupsByPatientIdentity: Record<string, DicomInfo[]> | null = null;
                try {
                    groupsByPatientIdentity = await this.uploadService.parse(data);
                } catch (error: unknown) {
                    log.error('File upload failed: %s', error instanceof Error ? error.message : error);
                    this.parseError = error instanceof Error ? error.message : 'Failed to parse files';
                    this.state = ScanUploadState.Failed;
                }

                if (groupsByPatientIdentity) {
                    const values = Object.values(groupsByPatientIdentity);
                    // If there are more than 1 group by patient identity it
                    // means the data is not consistent across the dicom files
                    if (values.length > 1) {
                        this.patientInconsistentIdentityErrorValue = groupsByPatientIdentity;
                        this.state = ScanUploadState.PatientInconsistentIdentityError;
                    } else if (values.length === 0) {
                        // not expected. Even if the patient identity is removed there has to be one group.
                        this.state = ScanUploadState.Failed;
                    } else {
                        this.uploadCandidate = values[0];

                        assert.ok(!!this.caseItem, 'cannot compare patient details without a case');
                        this.enteredPatientComparison = this.compareEnteredPatientDetailsAgainstDicom(
                            this.uploadCandidate, this.caseItem);

                        if (this.enteredPatientComparison.isSame) {
                            await this.progressCandidateForUpload(this.uploadCandidate);
                        } else {
                            this.state = ScanUploadState.EnteredPatientVsDicomDataMismatch;
                        }
                    }
                } else {
                    this.state = ScanUploadState.Failed;
                }
            }
        }

        /**
         * Progress the update workflow either
         * from: {@link ScanUploadState.CollectingData} or * {@link ScanUploadState.EnteredPatientVsDicomDataMismatch}
         * to: {@link ScanUploadState.WaitingForConfirmation} or
         * {@link ScanUploadState.UploadingData} or
         * {@link ScanUploadState.Failed}
         * @private
         */
        private async progressCandidateForUpload(uploadCandidate: DicomInfo[]): Promise<void> {
            assert.ok(!!this.uploadService, 'uploadService not defined');
            this.validatedUploadCandidate = await this.uploadService.makeValidatedSeries(uploadCandidate);
            const candidateForUpload = this.validatedUploadCandidate.candidate;
            if (candidateForUpload) {
                if (this.isCollectingDataState || this.isEnteredPatientVsDicomDataMismatch) {
                    if (this.isEnteredPatientVsDicomDataMismatch) {
                        DicomSeriesUtil.appendMessage(
                            candidateForUpload.value.messages,
                            DicomMessageLevel.Info,
                            DicomMetadataMessages.UserConfirmedPatientDicomMismatch);
                    }

                    assert.ok(!!candidateForUpload, 'candidate expect when no error');
                    if (this.uploadService.isSeriesOkForAutoUpload(candidateForUpload.value)) {
                        log.info('single series %s has no messages and is being auto-uploaded', candidateForUpload.value);
                        DicomSeriesUtil.appendMessage(
                            candidateForUpload.value.messages,
                            DicomMessageLevel.Info,
                            DicomMetadataMessages.UploadedWithoutDicomWarnings);

                        await this.uploadSeries(candidateForUpload);
                    } else {
                        log.info(
                            'single series %s has %d messages, waiting for user confirmation',
                            candidateForUpload.id,
                            candidateForUpload.value.messages.length);

                        this.state = ScanUploadState.WaitingForConfirmation;
                    }
                } else {
                    throw new Error(`Unexpected state ${this.state}`);
                }
            } else {
                // Will show the dicom dialog in error state. Typically, will say something like:
                // 0 series to upload
                this.state = ScanUploadState.Failed;
            }
        }

        private async onSubmitFromParseError(): Promise<void> {
            this.parseError = null;
            this.onCancel();
        }

        private async onSubmitFromPatientInconsistentIdentityDialog(): Promise<void> {
            this.patientInconsistentIdentityErrorValue = null;
            this.onCancel();
        }

        /**
         * Add a persistent message to the series noting that the user progressed the workflow event
         * there is a mismatch between the entered patient / patient data in the dicom.
         */
        private async onSubmitFromPatientMismatch(): Promise<void> {
            assert.ok(!!this.uploadCandidate, 'uploadCandidate not defined');
            await this.progressCandidateForUpload(this.uploadCandidate);
        }

        /**
         * Add a persistent message to the series noting that it was a user action that caused the
         * upload of the series (c.f. automatically uploaded)
         */
        private async onManualUpload(): Promise<void> {
            const uploadCandidate = this.validatedUploadCandidate?.candidate;
            assert.ok(!!uploadCandidate, 'uploadCandidateInfo not defined');

            DicomSeriesUtil.appendMessage(
                uploadCandidate.value.messages,
                DicomMessageLevel.Info,
                DicomMetadataMessages.UploadedByUserWithDicomWarnings);

            await this.uploadSeries(uploadCandidate);
        }

        /**
         * Upload the given series to the server and make it the active study.
         */
        private async uploadSeries(uploadData: UploadData): Promise<void> {
            this.state = ScanUploadState.UploadingData;

            try {
                const uploadWorker = new AuthenticatedWorker(
                    new Worker(new URL('/src/worker/upload-scan/worker.ts', import.meta.url)),
                    this.$authEvent,
                );
                uploadWorker.authenticateWorkerSession(authService.getAuthToken());

                assert.ok(!!this.uploadService, 'uploadService not defined');
                this.$emit('uploadEvent', UploadEventState.Started);

                const uploadTask = new DicomUploadTask(uploadData);
                // the upload worked, so hide (dismiss) this dialog
                const abortSignal: AbortSignal = uploadTask.getAbortSignal();

                assert.ok(!!this.caseItem, 'caseItem expected');
                const study = await DicomUploadUtil.getStudy(this.caseItem, uploadTask, this.$options);

                // Register callback functions for worker events
                uploadWorker.on(
                    WorkerEvent.UploadComplete,
                    partial(this.emitUploadCompleted, study, abortSignal, uploadWorker),
                );
                uploadWorker.on(
                    WorkerEvent.Error,
                    partial(this.logError, uploadWorker),
                );
                uploadWorker.on(
                    WorkerEvent.IncrementStart,
                    partial(this.incrementStarted, uploadTask, uploadWorker)
                );
                uploadWorker.on(
                    WorkerEvent.IncrementComplete,
                    partial(this.incrementCompleted, uploadTask, uploadWorker)
                );

                this.uploadService.startUploadWorker(study, uploadTask, uploadWorker);
            } catch (error: unknown) {
                this.state = ScanUploadState.Failed;

                if (error instanceof Error) {
                    log.error('File upload failed: %s', error.message);
                    log.info(error, error.stack); // additional info - we want to understand the 'Network Error'
                    Bugsnag.notify(error);
                } else {
                    Bugsnag.notify({ name: 'File upload failed', message: 'File upload failed' });
                    log.error('File upload failed: %s', error);
                }

                // Emit uploadEvent failed
                this.$emit('uploadEvent', UploadEventState.Error);
            }
        }

        private checkAbortSignal(abortSignal: AbortSignal, uploadWorker: AuthenticatedWorker): void {
            if (abortSignal.aborted) {
                uploadWorker.terminate();
                log.error('Upload DICOM files aborted');
            }
        }

        private async emitUploadCompleted(
            study: StudyRepresentation,
            abortSignal: AbortSignal,
            uploadWorker: AuthenticatedWorker,
            _e: WorkerMessageEvent,
        ): Promise<void> {
            assert.ok(this.caseItem, 'caseItem expected');
            assert.ok(study, 'study not defined');
            await CaseResource.updateCaseStudy(
                this.caseItem,
                getRequiredUri(study, LinkRelation.self),
                this.$apiOptions,
            );

            log.info('Upload of DICOM study files complete');

            // Upload should be completed without errors
            // the upload worked, so hide (dismiss) this dialog
            this.transitionToInitialState();

            if (!abortSignal.aborted) {
                // Emit upload event successful if the task was not cancelled
                this.$emit('uploadEvent', UploadEventState.Completed);
                uploadWorker.terminate();
                return;
            }

            this.checkAbortSignal(abortSignal, uploadWorker);
        }

        private logError(uploadWorker: AuthenticatedWorker, e: WorkerMessageEvent): void {
            uploadWorker.terminate();

            this.state = ScanUploadState.Failed;

            // Emit uploadEvent failed
            this.$emit('uploadEvent', UploadEventState.Error);

            const error = e.data.error ? e.data.error : 'Unknown';

            Bugsnag.notify({ name: 'File upload failed', message: error });
            log.error('File upload failed: %s', error);
        }

        private incrementStarted(
            uploadTask: DicomUploadTask,
            uploadWorker: AuthenticatedWorker,
            _e: WorkerMessageEvent,
        ): void {
            uploadTask.incrementStarted();
            this.checkAbortSignal(uploadTask.getAbortSignal(), uploadWorker);
        }

        private incrementCompleted(
            uploadTask: DicomUploadTask,
            uploadWorker: AuthenticatedWorker,
            _e: WorkerMessageEvent,
        ): void {
            uploadTask.incrementCompleted();
            this.checkAbortSignal(uploadTask.getAbortSignal(), uploadWorker);
        }

        protected get isCollectingDataState(): boolean {
            return this.state === ScanUploadState.CollectingData;
        }

        protected get isPatientInconsistentIdentityError(): boolean {
            return this.state === ScanUploadState.PatientInconsistentIdentityError;
        }

        /** whether an upload is in progress, and thus whether to show the progress slider */
        protected get isEnteredPatientVsDicomDataMismatch(): boolean {
            return this.state === ScanUploadState.EnteredPatientVsDicomDataMismatch;
        }

        protected get isWaitingForUserConfirmation(): boolean {
            return this.state === ScanUploadState.WaitingForConfirmation;
        }

        /** * Whether the 'upload' button shows a busy spinner. */
        protected get isUploadingData(): boolean {
            return this.state === ScanUploadState.UploadingData;
        }

        /** * Whether the 'upload' button shows a busy spinner. */
        protected get isFailed(): boolean {
            return this.state === ScanUploadState.Failed;
        }

        protected compareEnteredPatientDetailsAgainstDicom(
            seriesCandidate: DicomInfo[], caseItem: CaseRepresentation): PatientComparison {
            const sampleDicomInfo = seriesCandidate[0];
            const manuallyEnteredPatient = caseItem.patient;
            assert.ok(!!manuallyEnteredPatient, 'manuallyEnteredPatient not defined');

            if (manuallyEnteredPatient) {
                if (sampleDicomInfo) {
                    return PatientComparisonUtil.compare(manuallyEnteredPatient, PatientComparisonUtil.fromDicom(sampleDicomInfo));
                }
            }

            throw new Error('expected entered patient');
        }
    }
