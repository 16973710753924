import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { PlanningQueryParamsAvailable, RouteParserUtils } from '@/lib/base/RouteParserUtils';

/**
 * A mixin that to use {@RouteParserUtils} in the vue templates
 *
 * e.g:
 *
 * ```
 * <template>
 *      <div v-if=debugModeEnabled>
 *          show if hip
 *      </div>
 * </template>
 *
 *
 *  @Component({
 *       components: { },
 *       mixins: [RouteParseMixin],
 *   })
 *  class AComponentUsingTheMixing extends Vue {
 *  }
 *  ```
 */
@Component
export default class RouteParseMixin extends Vue {
    /**
     * Feature flag to control display of debug mode
     *
     * If the path includes the query parameter '_debugMode=true' returns true:
     * - #/case/3d/a/project/project_id?_debugMode=true => true
     * - #/case/3d/a/project/project_id?_debugMode=false => false
     * - #/case/3d/a/project/project_id => false
     **/
    public get debugModeEnabled(): boolean {
        return RouteParserUtils.hasQueryParamValue(
            this.$route, PlanningQueryParamsAvailable.DebugMode, 'true'
        );
    }
}
