
    import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

    @Component
    export default class AppCheckboxGroup extends Vue {
        @VModel({type: Array}) checked!: string[];
        @Prop(Array) readonly options!: { value: string, text: string; }[];
        @Prop(Boolean) readonly disabled: boolean | undefined;
        @Prop(String) readonly error?: string;
    }
