
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import MessageResource from '@/lib/api/resource/case/MessageResource';
    import NotificationListItem from '@/components/notifications/NotificationListItem.vue';
    import { CollectionRepresentation, LinkUtil } from 'semantic-link';
    import {
        MessageCollectionRepresentation,
        MessageRepresentation,
        NotificationPullStatus,
        NotificationPullStatusType,
    } from '@/lib/api/representation/MessageRepresentation';
    import TimeoutUtil from '@/lib/base/TimeoutUtil';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import LinkRelation from '@/lib/api/LinkRelation';
    import anylogger from 'anylogger';
    import AppBarButton from '@/components/app-bar/AppBarMenuButton.vue';
    import { SparseRepresentationFactory } from '@/lib/semantic-network';
    import AnalyticsUtil from '@/lib/analytics/AnalyticsUtil';
    import { isErrorCausedBy } from '@/hipPlanner/views/hipPlannerServices';

    const log = anylogger('Notifications');

    const NotificationCancellationReason = 'notifications pull cancelled';

    /**
     * Notification component found in the top right corner of the application
     * that shows message related to the case (maybe expended in the future to include site-wide messages).
     */
    @Component({
        components: { NotificationListItem, AppBarButton },
    })
    export default class Notifications extends Vue {
        /** The case (project) */
        @Prop({ required: true })
        project!: CaseRepresentation;

        protected cancellation = new AbortController();

        /**
         * a cache for all the messages while the component is alive.
         * TODO: This will be deleted once the component is unmounted. Need to move this collection to globa state maybe.
         */
        private allMessagesCache = SparseRepresentationFactory.make<CollectionRepresentation<MessageRepresentation>>(
            { sparseType: 'collection' });

        /** the list (array) of case messages */
        protected get messageItems(): MessageRepresentation[] {
            return this.messages?.items && this.messages.items.length > 0 ? this.messages.items : [];
        }

        protected isLoading = false;
        /** Whether the menu is visible/active */
        protected isActive = false;
        /** Case messages */
        protected messages: MessageCollectionRepresentation | null = null;
        /** Number of messages displayed in the notifications counter */
        protected numberOfNewMessages = 0;
        /** Number of messages before the user checks the new messages */
        protected numberOfCheckedMessages = 0;
        /** Flag for pausing case message pulling */
        protected messagesPullStatus: NotificationPullStatusType = NotificationPullStatus.Paused;
        /** Message pulling interval */
        protected messagePullInterval = 15000;

        protected async created(): Promise<void> {
            this.messagesPullStatus = NotificationPullStatus.Running;

            try {
                await this.refreshCaseMessages(this.messagePullInterval, true);
            } catch (e) {
                if (isErrorCausedBy(e, NotificationCancellationReason)) {
                    log.info('Loading notifications - cancelled (%s)', e.message);
                } else {
                    throw e;
                }
            }
        }

        @Watch('isActive') // watch the active boolean, which is the v-model/value
        @IsLoading('isLoading')
        protected async onActive(isActive: boolean): Promise<void> {
            if (isActive) {
                log.debug('Notifications are open');
                this.messagesPullStatus = NotificationPullStatus.Paused;

                this.messages = await MessageResource.getCaseMessages(
                    this.allMessagesCache, this.project, { ...this.$apiOptions, forceLoad: false });

                this.numberOfNewMessages = 0;
                // reset the number of checked messages
                this.numberOfCheckedMessages = this.messages?.items.length || 0;

                AnalyticsUtil.screenView(this.$gtag, AnalyticsUtil.ScreenName.Notifications);

                log.debug('%o', this.messages);
            } else {
                log.debug('Notifications are closed');
                this.messagesPullStatus = NotificationPullStatus.Running;
            }
        }

        protected getItemKey(item: MessageRepresentation): string {
            if (item) {
                const url = LinkUtil.getUri(item, LinkRelation.canonicalOrSelf);
                if (url) {
                    return url;
                } else if (item.timestamp) {
                    return item.timestamp;
                }
            }
            log.info('Case message item is malformed %o', item);
            return 'Unknown';
        }

        /**
         * Pull the available case messages.
         *
         * Note: if the predicate throws an error then that error will be returned to the caller.
         */
        protected async refreshCaseMessages(pollInterval: number, init = false): Promise<void> {
            if (this.messagesPullStatus === NotificationPullStatus.Cancelled) {
                return;
            } // else, continue checking for messages

            // check for new messages only if the browser tab is active/being viewed,
            // and the PullStatus is set to "running"
            if ((!document.hidden || init) && this.messagesPullStatus === NotificationPullStatus.Running) {
                // We are force loading the message collection,
                // since we need to check if there are new items in the collection
                //
                const messageCollection = await MessageResource.getCaseMessages(
                    this.allMessagesCache, this.project, { ...this.$apiOptions, forceLoad: true });
                if (messageCollection) {
                    if (init) {
                        this.numberOfCheckedMessages = messageCollection.items.length;
                    } // else do nothing

                    const numberOfNewMessages = messageCollection.items.length - this.numberOfCheckedMessages;

                    log.debug('New messages: %s', numberOfNewMessages);
                    if (numberOfNewMessages > 0) {
                        this.messages = messageCollection;
                        this.numberOfNewMessages = init ? 0 : numberOfNewMessages;
                    }
                } else {
                    log.warn('Failed to get case messages');
                }
            }

            // Loop until cancelled
            await TimeoutUtil.setTimeout(pollInterval, this.cancellation.signal);

            this.cancellation.signal.throwIfAborted();

            await this.refreshCaseMessages(pollInterval, false);
        }

        protected beforeDestroy(): void {
            this.cancellation.abort({ name: NotificationCancellationReason });
            // cancel pulling for messages
            this.messagesPullStatus = NotificationPullStatus.Cancelled;
        }
    }
