/**
 * IANA Mime Types
 */
enum ContentType {
    Any = '*/*',
    AnyImage = 'image/*',
    AnyModel = 'model/*',

    ModelStl = 'model/stl',

    ModelPly = 'model/ply',
    ModelPlyAscii = 'model/ply+ascii',
    ModelPlyBinary = 'model/ply+binary',

    /**
     * There is no IANA defined mime/content type for PLY files. This defines a user defined
     * type with an 'x' prefix.
     *
     * Note: Although it would be best practice to use the 'X' prefix, the ACID code base
     * is going to use the raw form of the content type without the 'x' prefix.
     */
    ModelXPly = 'model/x.ply',
    ModelXPlyAscii = 'model/x.ply+ascii',
    ModelXPlyBinary = 'model/x.ply+binary',

    /**
     * The media type for DICOM files.
     *
     * see
     * - http://dicom.nema.org/DICOM/supps/sup54_pc.pdf
     * - https://en.wikipedia.org/wiki/DICOM
     */
    Dicom = 'application/dicom',
    Pdf = 'application/pdf',

    Json = 'application/json',

    UriList = 'text/uri-list',

}

export default ContentType;
