
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import {
        SurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
    import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
    import SurgicalTemplate from '@/components/case-manage/SurgicalTemplate.vue';
    import PlanResource from '@/lib/api/resource/case/plan/PlanResource';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';

    /**
     * A top level page with a set of v-cards with plans for the case (project).
     *
     * Each project will have zero or more plans. After the surgical details and the
     * DICOM have been configured, segmentation and other processing is done that will
     * create an initial 'ACID' plan with the automated plan. The surgeon can then modify
     * the parameters of the case to approve additional plans.
     *
     * Note: This component is responsible for loading data. Other than the skeleton loader it has no
     * visible elements. The task of refreshing and keeping the list of plans up to date is the responsibility
     * of the child {@link Plans} component.
     */
    @Component({ components: { SurgicalTemplate } })
    export default class SurgicalTemplateLoader extends Vue {
        @Prop({ required: true })
        public readonly value!: SurgicalTemplateRepresentation;

        @Prop({ required: true })
        project!: CaseRepresentation;

        /** The URI of the active 'user' surgical template surgical specification */
        @Prop({ required: true })
        public userSurgicalTemplateUri!: string | null;

        /** The URI of the active 'acid' surgical template surgical specification */
        @Prop({ required: true })
        public acidSurgicalTemplateUri!: string | null;

        /** whether the main list of plans (and the project) is being loaded */
        private loading = true;

        @IsLoading('loading')
        protected async onIntersect(
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
            isIntersecting: boolean): Promise<void> {
                if (isIntersecting) {
                    const st = await SurgicalTemplateResource.get(this.value, { ...this.$apiOptions, forceLoad: true });
                    if (st) {
                        // if the surgical template has a plan, then load it (this is a conditional get)
                        await PlanResource.getSurgicalTemplatePlan(
                            st, this.project, { ...this.$apiOptions, forceLoad: true });
                    }
                }
            }
    }
