
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class AppFormControl extends Vue {
        @Prop(String) readonly labelText: string | undefined;
        @Prop(String) readonly optionalText: string | undefined;
        @Prop(Boolean) readonly optional: boolean | undefined;

        get optionalTextStyle() {
            const text = this.optionalText !== undefined && this.optionalText !== '' ? this.optionalText : 'optional';

            return {
                '--optional-text': JSON.stringify(' (' + text + ')'),
            };
        }
    }
