
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import {
        CaseCollectionRepresentation,
        CaseRepresentation,
    } from '@/lib/api/representation/case/CaseRepresentation';
    import { StudyProcessOptionsRepresentation } from '@/lib/api/representation/SeriesRepresentation';
    import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
    import { LinkUtil } from 'semantic-link';
    import LinkRelation from '@/lib/api/LinkRelation';
    import anylogger from 'anylogger';
    import ResourceUtil from '@/lib/api/ResourceUtil';
    import { StudyUtil } from '@/lib/api/resource/case/study/StudyUtil';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import { isNil } from 'ramda';
    import { UriDeconstructionUtil } from '@/components/case-plan/UrlDeconstructionUtil';
    import AxiosUtil from '@/lib/AxiosUtil';
    import assert from 'assert';

    const log = anylogger('DuplicateStudyFromStudyModels');

    /**
     * A dialog that is shown to the user (administrator) to re-run multiple cases
     * from the study phase. A duplicate study is created based on an existing study.
     * The existing study must have valid 3D models and DICOM files uploaded,
     * which are used by the new study.
     *
     * Note:
     * 1. Re-run cases is done sequentially. It could be improved to be
     *  done in parallel, or using bottleneck, but for simplicity and until required
     *   are gathered it is done sequentially.
     * 2. There is a first go attempt to show users about possible bugged cases
     *  e.g:
     *    a. cases without studies
     *    b. cases being processed, meaning there is a change the user is going to reprocess them again.
     * 3. Show warnings and progress on each item.
     * 4. Show a progress bar while it is doing the work.
     */
    @Component
    export default class DuplicateStudyFromStudyModels extends Vue {
        /** The selected cases to perform actions on */
        @Prop({ required: true, validator: notNilValidator('value') })
        public value!: CaseCollectionRepresentation;

        /** The selected cases to perform actions on */
        @Prop({ required: true, validator: notNilValidator('isDisabled') })
        public isDisabled!: boolean;

        /** bound utility for using on the template */
        protected studyUtil = StudyUtil;

        protected isVisible = false;
        protected isWorking = false;
        protected isLoading = true;
        protected createOptions: StudyProcessOptionsRepresentation = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            create_notifications: false,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            send_email: false,
        };

        protected errorText = '';

        /** Variables used for tracking progress and displaying progress bar */
        private progressCounter = 0;
        private progress = 0;

        /** The two collections used. Each project will fall into one or the other category */
        private projectsWithStudy: CaseRepresentation[] = [];
        private projectsWithoutStudy: CaseRepresentation[] = [];

        @IsLoading('isLoading')
        protected async onActivatorClick(): Promise<void> {
            this.resetState();
            await this.loadStudies();
            this.isVisible = true;
        }

        /** Load the active study for each project, and set the two collection used */
        private async loadStudies(): Promise<void> {
            await Promise.all(this.value.items.map((project: CaseRepresentation) => {
                return CaseStudyResource.getActiveStudy(project, { forceLoad: true, ...this.$apiOptions });
            }));

            this.projectsWithStudy = this.value.items.filter((project: CaseRepresentation): boolean => {
                return !isNil(project.activeStudy);
            });

            this.projectsWithoutStudy = this.value.items.filter((project: CaseRepresentation): boolean => {
                return isNil(project.activeStudy);
            });
        }

        private resetState(): void {
            this.progressCounter = 0;
            this.progress = 0;
            this.isWorking = false;
            this.isVisible = false;
            this.errorText = '';

            this.createOptions.send_email = false;
            this.createOptions.create_notifications = false;
        }

        /**
         * Re-run cases is done sequentially.
         *
         * Note: It could be done in parallel, or using bottleneck, but for simplicity and
         * until required are gathered it is done sequentially.
         */
        protected async onCreate(): Promise<void> {
            this.isWorking = true;
            this.errorText = '';

            try {
                for (const project of this.value.items) {
                    if (project.activeStudy) {
                        const study = await CaseStudyResource.createStudy(
                            project,
                            {
                                name: 'From 3d segmented models',
                                study: LinkUtil.getUri(project.activeStudy, LinkRelation.self),
                                operation: 'models',
                                options: this.createOptions,
                            },
                            this.$apiOptions);
                        if (study) {
                            const studyUri = LinkUtil.getUri(study, LinkRelation.self);
                            log.info('New study %s', studyUri);

                            // refresh the project as the active study should have changed
                            await ResourceUtil.refresh(project, this.$apiOptions);

                            // refresh the study
                            await CaseStudyResource.getActiveStudy(project, this.$apiOptions);
                            if (project.activeStudy) {
                                const activeStudyUri = LinkUtil.getUri(project.activeStudy, LinkRelation.self);
                                if (activeStudyUri === studyUri) {
                                    log.info(
                                        'Study with uri %s created (state \'%s\')',
                                        activeStudyUri,
                                        project.activeStudy.state);
                                } else {
                                    log.warn(
                                        'Study created with uri %s is not the active study of the case.',
                                        activeStudyUri,
                                        project.activeStudy);
                                }
                            } else {
                                log.warn(
                                    'Study with uri %s failed to being duplicated',
                                    LinkUtil.getUri(project.activeStudy, LinkRelation.self),
                                    project.activeStudy);
                            }

                            this.incrementProgress();

                            this.errorText = ``;
                        } else {
                            this.errorText = `Error creating study`;
                            return;
                        }
                    } else {
                        log.info('Skipping case re-run with uri: %s', LinkUtil.getUri(project, LinkRelation.self));
                    }
                }

                this.close();
            } catch (err: unknown) {
                assert.ok(err instanceof Error);
                if (AxiosUtil.isForbiddenError(err)) {
                    this.errorText = `Access denied`;
                } else {
                    this.errorText = `Error creating duplicate study: ${err.message}`;
                }
            } finally {
                this.isWorking = false;
            }
        }

        private incrementProgress(): void {
            this.progressCounter++;
            this.progress = this.totalProjectsToProcess ? this.progressCounter * 100 / this.totalProjectsToProcess : 0;
        }

        protected get totalProjects(): number {
            return this.value.items.length;
        }

        protected get totalProjectsToProcess(): number {
            return this.projectsWithStudy.length;
        }

        protected get totalProjectsWithoutStudy(): number {
            return this.projectsWithoutStudy.length;
        }

        private close(): void {
            this.isVisible = false;
        }

        protected referenceIdentifier(project: CaseRepresentation): string {
            const caseId = UriDeconstructionUtil.pathNameOfLink(project, LinkRelation.self);
            if (caseId) {
                return `#${caseId}`;
            }
            return '';
        }
    }
