import { HipCaseRepresentation } from '@/lib/api/representation/case/HipCaseRepresentation';
import { instanceOfLinkedRepresentation } from 'semantic-link';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { Procedure } from '@/lib/api/representation/case/ProcedureType';

/** A type guard to check the type of case representation. */
export function isCaseRepresentation(item: unknown): item is CaseRepresentation {
    return instanceOfLinkedRepresentation(item) &&
        (item as CaseRepresentation).name !== undefined;
}

/** A type guard to check the type of case representation. */
export function isHipCaseRepresentation(item: unknown): item is HipCaseRepresentation {
    const asHipCase = item as HipCaseRepresentation;
    return isCaseRepresentation(item) && asHipCase.side !== undefined && asHipCase.procedure === Procedure.Hip;
}

/** A type guard to check the type of case representation. */
export function isAHipCaseRepresentation(item: unknown): item is HipCaseRepresentation {
    return isCaseRepresentation(item) && isHipCaseRepresentation(item);
}
