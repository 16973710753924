
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import {
        HipSurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
    import { Fragment } from 'vue-fragment';
    import StemChanges from '@/components/case-manage/surgical-template/history/row/hip/StemChanges.vue';
    import CupChanges from '@/components/case-manage/surgical-template/history/row/hip/CupChanges.vue';
    import TargetChanges from '@/components/case-manage/surgical-template/history/row/hip/TargetChanges.vue';

    /**
     * This is a case component **DEBUG** component that shows
     * the specific columns of hip surgical template history
     *
     * It is assumed to be rendered on the 'hip' `<slot>` of the `<SurgicalTemplateHistoryRow/>`.
     */
    @Component({ components: { TargetChanges, CupChanges, StemChanges, Fragment } })
    export default class HipSurgicalTemplateHistoryContent extends Vue {
        @Prop({ required: true, default: null })
        private current?: HipSurgicalTemplateRepresentation | null;

        @Prop({ required: true, default: null })
        private previous?: HipSurgicalTemplateRepresentation | null;

        @Prop({ required: true, default: true })
        protected isLoading!: boolean;
    }
