
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';
    import UserResource from '@/lib/api/resource/user/UserResource';
    import { ProductCollectionRepresentation } from '@/lib/api/representation/ProductRepresentation';
    import ProductPreferencesResource from '@/lib/api/resource/user/ProductPreferencesResource';
    import { ProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';
    import { ApiUtil } from '@/lib/semantic-network';

    /**
     * This is the case surgeon
     */
    @Component({ components: { HyperlinkButton } })
    export default class Surgeon extends Vue {
        @Prop({ required: true })
        public value!: UserRepresentation;

        /** the list of products the user is subscribed to */
        protected products: ProductCollectionRepresentation | null = null;

        protected isLoading = false;
        protected selectedProductTab: string | null = null;

        @IsLoading('isLoading')
        protected async created(): Promise<void> {
            // refresh the user resource
            await ApiUtil.get(this.value, this.$apiOptions);

            // get the list of products that the user is subscribed to
            this.products = await UserResource.getProducts(this.value, this.$apiOptions);

            // Brute force load all surgeon preferences (in theory most of them will only
            // have one set of user preferences)
            for (const product of this.products?.items || []) {
                await ProductPreferencesResource.getSurgeonPreferences(
                    this.value, product.name, this.$apiOptions);
            }
        }

        protected get hipSurgeonPreferences(): ProductPreferencesRepresentation | null {
            return this.value?.hipSurgeonPreferences?.items?.length === 1 ?
                this.value.hipSurgeonPreferences.items[0] :
                null;
        }
    }
