import _Vue from 'vue';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import { LinkUtil } from 'semantic-link';
import { ApiOptions, SparseRepresentationFactory } from '@/lib/semantic-network';

const store = SparseRepresentationFactory.make<ApiRepresentation>(
    {
        sparseType: 'singleton',
        uri: LinkUtil.getUri('HEAD', 'api'),
    });

export function ApiPlugin(Vue: typeof _Vue): void {
    // attach to the root view, access via this.$api
    Object.defineProperties(Vue.prototype, {
        $api: {
            get(): ApiRepresentation {
                return store;
            },
        },
        $apiOptions: {
            get(): Partial<ApiOptions> {
                return { set: Vue.set };
            },
        },
    });
}

export function ApiPiniaPlugin() {
    return {
        get $api(): ApiRepresentation {
            return store;
        },
        get $apiOptions(): Partial<ApiOptions> {
            return { set: _Vue.set };
        },
    };
}

export default _Vue.use(ApiPlugin);
