import _ from 'lodash';

import anylogger from 'anylogger';
import { defaultTo } from 'ramda';
import assert from 'assert';
import visibility from 'vue-visibility-change';

const log = anylogger('RefreshAction');

export type FreshEventHandler = () => Promise<void>;

/**
 * Default options
 */
export type DefaultOptions = {
    /**
     * Optional default value for {@see RefreshAction.intersect}
     * There are some use cases whether checking a component is 'v-intersected' can be redundant.
     */
    intersected?: boolean,
    /**
     * Optional Default offset value for {@see RefreshAction.offset}
     */
    offset?: number
};

/**
 * A class to organise the periodic refresh of data on a Vue component.
 *
 * Note: this has been prototyped as a helper class. It is intended that this
 * will be converted to a mixin or a directive.
 */
export class RefreshAction {
    /** an external enabled state */
    private _enabled = false;
    /** whether the Vue component is mounted */
    private mounted = false;
    /** whether the browser window is hidden (or visible) */
    private hidden = false;
    /**
     * Whether the vue component is intersecting.
     * Default value is set on constructor from {@link DefaultOptions} if any, otherwise set to false
     */
    private intersected: boolean;
    /** how often to call the handler */
    private readonly interval: number;
    /** the timeout will be adjusted by +- this number of ms each iteration */
    private readonly offset: number;
    /** the event handler to call each interval period */
    protected readonly eventHandler: FreshEventHandler;
    /** the one-shot timer handler */
    private handle?: number;

    /**
     * Once destroyed it cannot be enabled.
     *
     * Given a refresh action is usually coupled to a Vue component,
     * and its lifecycle, once the component is destroyed, the refresh action does not execute anymore.
     */
    private destroyed = false;
    private readonly visibilityHandler: number;

    constructor(interval: number, handler: FreshEventHandler, defaultOptions?: DefaultOptions) {
        this.eventHandler = handler;
        this.interval = interval;
        if (!Number.isFinite(this.interval)) {
            throw new Error('not a valid interval number');
        }
        this.offset = defaultTo(0, defaultOptions?.offset);
        this.intersected = defaultTo(false, defaultOptions?.intersected);

        log.debug('Refresh action created with interval: %d, offset: %d', this.interval, this.offset);

        // Listen to the global changes on the document visibility (document.hidden)
        // Given the visibility of the document is something that is global, I do not see the point of exposing
        // a method to tweak the visibility. I think is better to couple to this behaviour just in one place.
        // If not, that leads to multiple components using the v-visibility-change directive and more code in
        // components that is less testable.
        this.visibilityHandler = visibility.change((evt, hidden) => {
            this.onVisible(hidden);
        });
    }

    private get offsetInterval(): number {
        if (this.offset) {
            return this.interval + _.random(-this.offset, this.offset, false);
        } else {
            return this.interval;
        }
    }

    public enable(): this {
        this._enabled = true;
        this.tryStart();
        return this;
    }

    /**
     * This method must be called from the Vue mounted() method
     */
    public onMounted(): this {
        this.mounted = true;
        this.tryStart();

        return this;
    }

    /**
     * This method must be called from the Vue beforeDestroy() method
     */
    public onDestroy(): void {
        this.destroyed = true;
        visibility.unbind(this.visibilityHandler);
        this.stop();
    }

    /**
     * This callback is executed when the document visibility changes at a
     * global level (typically the user leaves the tab).
     */
    private onVisible(hidden: boolean): this {
        this.hidden = hidden;
        if (this.hidden) {
            this.stop();
        } else {
            this.tryStart();
        }

        return this;
    }

    /**
     * This method must be called from the Vue intersect directive (v-intersect)
     */
    public onIntersect(isIntersected: boolean): this {
        this.intersected = isIntersected;
        if (this.intersected) {
            this.tryStart();
        } else {
            this.stop();
        }

        return this;
    }

    private tryStart(): void {
        if (this.destroyed) {
            log.info('refresh was already destroyed. Will not be started');
            return;
        }

        // check if the event handler is logically enabled
        if (this._enabled && this.mounted && !this.hidden && !this.handle && this.intersected) {
            // if it is, then call handler with a first delay of zero (next tick).
            this.handle = window.setTimeout(() => this.onTimeout(), 0);
        }
    }

    private async onTimeout(): Promise<void> {
        if (this.destroyed) {
            log.info('refresh was already destroyed. Will not execute handler');
            return;
        }

        // check if the event handler is logically enabled
        if (this._enabled && this.mounted && !this.hidden && this.intersected) {
            // If the document is hidden in the browser we might not have got an event, but suppress the
            // refresh action (without stopping the timer). This means if the tab becomes visible again the
            // the refresh action should be run (but it won't be run immediately).
            if (!document.hidden) {
                try {
                    await this.execute();
                } catch (err: unknown) {
                    // we eat the exception and we keep going, we just log an error
                    assert.ok(err instanceof Error);
                    log.error('Unexpected error in refresh handler: %s', err.message);
                }
            } else {
                log.debug('refresh action stopped due to browser tab inactive');
            }
            // if the handler is still enabled, restart the timer.
            if (this._enabled && this.mounted && !this.hidden) {
                this.handle = window.setTimeout(() => this.onTimeout(), this.offsetInterval);
            } else {
                // otherwise the handler is now disabled, so clear the handle (which acts as a lock)
                this.handle = undefined;
            }
        } else {
            // ignore the timeout and clear the handle (assuming it has expired)
            this.handle = undefined;
        }
    }

    /**
     * Calls the handler
     */
    protected async execute(): Promise<void> {
        return await this.eventHandler();
    }

    private stop(): void {
        if (this.handle) {
            const handle: number = this.handle;
            this.handle = undefined;
            window.clearTimeout(handle);
        }
    }
}
