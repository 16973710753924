import { ResectionPlane } from '@/lib/api/representation/interfaces';
import { CaseComponentRepresentation } from '@/lib/api/representation/case/CaseComponentRepresentation';
import {
    HipStemCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
import { HipHeadRepresentation } from '@/lib/api/representation/case/hip/HipHeadRepresentation';
import { NumberArray3 } from '@/lib/api/representation/geometry/arrays';

export type StemProperties = {
    /**
     * femoral stem anteversion angle
     */
    angle_anteversion: number;

    /**
     * leg length difference between the anatomic and the new femur head centre
     */
    ll_diff: number;

    /**
     * The offset difference between the anatomic and the new femur head centre
     */
    offset_diff: number;

    hjc: NumberArray3;

    /** Shaft axis - used for retroversion/anteversion rotation */
    shaft_axis_direction?: NumberArray3;
    shaft_axis_point?: NumberArray3;

    /** The neck axis vector: used for extension-flexion rotation */
    neck_axis: NumberArray3;

    /** The posterior-anterior axis: used for varus-valgus rotation */
    pa_axis: NumberArray3;

    /**
     * Pivot point for posterior-anterior and extension-flexion rotation: most medial point of the stem on the calcar
     * plane. Aka the calcar-superiomedial landmark.
     *  */
    medial_pivot_point?: NumberArray3;

    /** The resection plane coordinates are in the scene coordinate system (not in the femur or stem coordinates) */
    resection_plane: ResectionPlane;

    /**
     * Possible values are '0' and '1'
     * '0' is suitable
     * '1' is not suitable
     *
     * The idea was to have values in the range 0-1, but that did not happen yet
     */
    suitability_score: number;

    // -------8<---------
    //
    //  Client side materialisation of link 'component' relationships

    component?: HipStemCatalogComponentRepresentation;
}

export type HipStemRepresentation = CaseComponentRepresentation & StemProperties & {
    //  Client side materialisation of link 'head' relationship
    head?: HipHeadRepresentation;
}

export function isSuitableStem(stem: StemProperties): boolean {
    return stem.suitability_score === 0;
}
