
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import {
        HipComponentsCatalogRepresentation,
    } from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import HipSurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateResource';
    import ArrowFromTo, {
        RowChange,
        RowChangeFactory,
    } from '@/components/case-manage/surgical-template/history/row/ArrowFromTo.vue';
    import {
        HipSurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
    import {
        anyCupAssemblyChange,
        equalCupAnteriorPosterior,
        equalCupAnteversion,
        equalCupInclination,
        equalCupMedialLateral,
        equalCupSuperiorInferior,
        hasCupLinerUriChanged,
        hasCupUriChanged,
    } from '@/lib/api/resource/case/surgical-template/hipTemplateComparison';
    import {
        HipCupCatalogComponentRepresentation,
    } from '@/lib/api/representation/catalog/hip/HipCupCatalogComponentRepresentation';
    import {
        HipLinerCatalogComponentRepresentation,
    } from '@/lib/api/representation/catalog/hip/HipLinerCatalogComponentRepresentation';
    import { formatAngle } from '@/lib/filters/format/formatAngle';
    import { formatLengthSign } from '@/lib/filters/format/formatLengthSign';
    import { mapState } from 'pinia';
    import { useHipCaseStore } from '@/hipPlanner/stores/case/hipCaseStore';

    /**
     * A simple ui component with to display a from -> to information.
     * The 'from' can be strike through.
     *
     * e.g.:
     * 'processing' -> completed
     * */
    @Component({
        methods: { anyCupAssemblyChange },
        components: { ArrowFromTo },
        computed: {
            ...mapState(useHipCaseStore, ['componentsCatalog']),
        },
    })
    export default class CupChanges extends Vue {
        @Prop({ required: true, validator: notNilValidator('current') })
        private current!: HipSurgicalTemplateRepresentation;

        @Prop({ required: true, validator: notNilValidator('previous') })
        private previous!: HipSurgicalTemplateRepresentation;

        private currentCup: HipCupCatalogComponentRepresentation | null = null;
        private previousCup: HipCupCatalogComponentRepresentation | null = null;

        private currentLiner: HipLinerCatalogComponentRepresentation | null = null;
        private previousLiner: HipLinerCatalogComponentRepresentation | null = null;

        protected componentsCatalog!: HipComponentsCatalogRepresentation;

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            if (hasCupUriChanged(this.current, this.previous)) {
                this.currentCup = await HipSurgicalTemplateResource.getCurrentCup(
                    this.current, this.componentsCatalog, this.$apiOptions);
                this.previousCup = await HipSurgicalTemplateResource.getCurrentCup(
                    this.previous, this.componentsCatalog, this.$apiOptions);
            }

            if (hasCupLinerUriChanged(this.current, this.previous)) {
                this.currentLiner = await HipSurgicalTemplateResource.getCurrentCupLiner(
                    this.current, this.componentsCatalog, this.$apiOptions);
                this.previousLiner = await HipSurgicalTemplateResource.getCurrentCupLiner(
                    this.previous, this.componentsCatalog, this.$apiOptions);
            }
        }

        protected get fromCup(): string | null {
            return this.previousCup?.size || null;
        }

        protected get toCup(): string | null {
            return this.currentCup?.size || null;
        }

        protected get fromLiner(): string | null {
            return this.previousLiner?.size || null;
        }

        protected get toLiner(): string | null {
            return this.currentLiner?.size || null;
        }

        protected get changes(): (RowChange | null)[] {
            return [
                hasCupUriChanged(this.current, this.previous) ?
                    RowChangeFactory.make(this.fromCup, this.toCup, 'Size') :
                    null,
                hasCupLinerUriChanged(this.current, this.previous) ?
                    RowChangeFactory.make(this.fromLiner, this.toLiner, 'Liner') :
                    null,
                !equalCupAnteversion(this.current.cup_rotation, this.previous.cup_rotation) ?
                    RowChangeFactory.make(
                        formatAngle(this.previous.cup_rotation.anteversion),
                        formatAngle(this.current.cup_rotation.anteversion),
                        'Anteversion') :
                    null,
                !equalCupInclination(this.current.cup_rotation, this.previous.cup_rotation) ?
                    RowChangeFactory.make(
                        formatAngle(this.previous.cup_rotation.inclination),
                        formatAngle(this.current.cup_rotation.inclination),
                        'Inclination') :
                    null,
                !equalCupMedialLateral(this.current.cup_offset, this.previous.cup_offset) ?
                    RowChangeFactory.make(
                        formatLengthSign(this.previous.cup_offset.ml),
                        formatLengthSign(this.current.cup_offset.ml),
                        'Medial - Lateral') :
                    null,
                !equalCupAnteriorPosterior(this.current.cup_offset, this.previous.cup_offset) ?
                    RowChangeFactory.make(
                        formatLengthSign(this.previous.cup_offset.ap),
                        formatLengthSign(this.current.cup_offset.ap),
                        'Anterior - Posterior') :
                    null,
                !equalCupSuperiorInferior(this.current.cup_offset, this.previous.cup_offset) ?
                    RowChangeFactory.make(
                        formatLengthSign(this.previous.cup_offset.si),
                        formatLengthSign(this.current.cup_offset.si),
                        'Superior - Inferior') :
                    null,
            ];
        }
    }
