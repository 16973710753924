
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import { PlanType } from '@/lib/constants/PlanType';
    import PlanItemStateProvider from '@/components/case-plan/PlanItemStateProvider.vue';
    import ProjectStoreProvider from '@/hipPlanner/components/state/project/ProjectStoreProvider.vue';
    import ProjectStore from '@/hipPlanner/components/state/project/ProjectStore';
    import { HipCaseRepresentation } from '@/lib/api/representation/case/HipCaseRepresentation';
    import GetItemKeyMixin from '@/components/shared/GetItemKeyMixin';

    /**
     * A top level page with a set of v-cards with plans for the case (project).
     *
     * Each project will have zero or more plans. After the surgical details and the
     * DICOM have been configured, segmentation and other processing is done that will
     * create an initial 'ACID' plan with the automated plan. The surgeon can then modify
     * the parameters of the case to approve additional plans.
     *
     * Note: This component is responsible for loading data. Other than the skeleton loader it has no
     * visible elements. The task of refreshing and keeping the list of plans up to date is the responsibility
     * of the child {@link Plans} component.
     */
    @Component({
        components: { ProjectStoreProvider, PlanItemStateProvider },
        mixins: [GetItemKeyMixin],
    })
    export default class Plan extends Vue {
        @InjectReactive() store!: ProjectStore;

        /** An ugly export of the enum type for the template */
        public readonly planType = PlanType;

        protected get project(): HipCaseRepresentation | null {
            return this.store.project ?? null;
        }

        protected get isLoading(): boolean {
            return !this.store.initialised;
        }
    }
