import { AlignmentModeEnum } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { SelectAlignmentOption } from '@/components/data/combobox/types/component-selectors/types';

const HipAlignmentModes: SelectAlignmentOption[] = [
    {
        value: AlignmentModeEnum.None,
        text: 'CT Scanner Coordinates',
    },
    {
        value: AlignmentModeEnum.APP,
        text: 'Anterior Pelvic Plane',
    },
    // TODO: Uncomment this once the FPP alignment mode
    //       is ready to be used. Currently the client side
    //       does not support the FPP alignment.
    //       This option can also be shown, but 'disabled' if
    //       the 'disabled: true' flag is used.
    // {
    //     value: AlignmentModeEnum.FPP,
    //     text: 'Functional Pelvic Plane',
    // },
];

export default HipAlignmentModes;
