
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import {
        RadialMeasurementValueRepresentation,
    } from '@/lib/api/representation/case/measurements/value/RadialMeasurementValueRepresentation';
    import { Product } from '@/lib/api/representation/ProductRepresentation';
    import MeasurementsUtil from '@/lib/api/resource/case/study/MeasurementsUtil';

    /**
     * A linear distance measurement.
     *
     * TODO: Add support for showing the two points the distance has been calculated from.
     */
    @Component({ components: {} })
    export default class RadialMeasurement extends Vue {
        @Prop({ required: true })
        value!: RadialMeasurementValueRepresentation;

        /** the name of the product - this is used for language string loading */
        @Prop({ required: true })
        public product!: Product;

        @Prop({ required: true })
        public group!: string;

        /** i18n identifier for the group name */
        protected titleId(): string {
            return MeasurementsUtil.i18nId(this.product, this.group, this.value.name);
        }
    }
