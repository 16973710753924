import LinkRelation from '@/lib/api/LinkRelation';
import { findByUri } from '@/lib/api/SemanticNetworkUtils';
import { Uri } from 'semantic-link';
import { FittedStemAndHead, FittedCupAndLiner } from '@/hipPlanner/components/state/types';
import { HipCaseStore } from '@/hipPlanner/stores/case/hipCaseStore';

/** Find a fitted-stem-and-head combination given their catalog identifiers */
export async function findStemAndHead(
    caseStore: HipCaseStore,
    stemComponentUri: Uri,
    headComponentUri: Uri,
    options?: {
        signal?: AbortSignal
        timeout?: number
    },
): Promise<FittedStemAndHead> {
    // Get the set of all fitted components
    const allComponents = await caseStore.getFittedComponents(options);

    // Find the specified fitted stem (and 'original' fitted-head) in the set of fitted components
    const stemAndOriginalHead = findByUri(
        allComponents.stems, stemComponentUri, LinkRelation.component, c => c.stem);
    if (!stemAndOriginalHead) {
        throw Error(`Could not find stem matching '${stemComponentUri}' in fitted components`);
    }

    // Find the specified head catalog-component
    const headComponent = caseStore.findComponentByUri('heads', headComponentUri);
    if (!headComponent) {
        throw Error(`Could not find head component ${headComponentUri}`);
    }

    // Return the fitted-stem and component-head
    return {
        stem: stemAndOriginalHead.stem,
        head: {
            links: [{ rel: LinkRelation.component, href: headComponentUri }],
            tmatrix: [...stemAndOriginalHead.head.tmatrix],
            offset: headComponent.offset,
            component: headComponent,
        },
    };
}

/**
 * Find a fitted-cup-and-liner combination given their catalog identifiers
 * */
export async function findCupAndLiner(
    caseStore: HipCaseStore,
    cupComponentUri: Uri,
    linerComponentUri: Uri,
    options?: {
        signal?: AbortSignal
        timeout?: number
    },
): Promise<FittedCupAndLiner> {
    // Get the set of all fitted components
    const allComponents = await caseStore.getFittedComponents(options);
    // Find the specified fitted cup (and 'original' fitted-liner) in the set of fitted components
    const cupAndOriginalLiner = findByUri(
        allComponents.cups, cupComponentUri, LinkRelation.component, c => c.cup);
    if (!cupAndOriginalLiner) {
        throw Error(`Could not find cup matching '${cupAndOriginalLiner}' in fitted components`);
    }

    // Find the specified liner catalog-component
    const linerComponent = caseStore.findComponentByUri('liners', linerComponentUri);
    if (!linerComponent) {
        throw Error(`Could not find liner component ${linerComponentUri}`);
    }

    // Return the fitted-cup and a fitted-liner created by combining the catalog-component with placement
    // info from the original liner
    return {
        cup: cupAndOriginalLiner.cup,
        liner: {
            links: [{ rel: LinkRelation.component, href: linerComponentUri }],
            name: linerComponent.name,
            head_centre: [...cupAndOriginalLiner.liner.head_centre],
            tmatrix: [...cupAndOriginalLiner.liner.tmatrix],
            component: linerComponent,
        },
    };
}
