import Implant3d from '@/hipPlanner/assembly/objects/Implant3d';
import { asVector3 } from '@/lib/base/ThreeUtil';
import anylogger from 'anylogger';
import AxisObject, { attachAxis } from '@/hipPlanner/assembly/objects/AxisObject';
import { StemAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { RigidMatrix4 } from '@/lib/base/RigidTransform';
import { AxesHelper, Matrix4 } from 'three';
import { makeAxesHelper } from '@/lib/base/helpers';
import { ResectionPlane } from '@/lib/api/representation/interfaces';
import GroupObject from '@/hipPlanner/assembly/objects/GroupObject';
import { NumberArray3 } from '@/lib/api/representation/geometry/arrays';
import { HipAssembly } from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { CaseStem } from '@/hipPlanner/components/state/types';

const log = anylogger('hipStemAssembly');

export enum StemGroupAxis {
    NeckShaft = 'neck-shaft',
    StemShaft = 'stem-shaft',
    PosteriorAnteriorAxis = 'pa-axis',
}

export enum StemGroupPlane {
    StemResection = 'stem-resection'
}

/**
 * Create a stem-assembly containing a stem-group as parent of the given stem and head components.
 *
 * The head and stem are expected to be in the 'native' arrangement:
 * - the femur in its native or pre-operative position in the scene
 * - the stem is fitted to the femur
 * - the head is fitted to the stem, so it is **not** connected to the cup
 *
 * We create the stem-group with the given transform, which should correspond to the centre-of-rotation
 * (COR) of the head.
 *
 * - To change to the 'retracted' post-operative arrangement we 'socket' the stem in the cup by translating
 *   the stem-group so its origin matches the cup COR
 * - To change to the 'native' pre-operative arrangement we record the original transformation and shift
 *   the stem back.
 */
export function makeStemAssembly(
    transform: RigidMatrix4,
    head: Implant3d,
    stem: Implant3d,
    caseStem: CaseStem): StemAssembly {
    // Create the stem-group object and attach stem and head meshes
    const stemGroup = new GroupObject({
        name: HipAssembly.StemGroup,
        transform,
        debugColor: STEM_GROUP_COLOR,
    });
    stemGroup.attach(head, stem);

    const stemResectionPlane = attachResectionPlane(stemGroup, caseStem.resection_plane);

    let stemNeckAxis, stemShaftAxis, paAxisPosition;
    if (caseStem?.medial_pivot_point) {
        stemNeckAxis = tryAttachAxis(
            stemGroup,
            StemGroupAxis.NeckShaft,
            NECK_SHAFT_COLOR,
            caseStem?.medial_pivot_point,
            caseStem?.neck_axis,
        );
        stemShaftAxis = tryAttachAxis(
            stemGroup,
            StemGroupAxis.StemShaft,
            STEM_SHAFT_COLOR,
            caseStem?.shaft_axis_point,
            caseStem?.shaft_axis_direction,
        );
        paAxisPosition = caseStem.medial_pivot_point;
    } else {
        log.warn('Stem representation is missing stem-transformation data (old case?)');
        // For old cases the HipStemRepresentation will not have the neck-shaft axis and other properties
        // needed to apply manual stem positioning
        paAxisPosition = caseStem.hjc;
    }

    return {
        stemGroup,
        head,
        stem,
        stemResectionPlane,
        stemNeckAxis,
        stemShaftAxis,
        paAxis: attachAxis(
            stemGroup,
            paAxisPosition,
            caseStem?.pa_axis,
            {
                name: StemGroupAxis.PosteriorAnteriorAxis,
                color: PA_AXIS_COLOR,
                visible: false,
            },
        ),
    };
}

/** True if the assembly has properties necessary to do stem-rotation */
export function hasStemRotationAxes(assembly: StemAssembly): boolean {
    return !!assembly.stemNeckAxis && !!assembly.stemShaftAxis && !!assembly.paAxis;
}

const NECK_SHAFT_COLOR = '#39ea6f'; // Green
const STEM_SHAFT_COLOR = '#2929f1'; // Deep Blue
const PA_AXIS_COLOR = '#ea2222'; // Red
const RESECTION_PLANE_COLOR = '#ea5f5f'; // Salmon
const STEM_GROUP_COLOR = '#3685ff'; // Lighter blue

/**
 * Attach an axis to the given group object.
 */
function tryAttachAxis(
    stemGroup: GroupObject,
    name: string,
    color: string,
    origin?: NumberArray3,
    dir?: NumberArray3): AxisObject | undefined {
    if (origin && dir) {
        return attachAxis(stemGroup, origin, dir, {
            name,
            color,
            visible: false,
        });
    } else {
        throw Error(`Not able to add ${name} to stem assembly: representation is missing data`);
    }
}

/**
 * @return the data to make a resection plane
 *
 * Note: The resection plane has to be updated as the stem is translated/rotated
 * - The {@HipStemRepresentation.resection_plane} is in the scene coordinate system.
 * - The {@link stemTransform} is in the femoral coordinate system.
 */
function attachResectionPlane(
    stemGroup: GroupObject,
    representation: ResectionPlane): AxesHelper {
    const plane = makeAxesHelper({
        name: StemGroupPlane.StemResection,
        visible: false,
        matrixAutoUpdate: false,
        textColor: RESECTION_PLANE_COLOR,
        transform: new Matrix4().makeBasis(
            asVector3(representation.x),
            asVector3(representation.y),
            asVector3(representation.normal),
        ).setPosition(asVector3(representation.origin)),
    });
    stemGroup.theObject.attach(plane);
    return plane;
}
