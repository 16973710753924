import { Material, Mesh, MeshBasicMaterial, SphereGeometry, Vector3 } from 'three';
import SpriteText from 'three-spritetext';
import { asVector3, AsVector3, worldPosition } from '@/lib/base/ThreeUtil';
import { AcidObject3dBase } from '@/lib/planning/objects-3D/AcidObject3d';

export type PointProperties = {
    name: string
    worldPosition: AsVector3
    visible: boolean
    textHeight: number
    color: string
    radius: number
}

const DEFAULT_POINT_COLOR = '#cccccc';
const DEFAULT_POINT_VISIBLE = true;
const DEFAULT_POINT_RADIUS = 2;

export default class PointObject {
    private readonly _object: Mesh;

    constructor(properties: Partial<PointProperties> = {}) {
        this._object = new Mesh(
            PointObject.makeGeometry(properties.radius ?? DEFAULT_POINT_RADIUS),
            PointObject.makeMaterial(properties.color ?? DEFAULT_POINT_COLOR),
        );
        this._object.name = properties.name ?? '';
        if (properties.worldPosition) {
            this._object.position.copy(asVector3(properties.worldPosition));
        }
        this._object.visible = properties.visible ?? DEFAULT_POINT_VISIBLE;
        if (properties.name) {
            this._object.name = properties.name;
            const text = new SpriteText(properties.name, properties.textHeight ?? 5, properties.color ?? 'white');
            text.visible = true;
            this._object.add(text);
        }
    }

    public get object(): Mesh {
        return this._object;
    }

    get localPosition(): Vector3 {
        return this._object.position;
    }

    get worldPosition(): Vector3 {
        return worldPosition(this._object);
    }

    private static makeGeometry(radius: number): SphereGeometry {
        return new SphereGeometry(radius, 32, 20);
    }

    private static makeMaterial(color: string): Material {
        return new MeshBasicMaterial({ color });
    }
}

/**
 * Attach a point to the given object.
 */
export function attachPoint(
    parent: AcidObject3dBase,
    properties: Partial<PointProperties>,
): PointObject {
    const point = new PointObject(properties);
    parent.theObject.attach(point.object);
    return point;
}
