import { defineStore } from 'pinia';
import AuthenticatorResource from '@/lib/api/resource/auth/AuthenticatorResource';

export const useForgotPassword = defineStore('forgotPassword', {
    state: () => ({
        isDialogVisible: true,
        isLoading: false,
        isSubmitted: false,
        isSubmitting: false,
        email: '',
        error: '',
    }),
    actions: {
        async sendEmailWithResetLink(): Promise<void> {
            this.error = '';
            this.isSubmitted = false;
            this.isSubmitting = true;

            AuthenticatorResource
                .resetPassword(this.$http, this.$api, this.email, this.$apiOptions)
                .then((result) => {
                    if (result) {
                        this.isSubmitted = true;
                    } else {
                        this.error = `Sending a password reset failed`;
                    }
                })
                .catch(() => {
                    this.error = `Password reset failed`;
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },
    },
});

export type ForgotPasswordStore = ReturnType<typeof useForgotPassword>;
