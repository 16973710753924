import { Matrix3, Matrix4, Vector3 } from 'three';
import { approxEquals, DEFAULT_TOLERANCE } from '@/lib/base/ThreeUtil';
import {
    EulerAnglesRepresentation,
    EulerRotationOrder,
    isApproxEmptyRotation,
    RigidTransformRepresentation,
    TransformType,
} from '@/lib/api/representation/geometry/transforms';
import {
    eulerAnglesFromTransform,
    isEulerAngles,
    makeEulerAngles,
    makeEulerTransform,
} from '@/lib/base/EulerRotation';
import assert from 'assert';
import ViewerUtils from '@/lib/planning/viewer/ViewerUtils';
import { toMatrixRepresentation } from '@/lib/base/matrix';
import { cloneDeep } from 'lodash';
import { Ensure } from '@/lib/base/CustomTypes';

/**
 * Type alias to use in places we are using a Matrix4 that is always a rigid transform
 */
export type RigidMatrix4 = Matrix4;

/**
 * A rigid transformation
 */
export type RigidTransform = {
    type: 'rigid-transform'
    matrix: Matrix4 | null
    translation: Vector3 | null
    rotation: EulerAnglesRepresentation | null
}

export type FullRigidTransform = Ensure<RigidTransform, 'matrix' | 'translation' | 'rotation'>

export function isFullRigidTransform(transform: RigidTransform): transform is FullRigidTransform {
    return isRigidTransform(transform) && transform.matrix !== null && transform.translation !== null &&
        transform.rotation !== null;
}

/**
 * Make the rigid transform matrix for the given representation
 */
export function makeMatrix(transform: RigidTransform): RigidMatrix4 {
    if (transform.matrix) {
        assert.ok(verifyRigidTransform(transform));
        return transform.matrix.clone();
    } else {
        const result = isEulerAngles(transform.rotation) ? makeEulerTransform(transform.rotation) : new Matrix4();
        return result.setPosition(transform.translation ?? new Vector3());
    }
}

/**
 * Create a rigid transform as represented in Euler angles
 */
export function makeRigidTransform(
    properties?: Partial<RigidTransform>): RigidTransform {
    return { type: 'rigid-transform', matrix: null, translation: null, rotation: null, ...properties };
}

export function makeEulerRigidTransform(
    matrix?: Matrix4, rotationOrder: EulerRotationOrder | null = null): RigidTransform {
    return makeRigidTransform({
        matrix,
        translation: matrix ?
            new Vector3().setFromMatrixPosition(matrix) :
            new Vector3(),
        rotation: matrix ?
            eulerAnglesFromTransform(matrix, rotationOrder) :
            makeEulerAngles({ order: rotationOrder }),
    });
}

/**
 * Type-guard for rigid transform representations
 */
export function isRigidTransform(representation?: unknown): representation is RigidTransform {
    return !!representation && (representation as RigidTransform).type === 'rigid-transform';
}

/**
 * Return true if the given rigid-transformation representation is consistent.
 *
 * - Not all 4x4 matrices correspond to rigid transformations.
 * - There is some redundancy in the representation, and we can check that the redundant parts match.
 */
export function verifyRigidTransform(representation: RigidTransform): boolean {
    // Check the matrix is rigid
    const matrix = representation.matrix ?? new Matrix4();
    if (!isRigid(matrix)) {
        return false;
    }

    // Check the rotation representation, if present, matches the rotational effect of
    // the matrix (inside tolerance)
    if (isEulerAngles(representation.rotation)) {
        const impliedRotation = makeEulerTransform(representation.rotation);
        if (!approxEquals(rotationalPart(impliedRotation), rotationalPart(matrix))) {
            return false;
        }
    }

    // Check the translation representation, if present, matches the translational effect
    // of the matrix (inside tolerance)
    if (representation.translation) {
        if (!approxEquals(positionalPart(matrix), representation.translation)) {
            return false;
        }
    }

    return true;
}

/**
 * Check whether a Matrix3 is a pure rotation matrix (orthogonal and not mirrored).
 */
export function isRotational(matrix: Matrix3, tolerance = DEFAULT_TOLERANCE): boolean {
    // M is a rotational matrix if and only if M is orthogonal i.e.:
    // M * transpose(M) == I, and det(M) = 1
    if (Math.abs(matrix.determinant() - 1) > tolerance) {
        return false;
    } else {
        return approxEquals(new Matrix3(), matrix.clone().transpose().multiply(matrix), tolerance);
    }
}

/**
 * Returns the rotational part of a Matrix4 (the top-left 3x3 elements) as a Matrix3.
 */
export function rotationalPart(matrix4: Matrix4): Matrix3 {
    return (new Matrix3()).setFromMatrix4(matrix4.clone());
}

/**
 * Return the position part of a Matrix4 (the top-right 3x1 elements) as a Vector3.
 */
export function positionalPart(matrix: Matrix4): Vector3 {
    return new Vector3().setFromMatrixPosition(matrix.clone());
}

/**
 * Check whether the given Matrix4 is actually a rigid transform matrix
 */
export function isRigid(matrix: Matrix4, tolerance = DEFAULT_TOLERANCE): boolean {
    // In a Three Matrix the elements array is in *column-major order*
    // see https://threejs.org/docs/#api/en/math/Matrix4
    // This means the bottom row of the matrix is at indices 3, 7, 11, 15
    const elements = matrix.elements;
    if (Math.abs(elements[3]) > tolerance ||
        Math.abs(elements[7]) > tolerance ||
        Math.abs(elements[11]) > tolerance ||
        Math.abs(elements[15] - 1) > tolerance) {
        return false;
    } else {
        return isRotational(rotationalPart(matrix), tolerance);
    }
}

export function toRepresentation(transform: RigidTransform): RigidTransformRepresentation {
    return {
        type: TransformType.Rigid,
        matrix: transform.matrix ? toMatrixRepresentation(transform.matrix) : undefined,
        translation: transform.translation?.clone()?.toArray(),
        rotation: transform.rotation ? cloneDeep(transform.rotation) : undefined,
    };
}

export function fromRepresentation(transform: RigidTransformRepresentation | null): RigidTransform {
    if (!transform) {
        return makeNullRigidTransform();
    }

    const matrix = transform?.matrix ? ViewerUtils.makeMatrixFromArray(transform?.matrix) : new Matrix4();
    return makeEulerRigidTransform(matrix, transform?.rotation?.order);
}

/**
 * @returns whether the transform has values that are not the defaults
 */
export function isApproxEmptyRigidTransform(transform: RigidTransform): boolean {
    const {
        matrix,
        translation,
        rotation,
    } = transform;
    const noMatrix = !matrix || approxEquals(new Matrix4(), matrix);
    const noTranslation = !translation || approxEquals(new Vector3(), (translation));
    const noRotation = !rotation || isApproxEmptyRotation(rotation);
    return noMatrix && noTranslation && noRotation;
}

export function makeNullRigidTransform(): RigidTransform {
    return {
        type: 'rigid-transform',
        matrix: null,
        translation: null,
        rotation: {
            type: 'euler-angles',
            x: null,
            y: null,
            z: null,
            order: null,
        },
    };
}
