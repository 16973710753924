import { DeepNonNullable } from '@/lib/base/CustomTypes';
import {
    CatalogComponentRepresentation,
    isComponentFromCatalog,
} from '@/lib/api/representation/catalog/CatalogComponentRepresentation';
import { HipStemRepresentation, StemProperties } from '@/lib/api/representation/case/hip/HipStemRepresentation';
import { HeadProperties, HipHeadRepresentation } from '@/lib/api/representation/case/hip/HipHeadRepresentation';
import { LinerProperties } from '@/lib/api/representation/case/hip/HipLinerRepresentation';
import { CupProperties, HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import {
    ComponentOffsetRepresentation,
} from '@/lib/api/representation/case/surgical-template/common/ComponentOffsetRepresentation';
import { CaseComponentRepresentation } from '@/lib/api/representation/case/CaseComponentRepresentation';
import { ModelRepresentation } from '@/lib/api/representation/ModelRepresentation';
import { MatrixArray16 } from '@/lib/api/representation/geometry/arrays';
import {
    HipStemHeadCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemHeadCatalogComponentRepresentation';
import {
    HipStemCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
import {
    HipLinerCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipLinerCatalogComponentRepresentation';
import {
    HipCupCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipCupCatalogComponentRepresentation';
import { Matrix4, Vector3 } from 'three';
import { asMatrix4, getPosition } from '@/lib/base/matrix';
import { asVector3 } from '@/lib/base/ThreeUtil';
import { FittedComponentsRepresentation } from '@/hipPlanner/stores/case/fittedComponents';

export type CupOffset = DeepNonNullable<ComponentOffsetRepresentation>;

/**
 * Complete representation of a component in a particular hip-case.
 *
 * It is essentially the same as a CaseComponentRepresentation but with a defined *component* property
 * and without a nested coupled component (e.g. head to stem, liner to cup)
 */
export type CaseComponent<
    CatalogComponent extends CatalogComponentRepresentation,
    Properties> = ModelRepresentation & Omit<Properties, 'component'> & {
    tmatrix: MatrixArray16;

    component: CatalogComponent;
}

/**
 * Complete representation of a head-component in a particular hip-case, including its catalog-component info
 */
export type CaseHead = CaseComponent<HipStemHeadCatalogComponentRepresentation, HeadProperties>;

/**
 * Complete representation of a stem-component in a particular hip-case, including its catalog-component info
 */
export type CaseStem = CaseComponent<HipStemCatalogComponentRepresentation, StemProperties> & {
    // The transform for a head with zero offset
    headTransform: Matrix4

    // the head offset the stem was originally fitted with
    headOffset: number
};

/**
 * Complete representation of a liner-component in a particular hip-case, including its catalog-component info
 */
export type CaseLiner = CaseComponent<HipLinerCatalogComponentRepresentation, LinerProperties>;

/**
 * Complete representation of a cup-component in a particular hip-case, including its catalog-component info
 */
export type CaseCup = CaseComponent<HipCupCatalogComponentRepresentation, CupProperties>;

/**
 * A stem-and-head combination as fit by the automated placement
 */
export type FittedStemAndHead = {
    stem: CaseStem
    head: CaseHead
}

/**
 * A cup-and-liner combination as fit by the automated placement
 */
export type FittedCupAndLiner = {
    cup: CaseCup
    liner: CaseLiner
}

/**
 * Collection of all the components as fit by automated placement
 */
export type FittedComponents = {
    stems: FittedStemAndHead[]
    cups: FittedCupAndLiner[]
}

/**
 * Ensure an API-style representation is a functional component.
 *
 * The functional component will have a defined catalog-component property ('.component' is defined) and will
 * be missing coupled-components like head for stem and liner for cup (in type-terms anyway).
 */
function asCaseComponent<CatalogComponent extends CatalogComponentRepresentation, Properties>(
    representation: CaseComponentRepresentation & { component?: CatalogComponent } & Properties):
    CaseComponent<CatalogComponent, Properties> {

    const catalogComponent = representation.component;
    if (catalogComponent === undefined) {
        throw Error('Catalog component is undefined');
    } else if (!isComponentFromCatalog(representation.component)) {
        throw Error('Unknown catalog component');
    }
    return representation as CaseComponent<CatalogComponent, Properties>;
}

/**
 * Convert an API-style representation of a set of fitted components to a functional version of those
 * components.
 */
export function asFittedComponents(representation: FittedComponentsRepresentation): FittedComponents {
    return {
        stems: representation.stems.map(s => {
            if (s.head === undefined) {
                throw Error('Undefined head in HipStemRepresentation');
            }
            return {
                stem: asCaseStem(s),
                head: asCaseHead(s.head),
            };
        }),
        cups: representation.cups.map(c => asFittedCupAndLiner(c)),
    };
}

/**
 * Convert an API-style representation of a fitted head combination to a functional version of the
 * same head.
 */
export function asCaseHead(representation: HipHeadRepresentation): CaseHead {
    return asCaseComponent(representation);
}

/**
 * Convert an API-style representation of a fitted stem-and-head combination to a functional version of the
 * same stem.
 */
export function asCaseStem(representation: HipStemRepresentation): CaseStem {
    if (representation.head === undefined) {
        throw Error('Undefined head in HipStemRepresentation');
    }
    return {
        ...asCaseComponent(representation),
        headTransform: calculateTransformForZeroOffsetTransform(
            asCaseComponent(representation.head),
            asVector3(representation.neck_axis),
        ),
        headOffset: representation.head.offset,
    };
}

/**
 * Convert an API-style representation of a fitted cup-and-liner combination to a functional version of the
 * same cup-and-liner.
 */
export function asFittedCupAndLiner(representation: HipCupRepresentation): FittedCupAndLiner {
    if (representation.liner === undefined) {
        throw Error('Undefined liner in HipCupRepresentation');
    }
    return {
        cup: asCaseComponent(representation),
        liner: asCaseComponent(representation.liner),
    };
}

/**
 * Calculate the transform for a head-component with zero offset.
 */
function calculateTransformForZeroOffsetTransform(head: CaseHead, stemNeckAxis: Vector3): Matrix4 {
    const fittedHeadTransform = asMatrix4(head.tmatrix);
    const newHeadPosition = getPosition(fittedHeadTransform)
        .add(stemNeckAxis.multiplyScalar(-head.component.offset));
    return fittedHeadTransform.setPosition(newHeadPosition);
}
