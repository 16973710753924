
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import DragDropUtil from '@/lib/dragdrop/DragDropUtil';
    import { LinkUtil, Uri } from 'semantic-link';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
    import ResourceUtil from '@/lib/api/ResourceUtil';
    import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';

    import anylogger from 'anylogger';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import LinkRelation from '@/lib/api/LinkRelation';
    import ScanUploadDragHere from '@/components/case-settings/scan/ScanUploadDragHere.vue';
    import { PatientUtil } from '@/components/case-settings/utils/PatientUtil';
    import StudyDetails from '@/components/case-settings/scan/StudyDetails.vue';
    import ScanUploadWorkflow from '@/components/case-settings/scan/ScanUploadWorkflow.vue';
    import ScanUploadPreventDrag from '@/components/case-settings/scan/ScanUploadPreventDrag.vue';

    const log = anylogger('ScanUpload');

    /**
     * This the upload 'panel' used to allow upload (via drag-n-drop) of DICOM files.
     *
     */
    @Component({
        components: {
            StudyDetails,
            ScanUploadDragHere,
            ScanUploadWorkflow,
            ScanUploadPreventDrag,
        },
    })
    export default class ScanUpload extends Vue {
        /** The case (project) */
        @Prop({ required: true })
        protected value!: CaseRepresentation;

        protected dataTransfer: DataTransfer | null = null;

        /** the active study (if any) */
        private study: StudyRepresentation | null = null;

        private isLoading = false;

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            await this.getStudy();
        }

        /**
         * Determine if the case has an active study
         */
        protected get hasStudy(): boolean {
            return !!this.studyUri;
        }

        protected get studyUri(): Uri {
            if (this.value) {
                if (LinkUtil.matches(this.value, LinkRelation.activeStudy)) {
                    return LinkUtil.getUri(this.value, LinkRelation.activeStudy) || '';
                }
            }
            return '';
        }

        @Watch('studyUri')
        async onChangeInActiveStudy(): Promise<void> {
            await this.getStudy();
        }

        private async getStudy(): Promise<void> {
            const caseItem = this.value;
            if (caseItem) {
                this.study = await ResourceUtil.IgnoreNotFound(
                    async () => await CaseStudyResource.getCaseStudyWithFiles(caseItem, this.$apiOptions));
            } else {
                log.error('Case unexpected empty');
            }
        }

        /**
         * This support dropping the DICOM files (and any other files, which are ignored) on the
         * panel which will result in them being parsed, anonymised and uploaded to the server via the API.
         */
        onDrop(event: DragEvent): boolean {
            log.debug('Drop scan');

            if (event.dataTransfer) {
                // setting the data transfer will result in the data transfer/upload dialog popping
                //  up and process the data transfer
                this.dataTransfer = event.dataTransfer;
            }
            event.preventDefault();
            return false;
        }

        onDragover(event: DragEvent): boolean {
            return DragDropUtil.dragover(event);
        }

        onDragenter(event: DragEvent): boolean {
            return DragDropUtil.dragenter(event);
        }

        onDragleave(event: DragEvent): void {
            DragDropUtil.dragleave(event);
        }

        public get arePatientBasicDetailsCompleted(): boolean {
            if (this.value) {
                if (this.value.patient) {
                    return PatientUtil.arePatientBasicDetailsCompleted(this.value.patient);
                }
            }

            return false;
        }
    }
