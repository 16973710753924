import { OrthographicCamera, PerspectiveCamera, Scene, Vector3, WebGLRenderer } from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import RendererUtil from '@/lib/planning/viewer/RendererUtil';
import BasicViewerUtil from '@/lib/planning/viewer/BasicViewerUtil';
import { makePerspectiveCamera, makeTrackballControls } from '@/lib/scene/camera';
import { DEFAULT_CAMERA_DIRECTION, DEFAULT_CAMERA_DISTANCE } from '@/lib/planning/camera/CameraManUtil';

/**
 * The supported camera types
 *
 * Note:
 * - The perspective camera is used on the planning viewer
 * - The orthographic camera is used in the coverage view
 * - Three js has other camera 'StereoCamera' that we are not using.
 */
export type SupportedCameraType = PerspectiveCamera | OrthographicCamera;

/**
 * A class that brings together the main 3 objects needed in the three js space: camera, scene, renderer.
 *
 */
export default class BasicViewer {
    public constructor(
        /**
         * The parent container of the canvas element.
         *
         * Note:
         *   It will be used to set the size (width * height) of the canvas.
         *   It will be used to resize the canvas.
         *   It does not have to be the immediate parent.
         *      - In the hip app, it is the main window.
         *      - In the shoulder app, there are multiple views, and each one has a particular parent.
         */
        private _container: HTMLElement,
        public readonly renderer: WebGLRenderer,
        public readonly scene: Scene,
        public readonly camera: SupportedCameraType,
        /**
         * Camera controls for our camera.
         *
         * The controls cannot be initialised without a camera and the scene DOM element.
         * Also, the controls cannot be dynamically switched between scenes
         */
        public readonly cameraControls: TrackballControls,
        /**
         * Transform controls for our 3d objects.
         *
         * These controls can include translation (movement), rotation, and scaling
         */
        public readonly transformControls?: TransformControls) {
    }

    /** the canvas where the renderer draws its output */
    get canvas(): HTMLCanvasElement {
        return this.renderer.domElement;
    }

    public animate(): this {
        RendererUtil.animate(this.renderer, this.render.bind(this));
        return this;
    }

    public resize(): void {
        BasicViewerUtil.resize(this._container, this.renderer, this.camera as PerspectiveCamera);
    }

    public destroy(): void {
        RendererUtil.destroy(this.renderer);
    }

    /**
     * Render all the elements of the scene (scenes, cameras, objects)
     */
    private render(): void {
        this.cameraControls.update();

        RendererUtil.setClearColor(this.renderer); // TODO this can be on renderer creation

        this.renderer.render(this.scene, this.camera);
    }
}

/**
 * @param scene
 * @param container: the DOM element where the scene canvas lives.
 * The size of the renderer is set according to this container
 * @param canvas: A Canvas where the renderer draws its output.
 * @param lookAt
 * @param camera
 * @param controls
 * @param transformControls
 */
export function makeViewer(
    scene: Scene,
    container: HTMLElement,
    canvas: HTMLCanvasElement,
    lookAt?: Vector3,
    camera?: SupportedCameraType,
    controls?: TrackballControls,
    transformControls?: TransformControls): BasicViewer {
    const renderer = RendererUtil.make3DRenderer(container, canvas);

    camera = camera ?? makePerspectiveCamera(container);
    lookAt = lookAt ?? new Vector3();
    camera.position.copy(
        DEFAULT_CAMERA_DIRECTION
            .clone()
            .multiplyScalar(DEFAULT_CAMERA_DISTANCE)
            .add(lookAt),
    );
    camera.lookAt(lookAt);

    controls = controls ?? makeTrackballControls(camera, canvas);

    let result: BasicViewer;
    if (transformControls) {
        // add event listener to disable scene rotation while using the transform controls
        transformControls.addEventListener('dragging-changed', (event) => {
            transformControls.enabled = !event.value;
        });

        result = new BasicViewer(container, renderer, scene, camera, controls, transformControls);
    } else {
        result = new BasicViewer(container, renderer, scene, camera, controls);
    }

    result.animate();
    result.resize();

    return result;
}
