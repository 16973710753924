import { OrthographicCamera, PerspectiveCamera, Vector3 } from 'three';
import * as THREE from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import LPS from '@/lib/base/LPS';

export function makePerspectiveCamera(container: HTMLElement): PerspectiveCamera {
    const camera = new THREE.PerspectiveCamera();
    const DEFAULTS = {
        fov: 45,
        near: 0.1,
        far: 20000,
        distance: 500,
        position: LPS.Anterior.multiplyScalar(500),
        lookAt: new Vector3(0, 0, 0),
    };

    camera.fov = DEFAULTS.fov;
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.near = DEFAULTS.near;
    camera.far = DEFAULTS.far;

    camera.position.copy(DEFAULTS.position);
    camera.lookAt(DEFAULTS.lookAt);

    return camera;
}

export function makeTrackballControls(
    camera: PerspectiveCamera | OrthographicCamera,
    canvas: HTMLCanvasElement): TrackballControls {
    // There seems to be some conflicting issues with Vue and TrackballControls capturing right mouse click/drag,
    // so we need to pass the content DOM element to the camera TrackballControls in order to overcome this.
    //
    // There have been other reports on this issue, however the proposed solution was to create the canvas element
    // outside the Vue app, which we do not want to do.
    // @see (@link https://laracasts.com/discuss/channels/vue/vue-interfering-with-html5-canvas-somehow?#reply=297215)
    //
    // One solution that did solve the problem was to attach the content to the camera controls.
    // ThreeJS also offers another set of camera controls called OrbitControls, which do seem to work, however
    // these controls get locked when the camera is looking straight up/down and restrict the free camera movement
    // over the poles (Y axis).
    const cameraControls = new TrackballControls(camera, canvas);

    const DEFAULTS = {
        panSpeed: 2.0,
        zoomSpeed: 5.0,
        rotateSpeed: 3.0,

        // Max distance of the camera from the initial camera position
        maxDistance: 1000,
    };

    cameraControls.panSpeed = DEFAULTS.panSpeed;
    cameraControls.zoomSpeed = DEFAULTS.zoomSpeed;
    cameraControls.rotateSpeed = DEFAULTS.rotateSpeed;
    cameraControls.maxDistance = DEFAULTS.maxDistance;

    // If staticMoving is set to false, camera movement doesn't stop right away, but rather comes to a stop slowly
    cameraControls.staticMoving = true;

    // There is no option to disable keyboard keys for camera movement, so we set this to an empty array
    cameraControls.keys = [];

    return cameraControls;
}
