import { defineStore } from 'pinia';
import anylogger from 'anylogger';
import { HipCaseRepresentation } from '@/lib/api/representation/case/HipCaseRepresentation';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { HipSurgicalSpecificationRepresentation } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { isEmpty } from 'ramda';
import assert from 'assert';
import Bugsnag from '@bugsnag/js';
import { LinkUtil, Uri } from 'semantic-link';
import { getRequiredSelfUri } from '@/lib/api/SemanticNetworkUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import { HipCaseState } from '@/hipPlanner/stores/case/HipCaseState';
import {
    loadCase,
    loadParentOnSurgicalTemplate,
    loadStudyOnSurgicalTemplate,
    loadSurgicalSpecificationOnSurgicalTemplate,
    loadSurgicalTemplate,
    PlannerLoadingContext,
    reloadCurrentPlanOnSurgicalTemplate,
    validateCaseProduct,
} from '@/hipPlanner/stores/case/loading';
import ApiResource from '@/lib/api/resource/ApiResource';
import { Product } from '@/lib/api/representation/ProductRepresentation';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import { BodySide } from '@/lib/api/representation/interfaces';
import { calculateSceneOrigin } from '@/hipPlanner/stores/case/sceneOrigin';
import { isErrorCausedBy } from '@/hipPlanner/views/hipPlannerServices';
import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
import {
    SurgicalTemplateRecordState,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import { asyncTimeout } from '@/util/asyncTimeout';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { asFittedComponents, FittedComponents } from '@/hipPlanner/components/state/types';
import ResourceUtil from '@/lib/api/ResourceUtil';
import {
    CatalogComponentType, getHipComponentsCatalog,
    HipCatalogCollectionName,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
import {
    getFittedComponentsRepresentation,
} from '@/hipPlanner/stores/case/fittedComponents';
import { TimeoutError } from '@/util/errors';

const log = anylogger('hip-case');

const HipCaseStoreCancelReason = 'hip case store cancelled';

/**
 * A pinia-store that nominally represents the current case (aka project).
 *
 * A better name might be something like 'api-store', in that it is a local and reactive version
 * of API data, most of which is associated with a particular case.
 *
 * The notable functions are:
 *
 * - Initial load of data
 * - Syncing (reloading) the manual surgical-template and current plan
 * - Caching the list of catalog-components
 * - Creating 'fitted' components, which are each a combination of a catalog-component with
 *   placement information specific to a case
 *
 * The **case-specific** data is represented in the state by the 'caseResource' HipCaseRepresentation,
 * and various other api-representations are stuck on the caseResource as they
 * are loaded because 'semantic-network' or something. This other case-data includes:
 *
 * - the specification (surgicalSpecification)
 * - the study (study)
 * - the manual surgical-template (surgicalTemplate)
 * - the automated surgical-template (automatedTemplate)
 * - the manual-plan, if it exists (manualPlan)
 */
export const useHipCaseStore = defineStore('hipCase', {
    state: (): HipCaseState => ({
        loadingState: 'init',
        errorMessages: [],
        caseResource: null,
        componentsCatalog: null,
        sceneOrigin: null,
        cancellation: new AbortController(),
    }),
    getters: {
        project(this: HipCaseState): HipCaseRepresentation | null {
            return this.caseResource ?? null;
        },
        surgicalTemplate(this: HipCaseState): HipSurgicalTemplateRepresentation | null {
            return this.caseResource?.surgicalTemplate || null;
        },
        automatedTemplate(): HipSurgicalTemplateRepresentation | null {
            return this.surgicalTemplate?.parent as HipSurgicalTemplateRepresentation ?? null;
        },
        /**
         * The current manual plan.
         * Before a user approves a plan this value will be null.
         * If a user has approved a plan, it will be populated and available.
         */
        manualPlan(): PlanRepresentation | null {
            return this.caseResource?.surgicalTemplate?.currentPlan as PlanRepresentation ?? null;
        },
        /**
         * If the user surgical template if being created. This will be true only the first time the user
         * enters the 3D page after a new acid-surgical-template was processed.
         */
        isUserSurgicalTemplateBeingCreated(): boolean {
            const surgicalTemplate = this.surgicalTemplate;
            if (surgicalTemplate) {
                return SurgicalTemplateUtil.isNew(surgicalTemplate) ||
                    SurgicalTemplateUtil.isProcessing(surgicalTemplate);
            }

            return false;
        },
        study(): StudyRepresentation | null {
            return this.surgicalTemplate?.study || null;
        },
        studyMeasurements(): MeasurementsRepresentation | null {
            return this.study?.measurements || null;
        },
        surgicalSpecification(): HipSurgicalSpecificationRepresentation | null {
            return this.surgicalTemplate?.surgicalSpecification || null;
        },
        hasErrors(): boolean {
            return !isEmpty(this.errorMessages);
        },
        context(): PlannerLoadingContext {
            return {
                api: this.$api,
                apiOptions: { ...this.$apiOptions, signal: this.cancellation.signal },
                product: Product.Hip,
                addErrorMessage: (message) => (this.errorMessages = [...this.errorMessages, message]),
            };
        },
        isReady(): boolean {
            const predicates = [
                this.loadingState === 'completed',
                !this.hasErrors,
                this.caseResource,
                this.study,
                this.studyMeasurements,
                this.surgicalTemplate,
                this.surgicalSpecification,
                !!this.sceneOrigin,
            ];

            return predicates.every(p => !!p);
        },
        operativeSide(): BodySide {
            if (this.caseResource?.side) {
                return this.caseResource.side;
            } else {
                throw Error('Operative side is not loaded');
            }
        },
    },
    actions: {
        /** Cancel loading the current case */
        cancel(): void {
            this.cancellation.abort({ name: HipCaseStoreCancelReason });
        },

        /** Load the data for a particular case */
        async loadCase(apiUri: string): Promise<void> {
            log.info(`Loading case: ${apiUri}`);
            try {
                this.loadingState = 'loading';
                await this.loadNeededResources(apiUri);
                this.loadingState = 'completed';
            } catch (e: unknown) {
                if (isErrorCausedBy(e, HipCaseStoreCancelReason)) {
                    log.info(
                        'Case store loading cancelled while in state: \'%s\' - (%s)',
                        this.loadingState,
                        apiUri);
                    this.loadingState = 'cancelled';
                    // nothing do to
                } else {
                    this.loadingState = 'error';

                    assert.ok(e instanceof Error);
                    const message =
                        `Case store loading: Something unexpected happened while in state ` +
                        `'${this.loadingState}' - (${apiUri}). Stack: ${e}'`;
                    log.error(message);
                    this.context.addErrorMessage(message);
                    Bugsnag.notify(e);
                }
            }
        },

        /** Load resources for a particular case. This isn't an action */
        async loadNeededResources(apiUri: Uri): Promise<void> {
            await ApiResource.getApi(this.context.api, this.context.apiOptions);

            log.info(`Loading case resources...`);
            const caseResource = await loadCase<HipCaseRepresentation>(this.context, apiUri);

            // Mutating the state here to reproduce original behavior, but I'm not sure if it's meaningful or not.
            this.caseResource = caseResource;

            if (caseResource && validateCaseProduct(this.context, caseResource)) {
                log.info('Loading components catalog...');
                this.componentsCatalog = await getHipComponentsCatalog(this.context.api, this.context.apiOptions);

                log.info('Loading surgical template...');
                const surgicalTemplate = await loadSurgicalTemplate(
                    this.context, caseResource);

                if (surgicalTemplate) {
                    log.info('Loading automated surgical template...');
                    await loadParentOnSurgicalTemplate(this.context, surgicalTemplate);

                    log.info('Loading study...');
                    await loadStudyOnSurgicalTemplate(this.context, caseResource, surgicalTemplate);

                    log.info('Loading surgical specification...');
                    await loadSurgicalSpecificationOnSurgicalTemplate(this.context, surgicalTemplate);

                    log.info('Loading manual plan if any...');
                    await reloadCurrentPlanOnSurgicalTemplate(this.context.apiOptions, caseResource, surgicalTemplate);

                    assert.ok(!!this.study, 'Study is not defined when attempting to calculate scene-origin');
                    this.sceneOrigin = await calculateSceneOrigin(this.study, this.context);

                    log.info(`Resources loaded for case ${apiUri}`);
                }
            }
        },

        /**
         * Find the representation of a catalog-component.
         *
         * @param collectionName the collection the component is in i.e. 'cups', 'liners', 'stems', 'heads'.
         *   See {@link HipComponentsCatalogRepresentation}
         * @param uri the uri that identifies the catalog-component
         */
        findComponentByUri<Name extends HipCatalogCollectionName>(
            collectionName: Name,
            uri: string,
        ): CatalogComponentType<Name> | null {
            assert(this.componentsCatalog, 'No components catalog');
            const components = this.componentsCatalog[collectionName];
            return components.find(
                (component) => getRequiredSelfUri(component) === uri
            ) as CatalogComponentType<Name> ?? null;
        },

        /**
         * Repeatedly fetch the user-surgical-template (this.surgicalTemplate) on the API until it is complete
         * (has finished any server-side processing).
         *
         * When it is complete the current plan will be loaded on the template.
         *
         * NOTE: The significant effect of this action is to update this.surgicalTemplate and the current plan,
         * which will cause other updates in everything watching it,
         *
         * @throws TimeoutError if the template is not fetched as complete inside timeout.
         */
        async syncManualTemplate(
            options?: {
                timeout?: number,
                signal?: AbortSignal
            },
        ): Promise<void> {
            const project = this.project;
            assert(project, 'project was not defined');
            const cacheOptions: CacheOptions = {
                ...this.context.apiOptions,
                signal: options?.signal ?? this.context.apiOptions.signal,
                forceLoad: true,
            };
            const template = await pollForUserTemplate(
                project,
                (template) => template.record_state === SurgicalTemplateRecordState.Completed,
                cacheOptions,
                options?.timeout ?? DEFAULT_POLL_TIMEOUT_MILLISECONDS,
            );
            assert(template === this.surgicalTemplate);
            await reloadCurrentPlanOnSurgicalTemplate(cacheOptions, project, template);
        },

        /** Get the fitted-components for the current surgical-template. These are derived from the
         * {@link FittedComponentsRepresentation} on the API, which is linked on the surgical-template as either
         * its [component-ranking]{@link LinkRelation.componentRanking} or
         * [component-set]{@link LinkRelation.componentSet} ('components').
         *
         * This action will track the 'resource' on the surgical-template
         *
         * @throws TimeoutError if the template.
         * */
        async getFittedComponents(
            options?: {
                timeout?: number,
                signal?: AbortSignal,
            },
        ): Promise<FittedComponents> {
            const project = this.project;
            assert(project, 'project is not defined');
            const template = this.surgicalTemplate;
            assert(template, 'surgical-template is not defined');
            const componentsCatalog = this.componentsCatalog;
            assert(componentsCatalog, 'Missing components catalog');

            const cacheOptions: CacheOptions = {
                ...this.$apiOptions,
                signal: options?.signal ?? this.context.apiOptions.signal,
            };

            // Get the uri of the current fitted components
            const fittedComponentsUri = await pollForFittedComponentsUri(
                project,
                cacheOptions,
                options?.timeout ?? DEFAULT_POLL_TIMEOUT_MILLISECONDS);

            // Get the fitted components-representation
            const representation = await getFittedComponentsRepresentation(
                componentsCatalog, this.$http, fittedComponentsUri, options
            );
            // Track the resource on the template
            ResourceUtil.setResource(template, 'components', representation, this.$apiOptions);

            return asFittedComponents(representation);
        },
    },
});

export type HipCaseStore = ReturnType<typeof useHipCaseStore>;

const DEFAULT_POLL_TIMEOUT_MILLISECONDS = 60 * 1000 * 5;
const POLL_INTERVAL_MILLISECONDS = 1000;

/** Log every Nth attempt */
const LOG_POLLING_ATTEMPT = 5;

/**
 * Repeatedly fetch the user-surgical-template until a condition is met or a timeout reached
 *
 * @throws TimeoutError if the timeout elapses.
 */
async function pollForUserTemplate(
    project: HipCaseRepresentation,
    condition: (template: HipSurgicalTemplateRepresentation) => boolean,
    options: CacheOptions,
    timeout: number,
): Promise<HipSurgicalTemplateRepresentation> {

    options = { ...options, forceLoad: true };

    // Attempt to fetch the template
    const fetch = async (): Promise<HipSurgicalTemplateRepresentation | null> => {
        const template = await SurgicalTemplateResource
            .getCaseUserSurgicalTemplate<HipSurgicalTemplateRepresentation>(project, options);
        return (template !== null && condition(template)) ? template : null;
    };

    // Calculate the time we need to time-out
    const timeoutTime = Date.now() + timeout;

    for (let attempt = 1;; ++attempt) {
        options?.signal?.throwIfAborted();

        // Attempt to fetch the user template
        const template = await fetch();
        if (template) {
            if (attempt > 1) {
                log.log('Surgical template fetched after %d attempts', attempt);
            }
            return template;
        }
        options?.signal?.throwIfAborted();

        if (Date.now() >= timeoutTime) {
            throw new TimeoutError('Timed-out while attempting to fetch surgical template');
        }

        if (attempt++ % LOG_POLLING_ATTEMPT === 0) {
            log('Attempt %s to get surgical template...');
        }

        await asyncTimeout(POLL_INTERVAL_MILLISECONDS, options?.signal);
    }
}

/**
 * Fetch the user-surgical-template (this.surgicalTemplate) until the components-uri is available
 *
 * @throws TimeoutError if the timeout elapses.
 */
async function pollForFittedComponentsUri(
    project: HipCaseRepresentation,
    options: CacheOptions,
    timeout: number,
): Promise<string> {
    const getUri = (template: HipSurgicalTemplateRepresentation): string | undefined =>
        LinkUtil.getUri(template, LinkRelation.componentSet);
    const template = await pollForUserTemplate(
        project,
        template => !!getUri(template),
        options,
        timeout
    );
    const uri = getUri(template);
    assert(uri);
    return uri;
}
