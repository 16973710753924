import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import pinia from '@/stores';
import '@/plugins/api';
import '@/plugins/veevalidate';
import Logging from '@/lib/logging/Logging';
import anylogger from 'anylogger';
import axios from 'axios';
import Fragment from 'vue-fragment';
import { useAxios } from 'semantic-link';
import visibility from 'vue-visibility-change';
import { transformPlyResponse } from '@/lib/http/ModelGeometryLoader';
import { AuthEventBusPlugin } from '@/plugins/authEventBus';
import AxiosPlugin, { AxiosPiniaPlugin } from '@/plugins/axios';
import InitVueGtag from '@/plugins/vuegtag';
import { applicationVersion, productVersion } from '@/lib/version';
import AxiosBrowserCacheUrlMutation from '@/lib/http/AxiosBrowserCacheUrlMutation';
import { FiltersPlugin } from '@/plugins/filters';
import AsyncComputed from 'vue-async-computed';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import Bugsnag from '@bugsnag/js';
import VueHtmlToPaper from 'vue-html-to-paper';
import i18n from '@/plugins/i18n';
// @ts-ignore TODO: missing typing information in the package
import TextHighlight from 'vue-text-highlight';
import RequestQueue from '@/lib/http/RequestQueue';
import AuthenticatorService from '@/lib/http/AuthenticatorService';
import OfflineService from '@/lib/http/OfflineService';
import * as amplitude from '@amplitude/analytics-browser';
import { amplitudeApiKey } from '@/analytics/amplitude';
import { parseHeadMetaTag } from '@/lib/metaTagUtil';

const log = anylogger('main');

// Some of the library code is using the global instance of axios, so
// for now we need to use the same instance.
export const globalAxios = axios;

export const authEventBus = new Vue();
export const queue = new RequestQueue(globalAxios);
export const authService = new AuthenticatorService(globalAxios, authEventBus, queue);
const _offlineService = new OfflineService(globalAxios, authEventBus);

// TODO: stop using public CDN for material design icons
// import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.config.productionTip = false;

Logging.setupLogging();
log.info('Starting v%s', applicationVersion() || 'DEV BUILD');

InitVueGtag.init(router);


if (axios.defaults.headers) {
    // axios.defaults.baseURL = ;
    // axios.defaults.withCredentials = true;
    axios.defaults.headers.common.Accept = 'application/json;q=1.0';
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    if (axios.defaults.transformResponse instanceof Array) {
        axios.defaults.transformResponse.splice(0, 0, transformPlyResponse);
    } else {
        log.error('Failed to install PLY response transformer');
    }
} else {
    throw new Error('Axios headers not initialized');
}

function bugsnagApiKey(): string {
    return parseHeadMetaTag('acid-bugsnag-api-key', 'a1151e09196fc93e8bfb5605af9ffa2d');
}

function bugsnagReleaseStage(): string {
    return parseHeadMetaTag('acid-bugsnag-release-stage', '');
}

// Configure bugsnag to capture any stack traces or errors. This is configurable during
// deployment via the index.html file.
//
// see
// - https://docs.bugsnag.com/platforms/javascript/vue/
const apiKey = bugsnagApiKey();
const releaseStage = bugsnagReleaseStage();
if (apiKey && releaseStage) {
    Bugsnag.start({
        appType: 'client',
        apiKey,
        plugins: [new BugsnagPluginVue()],
        appVersion: productVersion(),
        releaseStage,
    });

    Bugsnag
        .getPlugin('vue')
        ?.installVueErrorHandler(Vue);
}
//  WARNING: This is important for CSRF support in django. What it will do is enable
// the copying of the 'csrftoken' value to the 'X-CSRFTOKEN' header so that PUT/DELETE
// requests work (otherwise they fail with 403 and a body with "CSRF token missing or incorrect.")
//
// see
//  - https://stackoverflow.com/questions/39254562/csrf-with-django-reactredux-using-axios
// axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
// axios.defaults.xsrfCookieName = 'csrftoken';

useAxios(axios);
AxiosBrowserCacheUrlMutation.addBrowserCacheUrlMunging(axios);

Vue.use(VueHtmlToPaper);
Vue.use(AsyncComputed);
Vue.use(FiltersPlugin);
Vue.use(AxiosPlugin, { instance: globalAxios });
pinia.use(AxiosPiniaPlugin(globalAxios));

Vue.use(AuthEventBusPlugin, { authEventBus });
Vue.mixin({
    methods: {
        $propagatedEmit(event, payload) {
            let vm = this.$parent;
            while (vm) {
                vm.$emit(event, payload);
                vm = vm.$parent;
            }
        },
    },
});

// page visibility directive
Vue.use(visibility);
// log.info('here %o', visibility);
// visibility.change((evt, hidden) => {
//     log.debug('The ACID Vue app is %s', hidden ? 'hidden' : 'visible');
// });

Vue.use(Fragment.Plugin);

// Add vue-text-highlight as a global component https://www.npmjs.com/package/vue-text-highlight
Vue.component('TextHighlight', TextHighlight);

amplitude.init(amplitudeApiKey());

new Vue({
    router,
    pinia,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
