/**
 * Enumerated bones that have been segmented or resected
 *
 * For reference please see:
 *   - acid.domain.segmentation.segmentation_types.Model for the model 'type'
 *   - acid.domain.segmentation.segmentation_types.ModelFlavour for the model 'subtype'
 */
export enum Bones {
    /**
     * Hip
     */
    OperativeFemur = 'operative-femur',
    ContraFemur = 'contralateral-femur',
    OperativeHemi = 'operative-hemipelvis',
    ContraHemi = 'contralateral-hemipelvis',
    InnerSurface = 'operative-femur-internal',
    Sacrum = 'sacrum',

    /**
     * Spine
     */
    T1Vertebra = 't-1-vertebra',
    T2Vertebra = 't-2-vertebra',
    T3Vertebra = 't-3-vertebra',
    T4Vertebra = 't-4-vertebra',
    T5Vertebra = 't-5-vertebra',
    T6Vertebra = 't-6-vertebra',
    T7Vertebra = 't-7-vertebra',
    T8Vertebra = 't-8-vertebra',
    T9Vertebra = 't-9-vertebra',
    T10Vertebra = 't-10-vertebra',
    T11Vertebra = 't-11-vertebra',
    T12Vertebra = 't-12-vertebra',
    L1Vertebra = 'l-1-vertebra',
    L2Vertebra = 'l-2-vertebra',
    L3Vertebra = 'l-3-vertebra',
    L4Vertebra = 'l-4-vertebra',
    L5Vertebra = 'l-5-vertebra',
    LeftAndRightHemipelvis = 'left-and-right-hemipelvis',
    LeftHemipelvis = 'left-hemipelvis',
    RightHemipelvis = 'right-hemipelvis',
    Unknown = '',
}

export type HipBoneAlias =
    Bones.OperativeFemur |
    Bones.ContraFemur |
    Bones.OperativeHemi |
    Bones.ContraHemi |
    Bones.InnerSurface |
    Bones.Sacrum
