import {
    MeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/MeasurementValueRepresentation';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import {
    MeasurementGroupName,
    MeasurementGroupNames,
} from '@/lib/api/representation/case/measurements/MeasurementGroupName';
import { StudyMeasurementNames } from '@/lib/api/representation/case/study/measurements/StudyMeasurementName';
import {
    MeasurementGroupRepresentation,
} from '@/lib/api/representation/case/measurements/MeasurementGroupRepresentation';
import { compact } from 'lodash';
import anylogger from 'anylogger';
import {
    HipTemplateMeasurementName,
} from '@/lib/api/representation/case/surgical-template/hip/measurements/HipTemplateMeasurementName';
import { Product } from '@/lib/api/representation/ProductRepresentation';
import { isPlaneMeasurement } from '@/debugView/assembly/MeasurementUtil';
import { MeasurementValueTypes } from '@/lib/api/representation/case/measurements/MeasurementValueType';
import {
    CoordinateSystemMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/CoordinateSystemMeasurementValueRepresentation';

const log = anylogger('log');

export type MeasurementName = StudyMeasurementNames | HipTemplateMeasurementName;

export type MeasurementsContainer = MeasurementsRepresentation | MeasurementGroupRepresentation

export default class MeasurementsUtil {
    /**
     * TODO Add enums for groups
     */
    public static getMeasurementByGroupAndName<T extends MeasurementValueRepresentation>(
        measurements: MeasurementsContainer, groupName: MeasurementGroupName, name: MeasurementName): T | null {
        const group = this.getGroupByName(measurements, groupName);
        if (group) {
            // some measurements values are null on the api, need to filter them until it is fixed
            const measurement = compact<MeasurementValueRepresentation>(group.values)
                .find((value) => value.name === name) as T;
            if (measurement) {
                return measurement;
            } else {
                log.warn('Could not find measurement with name \'%s\'', name);
            }
        } else {
            log.warn('Could not find measurement group with name \'%s\'', groupName);
        }
        return null;
    }

    public static getGroupByName(
        measurements: MeasurementsContainer, groupName: MeasurementGroupName,
    ): MeasurementGroupRepresentation | null {
        return measurements?.groups?.find((group: MeasurementGroupRepresentation) => group.name === groupName) ?? null;
    }

    /**
     * @returns i18n identifier for the group name
     */
    public static i18nGroupId(product: Product, name: string): string {
        return `measurement.${product}.group.${name.replace(/\./g, '-')}.name`;
    }

    /**
     * @returns the i18n id for a a measurement.
     * @see {@link Measurements.en.json5}
     */
    public static i18nId(product: Product, group: string, name: string): string {
        return `measurement.${product}.${group}.${name.replace(/\./g, '-')}`;
    }

    /**
     * Return client side {@link CoordinateSystemMeasurementValueRepresentation} for the hip, by combining the
     * vectors of the CT, ISB and APP planes with the Acetabulum right and left planes.
     *
     * TODO: If this turns out to be useful, move the construction of these measurements to the api.
     */
    public static makePelvisCoordinateSystems(
        measurements: MeasurementsRepresentation): MeasurementValueRepresentation[] {
        const coordinateSystemMeasurements = [
            { vectors: StudyMeasurementNames.CTPlane, origin: StudyMeasurementNames.AcetabulumPlaneRight },
            { vectors: StudyMeasurementNames.CTPlane, origin: StudyMeasurementNames.AcetabulumPlaneLeft },
            { vectors: StudyMeasurementNames.AnteriorPelvicPlane, origin: StudyMeasurementNames.AcetabulumPlaneRight },
            { vectors: StudyMeasurementNames.AnteriorPelvicPlane, origin: StudyMeasurementNames.AcetabulumPlaneLeft },
            { vectors: StudyMeasurementNames.ISBPelvicPlane, origin: StudyMeasurementNames.AcetabulumPlaneRight },
            { vectors: StudyMeasurementNames.ISBPelvicPlane, origin: StudyMeasurementNames.AcetabulumPlaneLeft },
        ];

        return compact(
            coordinateSystemMeasurements.map((data): CoordinateSystemMeasurementValueRepresentation | null => {
                const vectorsProvider = MeasurementsUtil.getMeasurementByGroupAndName(
                    measurements, MeasurementGroupNames.HipPelvis, data.vectors);
                const originProvider = MeasurementsUtil.getMeasurementByGroupAndName(
                    measurements, MeasurementGroupNames.HipPelvis, data.origin);

                if (isPlaneMeasurement(vectorsProvider)) {
                    if (isPlaneMeasurement(originProvider)) {
                        return {
                            name: `${vectorsProvider.name}_${originProvider.name}`,
                            type: MeasurementValueTypes.CoordinateSystem,
                            value: vectorsProvider.value,
                            x: vectorsProvider.x,
                            y: vectorsProvider.y,
                            origin: originProvider.origin,
                        };
                    } else {
                        log.info('Measurement with name \'%s\' was expected to be a plane', data.vectors);
                    }
                } else {
                    log.info('Measurement with name \'%s\' was expected to be a plane', data.origin);
                }

                return null;
            }));
    }
}
