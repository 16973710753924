import { AxiosInstance, AxiosResponse } from 'axios';
import { ComponentSelectorOptions } from '@/components/data/combobox/types/component-selectors/types';
import { apiBaseUrl } from '@/lib/url';

interface HipStemType {
    tags: string[]
    name: string
    type: string
    offset: string
    system: string
}

interface HipStem {
    id: string
    title: string
}

interface ApiResponse {
    items: HipStem[]
}

export async function getHipStemTypes(http: AxiosInstance): Promise<HipStemType[]> {
    return http
        .get<ApiResponse>(`${apiBaseUrl()}/component/hip-stem/type`)
        .then((response: AxiosResponse) => {
            const hipStemTypeIds = response.data.items.map(({ id }: HipStem) => id);
            const responses = hipStemTypeIds.map((id: string) => getHipStemType(http, id));
            return Promise.all(responses);
        });
}

export async function getHipStemType(http: AxiosInstance, hipStemTypeId: string): Promise<HipStemType> {
    return await http
        .get<HipStemType>(hipStemTypeId)
        .then(({ data }: AxiosResponse) => data);
}

export async function getHipStemTypeOptions(http: AxiosInstance): Promise<ComponentSelectorOptions> {
    const hipStemTypes: HipStemType[] = await getHipStemTypes(http).catch(() => []);
    const hipStemOptions: ComponentSelectorOptions = [{ header: 'Stem Types' }];

    hipStemTypes.forEach((item: HipStemType) => {
        const typeOffset = item.type.concat('[').concat(item.offset).concat(']');
        hipStemOptions.push({
            text: item.name,
            value: {
                system: item.system,
                type: typeOffset,
            },
        });
    });

    return hipStemOptions;
}
