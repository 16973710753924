
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';

    /**
     */
    @Component({ components: {} })
    export default class HomeNoData extends Vue {
        @InjectReactive()
        protected searchText!: string;
    }
