import { BodySide, BodySideType } from '@/lib/api/representation/interfaces';
import {
    AngleMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/AngleMeasurementValueRepresentation';
import { StudyMeasurementNames } from '@/lib/api/representation/case/study/measurements/StudyMeasurementName';
import {
    AxisMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/AxisMeasurementValueRepresentation';
import {
    DistanceMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/DistanceMeasurementValueRepresentation';
import {
    MeasurementGroupName,
    MeasurementGroupNames,
} from '@/lib/api/representation/case/measurements/MeasurementGroupName';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import MeasurementsUtil from '@/lib/api/resource/case/study/MeasurementsUtil';
import { AlignmentModeEnum } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import {
    PlaneMeasurementValueRepresentation,
} from '@/lib/api/representation/case/measurements/value/PlaneMeasurementValueRepresentation';

/**
 * A utility to operate with a study measurement
 *
 * @see {@class MeasurementsRepresentation}
 */
export default class StudyMeasurementsUtil extends MeasurementsUtil {
    /**
     * The acetabular anteversion measurement.
     *
     * Note: This measurement comes from the 'pelvis' group.
     * TODO: There is another measurement with a similar name: 'hip_hemipelvis_anteversion' in the group of
     *       'right-hemi' or 'left-hemi', but those measurement are in the process of being deprecated.
     *
     * The measurement from the pelvis group is a **more accurate measurement** than the one described before, given
     * the anteversion measurement is done against a plane defined in the pelvis coordinate system (which will vary
     * on the alignment mode none, app, or fpp), AND to define that plane accurately a **full pelvis** is needed.
     *
     * e.g.:
     * The APP plane will be defined using 3 points that are present in a full pelvis:
     * 1. the left ASIS point
     * 2. the right ASIS point
     * 3. the pubis symphysis (which will be measure using both the left and right pubis landmarks)
     *
     * @deprecated: Use the new radiographic measurements
     */
    public static getAcetabularAnteversion(
        measurements: MeasurementsRepresentation, side: BodySideType): AngleMeasurementValueRepresentation | null {
        // TODO: Consider having mapping utility for left/right side measurement names?
        const measurementName = side === BodySide.Left ?
            StudyMeasurementNames.AcetabularAnteversionAngleLeft :
            StudyMeasurementNames.AcetabularAnteversionAngleRight;
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            measurements, MeasurementGroupNames.HipPelvis, measurementName);
    }

    /**
     * Get the anterior pelvic plane from the pelvis
     */
    public static getAnteriorPelvicPlane(
        measurements: MeasurementsRepresentation): PlaneMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<PlaneMeasurementValueRepresentation>(
            measurements, MeasurementGroupNames.HipPelvis, StudyMeasurementNames.AnteriorPelvicPlane);
    }

    /**
     * Get the acetabular plane define by the normal pointing **inwards** from the pelvis.
     *
     * Note: Most of the usages of this function will need to then **negate** the normal to work with it.
     *
     * TODO: We could get the plane from the hemipelvis rather than the pelvis when the pelvis/hemipelvis
     *       differences caused by PCA/RBF fitting are resolved.
     *
     * N = Normal pointing inwards
     *
     *  ~ ~ -  ,
     *  Cup       ' ,
     *                ,
     *                 ,
     *   N              ,                      Normal is pointing inwards
     *  ---------------,--------------------------->
     *                  ,
     *                 ,
     *                ,
     *             , '
     *  _  _ ,
     */
    public static getAcetabularPlane(
        measurements: MeasurementsRepresentation, side: BodySideType): PlaneMeasurementValueRepresentation | null {
        const measurementName = side === BodySide.Left ?
            StudyMeasurementNames.AcetabulumPlaneLeft :
            StudyMeasurementNames.AcetabulumPlaneRight;
        return MeasurementsUtil.getMeasurementByGroupAndName<PlaneMeasurementValueRepresentation>(
            measurements, MeasurementGroupNames.HipPelvis, measurementName);
    }

    /**
     * Get the radiographic anteversion for the given side and the alignment mode (CT or APP).
     */
    public static getRadiographicAnteversion(
        measurements: MeasurementsRepresentation,
        side: BodySideType,
        alignmentMode: AlignmentModeEnum): AngleMeasurementValueRepresentation | null {
        if (alignmentMode === AlignmentModeEnum.None) {
            return StudyMeasurementsUtil.getHemiPelvisAnteversionCT(measurements, side);
        } else if (alignmentMode === AlignmentModeEnum.APP) {
            return StudyMeasurementsUtil.getPelvisAnteversionAPP(measurements, side);
        } else {
            throw new Error(`Invalid alignment mode ${alignmentMode}`);
        }
    }

    /**
     * Get the radiographic inclination for the given side and the alignment mode (CT or APP).
     */
    public static getRadiographicInclination(
        measurements: MeasurementsRepresentation,
        side: BodySideType,
        alignmentMode: AlignmentModeEnum): AngleMeasurementValueRepresentation | null {
        if (alignmentMode === AlignmentModeEnum.None) {
            return StudyMeasurementsUtil.getHemiPelvisInclinationCT(measurements, side);
        } else if (alignmentMode === AlignmentModeEnum.APP) {
            return StudyMeasurementsUtil.getPelvisInclinationAPP(measurements, side);
        } else {
            throw new Error(`Invalid alignment mode ${alignmentMode}`);
        }
    }

    private static getHemiPelvisAnteversionCT(
        measurements: MeasurementsRepresentation, side: BodySideType): AngleMeasurementValueRepresentation | null {
        const groupName = side === BodySide.Left ?
            MeasurementGroupNames.HipHemiLeft :
            MeasurementGroupNames.HipHemiRight;
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            measurements, groupName, StudyMeasurementNames.HipHemiPelvisRadiographicAnteversionCT);
    }

    private static getHemiPelvisInclinationCT(
        measurements: MeasurementsRepresentation, side: BodySideType): AngleMeasurementValueRepresentation | null {
        const groupName = side === BodySide.Left ?
            MeasurementGroupNames.HipHemiLeft :
            MeasurementGroupNames.HipHemiRight;
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            measurements, groupName, StudyMeasurementNames.HipHemiPelvisRadiographicInclinationCT);
    }

    private static getPelvisAnteversionAPP(
        measurements: MeasurementsRepresentation,
        side: BodySideType): AngleMeasurementValueRepresentation | null {
        const measurementName = side === BodySide.Left ?
            StudyMeasurementNames.HipAPPRadiographicAnteversionLeft :
            StudyMeasurementNames.HipAPPRadiographicAnteversionRight;
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            measurements, MeasurementGroupNames.HipPelvis, measurementName);
    }

    private static getPelvisInclinationAPP(
        measurements: MeasurementsRepresentation,
        side: BodySideType): AngleMeasurementValueRepresentation | null {
        const measurementName = side === BodySide.Left ?
            StudyMeasurementNames.HipAPPRadiographicInclinationLeft :
            StudyMeasurementNames.HipAPPRadiographicInclinationRight;
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            measurements, MeasurementGroupNames.HipPelvis, measurementName);
    }

    /**
     * The acetabular abduction measurement. (a.k.a "acetabular inclination")
     *
     * Note: This measurement comes from the 'pelvis' group.
     * TODO: There is another measurement with a similar name: 'hip_hemipelvis_abduction' in the group of
     *       'right-hemi' or 'left-hemi', but those measurement are in the process of being deprecated.
     *
     * The measurement from the pelvis group is a **more accurate measurement** than the one described before, given
     * the abduction measurement is done against a plane defined in the pelvis coordinate system (which will vary
     * on the alignment mode none, app, or fpp), AND to define that plane accurately a **full pelvis** is needed.
     *
     * e.g.:
     * The APP plane will be defined using 3 points that are present in a full pelvis:
     * 1. the left ASIS point
     * 2. the right ASIS point
     * 3. the pubis symphysis (which will be measure using both the left and right pubis landmarks)
     *
     * @deprecated: Use the new radiographic measurements
     */
    public static getAcetabularAbduction(
        measurements: MeasurementsRepresentation, side: BodySideType): AngleMeasurementValueRepresentation | null {
        const measurementName = side === BodySide.Left ?
            StudyMeasurementNames.AcetabularAbductionAngleLeft :
            StudyMeasurementNames.AcetabularAbductionAngleRight;
        return MeasurementsUtil.getMeasurementByGroupAndName<AngleMeasurementValueRepresentation>(
            measurements, MeasurementGroupNames.HipPelvis, measurementName);
    }

    public static getLegLengthDifference(
        measurements: MeasurementsRepresentation): DistanceMeasurementValueRepresentation | null {
        return MeasurementsUtil.getMeasurementByGroupAndName<DistanceMeasurementValueRepresentation>(
            measurements, MeasurementGroupNames.HipPelvis, StudyMeasurementNames.LegLengthDifference);
    }

    public static getFemurAnteversionAngle(
        measurements: MeasurementsRepresentation, side: BodySideType): AngleMeasurementValueRepresentation | null {
        return this.getMeasurementByGroupAndName(
            measurements, this.femurGroupName(side), StudyMeasurementNames.FemurAnteversionAngle);
    }

    public static getFemurNeckAxis(
        measurements: MeasurementsRepresentation, side: BodySideType): AxisMeasurementValueRepresentation | null {
        return this.getMeasurementByGroupAndName(
            measurements, this.femurGroupName(side), StudyMeasurementNames.FemurNeckAxis);
    }

    public static getFemurProximalShaftAxis(
        measurements: MeasurementsRepresentation, side: BodySideType): AxisMeasurementValueRepresentation | null {
        return this.getMeasurementByGroupAndName(
            measurements, this.femurGroupName(side), StudyMeasurementNames.FemurProximalShaftAxis);
    }

    public static getFemurShaftAxis(
        measurements: MeasurementsRepresentation, side: BodySideType): AxisMeasurementValueRepresentation | null {
        return this.getMeasurementByGroupAndName(
            measurements, this.femurGroupName(side), StudyMeasurementNames.FemurShaftAxis);
    }

    private static femurGroupName(side: BodySideType): MeasurementGroupName {
        return side === BodySide.Left ?
            MeasurementGroupNames.HipFemurLeft :
            MeasurementGroupNames.HipFemurRight;
    }
}
