import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import {
    HipSurgicalTemplateMeasurements,
} from '@/lib/api/resource/case/surgical-template/measurements/HipSurgicalTemplateMeasurements';
import {
    HipNewSurgicalTemplateMeasurements,
} from '@/lib/api/resource/case/surgical-template/measurements/HipNewSurgicalTemplateMeasurements';
import HipLegacySurgicalTemplateMeasurements
    from '@/lib/api/resource/case/surgical-template/measurements/HipLegacySurgicalTemplateMeasurements';
import HipSurgicalTemplateMeasurementsUtil
    from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateMeasurementsUtil';
import anylogger from 'anylogger';
import LinkRelation from '@/lib/api/LinkRelation';
import { getUri } from 'semantic-link';

const log = anylogger('ModelGeometryLoader');

/**
 * A factory that provides an entry point to instantiate the right measurements model
 * depending on how the underlying data looks like.
 *
 * Note: This a migration strategy until the legacy measurements are deleted / migrated.
 */
export class HipSurgicalTemplateMeasurementFactory {
    public static make(
        data: MeasurementsRepresentation,
        surgicalTemplate: HipSurgicalTemplateRepresentation): HipSurgicalTemplateMeasurements {
        if (HipSurgicalTemplateMeasurementsUtil.isNewFormat(data)) {
            return new HipNewSurgicalTemplateMeasurements(surgicalTemplate, data);
        } else {
            const measurements = getUri(surgicalTemplate, LinkRelation.measurements);
            log.warn(`Measurements with uri ${measurements} will be using the legacy measurements util`);

            return new HipLegacySurgicalTemplateMeasurements(data);
        }
    }
}
