import Vue from 'vue';

/**
 * This is a global event bus for communicating between independent components in the 3D Planner (Shoulder).
 * It should not be used for communication between children back to parent. Vue has a different convention for this.
 *
 * See https://alligator.io/vuejs/global-event-bus/
 *
 * Please create constants for all global events on {@link PlannerEvent}
 * so that we can track and understand their purpose.
 *
 */
const PlannerEventBus = new Vue();

export default PlannerEventBus;
