
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
    import { head, last, split } from 'ramda';
    import {
        hasComponentsUriChanged,
        hasMeasurementsUriChanged,
        hasPlanUriChanged,
        hasRangeOfMotionUriChanged,
        hasStateChanged,
    } from '@/lib/api/resource/case/surgical-template/hipTemplateComparison';
    import { formatShortDateTime } from '@/lib/filters/format/formatDateTime';
    import SurgicalTemplateJSONDiff
        from '@/components/case-manage/surgical-template/history/SurgicalTemplateJSONDiff.vue';
    import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
    import {
        SurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
    import ArrowFromTo from '@/components/case-manage/surgical-template/history/row/ArrowFromTo.vue';
    import RecordState from '@/components/case-manage/surgical-template/history/row/RecordState.vue';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';
    import ModelChanges from '@/components/case-manage/surgical-template/history/row/ModelChanges.vue';
    import { UriDeconstructionUtil } from '@/components/case-plan/UrlDeconstructionUtil';
    import Hyperlink from '@/components/case-manage/Hyperlink.vue';

    /**
     * This is a case component **DEBUG** component that shows the admin the state of surgical template history
     */
    @Component({
        components: {
            ArrowFromTo,
            Hyperlink,
            HyperlinkButton,
            ModelChanges,
            RecordState,
            SurgicalTemplateJSONDiff,
        },
    })
    export default class SurgicalTemplateHistoryRow extends Vue {
        /**
         * A surgical template history item
         */
        @Prop({ required: true })
        private current!: SurgicalTemplateRepresentation;

        /**
         * A surgical template history item
         */
        @Prop({ required: true })
        private previous!: SurgicalTemplateRepresentation;

        /**
         * The 'global' surgical template with a 'history' collection from where to find/load the history items
         */
        @Prop({ required: true })
        private surgicalTemplate!: SurgicalTemplateRepresentation;

        /**
         * Expand functionality does not work by default when combining  slot.#item and slot.#expand-item
         *
         * There is a custom approach get around it.
         * @see https://stackoverflow.com/questions/58723645/v-data-table-controlling-expanded-items-via-v-slotbody
         * @see https://www.reddit.com/r/vuetifyjs/comments/dd7aox/how_to_use_item_slot_with_expandeditem_slot_in/
         * @see https://codepen.io/thokkane/pen/PooemJP
         */
        @Prop({ required: true })
        private expand!: (value: boolean) => void;

        @Prop({ required: true })
        private isExpanded!: boolean;

        protected isLoading = true;

        /** Export hack for the template */
        protected linkRelation = LinkRelation;

        /** binding of utility so can be used in template */
        protected util = SurgicalTemplateUtil;

        @IsLoading('isLoading')
        protected async onIntersect(
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
            isIntersecting: boolean): Promise<void> {
                if (isIntersecting) {
                    const current = await SurgicalTemplateResource.get(this.current, this.$apiOptions);
                    const previous = await SurgicalTemplateResource.get(this.previous, this.$apiOptions);

                    if (!current) {
                        throw new Error('no current template');
                    }

                    if (!previous) {
                        throw new Error('no previous template');
                    }
                }
            }

        private get formatDate(): string {
            return this.current ? formatShortDateTime(this.current.updated) : '';
        }

        private get hasStateChanged(): boolean {
            return this.current && this.previous ? hasStateChanged(this.current, this.previous) : false;
        }

        private get hasPlanChanged(): boolean {
            return this.current && this.previous ? hasPlanUriChanged(this.current, this.previous) : false;
        }

        /**
         * Note that two records with the same identifier will evaluate to false, given we are not exposing
         * the `components_identifier`.
         */
        private get hasComponentsChanged(): boolean {
            return this.current && this.previous ? hasComponentsUriChanged(this.current, this.previous) : false;
        }

        /**
         * @returns whether the measurements for the current record are different from the previous record.
         */
        private get hasMeasurementsChanged(): boolean {
            return this.current && this.previous ? hasMeasurementsUriChanged(this.current, this.previous) : false;
        }

        /**
         * Note that two records with the same identifier will evaluate to false, given we are not exposing
         * the `range_of_motion_identifier`.
         */
        private get hasRangeOfMotionChanged(): boolean {
            return this.current && this.previous ? hasRangeOfMotionUriChanged(this.current, this.previous) : false;
        }

        /**
         * Extract the user id from a self link
         * e.g.:  "title": "2021-06-07 03:31:50.706585+00:00 admin@formuslabs.com" => admin
         */
        private get historyId(): string {
            return UriDeconstructionUtil.pathNameOfLink(this.current, LinkRelation.self);
        }

        /**
         * Extract the user email from a title from a collection
         * e.g.:  "title": "2021-06-07 03:31:50.706585+00:00 admin@formuslabs.com" => admin@formuslabs.com
         */
        private get userEmail(): string {
            return this.xxxUserEmail(this.current.name);
        }

        /**
         * Extract the user email from a title from a collection
         * e.g.:  "title": "2021-06-07 03:31:50.706585+00:00 admin@formuslabs.com" => admin
         */
        private get userName(): string {
            const email = this.userEmail;
            if (email) {
                return head(split('@', email)) || '';
            }

            return '';
        }

        /**
         * Extract the user email from a title from a collection
         * e.g.:  "title": "2021-06-07 03:31:50.706585+00:00 admin@formuslabs.com" => admin@formuslabs.com
         */
        private xxxUserEmail(name?: string): string {
            if (name) {
                return last(split(' ', name)) || '';
            }

            return '';
        }

        protected get itemClass(): string {
            // return item.current > 4.2 ? 'style-1' : 'style-2'
            return 'style-1';
        }
    }
