import anylogger, { BaseLevels, Logger as ALogger } from 'anylogger';
import ulog, { Logger, LogLevel, ULog } from 'ulog';

/**
 * A global singleton mapping of names to ulog loggers
 */
const loggers: { [name: string]: Logger } = {};

/**
 * An adapter from anylogger to ulog.
 *
 * This is an implementation until ulog issue #14 is implemented.
 *
 * @see
 *  - https://github.com/Download/ulog/issues/14
 *  - https://github.com/Download/anylogger/blob/master/anylogger.js
 *  - https://github.com/Download/anylogger-log4js/blob/master/anylogger-log4js.js
 *  - https://github.com/Download/anylogger-loglevel/blob/master/anylogger-loglevel.js
 *  - https://github.com/Download/anylogger-debug/blob/master/anylogger-debug.js
 *  - https://github.com/Download/ulog/blob/master/ulog.js
 */
export default class AnyLoggerUlogAdapter {
    /**
     * Initialise/install the anylogger to ulog adapter bridge. This will cause the ulog
     * log methods to be patched/hooked/installed on the anylogger logger instances.
     */
    public static init(): void {
        // Provide an extension method for new loggers
        anylogger.ext = AnyLoggerUlogAdapter.extendLogger;

        // Install an existing anylogger instances
        for (const name in anylogger()) {
            anylogger.ext(anylogger(name));
        }

        // Look when the top level ULog instance so that changes in the master log level changes
        // can be reflected in the loggers.
        //     AnyLoggerUlogAdapter.hookUlog(ulog);
    }

    /**
     * Hooks the level property on the top level ULog instance, which impacts all loggers.
     */
    private static hookUlog(uLog: ULog): ULog {
        // Get the current value of the level property so that the original set implementation
        // can be called.
        const originalProperty = Object.getOwnPropertyDescriptor(ulog, 'level');

        Object.defineProperty(uLog, 'level', {
            get: function() {
                return originalProperty?.get ? originalProperty.get() : undefined;
            },
            set: function(value) {
                // Call the original setter method
                if (originalProperty?.set) {
                    originalProperty.set(value);
                }

                for (const name in anylogger()) {
                    anylogger.ext(anylogger(name));
                }
            },
        });
        return uLog;
    }

    /**
     * hooks the `level` setter on a ulog logger instance so that the logger methods can be updated
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cant_redefine_property}
     */
    private static hookLogger(logger: Logger, _logLevel: LogLevel): Logger {
        // Get the current value of the level property so that the original set implementation
        // can be called.
        const originalProperty = Object.getOwnPropertyDescriptor(logger, 'level');
        if (originalProperty) {
            originalProperty.configurable = true;

            Object.defineProperty(logger, 'level', {
                get: function() {
                    return originalProperty?.get ? originalProperty.get() : undefined;
                },
                set: function(value) {
                    // Call the original setter method
                    if (originalProperty?.set) {
                        originalProperty.set(value);
                    }
                    // TODO: call the original 'base class' setter

                    for (const name in anylogger()) {
                        anylogger.ext(anylogger(name));
                    }
                },
            });
            return logger;
        }
        throw new Error('The logger has no \'level\' property');
    }

    /**
     * Factory method to make a new ulog logger instance of the given name.
     */
    private static makeUlogLogger(name: string): Logger {
        return ulog(name);
        // return AnyLoggerUlogAdapter.hookLogger(ulog(name), ulog(name).level);
    }

    /**
     * Extend an anylogger logger to use the logging methods on a new or existing
     * ulog instance.
     */
    private static extendLogger(aLogger: ALogger<BaseLevels>): ALogger<BaseLevels> {
        // Get an existing logger, or create a new one.
        const ulogLogger = loggers[aLogger.name] || (loggers[aLogger.name] = AnyLoggerUlogAdapter.makeUlogLogger(aLogger.name));

        // Copy the ulog implementation onto the anylogger class. This is the 6 methods:
        //   - 'error', 'warn', 'info', 'log', 'debug', 'trace'
        for (const aLogLevel in anylogger.levels) {
            // log.debug('Patch logger %s method %s', aLogger.name, aLogLevel);
            // ulogLogger[aLogLevel]('test me %s %s %s' , aLogger.name, aLogLevel, ulogLogger[aLogLevel].level);
            // @ts-ignore Not sure why this doesn't work, but expect to have ulog which is in beta available soon
            aLogger[aLogLevel] = ulogLogger[aLogLevel];
            // // @ts-ignore
            // aLogger[aLogLevel] = () => {
            //     console.info('%s log message %o', aLogLevel, arguments);
            //     // @ts-ignore
            //     ulogLogger[aLogLevel](...arguments);
            // };
        }

        // Implement the enabledFor method on the anylogger logger.
        //
        // This method will receive a level as a string. Convert this to a numeric value using the 'levels'
        // object map.
        aLogger.enabledFor = (_lvl: keyof BaseLevels) => true; // anylogger.levels[lvl] <= ulogLogger.level;
        return aLogger;
    }
}
