
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import LinkRelation from '@/lib/api/LinkRelation';
    import { LinkUtil } from 'semantic-link';
    import HyperlinkButton from '@/components/case-manage/HyperlinkButton.vue';
    import {
        SurgicalTemplateRepresentation,
        SurgicalTemplateState,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
    import SurgicalTemplateHistoryTable
        from '@/components/case-manage/surgical-template/history/SurgicalTemplateHistoryTable.vue';
    import SurgicalTemplateMeasurementsLoader
        from '@/components/case-manage/surgical-template/SurgicalTemplateMeasurementsLoader.vue';
    import { Product } from '@/lib/api/representation/ProductRepresentation';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
    import {
        SurgicalTemplateCollectionRepresentation,
    } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateCollectionRepresentation';
    import DuplicateSurgicalTemplate from '@/components/case-manage/surgical-template/DuplicateSurgicalTemplate.vue';
    import SurgicalTemplateSuitabilityList
        from '@/components/case-manage/surgical-template/suitability/SurgicalTemplateSuitabilityList.vue';
    import SurgicalTemplateRankingList
        from '@/components/case-manage/surgical-template/ranking/SurgicalTemplateRankingList.vue';

    /**
     * This is a case component **DEBUG** component that shows the admin the state of study
     */
    @Component({
        components: {
            SurgicalTemplateSuitabilityList,
            SurgicalTemplateRankingList,
            DuplicateSurgicalTemplate,
            SurgicalTemplateHistoryTable,
            SurgicalTemplateMeasurementsLoader,
            HyperlinkButton,
        },
    })
    export default class SurgicalTemplate extends Vue {
        protected products = Product;

        @Prop({ required: true })
        value!: SurgicalTemplateRepresentation;

        @Prop({ required: true })
        project!: CaseRepresentation;

        /** The URI of the active 'user' surgical template surgical specification */
        @Prop({ required: true })
        public userSurgicalTemplateUri!: string | null;

        /** The URI of the active 'acid' surgical template surgical specification */
        @Prop({ required: true })
        public acidSurgicalTemplateUri!: string | null;

        /** Used in the template above to track state */
        protected surgicalTemplateState = SurgicalTemplateState;

        /** Export hack for the template */
        protected linkRelation = LinkRelation;
        /** Export hack for the template */
        protected linkUtil = LinkUtil;

        protected isLoading = false;

        protected selectedDataTab = 'general';

        /** The history of surgical template. */
        protected history: SurgicalTemplateCollectionRepresentation | null = null;

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            this.history = await SurgicalTemplateResource.getHistory(this.value, this.$apiOptions);
        }

        protected caseProduct(): Product | null {
            if (this.project) {
                const title = LinkUtil.getTitle(this.project, LinkRelation.product);
                if (title) {
                    return title as Product;
                }
            }
            return null;
        }

        protected get isUserSurgicalTemplate(): boolean {
            // get the non-canonical 'self' link
            const selfUri = LinkUtil.getUri(this.value, LinkRelation.self);
            return !!selfUri && selfUri === this.userSurgicalTemplateUri;
        }

        protected get isAcidSurgicalTemplate(): boolean {
            // get the non-canonical 'self' link
            const selfUri = LinkUtil.getUri(this.value, LinkRelation.self);
            return !!selfUri && selfUri === this.acidSurgicalTemplateUri;
        }

        protected get selfUri(): string {
            return getRequiredUri(this.value, LinkRelation.self);
        }

        protected get historyLength(): number | null {
            return this.history?.items?.length || null;
        }
    }
