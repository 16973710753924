import { AcidObject3dBase, AcidObject3dType } from '@/lib/planning/objects-3D/AcidObject3d';

import anylogger from 'anylogger';
import debounce, { DebounceResult, isCancelledByCallerError, ScheduledCancelledError } from '@/lib/base/AsyncDebounce';
import { CancelToken } from 'axios';
import ComponentCoverageUtil from '@/lib/viewer/component-coverage/ComponentCoverageUtil';
import { BufferGeometry, Mesh } from 'three';
import { CollisionCoverageResults, ComponentCoverageStrategy } from '@/lib/viewer/component-coverage/types';

const log = anylogger('ComponentCoverageController');

/**
 * The name/alias use for the mask collision object. Given there is only one collision object at a time,
 */
const MASK_COLLISION_ALIAS = 'mask-collision';

/**
 * An abstract class that allows to perform component coverage calculations.
 * It is intended to be used for setting up and updating the component (eg. cup / baseplate) collision coverage
 * by another covering mesh (eg. hemi-pelvis / scapula)
 *
 * 1. It uses the async-debounce approach.
 * 1. It does exception handling (eating cancelled coverage calculations, and bubbling up other exceptions).
 * 1. It provides a clear way to be extended, allowing the subclasses to not worry about the implementation details.
 *
 *
 * Note:
 * 1. The component coverage should be updated whenever the component is adjusted/changed.
 * 2. If the covering mesh (hemi-pelvis / scapula) is for some reason moved/changed,
 * the coverage won't be recalculated.
 */
export abstract class ComponentCoverageController {
    /**
     * Function that updates the component collision coverage only once every
     * certain time (currently is 1500 milliseconds). The async-debounce is used, given
     * coverage calculation is computationally heavy and doesn't happen instantaneously.
     */
    protected _debouncedUpdateCoverage: DebounceResult<CollisionCoverageResults, [Mesh, BufferGeometry, ComponentCoverageStrategy]>;

    public constructor(
        /**
         * The collision object
         *
         * Note: This is only used for visualization purposes.
         */
        public collisionObject: AcidObject3dBase,
        /**
         * The covering mesh
         * e.g.:
         *   a. operative hemi pelvis if cup.
         *   b. scapula if baseplate.
         *
         * Note: The covering mesh is exposed as a constructor parameter given it is assumed
         * to not be moved during the lifecycle of this controller. If the covering mesh is moved,
         * the coverage won't be updated.
         */
        private coveringMesh: Mesh) {
        this._debouncedUpdateCoverage = debounce(this.performUpdateCoverage.bind(this), 1500, {
            cancelError: new ScheduledCancelledError('coverage cancelled'),
        });
    }

    /**
     * This is the function that performs the coverage update within the context of `debounce`.
     *
     * @returns a {class @CollisionCoverageResults} when successfully calculated
     * @throws Throw any exception during the calculation process.
     */
    private async performUpdateCoverage(
        token: CancelToken,
        componentMesh: Mesh,
        componentMaskGeometry: BufferGeometry,
        coverageStrategy: ComponentCoverageStrategy): Promise<CollisionCoverageResults> {
        return await ComponentCoverageUtil.checkComponentCoverage(
            token,
            ComponentCoverageUtil.makeComponentMask(componentMesh, componentMaskGeometry),
            this.coveringMesh,
            coverageStrategy);
    }

    /**
     * The main internal method used for calculation the collision coverage.
     * Note: This first methods does the exception handling.
     *
     * @returns a {class @CollisionCoverageInfo} when successfully calculated. Otherwise null as soon as the the
     *          collision coverage calculation was cancelled/interrupted.
     * @throws any exception thrown during the calculation process **that is not a cancellation event**.
     *
     * @see {@error CancelledError} and {@method isCancelledByCallerError}
     */
    protected async updateCoverage(
        componentMesh: Mesh,
        collisionMask: BufferGeometry,
        coverageStrategy: ComponentCoverageStrategy): Promise<CollisionCoverageResults | null> {
        log.debug('Coverage calculation scheduled');
        try {
            // remove right away any previous collision objects
            ComponentCoverageUtil.clearCollision(this.collisionObject);

            const results = await this._debouncedUpdateCoverage.function(componentMesh, collisionMask, coverageStrategy);

            const coverageMesh = ComponentCoverageUtil.makeCoverageMesh(results, coverageStrategy.getFaceColor);
            coverageMesh.renderOrder = coverageStrategy.coverageMeshRenderOrder;
            this.collisionObject.theObject.attach(coverageMesh);

            return results;
        } catch (error: unknown) {
            if (isCancelledByCallerError(error)) {
                // It is likely that if it was cancelled it will be re-run, so nothing to do here.
                log.debug('Coverage cancelled by caller while in progress. Nothing to do: %s', error.message);
                return null;
            } else {
                if (error instanceof ScheduledCancelledError) {
                    log.debug('Coverage cancelled by caller before even starting. Nothing to do: %s', error.message);
                    return null;
                }

                if (error instanceof Error) {
                    log.error('Not a cancellation event %s', error.message);
                }

                throw error;
            }
        }
    }

    /**
     * Makes a dummy collision object.
     */
    public static makeCollisionObject(): AcidObject3dBase {
        return new AcidObject3dBase({
            type: AcidObject3dType.Collision,
            name: MASK_COLLISION_ALIAS,
            alias: MASK_COLLISION_ALIAS,
        });
    }
}
