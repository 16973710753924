export enum PlanType {
    /** The plan is the acid plan that has been performed by the AI process */
    AutomatedPlan = 111,

    /** The most recently/active/selected plan as performed and approved by a surgeon */
    UserPlan = 222,

    /** just a plan, that is neither an acid plan or the active/current plan */
    Plan = 333,
}
