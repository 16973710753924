import * as amplitude from '@amplitude/analytics-browser';
import { useHipUser } from '@/stores/hipUser/store';
import Bugsnag from '@bugsnag/js';
import anylogger from 'anylogger';
import { parseHeadMetaTag } from '@/lib/metaTagUtil';

const logger = anylogger('Amplitude');

const DEV_API_KEY = '4ac249f924e92a695bbfcf4e0e208f83';
const AT_LEAST_5_CHARS = 5;

export function amplitudeApiKey(): string {
    return parseHeadMetaTag('acid-amplitude-api-key', DEV_API_KEY);
}

export function trackEvent(eventName: string, eventProperties?: Record<string, any>): void {
    logger.debug(`Amplitude event fired: ${eventName}`);
    const hipUser = useHipUser();
    if (!hipUser.isReady) {
        const warningMsg = 'hipUser store not ready, sending Amplitude event without user data.';
        logger.warn(warningMsg);
        Bugsnag.notify(warningMsg);

        amplitude.track(eventName, eventProperties);
    } else {
        const eventInput = {
            event_type: eventName,
            user_id: hipUser.userId.padStart(AT_LEAST_5_CHARS, '0'),
            user_properties: {
                organisation_id: hipUser.orgId,
                user_role: hipUser.role,
            },
            event_properties: eventProperties,
        };
        amplitude.track(eventInput);
        logger.debug(eventInput);
    }
}
