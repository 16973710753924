import {
    PlanFileCollectionRepresentation,
    PlanFileRepresentation,
} from '@/lib/api/representation/case/plan/PlanFileRepresentation';
import Bottleneck from 'bottleneck';
import { AxiosInstance } from 'axios';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import FileSaver from 'file-saver';
import TimeoutUtil from '@/lib/base/TimeoutUtil';

import anylogger from 'anylogger';
import AxiosBrowserCacheUrlMutation from '@/lib/http/AxiosBrowserCacheUrlMutation';
import { AsyncZippable, zip } from 'fflate';
import { promisify } from 'util';
import { ApiUtil } from '@/lib/semantic-network';

const log = anylogger('Plan');

const asyncZip = promisify(zip);

export interface PlanFileRepresentationAndBlob {
    file: PlanFileRepresentation;
    content: Promise<Blob>;
}

/**
 * A service class to support downloading 3D case plan models to the users computer.
 *
 * @see {@link https://stuk.github.io/jszip/documentation/api_jszip.html}
 * @see {@link https://stuk.github.io/jszip/}
 */
export default class ModelDownloadService {
    /**
     * The operation of downloading the models is network I/O intensive. In theory
     * we limit this so that the server is not overwhelmed (i.e. DOS attack on ourselves) and
     * so that under adverse conditions some downloads progress.
     */
    private downloadLimiter = new Bottleneck({ maxConcurrent: 6 });

    /** axios instance for making http requests for the file content */
    private $http: AxiosInstance;

    constructor($http: AxiosInstance) {
        this.$http = $http;
    }

    /**
     * Download and save all 3D models from a case plan to the local machine.
     *
     * The process involves:
     *  - getting meta-data for each file
     *  - downloading the file content with a rate limiter
     *  - zipping the files
     *  - presenting a save-as dialog
     */
    public async download(
        files: PlanFileCollectionRepresentation, zipFilename: string): Promise<void> {
        if (files && files.items.length > 0) {
            log.info('%d file to get meta-data for', files.items.length);
            const theFiles = await this.createRequests(files);

            log.info('Preparing zip with %d files', files.items.length);
            const fileList: AsyncZippable = {};
            for (const aFile of theFiles) {
                const content = await aFile.content;
                fileList[aFile.file.name] = new Uint8Array(await content.arrayBuffer());
            }

            log.info('Zipping %d files', files.items.length);
            const zipContent = new Blob([await asyncZip(fileList)], { type: 'application/zip' });

            log.info('Save Zip file %s', zipFilename);
            FileSaver.saveAs(zipContent, zipFilename);
        } else {
            throw new Error(`No 3D model files`);
        }
    }

    /**
     * Given the list of 3D model files to download, material each file resource representation (as JSON)
     * to get meta-data information, as well as starting off the rate limited requests to download
     * the main model data.
     */
    private async createRequests(files: PlanFileCollectionRepresentation): Promise<PlanFileRepresentationAndBlob[]> {
        return await Promise.all(files.items.map(async (planFile: PlanFileRepresentation) => {
            const fileUrl = LinkUtil.getUri(planFile, LinkRelation.canonicalOrSelf);
            if (fileUrl) {
                // Ensure that the file resource is materialised. As this implementation matures
                // there should be additional meta-data that can be added to the zip
                await ApiUtil.get(planFile);
                return {
                    // the file with materialised file information
                    file: planFile,
                    // the content which is rate limited and likely to be an unfulfilled promise
                    content: this.downloadFileContent(fileUrl, planFile.name),
                };
            } else {
                throw new Error(`Failed to download plan model`);
            }
        }));
    }

    /**
     * Download the model file.
     *
     * Note: If the responseType is set to 'Blob' then firefox appears to hang in the download. To
     * get the download to complete in both Firefox and Chrome, set the response type to 'ArrayBuffer' and
     * then use the response to construct a blob.
     */
    private async downloadFileContent(fileUrl: string, filename: string): Promise<Blob> {
        // Sleep until the next tick so the other work can be scheduled into the
        // http queue first.
        await TimeoutUtil.sleep(0);

        log.debug('Schedule GET plan model %s', fileUrl);
        return await this.downloadLimiter.schedule(async () => {
            log.debug('GET plan model %s', fileUrl);
            const response = await this.$http.get<ArrayBuffer>(
                fileUrl,
                AxiosBrowserCacheUrlMutation.makeMutationOption({
                    headers: {
                        // Let the service decide the best image representation
                        accept: 'model/*;q=0.8,image/*;q=0.4',
                    },
                    // arraybuffer, blob, stream, text, json, document
                    responseType: 'arraybuffer',
                    // disable an response transformation for this request. Models should be transferred without
                    // any mutation or modification.
                    transformResponse: (data /*: any, headers?: any */) => {
                        return data;
                    },
                }));
            log.debug('GET %s : %d', fileUrl, response.status);
            if (response.status === 200 && response.data) {
                return new Blob([response.data]);
            } else {
                throw new Error(`Failed to download plan model '${filename}`);
            }
        });
    }
}
