import { AcidObject3dBase } from '@/lib/planning/objects-3D/AcidObject3d';
import { CancelToken } from 'axios';
import { AcidObject3dMainClassTypes } from '@/hipPlanner/assembly/objects/AcidObject3dMainClassTypes';
import {
    BufferGeometry,
    Color,
    LineBasicMaterial,
    LineSegments,
    Matrix4,
    Mesh,
    MeshPhongMaterial,
    Plane,
    ShaderMaterial,
    Vector3,
} from 'three';
import AsyncIterationUtil from '@/lib/viewer/component-coverage/AsyncIterationUtil';
import BufferGeometryUtil, { Face3Indices, Face3Positions } from '@/lib/viewer/component-coverage/BufferGeometryUtil';
import { PlaneUtil } from '@/lib/base/three-js/PlaneUtil';
import { FaceUtil } from '@/lib/base/three-js/FaceUtil';
import { Object3DUtil } from '@/lib/base/three-js/Object3DUtil';
import anylogger from 'anylogger';
import { Object3DWrapperUtil } from '@/lib/planning/objects-3D/Object3DWrapperUtil';
import { compact } from 'lodash';
import { flatten } from 'ramda';

export type OutlineModel = AcidObject3dMainClassTypes;
/**
 * Number of faces to loop during a async debounce
 */
const FACES_PER_DEFER_LOOP = 400;

const log = anylogger('OutlineUtil');

/**
 * Utility for creating/clearing outline over a collection of {@link OutlineModel}
 *
 * Note: The utility relies on the {@AsyncDebounce} function.
 * Even the code could be sync, it is made async/debounced to release the single thread, to not freeze the browser
 *
 * TODO: This class needs some clean up.
 * TODO DRY complex code (intersection code related).
 *  - {@see getPlaneIntersectionPoints}
 *  - {@see getPlaneIntersectionPointsFromGeometry}
 */
export class OutlineUtils {
    /**
     * Remove existing cross-section outlines from objects.
     */
    public static clearCrossSectionOutlines(outlineCollection: AcidObject3dBase): void {
        Object3DUtil.removeChildren(outlineCollection.theObject);
    }

    /**
     * Get the mesh object material diffuse color or return random is no diffuse color available
     */
    private static getMeshDiffuseOrRandomColor(object: Mesh): Color {
        if (object.material instanceof ShaderMaterial) {
            return object.material.uniforms.diffuse.value;
        } else if (object.material instanceof MeshPhongMaterial) {
            return object.material.color;
        } else {
            return new Color().setHex(Math.random() * 0xFFFFFF);
        }
    }

    /**
     * Make outline objects for visible objects:
     *
     * The outline will be calculated on each leaf node which:
     * - wraps a Mesh.
     *   e.g: This will exclude objects like the {@link HipCup}
     * - all its ancestors are visible.

     */
    public static async makeOutlineObjects(
        token: CancelToken, outlineModels: OutlineModel[], crossSectionPlane: Plane): Promise<LineSegments[]> {
        const contourLines: LineSegments[] = [];

        const makeOutlines = async (
            /** The promise will be resolved once the latest outline was calculated */
            resolve: (lineSegments: LineSegments[]) => void,
            reject: (reason?: any) => void) => {
            try {
                const targetModels = this.filterOutlineModels(outlineModels);
                const targetModelsLength = targetModels.length;
                log.debug(
                    'Total identified children for outline: %d. Aliases: %s',
                    Object3DWrapperUtil.aliases(targetModels).length,
                    Object3DWrapperUtil.aliases(targetModels).join(', '));

                for (const [index, object] of targetModels.entries()) {
                    const object3D: Mesh = object.theObject as Mesh;
                    Object3DUtil.updateMatrixWorldIncludingParentsAndChildren(object3D);
                    Object3DUtil.updateLocalMatrix(object3D);

                    // construct the cross-section outline geometry
                    const geometry = await OutlineUtils.makeOutlineGeometry(token, crossSectionPlane, object3D);

                    // make the contour line object from the set of intersection points
                    const contourLine = this.makeContourLineFromPoints(
                        this.getMeshDiffuseOrRandomColor(object3D), geometry);

                    contourLines.push(contourLine);

                    const isLast = index === targetModelsLength - 1;
                    if (isLast) {
                        resolve(contourLines);
                    } else {
                        // nothing to do, keep looping.
                    }
                }
            } catch (err) {
                log.debug('Error while making outlines: %s', err);
                reject(err);
            }
        };

        return new Promise((resolve, reject) => {
            makeOutlines(resolve, reject);
        });
    }

    /**
     * @returns the list of models where the outline geometry will be calculated.
     * The result is a unique list of objects which:
     * - are meshes
     * - all its ancestors are visible.
     */
    private static filterOutlineModels(outlineModels: OutlineModel[]) {
        //
        // 1. Filter the visible objects
        //
        const onlyVisibleParents = compact((outlineModels.filter((model: OutlineModel) => {
            return Object3DUtil.isVisible(model.theObject) && Object3DUtil.areAncestorsVisible(model.theObject);
        })));

        //
        // 2. Filter only the the objects that wrap Meshes, which are visible, and its parents are visible.
        // The following function is not very performant, given it calls `Object3DUtil.areAncestorsVisible`
        //
        const onlyVisibleMeshes = onlyVisibleParents.map((model: OutlineModel) => {
            return Object3DWrapperUtil.filter(model, (childModel: OutlineModel) => {
                const object = childModel.theObject;
                return object instanceof Mesh &&
                    Object3DUtil.isVisible(object) &&
                    Object3DUtil.areAncestorsVisible(object); // attempt to get rid of middle nodes which are not visible;
            });
        });

        return Object3DWrapperUtil.unique(flatten(compact(onlyVisibleMeshes)));
    }

    /**
     * Construct cross-section outline geometry, from visible objects intersecting with a cross-section plane
     */
    public static async makeOutlineGeometry(
        token: CancelToken, worldPlane: Plane, object3d: Mesh): Promise<BufferGeometry> {
        const intersectionPoints = await OutlineUtils.getPlaneIntersectionPoints(token, worldPlane, object3d);
        return BufferGeometryUtil.makeFromPoints(intersectionPoints);
    }

    /**
     * Get the plane intersection points from the face positions.
     *
     * @param worldPlane Used for making the "cut" of the face.
     * @param facePositions: The three vertex positions.
     * @param matrixWorld: The matrixWorld used to apply to the face vertices to bring them in scene world space
     */
    private static getPlaneIntersectionPointsFromFace(
        worldPlane: Plane, facePositions: Face3Positions, matrixWorld: Matrix4): Vector3[] {
        return PlaneUtil.intersectFace(worldPlane, FaceUtil.applyMatrix4(facePositions, matrixWorld));
    }

    /**
     * Get a collection of intersection points from a Plane and BufferGeometry
     */
    private static async getPlaneIntersectionPoints(
        token: CancelToken, worldPlane: Plane, object3d: Mesh): Promise<Vector3[]> {
        const intersectionPoints: Vector3[] = [];
        const geometryVertexPositions = BufferGeometryUtil.vertexPositionBuffer(object3d.geometry);

        function makeFaceIntersectionPoints(faceIndices: Face3Indices): void {
            token.throwIfRequested();
            const positions = BufferGeometryUtil.getFaceVertexFromBufferAttribute(geometryVertexPositions, faceIndices);
            const points = OutlineUtils.getPlaneIntersectionPointsFromFace(worldPlane, positions, object3d.matrixWorld);
            intersectionPoints.push(...points);
        }

        function makeResult(): Vector3[] {
            token.throwIfRequested();
            return intersectionPoints;
        }

        const [result, _metrics] = await AsyncIterationUtil.doInChunks<Face3Indices, Vector3[]>(
            BufferGeometryUtil.faceIndices(object3d.geometry),
            FACES_PER_DEFER_LOOP,
            makeFaceIntersectionPoints,
            makeResult);

        return result;
    }

    /**
     * Make a contour line object (LineSegments) from an array of positional vectors
     */
    private static makeContourLineFromPoints(
        outlineColor: Color, intersectionPointsGeometry: BufferGeometry): LineSegments {
        const lineMaterial = new LineBasicMaterial({
            color: outlineColor,
            linewidth: 3,
        });

        const contourLine = new LineSegments(intersectionPointsGeometry, lineMaterial);
        contourLine.name = 'cross_section_line';
        return contourLine;
    }
}
