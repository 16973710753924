import { defineStore } from 'pinia';
import AuthenticatorResource from '@/lib/api/resource/auth/AuthenticatorResource';
import Name from '@/router/Name';

export const useResetPassword = defineStore('resetPassword', {
    state: () => ({
        hasTokenExpired: false,
        isDialogVisible: true,
        isLoading: false,
        isSubmitting: false,
        showConfirmPassword: false,
        showPassword: false,
        email: '',
        error: '',
        password: '',
        passwordConfirmation: '',
        token: '',
    }),
    actions: {
        async doPasswordReset(): Promise<void> {
            this.error = '';
            this.isSubmitting = true;

            return AuthenticatorResource
                .setPassword(this.$http, this.$api, this.email, this.token, this.password, this.$apiOptions)
                .then((result) => {
                    if (result) {
                        this.$router.push({ name: Name.Home });
                    } else {
                        this.error = 'Sending a password reset failed';
                    }
                })
                .catch(err => {
                    if (err.response && err.response.status === 403) {
                        this.hasTokenExpired = true;
                    } else {
                        this.error = 'Password reset failed, please contact support.';
                    }
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },
    },
});

export type ResetPasswordStore = ReturnType<typeof useResetPassword>;
