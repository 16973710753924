import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

export enum DateYearEnum {
    Numeric = 'numeric',
    TwoDigit = '2-digit',
}

export type DateYearType = DateYearEnum.Numeric | DateYearEnum.TwoDigit;

export enum DateMonthEnum {
    Numeric = 'numeric',
    TwoDigit = '2-digit',
    Long = 'long',
    Short = 'short',
}

export type DateMonthType = DateMonthEnum.Short | DateMonthEnum.Long | DateMonthEnum.Numeric | DateMonthEnum.TwoDigit;

export enum DateDayEnum {
    Numeric = 'numeric',
    TwoDigit = '2-digit',
}

export type DateDayType = DateDayEnum.Numeric | DateDayEnum.TwoDigit;

export interface DateFormatOptionsInterface extends Intl.DateTimeFormatOptions {
    year: DateYearType;
    month: DateMonthType;
    day: DateDayType;
}

/**
 * Default date formatting options
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
 */
const defaultDateFormat: DateFormatOptionsInterface = {
    year: DateYearEnum.Numeric,
    month: DateMonthEnum.Short,
    day: DateDayEnum.Numeric,
};

/**
 * Default date format locale
 */
const defaultLocale = 'en-GB';

/**
 * Datetime utilities for formattingDate objects and strings based on user's system/browser locale
 */
export default class DatetimeLocaleUtil {
    public static formatToLocale(
        date: Date | string | null | undefined,
        locale: string = DatetimeLocaleUtil.getUserLocale(),
        formatOptions: DateTimeFormatOptions = defaultDateFormat): string {
        let formattedDate = '';
        if (date) {
            formattedDate = new Date(date.toString()).toLocaleString(locale, formatOptions);
        }
        return formattedDate;
    }

    /**
     * Format a date to user locale date
     */
    public static formatToLocaleDate(
        date: Date | string | null | undefined,
        locale: string = DatetimeLocaleUtil.getUserLocale(),
        formatOptions: DateFormatOptionsInterface = defaultDateFormat): string {
        let formattedDate = '';

        if (date) {
            formattedDate = new Date(date.toString()).toLocaleDateString(locale, formatOptions);
        }
        return formattedDate;
    }

    /**
     * Get the locale used by the browser as set by the user's set system language preference
     *
     * The returned value is one of the BCP 47 language tags for the locale
     *
     * - see:
     *      @link https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language
     *      @link https://tools.ietf.org/html/bcp47
     */
    public static getUserLocale(): string {
        if (navigator.languages?.length) {
            return navigator.languages[0];
        } else {
            return navigator.language || defaultLocale;
        }
    }

    /**
     * Get the browser locale tag, as resolved by the browser default number formatting
     *
     * The returned value is one of the BCP 47 language tags for the locale
     *
     * - see:
     *      @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat/resolvedOptions
     *      @link https://tools.ietf.org/html/bcp47
     */
    public static getBrowserLocale(): string {
        // create a NumberFormat instance
        const numberFormat = new Intl.NumberFormat();
        // get the default resolved locale tag
        return numberFormat.resolvedOptions().locale;
    }
}
