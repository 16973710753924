import DateTimeFormat = Intl.DateTimeFormat;
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

const longOptions: DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',

    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};
const longFormatter = DateTimeFormat(undefined, longOptions);

const shortOptions: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',

    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};
const shortFormatter = DateTimeFormat(undefined, shortOptions);

/**
 * Format an API style timestamp (with timezone, which will usually be UTC) as a local browser time
 * with whole 'seconds' level of precision.
 */
export function formatLongDateTime(value?: string): string {
    if (value) {
        return longFormatter.format(new Date(value));
    }
    return '';
}

export function formatShortDateTime(value?: string): string {
    if (value) {
        return shortFormatter.format(new Date(value));
    }
    return '';
}
