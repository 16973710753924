/**
 * The different representations of its geometry.
 *
 * The enum value match the 3d model 'subtype' as defined in:
 *   acid.domain.segmentation.segmentation_types.ModelFlavour
 */
export enum SegmentedModelSubTypes {
    Segmentation = 'segmentation', // the raw segmented surface, output by a segmentation model and mask_to_surface()
    SegmentationWithNormals = 'segmentation-with-normals',
    SegmentationWithBoneQuality = 'segmentation-with-bone-quality',
    AtlasPcFit = 'atlas-pc-fit',
    AtlasRbfFit = 'atlas-rbf-fit',
    ResectionRemoved = 'resection-removed',
    ResectionRemaining = 'resection-remaining',
}

export type SegmentedModelSubType =
    SegmentedModelSubTypes.Segmentation |
    SegmentedModelSubTypes.SegmentationWithNormals |
    SegmentedModelSubTypes.SegmentationWithBoneQuality |
    SegmentedModelSubTypes.AtlasPcFit |
    SegmentedModelSubTypes.AtlasRbfFit |
    SegmentedModelSubTypes.ResectionRemoved |
    SegmentedModelSubTypes.ResectionRemaining
