import Vue, { markRaw } from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { ApiPiniaPlugin } from '@/plugins/api';
import router from '@/router';

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

pinia.use(ApiPiniaPlugin);

pinia.use(({ store }) => {
    (store.$router as typeof router) = markRaw(router);
});

export default pinia;
