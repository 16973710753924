
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import {
        SegmentedModelCollectionRepresentation,
        SegmentedModelRepresentation,
    } from '@/lib/api/representation/case/study/SegmentedModelRepresentation';
    import Object3DFactory from '@/hipPlanner/assembly/objects/Object3DFactory';
    import { HipRenderOrder } from '@/hipPlanner/assembly/objects/HipRenderOrder';
    import Bone3d from '@/hipPlanner/assembly/objects/Bone3d';
    import CameraMan from '@/lib/planning/camera/CameraMan';
    import CameraManUtil from '@/lib/planning/camera/CameraManUtil';
    import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
    import { SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
    import BasicViewer from '@/lib/scene/BasicViewer';

    /**
     * This is a 3D view renderer of the study models
     */
    @Component({ components: {} })
    export default class StudyModelsViewer extends Vue {
        @Prop({ required: true, validator: notNilValidator('value') })
        private value!: StudyRepresentation;

        @Prop({ required: true, validator: notNilValidator('viewer') })
        private viewer!: BasicViewer;

        @Prop({ required: true, validator: notNilValidator('assembly') })
        private assembly!: SceneAssembly;

        protected isLoading = true;

        @IsLoading('isLoading')
        protected async mounted(): Promise<void> {
            await CaseStudyResource.getSegmentedModels(this.value, this.$apiOptions);

            if (this.value.boneModels) {
                const bones = await this.makeSegmentedModel3D(this.value.boneModels);

                if (bones && bones.length > 0) {
                    this.assembly.scene.add(...bones.map((bone: Bone3d) => {
                        return bone.theObject;
                    }));

                    // Initialise the camera controller
                    const viewer = this.viewer;
                    const cameraMan = new CameraMan(viewer.camera, viewer.cameraControls);
                    CameraManUtil.moveToObject(cameraMan, bones[0]);
                }
            }
        }

        private async makeSegmentedModel3D(segmentedModels: SegmentedModelCollectionRepresentation): Promise<Bone3d[]> {
            return Promise.all(segmentedModels.items.map(async (model: SegmentedModelRepresentation): Promise<Bone3d> => {
                const bone = await Object3DFactory.makeBone3D(model, this.$http);
                bone.theObject.renderOrder = HipRenderOrder.Default;
                return bone;
            }));
        }

        public get segmentedModels(): SegmentedModelRepresentation[] | [] {
            return this.value.boneModels?.items ? this.value.boneModels?.items : [];
        }
    }
