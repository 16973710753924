import { AxiosInstance } from 'axios';

import anylogger from 'anylogger';
import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
import DownloadUtil from '@/lib/http/DownloadUtil';
import assert from 'assert';
import Bugsnag from '@bugsnag/js';
import AxiosUtil from '@/lib/AxiosUtil';
import ContentType from '@/lib/http/mimetype';
import { getReportTitle, getReportUri } from '@/lib/api/representation/case/plan/planRepresentationUtil';

const log = anylogger('Plan');

export default class PlanReportDownloadService {
    constructor(private $http: AxiosInstance) {
    }

    /**
     * Download a plan as a pdf to the local computer
     *
     * @see {@link https://stackoverflow.com/a/53775165}
     * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a}
     */
    public async download(plan: PlanRepresentation): Promise<void> {
        const reportUri = getReportUri(plan);
        try {
            const response = await this.$http.get(reportUri, {
                responseType: 'blob',
                headers: {
                    accept: `${ContentType.Pdf},*/*;q=0.5`,
                },
            });

            if (AxiosUtil.isResponseOk(response)) {
                DownloadUtil.httpResponseSaveAs(response, getReportTitle(plan));
            } else {
                log.error('Get report failed (%d)', response.status);
            }
        } catch (err: unknown) {
            assert.ok(err instanceof Error);
            log.error('Error fetching report pdf from %s: %s', reportUri, err.message);
            Bugsnag.notify(err);
        }
    }
}
