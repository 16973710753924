import { getUri, LinkType, RelationshipType, Uri } from 'semantic-link';
import { AxiosInstance, AxiosResponse } from 'axios';
import DownloadUtil from '@/lib/http/DownloadUtil';

import anylogger from 'anylogger';
import AxiosBrowserCacheUrlMutation from '@/lib/http/AxiosBrowserCacheUrlMutation';

const log = anylogger('ImageDataUrlLoader');

/**
 * Perform an http GET of an url that must return an image which
 * is encodes as a data url string.
 *
 * The data url can be assigned to the image 'src' of an image (or v-img).
 *
 * see
 *   {@link https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL}
 */
export default class ImageDataUrlLoaderUtil {
    /**
     * Load an image as a data URL.
     *
     * WARNING: This will return a data URL. Although the image can be over 2MB in size
     * the Chrome browser may have problems digesting the image in an IMG element.
     *
     * @return a data URLas a promise
     */
    public static async loadImageDataUrl(
        axios: AxiosInstance,
        parent: LinkType,
        relationship: RelationshipType): Promise<string | undefined> {
        try {
            const url = getUri(parent, relationship);
            if (url) {
                log.debug('GET image %s', url);

                const response = await axios.get<Blob>(
                    url,
                    AxiosBrowserCacheUrlMutation.makeMutationOption({
                        headers: {
                            // Let the service decide the best image representation
                            accept: 'image/*;q=0.5',
                        },
                        responseType: 'blob',
                    }));

                log.debug('Parsing blob response from %s %o', url, response);
                const dataUrlData = await ImageDataUrlLoaderUtil.readBlobResponseToDataUrl(response);
                log.debug('Got data URL from %s', url);
                return dataUrlData;
            } else {
                log.warn('No such link relationship \'%s\'', relationship);
                return undefined;
            }
        } catch (err) {
            log.error('Error loading data URL: %o', err);
            return undefined;
        }
    }

    /**
     * Convert the Axios response to a data url.
     *
     * This browser supported interface is callback based, so convert this to a
     * promise/async pattern.
     */
    private static async readBlobResponseToDataUrl(response: AxiosResponse<Blob>): Promise<string> {
        return await ImageDataUrlLoaderUtil.readBlobToDataUrl(response.data);
    }

    /**
     * Convert the response blob to a data url. This works ok up to the limit
     * of an URL for the browser (about 2MB for chrome).
     *
     * This browser supported interface is callback based, so convert this to a
     * promise/async pattern.
     */
    public static readBlobToDataUrl(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onerror = (ev: ProgressEvent<FileReader>) => {
                    if (ev.target) {
                        ev.target.abort();
                        reject(ev.target.error);
                    } else {
                        reject(new Error(`Known error loading image from blob`));
                    }
                };
                reader.onload = (ev: ProgressEvent<FileReader>) => {
                    if (ev.target) {
                        resolve(reader.result as string);
                    }
                    reject(new Error(`Known target`));
                };
                reader.readAsDataURL(blob);
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Load the image from http into a HTML IMG element. This is a multi-set process
     * involving:
     *  - http GET to arraybuffer
     *  - arraybuffer to blob conversion
     *  - read blob to data URL
     *  - load data URL into image
     *  - decode image
     *
     * This process has problems with load images over 1MB in size (of which all the
     * dicom sprite images are). Direct download to Blob fails on Firefox. Using a jpeg
     * file of less than 1MB works just fine.
     *
     * @see {@link https://github.com/eligrey/FileSaver.js/#supported-browsers}
     * @see {@link https://stackoverflow.com/questions/47802518/download-big-files}
     * @see {@link https://chromium.googlesource.com/chromium/src/+/224e43ce1ca4f6ca6f2cd8d677b8684f4b7c2351/storage/browser/blob/README.md}
     */
    public static async loadImage(axios: AxiosInstance, uri: Uri): Promise<HTMLImageElement | ImageBitmap> {
        if (uri) {
            log.debug('GET image %s', uri);

            const response = await axios.get<ArrayBuffer>(
                uri,
                AxiosBrowserCacheUrlMutation.makeMutationOption({
                    headers: {
                        // Let the service decide the best image representation
                        accept: 'image/*;q=0.5',
                    },
                    // arraybuffer, blob, stream, text, json, document
                    responseType: 'arraybuffer',
                }));
            if (response.status === 200) {
                const blob: Blob = new Blob(
                    [response.data],
                    { type: DownloadUtil.getContentType(response.headers, 'image/jpeg') });
                if (blob) {
                    return await this.makeImageBitmapFromBlob(blob);
                } else {
                    throw new Error(`Failed to get image blob for '${uri}'`);
                }
            } else {
                throw new Error(`Error getting url '${uri}': status ${response.status}`);
            }
        } else {
            throw new Error('Invalid image url');
        }
    }

    private static async makeImageFromBlob(blob: Blob) {
        log.debug('Parsing image from blob of %d bytes of %s', blob.size, blob.type);
        const objectUrl = URL.createObjectURL(blob);
        try {
            log.debug('Image object URL %s', objectUrl);
            return await this.makeImageFromUrl(objectUrl);
        } finally {
            URL.revokeObjectURL(objectUrl);
        }
    }

    private static async makeImageBitmapFromBlob(blob: Blob) {
        log.debug('Parsing image from blob of %d bytes of %s', blob.size, blob.type);
        return createImageBitmap(blob);
    }

    /**
     * Load the data URL into an image object. This will load the data URL and it will
     * asynchronously decode the JPEG file so that it can be rasterised.
     *
     * If an error occurs it is expected that the promise will throw an error from the
     * call to {@link Image.decode}
     *
     * WARNING: Chrome has a 2MB URL (and thus presumably a dataurl) length limit.
     *
     * @see {@link Image}
     * @see {@link https://stackoverflow.com/questions/695151/data-protocol-url-size-limitations}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs}
     * @see {@link https://www.chromestatus.com/feature/5637156160667648}
     * @see {@link https://html.spec.whatwg.org/multipage/embedded-content.html#dom-img-decode}
     */
    public static async makeImageFromUrl(imageUrl: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
                image.decode()
                    .catch(reject)
                    .then(() => resolve(image));
            };
            image.src = imageUrl;
        });
    }
}
