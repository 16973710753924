
    import { Component, Vue } from 'vue-property-decorator';
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
    import { email, required } from 'vee-validate/dist/rules';

    import ViewPath from '@/router/viewPath';
    import { mapStores } from 'pinia';
    import { ForgotPasswordStore, useForgotPassword } from '@/views/auth/stores/forgotPasswordStore';

    extend('required', required);
    extend('email', email);

    /**
     * this is part 1 of 2 of the reset password workflow.
     *
     * users can request an email with a reset password link using this form.
     */
    @Component({
        components: { ValidationProvider, ValidationObserver },
        computed: { ...mapStores(useForgotPassword) },
    })
    export default class ForgotPassword extends Vue {
        $refs!: {
            observer: typeof ValidationObserver
        };

        forgotPasswordStore!: ForgotPasswordStore;
        viewPaths = ViewPath;

        async onReset() {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                await this.forgotPasswordStore.sendEmailWithResetLink();
            }
        }

        onBack() {
            this.forgotPasswordStore.$reset();
        }
    }
