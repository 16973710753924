import LinkRelation from '@/lib/api/LinkRelation';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import anylogger from 'anylogger';
import { LinkUtil, RelationshipType } from 'semantic-link';
import { all } from 'ramda';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';

const log = anylogger('HipSurgicalTemplateValidator');

/**
 * @param template A surgical template to validate
 * @param store The store where to validate the cup & liner
 */
export function validateTemplate(template: HipSurgicalTemplateRepresentation, store: HipTemplateStore): boolean {
    const hasMandatoryLink = (rel: RelationshipType): boolean => {
        if (LinkUtil.matches(template, rel)) {
            return true;
        }
        log.error('Missing a %s link', rel);
        return false;
    };

    const cupMatchesLiner = (): boolean => {
        const cup = store.cup?.component;
        const liner = store.liner?.component;
        const cupLinerSize = cup ? cup.liner_size_ : undefined;
        const linerSize = liner?.size;

        if (!cupLinerSize || !linerSize) {
            log.info('Invalid surgical template. Could not validate cup liner size matches liner size');
            return false;
        } else if (cupLinerSize !== linerSize) {
            log.info(
                'Invalid surgical template. Cup liner size %s does not match liner size %s',
                cupLinerSize,
                linerSize);
            return false;
        }
        return true;
    };

    return all(v => v, [
        hasMandatoryLink(LinkRelation.hipCurrentStemComponent),
        hasMandatoryLink(LinkRelation.hipCurrentStemHeadComponent),
        hasMandatoryLink(LinkRelation.hipCurrentCupComponent),
        hasMandatoryLink(LinkRelation.hipCurrentCupLinerComponent),
        cupMatchesLiner(),
    ]);
}
