export default class LinkRelationTitle {
    public static readonly current = 'current';
    public static readonly currentPlan = LinkRelationTitle.current;

    public static readonly manualSurgicalTemplate = 'manual';
    public static readonly automatedSurgicalTemplate = 'automated';
    public static readonly parentSurgicalTemplate = 'parent';

    /** The current component titles - overused for all component types */
    public static readonly currentComponent = LinkRelationTitle.current;
}
