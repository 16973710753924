
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { DateTime } from 'luxon';
    import DragDrop from '@/components/lib/DragDrop.vue';
    import CaseStateStepper from '@/components/case/state-stepper/CaseStateStepper.vue';
    import Username from '@/components/profile/Username.vue';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import { PatientRepresentation } from '@/lib/api/representation/PatientRepresentation';
    import PatientResource from '@/lib/api/resource/case/PatientResource';
    import UserResource from '@/lib/api/resource/user/UserResource';
    import { UriDeconstructionUtil } from '@/components/case-plan/UrlDeconstructionUtil';
    import LinkRelation from '@/lib/api/LinkRelation';

    import anylogger from 'anylogger';
    import DatetimeLocaleUtil, { DateDayEnum, DateMonthEnum, DateYearEnum } from '@/lib/datetimeLocaleUtil';
    import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
    import { NameRepresentation } from '@/lib/api/representation/NameRepresentation';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import CaseItemMenu from '@/views/administer/case-item/CaseItemMenu.vue';
    import WithCaseProduct from '@/components/shared/with-product/WithCaseProduct.vue';
    import assert from 'assert';

    const log = anylogger('CaseListItem');

    @Component({
        components: { WithCaseProduct, CaseItemMenu, DragDrop, CaseStateStepper, Username },
    })
    export default class CaseListItem extends Vue {
        protected dateFormat = DateTime;

        /**
         * This is the sparsely populated case/project that will populated in the
         * created method (below). The property must be provided by the parent.
         */
        @Prop({ required: true })
        public value!: CaseRepresentation;

        /**
         * Callback for the checkbox being selected
         */
        @Prop({ required: true, validator: notNilValidator('onToggle') })
        public onCheckboxClick!: () => void;

        /**
         * Whether the case is currently active (selected) or not
         */
        @Prop({ required: true, validator: notNilValidator('active') })
        public active!: boolean;

        protected isLoading = true;

        protected owner: UserRepresentation | null = null;
        protected surgeon: UserRepresentation | null = null;
        protected patient: PatientRepresentation | null = null;

        protected get surgeonName(): NameRepresentation | null {
            return this.surgeon ? this.surgeon.name || null : null;
        }

        /**
         * Get the created on date from the plan representation as a Luxon timestamp ({@link DateTime}).
         */
        protected get createdOn(): DateTime | null {
            if (this.value.created) {
                const when = DateTime.fromISO(this.value.created, { zone: 'utc' });
                if (when) {
                    return when;
                }
            }
            return null;
        }

        protected isCurrentYear(): boolean {
            if (this.value.created) {
                const when = DateTime.fromISO(this.value.created, { zone: 'utc' });
                if (when) {
                    return when.year === new Date().getFullYear();
                }
            }
            return false;
        }

        /**
         * Manually for the created timestamp as a date (without time of day)
         */
        protected get createdDate(): string {
            if (this.value.created) {
                return DatetimeLocaleUtil.formatToLocaleDate(
                    this.value.created,
                    DatetimeLocaleUtil.getUserLocale(),
                    {
                        year: DateYearEnum.Numeric,
                        month: DateMonthEnum.Short,
                        day: DateDayEnum.Numeric,
                    });
            }
            return '';
        }

        protected get referenceIdentifier(): string {
            const caseId = UriDeconstructionUtil.pathNameOfLink(this.value, LinkRelation.self);
            if (caseId) {
                return `#${caseId}`;
            }
            return '';
        }

        protected async onIntersect(
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
            isIntersecting: boolean): Promise<void> {
                if (isIntersecting) {
                    if (this.value) {
                        try {
                            // Ensure that the case data is fresh, but don't reassign the value.
                            try {
                                this.patient = await PatientResource.getCasePatient(this.value, this.$apiOptions);
                            } catch (e: unknown) {
                                assert.ok(e instanceof Error);
                                log.info('Failed to read patient: %s', e.message);
                            }
                            try {
                                this.surgeon = await UserResource.getCaseSurgeon(this.$api, this.value, this.$apiOptions);
                            } catch (e: unknown) {
                                assert.ok(e instanceof Error);
                                log.info('Failed to read surgeon: %s', e.message);
                            }
                            try {
                                this.owner = await UserResource.getCaseOwner(this.$api, this.value, this.$apiOptions);
                            } catch (e: unknown) {
                                assert.ok(e instanceof Error);
                                log.info('Failed to read owner: %s', e.message);
                            }
                        } finally {
                            this.isLoading = false;
                        }
                    } else {
                        log.debug('Case representation not loaded. Cannot make CaseListItem');
                    }
                }
            }
    }
