
    /**
     * see
     *  - https://vuetifyjs.com/en/components/dialogs
     */
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import CaseSurgicalTemplateResource
        from '@/lib/api/resource/case/surgical-template/CaseSurgicalTemplateResource';
    import assert from 'assert';
    import AxiosUtil from '@/lib/AxiosUtil';
    import CaseResource from '@/lib/api/resource/case/CaseResource';

    /**
     * A simple status dialog that is shown to the user (administrator) while an automated (ACID)
     * surgical template is duplicated.
     */
    @Component
    export default class DuplicateSurgicalTemplate extends Vue {
        @Prop({ required: true })
        public value!: CaseRepresentation;

        /** Whether the dialog is visible */
        protected isVisible = false;

        /** Whether the dialog is perform http operations to create the new surgical template */
        protected isWorking = false;

        protected errorText = '';

        /**
         * Called by template to create a duplicate ACID automated surgical template. This will force
         * the automated processing to be re-run.
         *
         * **IMPORTANT:** This will discard the current ACID surgical template from the view of the
         * user. They have no way to get the old surgical template back. Any plans (i.e. the PDF) will
         * also be removed from the view of the user. When the user surgical templating workflow is updated
         * it should notice that the user plan is now based on an outdated ACID surgical template and thus
         * may result in the users planning to be impacted (effectively deleted).
         */
        protected async onShowAndExecute(): Promise<void> {
            this.isVisible = true;
            this.isWorking = true;
            this.errorText = '';
            try {
                try {
                    const surgicalTemplate = await CaseSurgicalTemplateResource.createDuplicateAcidSurgicalTemplate(
                        this.value,
                        this.$apiOptions);
                    if (surgicalTemplate) {
                        await CaseResource.getCase(this.value, this.$apiOptions);
                        this.errorText = ``;
                    } else {
                        this.errorText = 'Error creating surgical template';
                    }
                } catch (err: unknown) {
                    assert.ok(err instanceof Error);
                    if (AxiosUtil.isForbiddenError(err)) {
                        this.errorText = `Access denied`;
                    } else {
                        this.errorText = `Error creating duplicate surgical template: ${err.message}`;
                    }
                }
            } finally {
                this.isWorking = false;
            }
        }
    }
