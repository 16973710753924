import anylogger from 'anylogger';
import Bugsnag from '@bugsnag/js';
import { LinkUtil } from 'semantic-link';

import LinkedRepresentationUtil from '@/lib/LinkedRepresentationUtil';
import LinkRelation from '@/lib/api/LinkRelation';
import { HipCaseRepresentation } from '@/lib/api/representation/case/HipCaseRepresentation';

const logger = anylogger('AmplitudeEvent');

interface PlanProperties {
    plan_id: string,
    plan_type: 'AUTO' | 'MANUAL',
}

interface CaseProperties {
    project_id: string,
    study_id: string,
    surgical_template_id: string,
    surgical_template_history_id: string,
}

export function planEventProperties(planId: string, isAutomatedPlan: boolean): PlanProperties {
    return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        plan_id: planId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        plan_type: isAutomatedPlan ? 'AUTO' : 'MANUAL',
    };
}

export function caseEventProperties(project: HipCaseRepresentation): Partial<CaseProperties> {
    try {
        const projectUri = LinkUtil.getUri(project.links, LinkRelation.self);
        const projectId = LinkedRepresentationUtil.getIdFromUrl(projectUri);

        let studyId = '';
        if (project.activeStudy) {
            const studyUri = LinkUtil.getUri(project.activeStudy.links, LinkRelation.self);
            studyId = LinkedRepresentationUtil.getIdFromUrl(studyUri);
        }

        let surgicalTemplateId = '';
        let surgicalTemplateHistoryId = '';
        if (project.surgicalTemplate) {
            const surgicalTemplateUri = LinkUtil.getUri(project.surgicalTemplate.links, LinkRelation.self);
            surgicalTemplateId = LinkedRepresentationUtil.getIdFromUrl(surgicalTemplateUri);
            const surgicalTemplateHistoryUri = LinkUtil.getUri(
                project.surgicalTemplate.links, LinkRelation.canonical
            );
            surgicalTemplateHistoryId = LinkedRepresentationUtil.getIdFromUrl(surgicalTemplateHistoryUri);
        }

        return {
            project_id: projectId,
            study_id: studyId,
            surgical_template_id: surgicalTemplateId,
            surgical_template_history_id: surgicalTemplateHistoryId,
        };
    } catch (err) {
        const errorMsg = `Cannot create case event properties: ${err}`;
        logger.warn(errorMsg);
        Bugsnag.notify(errorMsg);
        return {};
    }
}
