import anylogger from 'anylogger';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import {
    SurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import CaseSurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/CaseSurgicalTemplateResource';
import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
import assert from 'assert';
import { LinkUtil } from 'semantic-link';
import ResourceUtil from '@/lib/api/ResourceUtil';
import LinkRelation from '@/lib/api/LinkRelation';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import CaseResource from '@/lib/api/resource/case/CaseResource';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import { ProductType } from '@/lib/api/representation/ProductRepresentation';
import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';
import MeasurementsResource from '@/lib/api/resource/case/MeasurementsResource';
import { WaitResourceTimeoutError } from '@/lib/api/resource/WaitResourceUtil';
import PlanResource from '@/lib/api/resource/case/plan/PlanResource';
import { HipCaseRepresentation } from '@/lib/api/representation/case/HipCaseRepresentation';
import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';

const log = anylogger('plannerLoading');

export type PlannerLoadingContext = {
    addErrorMessage(message: string): void;
    api: ApiRepresentation;
    apiOptions: Partial<CacheOptions>;
    product: ProductType;
}

/**
 * Loads the case and validate the state is hydrated.
 * Otherwise it returns null if 404, 403, 500, or other not expected HTTP codes
 *
 * Note: @see {@class semantic-network State.js} will reject or resolve the promise based on the HTTP code.
 * If the code is 404, the promise is **rejected**, and giving we are using the 'await' instead of
 * Promise.then(), the code will go to the 'catch' clause.
 * If the code is 403, the promise is **resolved** so it does not hit the 'catch'.
 */
export async function loadCase<T extends CaseRepresentation>(
    context: PlannerLoadingContext, apiUri: string): Promise<T | null> {
    try {
        const project = await CaseResource.getCaseByUri(
            context.api, apiUri, { ...context.apiOptions, forceLoad: true });
        if (project) {
            return validateCaseState(context, project as T);
        } else {
            log.error('No case loaded');
            context.addErrorMessage('Could not load case');
            return null;
        }
    } catch (error: unknown) {
        if ((error as any)?.resource) {
            const resource = (error as any).resource;
            return validateCaseState(context, resource as T);
        }

        assert.ok(error instanceof Error);
        log.error('Could not load case: %s', error.message);
        context.addErrorMessage('Could not load case');
        return null;
    }
}

/**
 *  Validate case state is hydrated, the resource was fetched successfully.
 *  Otherwise it returns null if 404, 403, 500, or other not expected HTTP codes.
 *  @return the case if the state is hydrated.
 *
 *   @see {@class semantic-network State.js} to understand the association between the State and the HTTP codes
 */
function validateCaseState<T extends CaseRepresentation>(
    context: PlannerLoadingContext, resource: T): T | null {
    if (ResourceUtil.isItemHydrated(resource)) {
        return resource;
    } else if (ResourceUtil.isDeleted(resource)) {
        log.error('Case state not found: %s', getRequiredUri(resource, LinkRelation.self));
        context.addErrorMessage('Case not found');
    } else if (ResourceUtil.isForbidden(resource)) {
        log.error('Case state forbidden: %s', getRequiredUri(resource, LinkRelation.self));
        context.addErrorMessage('Case forbidden');
    } else if (ResourceUtil.isUnknown(resource)) {
        log.error('Case state unknown: %s', getRequiredUri(resource, LinkRelation.self));
        context.addErrorMessage('Case unknown');
    } else {
        log.error(
            'Could not load case. State: %s',
            getRequiredUri(resource, LinkRelation.self),
            ResourceUtil.tryGetStatus(resource));
        context.addErrorMessage('Could not load case');
    }

    return null;
}

/**
 * Check project loaded is the type of project the UI requires (uri path)
 */
export function validateCaseProduct(context: PlannerLoadingContext, caseResource: CaseRepresentation): boolean {
    const caseProduct = LinkUtil.getTitle(caseResource, 'product');

    if (caseProduct === context.product) {
        return true;
    }

    log.error('Trying to load a %s, in a %s 3D page', caseProduct, context.product);
    context.addErrorMessage(`Check that the URI belongs to a ${caseProduct} project`);
    return false;
}

/**
 * 1. Load a surgical template, or attempts to create it if it was not present
 *
 * TODO when doing https://youtrack.formuslabs.com/issue/FL-688 or some feature related
 * TODO with showing errors to user, use different instances to display user friendly messages
 *
 * TODO Validations are very rudimentary. We could use some Validator pattern, having error codes, etc
 */
export async function loadSurgicalTemplate<TC extends CaseRepresentation>(
    context: PlannerLoadingContext, caseResource: TC): Promise<SurgicalTemplateRepresentation | null> | never {
    const surgicalTemplate = await CaseSurgicalTemplateResource.getOrCreateUserSurgicalTemplate(
        caseResource, { ...context.apiOptions, forceLoad: true });
    if (surgicalTemplate) {
        try {
            return await SurgicalTemplateUtil.waitUntilManualProcessing(
                surgicalTemplate,
                3000,
                { signal: context.apiOptions.signal },
                context.apiOptions);
        } catch (e: unknown) {
            if (e instanceof WaitResourceTimeoutError) {
                log.error('We cannot proceed without a surgical template: %s', e.message);
            } else {
                throw e;
            }
        }
    }

    context.addErrorMessage('We cannot proceed without a surgical template');
    return null;
}

/**
 * Load study (and study measurements) from the surgical template (using the global collection of studies in the project)
 * After this method a surgical template should have a study resource hydrated
 */
export async function loadStudyOnSurgicalTemplate<TC extends CaseRepresentation>(
    context: PlannerLoadingContext,
    caseResource: TC,
    surgicalTemplate: SurgicalTemplateRepresentation): Promise<void> {
    await SurgicalTemplateResource.getStudy(
        caseResource, surgicalTemplate, { ...context.apiOptions, forceLoad: true });

    const study = surgicalTemplate.study;
    if (!study) {
        log.error('We cannot proceed without a study associated to the surgical template');
        context.addErrorMessage('We cannot proceed without a study uploaded');
        return;
    }

    const studyMeasurements = await MeasurementsResource.getStudyMeasurements(
        study, context.apiOptions);
    if (!studyMeasurements) {
        log.error('We cannot proceed without a study measurements');
        context.addErrorMessage('We cannot proceed without a study measurements');
    }
}

/**
 * Load surgical specification from the surgical template
 * After this method a surgical template should have a surgical specification resource hydrated
 */
export async function loadSurgicalSpecificationOnSurgicalTemplate<T extends SurgicalTemplateRepresentation>(
    context: PlannerLoadingContext, surgicalTemplate: T): Promise<void> {
    await SurgicalTemplateResource.getSurgicalSpecification(
        surgicalTemplate, { ...context.apiOptions, forceLoad: true });
    if (surgicalTemplate.surgicalSpecification) {
        // TODO load surgeon preferences here
    } else {
        log.error('We cannot proceed without a surgical specification associated to the surgical template');
        context.addErrorMessage(
            'We cannot proceed without a surgical specification completed');
    }
}

/** Fetch the manual user plan (if any) from the surgical template */
export async function reloadCurrentPlanOnSurgicalTemplate<T extends SurgicalTemplateRepresentation>(
    options: CacheOptions, project: HipCaseRepresentation, surgicalTemplate: T): Promise<PlanRepresentation | null> {
    return await PlanResource.getSurgicalTemplatePlan(
        surgicalTemplate, project, { ...options, forceLoad: true }) ?? null;
}

/** Loads acid surgical template */
export async function loadParentOnSurgicalTemplate<T extends SurgicalTemplateRepresentation>(
    context: PlannerLoadingContext, surgicalTemplate: T): Promise<T> {
    const automatedTemplate = await SurgicalTemplateResource.getAcidSurgicalTemplate<T>(surgicalTemplate, {
        ...context.apiOptions, name: 'parent',
    });

    if (automatedTemplate) {
        return automatedTemplate;
    } else {
        throw new Error('There is no Formus surgical template for user surgical template');
    }
}
