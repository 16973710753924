import { CaseHead, CaseStem } from '@/hipPlanner/components/state/types';
import { AnatomicalCoordinateSystem, BodySideType } from '@/lib/api/representation/interfaces';
import { Vector3 } from 'three';
import LPS from '@/lib/base/LPS';
import { LegLengthAndOffset } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { cupWorldPosition } from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { asVector3 } from '@/lib/base/ThreeUtil';

/**
 * Calculate a change in:
 * - leg length (inferior-superior shift introduced by the stem) based on a change in position along the stem neck axis.
 * - offset (medial-lateral shift introduced by the stem) based on a change in position along the stem neck axis.
 *
 * Note:
 * 1. The given stem **neck_axis** is calculated server side and is in the CT Scan Coordinate system
 **/
export function calculateAdjustmentsStemOld(
    stem: CaseStem, head: CaseHead, headOffset: number, operativeSide: BodySideType): LegLengthAndOffset {
    const fittedHeadComponent = head.component;
    const headOffsetChangeVector = new Vector3(...stem.neck_axis);
    const headOffsetChange = fittedHeadComponent.offset - headOffset;
    headOffsetChangeVector.multiplyScalar(headOffsetChange);

    // projection onto the leg length axis
    const baseLegLengthChange = stem.ll_diff;
    const legLengthChange = LPS.Inferior.clone().dot(headOffsetChangeVector.clone()) + baseLegLengthChange;

    // projection onto the offset axis
    const baseOffsetChange = stem.offset_diff;
    const offsetChange = LPS.lateral(operativeSide).clone().dot(headOffsetChangeVector.clone()) + baseOffsetChange;

    return { legLengthChange, offsetChange };
}

/**
 * The original version of the leg-length and offset calculation
 */
export function calculateLegLengthAndOffsetCupOld(
    assembly: HipPlannerAssembly, cupGlobalCS: AnatomicalCoordinateSystem): LegLengthAndOffset {
    const cupPosition = cupWorldPosition(assembly);
    const nativeJointCentre = asVector3(cupGlobalCS.origin);

    // the vector from hjc to cup
    const vCup = cupPosition.sub(nativeJointCentre);

    // IS vector for leg length
    const siVector = asVector3(cupGlobalCS.si.vector);
    const legLengthChange = vCup.dot(siVector);

    // ML vector for offset
    const mlVector = asVector3(cupGlobalCS.ml.vector);
    const offsetChange = vCup.dot(mlVector);

    return { legLengthChange, offsetChange };
}
