import { AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';

import RequestQueue from '@/lib/http/RequestQueue';
import MessageBus from '@/lib/http/MessageBus';

import anylogger from 'anylogger';

const log = anylogger('OfflineService');

export class OffineEvent {
    /**
     * The browser cannot find an connection out to the internet.
     *
     * @type {string}
     */
    public static readonly offline: string = 'event:http-offline';

    /**
     * We are waiting for the browser to become online again. This is mostly used when there is a dialog
     * alerting the user that we are waiting for the http connection to come back.
     *
     * @type {string}
     */
    public static readonly online: string = 'event:http-online';
    /**
     * We are waiting for the browser to become online again. This is mostly used when there is a dialog
     * alerting the user that we are waiting for the http connection to come back.
     *
     * @type {string}
     */
    public static readonly checking: string = 'event:http-checking';
}

enum State {
    Online = 1,
    Offline,
}

/**
 * This is part of the axios http request pipeline.
 */
export default class OfflineService {
    private axios: AxiosInstance;
    private queue: RequestQueue;
    private eventBus: MessageBus;
    private state: State = State.Online;

    constructor(axios: AxiosInstance, eventBus: MessageBus, queue?: RequestQueue) {
        this.axios = axios;
        this.eventBus = eventBus;
        this.queue = queue || new RequestQueue(axios);

        /**
         * Intercept http offline error message of "Network Error" so we can continue
         * with request processing after it comes back online. The Offline component
         * has subscribed to the `offline` message and continues processing.
         *
         * Note: `error` contains the original `config` to replay the original request.
         *
         * The order of this method MUST be before other error handling (eg 500, 401) because error in this scenario
         * does NOT return a response.
         */
        this.axios.interceptors.response.use(
            response => {
                return this.onFulfilled(response);
            },
            error => {
                return this.onRejected(error);
            });
    }

    /**
     * axios returns a network error that we need to match against readyState < DONE (4)
     * to correctly trap network down errors. All other errors should be passed through.
     *
     * # Ready States
     * |Value |    State|    Description |
     * |------|---------|----------------|
     * | 0    | UNSENT  |  Client has been created. open() not called yet.|
     * | 1    | OPENED  |  open() has been called.|
     * | 2    | HEADERS_RECEIVED |  send() has been called, and headers and status are available.|
     * | 3    | LOADING |   Downloading; responseText holds partial data.|
     * | 4    | DONE    |  The operation is complete.|
     */
    private onRejected(error: any): Promise<never> | AxiosPromise {
        if (error.message === 'Network Error') {
            if (error.request.readyState <= 4) {
                log.info(
                    'Request %s %s failed (state %s status %s): %s',
                    error.config.method,
                    error.config.url,
                    error.request.readyState,
                    error.request.status,
                    error.message);

                const p = this.queue.pushAsPromise(error.config);
                if (this.state !== State.Offline) {
                    this.state = State.Offline;
                    this.eventBus.$emit(OffineEvent.offline, error);
                }
                return p;
            }
            log.warn(
                'Request %s %s failed with unknown ready state (state %s, status %s): %s',
                error.config.method,
                error.config.url,
                error.request.readyState,
                error.request.status,
                error.message);
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }

    /**
     * Watch all other http requests. If we are in the offline state and some other request
     * is successful, then requeue all messages.
     *
     * The theory here is that if other requests are going through then queuing up the ones
     * from when the machine was offline should work.
     */
    private onFulfilled(response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> {
        if (this.state === State.Offline && response.status >= 200 && response.status < 300) {
            this.state = State.Online;
            this.eventBus.$emit(OffineEvent.online);
            this.queue.retryAll();
        }
        return response;
    }
}
