/**
 * Function to get the compiler to check code that uses dynamic property names.
 *
 * {@see https://www.typescriptlang.org/docs/handbook/advanced-types.html}
 */
import { isEqual, isObject, transform } from 'lodash';

export const getPropertyValue = function <T, K extends keyof T>(obj: T, propertyName: K): T[K] {
    return obj[propertyName];
};

export default class ObjectUtil {
    /**
     * Deep diff between two object, using lodash
     * @param  {Object} object Object compared
     * @param  {Object} base   Object to compare with
     * @return {Object}        Return a new object who represent the diff
     * @see https://gist.github.com/Yimiprod/7ee176597fef230d1451
     *
     * Note: This is untested
     */
    public static difference(object: Record<string, unknown>, base: Record<string, unknown>): Record<string, unknown> {
        function changes(object: Record<string, unknown>, base: Record<string, unknown>): Record<string, unknown> {
            // @ts-ignore not sure how to get this working. Was stolen from vanilla js code
            return transform(
                object, function(result: Record<string, unknown>, value: Record<string, unknown>, key: string) {
                    if (!isEqual(value, base[key])) {
                        // @ts-ignore not sure how to get this working. Was stolen from vanilla js code
                        result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
                    }
                });
        }

        return changes(object, base);
    }
}
