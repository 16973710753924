import {
    HipProductPreferencesRepresentation,
    isHipProductPreferencesRepresentation,
} from '@/lib/api/representation/ProductPreferencesRepresentation';
import { Preferences, State } from '@/stores/preferences/types';
import { selectorKey, STEMTYPE_KEY_SEPARATOR } from '@/components/data/combobox/hip/HipStemTypeSelectorOptions';
import { HipCupAnteversionMode } from '@/lib/api/representation/interfaces';
import _ from 'lodash';

export const stateFromResponse = (representation: HipProductPreferencesRepresentation): Partial<State> => {
    if (!isHipProductPreferencesRepresentation(representation)) {
        throw new Error('cannot map preferences from invalid api response');
    }

    const { links, cup, stem, align_mode: alignmentMode } = representation;

    const mappedResponse: Preferences = {
        alignmentMode: alignmentMode || '',
        stemTypes: _.sortBy((stem?.selector || []).map(selectorKey)),
        cupFittingMode: cup.fit_method || '',
        cupAnteversionMode: cup.anteversion_mode || '',
        cupAnteversionAngle: cup.anteversion_angle ? String(cup.anteversion_angle) : '',
        cupInclinationAngle: cup.abduction_angle ? String(cup.abduction_angle) : '',
    };

    return {
        links: links || [],
        preferences: { ...mappedResponse },
        original: { ...mappedResponse },
    };
};

export const requestBodyFromState = (state: State) => {
    const {
        stemTypes,
        alignmentMode,
        cupInclinationAngle,
        cupFittingMode,
        cupAnteversionMode,
        cupAnteversionAngle,
    } = state.preferences;

    const selectedStemTypes = stemTypes
        .map((selected:string) => selected.split(STEMTYPE_KEY_SEPARATOR))
        .map(([system, type]: string[]) => ({ system, type, size: null }));

    // eslint-disable-next-line camelcase
    const anteversionAngle = cupAnteversionMode === HipCupAnteversionMode.Manual ?
        String(cupAnteversionAngle) :
        null;

    return {
        links: state.links,
        name: 'hip',
        stem: { selector: selectedStemTypes },
        cup: {
            abduction_angle: cupInclinationAngle,
            anteversion_angle: anteversionAngle,
            anteversion_mode: cupAnteversionMode,
            fit_method: cupFittingMode,
        },
        align_mode: alignmentMode,
    };
};
