import { isHipSurgicalTemplate } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateUtil';
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { isHipCaseRepresentation } from '@/lib/api/representation/case/CaseUtil';

/**
 * A mixin that adds simple guard clauses by type for different resources.
 *
 * Can be called with any of the following resources:
 * - surgical template
 * - a project
 *
 * Note: this methods can be used in the <template> part of the vue component, not inside the class.
 * Note: A preferred approach will be to use the `<WithProduct />`
 *
 * E.g:
 *
 * ```
 * <template>
 *      <div v-if=isHip(value)>
 *          show if hip
 *      </div>
 * </template>
 *
 *
 *  @Component({
 *       components: { },
 *       mixins: [ProductAssertionMixin],
 *   })
 *  class AComponentUsingTheMixing extends Vue {
 *      protected value: SurgicalTemplateRepresentation | CaseRepresentation
 *  }
 *  ```
 */
@Component
export default class ProductAssertionMixin extends Vue {
    /**
     * Return whether the value is of a hip product
     */
    public isHipSurgicalTemplate(value: unknown): boolean {
        return isHipSurgicalTemplate(value);
    }

    /**
     * Return whether the value is of a hip product
     */
    public isHipCase(value: unknown): boolean {
        return isHipCaseRepresentation(value);
    }
}
