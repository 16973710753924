import ResourceService from '@/lib/api/ResourceService';
import { ProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';
import { UserRepresentation } from '@/lib/api/representation/user/UserRepresentation';
import { CacheOptions, create } from '@/lib/semanticNetworkMigrationUtils';
import UserResource from '@/lib/api/resource/user/UserResource';
import ResourceUtil from '@/lib/api/ResourceUtil';
import LinkRelation from '@/lib/api/LinkRelation';
import { ProductType } from '@/lib/api/representation/ProductRepresentation';
import { CollectionRepresentation } from 'semantic-link';
import ContentType from '@/lib/http/mimetype';

export default class ProductPreferencesResource implements ResourceService {
    /**
     * Given a product type (simple string enum), get the surgeon preferences (if any).
     *
     * The user preferences are logically a container with many different types of preferences. At this
     * stage the main preferences are the 'surgeon preferences', but other types of preferences are possible.
     *
     * Surgeon preferences are searched for by using the product Uri (the simple name is mapped to a Uri by
     * enumerating the list of products available to the user).
     */
    public static async getSurgeonPreferences<T extends ProductPreferencesRepresentation>(
        userResource: UserRepresentation,
        productName: ProductType,
        options?: CacheOptions): Promise<CollectionRepresentation<T> | null> {
        // Map the product type to a product Uri
        const productUri = await UserResource.findUserProductByName(userResource, productName, options);
        if (productUri) {
            return await create<CollectionRepresentation<T>>(
                productUri, {
                    includeItems: true,
                    ...options,
                    createContext: userResource,
                    rel: LinkRelation.surgeonPreferences,
                    singletonName: productUri,
                    contentType: ContentType.UriList,
                    sparseType: 'collection' /* the 'search' result is a collection */,
                }) ?? null;
        }
        throw new Error(`Failed to get surgeon '${productName}' preferences`);
    }

    public static async getSurgeonPreferencesSingleton<T extends ProductPreferencesRepresentation>(
        userResource: UserRepresentation,
        productName: ProductType,
        options?: CacheOptions): Promise<T> {
        const collection = await ProductPreferencesResource.getSurgeonPreferences<T>(
            userResource, productName, options);
        //
        //  Return the singleton from the collection.
        //
        if (collection?.items && collection.items.length === 1) {
            return collection.items[0];
        } else {
            throw new Error(
                `exactly one set of preferences expected (got ${collection?.items ? collection.items.length : 0})`);
        }
    }

    /**
     * Put a new surgeon into play. This requires the following changes (if successful):
     *   - the 'surgeon' link
     *   - the surgeon representation
     */
    public static async updateSurgeonPreferences(
        preferences: ProductPreferencesRepresentation,
        changedPreferences: Partial<ProductPreferencesRepresentation>,
        options?: CacheOptions): Promise<ProductPreferencesRepresentation> {
        return await ResourceUtil.update(preferences, changedPreferences, options);
    }
}
